import React, { useEffect, useRef, useState } from "react";
import "./NewMyProduct.css";
import images from "../../constants/images";
import {
  get_all_user_product,
  get_all_club_product_assign_bond,
  get_all_user_after_acution_product,
  get_user_pending_bond,
} from "../../utils/Constant";
import axios from "axios";
import qs from "qs";
import { Link, useNavigate } from "react-router-dom";
import NavbarSec from "../../common/NavbarSec";
import NewModal from "../../common/newmodal/NewModal";
import ReactModal from "react-modal";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { IoSearch } from "react-icons/io5";
import V2Modal from "../../common/v2modal/V2Modal";

const data = [
  {
    id: 1,
    imgsrc: images.media1,
    title: "Breeza",
    desc: "Applied on-Aug 12,2023",
    bg: "linear-gradient(to bottom, #ff33cc 0%, #666699 100%)",
  },
  {
    id: 2,
    imgsrc: images.media1,
    title: "Ciaz",
    desc: "Applied on-Aug 12,2023",
    bg: "linear-gradient(to bottom, #669999 0%, #666699 100%)",
  },
  {
    id: 3,
    imgsrc: images.media1,
    title: "Creta",
    desc: "Applied on-Aug 12,2023",
    bg: "linear-gradient(to bottom, #ff33cc 0%, #666699 100%)",
  },
  {
    id: 4,
    imgsrc: images.media1,
    title: "Innova",
    desc: "Applied on-Aug 12,2023",
    bg: "linear-gradient(to bottom, #669999 0%, #666699 100%)",
  },
  {
    id: 5,
    imgsrc: images.media1,
    title: "BMW",
    desc: "Applied on-Aug 12,2023",
    bg: "linear-gradient(to bottom, #ff33cc 0%, #666699 100%)",
  },
  {
    id: 6,
    imgsrc: images.media1,
    title: "Mercedes",
    desc: "Applied on-Aug 12,2023",
    bg: "linear-gradient(to bottom, #669999 0%, #666699 100%)",
  },
  {
    id: 7,
    imgsrc: images.media1,
    title: "Audi",
    desc: "Applied on-Aug 12,2023",
    bg: "linear-gradient(to bottom, #ff33cc 0%, #666699 100%)",
  },
  {
    id: 8,
    imgsrc: images.media1,
    title: "Honda",
    desc: "Applied on-Aug 12,2023",
    bg: "linear-gradient(to bottom, #669999 0%, #666699 100%)",
  },
  {
    id: 9,
    imgsrc: images.media1,
    title: "Buggati",
    desc: "Applied on-Aug 12,2023",
    bg: "linear-gradient(to bottom, #ff33cc 0%, #666699 100%)",
  },
  {
    id: 10,
    imgsrc: images.media1,
    title: "Chevrolet",
    desc: "Applied on-Aug 12,2023",
    bg: "linear-gradient(to bottom, #669999 0%, #666699 100%)",
  },
  {
    id: 11,
    imgsrc: images.media1,
    title: "Lamorghini",
    desc: "Applied on-Aug 12,2023",
    bg: "linear-gradient(to bottom, #ff33cc 0%, #666699 100%)",
  },
];

const NewMyProduct = () => {
  const navigate = useNavigate();
  const userId = localStorage.getItem("user_id");

  const [searchTerm, setSearchTerm] = useState([]);
  const [search, setSearch] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessage1, setErrorMessage1] = useState("");
  const [getproductid, setproductid] = useState("");
  const [getconditon, setconditon] = useState(false);
  const [isNfbRell, setIsNfbRell] = useState(0);
  const [club_product, setclub_product] = useState([]);
  const [auction_product, setauction_product] = useState([]);
  const [getpending_product, setpending_product] = useState([]);

  //
  const [searchclub, setsearchclub] = useState([]);
  const [searchPendingProduct, setsearchPendingProduct] = useState([]);
  const [searchauction, setsearchauction] = useState([]);
  const [cat_asset, setcat_asset] = useState(1);

  const handleSearch = (event) => {
    const searchTerm = event.toLowerCase();
    const searchclubs = event.toLowerCase();
    const searchauctions = event.toLowerCase();
    const searchPending = event.toLowerCase();

    if (isNfbRell == 0) {
      const filteredResults = searchTerm
        ? search.filter((item) =>
            item.product_name.toLowerCase().includes(searchTerm)
          )
        : search;
      setSearchTerm(filteredResults);
    } else if (isNfbRell == 2) {
      const filteredResults2 = searchauctions
        ? searchauction.filter((item) =>
            item.product_name.toLowerCase().includes(searchauctions)
          )
        : auction_product;
      setsearchauction(filteredResults2);
    } else if (isNfbRell == 1) {
      const filteredResults1 = searchclubs
        ? searchclub.filter((item) =>
            item.product_name.toLowerCase().includes(searchclubs)
          )
        : club_product;
      setsearchclub(filteredResults1);
    } else if (isNfbRell == 3) {
      const filteredResults1 = searchPending
        ? searchPendingProduct.filter((item) =>
            item.product_name.toLowerCase().includes(searchPendingProduct)
          )
        : getpending_product;
      setsearchPendingProduct(filteredResults1);
    }
  };

  const Get_all_user_product = () => {
    const param = {
      user_id: userId,
    };
    console.log("param", param);
    axios
      .post(get_all_user_product, qs.stringify(param))
      .then((Response) => {
        console.log("Response", Response);
        if (Response.data.status == 1) {
          setSearchTerm(Response.data.result);
          setSearch(Response.data.result);
        } else {
          //  alert(Response.data.message);
        }
      })
      .catch((err) => {
        console.log("Respnose", err);
      });
  };
  const Get_setauction_product = () => {
    const param = {
      user_id: userId,
    };
    console.log("param", param);
    axios
      .post(get_all_user_after_acution_product, qs.stringify(param))
      .then((Response) => {
        if (Response.data.status == 1) {
          // setclub_product(Response.data.result);
          // setsearchclub(Response.data.result);
          setauction_product(Response.data.result);
          setsearchauction(Response.data.result);
        } else {
          //  alert(Response.data.message);
        }
      })
      .catch((err) => {
        console.log("Respnose", err);
      });
  };
  const Get_setclub_product = () => {
    const param = {
      user_id: userId,
    };
    console.log("param", param);
    axios
      .post(get_all_club_product_assign_bond, qs.stringify(param))
      .then((Response) => {
        if (Response.data.status == 1) {
          // setauction_product(Response.data.result);
          setclub_product(Response.data.result);
          setsearchclub(Response.data.result);
          // setsearchauction(Response.data.result);
        } else {
          //  alert(Response.data.message);
        }
      })
      .catch((err) => {
        console.log("Respnose", err);
      });
  };
  const Get_pending_product = () => {
    const param = {
      user_id: userId,
    };
    console.log("param", param);
    axios
      .post(get_user_pending_bond, qs.stringify(param))
      .then((Response) => {
        if (Response.data.status == 1) {
          // setauction_product(Response.data.result);
          setpending_product(Response.data.user_pending_bond);
          setsearchPendingProduct(Response.data.user_pending_bond);
          // setsearchauction(Response.data.result);
        } else {
          //  alert(Response.data.message);
        }
      })
      .catch((err) => {
        console.log("Respnose", err);
      });
  };

  const deletedata = () => {
    const param = {
      product_id: getproductid,
    };
    console.log(param);
    axios
      .post(
        "https://www.ravi.host/api/delete_bond_product",
        qs.stringify(param)
      )
      .then((Response) => {
        console.log(Response.data);
        if (Response.data.status == 1) {
          console.log("====================================");
          console.log("res", Response.data);
          console.log("====================================");
          setShowModal(false);
          setErrorMessage1(Response.data.message);
          setShowModal1(true);
          Get_all_user_product();
        } else {
          setErrorMessage1(Response.data.message);
          setShowModal1(true);
        }
      })
      .catch((err) => {
        console.log("dzv sd", err);
      });
  };
  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    Get_all_user_product();
    Get_setauction_product();
    Get_setclub_product();
    Get_pending_product();
  }, []);

  useEffect(() => {
    // return () => {};
    console.log("searchTerm", searchTerm);
  }, [searchTerm]);

  //disable body scrolling

  const [bodyScrollEnabled, setBodyScrollEnabled] = useState(true);

  useEffect(() => {
    if (showModal1 || showModal) {
      // Disable body scrolling
      setBodyScrollEnabled(false);
    } else {
      // Enable body scrolling
      setBodyScrollEnabled(true);
    }
    if (bodyScrollEnabled) {
      document.body.style.overflow = "visible";
    } else {
      document.body.style.overflow = "hidden";
    }
  }, [showModal1, showModal, bodyScrollEnabled]);

  const closemodal1 = () => {
    setShowModal1(false);
  };

  const [cat_assets, setcat_assets] = useState(2);
  useEffect(() => {
    setcat_assets(2);
  }, []);

  const containerRef = useRef(null);

  const scrollLeft = () => {
    containerRef.current.scrollBy({
      left: -150, // Adjust this value to control the scroll amount
      behavior: "smooth",
    });
  };

  const scrollRight = () => {
    containerRef.current.scrollBy({
      left: 150, // Adjust this value to control the scroll amount
      behavior: "smooth",
    });
  };

  return (
    <div className="myproduct_13_page_main_wrapp">
      <div className="myproduct_13_page_base_wrapp_2">
        <div className="myproduct_13_title_section">
          <button
            onClick={() => {
              navigate("/Sidemenu");
            }}
            className="myproduct_13_back_btn"
          >
            <FaAngleLeft color="var(--color-white)" size={20} />
          </button>
          <div className="myproduct_13_nav_title">Assets</div>
          <div className="underline_asset"></div>
        </div>
        <div className="myproduct_13_product-cnt_wrapp">
          <div className="myproduct_13_product-cnt">
            <p>
              "Ready to showcase your unique creations? Click the 'Protect
              Asset' button below to get started and unleash the power of
              aucemo.club!"
            </p>
            {/* <Link> */}
            <button
              onClick={() => {
                navigate("/CreateProduct");
              }}
              className="myproduct_13_create_product_btn"
              style={{ zIndex: 1, cursor: "pointer" }}
            >
              Protect Asset
            </button>
            {/* </Link> */}
          </div>
        </div>
        <div className="myproduct_13_search">
          <div className="myproduct_13_user_bid_main">
            <input
              type="text"
              placeholder="Search"
              className="myproduct_13_user_bid_search"
              // value={searchTerm}
              onChange={(e) => handleSearch(e.target.value)}
            />
            {/* <img
              src={images.search_icon}
              alt=""
              className="myproduct_13_search_ic"
            /> */}
            <IoSearch
              size={22}
              color="#fff"
              className="myproduct_13_search_ic"
            />
          </div>
        </div>
        {/* <p className={`my-p ${getname === "All" ? "custom custom-border" : "custom"}`} */}
        <div style={{ display: "flex", alignItems: "center" }}>
          <button className="scroll-button left" onClick={scrollLeft}>
            <FaAngleLeft color="var(--color-main)" size={22} />
          </button>
          <div ref={containerRef} className="myproduct_13_button-container">
            <button
              onClick={() => {
                setIsNfbRell(0);
                setcat_asset(1);
              }}
              className={`myproduct_13_my-p ${
                cat_asset === 1 ? "custom custom-border" : "custom"
              }`}
            >
              <p className="myproduct_13_filter-title-text">Assets</p>
            </button>

            <button
              onClick={() => {
                setIsNfbRell(2);
                setcat_asset(2);
              }}
              className={`myproduct_13_my-p ${
                cat_asset === 2 ? "custom custom-border" : "custom"
              }`}
            >
              <p className="myproduct_13_filter-title-text">Auction Assets</p>
            </button>

            <button
              onClick={() => {
                setIsNfbRell(1);
                setcat_asset(3);
              }}
              className={`myproduct_13_my-p ${
                cat_asset === 3 ? "custom custom-border" : "custom"
              }`}
            >
              <p className="myproduct_13_filter-title-text">Club Assets</p>
            </button>

            <button
              onClick={() => {
                setIsNfbRell(3);
                setcat_asset(4);
              }}
              className={`myproduct_13_my-p ${
                cat_asset === 4 ? "custom custom-border" : "custom"
              }`}
            >
              <p className="myproduct_13_filter-title-text">Pending Assets</p>
            </button>
          </div>
          <button className="scroll-button right" onClick={scrollRight}>
            <FaAngleRight color="var(--color-main)" size={22} />
          </button>
        </div>
        {/* <button
          className="nav-button right"
          // onClick={scrollRight}
          style={{ justifyContent: "flex-end" }}
        >
          <FaAngleRight color="var(--color-main)" size={22} />
        </button> */}
        <div className="main-card">
          {/* all prodcucts start */}
          {isNfbRell == 0 ? (
            <div className="myproduct_13_card-container">
              {searchTerm == 0 ? (
                <p
                  style={{
                    color: "white",
                    textAlign: "center",
                    fontFamily: "var(--font-400)",
                    alignSelf: "center",
                    width: "100%",
                  }}
                >
                  No items found.
                </p>
              ) : (
                <>
                  {searchTerm.map((item, index) =>
                    item.bond_img_video[0] == undefined ? null : (
                      <div
                        className="myproduct_13_club_user_details"
                        key={index}
                      >
                        {/* <div className="card-img"> */}
                        <img
                          src={item.bond_img_video[0].img_video}
                          alt=""
                          className="myproduct_13_img_details"
                        />
                        {/* </div> */}
                        <div
                          className="myproduct_13_card-content"
                          style={{
                            height: item.is_commit == 1 ? "auto" : "auto",
                            justifyContent:
                              item.is_commit == 1 ? "end" : "center",
                          }}
                        >
                          <h4 className="myproduct_13_card-title-text">
                            {" "}
                            {item.product_name}
                          </h4>
                          <p className="myproduct_13_applied-title">
                            Applied on - {item.apply_date}
                          </p>
                          {item.is_owner == 4 ? (
                            <p className="myproduct_13_applied-title">
                              {" "}
                              {/* Assigned Aucemo - {item.remaining_bond} */}
                            </p>
                          ) : null}

                          <div className="myproduct_13_btn_new">
                            {item.is_gift == 1 ? null : (
                              <button
                                onClick={() => {
                                  setproductid(item.product_id);
                                  setShowModal(true);
                                  setErrorMessage(
                                    "Are you sure want to remove this assets ?"
                                  );
                                  setcat_assets(1);
                                  // deletedata(item.)
                                }}
                                className={`myproduct_13_sub2_button_a ${
                                  cat_assets === 1
                                    ? "myproduct_13_sub2_button_a_hover"
                                    : ""
                                }`}
                                disabled={item.is_commit == 1}
                                style={{
                                  //   background:
                                  //     item.is_commit == 1
                                  //       ? "gray"
                                  //       : "transparent",
                                  opacity: item.is_commit == 1 ? 0.2 : 1,
                                }}
                              >
                                <div>Remove</div>
                              </button>
                            )}
                            {item.status == 5 ? (
                              <button
                                onClick={() => {
                                  setcat_assets(4);
                                  //   setproductid(item.product_id);
                                  //   setShowModal(true);
                                  //   setErrorMessage(
                                  //     "Are you sure want to remove this assets ?"
                                  //   );
                                  //   // deletedata(item.)
                                }}
                                className={`myproduct_13_sub2_button_a ${
                                  cat_assets === 4
                                    ? "myproduct_13_sub2_button_a_hover"
                                    : ""
                                }`}
                                disabled={item.is_commit == 1}
                                style={{
                                  //   background:
                                  // item.is_commit == 1
                                  //   ? "gray"
                                  //   : "transparent",
                                  // paddingTop:"3px",
                                  opacity: item.is_commit == 1 ? 0.2 : 1,
                                }}
                              >
                                <div>Share Aucemo</div>
                              </button>
                            ) : null}
                            <button
                              onClick={() => {
                                if (item.status == 1) {
                                  if (item.owner_id == userId) {
                                    navigate("/assetsapproval");
                                    console.log("item.owner_is", item.owner_id);
                                  } else {
                                    setShowModal1(true);
                                    setcat_assets(2);
                                    setErrorMessage1(
                                      "The creator has been notified about your request. Ones the creator approves, you can start creating Aucemo."
                                      // "Please accept the aucemo from Received Aucemo section to take further actions"
                                    );
                                    console.log("item.owner_is", item.owner_id);
                                    console.log("userId", userId);
                                  }
                                } else if (
                                  item.status == 4 ||
                                  item.status == 6
                                ) {
                                  setShowModal(true);
                                  setErrorMessage("Your Aucemo under auction");
                                } else {
                                  navigate("/actionneed", {
                                    state: {
                                      product_name: item.product_name,
                                      owner_name: item.owner_name,
                                      product_id: item.product_id,
                                      remaining_bond: item.remaining_bond,
                                      product_value: item.product_value,
                                      is_globle: item.is_globle,
                                      is_owner: item.is_owner,
                                      commitment_date: item.commitment_date,
                                      is_gift: item.is_gift,
                                    },
                                  });
                                }
                              }}
                              className={`myproduct_13_sub2_button_a ${
                                cat_assets === 2
                                  ? "myproduct_13_sub2_button_a_hover"
                                  : ""
                              }`}
                              disabled={item.is_commit == 1}
                              style={{
                                // background:
                                //   item.is_commit == 1 ? "gray" : "transparent",
                                opacity: item.is_commit == 1 ? 0.2 : 1,
                              }}
                            >
                              <div>
                                {item.status == 1
                                  ? "In review"
                                  : item.status == 2 || item.status == 3
                                  ? "Action Needed"
                                  : item.status == 3
                                  ? "Reject"
                                  : item.status == 4
                                  ? "In Auction"
                                  : item.status == 5 || item.status == 7
                                  ? "Action Needed"
                                  : item.status == 6
                                  ? "In Action"
                                  : null}
                              </div>
                            </button>
                            <button
                              // disabled={item.is_commit == 1}
                              style={{
                                // background:
                                //   item.is_commit == 1 ? "gray" : "transparent",
                                // opacity: item.is_commit == 1 ? 0.6 : 1,
                                opacity: 1,
                              }}
                              className={`myproduct_13_sub2_button_a ${
                                cat_assets === 3
                                  ? "myproduct_13_sub2_button_a_hover"
                                  : ""
                              }`}
                              onClick={() => {
                                setcat_assets(3);
                                navigate("/Editmyproduct", {
                                  state: {
                                    product_id: item.product_id,
                                    is_nfb: item.is_nfb,
                                    user_id: item.user_id,
                                    product_name: item.product_name,
                                    product_history: item.product_history,
                                    product_highlights: item.product_highlights,
                                    product_media_upload:
                                      item.product_media_upload,
                                    bond_img_video: item.bond_img_video,

                                    date_of_creation: item.date_of_creation,
                                    is_owner: item.is_owner,
                                    owner_name: item.owner_name,
                                    owner_id: item.owner_id,
                                    product_value: item.product_value,
                                    description: item.description,
                                    interest_data: item.interest_data,
                                    remaining_bond: item.remaining_bond,
                                    product_value: item.product_value,
                                    is_gift: item.is_gift,
                                  },
                                });
                              }}
                            >
                              {/* {item.is_commit == 1 ? '11' : '00'} */}
                              <div>Edit</div>
                            </button>
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </>
              )}
            </div>
          ) : null}
          {/* all prodcucts start */}

          {/* club products start */}
          {isNfbRell == 1 ? (
            <div className="myproduct_13_card-container">
              {searchclub == 0 ? (
                <p
                  style={{
                    color: "white",
                    textAlign: "center",
                    fontFamily: "var(--font-400)",
                    alignSelf: "center",
                    width: "100%",
                  }}
                >
                  No items found.
                </p>
              ) : (
                <>
                  {searchclub.map((item, index) =>
                    item.bond_img_video[0] == undefined ? null : (
                      <div
                        className="myproduct_13_club_user_details_2"
                        key={index}
                      >
                        {/* <div className="card-img"> */}
                        <img
                          src={item.bond_img_video[0].img_video}
                          alt=""
                          className="myproduct_13_img_details"
                        />
                        {/* </div> */}
                        <div className="myproduct_13_card-content">
                          <h4 className="myproduct_13_card-title-text">
                            {" "}
                            {item.product_name}
                          </h4>
                          <p className="myproduct_13_applied-title">
                            Applied on - {item.apply_date}
                          </p>
                          {item.is_owner == 4 ? (
                            <p className="myproduct_13_applied-title">
                              {" "}
                              Assigned Aucemo - {item.remaining_bond}
                            </p>
                          ) : null}
                          <div className="myproduct_13_btn_new">
                            {item.is_owner != 4 ? (
                              <button
                                onClick={() => {
                                  setproductid(item.product_id);
                                  setShowModal(true);
                                  setErrorMessage(
                                    "Are you sure want to remove this assets ?"
                                  );
                                  setcat_assets(1);
                                  // deletedata(item.)
                                }}
                                className={`myproduct_13_sub2_button_a ${
                                  cat_assets === 1
                                    ? "myproduct_13_sub2_button_a_hover"
                                    : ""
                                }`}
                              >
                                <div>Remove</div>
                              </button>
                            ) : item.status == 5 ? (
                              <button
                                onClick={() => {
                                  setcat_assets(4);
                                  setproductid(item.product_id);
                                  setShowModal(true);
                                  setErrorMessage(
                                    "Are you sure want to remove this assets ?"
                                  );
                                  // deletedata(item.)
                                }}
                                // style={{paddingTop:"3px"}}
                                className={`myproduct_13_sub2_button_a ${
                                  cat_assets === 4
                                    ? "myproduct_13_sub2_button_a_hover"
                                    : ""
                                }`}
                              >
                                <div>Share Aucemo</div>
                              </button>
                            ) : null}
                            <button
                              onClick={() => {
                                if (item.status == 1) {
                                  // setShowModal1(true);
                                  // setcat_assets(2);
                                  // setErrorMessage1(
                                  //   "The creator has been notified about your request. Ones the creator approves, you can start creating Aucemo."
                                  // );

                                  if (item.owner_id == userId) {
                                    navigate("/assetsapproval");
                                    console.log("item.owner_is", item.owner_id);
                                  } else {
                                    setShowModal1(true);
                                    setcat_assets(2);
                                    setErrorMessage1(
                                      "The creator has been notified about your request. Ones the creator approves, you can start creating Aucemo."
                                      // "Please accept the aucemo from Received Aucemo section to take further actions"
                                    );
                                  }
                                } else if (item.status == 4) {
                                  setShowModal(true);
                                  setErrorMessage("Your Aucemo under auction");
                                } else {
                                  navigate("/ClubActionNeed", {
                                    state: {
                                      is_globle: item.is_globle,
                                      product_name: item.product_name,
                                      owner_name: item.owner_name,
                                      club_product_id: item.club_product_id,
                                      remaining_bond: item.remaining_bond,
                                      club_id: item.club_id,
                                      club_name: item.club_name,
                                      product_image:
                                        item.bond_img_video[0].img_video,
                                      club_user_id: item.user_id,
                                      is_club_assign: item.is_club_assign,
                                    },
                                  });
                                }
                              }}
                              className={`myproduct_13_sub2_button_a ${
                                cat_assets === 2
                                  ? "myproduct_13_sub2_button_a_hover"
                                  : ""
                              }`}
                            >
                              <div>
                                {item.status == 1
                                  ? "In review"
                                  : item.status == 2 || item.status == 3
                                  ? "Action Needed"
                                  : item.status == 3
                                  ? "Reject"
                                  : item.status == 4
                                  ? "In Auction"
                                  : item.status == 5
                                  ? "Action Needed"
                                  : item.status == 6
                                  ? "In Action"
                                  : null}
                              </div>
                            </button>
                            {/* <button className="myproduct_13_sub2_button_a">Edit</button> */}
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </>
              )}
            </div>
          ) : null}
          {/* club products end */}

          {/* auction products start */}
          {isNfbRell == 2 ? (
            <div className="myproduct_13_card-container">
              {searchauction == 0 ? (
                <p
                  style={{
                    color: "white",
                    textAlign: "center",
                    fontFamily: "var(--font-400)",
                    alignSelf: "center",
                    width: "100%",
                  }}
                >
                  No items found.
                </p>
              ) : (
                <>
                  {searchauction.map((item, index) =>
                    item.bond_img_video[0] == undefined ? null : (
                      <div
                        className="myproduct_13_club_user_details"
                        key={index}
                      >
                        {/* <div className="card-img"> */}
                        <img
                          src={item.bond_img_video[0].img_video}
                          alt=""
                          className="myproduct_13_img_details"
                        />
                        {/* </div> */}
                        <div className="myproduct_13_card-content">
                          <h4 className="myproduct_13_card-title-text">
                            {" "}
                            {item.product_name}
                          </h4>
                          <p className="myproduct_13_applied-title">
                            Applied on - {item.apply_date}
                          </p>
                          {item.is_owner == 4 ? (
                            <p className="myproduct_13_applied-title">
                              {" "}
                              Assigned Aucemo - {item.remaining_bond}
                            </p>
                          ) : null}
                          <div className="myproduct_13_btn_new">
                            {item.is_owner != 4 ? (
                              <button
                                onClick={() => {
                                  setproductid(item.product_id);
                                  setShowModal(true);
                                  setErrorMessage(
                                    "Are you sure want to remove this assets ?"
                                  );
                                  setcat_assets(1);
                                  // deletedata(item.)
                                }}
                                className={`myproduct_13_sub2_button_a ${
                                  cat_assets === 1
                                    ? "myproduct_13_sub2_button_a_hover"
                                    : ""
                                }`}
                              >
                                <div>Remove</div>
                              </button>
                            ) : null}
                            {item.status == 5 ? (
                              <button
                                //   style={{paddingTop:"3px"}}
                                onClick={() => {
                                  setcat_assets(4);
                                  navigate("/EditShareAuction", {
                                    state: {
                                      product_name: item.product_name,
                                      owner_name: item.owner_name,
                                      product_id: item.product_id,
                                      remaining_bond: item.remaining_bond,
                                      product_value: item.product_value,
                                      is_globle: item.is_globle,
                                      is_owner: item.is_owner,
                                    },
                                  });
                                }}
                                className={`myproduct_13_sub2_button_a ${
                                  cat_assets === 4
                                    ? "myproduct_13_sub2_button_a_hover"
                                    : ""
                                }`}
                              >
                                <div>Share aucemo</div>
                              </button>
                            ) : null}

                            <button
                              onClick={() => {
                                setcat_assets(2);
                                if (item.status == 1) {
                                  setShowModal1(true);
                                  setErrorMessage1(
                                    "The creator has been notified about your request. Ones the creator approves, you can start creating Rares."
                                  );
                                } else if (
                                  item.status == 4 ||
                                  item.status == 6
                                ) {
                                  setShowModal(true);
                                  setErrorMessage("Your Aucemo under auction");
                                } else {
                                  navigate("/actionneed", {
                                    state: {
                                      product_name: item.product_name,
                                      owner_name: item.owner_name,
                                      product_id: item.product_id,
                                      remaining_bond: item.remaining_bond,
                                      product_value: item.product_value,
                                      is_globle: item.is_globle,
                                      is_owner: item.is_owner,
                                      total_bid_amount: item.total_bid_amount,
                                      total_bid: item.total_bid,
                                    },
                                  });

                                  console.log("item", item.product_id);
                                }
                              }}
                              className={`myproduct_13_sub2_button_a ${
                                cat_assets === 2
                                  ? "myproduct_13_sub2_button_a_hover"
                                  : ""
                              }`}
                            >
                              <div>
                                {item.status == 1
                                  ? "In review"
                                  : item.status == 2 || item.status == 3
                                  ? "Action Needed "
                                  : item.status == 3
                                  ? "Reject"
                                  : item.status == 4
                                  ? "In Auction"
                                  : item.status == 5 || item.status == 7
                                  ? "Action Needed "
                                  : item.status == 6
                                  ? "In Action"
                                  : null}
                              </div>
                            </button>
                            <button
                              className={`myproduct_13_sub2_button_a ${
                                cat_assets === 3
                                  ? "myproduct_13_sub2_button_a_hover"
                                  : ""
                              }`}
                              onClick={() => {
                                setcat_assets(3);
                                navigate("/Editmyproduct", {
                                  state: {
                                    product_id: item.product_id,
                                    is_nfb: item.is_nfb,
                                    user_id: item.user_id,
                                    product_name: item.product_name,
                                    product_history: item.product_history,
                                    product_highlights: item.product_highlights,
                                    product_media_upload:
                                      item.product_media_upload,
                                    date_of_creation: item.date_of_creation,
                                    is_owner: item.is_owner,
                                    owner_name: item.owner_name,
                                    product_value: item.product_value,
                                    description: item.description,
                                    interest_data: item.interest_data,
                                  },
                                });
                              }}
                            >
                              <div>Edit</div>
                            </button>
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </>
              )}
            </div>
          ) : null}
          {/* auction products end */}

          {/* pending products start */}
          {isNfbRell == 3 ? (
            <div className="myproduct_13_card-container">
              {searchPendingProduct == 0 ? (
                <p
                  style={{
                    color: "white",
                    textAlign: "center",
                    fontFamily: "var(--font-400)",
                    alignSelf: "center",
                    width: "100%",
                  }}
                >
                  No items found.
                </p>
              ) : (
                <>
                  {searchPendingProduct.map((item, index) => (
                    <div className="myproduct_13_club_user_details" key={index}>
                      {/* <div className="card-img"> */}
                      <img
                        src={
                          item.bond_img_video[0] == undefined
                            ? images.logo
                            : item.bond_img_video[0].img_video
                        }
                        alt=""
                        className="myproduct_13_img_details"
                      />
                      {/* </div> */}
                      <div className="myproduct_13_card-content">
                        <h4 className="myproduct_13_card-title-text">
                          {" "}
                          {item.product_name}
                        </h4>
                        <div className="myproduct_13_btn_new">
                          <button
                            onClick={() => {
                              console.log("item", item);
                              navigate("/EditProduct", {
                                state: {
                                  item: item,
                                },
                              });
                            }}
                            className={`myproduct_13_sub2_button_a ${
                              cat_assets === 1
                                ? "myproduct_13_sub2_button_a_hover"
                                : ""
                            }`}
                          >
                            <div>Edit</div>
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          ) : null}
          {/* pending products end */}
        </div>

        {showModal && (
          <V2Modal
            canclebtn={true}
            title=""
            msg={errorMessage}
            setShowModal={setShowModal}
            okfunction={deletedata}
          />
        )}
        {showModal1 && (
          <V2Modal
            canclebtn={false}
            title=""
            msg={errorMessage1}
            setShowModal={setShowModal1}
            okfunction={closemodal1}
          />
        )}
      </div>
    </div>
  );
};

export default NewMyProduct;
// <div key={index} className="myproduct_13_club_user_details">
//               <img
//                 src={item.bond_img_video[0].img_video}
//                 className="myproduct_13_img_details"
//                 alt=""
//                 onClick={() => {
//                   navigate("/productView", {
//                     state: {
//                       release_bond_id: item.release_bond_id,
//                       product_id: item.product_id,
//                       is_nfb: item.is_nfb,
//                       uniq_code: item.uniq_code,
//                       theme_index: item.theme_index,
//                       share_by_user: item.share_by_user,
//                       club_id: item.club_id,
//                     },
//                   });
//                 }}
//               />
//               <div className="bid13_details_sub1">

//                 <div className="bids13_h1">
//                     <img src={images.MaskGroup54} alt="" className="bids13_profile"/>
//                     <h1 className="bids13_h11">{item.username}</h1>
//                 </div>
//                 <div className="bids13_h1_rs">
//                     <h2 className="bids13_rs">₹ {item.bid_amount}</h2>

//                 </div>

//               </div>
//       {/* <div className="bid_details_sub2"> */}
//               <div className="myproduct_13_btn_new">
//                 <button
//                 className={`myproduct_13_sub2_button_a ${bidbtn === 1 ? 'btn_hover' : ''}`}
//                   onClick={() => {
//                     handleOpenModal(item);
//                     setbidbtn(1)
//                   }}
//                 >
//                   Update Bid
//                 </button>
//                 {item.is_days == 1 ? (
//                   <button
//                   className={`myproduct_13_sub2_button_a ${bidbtn === 2 ? 'btn_hover' : ''}`}
//                   onClick={() => {
//                       setpast_bid_id(item.bid_id);
//                       handleOpenWithdrawConfirmation();
//                       setbidbtn(2)

//                     }}
//                   >
//                     Withdraw Bid
//                   </button>
//                 ) : null}
//               </div>
// </div>
