import React from "react";
import "./NewMobileOtpSuccess.css";
import images from "../../constants/images";
import { useLocation, useNavigate } from "react-router-dom";

const NewMobileOtpSuccess = () => {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <div className="new_wrapp_container reletive_position">
      <div className="new_container">
        <div className="mobile_success_main_wrapp">
          <p className="success_heading">Success!!!</p>
          <p className="success_des_text">
            Congratulation! your{" "}
            <labal
              style={{
                color: "var(--color-main)",
                fontFamily: "var(--font-600)",
              }}
            >
              +91 {location.state.phone}
            </labal>{" "}
            have been successfully authenticated!
          </p>
          <img src={images.new_success_img} className="success_img" alt="" />

          {/* btn */}
          <button
            onClick={() => {
              navigate("/NewEmailVerification", {
                state: {
                  user_id: location.state.user_id,
                  phone: location.state.phone,
                  // email: location.state?.email,
                },
              });
            }}
            className="new_btn_wrapp mx-1"
          >
            <p>Verify your email</p>
          </button>
          {/* btn */}
        </div>
      </div>
    </div>
  );
};

export default NewMobileOtpSuccess;
