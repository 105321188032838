/* eslint-disable no-unused-expressions */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import images from "../../constants/images";
import "./resellnfb.css";
import { Link, useNavigate } from "react-router-dom";
import BackButton from "../../components/BackButton";
import {
  get_all_user_Resale_product,
  add_showcase_my_collections,
} from "../../utils/Constant";
import axios from "axios";
import CusModal from "../../common/cusmodal/CusModal";
import NavbarSec from "../../common/NavbarSec";
import NewModal from "../../common/newmodal/NewModal";
import V2Modal from "../../common/v2modal/V2Modal";
import { FaAngleLeft } from "react-icons/fa";
import NewLoader from "../../common/newloder/NewLoader";

const Resellnfb = () => {
  const [activeSection, setActiveSection] = useState("resellnfb_btn_details");
  const navigate = useNavigate();
  const handleSectionChange = (section) => {
    setActiveSection(section);
  };

  const [getdata, setdata] = useState([]);
  const [getsearchdata, setsearchdata] = useState([]);
  const [loading, setloading] = useState(false);
  const [getok, setok] = useState(null);
  const userId = localStorage.getItem("user_id");
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    getReleasebond();
  }, []);

  const statusadd = () => {
    setErrorMessage(
      "Asset is in under review by NFBClub. Kindly please wait we will get back to you once verified."
    );
    setShowModal(true);
  };

  const getReleasebond = () => {
    setloading(true);
    const param = {
      owner_user_id: userId,
    };
    console.log(param);
    axios
      .post(get_all_user_Resale_product, param)
      .then((Response) => {
        console.log("acdascs", Response.data.result);
        setloading(false);
        if (Response.data.status == 1) {
          setok(0);
          setdata(Response.data.result);
          setsearchdata(Response.data.result);
        } else {
          setok(1);
          // setShowModal(true);
          // setErrorMessage(Response.data.message);
        }
        // this.setState({
        //   // data: Response.data.result,
        //   task_arrayholder: Response.data.result,
        // });
      })
      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };

  const showcasemycollection = (item) => {
    setloading(true);
    const param = {
      user_id: userId,
      bond_id: item.bond_id,
      is_nfb: item.is_nfb,
      is_club: item.is_club,
      uniq_code: item.uniq_code,
      release_bond_id: item.release_bond_id,
      is_nfb_type: 3,
    };
    console.log(param);
    axios
      .post(add_showcase_my_collections, param)
      .then((Response) => {
        setloading(false);
        console.log(
          "Response.data.result",
          JSON.stringify(Response.data.message, null, 2)
        );
        console.log(
          "Response.data.+++",
          JSON.stringify(Response.data.status, null, 2)
        );
        if (Response.data.status == 1) {
          setloading(false);
          getReleasebond();
        } else {
          getReleasebond();
        }
      })
      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };

  const searchFilter_branch = (text) => {
    const newData = getsearchdata.filter(function (item) {
      console.log("item", item.product_name);
      const employee = item.product_name
        ? item.product_name.toUpperCase()
        : "".toLowerCase();
      const number = String(item.apply_date)
        ? String(item.apply_date).toUpperCase()
        : "".toUpperCase();

      const textData = text.toUpperCase();
      return employee.indexOf(textData) > -1 || number.indexOf(textData) > -1;
    });
    setdata(newData);
    console.log("asvsvsds", newData);
  };

  const closemodal = () => {
    setShowModal(false);
  };
  const openmodal = () => {
    setShowModal(true);
  };

  return (
    <div className="page_main_wrapp_nw reletive_position">
      {loading ? (
        <NewLoader />
      ) : (
        <div className="page_base_wrapp_2">
          {/* <div className="resellnfb_header">
              <div className="resellnfb_main_header_notifi">
                <div className="resellnfbmain1">
                  <BackButton />
                </div>
                <div className="resellnfbmain2">
                  <h1 className="resellnfb_h1">Resell aucemo</h1>
                </div>
              </div>
            </div> */}
          <div
            className="legacy_dashbord_nav_sec"
            style={{ margin: "10px auto" }}
          >
            <button
              onClick={() => {
                navigate("/mynfb");
              }}
            >
              <FaAngleLeft color="var(--color-white)" size={20} />
            </button>
            <p>Resell aucemo</p>
            <div></div>
            {/* <img
                onClick={() => {
                  navigate("/CreateLegacy");
                }}
                src={images.round_plus}
                alt=""
              /> */}
          </div>

          {getok == 1 ? (
            <p className="released_nodata_text" style={{ marginTop: "20px" }}>
              We couldn't find any aucemo listed for sale by you at the moment.
            </p>
          ) : null}

          {/* <input
            onChange={(e) => {
              searchFilter_branch(e.target.value);
            }}
            type="text"
            placeholder="Search"
            className="user_resellnfb_search"
          /> */}
          <div style={{}} className="bid13_search">
            <div
              className="bids13_user_bid_main"
              style={{ paddingTop: "10px" }}
            >
              <input
                type="text"
                placeholder="Search"
                className="bids13_user_bid_search"
                // value={searchTerm}
                onChange={(e) => searchFilter_branch(e.target.value)}
              />
              {/* <img src={images.search} alt="" className="search_ic" /> */}
            </div>
          </div>

          <div className="resell_btn_list">
            <button
              className={`resellnfb_btn ${
                activeSection === "resellnfb_btn_details" ? "active" : ""
              }`}
              onClick={() => handleSectionChange("resellnfb_btn_details")}
              style={{
                backgroundColor:
                  activeSection === "resellnfb_btn_details"
                    ? "var(--color-main)"
                    : "",
              }}
            >
              Resell
            </button>
            <button
              className={`resellnfb_btn_2 ${
                activeSection === "resellnfb_btn_2_details" ? "active" : ""
              }`}
              onClick={() => handleSectionChange("resellnfb_btn_2_details")}
              style={{
                backgroundColor:
                  activeSection === "resellnfb_btn_2_details"
                    ? "var(--color-main)"
                    : "",
              }}
            >
              Panding
            </button>
          </div>
          {getdata == 0 ? (
            <>
              <div
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p
                  style={{
                    alignSelf: "center",
                    textAlign: "center",
                    fontFamily: "var(--font-600)",
                    color: "white",
                    fontSize: "14px",
                  }}
                >
                  No Items found
                </p>
              </div>
            </>
          ) : (
            <>
              {activeSection === "resellnfb_btn_details" ? (
                <>
                  <div className="resellnfb_content">
                    {getdata && getdata.length > 0
                      ? getdata.map((item, index) => {
                          return item.status == 4 ? (
                            <>
                              <div className="resellnfb_btn_details">
                                <div className="resellnfb_btn_details_main">
                                  <img
                                    src={item.bond_img_video[0].img_video}
                                    className="details_main_img"
                                    alt=""
                                  />
                                  <div className="details_of_resell">
                                    <p className="details_of_resell_h1">
                                      {item.product_name}
                                    </p>
                                    <p className="details_of_resell_p">
                                      Applied on - {item.apply_date}
                                    </p>
                                    <div className="reles_details_benefits_wrapp">
                                      {item.product_bond_benifit_detail.map(
                                        (i, index) => {
                                          return (
                                            <img
                                              key={index}
                                              src={
                                                i.reward_img_video[0].img_video
                                              }
                                              className="reles_details_single_benefit"
                                              alt=""
                                              style={{ marginRight: "10px" }}
                                            />
                                          );
                                        }
                                      )}
                                      {item.partner_bond_benifit_data.map(
                                        (i, index) => {
                                          return (
                                            <img
                                              key={index}
                                              src={i.benifit_icon}
                                              className="reles_details_single_benefit"
                                              alt=""
                                            />
                                          );
                                        }
                                      )}
                                    </div>
                                    {item.status == 4 ? (
                                      <>
                                        <div className="action_of_resell">
                                          <Link
                                            to="/Resellbidslist"
                                            state={{
                                              resale_release_id:
                                                item.resale_release_id,
                                              uniq_code: item.uniq_code,
                                            }}
                                            className="action_of_resell_btn"
                                          >
                                            Manage Bids
                                          </Link>
                                          <img
                                            onClick={() => {
                                              showcasemycollection(item);
                                            }}
                                            src={
                                              item.check_is_showcase == 1
                                                ? images.fav_new
                                                : images.fav_new_white
                                            }
                                            className="launch_nfb_white_img"
                                            alt=""
                                          />
                                        </div>
                                      </>
                                    ) : null}
                                    {item.status != 4 ? (
                                      <button
                                        onClick={() => {
                                          item.status == 1 ? statusadd() : null;
                                        }}
                                        className="action_of_resell_btn"
                                      >
                                        {item.status == 1
                                          ? "In review"
                                          : item.status == 2 || item.status == 3
                                          ? "Action Needed"
                                          : item.status == 3
                                          ? "Reject"
                                          : null}
                                      </button>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : null;
                        })
                      : null}
                  </div>
                </>
              ) : null}
              {activeSection === "resellnfb_btn_2_details" ? (
                <>
                  <div className="resellnfb_content">
                    {getdata && getdata.length > 0
                      ? getdata.map((item, index) => {
                          return (
                            <>
                              <div className="resellnfb_btn_details">
                                <div className="resellnfb_btn_details_main">
                                  <img
                                    src={
                                      item.bond_img_video.length == 0
                                        ? images.logo
                                        : item.bond_img_video[0].img_video
                                    }
                                    className="details_main_img"
                                    alt=""
                                  />
                                  <div className="details_of_resell">
                                    <p className="details_of_resell_h1">
                                      {item.product_name}
                                    </p>
                                    <p className="details_of_resell_p">
                                      Applied on - {item.apply_date}
                                    </p>
                                    <div className="reles_details_benefits_wrapp">
                                      {item.product_bond_benifit_detail.map(
                                        (i, index) => {
                                          return (
                                            <img
                                              key={index}
                                              src={
                                                i.reward_img_video[0].img_video
                                              }
                                              className="reles_details_single_benefit"
                                              alt=""
                                              style={{ marginRight: "10px" }}
                                            />
                                          );
                                        }
                                      )}
                                      {item.partner_bond_benifit_data.map(
                                        (i, index) => {
                                          return (
                                            <img
                                              key={index}
                                              src={i.benifit_icon}
                                              className="reles_details_single_benefit"
                                              alt=""
                                              style={{ marginRight: "10px" }}
                                            />
                                          );
                                        }
                                      )}
                                    </div>

                                    {item.status == 4 ? (
                                      <>
                                        <div className="action_of_resell">
                                          <Link
                                            to="/Resellbidslist"
                                            state={{
                                              resale_release_id:
                                                item.resale_release_id,
                                              uniq_code: item.uniq_code,
                                            }}
                                            className="action_of_resell_btn"
                                          >
                                            Manage Bids
                                          </Link>
                                        </div>
                                      </>
                                    ) : null}
                                    {item.status != 4 ? (
                                      <button
                                        onClick={() => {
                                          item.status == 1
                                            ? statusadd()
                                            : navigate("/ResellActionneed", {
                                                state: {
                                                  product_name:
                                                    item.product_name,
                                                  owner_name: item.owner_name,
                                                  product_id: item.product_id,
                                                  remaining_bond:
                                                    item.remaining_bond,
                                                },
                                              });
                                        }}
                                        className="action_of_resell_btn"
                                      >
                                        {item.status == 1
                                          ? "In review"
                                          : item.status == 2 || item.status == 3
                                          ? "Action Needed"
                                          : item.status == 3
                                          ? "Reject"
                                          : null}
                                      </button>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })
                      : null}
                  </div>
                </>
              ) : null}
            </>
          )}
        </div>
      )}
      {showModal && (
        // <CusModal
        //   msg={errorMessage}
        //   setShowModal={setShowModal}
        //   showModal={showModal}
        // />
        // <NewModal
        //   title=""
        //   msg={errorMessage}
        //   setShowModal={openmodal}
        //   closemodal={closemodal}
        // />

        <V2Modal
          canclebtn={false}
          title=""
          msg={errorMessage}
          setShowModal={setShowModal}
          okfunction={closemodal}
        />
      )}
    </div>
  );
};

export default Resellnfb;
