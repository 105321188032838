import React, { useEffect, useState } from "react";
import images from "../constants/images";
import { useLocation, useNavigate } from "react-router-dom";
import NavbarSec from "../common/NavbarSec";
import axios from "axios";
import qs from "qs";
import NewModal from "../common/newmodal/NewModal";
import V2Modal from "../common/v2modal/V2Modal";
import { FaAngleLeft } from "react-icons/fa";
import NewLoader from "../common/newloder/NewLoader";

const Resellbidslist = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState([]);
  const [isok, setIsOk] = useState(0);
  const navigate = useNavigate();

  const location = useLocation();

  const _getData = () => {
    const param = {
      resale_release_id: location.state.resale_release_id,
      uniq_code: location.state.uniq_code,
    };

    setIsLoading(true);

    axios
      .post(
        "https://www.ravi.host/api/get_all_resale_release_bond_bids",
        qs.stringify(param)
      )
      .then((response) => {
        console.log("res", JSON.stringify(response.data, null, 2));
        setIsLoading(false);

        if (response.data.status === 1) {
          setData(response.data.result);
          setSearch(response.data.result);
          setIsOk(1);
        } else {
          setIsOk(0);
        }
      });
  };

  useEffect(() => {
    _getData();
  }, []); // This will run _getData when the component mounts
  const userId = localStorage.getItem("user_id");
  const [getcusmodal1, setcusmodal1] = useState(false);
  const [getcusmsg1, setcusmsg1] = useState("");
  const handleAcceptBondBid = (item) => {
    setIsLoading(true);

    const param = {
      bidder_user_id: item.bidder_user_id,
      resale_release_product_id: location.state.resale_release_id,
      uniq_code: location.state.uniq_code,
      bid_id: item.bid_id,
      owner_id: userId,
      accept_bond_bid_id: item.accept_bond_bid_id,
      share_by_user: item.share_by_user,
      club_id: item.club_id,
    };

    console.log("param", param);

    axios
      .post(
        "https://www.ravi.host/api/resale_accept_bond_bid",
        qs.stringify(param)
      )
      .then((Response) => {
        console.log("param", Response.data);
        setIsLoading(false);

        if (Response.data.status === 1) {
          setcusmodal1(true);
          setcusmsg1(Response.data.message);
          _getData();
        } else {
          setcusmodal1(true);
          setcusmsg1(Response.data.message);
        }
      });
  };

  const searchFilterBranch = (text) => {
    const newData = search.filter((item) => {
      console.log("item", item.firstname);
      const employee = item.firstname
        ? item.firstname.toUpperCase()
        : "".toLowerCase();
      const number = String(item.bid_amount)
        ? String(item.bid_amount).toUpperCase()
        : "".toUpperCase();

      const textData = text.toUpperCase();
      return employee.indexOf(textData) > -1 || number.indexOf(textData) > -1;
    });
    setData(newData);
    console.log("asvsvsds", newData);
  };

  const closemodal = () => {
    setcusmodal1(false);
  };
  const openmodal = () => {
    setcusmodal1(true);
  };
  return (
    <div className="page_main_wrapp_nw reletive_position">
      {isLoading ? (
        <div
          style={{
            width: "100vw",
            height: "50vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <NewLoader />
        </div>
      ) : (
        <>
          <div className="page_base_wrapp_2">
            {/* <NavbarSec name="Aucemo" /> */}
            <div
              className="legacy_dashbord_nav_sec"
              style={{ margin: "10px auto" }}
            >
              <button
                onClick={() => {
                  navigate(-1);
                }}
              >
                <FaAngleLeft color="var(--color-white)" size={20} />
              </button>
              <p>Aucemo</p>
              <div></div>
              {/* <img
                onClick={() => {
                  navigate("/CreateLegacy");
                }}
                src={images.round_plus}
                alt=""
              /> */}
            </div>
            <div style={{}} className="bid13_search">
              <div
                className="bids13_user_bid_main"
                style={{ paddingTop: "10px" }}
              >
                <input
                  type="text"
                  placeholder="Search"
                  className="bids13_user_bid_search"
                  // value={searchTerm}
                  onChange={(e) => searchFilterBranch(e.target.value)}
                />
                {/* <img src={images.search} alt="" className="search_ic" /> */}
              </div>
            </div>
            <div className="mybidslist_details">
              {data &&
                data.map((item, index) => {
                  return (
                    <div className="mybidslist_list">
                      <center>
                        <b>₹{item.bid_amount}</b>
                        <div className="b_list_hr"></div>
                      </center>
                      <div className="mybidslist_sub">
                        <img
                          src={item.user_profile}
                          className="my_list_bis_img"
                          alt=""
                        />
                        <p className="firstname_mybidslist">{item.firstname}</p>
                        {item.is_accept == "0" ? (
                          <button
                            onClick={() => handleAcceptBondBid(item)}
                            className="btn_sub_list_my"
                          >
                            <img
                              src={images.right_green}
                              className="btn_sub_list_my_img"
                              alt=""
                            />
                            <span>Accept</span>
                          </button>
                        ) : item.is_accept == "2" ? (
                          <p>Rejected</p>
                        ) : item.is_accept == "3" ? (
                          <p>Refund</p>
                        ) : item.is_accept == "4" ? (
                          <p> Withdraw by user</p>
                        ) : (
                          <p>Accepted - Bid Rank {item.bid_rank}</p>
                        )}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          {getcusmodal1 && (
            // <div className="modal_login">
            //   <div className="modal-content_login">
            //     <div className="modal_div1">
            //       <img className="forgot_main" src={images.logo} alt="" />
            //     </div>
            //     <div className="modal_div">
            //       <b>{""}</b>
            //       <br />
            //       <p className="modal-message"> {getcusmsg1}</p>
            //       <br />
            //     </div>
            //     <hr className="hr_forgot" />
            //     <br />
            //     <div className="modal_div">
            //       <button
            //         className="close_forgot"
            //         onClick={() => {
            //           setcusmodal1(false);
            //         }}
            //       >
            //         Okay
            //       </button>
            //     </div>
            //   </div>
            // </div>

            // <NewModal
            //   title=""
            //   msg={getcusmsg1}
            //   setShowModal={openmodal}
            //   closemodal={closemodal}
            // />

            <V2Modal
              canclebtn={false}
              title=""
              msg={getcusmsg1}
              setShowModal={setcusmodal1}
              okfunction={closemodal}
            />
          )}
        </>
      )}
    </div>
  );
};
export default Resellbidslist;
