import React, { useState } from "react";
import images from "../../constants/images";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import qs from "qs";
import { ind_user_pay_con, inter_user_pay_con } from "../../utils/Constant";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useWalletContext } from "../../context/user_wallet_context";

const NewAddBalanceSucess = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [getuser_id, setUser_id] = useState("");
  const userId = localStorage.getItem("user_id");
  const [getcusmodal1, setcusmodal1] = useState(false);
  const [getcusmsg1, setcusmsg1] = useState("");
  const [getaddLoading, setAddLoading] = useState(false);

  const { getUserWalletRecharge } = useWalletContext();

  // Retrieving data from sessionStorage
  const getDataFromSessionStorage = (key) => {
    return JSON.parse(sessionStorage.getItem(key));
  };
  const storedItems = getDataFromSessionStorage("wallet_allres");
  const add_amount = getDataFromSessionStorage("add_amount");
  const allres = JSON.parse(localStorage.getItem("allres"));

  useEffect(() => {
    // console.log("allres", allres);
    // console.log("storedItems", storedItems);
    const param = {
      amount: add_amount,
      user_id: userId,
    };
    const data = getUserWalletRecharge(
      param,
      setcusmodal1,
      setcusmsg1,
      setAddLoading,
      navigate
    );
    console.log("data", data);
  }, [add_amount, userId]);

  return (
    <div className="new_wrapp_container reletive_position">
      <div className="new_container">
        <div className="mobile_success_main_wrapp">
          <p className="success_heading">Success!!!</p>
          <p className="success_des_text">
            Your cash has been successfully credited to your wallet.
          </p>
          <img src={images.new_success_img} className="success_img" alt="" />

          {/* btn */}
          <button
            // onClick={() => {
            //   navigate("/wallet");
            // }}
            onClick={() => {
              navigate("/wallet", {
                replace: true,
                state: { fromNewAddBalanceSuccess: true },
              });
            }}
            className="new_btn_wrapp mx-1"
          >
            <p>Next</p>
          </button>
          {/* btn */}
        </div>
      </div>

      <ToastContainer />
    </div>
  );
};

export default NewAddBalanceSucess;
