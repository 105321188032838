import React, { useEffect, useState } from "react";
import images from "../../constants/images";
import "./notification.css";
import BackButton from "../../components/BackButton";
import NavbarSec from "../../common/NavbarSec";
import { useHomeContext } from "../../context/home_context";
import { useNavigate } from "react-router-dom";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { useRef } from "react";

const FilterBtns = [
  {
    id: 1,
    name: "All",
  },
  {
    id: 2,
    name: "aucemo",
  },
  {
    id: 3,
    name: "Bids",
  },
  {
    id: 4,
    name: "User",
  },
  {
    id: 5,
    name: "Club",
  },
  {
    id: 6,
    name: "Benefits",
  },
];

const Notification = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [getdata, setData] = useState([]);
  const [getnotifiId, setNotifiId] = useState("All");
  const [isLoading, setIsLoading] = useState(false);
  const { getAllNotification, is_loading, notification_data } =
    useHomeContext();
  const userId = localStorage.getItem("user_id");
  const navigate = useNavigate();

  const AllNotificationApi = async (name) => {
    const params = {
      user_id: JSON.parse(userId),
      type: name,
    };
    console.log("notification param", params);

    const data = await getAllNotification(params);
    if (data.status == 1) {
      // setAllIntrest(data.user_interest);
      setData(data.result);
    } else {
      setData([]);
    }
    console.log("notification", data);
  };

  useEffect(() => {
    AllNotificationApi("All");
  }, []);

  // Array of notification data (replace this with your actual data)
  const notificationData = [
    {
      username_msg: "Protect Asset",
      message: "Your asset has been created successfully!",
      timestamp: "22h",
    },
    // Add more notification data objects as needed
  ];

  // Function to handle changes in the search input
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Filter notifications based on the search term
  const filteredNotifications = getdata?.filter((notification) =>
    notification.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const sliderRef = useRef(null);

  const scrollLeft = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({
        left: -200,
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({
        left: 200,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="page_main_wrapp">
      <div className="page_base_wrapp_2">
        <div className="notification_header">
          {/* <div className="main_header_notifi">
            <div className="div_main1">
              <BackButton />
            </div>
            <div className="div_main2">
              <h1 className="main_h1">Notification</h1>
            </div>
          </div> */}
          {/* <NavbarSec name="Notification" /> */}
          <div
            style={{ width: "100%", margin: "10px auto", maxWidth: "920px" }}
          >
            {/* <NavbarSec name="Wishlist" /> */}
            <div
              className="legacy_dashbord_nav_sec"
              style={{ margin: "10px auto" }}
            >
              <button
                onClick={() => {
                  navigate(-1);
                }}
              >
                <FaAngleLeft color="var(--color-white)" size={20} />
              </button>
              <p>Notification</p>
              <div></div>
            </div>
          </div>
          {/* <br /> */}
          <div style={{ width: "100%" }} className="bid13_search">
            <div
              className="bids13_user_bid_main"
              style={{ paddingTop: "10px" }}
            >
              <input
                type="text"
                placeholder="Search"
                className="bids13_user_bid_search"
                // value={searchTerm}
                onChange={handleSearchChange}
              />
              {/* <img src={images.search} alt="" className="search_ic" /> */}
            </div>
          </div>
          <div style={{ position: "relative", width: "100%" }}>
            <button
              className="nav-button left"
              onClick={scrollLeft}
              style={{ padding: "10px 0px", width: "24px" }}
            >
              <FaAngleLeft color="var(--color-main)" size={22} />
            </button>
            <div
              className="user_notification_tabs_wrapp"
              ref={sliderRef}
              style={{ padding: "6px 1.5rem" }}
            >
              {FilterBtns.map((item, index) => {
                return (
                  <button
                    className="user_notification_single_tab"
                    key={index}
                    onClick={() => {
                      setNotifiId(item.name);
                      AllNotificationApi(item.name);
                    }}
                    style={{
                      background:
                        item.name == getnotifiId ? "var(--color-main)" : "#000",
                      borderColor:
                        item.name == getnotifiId ? "var(--color-main)" : "#fff",
                    }}
                  >
                    {item.name}
                  </button>
                );
              })}
            </div>
            <button
              className="nav-button right"
              onClick={scrollRight}
              style={{
                justifyContent: "flex-end",
                padding: "10px 0px",
                width: "24px",
              }}
            >
              <FaAngleRight color="var(--color-main)" size={22} />
            </button>
          </div>
        </div>
        {is_loading ? (
          <div className="loader" style={{ margin: "20px auto" }}></div>
        ) : (
          <>
            {filteredNotifications.map((item, index) => (
              <>
                <div
                  onClick={() => {
                    if (item.type == "bond_like") {
                      navigate("/productview", {
                        state: {
                          release_bond_id: item.release_bond_id,
                          product_id: item.product_id,
                          is_nfb: item.is_nfb,
                          uniq_code: item.uniq_code,
                          theme_index: item.theme_index,
                          share_by_user: item.share_by_user,
                          club_id: item.club_id,
                        },
                      });
                    } else if (item.type == "club_Join_request") {
                      navigate("/Clubprofile", {
                        state: {
                          club_id: item.club_id,
                        },
                      });
                    } else if (item.type === "user_community_request") {
                      navigate("/NewCommunityRequest");
                    } else if (item.type === "user_family_request") {
                      navigate("/NewCommunityRequest");
                    } else if (item.type === "partner_benifits") {
                      navigate("/SpecialBenefits");
                    } else if (item.type === "create_bond") {
                      navigate("/myproduct");
                    } else if (
                      item.type === "release_bond" ||
                      item.type === "bid"
                    ) {
                      navigate("/releasednfb");
                    } else if (item.type === "accept_bid") {
                      navigate("/purchasednfbs");
                    } else if (item.type === "bond_request") {
                      navigate("/ReceivedNfbs");
                    } else if (item.type === "club_manager_request") {
                      navigate("/ClubMamberRequsts");
                    } else if (item.type === "resale_royalty") {
                      navigate("/wallet");
                    } else if (item.type === "resale_release_bond") {
                      navigate("/resellnfb");
                    } else if (item.type === "club_release_bond") {
                      navigate("/clubreleasednfb", {
                        state: { club_id: item.club_id },
                      });
                    } else if (item.type === "benefit") {
                      navigate("/SpecialBenefits");
                    } else if (item.type === "partner_benifits") {
                      navigate("/SpecialBenefits");
                    } else if (item.type === "create_club_bond") {
                      navigate("/clubmyproduct", {
                        state: { club_id: item.club_id },
                      });
                    } else if (item.type === "remaining_rarerez") {
                      navigate("/myproduct");
                    }
                    //  else if (item.type === "create_bond") {
                    //   navigate("/myproduct");
                    // }
                  }}
                  className="notifi_details"
                  key={index}
                >
                  <div className="notification_img">
                    <img
                      src={item.profile !== "" ? item.profile : images.media5}
                      className="img_notification"
                      alt=""
                    />
                  </div>
                  <div className="notification_nm">
                    <p
                      className="username_msg"
                      style={{
                        fontSize: "14px",
                        fontFamily: "var(--font-600)",
                        color: "var(--color-white)",
                      }}
                    >
                      {item.title}
                    </p>
                    <p
                      style={{
                        fontSize: "12px",
                        fontFamily: "var(--font-400)",
                        color: "var(--color-gray)",
                      }}
                    >
                      {item.body}
                    </p>
                    <p
                      style={{
                        fontSize: "12px",
                        fontFamily: "var(--font-400)",
                        color: "var(--color-white)",
                      }}
                    >
                      {item.date_time}
                    </p>
                  </div>
                </div>
              </>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default Notification;
