import React, { useEffect, useRef, useState } from "react";
import NavbarSec from "../../common/NavbarSec";
import "./FreeBenefit.css";
import images from "../../constants/images";
import BondCard from "../../components/bondcard/BondCard";
import SimpleImageSlider from "react-simple-image-slider";
import Slider from "react-slick";
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";
import axios from "axios";
import {
  conform_benifits,
  free_partner_bond_benifit_1,
} from "../../utils/Constant";
import qs from "qs";
import { useNavigate } from "react-router-dom";
import NewLoader from "../../common/newloder/NewLoader";
import { ToastContainer, toast } from "react-toastify";

const FreeBenefit = () => {
  const userId = localStorage.getItem("user_id");
  const navigate = useNavigate();
  const [getdata, setData] = useState([]);
  const [getloading, setLoading] = useState(false);
  const sliderRef = useRef(null);
  const settings = {
    // dots: false,
    // infinite: true,
    // speed: 500,
    // slidesToShow: 1.2,
    // slidesToScroll: 1,
    // className: "center",
    // centerMode: true,
    // centerPadding: "60px",

    //dots: true,
    infinite: true,
    // centerMode: true,
    // centerPadding: "8%",
    slidesToShow: 1,
    speed: 500,
  };

  const [currentSlide, setCurrentSlide] = useState(getdata[0]);

  const FreeBenefitApi = async () => {
    setLoading(true);
    const param = {
      user_id: userId,
    };
    await axios
      .post(free_partner_bond_benifit_1, qs.stringify(param))
      .then((Response) => {
        setLoading(false);
        // console.log("Response111a", Response.data);
        console.log("Response", Response);
        // if (Response.data.status == 1) {
        setData(Response.data);
        setCurrentSlide(Response.data[0]);
        // } else {
        // }
      })
      .catch((error) => {
        setLoading(false);
        console.log("error", error);
      });
  };

  useEffect(() => {
    FreeBenefitApi();
  }, []);

  const sliderImages = [
    {
      url: images.hr_img,
      title: "Slide 1",
      description: "Description for slide 1",
      cardSets: [
        {
          img: images.media1,
          name: "Product Name 1",
          owner_name: "JalpeshAhir",
          owner_img: images.mask_profile,
        },
        {
          img: images.media2,
          name: "Product Name 2",
          owner_name: "AnotherOwner",
          owner_img: images.mask_profile,
        },
        {
          img: images.media3,
          name: "Product Name 3",
          owner_name: "ThirdOwner",
          owner_img: images.mask_profile,
        },
      ],
    },
    {
      url: images.vk_img,
      title: "Slide 2",
      description: "Description for slide 2",
      cardSets: [
        {
          img: images.media1,
          name: "Product Name 4",
          owner_name: "JalpeshAhir 2",
          owner_img: images.mask_profile,
        },
        {
          img: images.media2,
          name: "Product Name 5",
          owner_name: "AnotherOwner 2",
          owner_img: images.mask_profile,
        },
        {
          img: images.media3,
          name: "Product Name 6",
          owner_name: "ThirdOwner 2",
          owner_img: images.mask_profile,
        },
      ],
    },
    {
      url: images.ak_img,
      title: "Slide 3",
      description: "Description for slide 1",
      cardSets: [
        {
          img: images.media1,
          name: "Product Name 7",
          owner_name: "JalpeshAhir 3",
          owner_img: images.mask_profile,
        },
        {
          img: images.media2,
          name: "Product Name 8",
          owner_name: "AnotherOwner 3",
          owner_img: images.mask_profile,
        },
        {
          img: images.media3,
          name: "Product Name 9",
          owner_name: "ThirdOwner 3",
          owner_img: images.mask_profile,
        },
      ],
    },
    // ... other slides
  ];

  const handleAfterChange = (index) => {
    console.log("After change to index:", index);
    setCurrentSlide(getdata[index]);
    console.log("currentSlide", currentSlide);
  };
  const handleNextSlide = () => {
    sliderRef.current.slickNext();
  };

  const handlePrevSlide = () => {
    sliderRef.current.slickPrev();
  };

  const ConfirmBenefit = async (id) => {
    setLoading(true);
    const param = {
      user_id: userId,
      partner_benifit_id: id,
    };
    await axios
      .post(conform_benifits, qs.stringify(param))
      .then((Response) => {
        setLoading(false);
        // console.log("Response111a", Response.data);
        console.log("Response", Response);
        if (Response.data.status == 1) {
          navigate("/SpecialBenefits");
          toast.success(Response.data.message, { theme: "colored" });
        } else {
          toast.error(Response.data.message, { theme: "colored" });
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log("error", error);
      });
  };

  return (
    <div className="page_main_wrapp">
      <>
        <div className="page_base_wrapp_2">
          <NavbarSec name="Collect & Win" />
          {getloading ? (
            <NewLoader />
          ) : (
            <div className="fb_main_wrapp">
              <p className="fb_sec_title">Win</p>
              <div className="image-slider-container">
                <div className="slider-btn-div">
                  <button onClick={handlePrevSlide}>
                    {/* <SlArrowLeft className="free-btn-icon" /> */}
                    <img
                      src={images.new_sa_left}
                      alt=""
                      className="free-btn-icon"
                    />
                  </button>
                </div>

                <Slider
                  {...settings}
                  ref={sliderRef}
                  afterChange={handleAfterChange}
                  className="slider-benefit"
                >
                  {getdata.map((slide, index) => {
                    console.log("slide", slide);
                    return (
                      <>
                        {/* <div key={index} className="custom-slide"> */}
                        {/* <img src={slide.benifit_icon} alt={slide.benifit_name} />
                      <div className="slide-info">
                        <h3>111</h3>
                        <p>1111</p>
                      </div> */}
                        <div className="fb_banner_wrapp">
                          <div>
                            <p>{slide.benifit_name}</p>
                          </div>
                          <img
                            src={
                              slide.benifit_icon &&
                              slide.benifit_icon[0]?.img_video
                            }
                            alt={slide.benifit_name}
                          />
                        </div>
                        {/* </div> */}
                      </>
                    );
                  })}
                </Slider>
                <div className="slider-btn-div2">
                  <button onClick={handleNextSlide}>
                    {/* <SlArrowRight className="free-btn-icon" /> */}
                    <img
                      src={images.new_sa_right}
                      alt=""
                      className="free-btn-icon"
                    />
                  </button>
                </div>
              </div>

              <p className="fb_sec_title">Collect</p>

              <div className="fb_cards_wrapp">
                {currentSlide &&
                  currentSlide.free_bond.map((cardSet, cardIndex) => (
                    <BondCard
                      key={cardIndex}
                      img={cardSet.product_image_video[0]?.img_video}
                      name={cardSet.product_name}
                      owner_name={cardSet.username}
                      owner_img={cardSet.profile}
                      data={cardSet}
                    />
                  ))}
              </div>

              {/* <p>asfsd</p> */}

              {currentSlide?.user_buy_bond == 1 ? (
                <button
                  onClick={() =>
                    ConfirmBenefit(currentSlide.partner_benifit_id)
                  }
                  className="free_benefit_confirm_benefit_btn"
                >
                  Confirm this benefit
                </button>
              ) : null}
            </div>
          )}
        </div>
      </>
      <ToastContainer />
    </div>
  );
};

export default FreeBenefit;
