import React, { useEffect, useRef, useState } from "react";
import "./Resellnewattach.css";
import images from "../../constants/images";
import NavbarSec from "../../common/NavbarSec";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { preview_product, release_bond } from "../../utils/Constant";
import axios from "axios";
import qs from "qs";
import Resellcardblue from "./Resellcardblue";
import Resellcardred from "./Resellcardred";
import Resellcardyellow from "./resellcardyellow";
import CusModal from "../../common/cusmodal/CusModal";
import AlertModal from "../../components/AlertModal";
import Resellcardfour from "./Resellnewfour";
import Resellnewfour from "./Resellnewfour";
import Resellcardfive from "./Resellcardfive";
import Resellcardsix from "./Resellcardsix";
import Resellcardseven from "./Resellcardseven";
import Resellcardeight from "./Resellcardeight";
import Resellcard13 from "./Resellcard13";
import Resellcard14 from "./Rasellcard14";
import Resellcard15 from "./Resellcard15";
import NewModal from "../../common/newmodal/NewModal";
import ReactModal from "react-modal";
import NewResellNfbFrame1 from "../../frames/resellframes/NewResellNfbFrame1";
import NewResellNfbFrame2 from "../../frames/resellframes/NewResellNfbFrame2";
import NewResellNfbFrame3 from "../../frames/resellframes/NewResellNfbFrame3";
import NewResellNfbFrame4 from "../../frames/resellframes/NewResellNfbFrame4";
import NewResellNfbFrame5 from "../../frames/resellframes/NewResellNfbFrame5";
import NewResellNfbFrame6 from "../../frames/resellframes/NewResellNfbFrame6";
import NewResellNfbFrame7 from "../../frames/resellframes/NewResellNfbFrame7";
import NewResellNfbFrame8 from "../../frames/resellframes/NewResellNfbFrame8";
import NewResellNfbFrame9 from "../../frames/resellframes/NewResellNfbFrame9";
import NewResellNfbFrame10 from "../../frames/resellframes/NewResellNfbFrame10";
import V2Modal from "../../common/v2modal/V2Modal";
import { ToastContainer, toast } from "react-toastify";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

const Resellnewattach = () => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "0px",
      backgroundColor: "none",
      border: "none",
      borderRadius: "12px",
    },
    overlay: {
      zIndex: 1000,
      backgroundColor: "rgba(0, 0, 0, 0.3)",
    },
  };
  const navigate = useNavigate();
  const ttt = localStorage.getItem("reselltheme_index");
  const fffff = localStorage.getItem("reselltheme_frame");
  const [selectedFrame, setSelectedFrame] = useState(
    fffff == "" ||
      fffff == null ||
      fffff == "null" ||
      fffff == "undefined" ||
      fffff == undefined
      ? "frames12"
      : fffff
  );
  const [getsearchdata, setsearchdata] = useState([]);
  const [loading, setloading] = useState(false);
  const [getok, setok] = useState(null);
  const userId = localStorage.getItem("user_id");
  const [showModal, setShowModal] = useState(false);
  const [showinfoModal, setShowInfoModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [no_of_bond, setno_of_bond] = useState("");

  const [ismodalOpen, setIsModalOpen] = useState(false);
  const [getmsg, setMsg] = useState("");
  const [getmodalName, setModalName] = useState("");

  const handleFrameClick = (frame) => {
    setSelectedFrame(frame);
  };

  // const carddata = () => {

  // }

  const [getdata, setdata] = useState([]);

  const location = useLocation();

  useEffect(() => {
    console.log("acaca", ttt, fffff);
    Previewproduct();
    // ReleaseProduct();
    GetAllData();
  }, []);

  const [qr_code, setqr_code] = useState("");
  const [owner_name, setowner_name] = useState("");
  const [creator_name, setcreator_name] = useState("");
  const [product_name, setproduct_name] = useState("");
  const [description, setdescription] = useState("");
  const [created_at, setcreated_at] = useState("");
  const [no_of_release_bond, setno_of_release_bond] = useState("");
  const [max_bond, setmax_bond] = useState(1);
  const [theme_index, settheme_index] = useState(
    ttt == "" ||
      ttt == null ||
      ttt == "null" ||
      ttt == "undefined" ||
      ttt == undefined
      ? 16
      : ttt
  );
  const [assign_no_of_bond, setassign_no_of_bond] = useState(0);
  const [remaining_bond_release_date, setremaining_bond_release_date] =
    useState("");
  const [product_value, setproduct_value] = useState("");
  const [product_img_video_carosel, setproduct_img_video_carosel] =
    useState("");
  const [product_img_video_carosel3, setproduct_img_video_carosel3] =
    useState("");

  const [available_no_of_bond, setavailable_no_of_bond] = useState(0);
  const [attched_benifit_data, setattched_benifit_data] = useState([]);
  const [bond_img_id, setbond_img_id] = useState("");
  const [img_index, set_img_index] = useState(0);

  const Previewproduct = () => {
    setloading(true);
    const param = {
      resale_product_id: location.state.product_id,
      // user_id: userId,
    };
    console.log("param------>", param);
    axios
      .post("https://www.ravi.host/api/resale_preview_product", param)
      .then((Response) => {
        console.log(
          "resale_preview_product data",
          JSON.stringify(Response.data.result, null, 2)
        );
        const data = Response.data.result;
        setloading(false);
        if (Response.data.status == 1) {
          setdata(data);
          setqr_code(data.qr_code);
          setowner_name(data.owner_name);
          setcreator_name(data.creator_name);
          setproduct_name(data.product_name);
          setdescription(data.description);
          setcreated_at(data.created_at);
          setno_of_release_bond(data.no_of_release_bond);
          setremaining_bond_release_date(data.remaining_bond_release_date);
          setproduct_value(data.product_value);
          setproduct_img_video_carosel(data.product_img_video);
          setproduct_img_video_carosel3(data.product_img_video[0].product_icon);
          setbond_img_id(data.product_img_video[0].bond_img_id);
          var demo = [];
          for (let index = 0; index < data.product_img_video.length; index++) {
            demo = [
              ...product_img_video_carosel,
              data.product_img_video[index].product_icon,
            ];
          }
          console.log("demo", demo);
          setproduct_img_video_carosel3(demo);
        } else {
          // setok(1);
        }
        // this.setState({
        //   // data: Response.data.result,
        //   task_arrayholder: Response.data.result,
        // });
      })
      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };

  let selectedCardComponent;

  if (getdata) {
    // if (selectedFrame === "frames1") {
    //   selectedCardComponent = (
    //     <Resellcardyellow
    //       data={getdata}
    //       icon={product_img_video_carosel3}
    //       setModalName={setModalName}
    //       setIsModalOpen={setIsModalOpen}
    //       setMsg={setMsg}
    //     />
    //   );
    // } else if (selectedFrame === "frames2") {
    //   selectedCardComponent = (
    //     <Resellcardred
    //       data={getdata}
    //       icon={product_img_video_carosel3}
    //       setModalName={setModalName}
    //       setIsModalOpen={setIsModalOpen}
    //       setMsg={setMsg}
    //     />
    //   );
    // } else if (selectedFrame === "frames4") {
    //   selectedCardComponent = (
    //     <Resellnewfour
    //       data={getdata}
    //       icon={product_img_video_carosel3}
    //       setModalName={setModalName}
    //       setIsModalOpen={setIsModalOpen}
    //       setMsg={setMsg}
    //     />
    //   );
    // } else if (selectedFrame === "frames5") {
    //   selectedCardComponent = (
    //     <Resellcardfive
    //       data={getdata}
    //       icon={product_img_video_carosel3}
    //       setModalName={setModalName}
    //       setIsModalOpen={setIsModalOpen}
    //       setMsg={setMsg}
    //     />
    //   );
    // } else if (selectedFrame === "frames6") {
    //   selectedCardComponent = (
    //     <Resellcardsix
    //       data={getdata}
    //       icon={product_img_video_carosel3}
    //       setModalName={setModalName}
    //       setIsModalOpen={setIsModalOpen}
    //       setMsg={setMsg}
    //     />
    //   );
    // } else if (selectedFrame === "frames7") {
    //   selectedCardComponent = (
    //     <Resellcardseven
    //       data={getdata}
    //       icon={product_img_video_carosel3}
    //       setModalName={setModalName}
    //       setIsModalOpen={setIsModalOpen}
    //       setMsg={setMsg}
    //     />
    //   );
    // } else if (selectedFrame === "frames8") {
    //   selectedCardComponent = (
    //     <Resellcardeight
    //       data={getdata}
    //       icon={product_img_video_carosel3}
    //       setModalName={setModalName}
    //       setIsModalOpen={setIsModalOpen}
    //       setMsg={setMsg}
    //     />
    //   );
    // } else if (selectedFrame === "frames9") {
    //   selectedCardComponent = (
    //     <Resellcard13
    //       data={getdata}
    //       icon={product_img_video_carosel3}
    //       setModalName={setModalName}
    //       setIsModalOpen={setIsModalOpen}
    //       setMsg={setMsg}
    //     />
    //   );
    // } else if (selectedFrame === "frames10") {
    //   selectedCardComponent = (
    //     <Resellcard14
    //       data={getdata}
    //       icon={product_img_video_carosel3}
    //       setModalName={setModalName}
    //       setIsModalOpen={setIsModalOpen}
    //       setMsg={setMsg}
    //     />
    //   );
    // } else if (selectedFrame === "frames11") {
    //   selectedCardComponent = (
    //     <Resellcard15
    //       data={getdata}
    //       icon={product_img_video_carosel3}
    //       setModalName={setModalName}
    //       setIsModalOpen={setIsModalOpen}
    //       setMsg={setMsg}
    //     />
    //   );
    // } else
    if (selectedFrame === "frames12") {
      selectedCardComponent = (
        <NewResellNfbFrame1
          data={getdata}
          icon={product_img_video_carosel3}
          setModalName={setModalName}
          setIsModalOpen={setIsModalOpen}
          setMsg={setMsg}
        />
      );
    } else if (selectedFrame === "frames13") {
      selectedCardComponent = (
        <NewResellNfbFrame2
          data={getdata}
          icon={product_img_video_carosel3}
          setModalName={setModalName}
          setIsModalOpen={setIsModalOpen}
          setMsg={setMsg}
        />
      );
    } else if (selectedFrame === "frames14") {
      selectedCardComponent = (
        <NewResellNfbFrame3
          data={getdata}
          icon={product_img_video_carosel3}
          setModalName={setModalName}
          setIsModalOpen={setIsModalOpen}
          setMsg={setMsg}
        />
      );
    } else if (selectedFrame === "frames15") {
      selectedCardComponent = (
        <NewResellNfbFrame4
          data={getdata}
          icon={product_img_video_carosel3}
          setModalName={setModalName}
          setIsModalOpen={setIsModalOpen}
          setMsg={setMsg}
        />
      );
    } else if (selectedFrame === "frames16") {
      selectedCardComponent = (
        <NewResellNfbFrame5
          data={getdata}
          icon={product_img_video_carosel3}
          setModalName={setModalName}
          setIsModalOpen={setIsModalOpen}
          setMsg={setMsg}
        />
      );
    } else if (selectedFrame === "frames17") {
      selectedCardComponent = (
        <NewResellNfbFrame6
          data={getdata}
          icon={product_img_video_carosel3}
          setModalName={setModalName}
          setIsModalOpen={setIsModalOpen}
          setMsg={setMsg}
        />
      );
    } else if (selectedFrame === "frames18") {
      selectedCardComponent = (
        <NewResellNfbFrame7
          data={getdata}
          icon={product_img_video_carosel3}
          setModalName={setModalName}
          setIsModalOpen={setIsModalOpen}
          setMsg={setMsg}
        />
      );
    } else if (selectedFrame === "frames19") {
      selectedCardComponent = (
        <NewResellNfbFrame8
          data={getdata}
          icon={product_img_video_carosel3}
          setModalName={setModalName}
          setIsModalOpen={setIsModalOpen}
          setMsg={setMsg}
        />
      );
    } else if (selectedFrame === "frames20") {
      selectedCardComponent = (
        <NewResellNfbFrame9
          data={getdata}
          icon={product_img_video_carosel3}
          setModalName={setModalName}
          setIsModalOpen={setIsModalOpen}
          setMsg={setMsg}
        />
      );
    } else if (selectedFrame === "frames21") {
      selectedCardComponent = (
        <NewResellNfbFrame10
          data={getdata}
          icon={product_img_video_carosel3}
          setModalName={setModalName}
          setIsModalOpen={setIsModalOpen}
          setMsg={setMsg}
        />
      );
    }
  }

  // get all data start

  const GetAllData = () => {
    setattched_benifit_data([]);
    const param = {
      resale_product_id: location.state.product_id,
      user_id: userId,
    };
    console.log("Getting", param);
    axios
      .post(
        "https://www.ravi.host/api/list_of_resale_product_no_of_bond_assign_benifit",
        qs.stringify(param)
      )
      .then((Response) => {
        if (Response.data.status == 1) {
          setattched_benifit_data(Response.data.result);
          setavailable_no_of_bond(0);
          if (attched_benifit_data.length === 1) {
            setavailable_no_of_bond(0);
          }
        } else {
          setavailable_no_of_bond(1);
        }
      });
  };

  // get all data end

  // release product start

  // const ReleaseProduct = () => {
  //   const param = {
  //     product_id: location.state.product_id,
  //     user_id: userId,
  //   };

  //   axios
  //     .post(
  //       "https://www.ravi.host/api/get_no_of_release_bond_product",
  //       qs.stringify(param)
  //     )
  //     .then((Response) => {
  //       console.log(
  //         "====================================release product,",
  //         JSON.stringify(Response.data, null, 2)
  //       );

  //       if (Response.data.status == 1) {
  //         // setavailable_no_of_bond(Response.data.result.available_no_of_bond);
  //         setassign_no_of_bond(Response.data.result.assign_no_of_bond);
  //         // this.setState({
  //         //   max_bond: Response.data.result.no_of_release_bond,
  //         //   available_no_of_bond: Response.data.result.available_no_of_bond,
  //         //   assign_no_of_bond: Response.data.result.assign_no_of_bond,
  //         //   // description:
  //         // });
  //       } else {
  //         // this.setState({
  //         //   isModalVisible: true,
  //         //   cusmsg: Response.data.message,
  //         // });
  //         // setIsModalOpen(true);
  //         // setMsg(Response.data.message);
  //       }
  //     });
  // };

  // release product end

  // enter nfbs modal logic start
  const BenefitNFBs = () => {
    const isValid = /^\d+$/.test(no_of_bond);

    if (no_of_bond == 0) {
      // setIsModalOpen(true);
      // setModalName("Please enter number of aucemo");
      toast.error("Please enter number of aucemo", {
        theme: "colored",
      });
    } else if (no_of_bond < 0) {
      // alert("Enter bond value below release value of bond");
      // setIsModalOpen(true);
      // setModalName("Value is invalid");
      toast.error("Value is invalid", {
        theme: "colored",
      });
    } else if (!isValid) {
      // alert("Enter bond value below release value of bond");
      setno_of_bond("");
      // setIsModalOpen(true);
      // setModalName("Please enter a valid number of aucemo");
      toast.error("Please enter a valid number of aucemo", {
        theme: "colored",
      });
    } else if (no_of_bond > available_no_of_bond) {
      // alert("Enter bond value below release value of bond");
      // setIsModalOpen(true);
      // setModalName("Please enter a number below the available rare count");
      toast.error("Please enter a number below the available rare count", {
        theme: "colored",
      });
    } else if (available_no_of_bond != 0) {
      navigate("/ResellAttachbenefit", {
        state: {
          product_id: location.state.product_id,
          number_of_bond: no_of_bond,
        },
      });
    } else {
      // alert("You have already attached benefits to all your aucemo.");
      toast.error("You have already attached benefits to all your aucemo.", {
        theme: "colored",
      });
    }
  };

  // enter nfbs modal logic end

  // place bid start

  const releaseBond = () => {
    setIsLoading(true);

    if (available_no_of_bond == 0) {
      setShowInfoModal(false);

      const param = {
        resale_product_id: location.state.product_id,
        user_id: userId,
        no_of_release_bond: max_bond,
        theme_index: theme_index,
        // bond_img_id: bond_img_id,
      };

      console.log("ascsac", param);
      // setIsLoading(true);

      axios
        .post(
          "https://www.ravi.host/api/resale_release_bond",
          qs.stringify(param)
        )
        .then((Response) => {
          setIsLoading(false);
          if (Response.data.status === 1) {
            localStorage.removeItem("reselltheme_index");
            localStorage.removeItem("reselltheme_frame");
            // props.navigation.replace("BottomTabNavigator", {
            //   user_id: userId,
            // });
            navigate("/homescreen2");
          } else {
            // setCusmsg(Response.data.message);
            // setIsModalVisible(true);
            toast.error(Response.data.message, {
              theme: "colored",
            });
          }
        });
    } else {
      setShowInfoModal(false);
      const chars =
        "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
      let randomString = "";
      for (let i = 0; i < 4; i++) {
        const randomIndex = Math.floor(Math.random() * chars.length);
        randomString += chars.charAt(randomIndex);
      }

      const param = {
        resale_product_id: location.state.product_id,
        no_of_bond: "1",
        user_id: userId,
        uniq_code: randomString,
        theme_index: theme_index,
        // bond_img_id: bond_img_id,
      };
      console.log("param--->", param);
      // setIsLoading(true);
      axios
        .post(
          "https://www.ravi.host/api/resale_release_bond_without_benifit",
          qs.stringify(param)
        )
        .then((Response) => {
          // setIsModalVisible(false);
          if (Response.data.status === 1) {
            const param = {
              resale_product_id: location.state.product_id,
              user_id: userId,
              no_of_release_bond: max_bond,
              theme_index: theme_index,
              // bond_img_id: bond_img_id,
            };

            setIsLoading(true);

            axios
              .post(
                "https://www.ravi.host/api/resale_release_bond",
                qs.stringify(param)
              )
              .then((Response) => {
                setIsLoading(false);
                if (Response.data.status === 1) {
                  // props.navigation.replace("BottomTabNavigator", {
                  //   user_id: props.user_id,
                  // });
                  navigate("/homescreen2");
                } else {
                  // setCusmsg(Response.data.message);
                  // setIsModalVisible(true);
                  // setIsModalOpen(true);
                  // setMsg(Response.data.message);
                  toast.error(Response.data.message, {
                    theme: "colored",
                  });
                }
              });
          } else {
            // setCusmsg(Response.data.message);
            // setIsModalVisible(true);
            // setIsModalOpen(true);
            // setMsg(Response.data.message);
            toast.error(Response.data.message, {
              theme: "colored",
            });
          }
        });
    }
  };

  // const releaseBond = () => {
  //   setIsLoading(true);

  //   if (available_no_of_bond == 0) {
  //     const param = {
  //       product_id: location.state.product_id,
  //       user_id: userId,
  //       no_of_release_bond: max_bond,
  //       theme_index: theme_index,
  //       bond_img_id: bond_img_id,
  //     };

  //     console.log("params1---->", param);

  //     axios
  //       .post(
  //         release_bond
  //         // qs.stringify(param)
  //       )
  //       .then((Response) => {
  //         setIsLoading(false);
  //         console.log("Response.data", Response.data);
  //         if (Response.data.status === 1) {
  //           // props.navigation.replace("BottomTabNavigator", {
  //           //   user_id: props.user_id,
  //           // });
  //         } else {
  //           // setCusmsg(Response.data.message);
  //           // setIsModalVisible(true);
  //         }
  //       });
  //   } else {
  //     const chars =
  //       "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ!@#$%^&*()_+~`|}{[]:;?><,.=";
  //     let randomString = "";
  //     for (let i = 0; i < 4; i++) {
  //       const randomIndex = Math.floor(Math.random() * chars.length);
  //       randomString += chars.charAt(randomIndex);
  //     }

  //     const param = {
  //       product_id: location.state.product_id,
  //       no_of_release_bond: available_no_of_bond,
  //       user_id: userId,
  //       uniq_code: randomString,
  //       theme_index: theme_index,
  //       bond_img_id: bond_img_id,
  //     };
  //     console.log("params2---->", param);

  //     axios
  //       .post(
  //         "https://www.ravi.host/api/release_bond_without_benifit"
  //         // qs.stringify(param)
  //       )
  //       .then((Response) => {
  //         // setIsModalVisible(false);
  //         if (Response.data.status === 1) {
  //           const param = {
  //             product_id: location.state.product_id,
  //             user_id: userId,
  //             no_of_release_bond: max_bond,
  //             theme_index: theme_index,
  //             bond_img_id: bond_img_id,
  //           };
  //           console.log("params3---->", param);

  //           setIsLoading(true);

  //           axios
  //             .post(
  //               "https://www.ravi.host/api/release_bond"
  //               // qs.stringify(param)
  //             )
  //             .then((Response) => {
  //               setIsLoading(false);
  //               if (Response.data.status === 1) {
  //                 // props.navigation.replace("BottomTabNavigator", {
  //                 //   user_id: user,
  //                 // });
  //                 navigate("/homescreen2");
  //               } else {
  //                 // setCusmsg(Response.data.message);
  //                 // setIsModalVisible(true);
  //               }
  //             });
  //         } else {
  //           // setCusmsg(Response.data.message);
  //           // setIsModalVisible(true);
  //         }
  //       });
  //   }
  // };

  // place bid end

  //disable body scrolling

  const [bodyScrollEnabled, setBodyScrollEnabled] = useState(true);

  useEffect(() => {
    if (ismodalOpen || showinfoModal) {
      // Disable body scrolling
      setBodyScrollEnabled(false);
    } else {
      // Enable body scrolling
      setBodyScrollEnabled(true);
    }
    if (bodyScrollEnabled) {
      document.body.style.overflow = "visible";
    } else {
      document.body.style.overflow = "hidden";
    }
  }, [ismodalOpen, showinfoModal, bodyScrollEnabled]);

  const closemodal1 = () => {
    setShowInfoModal(false);
  };

  const closemodal = () => {
    setIsModalOpen(false);
  };
  const openmodal = () => {
    setIsModalOpen(true);
  };

  const sliderRef = useRef(null);

  const scrollLeft = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({
        left: -200,
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({
        left: 200,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="page_main_wrapp">
      <div className="page_base_wrapp_2">
        {/* <NavbarSec name="Create aucemo" /> */}
        <div
          style={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "30px",
            background: "black",
            zIndex: 11,
          }}
        >
          <div className="navsec_back_arrow">
            {/* <img
              src={images.back}
              // className="nav_back_btn"
              alt=""
              onClick={() => {
                localStorage.removeItem("reselltheme_index");
                localStorage.removeItem("reselltheme_frame");
                navigate("/resellnfb");
              }}
            /> */}
            <button
              onClick={() => {
                localStorage.removeItem("reselltheme_index");
                localStorage.removeItem("reselltheme_frame");
                navigate("/resellnfb");
              }}
              className="myproduct_13_back_btn"
            >
              <FaAngleLeft color="var(--color-white)" size={20} />
            </button>
          </div>

          <b
            style={{
              color: "#fff",
              fontfamily: "var(--font-600)",
              fontsize: "16px",
              marginTop: "10px",
            }}
          >
            Create aucemo
          </b>
        </div>
        {isLoading == true ? (
          <div className="loader"></div>
        ) : (
          <div>
            <div className="view_card_nfb">{selectedCardComponent}</div>
            {/* <div className="view_card_nfb">
            <p>Set Image</p>
            <br />
            {product_img_video_carosel &&
              product_img_video_carosel.map((i, index) => {
                return (
                  <img
                    src={i.product_icon}
                    className={
                      index != img_index
                        ? "set_images_nfb_border"
                        : "set_images_nfb"
                    }
                    alt=""
                    onClick={() => {
                      // console.log("dscv");
                      set_img_index(index);
                      setbond_img_id(i.bond_img_id);
                      setproduct_img_video_carosel3(i.product_icon);
                    }}
                  />
                );
              })}
          </div> */}
            <p>Set frames</p>
            <div style={{ position: "relative", width: "100%" }}>
              <button
                className="nav-button left"
                onClick={scrollLeft}
                style={{ padding: "10px 0px", width: "24px" }}
              >
                <FaAngleLeft color="var(--color-main)" size={22} />
              </button>
              <div
                ref={sliderRef}
                style={{ padding: "6px 1.5rem" }}
                className="view_card_nfb2"
              >
                <br />
                {/* Frame Selection */}
                {/* <img
              src={images.firstcard}
              className={
                selectedFrame === "frames3"
                  ? `frames_img ${
                      selectedFrame === "frames3" ? "selected" : ""
                    }`
                  : "frames_img_border"
              }
              alt=""
              onClick={() => {
                settheme_index(0);
                handleFrameClick("frames3");
              }}
            /> */}
                {/* <img
                src={images.yellowcard}
                className={
                  selectedFrame === "frames1"
                    ? `frames_img ${
                        selectedFrame === "frames1" ? "selected" : ""
                      }`
                    : "frames_img_border"
                }
                alt=""
                onClick={() => {
                  settheme_index(6);
                  handleFrameClick("frames1");
                  localStorage.setItem("reselltheme_index", 6);
                  localStorage.setItem("reselltheme_frame", "frames1");
                }}
              />
              <img
                src={images.redcard}
                className={
                  selectedFrame === "frames2"
                    ? `frames_img ${
                        selectedFrame === "frames2" ? "selected" : ""
                      }`
                    : "frames_img_border"
                }
                alt=""
                onClick={() => {
                  settheme_index(7);
                  handleFrameClick("frames2");
                  localStorage.setItem("reselltheme_index", 7);
                  localStorage.setItem("reselltheme_frame", "frames2");
                }}
              />
              <img
                src={images.Card8Frame}
                className={
                  selectedFrame === "frames4"
                    ? `frames_img ${
                        selectedFrame === "frames4" ? "selected" : ""
                      }`
                    : "frames_img_border"
                }
                alt=""
                onClick={() => {
                  settheme_index(8);
                  handleFrameClick("frames4");
                  localStorage.setItem("reselltheme_index", 8);
                  localStorage.setItem("reselltheme_frame", "frames4");
                }}
              />
              <img
                src={images.Card9Frame}
                className={
                  selectedFrame === "frames5"
                    ? `frames_img ${
                        selectedFrame === "frames5" ? "selected" : ""
                      }`
                    : "frames_img_border"
                }
                alt=""
                onClick={() => {
                  settheme_index(9);
                  handleFrameClick("frames5");
                  localStorage.setItem("reselltheme_index", 9);
                  localStorage.setItem("reselltheme_frame", "frames5");
                }}
              />
              <img
                src={images.Card10Frame}
                className={
                  selectedFrame === "frames6"
                    ? `frames_img ${
                        selectedFrame === "frames6" ? "selected" : ""
                      }`
                    : "frames_img_border"
                }
                alt=""
                onClick={() => {
                  settheme_index(10);
                  handleFrameClick("frames6");
                  localStorage.setItem("reselltheme_index", 10);
                  localStorage.setItem("reselltheme_frame", "frames6");
                }}
              />
              <img
                src={images.Card11Frame}
                className={
                  selectedFrame === "frames7"
                    ? `frames_img ${
                        selectedFrame === "frames7" ? "selected" : ""
                      }`
                    : "frames_img_border"
                }
                alt=""
                onClick={() => {
                  settheme_index(11);
                  handleFrameClick("frames7");
                  localStorage.setItem("reselltheme_index", 11);
                  localStorage.setItem("reselltheme_frame", "frames7");
                }}
              />
              <img
                src={images.Card12Frame}
                className={
                  selectedFrame === "frames8"
                    ? `frames_img ${
                        selectedFrame === "frames8" ? "selected" : ""
                      }`
                    : "frames_img_border"
                }
                alt=""
                onClick={() => {
                  settheme_index(12);
                  handleFrameClick("frames8");
                  localStorage.setItem("reselltheme_frame", "frames8");
                }}
              />
              <img
                src={images.card7_hr_thub}
                className={
                  selectedFrame === "frames9"
                    ? `frames_img ${
                        selectedFrame === "frames9" ? "selected" : ""
                      }`
                    : "frames_img_border"
                }
                alt=""
                onClick={() => {
                  settheme_index(13);
                  handleFrameClick("frames9");
                  localStorage.setItem("reselltheme_frame", "frames9");
                }}
              />
              <img
                src={images.frame14}
                className={
                  selectedFrame === "frames10"
                    ? `frames_img ${
                        selectedFrame === "frames10" ? "selected" : ""
                      }`
                    : "frames_img_border"
                }
                alt=""
                onClick={() => {
                  settheme_index(14);
                  handleFrameClick("frames10");
                  localStorage.setItem("reselltheme_frame", "frames10");
                }}
              />
              <img
                src={images.frame15}
                className={
                  selectedFrame === "frames11"
                    ? `frames_img ${
                        selectedFrame === "frames11" ? "selected" : ""
                      }`
                    : "frames_img_border"
                }
                alt=""
                onClick={() => {
                  settheme_index(15);
                  handleFrameClick("frames11");
                  localStorage.setItem("reselltheme_frame", "frames11");
                }}
              /> */}
                <img
                  src={images.new_frame_1_nfb}
                  className={
                    selectedFrame === "frames12"
                      ? `frames_img ${
                          selectedFrame === "frames12" ? "selected" : ""
                        }`
                      : "frames_img_border"
                  }
                  alt=""
                  onClick={() => {
                    settheme_index(16);
                    handleFrameClick("frames12");
                    localStorage.setItem("reselltheme_frame", "frames12");
                  }}
                />
                <img
                  src={images.new_frame_2_nfb}
                  className={
                    selectedFrame === "frames13"
                      ? `frames_img ${
                          selectedFrame === "frames13" ? "selected" : ""
                        }`
                      : "frames_img_border"
                  }
                  alt=""
                  onClick={() => {
                    settheme_index(17);
                    handleFrameClick("frames13");
                    localStorage.setItem("reselltheme_frame", "frames13");
                  }}
                />
                <img
                  src={images.new_frame_3_nfb}
                  className={
                    selectedFrame === "frames14"
                      ? `frames_img ${
                          selectedFrame === "frames14" ? "selected" : ""
                        }`
                      : "frames_img_border"
                  }
                  alt=""
                  onClick={() => {
                    settheme_index(18);
                    handleFrameClick("frames14");
                    localStorage.setItem("reselltheme_frame", "frames14");
                  }}
                />
                <img
                  src={images.new_frame_4_nfb}
                  className={
                    selectedFrame === "frames15"
                      ? `frames_img ${
                          selectedFrame === "frames15" ? "selected" : ""
                        }`
                      : "frames_img_border"
                  }
                  alt=""
                  onClick={() => {
                    settheme_index(19);
                    handleFrameClick("frames15");
                    localStorage.setItem("reselltheme_frame", "frames15");
                  }}
                />
                <img
                  src={images.new_frame_5_nfb}
                  className={
                    selectedFrame === "frames16"
                      ? `frames_img ${
                          selectedFrame === "frames16" ? "selected" : ""
                        }`
                      : "frames_img_border"
                  }
                  alt=""
                  onClick={() => {
                    settheme_index(20);
                    handleFrameClick("frames16");
                    localStorage.setItem("reselltheme_frame", "frames16");
                  }}
                />
                <img
                  src={images.new_frame_6_nfb}
                  className={
                    selectedFrame === "frames17"
                      ? `frames_img ${
                          selectedFrame === "frames17" ? "selected" : ""
                        }`
                      : "frames_img_border"
                  }
                  alt=""
                  onClick={() => {
                    settheme_index(21);
                    handleFrameClick("frames17");
                    localStorage.setItem("reselltheme_frame", "frames17");
                  }}
                />
                <img
                  src={images.new_frame_7_nfb}
                  className={
                    selectedFrame === "frames18"
                      ? `frames_img ${
                          selectedFrame === "frames18" ? "selected" : ""
                        }`
                      : "frames_img_border"
                  }
                  alt=""
                  onClick={() => {
                    settheme_index(22);
                    handleFrameClick("frames18");
                    localStorage.setItem("reselltheme_frame", "frames18");
                  }}
                />
                <img
                  src={images.new_frame_8_nfb}
                  className={
                    selectedFrame === "frames19"
                      ? `frames_img ${
                          selectedFrame === "frames19" ? "selected" : ""
                        }`
                      : "frames_img_border"
                  }
                  alt=""
                  onClick={() => {
                    settheme_index(23);
                    handleFrameClick("frames19");
                    localStorage.setItem("reselltheme_frame", "frames19");
                  }}
                />
                <img
                  src={images.new_frame_9_nfb}
                  className={
                    selectedFrame === "frames20"
                      ? `frames_img ${
                          selectedFrame === "frames20" ? "selected" : ""
                        }`
                      : "frames_img_border"
                  }
                  alt=""
                  onClick={() => {
                    settheme_index(24);
                    handleFrameClick("frames20");
                    localStorage.setItem("reselltheme_frame", "frames20");
                  }}
                />
                <img
                  src={images.new_frame_10_nfb}
                  className={
                    selectedFrame === "frames21"
                      ? `frames_img ${
                          selectedFrame === "frames21" ? "selected" : ""
                        }`
                      : "frames_img_border"
                  }
                  alt=""
                  onClick={() => {
                    settheme_index(25);
                    handleFrameClick("frames21");
                    localStorage.setItem("reselltheme_frame", "frames21");
                  }}
                />
              </div>
              <button
                className="nav-button right"
                onClick={scrollRight}
                style={{
                  justifyContent: "flex-end",
                  padding: "10px 0px",
                  width: "24px",
                }}
              >
                <FaAngleRight color="var(--color-main)" size={22} />
              </button>
            </div>
            {available_no_of_bond == 0 ? null : (
              <button
                className="attach_nfbs_nm"
                onClick={() => setShowModal(true)}
              >
                <p>Attach Benefit</p>
              </button>
            )}
            <br />
            <div className="attach_nfbs_nm2">
              <p style={{ fontSize: "12px", fontFamily: "var(--font-600)" }}>
                You can attach benefits on {available_no_of_bond} aucemo.
              </p>
              <p className="nm_attach_f">
                Attach benefits to your aucemo and make them irresistible. Your
                added benefits enhance the value of your aucemo. Get creative
                and watch them become highly sought after!
              </p>
              <Link
                className="createbenefit_btn"
                to={"/ResellCreatebenefit"}
                state={{ product_id: location.state.product_id }}
              >
                Create benefits
              </Link>
            </div>
            <div className="view_benefit_main">
              {attched_benifit_data && attched_benifit_data.length > 0 && (
                <b>Benefits Attached</b>
              )}
              {attched_benifit_data && attched_benifit_data.length > 0
                ? attched_benifit_data.map((item, index) => {
                    console.log("item", item);
                    return (
                      <div className="list_benefit_view">
                        <div className="part_benefit">
                          <div className="part_benefit1">
                            <div className="sub_list_benefits">
                              <div>
                                <b className="nm_nfbs_list">aucemo</b>
                                <br />
                                <b className="nm_nfbs_list1">
                                  {item.no_of_bond}
                                </b>
                              </div>
                              <Link
                                className="manage_link"
                                to="/ResellAttachbenefit"
                                state={{
                                  product_id: location.state.product_id,
                                  number_of_bond: item.no_of_bond,
                                  uniuq_code: item.uniq_code,
                                }}
                              >
                                Manage
                              </Link>
                            </div>
                          </div>
                          <div className="part_benefit2">
                            {/* partner data start */}
                            {item.partner_bond_benifit_data &&
                            item.partner_bond_benifit_data.length > 0
                              ? item.partner_bond_benifit_data.map(
                                  (itm, ind) => {
                                    console.log(
                                      "partner_bond_benifit_data item",
                                      item
                                    );
                                    return (
                                      <div className="view_sub_benefit">
                                        <img
                                          src={itm.benifit_icon}
                                          className="img_view_1"
                                          alt=""
                                        />
                                        <p className="benefit_nm_sub">
                                          {itm.benifit_name}
                                        </p>
                                        <p className="benefit_nm_sub">
                                          {itm.partner_name}
                                        </p>
                                      </div>
                                    );
                                  }
                                )
                              : null}

                            {/* partner data end */}

                            {/* own benefit data start */}

                            {item.product_bond_benifit_detail &&
                            item.product_bond_benifit_detail.length > 0
                              ? item.product_bond_benifit_detail.map(
                                  (data, ind) => {
                                    return (
                                      <div className="view_sub_benefit">
                                        <img
                                          src={
                                            data.reward_img_video[0].img_video
                                          }
                                          className="img_view_1"
                                          alt=""
                                        />
                                        <p className="benefit_nm_sub">
                                          {data.reward_name}
                                        </p>
                                      </div>
                                    );
                                  }
                                )
                              : null}

                            {/* own benefit data end */}
                          </div>
                        </div>
                      </div>
                    );
                  })
                : null}
            </div>
            <center>
              <button
                className="new_btn_wrapp mt-3"
                onClick={() => setShowInfoModal(true)}
              >
                {/* {attched_benifit_data && attched_benifit_data.length > 0
                ? "Launch aucemo"
                : "Launch aucemo Without Benefit"} */}
                <p>Launch</p>
              </button>
            </center>
          </div>
        )}
      </div>
      {showModal && (
        <div className="v2modal_main_wrapp">
          <div className="v2modal_inner_wrapp">
            <div className="v2modal_fixed_logo_wrapp">
              <img src={images.logo} alt="" />
            </div>
            <button
              className="v2modal_close_img"
              onClick={() => {
                setShowModal(false);
              }}
            >
              <img
                src={images.new_close_logo}
                className="v2modal_close_img"
                alt=""
              />
            </button>

            <h4 className="v2modal_fixed_heading">
              Enter aucemo (Max {available_no_of_bond})
            </h4>

            <div>
              {/* {mpin.map((digit, index) => { */}
              {/* return ( */}
              <input
                // key={index}
                type="tel"
                value={no_of_bond}
                className="newattach_modal_input"
                // onChange={(e) => setno_of_bond(e.target.value)}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  const isValid = /^\d+$/.test(inputValue);

                  if (no_of_bond > available_no_of_bond) {
                    setno_of_bond("");
                    // setModalName(
                    //   "Enter aucemo value below release value of aucemo"
                    // );
                    // setIsModalOpen(true);
                    toast.error(
                      "Enter aucemo value below release value of aucemo",
                      {
                        theme: "colored",
                      }
                    );
                  } else if (!isValid) {
                    setno_of_bond("");
                    // setModalName("Please enter a valid number of aucemo");
                    // setIsModalOpen(true);
                    toast.error("Please enter a valid number of aucemo", {
                      theme: "colored",
                    });
                  } else {
                    setno_of_bond(parseInt(inputValue));
                    console.log("isValid", inputValue);
                  }
                }}
                // maxLength={1}
                placeholder="Enter number of aucemo"
                // ref={(input) => (mpinRefs.current[index] = input)}
              />
              {/* ); */}
              {/* })} */}
            </div>
            <div className="newattech_hr"></div>
            <div
              style={{
                width: "80%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                margin: "0px auto",
              }}
            >
              <button
                className="v2modal_btn1"
                onClick={() => {
                  setno_of_bond(0);
                  setShowModal(false);
                }}
              >
                Cancel
              </button>
              <button className="v2modal_btn2" onClick={BenefitNFBs}>
                Okay
              </button>
            </div>
          </div>
        </div>
      )}

      {/* information modal start */}

      {showinfoModal && (
        // <div className="newattach_modal">
        //   <div className="modal-content1">
        //     <center>
        //       <img src={images.logo} className="newattach_modal_img2" alt="" />
        //     </center>
        //     <p
        //       style={{
        //         fontSize: "16px",
        //         fontFamily: "var(--font-600)",
        //         color: "#fff",
        //         marginTop: "-30px",
        //       }}
        //     >
        //       Information
        //     </p>
        //     <p
        //       style={{
        //         width: "90%",
        //         margin: "10px auto",
        //         fontFamily: "var(--font-400)",
        //         fontSize: "12px",
        //       }}
        //     >
        //       Before releasing the aucemo, Please take a moment to carefully
        //       verify the details and the benefits attached
        //     </p>
        //     <div
        //       style={{
        //         display: "flex",
        //         alignItems: "center",
        //         justifyContent: "center",
        //         width: "90%",
        //         margin: "10px auto",
        //         marginTop: "20px",
        //         gap: "50px",
        //       }}
        //     >
        //       <button
        //         className="new_attech_modal_btn"
        //         onClick={() => releaseBond()}
        //       >
        //         Okay
        //       </button>
        //       <button
        //         className="new_attech_modal_btn"
        //         onClick={() => setShowInfoModal(false)}
        //       >
        //         Cancel
        //       </button>
        //     </div>
        //   </div>
        // </div>

        // <ReactModal
        //   isOpen={setShowInfoModal}
        //   // onAfterOpen={afterOpenModal}
        //   onRequestClose={closemodal1}
        //   style={customStyles}
        // >
        //   <div className="NewModal_main_wrapp">
        //     <div
        //       style={{
        //         display: "flex",
        //         flexDirection: "column",
        //         alignItems: "center",
        //         justifyContent: "center",
        //       }}
        //     >
        //       <img className="newmodal_logo" src={images.logo} alt="" />
        //       <p className="newmodal_titel"></p>
        //       {/* <p className="newmodal_titel">Alert</p> */}
        //     </div>

        //     {/* <div className="newmodal_des_wrapp"> */}
        //     <p className="newmodal_des">
        //       Before releasing the aucemo, Please take a moment to carefully
        //       verify the details and the benefits attached
        //     </p>

        //     <div className="modal_two_buton" style={{ width: "100%" }}>
        //       <button className="close_forgot" onClick={() => releaseBond()}>
        //         Okay
        //       </button>
        //       <button className="close_forgot" onClick={closemodal1}>
        //         Cancel
        //       </button>
        //     </div>
        //   </div>
        // </ReactModal>

        <V2Modal
          canclebtn={true}
          title=""
          msg="Before releasing the aucemo, Please take a moment to carefully verify the details and the benefits attached"
          setShowModal={setShowInfoModal}
          okfunction={releaseBond}
        />
      )}

      {/* information modal end */}

      {/* alert modal start */}

      {/* {ismodalOpen && (
        <CusModal
          msg={getmsg}
          setShowModal={setIsModalOpen}
          showModal={ismodalOpen}
        />
      )} */}

      {ismodalOpen && (
        // <AlertModal
        //   msg={getmsg}
        //   setShowModal={setIsModalOpen}
        //   // showModal={ismodalOpen}
        //   title={getmodalName}
        // />
        // <NewModal
        //   title={getmodalName}
        //   msg={getmsg}
        //   setShowModal={openmodal}
        //   closemodal={closemodal}
        // />
        <V2Modal
          canclebtn={false}
          title=""
          msg={getmsg}
          setShowModal={setIsModalOpen}
          okfunction={closemodal}
        />
      )}
      <ToastContainer />

      {/* alert modal end */}
    </div>
  );
};

export default Resellnewattach;
