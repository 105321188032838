import React, { useRef } from "react";
import "./UserProfile.css";
import images from "../../constants/images";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useProfileContext } from "../../context/profile_context";
import { useState } from "react";
import { useEffect } from "react";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { ParallaxBanner } from "react-scroll-parallax";

const UserProfile = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const userId = localStorage.getItem("user_id");
  const { getProfile, profile_data, setFollow } = useProfileContext();
  const [isLoading, setIsLoading] = useState(false);
  const [getuserData, setUserData] = useState({});
  const [getuserVideo, setUserVideo] = useState("");
  const [getuserVideoThumb, setUserVideoThumb] = useState("");
  const [getpreviewVideoModal, setPreviewVideoModal] = useState("");

  const GetProfile = async () => {
    // setIsLoading(true);
    const param = {
      target_user_id: location.state.user_id,
      user_id: userId,
    };
    console.log("param -->", param);

    const data = await getProfile(param);
    console.log("profile data ->", data.result);

    setUserData(data.result);
  };

  const SetFollowApi = async () => {
    // setIsLoading(true);
    const param = {
      user_id: userId,
      target_user_id: location.state.user_id,
    };

    const data = await setFollow(param);
    console.log("data", data);
    GetProfile();
  };
  useEffect(() => {
    GetProfile();
    console.log("profile_data", profile_data);
  }, [location.state.user_id]);

  const handleUserClick = (user_id) => {
    // Update the state with the new user_id and navigate to the same route
    navigate("/userProfile", { state: { user_id: user_id } });
  };
  useEffect(() => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  }, []);

  const getCoverPhotoLuminance = (imageUrl, callback) => {
    const img = new Image();
    img.crossOrigin = "Anonymous";
    img.src = imageUrl;

    img.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0, img.width, img.height);

      const imageData = ctx.getImageData(0, 0, img.width, img.height).data;
      let r, g, b, avg;
      let colorSum = 0;

      for (let x = 0, len = imageData.length; x < len; x += 4) {
        r = imageData[x];
        g = imageData[x + 1];
        b = imageData[x + 2];

        avg = Math.floor((r + g + b) / 3);
        colorSum += avg;
      }

      const brightness = Math.floor(colorSum / (img.width * img.height));
      // alert(brightness);
      callback(brightness > 31 ? "black" : "white");
    };
  };

  const [arrowColor, setArrowColor] = useState("white");
  useEffect(() => {
    if (getuserData.user_cover_photo) {
      getCoverPhotoLuminance(getuserData.user_cover_photo, setArrowColor);
    }
  }, [getuserData.user_cover_photo]);

  // aucemo scroll
  const sliderRef = useRef(null);
  const scrollLeft = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({
        left: -200,
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({
        left: 200,
        behavior: "smooth",
      });
    }
  };

  // clubs scroll
  const sliderRef2 = useRef(null);
  const scrollLeft2 = () => {
    if (sliderRef2.current) {
      sliderRef2.current.scrollBy({
        left: -200,
        behavior: "smooth",
      });
    }
  };
  const scrollRight2 = () => {
    if (sliderRef2.current) {
      sliderRef2.current.scrollBy({
        left: 200,
        behavior: "smooth",
      });
    }
  };

  // legacy scroll
  const sliderRef3 = useRef(null);
  const scrollLeft3 = () => {
    if (sliderRef3.current) {
      sliderRef3.current.scrollBy({
        left: -200,
        behavior: "smooth",
      });
    }
  };
  const scrollRight3 = () => {
    if (sliderRef3.current) {
      sliderRef3.current.scrollBy({
        left: 200,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="userprfile_main_wrapp">
      <div className="userprfile_base_wrapp">
        {/* banner start */}
        <div className="user_profile_first_wrapp">
          <img
            src={
              getuserData.user_cover_photo == ""
                ? images.coverimg
                : getuserData.user_cover_photo
            }
            alt=""
            className="up_banner_img"
          />
          {/* <ParallaxBanner
            layers={[
              {
                image:
                  getuserData.user_cover_photo == ""
                    ? images.coverimg
                    : getuserData.user_cover_photo,
                speed: -15,
                expanded: false,
              },
            ]}
            className="up_banner_img"
          /> */}
          <button className="up_topbanner_back" onClick={() => navigate(-1)}>
            <FaAngleLeft color={arrowColor} size={30} />
          </button>
        </div>
        {/* banner end */}
        {/* USer Profile start */}
        <div className="up_profile_name_wrapp">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "10px",
              width: "75%",
            }}
          >
            <img
              onClick={() => {
                navigate("/ProductViewImg2", {
                  state: {
                    img: getuserData.profile
                      ? getuserData.profile
                      : images.mask_profile,
                  },
                });
              }}
              src={
                getuserData.profile !== ""
                  ? getuserData.profile
                  : images.mask_profile
              }
              className="up_profile_img"
              alt=""
            />
            <p
              className="up_user_name"
              style={{
                marginTop: "22px",
              }}
            >
              {getuserData.firstname}
            </p>
          </div>

          <div
            style={{
              width: "20%",
              justifyContent: "flex-end",
              display: "flex",
            }}
          >
            {getuserData.qr_code !== "" ? (
              <img
                // onClick={() => {
                //   navigate("/ProductViewImg2", {
                //     state: {
                //       img: getuserData.profile
                //         ? getuserData.profile
                //         : images.mask_profile,
                //     },
                //   });
                // }}
                src={
                  getuserData.qr_code !== ""
                    ? getuserData.qr_code
                    : images.qrcode
                }
                className="up_profile_qr"
                style={{}}
                alt=""
              />
            ) : null}
          </div>
        </div>
        {/* USer Profile end */}
        {/* Follow start */}
        <div className="follow_secs_wrapp">
          <div className="follow_1sec" style={{ marginTop: "20px" }}>
            <div className="follow_1sec_sigle_wrapp">
              <p>{getuserData.no_of_bond}</p>
              <span>aucemo</span>
            </div>
            <Link
              to="/AllFollower"
              state={{ target_user_id: location.state.user_id }}
              className="follow_1sec_sigle_wrapp"
            >
              <p>{getuserData.no_of_followers}</p>
              <span>Follower</span>
            </Link>
            <Link
              to="/AllFollowing"
              state={{ target_user_id: location.state.user_id }}
              className="follow_1sec_sigle_wrapp"
            >
              <p>{getuserData.no_of_following}</p>
              <span>Following</span>
            </Link>
          </div>
          <button
            className="new_btn_wrapp"
            style={{
              color: "var(--color-white)",
              // opacity: 0.6,
              height: "40px",
              width: "100px",
              marginTop: "15px",
            }}
            onClick={() => {
              SetFollowApi();
            }}
          >
            {getuserData.is_follow == 0 ? "Follow" : "Following"}
          </button>
        </div>
        {/* Follow end */}
        {/* showcase and edit btns start */}
        <button
          onClick={() => {
            navigate("/Showcase", {
              state: {
                user_id: location.state.user_id,
                getuserData: getuserData,
              },
            });
          }}
          className="up_showcase_btn"
        >
          Showcase
        </button>
        {location.state.user_id == userId && (
          <button
            className="up_showcase_btn"
            onClick={() => {
              navigate("/Editprofile");
            }}
          >
            Edit
          </button>
        )}
        {/* showcase and edit btns end */}
        <p className="up_first_name">
          {getuserData.bio == null ||
          getuserData.bio == "null" ||
          getuserData.bio == ""
            ? ""
            : getuserData.bio}
        </p>
        {/* myaucemo section start */}
        {getuserData.product_detail && getuserData.product_detail.length > 0 ? (
          <>
            <div className="mynfb_viewall_btn_wrapp">
              <p>My aucemo</p>
              <button
                onClick={() => {
                  navigate("/mynfbcollection", {
                    state: {
                      target_user_id: location.state.user_id,
                    },
                  });
                }}
                className="new_btn_wrapp"
                style={{
                  color: "var(--color-white)",
                  opacity: 0.6,
                  height: "30px",
                  width: "90px",
                }}
              >
                <p
                  style={{
                    fontFamily: "var(--font-400)",
                    fontSize: "10px",
                    width: "100%",
                  }}
                >
                  View all
                </p>
              </button>
            </div>
            <div style={{ position: "relative", width: "100%" }}>
              <button
                className="nav-button left"
                onClick={scrollLeft}
                style={{
                  padding: "8px",
                  top: "50%",
                  left: "0px",
                  width: "36px",
                }}
              >
                <FaAngleLeft color="var(--color-main)" size={22} />
              </button>
              <div className="up_mynfbs_products_wrapp" ref={sliderRef}>
                {getuserData.product_detail.map((item, index) => {
                  return (
                    <button
                      onClick={() => {
                        navigate("/ProductView", {
                          state: {
                            release_bond_id: item.release_bond_id,
                            product_id: item.product_id,
                            is_nfb: item.is_nfb,
                            uniq_code: item.uniq_code,
                            theme_index: item.theme_index,
                            share_by_user: item.share_by_user,
                            club_id: item.club_id,
                          },
                        });
                      }}
                      className="up_mynfb_single_product"
                    >
                      <img
                        src={
                          item.product_img_video == 0
                            ? images.cp_sponser
                            : item.product_img_video[0].img_video
                        }
                        alt=""
                      />
                      <p>{item.product_name}</p>
                      <span>
                        {item.owner_name} | {item.no_of_bond}
                      </span>
                    </button>
                  );
                })}
              </div>
              <button
                className="nav-button right"
                onClick={scrollRight}
                style={{
                  justifyContent: "flex-end",
                  padding: "8px",
                  top: "50%",
                  width: "36px",
                  right: "0px",
                }}
              >
                <FaAngleRight color="var(--color-main)" size={22} />
              </button>
            </div>
          </>
        ) : null}
        {/* mynfbs section end */}
        {/* my clubs section start */}
        {getuserData.club_detail_data &&
        getuserData.club_detail_data.length > 0 ? (
          <>
            <div className="mynfb_viewall_btn_wrapp">
              <p>My Clubs</p>
              {/* <Link to={"/Sponsors"}>View all</Link> */}
              <button
                onClick={() => {
                  navigate("/Sponsors");
                }}
                className="new_btn_wrapp"
                style={{
                  color: "var(--color-white)",
                  opacity: 0.6,
                  height: "30px",
                  width: "90px",
                }}
              >
                <p
                  style={{
                    fontFamily: "var(--font-400)",
                    fontSize: "10px",
                    width: "100%",
                  }}
                >
                  View all
                </p>
              </button>
            </div>

            <div style={{ position: "relative", width: "100%" }}>
              <button
                className="nav-button left"
                onClick={scrollLeft2}
                style={{
                  padding: "8px",
                  top: "50%",
                  left: "0px",
                  width: "36px",
                }}
              >
                <FaAngleLeft color="var(--color-main)" size={22} />
              </button>
              <div className="up_mynfbs_products_wrapp" ref={sliderRef2}>
                {getuserData.club_detail_data.map((item, index) => {
                  return (
                    <Link
                      to={"/ClubProfile"}
                      state={{ club_id: item.club_id }}
                      className="up_mynfb_single_product"
                    >
                      <img src={item.profile} alt="" />
                      <p>{item.club_name}</p>
                    </Link>
                  );
                })}
              </div>
              <button
                className="nav-button right"
                onClick={scrollRight2}
                style={{
                  justifyContent: "flex-end",
                  padding: "8px",
                  top: "50%",
                  width: "36px",
                  right: "0px",
                }}
              >
                <FaAngleRight color="var(--color-main)" size={22} />
              </button>
            </div>
          </>
        ) : null}
        {/* my clubs section end */}
        {/* history start */}
        {getuserData.user_story != "" ||
          (getuserData.user_story != null && (
            <>
              <p className="up_history_name">History</p>
              <p className="up_history_dis">{getuserData.user_story}</p>
            </>
          ))}
        {/* history end */}
        {/* authentication video start */}
        <div
          className="mynfb_viewall_btn_wrapp"
          style={{ flexDirection: "column", margin: "10px auto" }}
        >
          <p
            style={{
              display: "inline-block",
              width: "100%",
              margin: "10px auto",
            }}
          >
            Creator Authentication Video
          </p>
          <div
            className="image-preview"
            // key={index}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "10px",
              width: "100%",
              height: "100%",
              background: "#000",
              maxHeight: "200px",
              overflow: "hidden",
            }}
          >
            <button
              // disabled={gettab3Completed}
              style={{
                color: "var(--color-main)",
                fontSize: "12px",
                fontFamily: "var(--font-600)",
                cursor: "default",
                width: "100%",
              }}
            >
              {/* Preview */}
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  position: "relative",
                }}
              >
                <img
                  src={
                    getuserData.user_videos &&
                    getuserData.user_videos[0]?.user_thumbnail !== ""
                      ? getuserData.user_videos[0]?.user_thumbnail
                      : images.thumbnail
                  }
                  alt=""
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    margin: "0px",
                    objectPosition: "top",
                  }}
                />
                <img
                  src={images.play_white}
                  style={{
                    width: "30px",
                    height: "30px",
                    objectFit: "contain",
                    margin: "0px",
                    position: "absolute",
                    transform: "translate(-50%, -50%)",
                    left: "50%",
                    top: "50%",
                    zIndex: 11,
                    cursor: "pointer",
                  }}
                  alt=""
                  onClick={() => {
                    setPreviewVideoModal(true);
                    setUserVideo(
                      getuserData.user_videos &&
                        getuserData.user_videos[0].user_videos
                    );
                    setUserVideoThumb(
                      getuserData.user_videos &&
                        getuserData.user_videos[0]?.user_thumbnail
                    );
                  }}
                />
              </div>
            </button>
          </div>
        </div>
        {/* authentication video end */}
        {getuserData.user_legacy_milestone &&
        getuserData.user_legacy_milestone.length > 0 ? (
          <>
            <div className="mynfb_viewall_btn_wrapp">
              <p>Legacy Milestones</p>
              {/* <button
                onClick={() => {
                  navigate("/ShowAllMilestones", {
                    state: {
                      user_id: location.state.user_id,
                      data: getuserData.user_legacy_milestone,
                    },
                  });
                }}
                className="new_btn_wrapp"
                style={{
                  color: "var(--color-white)",
                  opacity: 0.6,
                  height: "30px",
                  width: "90px",
                }}
              >
                <p
                  style={{
                    fontFamily: "var(--font-400)",
                    fontSize: "10px",
                    width: "100%",
                  }}
                >
                  View all
                </p>
              </button> */}
            </div>

            <div style={{ position: "relative", width: "100%" }}>
              <button
                className="nav-button left"
                onClick={scrollLeft3}
                style={{
                  padding: "8px",
                  top: "50%",
                  left: "0px",
                  width: "36px",
                }}
              >
                <FaAngleLeft color="var(--color-main)" size={22} />
              </button>

              <div className="up_mynfbs_products_wrapp" ref={sliderRef3}>
                {getuserData.user_legacy_milestone.map((item, index) => {
                  return (
                    <>
                      {/* <button
                    onClick={() => {
                      navigate("/MilestoneDashboard", {
                        state: {
                          user_id: location.state.user_id,
                          user_milestone_id: item.user_milestone_id,
                        },
                      });
                    }}
                    className="up_mynfb_single_product"
                  >
                    <img src={item.imageLink[0]?.imageLink} alt="" />
                    <p>{item.milestone_name}</p>
                  </button> */}
                      {/* <button style={{color: 'main', }}>{item.legacy_year}</button> */}

                      <button
                        className={`year`}
                        id={`year-${item.legacy_year}`}
                        onClick={() => {
                          navigate("/ShowAllMilestones", {
                            state: {
                              target_user_id: location.state.user_id,
                              user_id: location.state.user_id,
                              year: item.legacy_year,
                              // year: item.legacy_year,
                            },
                          });
                        }}
                        style={{
                          textShadow:
                            "0px -1px #eb0546, -1px -1px 6px #ff004c,0 3px #ff004c,  0 -3px #130c0e, 1px 1px #130c0e, -1px -1px #130c0e, 1px -1px #130c0e, -1px 1px #130c0e",
                          padding: "0px",
                          color: "var(--color-white)",
                          fontSize: "1.6rem",
                          margin: "2px",
                          // marginTop: "10px",
                        }}
                      >
                        {item.legacy_year}
                      </button>
                    </>
                  );
                })}
              </div>

              <button
                className="nav-button right"
                onClick={scrollRight3}
                style={{
                  justifyContent: "flex-end",
                  padding: "8px",
                  top: "50%",
                  width: "36px",
                  right: "0px",
                }}
              >
                <FaAngleRight color="var(--color-main)" size={22} />
              </button>
            </div>
          </>
        ) : null}
        {getuserData.user_family && getuserData.user_family.length > 0 ? (
          <>
            <div className="mynfb_viewall_btn_wrapp">
              <p>Family Legacy</p>
              <button
                onClick={() => {
                  navigate("/ShowAllFamilyMember", {
                    state: {
                      target_user_id: location.state.user_id,
                      user_id: userId,
                    },
                  });
                }}
                className="new_btn_wrapp"
                style={{
                  color: "var(--color-white)",
                  opacity: 0.6,
                  height: "30px",
                  width: "90px",
                }}
              >
                <p
                  style={{
                    fontFamily: "var(--font-400)",
                    fontSize: "10px",
                    width: "100%",
                  }}
                >
                  View all
                </p>
              </button>
            </div>
            <div className="up_mynfbs_products_wrapp">
              {getuserData.user_family.map((item, index) => {
                return (
                  <button
                    // to="/UserProfile"
                    // state={{ user_id: item.user_id }}
                    target="_parent"
                    onClick={() => {
                      handleUserClick(item.user_id);
                    }}
                    key={index}
                    className="up_mynfb_single_product"
                  >
                    <div
                      style={{
                        backgroundImage:
                          "linear-gradient(to right,rgba(255, 0, 76, 0.36),rgba(202, 172, 255, 0.13))",
                        width: "85px",
                        height: "85px",
                        borderRadius: "85px",
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                      }}
                    >
                      <div
                        style={{
                          backgroundImage:
                            "linear-gradient(to right, rgba(255, 0, 76, 1), rgba(255, 0, 76, 0))",
                          width: "75px",
                          height: "75px",
                          borderRadius: "75px",
                          alignItems: "center",
                          justifyContent: "center",
                          display: "flex",
                        }}
                      >
                        <img
                          style={{
                            width: "69px",
                            height: "69px",
                            borderRadius: "69px",
                          }}
                          src={item.profile}
                          alt=""
                        />
                      </div>
                    </div>
                    <p>{item.firstname}</p>
                    {/* <span>
                      {item.owner_name} | {item.no_of_bond}
                    </span> */}
                  </button>
                );
              })}
            </div>
          </>
        ) : null}
        {getuserData.user_community && getuserData.user_community.length > 0 ? (
          <>
            <div className="mynfb_viewall_btn_wrapp">
              <p>Community Legacy</p>
              <button
                onClick={() => {
                  navigate("/ShowAllCommunity", {
                    state: {
                      target_user_id: location.state.user_id,
                      user_id: userId,
                    },
                  });
                }}
                className="new_btn_wrapp"
                style={{
                  color: "var(--color-white)",
                  opacity: 0.6,
                  height: "30px",
                  width: "90px",
                }}
              >
                <p
                  style={{
                    fontFamily: "var(--font-400)",
                    fontSize: "10px",
                    width: "100%",
                  }}
                >
                  View all
                </p>
              </button>
            </div>
            <div className="up_mynfbs_products_wrapp">
              {getuserData.user_community.map((item, index) => {
                return (
                  <button
                    onClick={() => {
                      // navigate("/UserProfile", {
                      //   state: { user_id: item.user_id },
                      // });
                      // navigate(`/user/${item.user_id}`);
                      handleUserClick(item.user_id);
                    }}
                    key={index}
                    className="up_mynfb_single_product"
                  >
                    <div
                      style={{
                        backgroundImage:
                          "linear-gradient(to right,rgba(255, 0, 76, 0.36),rgba(202, 172, 255, 0.13))",
                        width: "85px",
                        height: "85px",
                        borderRadius: "85px",
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                      }}
                    >
                      <div
                        style={{
                          backgroundImage:
                            "linear-gradient(to right, rgba(255, 0, 76, 1), rgba(255, 0, 76, 0))",
                          width: "75px",
                          height: "75px",
                          borderRadius: "75px",
                          alignItems: "center",
                          justifyContent: "center",
                          display: "flex",
                        }}
                      >
                        <img
                          style={{
                            width: "69px",
                            height: "69px",
                            borderRadius: "69px",
                          }}
                          src={item.profile}
                          alt=""
                        />
                      </div>
                    </div>
                    <p>{item.firstname}</p>
                    {/* <span>
                      {item.owner_name} | {item.no_of_bond}
                    </span> */}
                  </button>
                );
              })}
            </div>
          </>
        ) : null}
      </div>

      {getpreviewVideoModal && (
        <div
          style={{
            position: "fixed",
            width: "100vw",
            height: "100vh",
            top: "0px",
            left: "0px",
            zIndex: 112,
          }}
        >
          <div className="page_main_wrapp">
            <div className="page_base_wrapp">
              <div className="page_navbar_wrapp">
                <div className="nav_back_wrapp">
                  <button
                    onClick={() => {
                      setPreviewVideoModal(false);
                    }}
                  >
                    <FaAngleLeft color="var(--color-white)" size={20} />
                  </button>
                </div>
                <p className="nav_screen_name">Video</p>
              </div>
              <video
                controls
                style={{ width: "100%", height: "90%" }}
                poster={getuserVideoThumb !== "" ? getuserVideoThumb : ""}
              >
                <source src={getuserVideo} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserProfile;
