import React, { useEffect, useState } from "react";
import "./CandWBenefitDetail.css";
import images from "../../constants/images";
import { Link, useLocation, useNavigate } from "react-router-dom";
import NavbarSec from "../../common/NavbarSec";
import Slider from "react-slick";
import {
  get_single_partner_benifit_detail,
  single_c_and_w_product_bond_benifit_detail,
  single_product_bond_benifit_detail,
} from "../../utils/Constant";
import axios from "axios";
import qs from "qs";
import CusModal from "../../common/cusmodal/CusModal";
import ReactModal from "react-modal";
import V2Modal from "../../common/v2modal/V2Modal";
import { FaAngleLeft } from "react-icons/fa";

const CandWBenefitDetail = () => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "0px",
      backgroundColor: "none",
      border: "none",
      borderRadius: "12px",
    },
    overlay: {
      zIndex: 1000,
      backgroundColor: "rgba(0, 0, 0, 0.3)",
    },
  };
  const location = useLocation();
  const [is_loading, setIsLoading] = useState(false);
  const [getmodalOpen, setModalOpen] = useState(false);
  const [getpartnerData, setPartnerData] = useState({});

  // -----------

  const [benifit_price, setbenifit_price] = useState(0);
  const [name, setname] = useState("");
  const [description, setdescription] = useState("");
  const [benifit_icon, setbenifit_icon] = useState([]);
  const [timeRemaining, settimeRemaining] = useState(1);
  const [terms, setterms] = useState("");
  const [manager_data, setmanager_data] = useState([]);
  const [benifit_galary, setbenifit_galary] = useState([]);
  const [getdates, setdates] = useState("");
  const [isvideoLoading, setIsVideoLoading] = useState(true);

  // -----------

  useEffect(() => {
    console.log("getdates", getdates);
    console.log("manager_data", manager_data);
    console.log("location.state.user_selected_video_date", location.state);
  }, [getdates]);

  useEffect(() => {
    console.log("location", location.state);

    GetProductBenefit();
  }, []);

  const GetProductBenefit = () => {
    setIsLoading(true);
    const param = {
      is_nfb: location.state.is_nfb,
      pending_c_and_w_id: location.state.pending_c_and_w_id,
    };
    axios
      .post(single_c_and_w_product_bond_benifit_detail, qs.stringify(param))
      .then((Response) => {
        setIsLoading(false);
        console.log("====================================");
        console.log(
          "single_c_and_w_product_bond_benifit_detail Res",
          JSON.stringify(Response.data, null, 2)
        );
        console.log("====================================");
        if (Response.data.status == 1) {
          setname(Response.data.result.reward_name);
          setdescription(Response.data.result.description);
          setPartnerData(Response.data.result);
          setbenifit_icon(Response.data.result.reward_img_video[0].img_video);
          setterms(Response.data.result.terms_condition);
          setmanager_data(Response.data.result.manager_data);
          settimeRemaining(Response.data.result.valid_time);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("get_single_partner_benifit_detail errr", error);
      });
  };

  const navigate = useNavigate();

  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    console.log(
      "bid_benifit_last_date: item.bid_benifit_last_date,",
      location.state?.message
    );
  }, []);

  useEffect(() => {
    const countdownDate = new Date(
      location.state?.bid_benifit_last_date
    ).getTime();

    const countdownInterval = setInterval(() => {
      const now = new Date().getTime();
      const distance = countdownDate - now;

      if (distance > 0) {
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        setDays(days);
        setHours(hours);
        setMinutes(minutes);
        setSeconds(seconds);
      } else {
        clearInterval(countdownInterval);
      }
    }, 1000);

    return () => {
      clearInterval(countdownInterval);
    };
  }, [location.state?.bid_benifit_last_date]);
  const [descriptionMsg, setDescriptionMsg] = useState(location.state.message);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [cusmsg, setCusmsg] = useState("");
  const [isModalVisible1, setIsModalVisible1] = useState(false);
  const [cusmsg1, setCusmsg1] = useState("");
  const [con, setCon] = useState(false);

  const handleClaim = () => {
    setIsLoading(true);

    const param = {
      reward_id: location.state.reward_id,
      user_history_id: location.state.user_history_id,
      user_id: location.state.user_id,
      message: descriptionMsg,
    };

    console.log("====================================");
    console.log("param", param);
    console.log("====================================");

    axios
      .post(
        "https://www.ravi.host/api/user_request_reward_claim",
        qs.stringify(param)
      )
      .then((Response) => {
        if (Response.data.status == 1) {
          setCon(true);
          setIsModalVisible(true);
          setCusmsg(Response.data.message);
        } else {
          setCon(false);
          setIsModalVisible(true);
          setCusmsg(Response.data.message);
        }
      });
  };

  const [get_desmsg, setdismessage] = useState(location.state.message);

  const Climnow = () => {
    setIsLoading(true);
    const param = {
      reward_id: location.state.reward_id,
      user_history_id: location.state.user_history_id,
      user_id: location.state.user_id,
      message: get_desmsg,
    };
    console.log("param", param);
    axios
      .post(
        "https://www.ravi.host/api/user_request_reward_claim",
        qs.stringify(param)
      )
      .then((Response) => {
        console.log("response", Response.data);
        if (Response.data.status === 1) {
          setCon(true);
          setIsModalVisible(true);
          setCusmsg(Response.data.message);
          // navigate(-1);
        } else {
          // alert(Response.data.message);
          setCon(false);
          setIsModalVisible(true);
          setCusmsg(Response.data.message);
        }
      });
  };

  const handlePress = () => {
    if (getdates == "") {
      setIsModalVisible1(true);
      setCusmsg1("Please select a date");
    } else {
      setIsLoading(true);

      // const date =
      //   selected_date === 1
      //     ? location.state.date_1
      //     : selected_date === 2
      //     ? location.state.date_2
      //     : location.state.date_3;

      const param = {
        reward_id: location.state.reward_id,
        user_history_id: location.state.user_history_id,
        user_id: location.state.user_id,
        date: getdates,
      };
      console.log(param);
      axios
        .post(
          "https://www.ravi.host/api/video_user_request_reward_claim",
          qs.stringify(param)
        )
        .then((Response) => {
          if (Response.data.status === 1) {
            setIsModalVisible(true);
            setCusmsg(Response.data.message);
          } else {
            // alert(Response.data.message);
            setIsModalVisible(true);
            setCusmsg(Response.data.message);
          }
        });
    }
  };

  //disable body scrolling

  const [bodyScrollEnabled, setBodyScrollEnabled] = useState(true);

  useEffect(() => {
    if (getmodalOpen || isModalVisible || isModalVisible1) {
      // Disable body scrolling
      setBodyScrollEnabled(false);
    } else {
      // Enable body scrolling
      setBodyScrollEnabled(true);
    }
    if (bodyScrollEnabled) {
      document.body.style.overflow = "visible";
    } else {
      document.body.style.overflow = "hidden";
    }
  }, [getmodalOpen, isModalVisible, isModalVisible1, bodyScrollEnabled]);

  const closemodal = () => {
    setIsModalVisible(false);
  };
  const closemodal1 = () => {
    setIsModalVisible1(true);
  };

  const handlePhoneClick = (manager_mobile) => {
    // alert(manager_mobile);
    window.location.href = `tel:${manager_mobile}`;
  };

  return (
    <div className="page_newmain_wrapp">
      <div className="page_newbase_wrapp">
        {/* <div className="page_newnavbar_wrapp">
          <div className="nav_back_wrapp">
            <img
              src={images.back}
              className="nav_back_btn"
              alt=""
              onClick={() => navigate(-1)}
            />
          </div>
          <p className="nav_screen_name">Benefits</p>
        </div> */}
        <div style={{ padding: "10px 5%" }}>
          {/* <NavbarSec name="Wishlist" /> */}
          <div className="legacy_dashbord_nav_sec" style={{ margin: 0 }}>
            <button
              onClick={() => {
                navigate(-1);
              }}
            >
              <FaAngleLeft color="var(--color-white)" size={20} />
            </button>
            <p>Benefits</p>
            <div></div>
          </div>
        </div>
        <div>
          {manager_data && manager_data.length > 0 ? (
            location.state?.is_claim == 3 ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <p
                  style={{
                    fontSize: "1rem",
                    color: "var(--color-white)",
                    fontFamily: "var(--font-600)",
                  }}
                >
                  Completed
                </p>
              </div>
            ) : (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "20px",
                  }}
                >
                  <p
                    style={{
                      fontSize: "1rem",
                      color: "var(--color-white)",
                      fontFamily: "var(--font-600)",
                    }}
                  >
                    Ends in
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <p
                    style={{
                      fontSize: "2rem",
                      color: "var(--color-white)",
                      fontFamily: "var(--font-700)",
                    }}
                  >
                    {days}:{hours}:{minutes}:{seconds}
                  </p>
                </div>
              </>
            )
          ) : null}
          <div className="benefits_club_user">
            <h1 className="benefits_name1">{name}</h1>
            <p className="benefits_name2">{description}</p>
            <div>
              <img src={benifit_icon} className="Collaboratenfb_img1" alt="" />
              {/* <Slider {...sliderSettings}>
                {benifit_icon && benifit_icon.length > 0
                  ? benifit_icon.map((item, index) => {
                      console.log("item: ", item);
                      return (
                        <div>
                          <img
                            src={item.img_video}
                            className="Collaboratenfb_img1"
                            alt=""
                          />
                        </div>
                      );
                    })
                  : null}
              </Slider> */}
            </div>
          </div>
          {manager_data && manager_data.length > 0 ? (
            <center>
              {location.state.bid_amount_price &&
                location.state.bid_amount_price !== 0 && (
                  <h1 className="benefit_price">
                    ₹{location.state.bid_amount_price}
                  </h1>
                )}
            </center>
          ) : null}
        </div>
        {/* <center> */}
        {manager_data && manager_data.length > 0 ? (
          <>
            <div
              style={{
                alignItems: "center",
                justifyContent: "center",

                width: "90%",
                margin: "10px auto",
                textAlign: "center",
                // marginTop: "20px",
              }}
            >
              <p
                style={{
                  fontSize: "1rem",
                  color: "var(--color-white)",
                  fontFamily: "var(--font-400)",
                }}
              >
                Contact Person
              </p>
            </div>
          </>
        ) : null}
        {manager_data && manager_data.length > 0 ? (
          <>
            {manager_data &&
              manager_data.map((item, index) => {
                // console.log("qu?ery", manager_data);
                return (
                  <div
                    style={{
                      display: "flex",
                      width: "90%",
                      border: "1px solid red",
                      margin: "10px auto",
                      padding: "6px",
                      alignItems: "center",
                      justifyContent: "space-between",
                      height: "45px",
                      borderRadius: "45px",
                    }}
                  >
                    <img
                      style={{
                        width: "35px",
                        height: "35px",
                        borderRadius: "35px",
                      }}
                      src={item.manager_profile}
                    />

                    <p
                      style={{
                        fontSize: "1rem",
                        color: "var(--color-white)",
                        fontFamily: "var(--font-600)",
                      }}
                    >
                      {item.manager_name}
                    </p>
                    <a
                      // onClick={() => {
                      //   handlePhoneClick(item.manager_mobile);
                      // }}
                      href={`tel:${item.manager_mobile}`}
                      style={{
                        width: "35px",
                        height: "35px",
                        borderRadius: "35px",
                        // marginBottom: "-10px",
                        padding: "0px",
                      }}
                    >
                      <img
                        src={images.call}
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "100%",
                        }}
                      />
                    </a>
                  </div>
                );
              })}
          </>
        ) : null}

        <div
          style={{ position: "relative", marginTop: "10px" }}
          className="details_of_benefit"
        >
          <div className="sub_benefit">
            <button
              onClick={() => setModalOpen(true)}
              className="benefits_btn_details"
            >
              {" "}
              <img src={images.terms_white} className="img_benefits" alt="" />
              Terms & condition
            </button>
            {/* <Link to="/ReportPage" className="benefits_btn_details">
              <img src={images.need} className="img_benefits" alt="" />
              Need help?
            </Link> */}
          </div>
        </div>
        {/* <div className="details_of_benefit">
          <div className="sub_benefit">
            <button
              onClick={() => setModalOpen(true)}
              className="benefits_btn_details"
            >
              {" "}
              <img src={images.terms_white} className="img_benefits" alt="" />
              Terms & condition
            </button>
            <Link to="/ReportPage" className="benefits_btn_details">
              <img src={images.need} className="img_benefits" alt="" />
              Need help?
            </Link>
          </div>
        </div> */}
      </div>

      {getmodalOpen && (
        <div className="v2modal_main_wrapp">
          <div className="v2modal_inner_wrapp">
            <div className="v2modal_fixed_logo_wrapp">
              <img src={images.logo} alt="" />
            </div>
            <button
              className="v2modal_close_img"
              onClick={() => {
                setModalOpen(false);
              }}
            >
              <img
                src={images.new_close_logo}
                className="v2modal_close_img"
                alt=""
              />
            </button>
            <div className="v2modal_fixed_text_wrapp">
              <p>{terms}</p>
            </div>

            <div className="v2modal_fixed_btns_wrapp">
              {/* <button
              className="v2modal_btn1"
              onClick={() => {
                setconformationmodel(false);
              }}
            >
              Cancel
            </button> */}
              <button
                className="v2modal_btn2"
                onClick={() => {
                  setModalOpen(false);
                }}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      )}
      {isModalVisible && (
        // <div className="cusModal_main_wrapp">
        //   <div className="cusmodal_inner_wrapp">
        //     <img src={images.logo} alt="" className="cm_logo" />
        //     <p className="cm_heading"></p>
        //     <div className="cm_des_wrapp">
        //       <p className="cm_des">{cusmsg}</p>
        //       <div className="cm_hr"></div>
        //       <button
        //         className="cm_btn"
        //         onClick={() => {
        //           navigate(-1);
        //         }}
        //       >
        //         Okay
        //       </button>
        //     </div>
        //   </div>
        // </div>

        // <ReactModal
        //   isOpen={setIsModalVisible}
        //   onRequestClose={closemodal}
        //   style={customStyles}
        // >
        //   <div className="NewModal_main_wrapp">
        //     <div
        //       style={{
        //         display: "flex",
        //         flexDirection: "column",
        //         alignItems: "center",
        //         justifyContent: "center",
        //       }}
        //     >
        //       <img className="newmodal_logo" src={images.logo} alt="" />
        //       <p className="newmodal_titel"></p>
        //     </div>

        //     <p className="newmodal_des">{cusmsg}</p>

        //     <button
        //       className="newmodal_btn"
        //       onClick={() => {
        //         navigate(-1);
        //       }}
        //     >
        //       Okay
        //     </button>
        //   </div>
        // </ReactModal>

        <V2Modal
          canclebtn={false}
          title=""
          msg={cusmsg}
          setShowModal={setIsModalVisible}
          okfunction={() => {
            navigate(-1);
          }}
        />
      )}
      {isModalVisible1 && (
        // <div className="cusModal_main_wrapp">
        //   <div className="cusmodal_inner_wrapp">
        //     <img src={images.logo} alt="" className="cm_logo" />
        //     <p className="cm_heading"></p>
        //     <div className="cm_des_wrapp">
        //       <p className="cm_des">{cusmsg1}</p>
        //       <div className="cm_hr"></div>
        //       <button
        //         className="cm_btn"
        //         onClick={() => {
        //           setIsModalVisible1(false);
        //           // navigate(-1);
        //         }}
        //       >
        //         Okay
        //       </button>
        //     </div>
        //   </div>
        // </div>

        // <ReactModal
        //   isOpen={setIsModalVisible1}

        //   onRequestClose={closemodal1}
        //   style={customStyles}
        // >
        //   <div className="NewModal_main_wrapp">
        //     <div
        //       style={{
        //         display: "flex",
        //         flexDirection: "column",
        //         alignItems: "center",
        //         justifyContent: "center",
        //       }}
        //     >
        //       <img className="newmodal_logo" src={images.logo} alt="" />
        //       <p className="newmodal_titel"></p>

        //     </div>

        //     <p className="newmodal_des">{cusmsg1}</p>

        //     <button className="newmodal_btn" onClick={closemodal1}>
        //       Okay
        //     </button>
        //   </div>
        // </ReactModal>

        <V2Modal
          canclebtn={false}
          title=""
          msg={cusmsg1}
          setShowModal={setIsModalVisible1}
          okfunction={closemodal1}
        />
      )}
    </div>
  );
};

export default CandWBenefitDetail;
