import React, { useEffect, useState } from "react";
import "./CandWBenefit.css";
import NavbarSec from "../../common/NavbarSec";
import images from "../../constants/images";
import axios from "axios";
import qs from "qs";
import {
  IoIosCheckmarkCircleOutline,
  IoIosCloseCircleOutline,
} from "react-icons/io";
import NewLoader from "../../common/newloder/NewLoader";
import { FaAngleLeft, FaCheck } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { IoClose } from "react-icons/io5";
import { FiCheck } from "react-icons/fi";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";

const CandWBenefit = () => {
  const userId = localStorage.getItem("user_id");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const [getdata, setData] = useState([]);

  const _getData_CandWrequest = () => {
    const param = {
      user_id: userId,
    };
    console.log(param);
    setIsLoading(true);

    axios
      .post(
        "https://www.ravi.host/api/get_user_c_and_w_benifit_history",
        qs.stringify(param)
      )
      .then((response) => {
        console.log("get_user_family_request_responcw", response.data);
        setIsLoading(false);

        if (response.data.status == 1) {
          setData(response.data.result[0]?.product_c_and_w_benifit);
        } else {
          setData([]);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const handleClaimReward = (item) => {
    if (item.is_claim == 1) {
      // Handle case where is_claim is 1

      navigate("/CandWBenefitDetail", {
        state: {
          is_nfb: item.is_nfb,
          pending_c_and_w_id: item.pending_c_and_w_id,
          partner: 0,
          product_assign_id: item.product_assign_id,
          release_bond_id: item.release_bond_id,
          accept_bond_bid_id: item.accept_bond_bid_id,
          user_id: userId,
          bid_benifit_last_date: item.bid_benifit_last_date,
          is_claim: item.is_claim,
        },
      });
      console.log("item", item);
    } else if (item.is_claim === 2) {
      const param = {
        reward_id: item.reward_id,
        pending_c_and_w_id: item.pending_c_and_w_id,
        status: 3,
      };

      axios
        .post(
          "https://www.ravi.host/api/reward_claim_user_product_accept",
          qs.stringify(param)
        )
        .then((response) => {
          if (response.data.status === 1) {
            toast.success(`${response.data.message}`, { theme: "colored" });
            _getData_CandWrequest();
          } else {
            toast.error(`${response.data.message}`, { theme: "colored" });
          }
        });
    } else {
      // Handle the default case

      navigate("/CandWBenefitDetail", {
        state: {
          is_nfb: item.is_nfb,
          pending_c_and_w_id: item.pending_c_and_w_id,
          partner: 0,
          product_assign_id: item.product_assign_id,
          release_bond_id: item.release_bond_id,
          accept_bond_bid_id: item.accept_bond_bid_id,
          user_id: userId,
          bid_benifit_last_date: item.bid_benifit_last_date,
          is_claim: item.is_claim,
        },
      });
    }
  };

  useEffect(() => {
    _getData_CandWrequest();
  }, []);

  // vishal code start

  return (
    <div className="new_wrapp_container reletive_position">
      <img
        src={images.new_bg_circule}
        alt=""
        className="back_circule_shadow_left"
      />
      {isLoading ? (
        <NewLoader />
      ) : (
        <div className="new_container">
          <div
            className="play_screen_main_wrapp"
            style={{ padding: "10px 5%" }}
          >
            <div className="legacy_dashbord_nav_sec">
              <button
                onClick={() => {
                  navigate("/Sidemenu");
                }}
              >
                <FaAngleLeft color="var(--color-white)" size={20} />
              </button>
              <p>C & W Benefits</p>
              <div></div>
            </div>

            {/* recevied list start */}

            <>
              {/* request tabs section end */}

              {/* recevied list start */}
              <div className="family_req_receved_list">
                <>
                  {getdata && getdata.length == 0 ? (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <p>No requests found</p>
                    </div>
                  ) : (
                    <>
                      {getdata && getdata.length > 0
                        ? getdata.map((item, index) => {
                            return (
                              <>
                                <div className="family_req_receved_single_card">
                                  <img
                                    // onClick={() => {
                                    //   navigate("/productview", {
                                    //     state: {
                                    //       release_bond_id: item.release_bond_id,
                                    //       product_id: item.product_id,
                                    //       is_nfb: item.is_nfb,
                                    //       uniq_code: item.uniq_code,
                                    //       theme_index: item.theme_index,
                                    //       share_by_user: item.share_by_user,
                                    //       club_id: item.club_id,
                                    //     },
                                    //   });
                                    // }}
                                    src={item.reward_img_video[0]?.img_video}
                                    alt=""
                                  />
                                  <div className="family_req_receved_single_card_name">
                                    <h4 style={{ fontSize: "12px" }}>
                                      {item.reward_name}
                                    </h4>
                                    {/* <p>Request Content</p> */}
                                    {/* <p>{item.c_and_w_request_time}</p> */}
                                    {/* <p>
                                      {moment(
                                        item.bid_benifit_last_date
                                      ).format("DD-MM-YYYY")}
                                    </p> */}
                                  </div>
                                  <div className="family_req_receved_single_card_btn_wrapp">
                                    <button
                                      onClick={() => {
                                        handleClaimReward(item);
                                      }}
                                      disabled={item.is_by == 0}
                                      className="family_req_receved_single_card_lrg_btn"
                                    >
                                      <p>
                                        {item.is_claim == 1
                                          ? "Explore"
                                          : item.is_claim == 2
                                          ? "Confirm"
                                          : item.is_claim == 3
                                          ? "Approved"
                                          : "Add"}
                                      </p>
                                    </button>
                                  </div>
                                </div>
                              </>
                            );
                          })
                        : null}
                    </>
                  )}
                </>
              </div>
            </>

            {/* recevied list end */}
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default CandWBenefit;
