import React, { useEffect, useRef, useState } from "react";
import "./bidscreate.css";
import images from "../../constants/images";
import { useLocation, useNavigate } from "react-router-dom";
import NavbarSec from "../../common/NavbarSec";
import Cardthree from "../../components/feedcard/cardthree";
import {
  create_bid,
  get_user_balance,
  platform_fees,
  platform_feesbid,
  update_bid,
  user_Check_m_pin,
  user_Send_otp_for_bond,
} from "../../utils/Constant";
import axios from "axios";
import CusModal from "../../common/cusmodal/CusModal";
import Urls from "../../utils/Urls";
import { loadStripe } from "@stripe/stripe-js";
import ReactModal from "react-modal";
import NewModal from "../../common/newmodal/NewModal";
import V2Modal from "../../common/v2modal/V2Modal";
import NewLoader from "../../common/newloder/NewLoader";

const Bidscreate = () => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "0px",
      backgroundColor: "none",
      border: "none",
      borderRadius: "12px",
    },
    overlay: {
      zIndex: 1000,
      backgroundColor: "rgba(0, 0, 0, 0.3)",
    },
  };
  const location = useLocation();

  const [loading, setloading] = useState(false);
  const [getok, setok] = useState(null);
  const userId = localStorage.getItem("user_id");
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [condition, setCondition] = useState(false);
  const [fees_count, setfees_count] = useState(0);
  const [company_royalty, setcompany_royalty] = useState(0);
  const [creator_royalty, setcreator_royalty] = useState(0);
  const [first_buyer_royalty, setfirst_buyer_royalty] = useState(0);
  const [second_buyer_royalty, setsecond_buyer_royalty] = useState(0);
  const [mpin_status, setmpin_status] = useState("");
  const [total_amount_bid, settotal_amount_bid] = useState(0);
  const [my_balance, setmy_balance] = useState(0);
  const [getis_active, setis_active] = useState("");
  const [getwallet_charge, setwallet_charge] = useState("");
  const [walletmassage, setwalletmassage] = useState("");
  const [walletmodel, setwalletmodel] = useState(false);
  const [isModalOpen, setisisModalOpen] = useState(false);
  const [benifit_price, setbenifit_price] = useState(
    location.state ? location.state.benifit_price : 0
  );

  const [successbidmodel, setsuccessmodel] = useState(false);
  const [errbidmodel, setErrBidmodel] = useState(false);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [getproductname, setProductname] = useState("");
  const [getplatform_fees_data, setPlatform_fees_data] = useState({});
  const [response, setResponsesession] = useState(null);

  useEffect(() => {
    getReleasebond();
    Getcheckbalance();

    console.log("total_amount_bid", total_amount_bid);
  }, []);

  // -----------------------------------------------------------

  // -----------------------------------------------------------

  const getReleasebond = () => {
    var new_acc = location.state ? location.state.accept_bond_bid_id : 0;
    var new_glbaolc = location.state ? location.state.is_globle : 0;
    setloading(true);
    const param = {
      bid_amount: location.state?.amount,
      release_bond_id: location.state?.release_bond_id,
      // bid_id: location.state?.bid_id,
      uniq_code: location.state?.uniq_code,
      is_nfb: location.state?.is_nfb,
      product_id: location.state?.product_id,
      accept_bond_bid_id: new_acc,
      user_id: userId,
      is_globle: new_glbaolc,
    };
    console.log(param);
    axios
      .post("https://www.ravi.host/api/platform_fees", param)
      .then((Response) => {
        console.log("acdascs", Response.data.result);
        setloading(false);
        if (Response.data.status == 1) {
          const data = Response.data.result;
          setfees_count(data.platform_fee);
          setcompany_royalty(data.company_royalty);
          setcreator_royalty(data.creator_royalty);
          setfirst_buyer_royalty(data.first_buyer_royalty);
          setsecond_buyer_royalty(data.second_buyer_royalty);
          setmpin_status(data.is_m_pin);
          setis_active(data.is_active);
          setwallet_charge(data.wallet_charge);
          setProductname(data.get_product_name);
          settotal_amount_bid(
            parseFloat(
              parseFloat(location.state?.amount) +
                parseFloat(benifit_price) +
                parseFloat(fees_count) +
                parseFloat(company_royalty) +
                parseFloat(creator_royalty) +
                parseFloat(first_buyer_royalty) +
                parseFloat(second_buyer_royalty) +
                parseInt(getplatform_fees_data.is_active == 0 ? 100 : 0) +
                parseFloat(getplatform_fees_data.total_gst)
            ).toFixed(2)
          );
          // settotal_amount_bid(
          //   parseFloat(location.state.amount) +
          //     parseFloat(fees_count) +
          //     parseFloat(company_royalty) +
          //     parseFloat(creator_royalty) +
          //     parseFloat(first_buyer_royalty) +
          //     parseFloat(second_buyer_royalty) +
          //     parseFloat(benifit_price) +
          //     parseFloat(getplatform_fees_data.total_gst)
          // );
          setPlatform_fees_data(data);
        } else {
          setfees_count(0);
        }
        // this.setState({
        //   // data: Response.data.result,
        //   task_arrayholder: Response.data.result,
        // });
      })
      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };

  const Getcheckbalance = () => {
    setloading(true);
    const param = {
      user_id: userId,
    };
    console.log(param);
    axios
      .post(get_user_balance, param)
      .then((Response) => {
        console.log("acdascs", Response.data);
        setloading(false);
        if (Response.data.status == 1) {
          const data = Response.data;
          setmy_balance(data.total);
        } else {
          setmy_balance(0);
        }
        // this.setState({
        //   // data: Response.data.result,
        //   task_arrayholder: Response.data.result,
        // });
      })
      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };

  // Storing data in sessionStorage
  const saveDataToSessionStorage = (key, data) => {
    const storedData = JSON.parse(sessionStorage.getItem(key)) || [];
    storedData.push(data);
    sessionStorage.setItem(key, JSON.stringify(storedData));
  };

  // Retrieving data from sessionStorage
  const getDataFromSessionStorage = (key) => {
    return JSON.parse(sessionStorage.getItem(key)) || [];
  };

  const stripePromise = loadStripe(
    "pk_live_51ODMf4SIZS16AN382v1WLRYA0m5T0xqKIjkWwJCYo2eLmEVR6JlSxQM1MIgvQNl6NJewKCQBR2F6JqMqhKrhm5O7000ONaUVem"
  );

  const makePayment = async () => {
    setloading(true);
    try {
      const apiUrl = "https://www.ravi.host/api/stripe_payment";
      const datas = {
        user_id: userId,
        amount: 1, // Replace with the desired amount
        // amount: getplatform_fees_data.stripe_total, // Replace with the desired amount
        success_url: ` ${Urls.main_url}Success`, // Replace with your success URL
        fail_url: `${Urls.main_url}Fail`, // Replace with your fail URL
      };
      console.log("dddd", datas);
      console.log("location.state", location.state);
      const response = await axios.post(apiUrl, datas);
      console.log("Response: " + JSON.stringify(response.data.success));
      // Handle the response data as needed
      setResponsesession(response.data.session_id);
      if (response.data.success === true) {
        setloading(false);
        saveDataToSessionStorage("myItems", getplatform_fees_data);
        saveDataToSessionStorage("location_data", location.state);
        // saveDataToSessionStorage("total_ammount", total_amount_bid);
        localStorage.setItem("allres", JSON.stringify(response.data));

        const stripe = await stripePromise;
        await stripe.redirectToCheckout({
          sessionId: response.data.session_id,
        });
      } else {
        alert("something went wrong ");
        sessionStorage.clear();
        setloading(false);
      }
    } catch (error) {
      // Handle errors
      console.error("Error making payment:", error);
      setloading(false);
    }
  };

  const navigate = useNavigate();

  const Usercheckotp = () => {
    console.log("blance", total_amount_bid);
    // console.log("blance", parseInt(total_amount_bid) <= parseInt(my_balance));
    if (parseFloat(full_amount) <= parseFloat(my_balance)) {
      if (mpin_status == 1) {
        setisisModalOpen(true);
        setButtonDisabled(false);
      } else {
        setloading(true);
        const param = {
          user_id: userId,
        };
        console.log(param);
        axios
          .post(user_Send_otp_for_bond, param)
          .then((Response) => {
            setButtonDisabled(false);
            console.log("acdascs", Response.data);
            setloading(false);
            if (Response.data.status == 1) {
              navigate("/mpin", {
                state: {
                  otp: Response.data.user_otp,
                },
              });
            } else {
              setErrorMessage(Response.data.message);
              setShowModal(true);
            }
            // this.setState({
            //   // data: Response.data.result,
            //   task_arrayholder: Response.data.result,
            // });
          })
          .catch((err) => {
            setloading(false);
            setButtonDisabled(false);

            console.log(err);
          });
      }
    } else {
      // setwalletmodel('')
      setwalletmodel(true);
      setButtonDisabled(false);
    }
  };

  const Updatebid = () => {
    setisisModalOpen(false);
    const otpValueEmail = otpValue;
    console.log("otpValueEmail", otpValueEmail);
    if (otpValueEmail == "") {
      setShowModal(true);
      setErrorMessage("Please enter m-PIN");
    } else if (otpValueEmail.length < 4) {
      setShowModal(true);
      setErrorMessage("Please enter valid m-PIN");
    } else {
      setloading(true);

      const param = {
        user_id: userId,
        m_pin: otpValueEmail,
      };
      console.log("parammpin", param);
      axios
        .post(user_Check_m_pin, param)
        .then((Response) => {
          console.log("acdascs", Response.data);
          setloading(false);
          if (Response.data.status == 1) {
            bidplace();
          } else {
            setErrorMessage(Response.data.message);
            setShowModal(true);
          }
          // this.setState({
          //   // data: Response.data.result,
          //   task_arrayholder: Response.data.result,
          // });
        })
        .catch((err) => {
          setloading(false);
          console.log(err);
        });
    }
  };

  const bidplace = () => {
    setloading(true);
    const param = {
      bond_launch_user_id: getplatform_fees_data.bond_launch_user_id,
      creator_id: getplatform_fees_data.creator_id,
      first_buyer_id: getplatform_fees_data.first_buyer_id,
      second_buyer_id: getplatform_fees_data.second_buyer_id,
      get_buyer_rank: getplatform_fees_data.get_buyer_rank,
      company_royalty: getplatform_fees_data.company_royalty,
      creator_royalty: getplatform_fees_data.creator_royalty,
      first_buyer_royalty: getplatform_fees_data.first_buyer_royalty,
      second_buyer_royalty: getplatform_fees_data.second_buyer_royalty,

      company_royalty_gst: getplatform_fees_data.company_royalty_gst,
      platform_fee_gst: getplatform_fees_data.platform_fee_gst,
      bond_launch_user_gst: getplatform_fees_data.bond_launch_user_gst,
      first_buyer_royalty_gst: getplatform_fees_data.first_buyer_royalty_gst,
      second_buyer_royalty_gst: getplatform_fees_data.second_buyer_royalty_gst,

      user_id: userId,
      // bid_id: location.state?.bid_id,
      bid_amount: location.state.amount,
      total:
        parseFloat(location.state.amount) +
        parseFloat(fees_count) +
        parseFloat(company_royalty) +
        parseFloat(creator_royalty) +
        parseFloat(first_buyer_royalty) +
        parseFloat(second_buyer_royalty) +
        parseFloat(getplatform_fees_data.total_gst) +
        parseFloat(benifit_price),
      release_bond_id: location.state.release_bond_id,
      platform_fees: fees_count,
      uniq_code: location.state.uniq_code,
      is_nfb: location.state.is_nfb,
      partner_benifit_id: location.state.partner_benifit_id,
      partner_id: location.state.partned_id,
      benifit_price: location.state.benifit_price,
      product_assign_id: location.state?.product_assign_id,
      share_by_user: location.state?.share_by_user,
      is_club:
        location.state.is_club_id == undefined ? 0 : location.state.is_club_id,
      club_id: location.state.is_club == undefined ? 0 : location.state.is_club,
      original_partner_benifit_price:
        location.state?.original_partner_benifit_price,
      is_active: getis_active == 1 ? 0 : 118,
      wallet_charge: getis_active == 1 ? 0 : 118,
      total_gst: getplatform_fees_data.total_gst,
      pending_c_and_w_id: location.state.pending_c_and_w_id
        ? location.state.pending_c_and_w_id
        : "",
      // company_royalty_gst: getplatform_fees_data.company_royalty_gst,
    };
    console.log("assacacacasavsedav", param);
    axios
      .post(create_bid, param)
      .then((Response) => {
        setloading(false);
        if (Response.data.status == 1) {
          // navigate("/mpin", {
          //   state: {
          //     otp: Response.data.user_otp,
          //   },
          // });
          setErrorMessage(Response.data.message);
          setsuccessmodel(true);
          setCondition(true);
          // navigate("/homescreen2");
          setisisModalOpen(false);
        } else {
          setErrorMessage(Response.data.message);
          setErrBidmodel(true);
        }
        // this.setState({
        //   // data: Response.data.result,
        //   task_arrayholder: Response.data.result,
        // });
      })
      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };

  const [mpin, setMpin] = useState(["", "", "", ""]);
  const mpinRefs = useRef([]);

  const handleMpinChange = (index, value) => {
    if (/^\d*$/.test(value) && value.length <= 1) {
      const newMpin = [...mpin];
      newMpin[index] = value;
      setMpin(newMpin);
      if (value !== "" && index < mpin.length - 1) {
        mpinRefs.current[index + 1].focus();
      }
    }
  };

  const [mobileotp, setMobileOtp] = React.useState(["", "", "", ""]);
  const refsMobile = [useRef(), useRef(), useRef(), useRef()];

  const handleInputChangeMobile = (index, event) => {
    const value = event.target.value;
    if (!isNaN(value) && value.length <= 1) {
      const newOtp = [...mobileotp];
      newOtp[index] = value;
      setMobileOtp(newOtp);

      if (index < refsMobile.length - 1 && value !== "") {
        refsMobile[index + 1].current.focus();
      }
    }
  };

  const handleKeyDownMobile = (index, event) => {
    if (event.key === "Backspace" && index > 0 && mobileotp[index] === "") {
      const newOtp = [...mobileotp];
      newOtp[index - 1] = "";
      setMobileOtp(newOtp);
      refsMobile[index - 1].current.focus();
    }
  };
  const otpValue = mobileotp.join("");

  const closemodal = () => {
    setsuccessmodel(false);
  };

  const closemodal1 = () => {
    setErrBidmodel(false);
  };

  const openmodal = () => {
    setsuccessmodel(true);
  };
  const closemodal2 = () => {
    setShowModal(false);
  };

  const openmodal2 = () => {
    setShowModal(true);
  };

  const full_amount = parseFloat(
    parseFloat(location.state?.amount) +
      parseFloat(benifit_price) +
      parseFloat(fees_count) +
      parseFloat(company_royalty) +
      parseFloat(creator_royalty) +
      parseFloat(first_buyer_royalty) +
      parseFloat(second_buyer_royalty) +
      parseInt(getplatform_fees_data.is_active == 0 ? 100 : 0) +
      parseFloat(getplatform_fees_data.total_gst)
  ).toFixed(2);

  return (
    <div className="page_main_wrapp">
      <div className="page_base_wrapp_b">
        {loading ? (
          <NewLoader />
        ) : (
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div className="bidlist1">
              <center style={{ height: "60px" }}>
                <div className="v2modal_fixed_logo_wrapp">
                  <img src={images.logo} alt="" />
                </div>
                {/* <img src={images.logo} className="bidlist_img" alt="" /> */}
              </center>
              <p
                style={{
                  textAlign: "center",
                  width: "100%",
                  alignSelf: "center",
                  fontFamily: "var(--font-600)",
                }}
              >
                {getproductname}
              </p>
              <p
                style={{
                  textAlign: "center",
                  width: "100%",
                  alignSelf: "center",
                  fontFamily: "var(--font-600)",
                  fontSize: "12px",
                  padding: "10px 20px",
                }}
              >
                {/* If your bid is accepted by the seller, you will become the owner
                with a rank of {getplatform_fees_data.get_buyer_rank}. */}
                If your bid is accepted by the seller, you will be the{" "}
                {getplatform_fees_data.get_buyer_rank} buyer. And you will
                receive {getplatform_fees_data.get_buyer_rank} buyer royalty.
              </p>
              <div className="nm_bid_list_view_demo">
                <div className="bid_list_nm_set_main">
                  <p className="bid_list_nm_set">Aucemo Bid </p>
                  <p className="bid_list_nm_set2">₹{location.state?.amount}</p>
                </div>
                <div className="bid_list_nm_set_main">
                  <p
                    className="bid_list_nm_set"
                    style={{ lineHeight: "normal" }}
                  >
                    Processing Fees <br />
                    <span
                      style={{
                        fontFamily: "var(--font-300)",
                        color: "var(--color-white)",
                        fontSize: "12px",
                      }}
                    >
                      (Non refundable)
                    </span>
                  </p>
                  <p className="bid_list_nm_set2">₹{fees_count}</p>
                </div>
                {getis_active == 0 ? (
                  <div className="bid_list_nm_set_main">
                    <p className="bid_list_nm_set">Wallet Charges</p>
                    <p className="bid_list_nm_set2">
                      ₹{getis_active == 1 ? 0 : 118}
                    </p>
                  </div>
                ) : null}
                <div className="company_royaltymain">
                  <div className="company_royalty_sub" style={{ gap: "10px" }}>
                    <p
                      className="bid_list_nm_set"
                      style={{ lineHeight: "14px" }}
                    >
                      Platform commission
                    </p>
                    {creator_royalty == 0 ? null : (
                      <p className="bid_list_nm_set">Creator Royalty</p>
                    )}
                    {first_buyer_royalty == 0 ? null : (
                      <p className="bid_list_nm_set"> First Buyer</p>
                    )}
                    {second_buyer_royalty == 0 ? null : (
                      <p className="bid_list_nm_set"> Second Buyer</p>
                    )}
                  </div>
                  <div className="company_royalty_sub2" style={{ gap: "10px" }}>
                    <p className="bid_list_nm_set2">₹{company_royalty}</p>
                    {creator_royalty == 0 ? null : (
                      <p className="bid_list_nm_set2">₹{creator_royalty}</p>
                    )}
                    {first_buyer_royalty == 0 ? null : (
                      <p className="bid_list_nm_set2">₹{first_buyer_royalty}</p>
                    )}
                    {second_buyer_royalty == 0 ? null : (
                      <p className="bid_list_nm_set2">
                        ₹{second_buyer_royalty}
                      </p>
                    )}
                  </div>
                </div>
                <div className="bid_list_nm_set_main">
                  <p
                    className="bid_list_nm_set"
                    style={{ lineHeight: "normal" }}
                  >
                    {" "}
                    Benefit Bid <br />
                    <span
                      style={{
                        fontFamily: "var(--font-300)",
                        color: "var(--color-white)",
                        fontSize: "12px",
                      }}
                    >
                      (incl. Processing fees)
                    </span>
                  </p>
                  <p className="bid_list_nm_set2">₹ {benifit_price}</p>
                </div>
              </div>

              <hr />
              <div className="bid_total" style={{ padding: "10px 20px" }}>
                <div className="nm_bid_total">
                  <p className="total_nm_list">GST</p>
                </div>
                <div className="amnt_bid_list">
                  <p className="total_nm_list">
                    ₹ {getplatform_fees_data.total_gst}
                  </p>
                </div>
              </div>
              <div className="bid_total" style={{ padding: "10px 20px" }}>
                <div className="nm_bid_total">
                  <p className="total_nm_list">Total</p>
                </div>
                <div className="amnt_bid_list">
                  <p className="total_nm_list">
                    ₹
                    {parseFloat(
                      parseFloat(location.state?.amount) +
                        parseFloat(benifit_price) +
                        parseFloat(fees_count) +
                        parseFloat(company_royalty) +
                        parseFloat(creator_royalty) +
                        parseFloat(first_buyer_royalty) +
                        parseFloat(second_buyer_royalty) +
                        parseInt(
                          getplatform_fees_data.is_active == 0 ? 100 : 0
                        ) +
                        parseFloat(getplatform_fees_data.total_gst)
                    ).toFixed(2)}
                  </p>
                </div>
              </div>
              <div
                className="list_bid_btn"
                style={{
                  width: "90%",
                  display: "flex",
                  alignItems: "center",
                  // border: "1px solid red",
                  margin: "10px auto",
                  justifyContent: "space-between",
                }}
              >
                <button
                  onClick={() => {
                    window.history.back();
                  }}
                  className="new_skip_btn"
                >
                  Cancel
                </button>
                <button
                  // disabled={isButtonDisabled}
                  onClick={() => {
                    Usercheckotp();
                    // const storedItems = getDataFromSessionStorage("myItems");
                    // console.log(storedItems);
                    // makePayment();

                    // setButtonDisabled(true);
                    // setisisModalOpen(true);
                  }}
                  className="new_btn_wrapp"
                  style={{ width: "48%", height: "55px" }}
                >
                  <p>Place bid</p>
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      {showModal && (
        // <CusModal
        //   msg={errorMessage}
        //   setShowModal={setShowModal}
        //   showModal={showModal}
        // />

        // <NewModal
        //   title=""
        //   msg={errorMessage}
        //   setShowModal={openmodal2}
        //   closemodal={closemodal2}
        // />

        <V2Modal
          canclebtn={false}
          title=""
          msg={errorMessage}
          setShowModal={setShowModal}
          okfunction={closemodal2}
        />
      )}
      {walletmodel && (
        <div className="cusModal_main_wrapp">
          <div className="cusmodal_inner_wrapp">
            <img src={images.logo} alt="" className="cm_logo" />
            <p className="cm_heading"></p>
            <div className="cm_des_wrapp">
              <p className="cm_des">Please recharge your wallet now</p>
              <div className="cm_hr"></div>
              <button className="cm_btn" onClick={() => navigate("/wallet")}>
                Okay
              </button>
            </div>
          </div>
        </div>
      )}
      {/* <div className="list_bid_btn">
        <button className="list_bid_btn1">Cancel</button>
        <button className="list_bid_btn1">Place bid</button>
      </div> */}
      {/* Step 4: Render the modal when isModalOpen is true */}
      {isModalOpen && (
        // Apply CSS styles for the bottom modal
        <div className="modal_bottom">
          <div className="modal-content1">
            <center>
              <img src={images.logo} className="bidlist_img2" alt="" />
            </center>
            <p className="bidslist_new_nm">Enter m-Pin</p>
            <div>
              <div
                style={{
                  alignSelf: "center",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                className="otp_input_container"
              >
                {mobileotp.map((digit, index) => (
                  <input
                    style={{
                      borderWidth: 0.5,
                    }}
                    key={index}
                    // security=""
                    type="password"
                    maxLength={1}
                    className="in_de_otp"
                    ref={refsMobile[index]}
                    value={digit}
                    onChange={(event) => handleInputChangeMobile(index, event)}
                    onKeyDown={(event) => handleKeyDownMobile(index, event)}
                    inputMode="numeric"
                  />
                ))}
              </div>
            </div>
            <div className="list_bid_btn2">
              <button
                className="list_bid_btn3"
                onClick={() => {
                  setisisModalOpen(false);
                }}
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  Updatebid();
                }}
                className="list_bid_btn1"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
      {successbidmodel && (
        // <div className="cusModal_main_wrapp">
        //   <div
        //     className="cusmodal_inner_wrapp"
        //     style={{ background: "#080707e6" }}
        //   >
        //     <img src={images.logo} alt="" className="cm_logo" />
        //     <p className="cm_heading"></p>
        //     <div
        //       className="cm_des_wrapp"
        //       style={{ height: "200px", justifyContent: "space-around" }}
        //     >
        //       <p
        //         className="cm_des"
        //         style={{ fontFamily: "var(--font-600)", fontSize: "14px" }}
        //       >
        //         {errorMessage}
        //       </p>
        //       <div className="cm_hr"></div>
        //       <button
        //         className="cm_btn"
        //         onClick={() => {
        //           setsuccessmodel(false);
        //           navigate("/homescreen2");
        //         }}
        //       >
        //         Okay
        //       </button>
        //     </div>
        //   </div>
        // </div>

        // <ReactModal
        //   isOpen={setShowModal}
        //   // onAfterOpen={afterOpenModal}
        //   onRequestClose={closemodal}
        //   style={customStyles}
        // >
        //   <div className="NewModal_main_wrapp">
        //     <div
        //       style={{
        //         display: "flex",
        //         flexDirection: "column",
        //         alignItems: "center",
        //         justifyContent: "center",
        //       }}
        //     >
        //       <img className="newmodal_logo" src={images.logo} alt="" />
        //       <p className="newmodal_titel"></p>
        //       {/* <p className="newmodal_titel">Alert</p> */}
        //     </div>
        //     {/* <div className="newmodal_des_wrapp"> */}
        //     <p className="newmodal_des">{errorMessage}</p>
        //     {/* </div> */}

        //     <button
        //       className="newmodal_btn"
        //       onClick={() => {
        //         setsuccessmodel(false);
        //         navigate("/homescreen2");
        //       }}
        //     >
        //       Okay
        //     </button>
        //   </div>
        // </ReactModal>

        <V2Modal
          canclebtn={false}
          title=""
          msg={errorMessage}
          setShowModal={setsuccessmodel}
          okfunction={() => {
            setsuccessmodel(false);
            navigate("/homescreen2");
          }}
        />
      )}
      {errbidmodel && (
        // <div className="cusModal_main_wrapp">
        //   <div className="cusmodal_inner_wrapp">
        //     <img src={images.logo} alt="" className="cm_logo" />
        //     <p className="cm_heading"></p>
        //     <div className="cm_des_wrapp">
        //       <p className="cm_des">{errorMessage}</p>
        //       <div className="cm_hr"></div>
        //       <button
        //         className="cm_btn"
        //         onClick={() => {
        //           setErrBidmodel(false);
        //           navigate("/homescreen2");
        //         }}
        //       >
        //         Okay
        //       </button>
        //     </div>
        //   </div>
        // </div>

        // <ReactModal
        //   isOpen={setErrBidmodel}

        //   onRequestClose={closemodal1}
        //   style={customStyles}
        // >
        //   <div className="NewModal_main_wrapp">
        //     <div
        //       style={{
        //         display: "flex",
        //         flexDirection: "column",
        //         alignItems: "center",
        //         justifyContent: "center",
        //       }}
        //     >
        //       <img className="newmodal_logo" src={images.logo} alt="" />
        //       <p className="newmodal_titel"></p>

        //     </div>

        //     <p className="newmodal_des">{errorMessage}</p>

        //     <button
        //       className="newmodal_btn"
        //       onClick={() => {
        //         setErrBidmodel(false);
        //         navigate("/homescreen2");
        //       }}
        //     >
        //       Okay
        //     </button>
        //   </div>
        // </ReactModal>

        <V2Modal
          canclebtn={false}
          title=""
          msg={errorMessage}
          setShowModal={setErrBidmodel}
          okfunction={() => {
            setErrBidmodel(false);
            navigate("/homescreen2");
          }}
        />
      )}
    </div>
  );
};

export default Bidscreate;
