import React, { useEffect, useState } from "react";
import "./CreatorCandWRequest.css";
import NavbarSec from "../../common/NavbarSec";
import images from "../../constants/images";
import axios from "axios";
import qs from "qs";
import {
  IoIosCheckmarkCircleOutline,
  IoIosCloseCircleOutline,
} from "react-icons/io";
import NewLoader from "../../common/newloder/NewLoader";
import { FaAngleLeft, FaCheck } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { IoClose } from "react-icons/io5";
import { FiCheck } from "react-icons/fi";
import moment from "moment";

const CreatorCandWRequest = () => {
  const userId = localStorage.getItem("user_id");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const [getdatafamily, setdatafamily] = useState([]);

  const _getData_CandWrequest = () => {
    const param = {
      user_id: userId,
    };
    console.log(param);
    setIsLoading(true);

    axios
      .post(
        "https://www.ravi.host/api/get_creater_c_and_w_bond_request",
        qs.stringify(param)
      )
      .then((response) => {
        console.log("get_user_family_request_responcw", response.data);
        setIsLoading(false);

        if (response.data.status == 1) {
          setdatafamily(response.data.user_c_and_w_request);
        } else {
          setdatafamily([]);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const _setAcceptReject_CandW = (id, status) => {
    const param = {
      pending_c_and_w_bond_request_id: id,
      is_type: status,
    };
    console.log(param);
    setIsLoading(true);

    axios
      .post(
        "https://www.ravi.host/api/c_and_w_request_accept_reject",
        qs.stringify(param)
      )
      .then((response) => {
        console.log("res", response.data);
        setIsLoading(false);

        if (response.data.status == 1) {
          _getData_CandWrequest();
        } else {
          _getData_CandWrequest();
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    _getData_CandWrequest();
  }, []);

  // vishal code start

  return (
    <div className="new_wrapp_container reletive_position">
      <img
        src={images.new_bg_circule}
        alt=""
        className="back_circule_shadow_left"
      />
      {isLoading ? (
        <NewLoader />
      ) : (
        <div className="new_container">
          <div
            className="play_screen_main_wrapp"
            style={{ padding: "10px 5%" }}
          >
            <div className="legacy_dashbord_nav_sec">
              <button
                onClick={() => {
                  navigate("/Sidemenu");
                }}
              >
                <FaAngleLeft color="var(--color-white)" size={20} />
              </button>
              <p>C & W Requests</p>
              <div></div>
            </div>

            {/* recevied list start */}

            <>
              {/* request tabs section end */}

              {/* recevied list start */}
              <div className="family_req_receved_list">
                <>
                  {getdatafamily && getdatafamily.length == 0 ? (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <p>No requests found</p>
                    </div>
                  ) : (
                    <>
                      {getdatafamily && getdatafamily.length > 0
                        ? getdatafamily.map((item, index) => {
                            return (
                              <>
                                <div className="family_req_receved_single_card">
                                  <img
                                    onClick={() => {
                                      navigate("/productview", {
                                        state: {
                                          release_bond_id: item.release_bond_id,
                                          product_id: item.product_id,
                                          is_nfb: item.is_nfb,
                                          uniq_code: item.uniq_code,
                                          theme_index: item.theme_index,
                                          share_by_user: item.share_by_user,
                                          club_id: item.club_id,
                                        },
                                      });
                                    }}
                                    src={item.img_video}
                                    alt=""
                                  />
                                  <div className="family_req_receved_single_card_name">
                                    <h4 style={{ fontSize: "12px" }}>
                                      {item.product_name}
                                    </h4>
                                    {/* <p>Request Content</p> */}
                                    {/* <p>{item.c_and_w_request_time}</p> */}
                                    <p
                                      style={{ width: "80%" }}
                                      className="candw_request_time_text"
                                    >
                                      You can accept this request till{" "}
                                      {moment(item.c_and_w_request_time).format(
                                        "DD-MM-YYYY"
                                      )}
                                    </p>
                                  </div>
                                  <div className="family_req_receved_single_card_btn_wrapp">
                                    <button
                                      onClick={() => {
                                        _setAcceptReject_CandW(
                                          item.pending_c_and_w_bond_request_id,
                                          1
                                        );
                                      }}
                                      className="family_req_receved_single_card_btn"
                                    >
                                      <FiCheck
                                        size={20}
                                        color="var(--color-white)"
                                      />
                                    </button>
                                    <button
                                      onClick={() => {
                                        _setAcceptReject_CandW(
                                          item.pending_c_and_w_bond_request_id,
                                          2
                                        );
                                      }}
                                      className="family_req_receved_single_card_btn"
                                    >
                                      <IoClose
                                        size={20}
                                        color="var(--color-white)"
                                      />
                                    </button>
                                  </div>
                                </div>
                              </>
                            );
                          })
                        : null}
                    </>
                  )}
                </>
              </div>
            </>

            {/* recevied list end */}
          </div>
        </div>
      )}
    </div>
  );
};

export default CreatorCandWRequest;
