import React from "react";
import "./NewAssetCreatorScreen.css";
import { useLocation, useNavigate } from "react-router-dom";
import { FaAngleLeft } from "react-icons/fa6";
import images from "../../constants/images";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { EffectCoverflow, Pagination } from "swiper";
import { useState } from "react";
import { useEffect } from "react";
import { accept_bond_verify_request } from "../../utils/Constant";
import qs from "qs";
import axios from "axios";

// import { Pagination } from "swiper/modules";

const NewAssetCreatorScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setdata] = useState(null);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    console.log("first", location.state.data);
    setdata(location.state.data);
  }, [data]);

  const AcceptRejectRequest = async (id) => {
    const param = {
      bond_verify_id: data.bond_verify_id,
      is_accept: id,
    };

    await axios
      .post(accept_bond_verify_request, qs.stringify(param))
      .then((response) => {
        setLoading(false);
        console.log("response", response);
        navigate(-1);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  return (
    <div className="new_wrapp_container reletive_position">
      <img
        src={images.new_bg_circule}
        alt=""
        className="back_circule_shadow_left"
      />
      <div className="new_container">
        <div
          className="back_arrow_wrapp"
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            justifyContent: "space-between",
          }}
        >
          <button
            onClick={() => {
              navigate(-1);
            }}
          >
            <FaAngleLeft color="var(--color-white)" size={20} />
          </button>
          <div className="new_asset_creator_user_wrapp">
            {/* <img src={data.profile} alt="" /> */}
            <div className="newassetcreator_request_card_img_wrapp">
              <img src={data?.profile} alt="" />
            </div>
            <p>{data?.user_name}</p>
          </div>

          <div style={{ width: "13%" }}></div>
        </div>

        {/* Assets slider start */}
        <div className="new_certificate_slider">
          <Swiper
            effect={"coverflow"}
            grabCursor={true}
            centeredSlides={true}
            slidesPerView={1.5}
            coverflowEffect={{
              rotate: 30,
              stretch: 0,
              depth: 200,
              modifier: 1,
              slideShadows: true,
            }}
            pagination={false}
            modules={[EffectCoverflow, Pagination]}
            className="mySwiper"
          >
            {data?.bond_img_video.map((item, index) => {
              return (
                <SwiperSlide>
                  <div className="slide-content">
                    <img src={item.img_video} alt="" className="slide-image" />
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>

          {/* Centered Text */}
          <div className="slider-caption">
            <p>Assets</p>
          </div>
        </div>
        {/* Assets slider end */}

        {/* Assets slider start */}
        <div className="new_certificate_slider" style={{ marginTop: "3rem" }}>
          <Swiper
            effect={"coverflow"}
            grabCursor={true}
            centeredSlides={true}
            //  slidesPerView={"1.5"}
            slidesPerView={1.5}
            coverflowEffect={{
              rotate: 30,
              stretch: 0,
              depth: 200,
              modifier: 1,
              slideShadows: true,
            }}
            pagination={false}
            modules={[EffectCoverflow, Pagination]}
            className="mySwiper"
          >
            <SwiperSlide>
              <div className="slide-content">
                <img src={data?.owner_proof} alt="" className="slide-image" />
              </div>
            </SwiperSlide>
            {/* <SwiperSlide>
              <div className="slide-content">
                <img src={images.media4} alt="" className="slide-image" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="slide-content">
                <img src={images.media3} alt="" className="slide-image" />
              </div>
            </SwiperSlide> */}
          </Swiper>

          {/* Centered Text */}
          <div className="slider-caption">
            <p>Authentication Files</p>
          </div>
        </div>
        {/* Assets slider end */}

        {/* btns wrapp start */}

        <div className="new_asset_creator_btns_wrapp">
          <button
            onClick={() => {
              AcceptRejectRequest(1);
            }}
            className="myproduct_13_sub2_button_a_hover"
          >
            <div>Accept</div>
          </button>
          <button
            onClick={() => {
              AcceptRejectRequest(2);
            }}
            className="myproduct_13_sub2_button_a "
          >
            <div>Reject</div>
          </button>
        </div>

        {/* btns wrapp end */}
      </div>
    </div>
  );
};

export default NewAssetCreatorScreen;
