import React, { useEffect, useState } from "react";
import "../FeedNewFrame1.css";
import { useSwipeable } from "react-swipeable";
import images from "../../constants/images";
import axios from "axios";
import qs from "qs";
import { release_bond_like } from "../../utils/Constant";
import { useNavigate } from "react-router-dom";
import html2canvas from "html2canvas";

const ShowcaseNewFrame7 = ({
  setIsModalOpen,
  setMsg,
  setModalName,
  data,
  location,
  icon,
  handleData,
  windowWidth,
}) => {
  const navigate = useNavigate();

  const [isFlipped, setIsFlipped] = useState(false);
  const userId = localStorage.getItem("user_id");
  useEffect(() => {
    console.log("user_Id", userId);
    console.log("location", location);
  }, []);

  const handleSwipe = () => {
    setIsFlipped(!isFlipped);
  };
  const swipeHandlers = useSwipeable({
    onSwiped: handleSwipe,
  });

  const Bond_like = () => {
    const param = {
      user_id: location.user_id,
      bond_id: data.product_id,
      is_nfb: data.is_nfb,
      is_club: data.is_club,
      uniq_code: data.uniq_code,
      time: data.time,
      release_bond_id: data.release_bond_id,
      is_nfb_type: data.is_nfb_type,
    };
    console.log("====================================");
    console.log("param---->", param);
    console.log("====================================");
    axios
      .post(
        "https://www.ravi.host/api/user_own_bond_like_unlike",
        qs.stringify(param)
      )
      .then((Response) => {
        console.log("Response", Response.data);
        if (Response.data.status == 1) {
          window.location.reload();
          // setislikes(1);
          // onBondLikeSuccess();
          // daata();
          // setSetIndex(setindex);
        } else {
          window.location.reload();
          // onBondLikeSuccess();
          // setislikes(0);
          // daata();
        }
      });
  };
  const captureDivAsImage = () => {
    const divToCapture = document.getElementById("divToCapture"); // Replace with the ID or reference to your specific div
    if (!divToCapture) {
      console.error("Div not found");
      return;
    }

    html2canvas(divToCapture).then((canvas) => {
      // Convert the canvas to a data URL
      const imgData = canvas.toDataURL("image/png");

      // Create a link element to download the image
      const a = document.createElement("a");
      a.href = imgData;
      a.download = "captured_image.png"; // You can specify a custom filename
      a.click();
    });
  };
  const captureDivAsImage1 = () => {
    const divToCapture1 = document.getElementById("divToCapture1"); // Replace with the ID or reference to your specific div
    if (!divToCapture1) {
      console.error("Div not found");
      return;
    }

    html2canvas(divToCapture1).then((canvas) => {
      // Convert the canvas to a data URL
      const imgData1 = canvas.toDataURL("image/png");

      // Create a link element to download the image
      const a = document.createElement("a");
      a.href = imgData1;
      a.download = "captured_image.png"; // You can specify a custom filename
      a.click();
    });
  };

  // share functionality start

  const copyUrlToClipboard = () => {
    const urlToCopy = window.location.href;
    navigator.clipboard.writeText(data.qr_string).then(
      () => {
        // The URL has been copied successfully
        console.log("URL copied to clipboard:", data.qr_string);
      },
      (err) => {
        // Unable to copy URL
        console.error("Failed to copy URL:", err);
      }
    );
  };

  const handleShare = () => {
    const urlToShare = window.location.href;
    if (navigator.share) {
      navigator
        .share({
          title: "Share Profile",
          url: data.qr_string,
        })
        .then(
          () => {
            // The link has been shared successfully
            console.log("Link shared successfully:", data.qr_string);
          },
          (err) => {
            // Sharing failed
            console.error("Failed to share link:", err);
          }
        );
    } else {
      // Fallback to copy URL to clipboard if the Web Share API is not supported
      copyUrlToClipboard();
    }
  };

  // share functionality end

  const getImageSrc = (mediaList) => {
    if (!mediaList) return null;

    // Filter for 'new' images first
    // let filtered = mediaList.filter((media) => media.image_type === "new");
    // if (filtered.length > 0) {
    //   return filtered.map((media) => media.img_video)[0] || null;
    // }

    // Filter for 'new' images where user_id matches
    let filtered = mediaList.filter(
      (media) => media.image_type === "new" && media.user_id == data.owner_id
    );
    if (filtered.length > 0) {
      return filtered.map((media) => media.img_video)[0] || null;
    }

    // Fallback to 'old' images if no 'new' images are found
    filtered = mediaList.filter((media) => media.image_type === "old");
    if (filtered.length > 0) {
      return filtered.map((media) => media.img_video)[0] || null;
    }

    // Further fallback to images with 'type' as 'image'
    filtered = mediaList.filter((media) => media.type === "image");
    return filtered.map((media) => media.img_video)[0] || null;
  };

  return (
    <div className="blue-card-main-wrapp">
      <div
        className={`flip-container ${isFlipped ? "flipped" : ""}`}
        {...swipeHandlers}
      >
        {windowWidth > 786 && (
          <div
            onClick={() => setIsFlipped(!isFlipped)}
            style={{
              width: "90%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <img
              src={images.flip_icon}
              style={{ width: "30px", height: "30px", alignSelf: "flex-end" }}
            />
          </div>
        )}
        <div className="flipper">
          <div className="front">
            <img
              src={images.new_frame_7_f}
              onClick={() => {
                navigate("/ProductView2", {
                  state: {
                    uniq_code: data.uniq_code,
                    is_nfb: data.is_nfb,
                    product_id: data.product_id,
                    release_bond_id: data.release_bond_id,
                    theme_index: data.theme_index,
                    accept_bond_bid_id: data.accept_bond_bid_id,
                    share_by_user: data.share_by_user,
                    user_id: location.user_id,
                  },
                });
              }}
              className="frame-img"
              alt=""
            />
            <img
              src={
                data.bond_img_video ? getImageSrc(data.bond_img_video) : null
              }
              className="inner-img"
              alt="product img"
            />
            <div className="front-card-del-qr">
              <div className="front-card-del-div">
                <div className="front-card-del">
                  <p>
                    {data.product_name?.slice(0, 24)}
                    {data.product_name?.length > 24 ? "..." : null}
                  </p>
                  <p>
                    By : {data.username?.slice(0, 20)}{" "}
                    {data.creator_name?.length > 20 ? "..." : null}
                  </p>
                  <p>Product Value : ₹{data.product_value}</p>
                </div>
              </div>
              <div className="front-card-qr">
                <div className="qr-circle">
                  <img src={data.qr_code} alt="" />
                </div>
              </div>
            </div>
            {location && location.user_id == userId && (
              <div className="btn_showcardsecond">
                <div className="btn_showcardsecond_sub1">
                  {data.is_nfb_type == 0 ? (
                    <button
                      onClick={() => {
                        navigate("/resellcreateproduct", {
                          state: {
                            accept_bond_bid_id: data.accept_bond_bid_id,
                            is_nfb: data.is_nfb,
                            resale_product_id: data.resale_product_id,
                            bond_id: data.bond_id,
                          },
                        });
                      }}
                      className="show_second_btn"
                    >
                      SELL
                    </button>
                  ) : null}
                  {/* <button
                    onClick={captureDivAsImage}
                    className="show_second_btn"
                  >
                    SAVE
                  </button> */}
                  <a
                    // href={data.qr_string}
                    // target="_blank"
                    onClick={() => {
                      handleShare();
                    }}
                    className="show_second_btn"
                  >
                    SHARE
                  </a>
                </div>
                <div className="btn_showcardsecond_sub2">
                  <img
                    onClick={() => Bond_like()}
                    src={
                      // data.is_nfb_type == 0
                      //   ? images.new_launch_nfb_new
                      //   : data.is_nfb_type == 0
                      //   ? images.logo
                      //   : data.is_nfb_type == 2
                      //   ? images.logo
                      //   : data.is_nfb_type == 3
                      //   ? images.new_launch_nfb_new
                      //   : images.new_launch_nfb_new
                      data.is_nfb_type == 0
                        ? images.collection
                        : data.is_nfb_type == 0
                        ? images.collection
                        : data.is_nfb_type == 2
                        ? images.logo
                        : data.is_nfb_type == 3
                        ? images.new_launch_nfb_new
                        : images.new_launch_nfb_new
                    }
                    className="show_second_img"
                    alt=""
                  />{" "}
                </div>
              </div>
            )}
          </div>
          <div className="back">
            <img src={images.new_frame_7_b} className="frame-img" alt="" />
            {/* <img src={icon} className="inner-img" alt="" /> */}
            <div className="back-card-del-div">
              {data.desc && (
                <div style={{ width: "100%", marginBottom: "10px" }}>
                  <p className="des-name">Product Description</p>
                  <p className="des-content">
                    {data.desc?.slice(0, 78)} {data.desc?.length > 78 && "..."}
                  </p>
                  {data.desc?.length > 78 && (
                    <button
                      onClick={() => {
                        setIsModalOpen(true);
                        setMsg(data.desc);
                        setModalName("Product Description");
                      }}
                      className="fram_read_btn"
                    >
                      Read more
                    </button>
                  )}
                </div>
              )}

              {data.product_history && (
                <div style={{ width: "100%", marginBottom: "10px" }}>
                  <p className="des-name">Product story</p>
                  <p className="des-content">
                    {data.product_history?.slice(0, 78)}{" "}
                    {data.product_history?.length > 78 && "..."}
                  </p>
                  {data.product_history?.length > 78 && (
                    <button
                      onClick={() => {
                        setIsModalOpen(true);
                        setMsg(data.product_history);
                        setModalName("Product Story");
                      }}
                      className="fram_read_btn"
                    >
                      Read more
                    </button>
                  )}
                </div>
              )}

              {data.product_highlights && (
                <>
                  <p className="des-name">Product Highlight</p>
                  <p className="des-content">
                    {data.product_highlights?.slice(0, 78)}{" "}
                    {data.product_highlights?.length > 78 && "..."}
                  </p>
                  {data.product_highlights?.length > 78 && (
                    <button
                      onClick={() => {
                        setIsModalOpen(true);
                        setMsg(data.product_highlights);
                        setModalName("Product Highlight");
                      }}
                      className="fram_read_btn"
                    >
                      Read more
                    </button>
                  )}
                </>
              )}
            </div>
            <div className="owner-name-div">
              <p className="owned-by">Owned by</p>
              <p className="owner-name">{data.owner_name}</p>
            </div>
            <div className="back-card-del-qr">
              <div className="back-card-del-div2">
                <div className="back-card-del">
                  <p>{data.product_name}</p>
                  <p>By : {data.username}</p>
                  <p>aucemo Value : ₹{data.minimum_bid_amount}</p>
                </div>
              </div>
              <div className="back-card-qr">
                <div className="qr-circle">
                  <img src={data.qr_code} alt="" />
                </div>
              </div>
            </div>
            {location && location.user_id == userId && (
              <div className="btn_showcardsecond">
                <div className="btn_showcardsecond_sub1">
                  {data.is_nfb_type == 0 ? (
                    <button
                      onClick={() => {
                        navigate("/resellcreateproduct", {
                          state: {
                            accept_bond_bid_id: data.accept_bond_bid_id,
                            is_nfb: data.is_nfb,
                            resale_product_id: data.resale_product_id,
                            bond_id: data.bond_id,
                          },
                        });
                      }}
                      className="show_second_btn"
                    >
                      SELL
                    </button>
                  ) : null}
                  {/* <button
                    onClick={captureDivAsImage}
                    className="show_second_btn"
                  >
                    SAVE
                  </button> */}
                  <a
                    // href={data.qr_string}
                    // target="_blank"
                    onClick={() => {
                      handleShare();
                    }}
                    className="show_second_btn"
                  >
                    SHARE
                  </a>
                </div>
                <div className="btn_showcardsecond_sub2">
                  <img
                    onClick={() => Bond_like()}
                    src={
                      // data.is_nfb_type == 0
                      //   ? images.new_launch_nfb_new
                      //   : data.is_nfb_type == 0
                      //   ? images.logo
                      //   : data.is_nfb_type == 2
                      //   ? images.logo
                      //   : data.is_nfb_type == 3
                      //   ? images.new_launch_nfb_new
                      //   : images.new_launch_nfb_new
                      data.is_nfb_type == 0
                        ? images.collection
                        : data.is_nfb_type == 0
                        ? images.collection
                        : data.is_nfb_type == 2
                        ? images.logo
                        : data.is_nfb_type == 3
                        ? images.new_launch_nfb_new
                        : images.new_launch_nfb_new
                    }
                    className="show_second_img"
                    alt=""
                  />{" "}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowcaseNewFrame7;
