import React, { useEffect, useState } from "react";
import images from "../../constants/images";
import Modal from "react-modal";
import "./Clubwallet.css";
import { useWalletContext } from "../../context/user_wallet_context";
import qs from "qs";
import { club_wallet, club_withdrawal_request } from "../../utils/Constant";
import axios from "axios";
import AlertModal from "../../components/AlertModal";
import { useLocation, useNavigate } from "react-router-dom";
import NewModal from "../../common/newmodal/NewModal";
import V2Modal from "../../common/v2modal/V2Modal";
import { FaAngleLeft } from "react-icons/fa";

const Clubwallet = () => {
  const [selectedFilter, setSelectedFilter] = useState("all"); // Set initial state to "all"
  const [isWithdrawModalOpen, setIsWithdrawModalOpen] = useState(false);
  const [getcusmodal1, setcusmodal1] = useState(false);
  const [getcusmsg1, setcusmsg1] = useState("");

  const handleFilterChange = (filter) => {
    setSelectedFilter(filter);
  };

  const openWithdrawModal = () => {
    setIsWithdrawModalOpen(true);
  };

  const closeWithdrawModal = () => {
    setIsWithdrawModalOpen(false);
  };

  const { getclubloading, get_clubwalletdata, get_club_wallet } =
    useWalletContext();

  const userId = localStorage.getItem("user_id");

  // const get_data = async () => {
  //   const params = {
  //     club_id: 191,
  //   };
  //   get_club_wallet(params);
  //   // console.log("res", res);
  // };

  const [isLoading, setIsLoading] = useState(true);
  const [result, setResult] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const location = useLocation();
  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setIsLoading(true);
    setResult([]);
    setTotalAmount(0);

    const param = {
      club_id: location.state.club_id,
    };

    console.log("====================================");
    console.log("param", param);
    console.log("====================================");

    axios.post(club_wallet, qs.stringify(param)).then((Response) => {
      console.log("====================================");
      console.log("resw", Response.data);
      console.log("====================================");
      setIsLoading(false);

      if (Response.data.status === 1) {
        setResult(Response.data.result);
        setTotalAmount(Response.data.total_amount);
      } else {
        // Handle error or display a message
        // alert(Response.data.message);
      }

      console.log("Response", Response.data);
    });
  };

  const [getwithdrew, setwithdrew] = useState(null);

  const Withdraw_user = async () => {
    if (getwithdrew == null) {
      setcusmsg1("Please enter amount");
      setcusmodal1(true);
    } else if (
      parseInt(getwithdrew) >= parseInt(totalAmount.replace(/,/g, ""))
    ) {
      setcusmsg1("Withdrawal amount cannot exceed your balance.'");
      setcusmodal1(true);
    } else {
      setIsLoading(true);
      const param = {
        club_id: location.state.club_id,
        amount: getwithdrew,
        user_id: userId,
      };
      console.log("====================================");
      console.log("param", param);
      console.log("====================================");

      axios
        .post(club_withdrawal_request, qs.stringify(param))
        .then((Response) => {
          console.log("====================================");
          console.log("resw", Response.data);
          console.log("====================================");
          setIsLoading(false);

          if (Response.data.status === 1) {
            setIsWithdrawModalOpen(false);
            setcusmsg1(Response.data.message);
            setcusmodal1(true);
            getData();
          } else {
            setcusmsg1(Response.data.message);
            setcusmodal1(true);
            // Handle error or display a message
            // alert(Response.data.message);
          }
          console.log("Response", Response.data);
        });
    }
  };

  const navigate = useNavigate();

  //disable body scrolling

  const [bodyScrollEnabled, setBodyScrollEnabled] = useState(true);

  useEffect(() => {
    if (isWithdrawModalOpen || getcusmodal1) {
      // Disable body scrolling
      setBodyScrollEnabled(false);
    } else {
      // Enable body scrolling
      setBodyScrollEnabled(true);
    }
    if (bodyScrollEnabled) {
      document.body.style.overflow = "visible";
    } else {
      document.body.style.overflow = "hidden";
    }
  }, [isWithdrawModalOpen, getcusmodal1, bodyScrollEnabled]);

  const closemodal1 = () => {
    setcusmodal1(false);
  };
  const openmodal1 = () => {
    setcusmodal1(true);
  };

  return (
    <div className="club_Walletscreen">
      {isLoading ? (
        <div className="loader"></div>
      ) : (
        <>
          <div className="club_Walletheader_list">
            <div
              onClick={() => navigate(-1)}
              className="club_Walletheader_list_1"
            >
              {/* <img src={images.back} alt="" className="club_Walletback" /> */}
              <FaAngleLeft color="var(--color-white)" size={20} />
            </div>

            <div className="club_Walletheader_list_2"></div>
            <div className="club_Walletheader_list_3">
              <h1>Wallet</h1>
            </div>
          </div>
          <div className="club_Walletdetails">
            <p className="total_club_Walletrupee">₹ {totalAmount}</p>
            <p className="wl_titel">My balance</p>
          </div>
          <div className="container_bank">
            <button
              onClick={() => {
                // navigate("/clubBankaccount", {
                //   state: {
                //     club_id: location.state.club_id,
                //   },
                // });
                navigate("/ClubListOfBankAccounts", {
                  state: {
                    club_id: location.state.club_id,
                  },
                });
              }}
              className="manage_account_btn"
            >
              Manage Bank Account
            </button>
          </div>
          <br />
          <div className="club_add_with">
            {/* Rest of the component code */}
            {/* <div className="club_add_with">
              <div className="club_add_with_c"></div>
              <div className="club_add_with_2" onClick={openWithdrawModal}>
                <img src={images.withcash} className="club_Wallettran" alt="" />
                <h1 className="add_name">Withdraw</h1>
              </div>
            </div> */}

            {/* Withdraw Modal */}
            <Modal
              isOpen={isWithdrawModalOpen}
              onRequestClose={closeWithdrawModal}
              className="modal_w"
              overlayClassName="modal-overlay"
            >
              <img src={images.logo} className="modal_img_wallet" alt="" />
              <h3>Withdraw Your Balance</h3>
              <input
                onChange={(e) => {
                  setwithdrew(e.target.value);
                }}
                type="number"
                className="w_input"
                placeholder="Enter amount"
              />
              <div className="footer_center">
                <div className="can_1">
                  <button onClick={closeWithdrawModal}>Cancel</button>
                </div>
                <div className="can_2">
                  <button
                    onClick={() => {
                      Withdraw_user();
                    }}
                  >
                    Okay
                  </button>
                </div>
              </div>
              {/* Withdraw form or input */}
            </Modal>
          </div>
          <br />
          <p
            style={{
              color: "white",
              fontSize: "18px",
              fontFamily: "var(--font-600)",
              marginBottom: "10px",
            }}
          >
            Transactions
          </p>
          {/* <div className="all_btn">
        <button
          className={`button_new ${selectedFilter === "all" ? "active" : ""}`}
          onClick={() => handleFilterChange("all")}
        >
          <h1 className="h1_new">All</h1>
        </button>
        <button
          className={`button_new ${
            selectedFilter === "credit" ? "active" : ""
          }`}
          onClick={() => handleFilterChange("credit")}
        >
          <h1 className="h1_new">Credit</h1>
        </button>
        <button
          className={`button_new ${selectedFilter === "debit" ? "active" : ""}`}
          onClick={() => handleFilterChange("debit")}
        >
          <h1 className="h1_new">Debit</h1>
        </button>
      </div> */}

          {/* {selectedFilter === "all" && ( */}
          {result &&
            result.map((item, index) => {
              return (
                <div style={{ width: "100%" }}>
                  <div className="wallet_entry_wrapp">
                    <div className="all_list1">
                      {item.is_accept == "2" ||
                      item.is_accept == "4" ||
                      item.is_accept == "3" ||
                      item.is_accept == "5" ? (
                        <img
                          src={images.UpArrow}
                          // className="club_Wallettran"
                          alt=""
                        />
                      ) : (
                        <img
                          src={images.DownArrow}
                          // className="club_Wallettran"
                          alt=""
                        />
                      )}
                    </div>
                    <div className="all_list2">
                      <p
                        className={
                          item.is_accept == "4" ||
                          item.is_accept == "3" ||
                          item.is_accept == "5"
                            ? "Account-name"
                            : "credit_active"
                        }
                      >
                        {item.is_accept == "1"
                          ? "Account debited ₹ " + item.is_debit
                          : item.is_accept == "2"
                          ? "Account credited  ₹ " + item.is_refund
                          : item.is_accept == "3"
                          ? "Account credited ₹ " + item.is_royalty
                          : item.is_accept == "4"
                          ? "Account credited ₹ " + item.owner_amount
                          : item.is_accept == "5"
                          ? "Account credited ₹ " + item.owner_amount
                          : null}
                      </p>
                      <p className="by-text"> For : {item.main_product}</p>
                      <p className="wallet-recharged">
                        {item.is_accept == "1"
                          ? item.main_product == "Withdrawal"
                            ? "Withdrawal"
                            : "Bidding amount Deducted " + item.main_product
                          : item.is_accept == "2"
                          ? "Refund " + item.main_product
                          : item.is_accept == "3"
                          ? "Royalty Received -  " +
                            item.main_product +
                            "Aucemo (" +
                            item.bidder_user_names +
                            ")"
                          : item.is_accept == "4"
                          ? "" +
                            "Payment  Received  For Aucemo " +
                            item.main_product +
                            " " +
                            item.bidder_user_names
                          : item.is_accept == "5"
                          ? "Wallet Recharged "
                          : null}
                      </p>
                    </div>
                    {/* <div className="club_all_list3">
                  <p className="credit_p">+25.k</p>
                </div> */}
                  </div>
                  <hr style={{ marginTop: "10px" }} />
                </div>
              );
            })}
        </>
      )}
      {/* // )} */}
      {/* {selectedFilter === "credit" && (
        <div className="credit">
          <div className="club_all_list">
            <div className="club_all_list1">
              <img src={images.UpArrow} className="club_Wallettran" alt="" />
            </div>
            <div className="club_all_list2">
              <p>user credit</p>
              <p>user details</p>
            </div>
            <div className="club_all_list3">
              <p className="credit_p">+25.k</p>
            </div>
          </div>
          <hr />
          <div className="club_all_list">
            <div className="club_all_list1">
              <img src={images.UpArrow} className="club_Wallettran" alt="" />
            </div>
            <div className="club_all_list2">
              <p>user credit</p>
              <p>user details</p>
            </div>
            <div className="club_all_list3">
              <p className="credit_p">+25.k</p>
            </div>
          </div>
          <hr />
        </div>
      )}
      {selectedFilter === "debit" && (
        <div className="debit">
          <div className="club_all_list">
            <div className="club_all_list1">
              <img src={images.DownArrow} className="club_Wallettran" alt="" />
            </div>
            <div className="club_all_list2">
              <p>user debit</p>
              <p>user details</p>
            </div>
            <div className="club_all_list3">
              <p className="debit_p">+25.k</p>
            </div>
          </div>
          <hr />
          <div className="club_all_list">
            <div className="club_all_list1">
              <img src={images.DownArrow} className="club_Wallettran" alt="" />
            </div>
            <div className="club_all_list2">
              <p>user debit</p>
              <p>user details</p>
            </div>
            <div className="club_all_list3">
              <p className="debit_p">+25.k</p>
            </div>
          </div>

          <hr />
        </div>
      )} */}
      {getcusmodal1 && (
        // <AlertModal
        //   setShowModal={setcusmodal1}
        //   // title={"Success"}
        //   msg={getcusmsg1}
        // />

        // <NewModal
        //   title=""
        //   msg={getcusmsg1}
        //   setShowModal={openmodal1}
        //   closemodal={closemodal1}
        // />

        <V2Modal
          canclebtn={false}
          title=""
          msg={getcusmsg1}
          setShowModal={setcusmodal1}
          okfunction={closemodal1}
        />
      )}
    </div>
  );
};

export default Clubwallet;
