import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import NavbarSec from "../common/NavbarSec";
import images from "../constants/images";
import { Resale_product_history, product_history } from "../utils/Constant";
import axios from "axios";

const ResellActionneed = () => {
  const userId = localStorage.getItem("user_id");
  const location = useLocation();
  const [is_step, setis_step] = useState(0);
  const [offer_price, setoffer_price] = useState("");
  const [getproductPrice, setProductPrice] = useState("");
  const [full_msg, setfull_msg] = useState("");
  const [getstatuscode, setstatuscode] = useState("");
  const [loading, setloading] = useState(false);
  const [getextra_msg, setextra_msg] = useState("");
  const [getusername, setusername] = useState("");

  useEffect(() => {
    History();
  }, []);

  const History = () => {
    const param = {
      resale_product_id: location.state.product_id,
      user_id: userId,
    };
    console.log("parammpin", param);
    axios
      .post(Resale_product_history, param)
      .then((Response) => {
        console.log("acdascs", Response.data.result);
        const data = Response.data.result;
        if (data.status == 2) {
          // if (data.message != null) {
          //   setoffer_price(data.offer_price);
          //   setis_step(data.is_step);
          //   setfull_msg(data.message);
          // } else {
          setoffer_price(data.offer_price);
          setis_step(data.is_step);
          setusername(data.username);
          // setfull_msg(
          //   `Exciting news! You now have the opportunity to create Aucemo and tap into our vibrant community. We believe in the value of your creation and are committed to helping you succeed.\n Please note that a nominal fee of ${
          //     location.state && location.state.is_globle ? "10" : "20"
          //   } Aucemo will be charged for the creation of Aucemo. This enables us to provide you with exceptional resources and support. Get ready to showcase your asset, build your brand, and unlock new revenue streams. Thank you for choosing aucemo.club as your partner. We can't wait to see your asset thrive in our community.`
          // );

          setfull_msg(
            data.is_user && data.is_user == 1
              ? `Exciting news! You now have the opportunity to create Aucemo and tap into our vibrant community. We believe in the value of your creation and are committed to helping you succeed.\n Please note that a nominal fee of ${
                  location.state && location.state.is_globle ? "10" : "20"
                } Aucemo will be charged for the creation of Aucemo. This enables us to provide you with exceptional resources and support. Get ready to showcase your asset, build your brand, and unlock new revenue streams. Thank you for choosing aucemo.club as your partner. We can't wait to see your aucemo thrive in our community.`
              : `Exciting news! You now have the opportunity to create Aucemo and tap into our vibrant community. We believe in the value of your creation and are committed to helping you succeed.\n  Get ready to showcase your asset, build your brand, and unlock new revenue streams. Thank you for choosing aucemo.club as your partner. We can't wait to see your aucemo thrive in our community.`
          );
          setProductPrice(Response.data.result.product_price);

          // alert(Response.data.message);
          // }
        } else if (data.status == 3) {
          setfull_msg(data.message);
          setstatuscode(3);
          setextra_msg(data.extra_message);
        }
        setloading(false);
        // if (Response.data.status == 1) {
        //   setis_step(data.is_step);
        //   setoffer_price(data.offer_price)
        // } else {
        //   setErrorMessage(Response.data.message);
        //   setShowModal(true);
        // }
        // this.setState({
        //   // data: Response.data.result,
        //   task_arrayholder: Response.data.result,
        // });
      })
      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };

  return (
    <div className="page_main_wrapp">
      <div className="page_base_wrapp_2">
        <NavbarSec name="" />
        <div className="actionneed_main">
          <b>Dear {getusername}</b>
          <br />
          <p className="actionneed_p">
            {/* Exciting news! You now
            have the opportunity to create Aucemo and tap into our vibrant
            community. We believe in the value of your creation and are
            committed to helping you succeed. */}
            {/* {full_msg} */}
            Thank you for choosing aucemo.club as your partner. We can't wait to
            see your aucemo thrive in our community.
            <br />
            {/* {getextra_msg !== "" ? getextra_msg : null} */}
            {/* {offer_price == "" ? null : offer_price} */}
          </p>
          <br />
          <img className="actionneed_img" src={images.new_logo} alt="" />
          {getstatuscode == 3 ? null : (
            <>
              {(offer_price == "" || offer_price == null) && is_step == 0 ? (
                <Link
                  to={"/bondissue"}
                  state={{
                    product_id: location.state.product_id,
                    remaining_bond: location.state.remaining_bond,
                    // product_price: getproductPrice,
                    // is_globle: location.state.is_globle,
                    // is_owner: location.state.is_owner,
                  }}
                  className="new_btn_wrapp mt-3"
                >
                  Create Aucemo
                </Link>
              ) : is_step == 1 ? (
                <Link
                  to={"/Collaboratenfb"}
                  state={{
                    product_id: location.state.product_id,
                  }}
                  className="new_btn_wrapp mt-3"
                >
                  Create Aucemo
                </Link>
              ) : (
                <Link
                  to={"/Resellnewattach"}
                  state={{
                    product_id: location.state.product_id,
                  }}
                  className="new_btn_wrapp mt-3"
                >
                  Create Aucemo
                </Link>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ResellActionneed;
