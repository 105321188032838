import React from "react";
import "./NewUploadAutherVideoScreen.css";
import { useState } from "react";
import { useRef } from "react";
import images from "../../constants/images";
import Webcam from "react-webcam";
import RecordRTC from "recordrtc";
import { useEffect } from "react";
import V2Modal from "../../common/v2modal/V2Modal";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate } from "react-router-dom";
import { user_add_video_v2 } from "../../utils/Constant";
import Slider from "react-slick";

const NewUploadAutherVideoScreen = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const recorderRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isloading, setIsLoading] = useState(false);
  const [recording, setRecording] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [getrecordedvideo, setrecordedvideo] = useState(false);
  const [recordingTime, setRecordingTime] = useState(180);
  const [isRecordingstatus, setRecordingstatus] = useState(false);
  const [isModalVisiblevideo, setIsModalVisiblevideo] = useState(false);
  const [getpreviewVideoModal2, setPreviewVideoModal2] = useState(false);
  const [getvideo, setVideo] = useState("");
  const [showModal2, setShowModal2] = useState(false);
  const [uploadPercentage, setUploadPercentage] = useState(0);

  const handleVideoChange = async (e) => {
    const files = Array.from(e.target.files);

    // Assuming you have a minimum and maximum duration in seconds
    const minDuration = 30; // Minimum duration in seconds
    const maxDuration = 180; // Maximum duration in seconds

    // Validate each video file
    for (const file of files) {
      const video = document.createElement("video");
      video.src = URL.createObjectURL(file);

      // Wait for the video metadata to load
      await new Promise((resolve) => {
        video.onloadedmetadata = resolve;
      });

      const duration = video.duration;

      if (duration < minDuration || duration > maxDuration) {
        // Video duration is outside the allowed range
        alert(
          `Video duration should be between ${minDuration}s and ${maxDuration}s.`
        );
      } else {
        // Video is within the allowed range, add it to the state
        setSelectedVideos([...files]);
      }

      // Clean up the video element
      video.remove();
    }
  };

  // --------------------- recording functions start --------------------------------

  // stop recording function
  const StopRecording = () => {
    if (recording) {
      // Check if recording is in progress
      setRecording(false);
      const minDuration = 30;
      const maxDuration = 180;
      if (recorderRef.current) {
        recorderRef.current.stopRecording(() => {
          const blob = recorderRef.current.getBlob();

          if (180 - recordingTime < minDuration) {
            // Display an alert or take other actions for insufficient duration
            // alert(
            //   Video duration should be between ${minDuration}s and ${maxDuration}s.
            // );
            toast.error(
              `Video duration should be between ${minDuration}s and ${maxDuration}s.`,
              { theme: "colored" }
            );

            setRecordedChunks([]);
            setSelectedVideos([]);
          } else {
            // Continue with handling the recorded video
            setRecordedChunks([blob]);
            setSelectedVideos([blob]);
            setRecordingTime(180);
            setIsModalVisiblevideo(false);

            // Stop the media stream to release the camera
            const stream = videoRef.current.srcObject;
            if (stream) {
              const tracks = stream.getTracks();
              tracks.forEach((track) => track.stop());
            }
          }
        });
      }
    } else {
      // Handle the case when recording is not in progress
      // alert("Recording is not in progress.");
    }
  };

  const startRecording = () => {
    setRecordingstatus(true);
    setRecordingTime(180);
    if (videoRef.current) {
      navigator.mediaDevices
        .getUserMedia({
          video: true,
          audio: {
            echoCancellation: true,
            noiseSuppression: true,
            autoGainControl: true,
          },
        })
        .then((stream) => {
          const recorder = RecordRTC(stream, {
            type: "video",
            mimeType: "video/webm",
          });
          recorder.startRecording();
          recorderRef.current = recorder;
          setRecording(true);
          setrecordedvideo(false);
          videoRef.current.srcObject = stream;
        })
        .catch((error) => {
          console.error("Error accessing media devices:", error);
          // Handle errors related to accessing media devices
          if (error.name === "NotAllowedError") {
            // Alert the user that they need to allow camera and microphone permissions
            // alert(
            //   "Please allow camera and microphone access to record a video."
            // );
            toast.error(
              "Please allow camera and microphone access to record a video.",
              { theme: "colored" }
            );
          } else if (error.name === "NotFoundError") {
            // Alert the user that no camera or microphone was found
            // alert("No camera or microphone found.");
            toast.error("No camera or microphone found.", { theme: "colored" });
          } else {
            // Alert for generic errors
            // alert("Error accessing media devices. Please try again later.");
            toast.error(
              "Error accessing media devices. Please try again later.",
              { theme: "colored" }
            );
          }
        });
    }
  };

  // --------------------- recording functions end ----------------------------------

  // show image function
  const showimage = () => {
    StopRecording();
    setRecordedChunks([]);
    setSelectedVideos([]);
    resetCountdown();
    console.log(videoRef.current);
    // Stop the media stream to release the camera
    const stream = videoRef.current.srcObject;
    if (stream) {
      const tracks = stream.getTracks();
      tracks.forEach((track) => track.stop());
    }
  };

  // video timer functionality start
  useEffect(() => {
    let timerInterval;

    if (isRecordingstatus && recordingTime > 0) {
      timerInterval = setInterval(() => {
        setRecordingTime((prevTime) => {
          const newTime = prevTime - 1;
          // Check if the recording time reaches 00:00
          if (newTime <= 0) {
            StopRecording(); // Stop recording when time is 00:00
            return 0; // Set time to 0
          }
          return newTime;
        });
      }, 1000);
    } else {
      // If not recording or time is 00:00, clear the interval
      clearInterval(timerInterval);
    }

    // Return cleanup function to clear the interval when component unmounts or when isRecordingstatus or recordingTime changes
    return () => clearInterval(timerInterval);
  }, [isRecordingstatus, recordingTime]); // Add isRecordingstatus and recordingTime as dependencies

  const resetCountdown = () => {
    setIsModalVisiblevideo(false);
    setRecordingTime(180);
  };
  // video timer functionality end

  const opendeleteModal = () => {
    setShowModal2(true);
  };

  const handleVideoDelete = () => {
    // const newVideos = [...selectedVideos];
    // newVideos.splice(index, 1);

    setSelectedVideos([]);
    // setSelectedVideos(newVideos);
    setRecordedChunks([]);
    setPreviewVideoModal2(false);
    setShowModal2(false);
  };

  // Upload video api start

  const UploadVideo = () => {
    if (selectedVideos && selectedVideos.length <= 0) {
      // setShowModal(true);
      // setErrorMessage("Please Upload video");
      toast.error("Please Upload video", { theme: "colored" });
    } else {
      setIsLoading(true);
      let formData = new FormData();
      formData.append("user_id", location.state.user_id);

      selectedVideos.forEach((image, index) => {
        formData.append("user_videos", image);
      });

      axios
        .post(user_add_video_v2, formData, {
          onUploadProgress: (progressEvent) => {
            const percentage = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            console.log("Upload Percentage:", percentage);
            // Update the state to show the progress
            setUploadPercentage(percentage);
          },
        })
        .then((response) => {
          console.log("Response:", response.data);
          setIsLoading(false);

          if (response.data.status === 1) {
            toast.success(response.data.message, { theme: "colored" });

            // Navigate to the "/Intrests" screen
            navigate("/NewInterests", {
              state: {
                user_id: location.state.user_id,
              },
            });
          } else {
            toast.error(response.data.message, { theme: "colored" });
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          toast.error(
            "Unable to establish a connection. Please check your internet connection and try again.",
            {
              theme: "colored",
            }
          );
          setIsLoading(false);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  // Upload video api end
  const [slideIndex, setSlideIndex] = useState(0);

  const settings = {
    dots: true,
    infinite: true,
    // speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    pauseOnHover: true,
    speed: 500,
    autoplaySpeed: 4000,
    afterChange: (currentSlide) => setSlideIndex(currentSlide),
    customPaging: (i) => (
      <div
        style={{
          width: "10px",
          height: "10px",
          margin: "10px 5px",
          borderRadius: "50%",
          backgroundColor: slideIndex === i ? "#da0e41" : "white",
          opacity: slideIndex === i ? 1 : 0.6,
          // marginTop: "30px",
        }}
      ></div>
    ),
    responsive: [
      {
        breakpoint: 2560,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  function Timer({ seconds }) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    return (
      <p>
        {minutes < 10 ? "0" : ""}
        {minutes}:{remainingSeconds < 10 ? "0" : ""}
        {remainingSeconds} minutes
      </p>
    );
  }

  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     setRecordingTime((prevTime) => prevTime - 1);
  //   }, 1000);

  //   return () => clearInterval(timer);
  // }, []);
  return (
    <div lassName="new_wrapp_container">
      <div className="new_container">
        <div className="new_founder_video_main_wrapp">
          <p className="new_founder_video_heading mt-3">
            Upload Authentication <br /> Video
          </p>

          {/* video start */}
          <div className="new_auther_video_wrapp">
            {selectedVideos && selectedVideos.length <= 0 ? (
              <>
                <label
                  onClick={() => {
                    setIsModalVisiblevideo(true);
                    setRecordedChunks([]);
                    setrecordedvideo(false);
                  }}
                  htmlFor="creator_vid"
                  //   className="upload-button"
                  className="new_auther_video_upload_button"
                >
                  <img src={images.new_upload_logo} alt="" />
                  <p>Upload Your Video</p>
                </label>
                <input
                  disabled
                  id="creator_vid"
                  type="file"
                  accept="video/*"
                  // multiple
                  capture="camera"
                  onChange={(e) => {
                    handleVideoChange(e);
                    // setIsModalVisiblevideo(true);
                  }}
                />
              </>
            ) : (
              <div
                className="image-previews"
                style={{
                  width: "100%",
                  height: "100%",
                  justifyContent: "center",
                }}
              >
                {selectedVideos.map((image, index) => (
                  <div
                    className="new_auther_video_upload_button"
                    // style={{ width: "140px", height: "100px", opacity: 0.8 }}
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "10px",
                      width: "100%",
                      height: "100%",
                      // maxWidth: "130px",
                    }}
                  >
                    <button
                      onClick={() => {
                        setPreviewVideoModal2(true);
                        setVideo(URL.createObjectURL(image));
                        console.log("getvideo", getvideo);
                        console.log("getvideo 111", URL.createObjectURL(image));
                      }}
                      style={{
                        color: "var(--color-main)",
                        fontSize: "12px",
                        fontFamily: "var(--font-600)",
                      }}
                    >
                      Preview
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>
          {/* video end */}

          <p className="new_auther_video_text" style={{ textAlign: "left" }}>
            Now you're verified! Let's personalize your profile and connect with
            your audience.
          </p>
          <p
            className="new_auther_video_text"
            style={{ textAlign: "left", color: "var(--color-main)" }}
          >
            Note:- Video duration should be between 30s and 180s.
          </p>

          <div
            className="otp_screen_text_container"
            style={{ padding: "0px", margin: "0px" }}
          >
            <h4 style={{ marginBottom: "0px", fontSize: "16px" }}>
              The Power of Authenticity
            </h4>
            <p>
              At aucemo, genuine connections are key. Sharing a short selfie
              video introduces yourself to your audience in a personal way.
            </p>
          </div>

          <div
            className="pv_imgs_wrapp"
            style={{
              width: "100%",
              // margin: "10px auto",
              // minHeight: "25vh",
              margin: "10px auto",
              padding: "0px",
            }}
          >
            <Slider {...settings}>
              <div
                className="otp_screen_text_container2"
                style={{
                  margin: "6px auto",
                }}
              >
                <h4
                  style={{
                    margin: "0px",
                    color: "var(--color-main)",
                    fontSize: "18px",
                  }}
                >
                  Strengthen Your Connection
                </h4>
                <p>
                  A genuine selfie video allows your audience to connect with
                  you on a personal level, fostering trust and engagement.
                </p>
              </div>
              <div
                className="otp_screen_text_container2"
                style={{ margin: "6px auto" }}
              >
                <h4
                  style={{
                    margin: "0px",
                    color: "var(--color-main)",
                    fontSize: "18px",
                  }}
                >
                  Combat Deepfakes
                </h4>
                <p>
                  In today's digital world, authenticity is crucial. Your selfie
                  video serves as a verification tool, ensuring your audience
                  knows they're interacting with the real you.
                </p>
              </div>
              <div
                className="otp_screen_text_container2"
                style={{ margin: "6px auto" }}
              >
                <h4
                  style={{
                    margin: "0px",
                    color: "var(--color-main)",
                    fontSize: "18px",
                  }}
                >
                  Express Yourself Freely
                </h4>
                <p>
                  Showcase your personality and bring your story to life with a
                  captivating video introduction.
                </p>
              </div>
            </Slider>
          </div>

          {/* btn */}
          <button
            onClick={UploadVideo}
            className="new_btn_wrapp mt-3"
            style={{
              display:
                uploadPercentage === 0 || uploadPercentage === 100
                  ? "block"
                  : "none",
            }}
          >
            <p>Next</p>
          </button>

          {uploadPercentage > 0 && uploadPercentage < 100 && (
            <div className="progress-wrapper">
              <div className="progress-bar-container">
                <progress
                  value={uploadPercentage}
                  max="100"
                  className="progress"
                />
                <div className="progress-text">
                  {`Video Uploading ${uploadPercentage}%`}
                </div>
              </div>
            </div>
          )}
          {/* btn */}
        </div>
      </div>

      {/* video modal start */}

      {isModalVisiblevideo && (
        <div
          className="modal_login"
          style={{ zIndex: 111, width: "100%", height: "95vh", bottom: "-5px" }}
        >
          <div
            className="select_nw_modal"
            style={{ height: "100%", background: "#333" }}
          >
            <div className="modal_div1">
              <img
                className="forgot_main"
                src="/static/media/logo.c9b7a18fb586e344723a.png"
                alt=""
              />
            </div>
            {getrecordedvideo ? (
              <div>
                {recordedChunks.length > 0 && (
                  <div>
                    <p>Recorded Video:</p>
                    <video controls width="400">
                      <source
                        src={URL.createObjectURL(recordedChunks[0])}
                        type="video/webm"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                )}
              </div>
            ) : (
              <>
                <div
                  style={{
                    marginBottom: "14px",
                    width: "100%",
                    borderRadius: "18px",
                    overflow: "hidden",
                    height: "60vh",
                  }}
                >
                  <Webcam
                    width={"100%"}
                    height={"100%"}
                    videoConstraints={{ facingMode: "user" }}
                    ref={videoRef}
                    mirrored={true}
                    // videoConstraints={videoConstraints}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {recording ? (
                    <>
                      <div
                        style={{
                          width: "90%",
                          height: "60px",
                          borderRadius: "28px",
                          backgroundColor: "#69696971",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          position: "relative",
                        }}
                      >
                        <p
                          style={{
                            position: "absolute",
                            left: "10px",
                            top: "20px",
                            fontFamily: "var(--font-600)",
                            color: "white",
                            fontSize: "14px",
                          }}
                        >
                          {/* {recordingTime} seconds */}
                          <Timer seconds={recordingTime} />
                        </p>

                        <img
                          onClick={StopRecording}
                          src={images.stop_circle}
                          style={{
                            height: "50px",
                            width: "50px",
                            objectFit: "contain",
                          }}
                          alt=""
                        />
                      </div>
                    </>
                  ) : (
                    <div
                      style={{
                        width: "90%",
                        height: "60px",
                        borderRadius: "28px",
                        backgroundColor: "#69696971",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        onClick={startRecording}
                        src={images.start_circle}
                        style={{
                          height: "50px",
                          width: "50px",
                          objectFit: "contain",
                        }}
                        alt=""
                      />
                    </div>
                  )}
                </div>
              </>
            )}

            <button
              onClick={() => {
                showimage();
              }}
            ></button>
            <br />
            <div className="modal_div">
              <button
                className="v2modal_btn2"
                onClick={() => {
                  showimage();
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {/* video modal end */}

      {/* preview video modal start */}

      {getpreviewVideoModal2 && (
        <div
          style={{
            position: "fixed",
            width: "100vw",
            height: "100vh",
            top: "0px",
            left: "0px",
            zIndex: 11,
          }}
        >
          <div className="page_main_wrapp">
            <div className="page_base_wrapp" style={{ maxWidth: "450px" }}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  margin: "10px auto",
                  maxWidth: "450px",
                }}
              >
                <div className="nav_back_wrapp">
                  <img
                    src={images.back}
                    className="nav_back_btn"
                    alt=""
                    onClick={() => setPreviewVideoModal2(false)}
                  />
                </div>
                <p className="nav_screen_name" style={{ flex: 0.5 }}>
                  Preview
                </p>
                <button
                  // className="delete-button"
                  style={{
                    width: "23px",
                    height: "23px",
                  }}
                  onClick={() => opendeleteModal()}
                >
                  <img
                    style={{
                      width: "96%",
                      height: "96%",
                      objectFit: "contain",
                    }}
                    src={images.delete2}
                    alt=""
                  />
                </button>
              </div>
              <video controls style={{ width: "100%", height: "90%" }}>
                <source src={getvideo} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
      )}

      {/* preview video modal end */}

      {showModal2 ? (
        <V2Modal
          canclebtn={true}
          title="Alert"
          msg="Are you sure want to delete this video?"
          setShowModal={setShowModal2}
          okfunction={handleVideoDelete}
        />
      ) : null}
      <ToastContainer />
    </div>
  );
};

export default NewUploadAutherVideoScreen;
