import React, { useEffect } from "react";
import "./SelectCandWBond.css";
import { FaAngleLeft } from "react-icons/fa6";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import {
  add_c_and_w_bond_request,
  add_user_grup_benifit,
  get_release_bond,
} from "../../utils/Constant";
import { ToastContainer, toast } from "react-toastify";

const SelectCandWBond = () => {
  const userId = localStorage.getItem("user_id");

  const navigate = useNavigate();
  const location = useLocation();

  const [selectedNumber, setSelectedNumber] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [getallBonds, setAllBonds] = useState([]);
  const [getsearchBonds, setSearchBonds] = useState([]);
  const [showModal1, setShowModal1] = useState(false);

  const [getselectedProIds, setSelectedProIds] = useState([]);
  const [getselectedBondId, setSelectedBondId] = useState([]);
  const [getselectedBondIsNfb, setSelectedBondIsNfb] = useState([]);

  const [getselectedBondOwnerId, setSelectedBondOwnerId] = useState([]);
  const [getselectedReleaseBondId, setSelectedReleaseBondId] = useState([]);
  const [getselectedShareByUser, setSelectedShareByUser] = useState([]);
  const [getselectedUniqCode, setSelectedUniqCode] = useState([]);
  const [getselectedClubId, setSelectedClubId] = useState([]);
  const [getselectedUserId, setSelectedUserId] = useState([]);

  const [getdeadlineNumber, setDeadlineNumber] = useState("");
  const [getcandwTime, setCandWTime] = useState("");

  const [getcandwTimeRadio, setCandWTimeRadio] = useState(0);

  const handleChange = (e) => {
    setSelectedNumber(parseInt(e.target.value));
    console.log("first", parseInt(e.target.value));
    AllBonds(parseInt(e.target.value));
  };

  const handleDeadlineChange = (e) => {
    const { value } = e.target;
    // Allow only numbers
    if (/^\d*$/.test(value)) {
      setDeadlineNumber(value);
    }
  };

  const handleCandWTimelineChange = (e) => {
    const { value } = e.target;
    // Allow only numbers
    if (/^\d*$/.test(value)) {
      setCandWTime(value);
    }
  };

  const AllBonds = async (number) => {
    setIsLoading(true);

    const param = {
      no_of_c_and_w: 1,
    };
    await axios
      .post(get_release_bond, param)
      // .get("https://gokulnair.com/blockchain_2/api/get_release_bond")
      .then((res) => {
        setIsLoading(false);
        console.log("res", res.data);
        if (res.data.status === 1) {
          setAllBonds(res.data.result);
          setSearchBonds(res.data.result);
        }
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoading(false);
      });
  };

  const handleCheckboxChange = (
    proids,
    productId,
    isNfb,
    ownerId,
    ReleaseBondId,
    ShareByUser,
    UniqCode,
    ClubId,
    Userid
  ) => {
    console.log("Userid", Userid);
    // Create a copy of the existing state arrays
    const selectedProIdsCopy = [...getselectedProIds];
    const selectedBondIdsCopy = [...getselectedBondId];
    const selectedBondIsNfbCopy = [...getselectedBondIsNfb];

    const selectedBondOwnerIdCopy = [...getselectedBondOwnerId];
    const selectedBondReleaseBondIdCopy = [...getselectedReleaseBondId];
    const selectedBondReleaseShareByUserCopy = [...getselectedShareByUser];
    const selectedBondReleaseUniqCodeCopy = [...getselectedUniqCode];
    const selectedBondReleaseClubIdCopy = [...getselectedClubId];
    const selectedBondReleaseUserIdCopy = [...getselectedUserId];

    // Check if the product_id is already in the array
    const index = selectedBondIdsCopy.indexOf(productId);

    // If the product_id is not in the array, add it
    if (index === -1) {
      selectedProIdsCopy.push(proids);
      selectedBondIdsCopy.push(productId);
      selectedBondIsNfbCopy.push(isNfb);

      selectedBondOwnerIdCopy.push(ownerId);
      selectedBondReleaseBondIdCopy.push(ReleaseBondId);
      selectedBondReleaseShareByUserCopy.push(ShareByUser);
      selectedBondReleaseUniqCodeCopy.push(UniqCode);
      selectedBondReleaseClubIdCopy.push(ClubId);
      selectedBondReleaseUserIdCopy.push(Userid);
    } else {
      // If the product_id is already in the array, remove it
      selectedProIdsCopy.splice(index, 1);
      selectedBondIdsCopy.splice(index, 1);
      selectedBondIsNfbCopy.splice(index, 1);

      selectedBondOwnerIdCopy.splice(index, 1);
      selectedBondReleaseBondIdCopy.splice(index, 1);
      selectedBondReleaseShareByUserCopy.splice(index, 1);
      selectedBondReleaseUniqCodeCopy.splice(index, 1);
      selectedBondReleaseClubIdCopy.splice(index, 1);
      selectedBondReleaseUserIdCopy.splice(index, 1);
    }

    // Update the state with the modified arrays
    setSelectedProIds(selectedProIdsCopy);
    setSelectedBondId(selectedBondIdsCopy);
    setSelectedBondIsNfb(selectedBondIsNfbCopy);

    setSelectedBondOwnerId(selectedBondOwnerIdCopy);
    setSelectedReleaseBondId(selectedBondReleaseBondIdCopy);
    setSelectedShareByUser(selectedBondReleaseShareByUserCopy);
    setSelectedUniqCode(selectedBondReleaseUniqCodeCopy);
    setSelectedClubId(selectedBondReleaseClubIdCopy);
    setSelectedUserId(selectedBondReleaseUserIdCopy);

    const selectedProIdsString = selectedProIdsCopy.join(",");
    const selectedBondIdsString = selectedBondIdsCopy.join(",");
    const selectedBondIsNfbString = selectedBondIsNfbCopy.join(",");
    console.log("getselectedProIds", getselectedProIds);
    console.log("getselectedBondId", getselectedBondId);
    console.log("getselectedBondIsNfb", getselectedBondIsNfb);
    console.log("getselectedBondOwnerId", getselectedBondOwnerId);
    console.log("getselectedUserId", getselectedUserId);
  };

  const handleRarerezSearch = (event) => {
    const query = event.target.value;
    setSearchBonds(query);
    filterData(query);
  };

  const filterData = (query) => {
    const filtered = getallBonds.filter((item) =>
      item.product_name.toLowerCase().includes(query.toLowerCase())
    );
    setSearchBonds(filtered);
  };

  const createnfb = () => {
    if (getselectedBondId.length < 1) {
      toast.error("Please select at least one aucemo", {
        theme: "colored",
      });
    } else if (getdeadlineNumber == "") {
      toast.error("Please enter deadline for creator to approve request", {
        theme: "colored",
      });
    } else if (getcandwTimeRadio != 1 && getcandwTime == "") {
      toast.error("Please enter duration of C & W you want open", {
        theme: "colored",
      });
    } else {
      let formData = new FormData();
      {
        getselectedProIds.map((ProIds) =>
          formData.append("pro_as_ids[]", ProIds)
        );
      }
      {
        getselectedBondIsNfb.map((IsNfb) => formData.append("is_nfb[]", IsNfb));
      }
      {
        getselectedBondId.map((product_id) =>
          formData.append("product_id[]", product_id)
        );
      }
      {
        getselectedReleaseBondId.map((release_bond_id) =>
          formData.append("release_bond_id[]", release_bond_id)
        );
      }
      {
        getselectedUserId.map((user_id) =>
          formData.append("owner_id[]", user_id)
        );
      }
      {
        getselectedShareByUser.map((share_by_user) =>
          formData.append("share_by_user[]", share_by_user)
        );
      }
      {
        getselectedClubId.map((club_id) =>
          formData.append("club_id[]", club_id)
        );
      }
      {
        getselectedUniqCode.map((uniq_code) =>
          formData.append("uniq_code[]", uniq_code)
        );
      }
      formData.append("user_id", userId);
      formData.append(
        "c_and_w_time",
        getcandwTimeRadio == 1 ? "" : getcandwTime
      );
      formData.append("no_of_request_bond", 1);
      // formData.append("no_of_request_bond", selectedNumber);
      formData.append("pending_c_and_w_id", location.state.pending_c_and_w_id);
      formData.append("c_and_w_request_time", getdeadlineNumber);

      for (let [key, value] of formData.entries()) {
        console.log(`${key}: ${value}`);
      }

      setIsLoading(true);
      axios
        .post(add_c_and_w_bond_request, formData)
        .then((Response) => {
          console.log("acdascs", Response.data);
          setIsLoading(false);
          if (Response.data.status == 1) {
            // setgetcon(true);
            toast.success(Response.data.message, {
              theme: "colored",
            });
            navigate("/MyCAndW");
            // window.location.reload();
          } else {
            toast.error(Response.data.message, {
              theme: "colored",
            });
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  };

  const handleCandWTimheChange = (event) => {
    setCandWTimeRadio(event.target.value);

    // new change
    // setDonationOwnerName('');
    // setDonationClubName('');
    // new change

    console.log("event.target.value", event.target.value);
    // setnumber_of_bonds_created_for_share([]);
    // {
    //   (selectedCreator == 1 || selectedCreator == 3) && GetEditProfileApi();
    // }
  };

  useEffect(() => {
    AllBonds();
  }, []);

  return (
    <div className="new_wrapp_container reletive_position">
      <div className="new_container">
        <div className="play_screen_main_wrapp" style={{ padding: "10px 5%" }}>
          {/* header start */}
          <div className="legacy_dashbord_nav_sec">
            <button
              onClick={() => {
                navigate(-1);
              }}
            >
              <FaAngleLeft color="var(--color-white)" size={20} />
            </button>
            <p></p>
            <div></div>
          </div>
          {/* header end */}

          {/* select bond start */}

          {/* <div className="c_and_w_select_bond_wrapper">
            <p>Select Number Of Bond</p>

            <div>
              <select name="" id="" onChange={handleChange}>
                <option selected disabled value="">
                  1
                </option> */}
          {/* {[...Array(80)].map((_, index) => (
                  <option key={index} value={index + 1}>
                    {index + 1}
                  </option>
                ))} */}
          {/* </select>
            </div>
          </div> */}

          {/* select bond end */}

          {/* Select bonds start */}
          {getallBonds && getallBonds.length > 0 ? (
            <>
              <div className="cub_select_bond_btns_wrapp mb-1">
                <p style={{ fontSize: "14px" }}>Select aucemo</p>
                <button
                  onClick={() => {
                    setShowModal1(true);
                  }}
                >
                  View All
                </button>
              </div>

              <div className="cub_select_bond_cards_wrapp mb-2">
                {getallBonds.map((item, index) => {
                  console.log("item", item);
                  return (
                    <div
                      className="cub_select_bond_single_card"
                      key={item.product_id}
                    >
                      <input
                        type="checkbox"
                        name="bond-check"
                        id={`check_bond_${index}`}
                        className="attach_partner_select"
                        value={item.product_id}
                        onChange={() => {
                          handleCheckboxChange(
                            item.pro_as_id,
                            item.product_id,
                            item.is_nfb,

                            item.owner_id,
                            item.release_bond_id,
                            item.share_by_user,
                            item.uniq_code,
                            item.club_id,
                            item.user_id
                          );
                        }}
                        checked={getselectedBondId.includes(item.product_id)}
                      />
                      <img src={item.img_video} alt="" />
                      <p>{item.product_name}</p>
                    </div>
                  );
                })}
              </div>
            </>
          ) : null}
          {/* Select bonds end */}

          {/* auther request input start */}

          {getallBonds && getallBonds.length > 0 ? (
            <div className="c_and_w_select_bond_wrapper">
              <p>
                Set the number of days for the creators to approve your request.
              </p>

              <div>
                <input
                  type="number"
                  value={getdeadlineNumber}
                  onChange={handleDeadlineChange}
                />
              </div>
            </div>
          ) : null}

          {/* auther request input end */}

          {/* time for c&w input start */}

          {getallBonds && getallBonds.length > 0 ? (
            <div className="c_and_w_select_bond_wrapper">
              <p>Can you open this C & W for unlimited Time</p>

              <div
                id="custom_radio"
                style={{ background: "none", width: "40%" }}
              >
                <label class="custom_radio">
                  <input
                    type="radio"
                    name="share_checkbox"
                    value={0}
                    checked={getcandwTimeRadio == 0}
                    onChange={handleCandWTimheChange}
                  />
                  <span class="checkmark"></span>
                  No
                </label>
                <label class="custom_radio">
                  <input
                    type="radio"
                    name="share_checkbox"
                    value={1}
                    checked={getcandwTimeRadio == 1}
                    onChange={handleCandWTimheChange}
                  />
                  <span class="checkmark"></span>
                  Yes
                </label>
              </div>
            </div>
          ) : null}

          {/* time for c&w input end */}

          {/* time for c&w input start */}

          {getallBonds && getallBonds.length > 0 && getcandwTimeRadio == 0 ? (
            <div className="c_and_w_select_bond_wrapper">
              <p>Set the number of days you want to keep C & W open.</p>
              <div>
                <input
                  type="number"
                  value={getcandwTime}
                  onChange={handleCandWTimelineChange}
                />
              </div>
            </div>
          ) : null}

          {/* time for c&w input end */}

          <button
            onClick={() => {
              createnfb();
            }}
            className="new_btn_wrapp  mt-3"
          >
            <p>Next</p>
          </button>
        </div>
      </div>
      {showModal1 && (
        <>
          <div className="user_benefit_allbonds_modal_wrapp">
            <div className="page_base_wrapp_2">
              <div className="user_benefit_bond_modal_heding">
                <button
                  onClick={() => {
                    setShowModal1(false);
                  }}
                >
                  <FaAngleLeft color="var(--color-white)" size={20} />
                </button>
                <p>Select aucemo</p>
              </div>

              {/* search bar start */}
              <div className="myproduct_13_search">
                <div className="myproduct_13_user_bid_main">
                  <input
                    type="text"
                    placeholder="Search aucemo"
                    className="myproduct_13_user_bid_search"
                    // value={searchTerm}
                    // onChange={(e) => handleSearch(e.target.value)}
                    onChange={handleRarerezSearch}
                  />
                  {/* <IoSearch
                  size={24}
                  color="#fff"
                  className="myproduct_13_search_ic"
                /> */}
                </div>
              </div>

              {/* search bar end */}

              <div className="user_benefit_modal_allbonds_wrapp">
                {getsearchBonds && getsearchBonds.length > 0
                  ? getsearchBonds.map((item, index) => {
                      return (
                        <div
                          className="user_benefit_modal_single_bond"
                          // key={item.product_id}
                        >
                          <div className="user_benefit_modal_single_bond_inner_wrapp">
                            <img src={item.img_video} alt="" />
                            <p>{item.product_name}</p>
                            <input
                              type="checkbox"
                              name="bond-check"
                              id={`check_bond_${index}`}
                              // className="attach_partner_select"
                              value={item.product_id}
                              onChange={() => {
                                handleCheckboxChange(
                                  item.pro_as_id,
                                  item.product_id,
                                  item.is_nfb,

                                  item.owner_id,
                                  item.release_bond_id,
                                  item.share_by_user,
                                  item.uniq_code,
                                  item.club_id,
                                  item.user_id
                                );
                              }}
                              checked={getselectedBondId.includes(
                                item.product_id
                              )}
                            />
                          </div>
                          <div className="user_benefit_modal_bond_hr"></div>
                        </div>
                      );
                    })
                  : null}
              </div>
            </div>
          </div>
        </>
      )}
      <ToastContainer />
    </div>
  );
};

export default SelectCandWBond;
