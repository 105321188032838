import React from "react";
import "./BondCard.css";
import { IoArrowForwardOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import { RiVerifiedBadgeFill } from "react-icons/ri";

const BondCard = ({ img, name, owner_img, owner_name, data }) => {
  console.log("img", img);
  return (
    <div className="bc_main_wrapp">
      <div className="bc_base_wrapp">
        <img src={img} className="bc_img" alt="" />
        <div className="bc_text_wrapp">
          <div style={{ display: "flex", alignItems: "center" }}>
            <p
              className="bc_name"
              style={{ maxWidth: "80%", margin: "6px 10px" }}
            >
              {name}
            </p>
            {data.is_verify == 1 ? (
              <RiVerifiedBadgeFill size={20} color="gold" />
            ) : null}
          </div>
          <div className="bc_pv_wrapp">
            <div>
              <label>Asset Value</label>
              <p>{data.product_value}</p>
            </div>
            <div>
              <label>Minimum Bid</label>
              <p>₹{data.minimum_bid_amount}</p>
            </div>
          </div>
          {data.user_is_check == 1 ? (
            <div className="bc_purchase_btn">
              <p>Purchased</p>
            </div>
          ) : data.total_available_bond_bid == 0 ? (
            <div className="bc_outofstock_btn">
              <p>Out of stock</p>
            </div>
          ) : (
            <Link
              to={"/productview"}
              state={{
                release_bond_id: data.release_bond_id,
                product_id: data.product_id,
                is_nfb: data.is_nfb,
                uniq_code: data.uniq_code,
                theme_index: data.theme_index,
                share_by_user: data.share_by_user,
                club_id: data.club_id,
                pending_c_and_w_id: data.pending_c_and_w_id,
              }}
              className="bc_btn_wrapp"
            >
              <p className="bc_btn1">Bid Now</p>
              <button className="bc_btn2">
                <IoArrowForwardOutline size={18} color="#fff" />
              </button>
            </Link>
          )}
        </div>
        <div className="bc_owner_wrapp">
          <img src={owner_img} alt="" />
          <p>{owner_name}</p>
        </div>
        {/* <button className="bc_btn2">
          <IoArrowForwardOutline size={18} color="#fff" />
        </button> */}
      </div>
    </div>
  );
};

export default BondCard;
