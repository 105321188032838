import images from "../../constants/images";
import React from "react";
import "./Navbar.css";
import { Link } from "react-router-dom";

function Navbar() {
  return (
    <header className="header_main_wrapp">
      <nav>
        <div className="logo" id="main_h_1">
          <a href="usermenu">
            <img className="logo_one" src={images.logo} alt="" />
          </a>
        </div>
        <div className="main_h_2">
          <img src={images.search_2} className="menu_header_icon" />
          <Link className="" to="/wallet">
            <img src={images.clubs_icon} className="menu_header_icon" />
          </Link>
        </div>
        <div>
          <input type="checkbox" id="menu-toggle" />
          <label htmlFor="menu-toggle" className="menu-icon">
            &#9776;
          </label>
          <ul className="menu">
            <div className="menu_header_list">
              <div className="h_1_list">
                <img className="header_logo_one" src={images.logo} alt="" />
              </div>
              <div className="h_2_list">
                <p className="user_titel"> Creator</p>
                <p className="user_name">User Name</p>
              </div>
              <div className="h_3_list">
                <label htmlFor="menu-toggle" className="close-icon menu_close">
                  &#10005;
                </label>
              </div>
            </div>
            <div className="menu_body_list">
              <p className="menu_main_titel"></p>
              <li>
                <a href="#">Protect Asset</a>
              </li>
              <li>
                <a href="#">My Profile</a>
              </li>
              <hr />
              <p className="menu_main_titel"></p>
              <li>
                <a href="creatednfbs">Create aucemo</a>
              </li>
              <li>
                <a href="#">Launched aucemo</a>
              </li>
              <li>
                <a href="#">Bids</a>
              </li>
              <li>
                <a href="#">Auction</a>
              </li>
              <hr />
              <p className="menu_main_titel">Requests & Benefits</p>
              <li>
                <a href="#">Creator Requests</a>
              </li>
              <li>
                <a href="#">Earned Benefits</a>
              </li>
              <li>
                <a href="#">My Benefits</a>
              </li>
              <li>
                <a href="#">Partner Benefits</a>
              </li>
              <hr />
              <p className="menu_main_titel">Clubs</p>
              <li>
                <a href="#">Clubs</a>
              </li>
              <li>
                <a href="#">create Club</a>
              </li>
              <li>
                <a href="#">My Clubs</a>
              </li>
              <li>
                <a href="#">My Memberships</a>
              </li>
              <a className="logout">
                <button style={{ color: "white" }}>Logout</button>
              </a>
            </div>
          </ul>
        </div>
      </nav>
    </header>
  );
}

export default Navbar;
