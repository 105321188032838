import React, { useState } from "react";
import "./keyur.css";
import images from "../../constants/images";
import NavbarSec from "../../common/NavbarSec";
import { Link } from "react-router-dom";
import axios from "axios";

const Keyur = () => {
  // State to manage the selected city
  const [selectedCity, setSelectedCity] = useState("");
  // State to manage the visibility of city options
  const [isCityOptionsVisible, setIsCityOptionsVisible] = useState(false);
  // State to store the input text for filtering city options
  const [inputText, setInputText] = useState("");
  // State to control the visibility of the modal
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Filter city options based on the input text
  const filteredCities = ["Rajkot", "Ahmedabad", "Surat", "Mumbai"].filter(
    (city) => city.toLowerCase().includes(inputText.toLowerCase())
  );

  // Function to handle city selection
  const handleCitySelect = (city) => {
    setSelectedCity(city);
    setInputText(city); // Set the input text to the selected city
    setIsCityOptionsVisible(false); // Hide the options when a city is selected
    setIsModalOpen(false); // Close the modal when a city is selected
  };

  // Function to toggle the visibility of city options
  const toggleCityOptions = () => {
    setIsCityOptionsVisible(!isCityOptionsVisible);
  };

  // Function to handle input text change
  const handleInputChange = (event) => {
    const text = event.target.value;
    setInputText(text);

    // Show the options when the input text changes
    setIsCityOptionsVisible(true);
  };

  // Function to open the modal
  const openModal = () => {
    setIsModalOpen(true);
  };

  return (
    <div className="page_main_wrapp">
      <div className="page_base_wrapp">
        <NavbarSec name="Aucemo" />
        <br />
        <div>
          <div>
            <input
              type="text"
              className="modal_city_select"
              onClick={openModal} // Open the modal when clicked
              value={inputText}
            />
          </div>
          {isModalOpen && ( // Render the modal if isModalOpen is true
            <div className="city-select-container">
              <input
                type="text"
                className="city_nm_select"
                placeholder="Select your city"
                onClick={toggleCityOptions}
                onChange={handleInputChange}
                value={inputText} // Set the input value to the selected city or filtered text
              />
              {isCityOptionsVisible && (
                <div className="city_modal_set">
                  {filteredCities.map((city) => (
                    <p
                      key={city}
                      className="city_option_nm_set"
                      onClick={() => handleCitySelect(city)}
                    >
                      {city}
                    </p>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Keyur;
