import React, { useState, useEffect, useRef } from "react";
import "./CreateProduct.css";
import images from "../../constants/images";
import { useHomeContext } from "../../context/home_context";
import CusModal from "../../common/cusmodal/CusModal";
import {
  create_bond,
  edit_profile,
  get_all_club_2,
  get_manager_all_user,
  get_user_profile_video,
  getall_user,
} from "../../utils/Constant";
import axios from "axios";
import { useProfileContext } from "../../context/profile_context";
import moment from "moment/moment";
import qs from "qs";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import NavbarSec from "../../common/NavbarSec";
import { Link, useNavigate } from "react-router-dom";
import AlertModal from "../../components/AlertModal";
import Webcam from "react-webcam";
import RecordRTC from "recordrtc";
import NewModal from "../../common/newmodal/NewModal";
import { Tab } from "@headlessui/react";
// import Heading from "~/core/ui/Heading";
// import Button from "~/core/ui/Button";
// import StepperItem from "~/core/ui/StepperItem";
// import TextField from "~/core/ui/TextField";
// import Label from "~/core/ui/Label";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import classNames from "classnames";
import {
  Button,
  StepButton,
  StepConnector,
  Typography,
  stepConnectorClasses,
} from "@mui/material";
import { FaCheck } from "react-icons/fa6";
import styled from "styled-components";
import ReactModal from "react-modal";
import V2Modal from "../../common/v2modal/V2Modal";

const CustomStepButton = ({ label, completed, onClick, isActive, index }) => {
  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      {/* <StepButton
        color={isActive ? "primary" : "white"} // Use "primary" color for the active step
        onClick={onClick}
      >
      </StepButton> */}
      <div
        style={{
          width: "20px",
          height: "20px",
          borderRadius: "50%",
          background: completed || isActive ? "var(--color-main)" : "white",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          // border: isActive ? "1px solid var(--color-main)" : "none",
          // Use "green" background for completed steps
        }}
      >
        {completed ? (
          <FaCheck size={14} color="#fff" />
        ) : (
          <p
            style={{
              color: isActive ? "#fff" : "#000",
              fontSize: "12px",
              fontFamily: "var(--font-600)",
            }}
          >
            {index + 1}
          </p>
        )}
      </div>
      {/* <p
        style={{
          color: "#fff",
          fontSize: "12px",
          fontFamily: "var(--font-400)",
        }}
      >
        {label}
      </p> */}
    </div>
  );
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 2,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: "var(--color-main)",
      height: "1px",
      paddingTop: "2px",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: "var(--color-main)",
      height: "1px",
      paddingTop: "2px",

      // backgroundImage:
      //   "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 1,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
    paddingTop: "2px",
    width: "100%",
  },
}));

const CustomStepper = ({
  activeStep,
  completed,
  steps,
  handleStep,
  handleBack,
  handleNext,
  handleComplete,
  handleReset,
}) => {
  return (
    <Box sx={{ width: "100%", marginTop: "10px" }}>
      <Stepper
        nonLinear
        activeStep={activeStep}
        connector={<ColorlibConnector />}
      >
        {steps.map((label, index) => (
          <Step
            key={label}
            completed={completed[index]}
            sx={{ paddingLeft: "0px", paddingRight: "0px" }}
          >
            <CustomStepButton
              label={label}
              completed={completed[index]}
              onClick={handleStep(index)}
              isActive={index === activeStep}
              index={index}
            />
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

const CreateProduct = () => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "0px",
      backgroundColor: "none",
      border: "none",
      borderRadius: "12px",
    },
    overlay: {
      zIndex: 1000,
      backgroundColor: "rgba(0, 0, 0, 0.3)",
    },
  };
  const [showYearPicker, setShowYearPicker] = useState(false);
  const dateRef = useRef();

  const videoConstraints = {
    width: 310,
    height: 510,
  };

  // const toggleYearPicker = () => {
  //   setShowYearPicker(!showYearPicker);
  //   if (dateRef.current) {
  //     dateRef.current.handleClick();
  //   }
  // };
  //
  const [showModal1, setShowModal1] = useState(false);
  const [errorMessage1, setErrorMessage1] = useState("");
  //

  const { is_loading, getAllInterest } = useHomeContext();
  const { profile_loading, edit_profile_data, EditProfile } =
    useProfileContext();
  const navigate = useNavigate();
  const userId = localStorage.getItem("user_id");
  const Profile_img = localStorage.getItem("profile");
  const get_username = localStorage.getItem("username");

  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedImagesAndVidio, setSelectedImagesAndVidio] = useState([]);
  const [selectedImagesMin1Img, setSelectedImagesMin1Img] = useState([]);

  const [selectedCreator, setSelectedCreator] = useState(0);
  const [selectedGlob, setSelectedGlob] = useState(0);
  const [selectedAuction, setSelectedAuction] = useState(0);
  const [getallInterest, setAllInterest] = useState([]);
  const [getInterestArray, setInterestArray] = useState([]);
  const [gethashtags, setHashtags] = useState("");
  const [getproductname, setproductname] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [getcreatorname, setGetcreatorname] = useState("");
  const [getcreatorid, setGetcreatorid] = useState("");
  const [creatormodal, setCreatormodal] = useState(false);
  const [getseatch, searchFilter] = useState("");
  const [getalluser, setalluser] = useState([]);
  const [getallclubs, setallClubs] = useState([]);
  const [getsearchallclubs, setSearchallClubs] = useState([]);
  const [data_filter_data, setdata_filter_data] = useState([]);
  const [getallmanager, setallmanager] = useState([]);
  const [getsearchManagerData, setSearchManagerData] = useState([]);
  const [getmanegerName, setmanegerName] = useState("");
  const [getmanegerid, setmanegerId] = useState("");
  const [managermodal, setManagermodal] = useState(false);
  const [getimageFiles, setImageFiles] = useState([]);
  // ---
  const [getcreatornameText, setGetcreatornameText] = useState("");
  const [getcreatornameContactNo, setGetcreatornameContactNo] = useState(null);
  const [getcreatornameEmail, setGetcreatornameEmail] = useState("");
  const [getProductDes, setProductDes] = useState("");
  const [getProductStory, setProductStory] = useState("");
  const [getProductHighlight, setProductHighlight] = useState("");
  const [getProductValue, setProductValue] = useState(null);
  const [getminimumBid, setMinimumBid] = useState(null);
  const [getauctionDurtion, setAuctoinDuration] = useState(null);
  const [getsharenfbModal, setSharenfbModal] = useState(false);
  const [getuserlistModal, setUserListModal] = useState(false);
  const [getavailableBond, setAvailableBond] = useState(80);
  const [getnoOfBond, setNoOfBond] = useState(null);
  const [getnoOfBondClub, setNoOfBondClub] = useState(null);
  const [getdonationOwnerName, setDonationOwnerName] = useState("");
  const [getdonationOwnerId, setDonationOwnerId] = useState("");
  const [getdonationClubName, setDonationClubName] = useState("");
  const [getdonationClubId, setDonationClubId] = useState("");
  const [getisLoading, setIsLoading] = useState(false);
  const [getshareOwnerModal, setShareOwnerModal] = useState(false);
  const [getshareClubModal, setShareClubModal] = useState(false);

  const [getattechVideoModal, setAttechVideoModal] = useState(false);
  const [getpreviewVideoModal, setPreviewVideoModal] = useState(false);
  const [getpreviewVideoModal2, setPreviewVideoModal2] = useState(false);
  const [getvideoId, setVideoId] = useState("");
  const [getuserVid, setUserVideo] = useState("");
  const [getuserVid2, setUserVideo2] = useState("");
  const [getvideoArray, setVideoArray] = useState([]);
  const [getdonetQrhunt, setDonetQrhunt] = useState(0);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [getglobModalOpen, setGlobModalOpen] = useState(false);
  const [getglobModalMsg, setGlobModalMsg] = useState(
    "Non-Exclusive Commitment: Dear Asset Uploader, Thank you for considering aucemo.club as a platform to create digital assets of your asset. We appreciate your interest. Here are the terms for your non-exclusive commitment: Platform Fee: aucemo.club will charge 20 Aucemo of the asset as the platform fee. This fee allows us to provide you with a robust platform and comprehensive support. Royalty for Life: As a gesture of our partnership, you will receive a 5% royalty for life from the sales of the Aucemo. This means that for every Aucemo sold, you will earn a share of the revenue, ensuring a continuous stream of passive income. We believe in fostering a mutually beneficial relationship and are excited to have your asset on our platform. Lets collaborate to make your Aucemo a success!"
  );

  const [getownerName, setOwnerName] = useState("");
  const [getownerId, setOwnerId] = useState("");

  const [getisUserAndClub, setIsUserAndClub] = useState(0);

  const [
    getnumber_of_bonds_created_for_share,
    setnumber_of_bonds_created_for_share,
  ] = useState([]);

  const handleKeyPress = (e) => {
    // Check if the pressed key is a dot
    if (!(e.charCode >= 48 && e.charCode <= 57) && e.charCode !== 8) {
      e.preventDefault();
    }
  };

  const [
    getnumber_of_bonds_created_for_share_name,
    setnumber_of_bonds_created_for_share_name,
  ] = useState([]);

  const [
    getnumber_of_bonds_created_for_share_id,
    setnumber_of_bonds_created_for_share_id,
  ] = useState([]);

  // club and

  const [
    getclub_number_of_bonds_created_for_share,
    setclub_number_of_bonds_created_for_share,
  ] = useState([]);

  const [
    getclub_number_of_bonds_created_for_share_name,
    setclub_number_of_bonds_created_for_share_name,
  ] = useState([]);

  const [
    getclub_number_of_bonds_created_for_share_id,
    setclub_number_of_bonds_created_for_share_id,
  ] = useState([]);

  // tab start
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [gettab1Completed, setTab1Completed] = useState(false);
  const [gettab2Completed, setTab2Completed] = useState(false);
  const [gettab3Completed, setTab3Completed] = useState(false);
  const [gettab4Completed, setTab4Completed] = useState(false);
  const [gettab5Completed, setTab5Completed] = useState(false);

  const FORM_STEPS = [
    {
      label: `Details`,
    },
    {
      label: `Preferences`,
    },
    {
      label: `Complete`,
    },
  ];

  const steps = ["Step-1", "Step-2", "Step-3", "Step-4", "Step-5", "Step-6"];

  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
    console.log("getmanegerName", getmanegerName);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    console.log("activeStep", newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  // step validations start

  // useEffect(() => {
  //   if (getInterestArray == []) {
  //     // Set the completion status of the 0 index to false
  //     setCompleted((prevCompleted) => ({
  //       ...prevCompleted,
  //       0: false,
  //     }));
  //   }
  //   console.log("completed", completed);
  // }, [getInterestArray]);

  const ValidateStep1 = () => {
    let idString = "";
    var demo = 0;
    for (let index = 0; index < getallInterest.length; index++) {
      if (getallInterest[index].status == "2") {
        demo = demo + 1;

        if (index > 0) {
          idString += ",";
        }
        idString += getallInterest[index].id;
      }
    }
    if (getproductname == "") {
      setErrorMessage("Enter asset name");
      setShowModal(true);
    } else if (selectedCreator == 3 && getmanegerName == "") {
      setErrorMessage("Please select your owner");
      setShowModal(true);
    } else if (idString == "") {
      setErrorMessage("Please Select category");
      setShowModal(true);
    } else if (year == "") {
      setErrorMessage("Enter date of creation");
      setShowModal(true);
    } else {
      setTab1Completed(true);
      handleComplete();
    }
  };

  const ValidateStep2 = () => {
    if (getProductDes == "") {
      setErrorMessage("Enter description");
      setShowModal(true);
    } else {
      setTab2Completed(true);
      handleComplete();
    }
  };

  const ValidateStep3 = () => {
    if (selectedImages.length <= 0) {
      setErrorMessage("Upload owner file");
      setShowModal(true);
    } else if (selectedImagesMin1Img.length <= 0) {
      setErrorMessage("Upload minimum 1 image");
      setShowModal(true);
    } else {
      setTab3Completed(true);
      handleComplete();
    }
  };

  const ValidateStep4 = () => {
    if (getvideoId == "") {
      setErrorMessage("Please attach creator video");
      setShowModal(true);
    } else if (selectedVideos.length <= 0) {
      setErrorMessage("Upload video");
      setShowModal(true);
    } else {
      setTab4Completed(true);
      handleComplete();
    }
  };

  const ValidateStep5 = () => {
    if (
      selectedAuction == 0 &&
      (getProductValue == null || getProductValue == "" || getProductValue == 0)
    ) {
      setErrorMessage("Enter Asset value");
      setShowModal(true);
    } else {
      setTab5Completed(true);
      handleComplete();
    }
  };

  const ValidateStep6 = () => {
    if (
      selectedAuction == 1 &&
      (getauctionDurtion == null ||
        getauctionDurtion == "" ||
        getauctionDurtion == 0)
    ) {
      setErrorMessage("Enter Auction Days");
      setShowModal(true);
    } else if (
      selectedAuction == 1 &&
      (getminimumBid == null || getminimumBid == "" || getminimumBid == 0)
    ) {
      setErrorMessage("Enter minimum bid");
      setShowModal(true);
    }
    // else if (selectedCreator == 3) {
    //   // setTab4Completed(true);
    //   CreateproductManager();
    // }
    else {
      CreateproductYesOption();
    }
  };
  // step validations end

  // tab end

  // video strat

  const [selectedVideos, setSelectedVideos] = useState([]);
  const [getdonationSelect, setDonationSelect] = useState(false);

  const closemodal = () => {
    setShowModal(false);
  };
  const openmodal = () => {
    setShowModal(true);
  };

  const closemodal1 = () => {
    setShowModal1(false);
  };
  const openmodal1 = () => {
    setShowModal1(true);
  };

  // const handleVideoChange = (e) => {
  //   const files = Array.from(e.target.files);
  //   setSelectedVideos([...selectedVideos, ...files]);
  // };

  // const handleVideoChange = async (e) => {
  //   const files = Array.from(e.target.files);

  //   // Assuming you have a minimum and maximum duration in seconds
  //   const minDuration = 20; // Minimum duration in seconds
  // const maxDuration = 60; // Maximum duration in seconds

  //   // Validate each video file
  //   for (const file of files) {
  //     const video = document.createElement("video");
  //     video.src = URL.createObjectURL(file);

  //     // Wait for the video metadata to load
  //     await new Promise((resolve) => {
  //       video.onloadedmetadata = resolve;
  //     });

  //     const duration = video.duration;

  //     if (duration < minDuration || duration > maxDuration) {
  //       // Video duration is outside the allowed range
  //       alert(
  //         `Video duration should be between ${minDuration}s and ${maxDuration}s.`
  //       );
  //     } else {
  //       // Video is within the allowed range, add it to the state
  //       setSelectedVideos([...selectedVideos, file]);
  //     }

  //     // Clean up the video element
  //     video.remove();
  //   }
  // };

  const mediaRecorderRef = useRef(null);
  const chunksRef = useRef([]);

  // handle video without camera off start

  // const handleVideoChange = async () => {
  //   try {
  //     const stream = await navigator.mediaDevices.getUserMedia({ video: true });
  //     videoRef.current.srcObject = stream;

  //     mediaRecorderRef.current = new MediaRecorder(stream);

  //     mediaRecorderRef.current.ondataavailable = (event) => {
  //       if (event.data.size > 0) {
  //         chunksRef.current.push(event.data);
  //       }
  //     };

  //     mediaRecorderRef.current.onstop = () => {
  //       const blob = new Blob(chunksRef.current, { type: "video/webm" });
  //       const url = URL.createObjectURL(blob);
  //       // Do something with the recorded video URL, e.g., set it in state
  //       setSelectedVideos([...selectedVideos, url]);
  //     };

  //     mediaRecorderRef.current.start();
  //   } catch (error) {
  //     console.error("Error accessing camera:", error);
  //   }
  // };

  // const stopRecording = () => {
  //   if (
  //     mediaRecorderRef.current &&
  //     mediaRecorderRef.current.state === "recording"
  //   ) {
  //     mediaRecorderRef.current.stop();
  //   }
  // };

  // handle video without camera off end

  // handle new video function start

  const handleVideoChange = async (e) => {
    const files = Array.from(e.target.files);

    // Assuming you have a minimum and maximum duration in seconds
    const minDuration = 30; // Minimum duration in seconds
    const maxDuration = 60; // Maximum duration in seconds

    // Validate each video file
    for (const file of files) {
      const video = document.createElement("video");
      video.src = URL.createObjectURL(file);

      // Wait for the video metadata to load
      await new Promise((resolve) => {
        video.onloadedmetadata = resolve;
      });

      const duration = video.duration;

      if (duration < minDuration || duration > maxDuration) {
        // Video duration is outside the allowed range
        alert(
          `Video duration should be between ${minDuration}s and ${maxDuration}s.`
        );
      } else {
        // Video is within the allowed range, add it to the state
        setSelectedVideos([...files]);
      }

      // Clean up the video element
      video.remove();
    }
  };

  // handle new video function end

  const handleVideoDelete = (index) => {
    const newVideos = [...selectedVideos];
    newVideos.splice(index, 1);
    setSelectedVideos(newVideos);
    setRecordedChunks(newVideos);
  };

  // video end

  // date Piker

  const [startDate, setStartDate] = useState(new Date());
  const [startDate2, setStartDate2] = useState(new Date());
  const [isyear, setIsYear] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);

  // const handleDateChange = (date) => {
  //   setSelectedDate(date);
  // };

  // date
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;
  const currentDate = new Date().getDate();
  const [year, setYear] = useState(currentYear);
  const [month, setMonth] = useState("");
  const [day, setDay] = useState("");

  const daysInMonth = (year, month) => {
    return new Date(year, month, 0).getDate();
  };

  const handleYearChange = (e) => {
    const selectedYear = parseInt(e.target.value);
    if (selectedYear <= currentYear) {
      setYear(selectedYear);
      if (selectedYear === currentYear) {
        setMonth(currentMonth);
        setDay(currentDate);
      }
    }
    // Reset month and day if the year changes
    setMonth("");
    setDay("");
  };

  const handleMonthChange = (e) => {
    setMonth(parseInt(e.target.value));
    // Reset day if the month changes
    setDay("");
  };

  const handleDayChange = (e) => {
    setDay(parseInt(e.target.value));
  };

  const formatValue = (value) => {
    return value < 10 ? `0${value}` : `${value}`;
  };

  const handleSubmit = () => {
    if (year && month && day) {
      const formattedDate = `${formatValue(day)}-${formatValue(month)}-${year}`;
      console.log(formattedDate); // Store or use the formatted date
    } else {
      console.log("Please select a complete date");
    }
  };
  const days = Array.from(
    { length: daysInMonth(year, month) },
    (_, i) => i + 1
  );

  // date

  const handleChange = (e) => {
    setIsOpen(!isOpen);
    setStartDate(e);
    // setStartDate2(e);
    console.log("e", e);
    setIsYear(false);
    // setSelectedDate(moment(e).format("YYYY-MM-DD"));
  };
  const handleChange2 = (e) => {
    setIsOpen2(!isOpen2);

    // setStartDate2(e);
    setStartDate(e);
    setIsYear(true);
    console.log("e", moment(e).format("YYYY"));
    // setSelectedDate(moment(e).format("YYYY"));
  };
  const handleClick = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
    setIsOpen2(false);
  };
  const handleClick2 = (e) => {
    e.preventDefault();
    setIsOpen2(!isOpen2);
    setIsOpen(false);
  };

  // date end

  const fetchData = async () => {
    try {
      const intrestdata = await getAllInterest();
      console.log("Fetched data:", intrestdata); // Log fetched data
      setAllInterest(intrestdata.result);
      console.log("getallInterest", getallInterest);
    } catch (error) {
      console.error("Error fetching interest data:", error);
    }
  };

  const GetEditProfileApi = async () => {
    const param = {
      user_id: userId,
    };
    await axios
      .post(edit_profile, qs.stringify(param))
      .then((Response) => {
        if (Response.data.status == 1) {
          setOwnerName(Response.data.result.username);
          setOwnerId(Response.data.result.id);
        }
      })
      .catch((err) => {
        console.log("get_club_join_fees err", err);
      });
  };

  const GetUserVideos = async () => {
    const param = {
      user_id: userId,
    };
    await axios
      .post(get_user_profile_video, qs.stringify(param))
      .then((Response) => {
        if (Response.data.status == 1) {
          setVideoArray(Response.data.result.user_videos);
        }
      })
      .catch((err) => {
        console.log("get_user_profile_video err", err);
      });
  };

  useEffect(() => {
    if (getvideoArray.length === 1) {
      getvideoArray &&
        getvideoArray.map((i, index) => {
          console.log("sdcdsc", i);
          setVideoId(i.user_video_id);
          setUserVideo(i.user_videos);
          // setAttechVideoModal(false);
        });
    }
  }, [getvideoArray]);

  useEffect(() => {
    GetEditProfileApi();
    fetchData();
    GetUserVideos();
  }, []);

  // useEffect(() => {
  //   console.log("selectedDatE-NEW ", moment(selectedDate).format("DD-MM-YYYY"));
  // }, [selectedDate]);

  useEffect(() => {
    console.log(getuserlistModal);
  }, [getuserlistModal]);

  useEffect(() => {
    console.log(selectedCreator);
  }, [selectedCreator]);

  const handleOptionChange = (event) => {
    setSelectedCreator(event.target.value);
    setnumber_of_bonds_created_for_share([]);
    {
      (selectedCreator == 1 || selectedCreator == 3) && GetEditProfileApi();
    }
    setmanegerName("");
  };

  const handleShareUserAndClubChange = (event) => {
    setIsUserAndClub(event.target.value);
    console.log("event.target.value", event.target.value);
    // setnumber_of_bonds_created_for_share([]);
    // {
    //   (selectedCreator == 1 || selectedCreator == 3) && GetEditProfileApi();
    // }
  };

  const handleGlobOptionChange = (event) => {
    setSelectedGlob(event.target.value);
    setnumber_of_bonds_created_for_share([]);
    setclub_number_of_bonds_created_for_share([]);
    console.log("handleGlobOptionChange", event.target.value);

    setAvailableBond(event.target.value == 1 ? 90 : 80);
    setGlobModalMsg(
      event.target.value == 1
        ? "Exclusive Commitment: Dear Asset Uploader, Thank you for choosing aucemo.club as your platform to create digital assets of your asset exclusively. We are excited to collaborate with you on this journey. Here are the terms for your exclusive commitment: Platform Fee: aucemo.club will take only 10 Aucemo of this particular asset as the platform fee. This ensures that you retain a significant portion of your Aucemo for potential sales. Royalty for Life: As a token of appreciation for your exclusive commitment, you will receive a 10% royalty for life from the sales of the Aucemo. This means that for every Aucemo sold, you will earn a share of the revenue, providing you with ongoing passive income. We value your trust in aucemo.club, and we are committed to helping you maximize the potential of your asset through our platform. Let's work together to create a thriving marketplace for your Aucemo! Best regards"
        : "Non-Exclusive Commitment: Dear Asset Uploader, Thank you for considering aucemo.club as a platform to create digital assets of your asset. We appreciate your interest. Here are the terms for your non-exclusive commitment: Platform Fee: aucemo.club will charge 20 Aucemo of the asset as the platform fee. This fee allows us to provide you with a robust platform and comprehensive support. Royalty for Life: As a gesture of our partnership, you will receive a 5% royalty for life from the sales of the Aucemo. This means that for every Aucemo sold, you will earn a share of the revenue, ensuring a continuous stream of passive income. We believe in fostering a mutually beneficial relationship and are excited to have your asset on our platform. Lets collaborate to make your Aucemo a success!"
    );
  };

  const handleAuctionOptionChange = (event) => {
    setSelectedAuction(event.target.value);
    setMinimumBid(null);
    setAuctoinDuration(null);
    setProductValue(null);
    console.log("setSelectedAuction", event.target.value);
  };

  // share nfb list delete btn start

  const handleShareDelete = (item, index) => {
    setAvailableBond(
      (prevAvailableNoOfBond) => prevAvailableNoOfBond + parseInt(item)
    );

    const updatedDataName = [...getnumber_of_bonds_created_for_share_name];
    updatedDataName.splice(index, 1);
    setnumber_of_bonds_created_for_share_name(updatedDataName);

    const updatedDataShare = [...getnumber_of_bonds_created_for_share];
    updatedDataShare.splice(index, 1);
    setnumber_of_bonds_created_for_share(updatedDataShare);

    const updatedDataId = [...getnumber_of_bonds_created_for_share_id];
    updatedDataId.splice(index, 1);
    setnumber_of_bonds_created_for_share_id(updatedDataId);
  };

  // share nfb list delete btn end

  // share club nfb list delete btn start

  const handleClubShareDelete = (item, index) => {
    setAvailableBond(
      (prevAvailableNoOfBond) => prevAvailableNoOfBond + parseInt(item)
    );

    const updatedDataName = [...getclub_number_of_bonds_created_for_share_name];
    updatedDataName.splice(index, 1);
    setclub_number_of_bonds_created_for_share_name(updatedDataName);

    const updatedDataShare = [...getclub_number_of_bonds_created_for_share];
    updatedDataShare.splice(index, 1);
    setclub_number_of_bonds_created_for_share(updatedDataShare);

    const updatedDataId = [...getclub_number_of_bonds_created_for_share_id];
    updatedDataId.splice(index, 1);
    setclub_number_of_bonds_created_for_share_id(updatedDataId);
  };

  // share club nfb list delete btn end

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    // setImageFiles(e.target.files[0]);
    setSelectedImages((prevSelectedImages) => [
      ...prevSelectedImages,
      ...files,
    ]);
  };

  const handleImageAndVideoChange = (e) => {
    const files = Array.from(e.target.files);
    // setImageFiles(e.target.files[0]);
    setSelectedImagesAndVidio((prevSelectedImages) => [
      ...prevSelectedImages,
      ...files,
    ]);
  };

  useEffect(() => {
    console.log("selectedImagesMin1Img", selectedImagesMin1Img);
    if (selectedImagesMin1Img.length > 10) {
      setSelectedImagesMin1Img([]);
      setErrorMessage("You can select maximum of 10 images.");
      setShowModal(true);
    } else {
    }
  }, [selectedImagesMin1Img]);

  const handleImageChangeMin1Img = (e) => {
    const files = Array.from(e.target.files);
    console.log("files:", files.length);
    if (files.length == 11) {
    } else {
      setSelectedImagesMin1Img((prevSelectedImages) => [
        ...prevSelectedImages,
        ...files,
      ]);
    }
    // setImageFiles(e.target.files[0]);
  };

  const handleImageDelete = (index) => {
    const newImages = [...selectedImages];
    newImages.splice(index, 1);
    setSelectedImages(newImages);
  };

  const handleImageDelete2 = (index) => {
    const newImages = [...selectedImagesMin1Img];
    newImages.splice(index, 1);
    setSelectedImagesMin1Img(newImages);
  };

  const handleImageAndVideoDelete = (index) => {
    const newImages = [...selectedImagesAndVidio];
    newImages.splice(index, 1);
    setSelectedImagesAndVidio(newImages);
  };

  // share nfb to qr hunt start

  const handleQrDonateInputChange = (data) => {
    // Ensure that the entered value is not greater than 70
    if (parseInt(data) <= getavailableBond || data === "") {
      // this.setState({ donate: data });
      setDonetQrhunt(data);
    } else {
      setShowModal1(true);
      setErrorMessage1("Enter valid Aucemo");
    }
  };

  // share nfb to qr hunt end

  //  No option select api start
  const CreateproductNO = () => {
    setButtonDisabled(false);
    if (getproductname == "") {
      setErrorMessage("Enter asset name");
      setShowModal(true);
    } else if (getcreatorname == "") {
      setErrorMessage("Select owner name ");
      setShowModal(true);
    } else {
      setIsLoading(true);

      let idString = "";
      var demo = 0;
      for (let index = 0; index < getallInterest.length; index++) {
        if (getallInterest[index].status == "2") {
          demo = demo + 1;

          if (index > 0) {
            idString += ",";
          }
          idString += getallInterest[index].id;
        }
      }

      let formData = new FormData();

      formData.append("product_name", getproductname);
      formData.append("owner_name", getcreatorname);
      formData.append("owner_id", getcreatorid);

      formData.append("is_owner", 0);

      formData.append("user_id", userId);

      selectedImagesMin1Img.forEach((image, index) => {
        formData.append("img_video[]", image);
      });

      selectedImages.forEach((image, index) => {
        formData.append("proof_file", image);
      });
      for (var pair of formData.entries()) {
        console.log(pair[0] + ", " + pair[1]);
      }
      console.log("formData ==>", formData);
      // setIsLoading(false);
      axios
        .post(create_bond, formData)
        .then((Response) => {
          setIsLoading(false);

          setButtonDisabled(false);
          console.log("Response", Response);
          if (Response.data.status == 1) {
            navigate("/actionneed2");
          }
        })
        .catch((err) => {
          setIsLoading(false);

          setButtonDisabled(false);

          console.log("err", err);
        });
    }
  };
  //  No option select api end

  // not on platform creator api start
  const CreateproductNotPlatform = () => {
    setButtonDisabled(false);

    if (getproductname == "") {
      setErrorMessage("Enter asset name");
      setShowModal(true);
    } else if (selectedImages.length <= 0) {
      setErrorMessage("Upload owner file");
      setShowModal(true);
    } else {
      setIsLoading(true);

      let formData = new FormData();

      formData.append("product_name", getproductname);
      // formData.append("owner_name", getcreatorname);
      // formData.append("owner_id", getcreatorid);

      formData.append("is_owner", 2);
      formData.append("creator_name", getcreatornameText);
      formData.append("creator_contact", getcreatornameContactNo);
      formData.append("creator_email", getcreatornameEmail);
      formData.append("user_id", userId);

      selectedImagesMin1Img.forEach((image, index) => {
        formData.append("img_video[]", image);
      });

      selectedImages.forEach((image, index) => {
        formData.append("proof_file", image);
      });
      for (var pair of formData.entries()) {
        console.log(pair[0] + ", " + pair[1]);
      }
      axios
        .post(create_bond, formData)
        .then((Response) => {
          setIsLoading(false);
          setButtonDisabled(false);
          console.log("Response", Response);
          if (Response.data.status == 1) {
            navigate("/actionneed2");
          }
        })
        .catch((err) => {
          setIsLoading(false);
          setButtonDisabled(false);
          console.log("err", err);
        });
    }
  };
  // not on platform creator api end

  // creator on platform  api start
  const CreateproductYesOption = () => {
    setButtonDisabled(false);
    let idString = "";
    var demo = 0;
    for (let index = 0; index < getallInterest.length; index++) {
      if (getallInterest[index].status == "2") {
        demo = demo + 1;

        if (index > 0) {
          idString += ",";
        }
        idString += getallInterest[index].id;
      }
    }
    if (getproductname == "") {
      setErrorMessage("Enter asset name");
      setShowModal(true);
    } else if (selectedCreator == 3 && getmanegerName == "") {
      setErrorMessage("Please select your owner");
      setShowModal(true);
    } else if (idString == "") {
      setErrorMessage("Please Select category");
      setShowModal(true);
    } else if (setYear == "") {
      setErrorMessage("Select date of creation");
      setShowModal(true);
    } else if (getProductDes == "") {
      setErrorMessage("Enter description");
      setShowModal(true);
    } else if (selectedImages.length <= 0) {
      setErrorMessage("Upload owner file");
      setShowModal(true);
    } else if (selectedVideos.length <= 0) {
      setErrorMessage("Upload video");
      setShowModal(true);
    } else if (
      // getProductValue == null ||
      // getProductValue == "" ||
      // getProductValue == 0

      (selectedAuction == 0 && getProductValue == null) ||
      getProductValue == "" ||
      getProductValue == 0
    ) {
      setErrorMessage("Enter Asset value");
      setShowModal(true);
    } else if (
      // getProductValue == null ||
      // getProductValue == "" ||
      // getProductValue == 0

      selectedAuction == 1 &&
      (getminimumBid == null || getminimumBid == "" || getminimumBid == 0)
    ) {
      setErrorMessage("Enter minimum bid");
      setShowModal(true);
    } else if (
      // getProductValue == null ||
      // getProductValue == "" ||
      // getProductValue == 0

      selectedAuction == 1 &&
      (getauctionDurtion == null ||
        getauctionDurtion == "" ||
        getauctionDurtion == 0)
    ) {
      setErrorMessage("Enter Auction Days");
      setShowModal(true);
    } else if (getvideoId == "") {
      setErrorMessage("Please attach creator video");
      setShowModal(true);
    } else {
      // setIsLoading(true);
      setGlobModalOpen(true);
    }
  };
  // creator on platform api end

  // not on platform creator api start
  const CreateproductManager = () => {
    setButtonDisabled(false);
    let idString = "";
    var demo = 0;
    for (let index = 0; index < getallInterest.length; index++) {
      if (getallInterest[index].status == "2") {
        demo = demo + 1;

        if (index > 0) {
          idString += ",";
        }
        idString += getallInterest[index].id;
      }
    }
    if (getproductname == "") {
      setErrorMessage("Enter asset name");
      setShowModal(true);
    } else if (idString == "") {
      setErrorMessage("Please Select category");
      setShowModal(true);
    } else if (getmanegerName == "") {
      setErrorMessage("Please select your owner");
      setShowModal(true);
    } else if (year == "") {
      setErrorMessage("Enter date of creation");
      setShowModal(true);
    } else if (getProductDes == "") {
      setErrorMessage("Enter description");
      setShowModal(true);
    } else if (selectedImages.length <= 0) {
      setErrorMessage("Upload owner file");
      setShowModal(true);
    } else if (selectedVideos.length <= 0) {
      setErrorMessage("Upload video");
      setShowModal(true);
    } else if (
      getProductValue == null ||
      getProductValue == "" ||
      getProductValue == 0
    ) {
      setErrorMessage("Enter Asset value");
      setShowModal(true);
    } else if (getvideoId == "") {
      setErrorMessage("Please attach video");
      setShowModal(true);
    } else {
      setIsLoading(true);
      let formData = new FormData();

      formData.append("product_name", getproductname);
      formData.append("owner_name", get_username);
      formData.append("owner_id", getmanegerid);
      formData.append("bond_interest_id", idString);
      formData.append("globle_exclusive", selectedGlob);
      formData.append("product_history", getProductStory);
      formData.append("product_highlights", getProductHighlight);
      formData.append("description", getProductDes);
      formData.append("product_value", getProductValue);
      formData.append("product_value_checker", selectedAuction);
      formData.append("minimum_bid_amount", getminimumBid);
      formData.append("auction_days", getauctionDurtion);
      formData.append("product_hashtag", gethashtags);
      formData.append("is_owner", selectedCreator);
      formData.append("user_id", userId);
      formData.append("user_video_id", getvideoId);
      selectedImagesMin1Img.forEach((image, index) => {
        formData.append("img_video[]", image);
      });
      selectedImagesAndVidio.forEach((image, index) => {
        formData.append("media_upload[]", image);
      });

      selectedImages.forEach((image, index) => {
        formData.append("proof_file", image);
      });

      selectedVideos.forEach((image, index) => {
        formData.append("bond_video", image);
      });

      // formData.append(
      //   "date_of_creation",
      //   moment(selectedDate).format("YYYY-MM-DD")
      // );

      if (month == "" && day == "") {
        formData.append("date_of_creation", year);
      } else if (day == "") {
        formData.append("date_of_creation", `${year}-${month}`);
      } else {
        formData.append("date_of_creation", `${year}-${month}-${day}`);
      }

      let idString_usernmae = "";
      let idString_number_of_bond = "";
      let idString_user_id = "";
      var demo = 0;
      for (
        let index = 0;
        index < getnumber_of_bonds_created_for_share.length;
        index++
      ) {
        demo = demo + 1;

        if (index > 0) {
          idString_usernmae += ",";
          idString_number_of_bond += ",";
          idString_user_id += ",";
        }
        idString_usernmae += getnumber_of_bonds_created_for_share_name[index];
        idString_number_of_bond += getnumber_of_bonds_created_for_share[index];
        idString_user_id += getnumber_of_bonds_created_for_share_id[index];
      }

      formData.append("bond_share_owner_name", idString_usernmae);
      formData.append("bond_share_owner_id", idString_user_id);
      formData.append("bond_share_total_assign", idString_number_of_bond);

      let club_idString_usernmae = "";
      let club_idString_number_of_bond = "";
      let club_idString_user_id = "";
      var club_demo = 0;
      for (
        let index = 0;
        index < getclub_number_of_bonds_created_for_share.length;
        index++
      ) {
        club_demo = club_demo + 1;

        if (index > 0) {
          club_idString_usernmae += ",";
          club_idString_number_of_bond += ",";
          club_idString_user_id += ",";
        }
        club_idString_usernmae +=
          getclub_number_of_bonds_created_for_share_name[index];
        club_idString_number_of_bond +=
          getclub_number_of_bonds_created_for_share[index];
        club_idString_user_id +=
          getclub_number_of_bonds_created_for_share_id[index];
      }
      formData.append("share_club_name", club_idString_usernmae);
      formData.append("share_club_id", club_idString_user_id);
      formData.append("share_total_assign", club_idString_number_of_bond);
      // formData.append("total_qr_hunt_bond", getdonetQrhunt);
      formData.append("share_user_id", userId);

      let linktag = "";
      var demolinktag = 0;
      for (let index = 0; index < inputValues.length; index++) {
        if (inputValues[index]) {
          demolinktag = demolinktag + 1;

          if (index > 0) {
            linktag += ",";
          }
          linktag += inputValues[index];
        }
      }
      formData.append("social_media_link", linktag);

      console.log("formData ==>", formData);
      for (let pair of formData.entries()) {
        console.log(`${pair[0]}: ${pair[1]}`);
      }

      axios
        .post(create_bond, formData)
        .then((Response) => {
          setButtonDisabled(false);
          console.log("Response", Response);
          setIsLoading(false);
          if (Response.data.status == 1) {
            navigate("/actionneed2");
          }
        })
        .catch((err) => {
          setButtonDisabled(false);
          console.log("err", err);
          setIsLoading(false);
        });
    }
  };
  // not on platform creator api end

  const getUser = () => {
    const param = {
      user_id: userId,
    };
    axios
      .post(getall_user, param)
      .then((Response) => {
        console.log(
          "Response.data.result",
          JSON.stringify(Response.data.result, null, 2)
        );
        setalluser(Response.data.result);
        // this.setState({
        //   // data: Response.data.result,
        //   task_arrayholder: Response.data.result,
        // });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllClubs = () => {
    const param = {
      user_id: userId,
    };
    axios
      .post(get_all_club_2, param)
      .then((Response) => {
        console.log(
          "Response.data.result",
          JSON.stringify(Response.data.result, null, 2)
        );
        setallClubs(Response.data.result);
        setSearchallClubs(Response.data.result);
        // this.setState({
        //   // data: Response.data.result,
        //   task_arrayholder: Response.data.result,
        // });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getManager = async () => {
    const param = {
      user_id: userId,
    };
    await axios
      .post(get_manager_all_user, param)
      .then((Response) => {
        console.log(
          "Response.data.result",
          JSON.stringify(Response.data.result, null, 2)
        );
        setallmanager(Response.data.result);
        setSearchManagerData(Response.data.result);
        // this.setState({
        //   // data: Response.data.result,
        //   task_arrayholder: Response.data.result,
        // });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getUser();
    getAllClubs();
    getManager();
  }, []);

  const selecteduser = (item) => {
    setCreatormodal(false);
    // setSharenfbModal(true);
    // setDonationOwnerName(item.label);
    // setDonationOwnerId(item.id);

    setGetcreatorname(item.label);
    setGetcreatorid(item.id);
  };

  const Search_user = (value) => {
    const newData = getalluser.filter(function (item) {
      const employee = item.label ? item.label.toUpperCase() : "".toUpperCase();
      const number = item.phone ? item.phone.toUpperCase() : "".toUpperCase();
      const email = item.email ? item.email.toUpperCase() : "".toUpperCase();

      const textData = value.toUpperCase();
      return (
        employee.indexOf(textData) > -1 ||
        number.indexOf(textData) > -1 ||
        email.indexOf(textData) > -1
      );
    });
    setdata_filter_data(newData);
    console.log("asvsvsds", newData);
  };

  const Search_manager = (value) => {
    const newData = getsearchManagerData.filter(function (item) {
      const employee = item.label ? item.label.toUpperCase() : "".toUpperCase();
      const number = item.phone ? item.phone.toUpperCase() : "".toUpperCase();
      // const email = item.email ? item.email.toUpperCase() : "".toUpperCase();

      const textData = value.toUpperCase();
      return employee.indexOf(textData) > -1 || number.indexOf(textData) > -1;
    });
    setallmanager(newData);
    console.log("asvsvsds", newData);
  };

  const Search_clubs = (value) => {
    const newData = getsearchallclubs.filter(function (item) {
      const employee = item.club_name
        ? item.club_name.toUpperCase()
        : "".toUpperCase();
      const number = item.phone_number
        ? item.phone_number.toUpperCase()
        : "".toUpperCase();

      // const email = item.email ? item.email.toUpperCase() : "".toUpperCase();

      const textData = value.toUpperCase();
      return employee.indexOf(textData) > -1 || number.indexOf(textData) > -1;
    });
    // console.log("newData", newData);
    setallClubs(newData);
    console.log("asvsvsds", newData);
  };

  // media links start

  const [inputValues, setInputValues] = useState([""]);

  const validateSocialMediaLink = (link) => {
    const urlPattern =
      /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-])\/?$/;

    // Test if the inputValue matches the URL pattern
    return urlPattern.test(link);
  };

  const handleInputChange = (text, index) => {
    const updatedInputValues = [...inputValues];
    updatedInputValues[index] = text;
    setInputValues(updatedInputValues);
  };

  const handleAddValue = () => {
    const isValid = inputValues.every(validateSocialMediaLink);

    if (isValid) {
      setInputValues([...inputValues, ""]); // Add a new empty input field
    } else {
      // Handle invalid input
      console.log("Please enter valid social media links for all fields.");
      setShowModal(true);
      setErrorMessage("Please enter valid social media links");
    }
  };

  const handleRemoveValue = () => {
    if (inputValues.length > 1) {
      const updatedInputValues = [...inputValues];
      updatedInputValues.pop(); // Remove the last input field
      setInputValues(updatedInputValues);
    }
  };

  const handlePhoneNumberChange = (event) => {
    const value = event.target.value;
    const numericValue = value.replace(/\D/g, ""); // Remove non-digit characters

    if (numericValue.length <= 10) {
      setGetcreatornameContactNo(numericValue);
      // setErrorMessage("");
    } else {
      // If more than 10 digits, trim to the first 10 digits
      const trimmedValue = numericValue.slice(0, 10);
      setGetcreatornameContactNo(trimmedValue);
      console.log("trimmedValue", trimmedValue);
      // setphonedisable()
      // alert("Please enter up to 10 digits.");
      // setcusmsg("Please valid phone number.");
      // setShowModal(true);
    }
  };

  // media links end
  const ddd = getavailableBond - getdonetQrhunt;

  //disable body scrolling

  const handleAssetsChange = (e) => {
    const inputValue = e.target.value;
    const words = inputValue.trim().split(/\s+/); // Split input into words

    const containsEmoji =
      /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}]/u.test(
        inputValue
      );

    // Check if the length of the input is less than or equal to 10 and does not contain emojis
    if (words.length <= 10 && !containsEmoji) {
      setproductname(inputValue);
    }
  };

  // const handleHashtagsChange = (e) => {
  //   const inputValue = e.target.value;

  //   // Remove any existing '#' and spaces
  //   const sanitizedValue = inputValue.replace(/[#\s]/g, "");

  //   // Split the sanitized value into chunks of up to 10 characters
  //   const chunks = [];
  //   for (let i = 0; i < sanitizedValue.length; i += 10) {
  //     chunks.push(sanitizedValue.slice(i, i + 10));
  //   }

  //   // Add '#' to each chunk and join them with a space
  //   const formattedValue = chunks.map((chunk) => `#${chunk}`).join(" ");

  //   // Update state
  //   setHashtags(formattedValue);
  // };

  // const handleHashtagsChange = (e) => {
  //   const inputValue = e.target.value;

  //   // Remove any existing '#' and spaces
  //   const sanitizedValue = inputValue.replace(/[#\s]/g, "");

  //   // Split the sanitized value into chunks of up to 9 characters
  //   const chunks = [];
  //   for (let i = 0; i < sanitizedValue.length; i += 10) {
  //     chunks.push(sanitizedValue.slice(i, i + 10));
  //   }

  //   // Join chunks with a space and add '#' to each chunk
  //   const formattedValue = chunks.map((chunk) => `#${chunk}`).join(" ");

  //   // Update state
  //   setHashtags(formattedValue);
  // };

  const handleHashtagsChange = (e) => {
    const data = e.target.value;

    const updatedValue = data.replace(/\s+/g, "#");
    setHashtags(updatedValue);

    let formattedText = data.replace(/(\s|^)(\w+)/g, "$1#$2");
    formattedText = formattedText.replace(/##/g, "#"); // Remove duplicate hashtags

    // Limit to 10 words
    setHashtags(formattedText);
  };

  const videoRef = useRef(null);
  const [recording, setRecording] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [getrecordedvideo, setrecordedvideo] = useState(false);
  const [recordingTime, setRecordingTime] = useState(60);
  const [isRecordingstatus, setRecordingstatus] = useState(false);
  const [isModalVisiblevideo, setIsModalVisiblevideo] = useState(false);

  const [bodyScrollEnabled, setBodyScrollEnabled] = useState(true);

  useEffect(() => {
    if (
      showModal ||
      showModal1 ||
      getattechVideoModal ||
      isModalVisiblevideo ||
      getglobModalOpen
    ) {
      // Disable body scrolling
      setBodyScrollEnabled(false);
    } else {
      // Enable body scrolling
      setBodyScrollEnabled(true);
    }
    if (bodyScrollEnabled) {
      document.body.style.overflow = "visible";
    } else {
      document.body.style.overflow = "hidden";
    }
  }, [
    showModal,
    showModal1,
    getattechVideoModal,
    isModalVisiblevideo,
    bodyScrollEnabled,
    getglobModalOpen,
  ]);

  const recorderRef = useRef(null);
  // const startRecording = () => {
  //   setRecordingstatus(true);
  //   setRecordingTime(0);
  //   if (videoRef.current) {
  //     const recorder = RecordRTC(videoRef.current.stream, {
  //       type: "video",
  //       mimeType: "video/webm",
  //     });
  //     recorder.startRecording();
  //     recorderRef.current = recorder;
  //     setRecording(true);
  //     setrecordedvideo(false);
  //   }
  // };

  const startRecording = () => {
    setRecordingstatus(true);
    setRecordingTime(60);
    if (videoRef.current) {
      navigator.mediaDevices
        .getUserMedia({
          video: true,
          audio: {
            echoCancellation: true,
            noiseSuppression: true,
            autoGainControl: true,
          },
        })
        .then((stream) => {
          const recorder = RecordRTC(stream, {
            type: "video",
            mimeType: "video/webm",
          });
          recorder.startRecording();
          recorderRef.current = recorder;
          setRecording(true);
          setrecordedvideo(false);
          videoRef.current.srcObject = stream;
        })
        .catch((error) => {
          console.error("Error accessing media devices:", error);
        });
    }
  };

  // const stopRecording = () => {
  //   setRecordingstatus(false);
  //   if (recorderRef.current) {
  //     recorderRef.current.stopRecording(() => {
  //       const blob = recorderRef.current.getBlob();
  //       console.log("blob", blob);
  //       setRecordedChunks([blob]);
  //       setSelectedVideos(...selectedVideos, [blob]);
  //       setRecording(false);
  //       setrecordedvideo(true);
  //       // Clear recorded data if needed
  //       recorderRef.current.clearRecordedData();
  //     });
  //   }
  // };

  // const startRecording = async () => {
  //   try {
  //     setRecording(true);
  //     setRecordedChunks([]);
  //     setRecordingTime(0);
  //     const stream = await navigator.mediaDevices.getUserMedia({
  //       audio: true,
  //       video: true,
  //     });
  //     videoRef.current.srcObject = stream;

  //     const recorder = new RecordRTC(stream, {
  //       type: "video",
  //       mimeType: "video/webm",
  //     });

  //     recorder.startRecording();
  //     recorderRef.current = recorder;
  //     setRecording(true);
  //     setrecordedvideo(false);
  //   } catch (error) {
  //     console.error("Error accessing camera:", error);
  //     setRecording(false);
  //   }
  // };

  const stopRecording = () => {
    setRecording(false);
    const minDuration = 30;
    const maxDuration = 60;
    if (recorderRef.current) {
      recorderRef.current.stopRecording(() => {
        const blob = recorderRef.current.getBlob();

        console.log("blob---->", blob.length);
        // Use the current value of recordingTime from the useEffect
        if (60 - recordingTime < minDuration) {
          // Display an alert or take other actions for insufficient duration
          alert(
            `Video duration should be between ${minDuration}s and ${maxDuration}s.`
          );
          setRecordedChunks([]);
          setSelectedVideos([]);
        } else {
          // Continue with handling the recorded video
          setRecordedChunks([blob]);
          setSelectedVideos([blob]);
          setRecordingTime(60);
          setIsModalVisiblevideo(false);

          // Stop the media stream to release the camera
          const stream = videoRef.current.srcObject;
          if (stream) {
            const tracks = stream.getTracks();
            tracks.forEach((track) => track.stop());
          }
        }
      });
    }
  };
  const stopRecording1 = () => {
    setRecording(false);
    const minDuration = 30;
    const maxDuration = 60;

    if (recorderRef.current) {
      recorderRef.current.stopRecording(() => {
        const blob = recorderRef.current.getBlob();
        console.log("blob---->", blob.length);
        const stream = videoRef.current.srcObject;
        if (stream) {
          const tracks = stream.getTracks();
          tracks.forEach((track) => track.stop());
        }
        // if (recordingTime < minDuration) {
        //   // console.error(
        //   //   `Recording time should be at least ${minDuration} seconds.`
        //   // );
        //   alert(
        //     `Video duration should be between ${minDuration}s and ${maxDuration}s.`
        //   );
        //   setRecordedChunks([]);
        //   setSelectedVideos([]);
        // } else {
        setRecordedChunks([blob]);
        setSelectedVideos([blob]);
        setRecordingTime(60);
        setIsModalVisiblevideo(false);
        // }
      });
    }
  };

  // const stopRecording = () => {
  //   const minDuration = 20; // Minimum duration in seconds
  //   const maxDuration = 60;
  //   setRecording(false);
  //   if (recorderRef.current) {
  //     recorderRef.current.stopRecording(() => {
  //       const blob = recorderRef.current.getBlob();
  //       const durationInSeconds =
  //         blob.size / (blob.type === "audio/wav" ? 2 : 1) / 1000;
  //       if (
  //         durationInSeconds < minDuration ||
  //         durationInSeconds > maxDuration
  //       ) {
  //         alert(
  //           `Video duration should be between ${minDuration}s and ${maxDuration}s.`
  //         );
  //         // Optionally, you may want to clear the recorded data and not add it to the state
  //         recorderRef.current.clearRecordedData();
  //       } else {
  //         // Video is within the allowed range, add it to the state
  //         setRecordedChunks([blob]);
  //         setSelectedVideos([...selectedVideos, blob]);
  //       }
  //     });
  //   }
  // };

  // const stopRecording = () => {
  //   const minDuration = 20; // Minimum duration in seconds
  //   const maxDuration = 60;
  //   setRecording(false);

  //   if (recorderRef.current) {
  //     recorderRef.current.stopRecording(() => {
  //       const blob = recorderRef.current.getBlob();

  //       // Calculate the duration based on start and stop timestamps
  //       const durationInSeconds =
  //         ((blob && blob.size) /
  //           (recorderRef.current.getAudioLength() || blob.size)) *
  //         (recorderRef.current.getDuration() || 1);

  //       // Check if the recorded video duration is within the allowed range
  //       if (
  //         durationInSeconds < minDuration ||
  //         durationInSeconds > maxDuration
  //       ) {
  //         alert(
  //           `Video duration should be between ${minDuration}s and ${maxDuration}s.`
  //         );
  //         // Optionally, you may want to clear the recorded data and not add it to the state
  //         recorderRef.current.clearRecordedData();
  //       } else {
  //         // Video is within the allowed range, add it to the state
  //         setRecordedChunks([blob]);
  //         setSelectedVideos([...selectedVideos, blob]);
  //       }
  //     });
  //   }
  // };

  // useEffect(() => {
  //   let timerInterval;
  //   if (isRecordingstatus) {
  //     timerInterval = setInterval(() => {
  //       setRecordingTime((prevTime) => prevTime + 1);
  //     }, 1000);
  //   }

  //   return () => {
  //     clearInterval(timerInterval);
  //   };
  // }, [isRecordingstatus]);

  useEffect(() => {
    let timerInterval;
    const minDuration = 30;

    if (isRecordingstatus) {
      timerInterval = setInterval(() => {
        setRecordingTime((prevTime) => {
          const newTime = prevTime - 1;

          // Check if the recording time is less than the minimum duration
          if (newTime < minDuration) {
            // Display an error or take other actions
            console.error(
              `Recording time should be at least ${minDuration} seconds.`
            );
          }

          // Check if the recording time reaches 1 second
          if (newTime <= 1) {
            stopRecording1(); // Stop recording or take other actions
          }

          return newTime;
        });
      }, 1000);
    }

    return () => {
      clearInterval(timerInterval);
    };
  }, [isRecordingstatus]);

  const resetCountdown = () => {
    setIsModalVisiblevideo(false);
    setRecordingTime(60);
  };
  const showimage = () => {
    stopRecording();
    setRecordedChunks([]);
    setSelectedVideos([]);
    resetCountdown();
    console.log(videoRef.current);
    // Stop the media stream to release the camera
    const stream = videoRef.current.srcObject;
    if (stream) {
      const tracks = stream.getTracks();
      tracks.forEach((track) => track.stop());
    }
  };

  const closemodal3 = () => {
    setGlobModalOpen(false);
  };

  return (
    <div className="page_main_wrapp">
      <>
        {getisLoading ? (
          <div className="loader"></div>
        ) : (
          <>
            {creatormodal == false && managermodal == false ? (
              <>
                <div className="page_base_wrapp_2">
                  <NavbarSec name="Protect Asset" />

                  {/* new calender start */}

                  {/* new calender end */}

                  <div className="product_form_wrapp">
                    {/* ARE YOU CREATOR START */}
                    {gettab1Completed ||
                    gettab2Completed ||
                    gettab3Completed ||
                    gettab4Completed ||
                    gettab5Completed ? null : (
                      <>
                        <label
                          htmlFor="product_name"
                          className="form_input_label"
                        >
                          Do you own the copyright ?
                        </label>
                        <div className="cbond_form_radio_wrapp">
                          <div id="custom_radio">
                            <label class="custom_radio">
                              <input
                                type="radio"
                                name="checkbox"
                                value={1}
                                checked={selectedCreator == 1}
                                onChange={handleOptionChange}
                              />
                              <span class="checkmark"></span>
                              Yes
                            </label>
                            <label class="custom_radio">
                              <input
                                type="radio"
                                name="checkbox"
                                value={0}
                                checked={selectedCreator == 0}
                                onChange={handleOptionChange}
                              />
                              <span class="checkmark"></span>
                              No
                            </label>
                            <label class="custom_radio">
                              <input
                                type="radio"
                                name="checkbox"
                                value={2}
                                checked={selectedCreator == 2}
                                onChange={handleOptionChange}
                              />
                              <span class="checkmark"></span>
                              Creator not on platform
                            </label>

                            {getallmanager && getallmanager.length > 0 && (
                              <label class="custom_radio">
                                <input
                                  type="radio"
                                  name="checkbox"
                                  value={3}
                                  checked={selectedCreator == 3}
                                  onChange={handleOptionChange}
                                />
                                <span class="checkmark"></span>
                                Protect asset on behalf of owner
                              </label>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                    {/* ARE YOU CREATOR END */}

                    {/* tabs start */}
                    {/* <Box sx={{ width: "100%" }}>
                      <Stepper nonLinear activeStep={activeStep}>
                        {steps.map((label, index) => (
                          <Step key={label} completed={completed[index]}>
                            <StepButton
                              color="white"
                              onClick={handleStep(index)}
                            >
                              {label}
                            </StepButton>
                          </Step>
                        ))}
                      </Stepper>
                      <div>
                        {allStepsCompleted() ? (
                          <React.Fragment>
                            <Typography sx={{ mt: 2, mb: 1 }}>
                              All steps completed - you&apos;re finished
                            </Typography>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                pt: 2,
                              }}
                            >
                              <Box sx={{ flex: "1 1 auto" }} />
                              <Button onClick={handleReset}>Reset</Button>
                            </Box>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
                              Step {activeStep + 1}
                            </Typography>      
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                pt: 2,
                              }}
                            >
                              <Button
                                color="inherit"
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                sx={{ mr: 1 }}
                              >
                                Back
                              </Button>
                              <Box sx={{ flex: "1 1 auto" }} />
                              <Button onClick={handleNext} sx={{ mr: 1 }}>
                                Next
                              </Button>
                              {activeStep !== steps.length &&
                                (completed[activeStep] ? (
                                  <Typography
                                    variant="caption"
                                    sx={{ display: "inline-block" }}
                                  >
                                    Step {activeStep + 1} already completed
                                  </Typography>
                                ) : (
                                  <Button onClick={handleComplete}>
                                    {completedSteps() === totalSteps() - 1
                                      ? "Finish"
                                      : "Complete Step"}
                                  </Button>
                                ))}
                            </Box>
                          </React.Fragment>
                        )}
                      </div>
                    </Box> */}
                    {(selectedCreator == 1 || selectedCreator == 3) && (
                      <>
                        <CustomStepper
                          activeStep={activeStep}
                          completed={completed}
                          steps={steps}
                          handleStep={handleStep}
                          handleBack={handleBack}
                          handleNext={handleNext}
                          handleComplete={handleComplete}
                          handleReset={handleReset}
                        />

                        <div style={{ width: "100%" }}>
                          {completed.length === steps.length ? (
                            <React.Fragment>
                              <Typography sx={{ mt: 2, mb: 1 }}>
                                All steps completed - you&apos;re finished
                              </Typography>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  pt: 2,
                                }}
                              >
                                <Box sx={{ flex: "1 1 auto" }} />
                                <Button onClick={handleReset}>Reset</Button>
                              </Box>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              {activeStep === 0 && (
                                <>
                                  <div
                                    style={{
                                      width: "100%",
                                      padding: "10px 0px",
                                      // border: "1px solid green",
                                      margin: "10px auto",
                                    }}
                                  >
                                    {/* creator name start */}

                                    {selectedCreator == 3 ? (
                                      <>
                                        <label
                                          htmlFor="product_name"
                                          className="form_input_label mt-2"
                                        >
                                          Creator Name
                                        </label>
                                        <input
                                          type="text"
                                          name="product_name"
                                          className="form_input_box"
                                          placeholder="Please select your owner"
                                          // onChange={(e) => {
                                          //   setproductname(e.target.value);
                                          // }}
                                          // disabled
                                          readOnly
                                          value={getmanegerName}
                                          onClick={() => {
                                            setManagermodal(true);
                                            console.log(
                                              "managermodal",
                                              managermodal
                                            );
                                          }}
                                        />
                                      </>
                                    ) : (
                                      <>
                                        <label
                                          htmlFor="product_name"
                                          className="form_input_label mt-2"
                                        >
                                          Creator Name
                                        </label>
                                        <input
                                          type="text"
                                          name="product_name"
                                          className="form_input_box"
                                          placeholder={getownerName}
                                          // onChange={(e) => {
                                          //   setproductname(e.target.value);
                                          // }}
                                          disabled
                                          value={getownerName}
                                        />
                                      </>
                                    )}
                                    {/* creator name end */}

                                    {/* PRODUCT NAME START */}
                                    <label
                                      htmlFor="product_name"
                                      className="form_input_label mt-2"
                                    >
                                      Asset Name/Title
                                    </label>
                                    <input
                                      type="text"
                                      name="product_name"
                                      className="form_input_box"
                                      value={getproductname}
                                      placeholder="Enter Asset Name"
                                      onChange={handleAssetsChange}
                                      disabled={gettab1Completed}
                                    />
                                    {/* PRODUCT NAME END */}

                                    {/* category START */}
                                    <label
                                      htmlFor="category"
                                      className="form_input_label mt-2"
                                    >
                                      Select Category
                                    </label>

                                    {is_loading ? (
                                      <div className="loader"></div>
                                    ) : (
                                      <div className="product_interests_wrapp">
                                        {getallInterest &&
                                          getallInterest.length > 0 &&
                                          getallInterest.map((item, index) => {
                                            return (
                                              <>
                                                <button
                                                  onClick={() => {
                                                    const updatedArray = [
                                                      ...getallInterest,
                                                    ];
                                                    updatedArray[index].status =
                                                      updatedArray[index]
                                                        .status === 1
                                                        ? 2
                                                        : 1;

                                                    // this.setState({ myArray: updatedArray });
                                                    setInterestArray(
                                                      updatedArray
                                                    );

                                                    console.log(
                                                      "===================================="
                                                    );
                                                    console.log(
                                                      "getallInterest",
                                                      getallInterest
                                                    );
                                                  }}
                                                  // disabled={
                                                  //   getInterestArray.length >
                                                  //     0 && gettab1Completed
                                                  // }
                                                  className="interest_sigle_btn"
                                                  style={{
                                                    border: "1px solid",
                                                    borderColor:
                                                      item.status == 2
                                                        ? "var(--color-main)"
                                                        : "var(--color-gray)",
                                                  }}
                                                >
                                                  <img
                                                    src={
                                                      "https://www.ravi.host/public/assets/interest/" +
                                                      item.icon
                                                    }
                                                    className="interest_sigle_icon"
                                                    alt=""
                                                  />
                                                  <p className="interest_sigle_icon_name">
                                                    {item.name}
                                                  </p>
                                                </button>
                                              </>
                                            );
                                          })}
                                      </div>
                                    )}

                                    {/* category END */}

                                    <div
                                      className="glob_row_wrapp"
                                      style={{ marginTop: "18px" }}
                                    >
                                      <label
                                        htmlFor="glob_exclusive"
                                        className="form_input_label"
                                      >
                                        Date of creation
                                      </label>

                                      <>
                                        <div className="custom-date-picker">
                                          <div className="date-input">
                                            {/* <input
                                  style={{
                                    width: "38px",
                                    padding: "5px 10px",
                                    marginBottom: "5px",
                                  }}
                                  type="text"
                                  placeholder="DD"
                                  readOnly
                                  // value={moment(startDate).format("DD")}
                                  value={
                                    isyear
                                      ? "-"
                                      : moment(startDate).format("DD")
                                  }
                                  // onFocus={() => setSelectedDate(new Date())}
                                  onClick={handleClick}
                                />
                                <span style={{ padding: "0px", margin: "0px" }}>
                                  /
                                </span>
                                <input
                                  style={{
                                    width: "38px",
                                    padding: "5px 10px",
                                    marginBottom: "5px",
                                  }}
                                  type="text"
                                  readOnly
                                  placeholder="MM"
                                  maxDate={(new Date(), 0)}
                                  value={
                                    isyear
                                      ? "-"
                                      : moment(startDate).format("MM")
                                  }
                                  // onFocus={() => setSelectedDate(new Date())}
                                  onClick={handleClick}
                                />
                                <span style={{ padding: "0px", margin: "0px" }}>
                                  /
                                </span>
                                <input
                                  style={{
                                    width: "51px",
                                    padding: "5px 10px",
                                    marginBottom: "5px",
                                  }}
                                  type="text"
                                  placeholder="YYYY"
                                  readOnly
                                  value={moment(startDate).format("YYYY")}
                                  onClick={handleClick2}
                                  // onFocus={toggleYearPicker}
                                /> */}

                                            {/* <DatePicker
                                              selected={selectedDate}
                                              onChange={handleDateChange}
                                              placeholderText="DD/MM/YYYY"
                                              dateFormat="dd/MM/yyyy"
                                              maxDate={currentDate}
                                              showYearDropdown
                                              showMonthDropdown
                                              scrollableYearDropdown
                                              yearDropdownItemNumber={10}
                                              dropdownMode="select"
                                              isClearable
                                              minDate={new Date(1300, 0, 1)}
                                            /> */}

                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                gap: "4px",
                                              }}
                                            >
                                              <select
                                                style={{ padding: "4px" }}
                                                value={day}
                                                onChange={handleDayChange}
                                              >
                                                <option value="" disabled>
                                                  DD
                                                </option>
                                                {days.map((d) => (
                                                  <option
                                                    key={d}
                                                    value={d}
                                                    disabled={
                                                      year === currentYear &&
                                                      month === currentMonth &&
                                                      d > currentDate
                                                    }
                                                  >
                                                    {formatValue(d)}
                                                  </option>
                                                ))}
                                              </select>
                                              /
                                              <select
                                                style={{ padding: "4px" }}
                                                value={month}
                                                onChange={handleMonthChange}
                                              >
                                                <option value="" disabled>
                                                  MM
                                                </option>
                                                {Array.from(
                                                  { length: 12 },
                                                  (_, i) => i + 1
                                                ).map((m) => (
                                                  <option
                                                    key={m}
                                                    value={m}
                                                    disabled={
                                                      year === currentYear &&
                                                      m > currentMonth
                                                    }
                                                  >
                                                    {formatValue(m)}
                                                  </option>
                                                ))}
                                              </select>
                                              /
                                              <select
                                                style={{ padding: "4px" }}
                                                value={year}
                                                onChange={handleYearChange}
                                              >
                                                <option value="" disabled>
                                                  YYYY
                                                </option>
                                                {Array.from(
                                                  {
                                                    length:
                                                      currentYear - 1300 + 1,
                                                  },
                                                  (_, i) => currentYear - i
                                                ).map((y) => (
                                                  <option key={y} value={y}>
                                                    {y}
                                                  </option>
                                                ))}
                                              </select>
                                            </div>
                                          </div>

                                          {isOpen && (
                                            <DatePicker
                                              selected={startDate}
                                              onChange={handleChange}
                                              maxDate={new Date()}
                                              inline
                                              openToDate={new Date()}
                                            />
                                          )}

                                          {isOpen2 && (
                                            <DatePicker
                                              selected={startDate2}
                                              onChange={handleChange2}
                                              maxDate={new Date()}
                                              inline
                                              showYearPicker
                                            />
                                          )}
                                          {/* 
                              {selectedDate && !showYearPicker && (
                                <DatePicker
                                  selected={selectedDate}
                                  onChange={(date) => setSelectedDate(date)}
                                  dateFormat="dd/MM/yyyy"
                                />
                              )} */}
                                        </div>
                                      </>

                                      {/* date picker end */}
                                    </div>
                                    {/* date of creation end */}
                                  </div>

                                  {/* buttons start */}
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "row",
                                      pt: 2,
                                    }}
                                  >
                                    <Box sx={{ flex: "1 1 auto" }} />
                                    <button
                                      onClick={handleNext}
                                      style={{
                                        color: "var(--color-main)",
                                        border: "1px solid var(--color-main)",
                                        padding: "10px 14px",
                                        fontFamily: "var(--font-600)",
                                        fontSize: "12px",
                                        borderRadius: "8px",
                                        lineHeight: "12px",
                                      }}
                                    >
                                      NEXT
                                    </button>
                                    {activeStep !== steps.length &&
                                      (completed[activeStep] ? (
                                        <p
                                          // variant="caption"
                                          style={{
                                            display: "inline-block",
                                            color: "#fff",
                                            fontSize: "12px",
                                            fontFamily: "var(--font-400)",
                                            padding: "6px 12px",
                                          }}
                                        >
                                          Step {activeStep + 1} already
                                          completed
                                        </p>
                                      ) : (
                                        <button
                                          onClick={() => {
                                            ValidateStep1();
                                          }}
                                          style={{
                                            color: "var(--color-main)",
                                            // border: "1px solid var(--color-main)",
                                            padding: "10px 14px",
                                            fontFamily: "var(--font-600)",
                                            fontSize: "12px",
                                            borderRadius: "8px",
                                            backgroundColor: "#fff",
                                            lineHeight: "12px",
                                            marginLeft: "10px",
                                          }}
                                        >
                                          SUBMIT
                                        </button>
                                      ))}
                                  </Box>
                                  {/* buttons end */}
                                </>
                              )}

                              {activeStep === 1 && (
                                <>
                                  <div
                                    style={{
                                      width: "100%",
                                      padding: "10px 0px",
                                      // border: "1px solid red",
                                      margin: "10px auto",
                                    }}
                                  >
                                    {/* PRODUCT DESCRIPTION START  */}
                                    <label
                                      htmlFor="product_des"
                                      className="form_input_label mt-2"
                                    >
                                      Asset Description
                                      <span
                                        className="details_home_icon"
                                        onClick={() => {
                                          setShowModal1(true);
                                          setErrorMessage1(
                                            "Congratulations on choosing to create Aucemo for your asset! To maximize the potential of your Aucemo, its essential to provide a compelling and detailed description of your asset. This description serves as a powerful tool to engage potential bondholders and showcase the uniqueness of your offering.Tell the story: Share the story behind your asset. Let users know about its inception, the inspiration behind it, and any interesting anecdotes. Stories create an emotional connection and make your asset more relatable. Highlight USPs: Emphasize the unique selling points (USPs) that set your asset apart from others. What makes it special? Is it the design, craftsmanship, functionality, or innovation? Clearly communicate these standout features to attract bondholders. Showcase attractive qualities: Describe the key qualities that make your asset desirable. Is it eco-friendly, sustainable, or ethically produced? Does it have exceptional quality, durability, or versatility? Highlighting these qualities enhances the perceived value of your asset. Growth potential: Illuminate the growth potential of your asset. Explain how it can appreciate in value over time, making it an attractive investment opportunity for bondholders. Discuss factors such as limited edition releases, increasing demand, or potential collaborations that contribute to its growth potential. By crafting a compelling and informative asset description, you can captivate potential bondholders, generate excitement around your Aucemo, and increase the chances of successful Aucemo sales. Remember, the more detailed and engaging your description, the better the chances of creating a strong Aucemo with your audience. Wishing you great success in your Aucemo journey!"
                                          );
                                        }}
                                      >
                                        {String.fromCharCode(9432)}
                                      </span>
                                    </label>
                                    <textarea
                                      name="product_des"
                                      rows="6"
                                      className="form_input_box"
                                      value={getProductDes}
                                      onChange={(e) =>
                                        setProductDes(e.target.value)
                                      }
                                      disabled={gettab2Completed}
                                    ></textarea>
                                    {/* PRODUCT DESCRIPTION END  */}

                                    {/* PRODUCT STORY START  */}
                                    <label
                                      htmlFor="product_story"
                                      className="form_input_label mt-2"
                                    >
                                      Asset Story/Statement
                                      <span
                                        className="details_home_icon"
                                        onClick={() => {
                                          setShowModal1(true);
                                          setErrorMessage1(
                                            "Please provide us with the Story of your asset. This is an opportunity to showcase the unique features, benefits, and qualities that make your asset special. Think about what sets it apart from others in the market, its functionality, design, materials used, and any additional value it offers to potential buyers. Use this space to captivate their attention and highlight the reasons why they should consider investing in your asset through aucemo.club."
                                          );
                                        }}
                                      >
                                        {String.fromCharCode(9432)}
                                      </span>
                                    </label>
                                    <textarea
                                      name="product_story"
                                      rows="6"
                                      className="form_input_box"
                                      value={getProductStory}
                                      onChange={(e) =>
                                        setProductStory(e.target.value)
                                      }
                                      disabled={gettab2Completed}
                                    ></textarea>
                                    {/* PRODUCT STORY END  */}

                                    {/* PRODUCT HIGHLIGHT START  */}
                                    <label
                                      htmlFor="product_highlight"
                                      className="form_input_label mt-2"
                                    >
                                      Asset Highlights
                                      <span
                                        className="details_home_icon"
                                        onClick={() => {
                                          setShowModal1(true);
                                          setErrorMessage1(
                                            "Boost your Aucemo visibility! Use relevant and impactful Highlights when creating Aucemo of your asset. Help your masterpiece reach the right audience and connect with fellow enthusiasts. Choose Highlights that reflect the essence, style, and category of your creation. Let the power of Highlights amplify your Aucemo presence and make it stand out in the aucemo.club community."
                                          );
                                        }}
                                      >
                                        {String.fromCharCode(9432)}
                                      </span>
                                    </label>
                                    <textarea
                                      name="product_highlight"
                                      rows="6"
                                      className="form_input_box"
                                      value={getProductHighlight}
                                      onChange={(e) =>
                                        setProductHighlight(e.target.value)
                                      }
                                      disabled={gettab2Completed}
                                    ></textarea>
                                    {/* PRODUCT HIGHLIGHT END  */}

                                    {/* HASHTAGS START */}
                                    <label
                                      htmlFor="product_hastags"
                                      className="form_input_label mt-2"
                                    >
                                      Hashtags{" "}
                                      <span
                                        className="details_home_icon"
                                        onClick={() => {
                                          setShowModal1(true);
                                          setErrorMessage1(
                                            "Boost your Aucemo visibility! Use relevant and impactful hashtags when creating Aucemo of your asset. Help your masterpiece reach the right audience and connect with fellow enthusiasts. Choose hashtags that reflect the essence, style, and category of your creation. Let the power of hashtags amplify your Aucemo presence and make it stand out in the aucemo.club community."
                                          );
                                        }}
                                      >
                                        {" "}
                                        {String.fromCharCode(9432)}
                                      </span>
                                    </label>
                                    <textarea
                                      onChange={handleHashtagsChange}
                                      disabled={
                                        gettab2Completed ||
                                        gethashtags.trim().split(/\s+/)
                                          .length >= 10
                                      }
                                      value={gethashtags}
                                      name="product_hastags"
                                      rows="5"
                                      className="form_input_box"
                                      // disabled={gettab2Completed}
                                    ></textarea>
                                    {/* HASHTAGS END */}
                                  </div>

                                  {/* buttons start */}
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "row",
                                      pt: 2,
                                    }}
                                  >
                                    <button
                                      style={{
                                        color: "var(--color-main)",
                                        border: "1px solid var(--color-main)",
                                        padding: "10px 14px",
                                        fontFamily: "var(--font-600)",
                                        fontSize: "12px",
                                        borderRadius: "8px",
                                        lineHeight: "12px",
                                      }}
                                      color="inherit"
                                      // disabled={activeStep === 0}
                                      onClick={handleBack}
                                      sx={{ mr: 1 }}
                                    >
                                      Back
                                    </button>
                                    <Box sx={{ flex: "1 1 auto" }} />
                                    <button
                                      onClick={handleNext}
                                      style={{
                                        color: "var(--color-main)",
                                        border: "1px solid var(--color-main)",
                                        padding: "10px 14px",
                                        fontFamily: "var(--font-600)",
                                        fontSize: "12px",
                                        borderRadius: "8px",
                                        lineHeight: "12px",
                                      }}
                                    >
                                      NEXT
                                    </button>
                                    {activeStep !== steps.length &&
                                      (completed[activeStep] ? (
                                        <p
                                          // variant="caption"
                                          style={{
                                            display: "inline-block",
                                            color: "#fff",
                                            fontSize: "12px",
                                            fontFamily: "var(--font-400)",
                                            padding: "6px 12px",
                                          }}
                                        >
                                          Step {activeStep + 1} already
                                          completed
                                        </p>
                                      ) : (
                                        <button
                                          onClick={() => {
                                            ValidateStep2();
                                          }}
                                          style={{
                                            color: "var(--color-main)",
                                            // border: "1px solid var(--color-main)",
                                            padding: "10px 14px",
                                            fontFamily: "var(--font-600)",
                                            fontSize: "12px",
                                            borderRadius: "8px",
                                            backgroundColor: "#fff",
                                            lineHeight: "12px",
                                            marginLeft: "10px",
                                          }}
                                        >
                                          SUBMIT
                                        </button>
                                      ))}
                                  </Box>
                                  {/* buttons end */}
                                </>
                              )}

                              {activeStep === 2 && (
                                <>
                                  <div
                                    style={{
                                      width: "100%",
                                      padding: "10px 0px",
                                      // border: "1px solid red",
                                      margin: "10px auto",
                                    }}
                                  >
                                    {/* UPLOAD AUTHNATICATION FILE START */}
                                    <label
                                      htmlFor="auth_file"
                                      className="form_input_label mt-2"
                                    >
                                      Upload authentication file
                                      <span
                                        className="details_home_icon"
                                        onClick={() => {
                                          setShowModal1(true);
                                          setErrorMessage1(
                                            "Verify the authenticity of your asset by uploading the authentication certificate file. Ensure it is a valid document that confirms the originality of your Aucemo. Protect your investment and contribute to a trustworthy community."
                                          );
                                        }}
                                      >
                                        {String.fromCharCode(9432)}
                                      </span>
                                    </label>
                                    <div className="image-upload">
                                      <label
                                        htmlFor="file-input"
                                        className="upload-button"
                                      >
                                        <img src={images.gallery_icon} alt="" />
                                      </label>
                                      <input
                                        id="file-input"
                                        type="file"
                                        // accept="image/*"
                                        accept="image/*,.pdf"
                                        // capture="camera"
                                        onChange={handleImageChange}
                                        disabled={gettab3Completed}
                                      />
                                      <div className="image-previews">
                                        {selectedImages.map((image, index) => {
                                          console.log("img", image.type);
                                          return (
                                            <>
                                              {image.type.startsWith(
                                                "image/"
                                              ) ? (
                                                <div
                                                  className="image-preview"
                                                  key={index}
                                                >
                                                  <img
                                                    src={URL.createObjectURL(
                                                      image
                                                    )}
                                                    alt={`Preview ${index}`}
                                                  />
                                                  <button
                                                    className="delete-button"
                                                    disabled={gettab3Completed}
                                                    onClick={() =>
                                                      handleImageDelete(index)
                                                    }
                                                  >
                                                    <img
                                                      src={images.close_red}
                                                      alt=""
                                                    />
                                                  </button>
                                                </div>
                                              ) : (
                                                <div
                                                  className="image-preview"
                                                  key={index}
                                                >
                                                  <img
                                                    src={images.pdf_icon}
                                                    alt={`Preview ${index}`}
                                                  />
                                                  <button
                                                    className="delete-button"
                                                    disabled={gettab3Completed}
                                                    onClick={() =>
                                                      handleImageDelete(index)
                                                    }
                                                  >
                                                    <img
                                                      src={images.close_red}
                                                      alt=""
                                                    />
                                                  </button>
                                                </div>
                                              )}
                                            </>
                                          );
                                        })}
                                      </div>
                                    </div>
                                    {/* UPLOAD AUTHNATICATION FILE END */}
                                    {/* UPLOAD MINIMUM 1 IMAGE START */}
                                    <label
                                      htmlFor="product_name"
                                      className="form_input_label mt-2"
                                    >
                                      Upload minimum 1 image
                                      <span
                                        className="details_home_icon"
                                        onClick={() => {
                                          setShowModal1(true);
                                          setErrorMessage1(
                                            "To ensure that your Aucemo stands out and captivates potential buyers, we kindly request you to upload 6 high-quality images of your asset. Here are some tips to make the most of your asset images: \n1. Showcasing the Asset: Capture clear, well-lit images that highlight the unique features, craftsmanship, and details of your asset. Showcase its beauty and functionality to entice potential buyers. \n2. Authenticity and Originality: Make sure the images accurately represent your asset. Avoid using stock photos or images that may mislead buyers. Authenticity is key to building trust with our community. \n3. Intriguing Perspectives: Experiment with different angles, perspectives, and compositions to create visual interest. Capture shots that evoke curiosity and make viewers want to explore your asset further. \n4. High Resolution: Upload images with high resolution to ensure clarity and sharpness. This allows potential buyers to zoom in and examine the intricate details of your asset. \n5. Contextualizing the Asset: If appropriate, consider including images that showcase your asset in real-life settings or demonstrate its usage. This helps potential buyers visualize how your asset fits into their lives. \n6. Multiple Views: Utilize the opportunity to upload multiple images to showcase various aspects of your asset. Include different angles, close-ups, and alternative views to provide a comprehensive understanding. By uploading compelling and visually appealing images, you enhance the overall appeal and desirability of your Aucemo. Remember, first impressions matter, and stunning visuals can significantly impact the success of your Aucemo in attracting potential buyers. Thank you for your attention to detail, and we look forward to seeing your captivating asset images!"
                                          );
                                        }}
                                      >
                                        {String.fromCharCode(9432)}
                                      </span>
                                    </label>
                                    <div className="image-upload">
                                      <>
                                        <label
                                          htmlFor="min_1_img"
                                          className="upload-button"
                                        >
                                          <img
                                            src={images.gallery_icon}
                                            alt=""
                                          />
                                        </label>
                                        <input
                                          id="min_1_img"
                                          type="file"
                                          accept="image/*"
                                          disabled={gettab3Completed}
                                          // capture="camera"
                                          multiple
                                          onChange={handleImageChangeMin1Img}
                                        />
                                      </>

                                      <div className="image-previews">
                                        {selectedImagesMin1Img.map(
                                          (image, index) => (
                                            <div
                                              className="image-preview"
                                              key={index}
                                            >
                                              <img
                                                src={URL.createObjectURL(image)}
                                                alt={`Preview ${index}`}
                                              />

                                              <button
                                                className="delete-button"
                                                disabled={gettab3Completed}
                                                onClick={() =>
                                                  handleImageDelete2(index)
                                                }
                                              >
                                                <img
                                                  src={images.close_red}
                                                  alt=""
                                                />
                                              </button>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </div>
                                    {/* UPLOAD MINIMUM 1 IMAGE END */}

                                    {/* UPLOAD IMAGE/VIDEO START */}
                                    <label
                                      htmlFor="upload_media"
                                      className="form_input_label mt-2"
                                    >
                                      Upload media images
                                      <span
                                        className="details_home_icon"
                                        onClick={() => {
                                          setShowModal1(true);
                                          setErrorMessage1(
                                            "To ensure that your Aucemo stands out and captivates potential buyers, we kindly request you to upload 6 high-quality images of your asset. Here are some tips to make the most of your asset images: \n1. Showcasing the Asset: Capture clear, well-lit images that highlight the unique features, craftsmanship, and details of your asset. Showcase its beauty and functionality to entice potential buyers. \n2. Authenticity and Originality: Make sure the images accurately represent your asset. Avoid using stock photos or images that may mislead buyers. Authenticity is key to building trust with our community. \n3. Intriguing Perspectives: Experiment with different angles, perspectives, and compositions to create visual interest. Capture shots that evoke curiosity and make viewers want to explore your asset further. \n4. High Resolution: Upload images with high resolution to ensure clarity and sharpness. This allows potential buyers to zoom in and examine the intricate details of your asset. \n5. Contextualizing the Asset: If appropriate, consider including images that showcase your asset in real-life settings or demonstrate its usage. This helps potential buyers visualize how your asset fits into their lives. \n6. Multiple Views: Utilize the opportunity to upload multiple images to showcase various aspects of your asset. Include different angles, close-ups, and alternative views to provide a comprehensive understanding. By uploading compelling and visually appealing images, you enhance the overall appeal and desirability of your Aucemo. Remember, first impressions matter, and stunning visuals can significantly impact the success of your Aucemo in attracting potential buyers. Thank you for your attention to detail, and we look forward to seeing your captivating asset images!"
                                          );
                                        }}
                                      >
                                        {String.fromCharCode(9432)}
                                      </span>
                                    </label>

                                    <div className="image-upload">
                                      <label
                                        htmlFor="media_img_vid"
                                        className="upload-button"
                                      >
                                        <img src={images.gallery_icon} alt="" />
                                      </label>
                                      <input
                                        id="media_img_vid"
                                        type="file"
                                        accept="image/*"
                                        multiple
                                        onChange={handleImageAndVideoChange}
                                        disabled={gettab3Completed}
                                      />
                                      <div className="image-previews">
                                        {selectedImagesAndVidio.map(
                                          (image, index) => (
                                            <div
                                              className="image-preview"
                                              key={index}
                                            >
                                              <img
                                                src={URL.createObjectURL(image)}
                                                alt={`Preview ${index}`}
                                              />
                                              <button
                                                className="delete-button"
                                                disabled={gettab3Completed}
                                                onClick={() =>
                                                  handleImageAndVideoDelete(
                                                    index
                                                  )
                                                }
                                              >
                                                <img
                                                  src={images.close_red}
                                                  alt=""
                                                />
                                              </button>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </div>
                                    {/* UPLOAD IMAGE/VIDEO END */}
                                  </div>

                                  {/* buttons start */}
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "row",
                                      pt: 2,
                                    }}
                                  >
                                    <button
                                      style={{
                                        color: "var(--color-main)",
                                        border: "1px solid var(--color-main)",
                                        padding: "10px 14px",
                                        fontFamily: "var(--font-600)",
                                        fontSize: "12px",
                                        borderRadius: "8px",
                                        lineHeight: "12px",
                                      }}
                                      color="inherit"
                                      // disabled={activeStep === 0}
                                      onClick={handleBack}
                                      sx={{ mr: 1 }}
                                    >
                                      Back
                                    </button>
                                    <Box sx={{ flex: "1 1 auto" }} />
                                    <button
                                      onClick={handleNext}
                                      style={{
                                        color: "var(--color-main)",
                                        border: "1px solid var(--color-main)",
                                        padding: "10px 14px",
                                        fontFamily: "var(--font-600)",
                                        fontSize: "12px",
                                        borderRadius: "8px",
                                        lineHeight: "12px",
                                      }}
                                    >
                                      NEXT
                                    </button>
                                    {activeStep !== steps.length &&
                                      (completed[activeStep] ? (
                                        <p
                                          // variant="caption"
                                          style={{
                                            display: "inline-block",
                                            color: "#fff",
                                            fontSize: "12px",
                                            fontFamily: "var(--font-400)",
                                            padding: "6px 12px",
                                          }}
                                        >
                                          Step {activeStep + 1} already
                                          completed
                                        </p>
                                      ) : (
                                        <button
                                          onClick={() => {
                                            ValidateStep3();
                                          }}
                                          style={{
                                            color: "var(--color-main)",
                                            // border: "1px solid var(--color-main)",
                                            padding: "10px 14px",
                                            fontFamily: "var(--font-600)",
                                            fontSize: "12px",
                                            borderRadius: "8px",
                                            backgroundColor: "#fff",
                                            lineHeight: "12px",
                                            marginLeft: "10px",
                                          }}
                                        >
                                          SUBMIT
                                        </button>
                                      ))}
                                  </Box>
                                  {/* buttons end */}
                                </>
                              )}

                              {activeStep === 3 && (
                                <>
                                  <div
                                    style={{
                                      width: "100%",
                                      padding: "10px 0px",
                                      // border: "1px solid red",
                                      margin: "10px auto",
                                    }}
                                  >
                                    {/* Attach video section start */}
                                    <label
                                      htmlFor="creator_video"
                                      className="form_input_label"
                                    >
                                      Attach Creator Video
                                      {/* <span
                                      className="details_home_icon"
                                      onClick={() => {
                                        setShowModal1(true);
                                        setErrorMessage1(
                                          "Bring your Aucemo to life! Record a captivating video showcasing your asset in all its glory. Show or speak about its unique features, craftsmanship, or the story behind its creation. Remember to keep it between 20 seconds to 1 minute for the best impact."
                                        );
                                      }}
                                    >
                                      {" "}
                                      {String.fromCharCode(9432)}
                                    </span> */}
                                    </label>
                                    <div style={{ width: "100%" }}>
                                      <button
                                        onClick={() => {
                                          setAttechVideoModal(true);
                                        }}
                                        className="attach_video_btn_cp"
                                        style={{
                                          marginBottom: "14px",
                                          marginTop: "15px",
                                        }}
                                        disabled={gettab4Completed}
                                      >
                                        Attach Video
                                      </button>
                                    </div>
                                    {/* Attach video section end */}

                                    {/* CREATOR VIDEO START */}
                                    <label
                                      htmlFor="creator_video"
                                      className="form_input_label"
                                    >
                                      Record Asset Video{" "}
                                      <span
                                        className="details_home_icon"
                                        onClick={() => {
                                          setShowModal1(true);
                                          setErrorMessage1(
                                            "Bring your Aucemo to life! Record a captivating video showcasing your asset in all its glory. Show or speak about its unique features, craftsmanship, or the story behind its creation. Remember to keep it between 20 seconds to 1 minute for the best impact."
                                          );
                                        }}
                                      >
                                        {" "}
                                        {String.fromCharCode(9432)}
                                      </span>
                                    </label>
                                    {/* <button onClick={handleVideoChange}>
                            Start Recording
                          </button>
                          <button onClick={stopRecording}>Stop Recording</button>
                          <video ref={videoRef} autoPlay muted /> */}
                                    <div className="image-upload">
                                      {selectedVideos &&
                                      selectedVideos.length <= 0 ? (
                                        <>
                                          <label
                                            onClick={() => {
                                              setIsModalVisiblevideo(true);
                                              setRecordedChunks([]);
                                              setrecordedvideo(false);
                                            }}
                                            disabled={gettab4Completed}
                                            htmlFor="creator_vid"
                                            className="upload-button"
                                          >
                                            <img
                                              src={images.gallery_icon}
                                              alt=""
                                            />
                                          </label>
                                          <input
                                            disabled
                                            id="creator_vid"
                                            type="file"
                                            accept="video/*"
                                            // multiple
                                            capture="camera"
                                            onChange={(e) => {
                                              handleVideoChange(e);
                                              // setIsModalVisiblevideo(true);
                                            }}
                                          />
                                          {/* <button onClick={handleVideoChange}>
                                Start Recording
                              </button>
                              <button onClick={stopRecording}>
                                Stop Recording
                              </button>
                              <video ref={videoRef} autoPlay muted /> */}
                                        </>
                                      ) : (
                                        <div className="image-previews">
                                          {selectedVideos.map(
                                            (image, index) => (
                                              <div
                                                className="image-preview"
                                                key={index}
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "column",
                                                  alignItems: "center",
                                                  justifyContent: "center",
                                                  border: "1px dashed gray",
                                                  borderRadius: "8px",
                                                }}
                                              >
                                                {/* <img
                                  src={URL.createObjectURL(image)}
                                  alt={`Preview ${index}`}
                                /> */}
                                                <button
                                                  onClick={() => {
                                                    setPreviewVideoModal2(true);
                                                    setUserVideo2(
                                                      URL.createObjectURL(image)
                                                    );
                                                  }}
                                                  // disabled={gettab3Completed}
                                                  style={{
                                                    color: "var(--color-main)",
                                                    fontSize: "12px",
                                                    fontFamily:
                                                      "var(--font-600)",
                                                  }}
                                                >
                                                  Preview
                                                </button>
                                                <button
                                                  className="delete-button"
                                                  onClick={() =>
                                                    handleVideoDelete(index)
                                                  }
                                                  disabled={gettab4Completed}
                                                >
                                                  <img
                                                    src={images.close_red}
                                                    alt=""
                                                  />
                                                </button>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      )}
                                    </div>
                                    {/* CREATOR VIDEO END */}
                                  </div>
                                  {/* buttons start */}
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "row",
                                      pt: 2,
                                    }}
                                  >
                                    <button
                                      style={{
                                        color: "var(--color-main)",
                                        border: "1px solid var(--color-main)",
                                        padding: "10px 14px",
                                        fontFamily: "var(--font-600)",
                                        fontSize: "12px",
                                        borderRadius: "8px",
                                        lineHeight: "12px",
                                      }}
                                      color="inherit"
                                      // disabled={activeStep === 0}
                                      onClick={handleBack}
                                      sx={{ mr: 1 }}
                                    >
                                      Back
                                    </button>
                                    <Box sx={{ flex: "1 1 auto" }} />
                                    <button
                                      onClick={handleNext}
                                      style={{
                                        color: "var(--color-main)",
                                        border: "1px solid var(--color-main)",
                                        padding: "10px 14px",
                                        fontFamily: "var(--font-600)",
                                        fontSize: "12px",
                                        borderRadius: "8px",
                                        lineHeight: "12px",
                                      }}
                                    >
                                      NEXT
                                    </button>
                                    {activeStep !== steps.length &&
                                      (completed[activeStep] ? (
                                        <p
                                          // variant="caption"
                                          style={{
                                            display: "inline-block",
                                            color: "#fff",
                                            fontSize: "12px",
                                            fontFamily: "var(--font-400)",
                                            padding: "6px 12px",
                                          }}
                                        >
                                          Step {activeStep + 1} already
                                          completed
                                        </p>
                                      ) : (
                                        <button
                                          onClick={() => {
                                            ValidateStep4();
                                          }}
                                          style={{
                                            color: "var(--color-main)",
                                            // border: "1px solid var(--color-main)",
                                            padding: "10px 14px",
                                            fontFamily: "var(--font-600)",
                                            fontSize: "12px",
                                            borderRadius: "8px",
                                            backgroundColor: "#fff",
                                            lineHeight: "12px",
                                            marginLeft: "10px",
                                          }}
                                        >
                                          SUBMIT
                                        </button>
                                      ))}
                                  </Box>
                                  {/* buttons end */}
                                </>
                              )}

                              {activeStep === 4 && (
                                <>
                                  <div
                                    style={{
                                      width: "100%",
                                      padding: "10px 0px",
                                      // border: "1px solid red",
                                      margin: "10px auto",
                                    }}
                                  >
                                    {/* PRODUCT VALUE START */}
                                    {selectedAuction == 0 && (
                                      <div className="glob_col_wrapp">
                                        <div className="glob_row_wrapp">
                                          <label
                                            htmlFor="product_value"
                                            className="form_input_label"
                                          >
                                            Enter Asset value{" "}
                                            <span
                                              className="details_home_icon"
                                              onClick={() => {
                                                setShowModal1(true);
                                                setErrorMessage1(
                                                  "Assign a value to your Aucemo that reflects its worth and desirability. Consider factors such as rarity, artistic merit, and demand when determining the value. A fair and accurate valuation will attract collectors who appreciate its true value."
                                                );
                                              }}
                                            >
                                              {" "}
                                              {String.fromCharCode(9432)}
                                            </span>
                                          </label>

                                          <input
                                            type="number"
                                            name="product_value"
                                            className="product_val_input"
                                            placeholder=""
                                            onKeyPress={handleKeyPress}
                                            value={getProductValue}
                                            // disabled={gettab5Completed}
                                            // onChange={(e) => {
                                            //   setProductValue(e.target.value);
                                            // }}
                                            onChange={(e) => {
                                              // Ensure that the entered value is a positive integer
                                              const inputValue = e.target.value;
                                              const isValid = /^\d+$/.test(
                                                inputValue
                                              ); // Checks if the value is a positive integer

                                              if (inputValue <= 0) {
                                                setProductValue("");
                                                setErrorMessage1(
                                                  "Please enter valid asset value"
                                                );
                                                setShowModal1(true);
                                              } else {
                                                setProductValue(inputValue);
                                                console.log(
                                                  "isValid",
                                                  inputValue
                                                );
                                              }
                                            }}
                                          />
                                        </div>
                                      </div>
                                    )}
                                    {/* PRODUCT VALUE END */}

                                    {/* links start */}

                                    <label
                                      htmlFor="product_name"
                                      className="form_input_label mt-2"
                                    >
                                      Media Links
                                    </label>

                                    <>
                                      {inputValues.map((value, index) => (
                                        <div
                                          key={index}
                                          className="cp_medialink_signle_wrapp"
                                        >
                                          <input
                                            style={{
                                              width: "80%",
                                              marginBottom: "0px",
                                            }}
                                            className="form_input_box"
                                            placeholder="Enter a Link"
                                            placeholderTextColor="gray"
                                            value={value}
                                            onChange={(e) =>
                                              handleInputChange(
                                                e.target.value,
                                                index
                                              )
                                            }
                                            disabled={gettab5Completed}
                                          />
                                          {index === inputValues.length - 1 ? (
                                            <div className="cp_ml_btns_wrapp">
                                              <button
                                                className="cp_ml_plus_btn"
                                                onClick={handleAddValue}
                                                disabled={gettab5Completed}
                                              >
                                                +
                                              </button>
                                              {inputValues.length > 1 && (
                                                <button
                                                  className="cp_ml_mius_btn"
                                                  onClick={handleRemoveValue}
                                                  disabled={gettab5Completed}
                                                >
                                                  -
                                                </button>
                                              )}
                                            </div>
                                          ) : null}
                                        </div>
                                      ))}
                                    </>

                                    {/* links end */}
                                  </div>
                                  {/* buttons start */}
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "row",
                                      pt: 2,
                                    }}
                                  >
                                    <button
                                      style={{
                                        color: "var(--color-main)",
                                        border: "1px solid var(--color-main)",
                                        padding: "10px 14px",
                                        fontFamily: "var(--font-600)",
                                        fontSize: "12px",
                                        borderRadius: "8px",
                                        lineHeight: "12px",
                                      }}
                                      color="inherit"
                                      // disabled={activeStep === 0}
                                      onClick={handleBack}
                                      sx={{ mr: 1 }}
                                    >
                                      Back
                                    </button>
                                    <Box sx={{ flex: "1 1 auto" }} />
                                    <button
                                      onClick={handleNext}
                                      style={{
                                        color: "var(--color-main)",
                                        border: "1px solid var(--color-main)",
                                        padding: "10px 14px",
                                        fontFamily: "var(--font-600)",
                                        fontSize: "12px",
                                        borderRadius: "8px",
                                        lineHeight: "12px",
                                      }}
                                    >
                                      NEXT
                                    </button>
                                    {activeStep !== steps.length &&
                                      (completed[activeStep] ? (
                                        <p
                                          // variant="caption"
                                          style={{
                                            display: "inline-block",
                                            color: "#fff",
                                            fontSize: "12px",
                                            fontFamily: "var(--font-400)",
                                            padding: "6px 12px",
                                          }}
                                        >
                                          Step {activeStep + 1} already
                                          completed
                                        </p>
                                      ) : (
                                        <button
                                          onClick={() => {
                                            ValidateStep5();
                                          }}
                                          style={{
                                            color: "var(--color-main)",
                                            // border: "1px solid var(--color-main)",
                                            padding: "10px 14px",
                                            fontFamily: "var(--font-600)",
                                            fontSize: "12px",
                                            borderRadius: "8px",
                                            backgroundColor: "#fff",
                                            lineHeight: "12px",
                                            marginLeft: "10px",
                                          }}
                                        >
                                          SUBMIT
                                        </button>
                                      ))}
                                  </Box>
                                  {/* buttons end */}
                                </>
                              )}

                              {activeStep === 5 && (
                                <>
                                  <div
                                    style={{
                                      width: "100%",
                                      padding: "10px 0px",
                                      // border: "1px solid red",
                                      margin: "10px auto",
                                    }}
                                  >
                                    {/* GLOBAL EXCLUSIVE START */}
                                    <div className="glob_col_wrapp">
                                      <div className="glob_row_wrapp">
                                        <label
                                          htmlFor="glob_exclusive"
                                          className="form_input_label"
                                        >
                                          Global Exclusive{" "}
                                          <span
                                            className="details_home_icon"
                                            onClick={() => {
                                              setShowModal1(true);
                                              setErrorMessage1(
                                                `Exclusive Commitment:${"\n"}Dear Asset Uploader, Thank you for choosing aucemo.club as your platform to create digital assets of your asset exclusively. We are excited to collaborate with you on this journey. Here are the terms for your exclusive commitment: Platform Fee: aucemo.club will take only 10 Aucemo of this particular asset as the platform fee. This ensures that you retain a significant portion of your Aucemo for potential sales. Royalty for Life: As a token of appreciation for your exclusive commitment, you will receive a 10% royalty for life from the sales of the Aucemo. This means that for every Aucemo sold, you will earn a share of the revenue, providing you with ongoing passive income. We value your trust in aucemo.club, and we are committed to helping you maximize the potential of your asset through our platform. Let's work together to create a thriving marketplace for your Aucemo! Best regards, \n\nNon-Exclusive Commitment:\n\nDear Asset Uploader, Thank you for considering aucemo.club as a platform to create digital assets of your asset. We appreciate your interest. Here are the terms for your non-exclusive commitment: Platform Fee: aucemo.club will charge 20 Aucemo of the asset as the platform fee. This fee allows us to provide you with a robust platform and comprehensive support. Royalty for Life: As a gesture of our partnership, you will receive a 5% royalty for life from the sales of the Aucemo. This means that for every Aucemo sold, you will earn a share of the revenue, ensuring a continuous stream of passive income. We believe in fostering a mutually beneficial relationship and are excited to have your asset on our platform. Let's collaborate to make your Aucemo a success!`
                                              );
                                            }}
                                          >
                                            {" "}
                                            {String.fromCharCode(9432)}
                                          </span>
                                        </label>
                                        <div id="custom_radio">
                                          <label class="custom_radio">
                                            <input
                                              type="radio"
                                              name="glob_exclusive"
                                              value={1}
                                              checked={selectedGlob == 1}
                                              onChange={handleGlobOptionChange}
                                            />
                                            <span class="checkmark"></span>
                                            Yes
                                          </label>
                                          <label class="custom_radio">
                                            <input
                                              type="radio"
                                              name="glob_exclusive"
                                              value={0}
                                              checked={selectedGlob == 0}
                                              onChange={handleGlobOptionChange}
                                            />
                                            <span class="checkmark"></span>
                                            No
                                          </label>
                                        </div>
                                      </div>
                                      {/* <p className="red_err_text">
                            Read it properly before choosing the correct option.
                          </p> */}
                                    </div>
                                    {/* GLOBAL EXCLUSIVE END */}

                                    {/* AUCTION BTN START */}
                                    <div className="glob_col_wrapp">
                                      <div className="glob_row_wrapp">
                                        <label
                                          htmlFor="auction"
                                          className="form_input_label"
                                        >
                                          Start auction to set base value?
                                        </label>
                                        <div id="custom_radio">
                                          <label class="custom_radio">
                                            <input
                                              type="radio"
                                              name="auction"
                                              value={1}
                                              checked={selectedAuction == 1}
                                              onChange={
                                                handleAuctionOptionChange
                                              }
                                            />
                                            <span class="checkmark"></span>
                                            Yes
                                          </label>
                                          <label class="custom_radio">
                                            <input
                                              type="radio"
                                              name="auction"
                                              value={0}
                                              checked={selectedAuction == 0}
                                              onChange={
                                                handleAuctionOptionChange
                                              }
                                            />
                                            <span class="checkmark"></span>
                                            No
                                          </label>
                                        </div>
                                      </div>
                                      {/* <p className="red_err_text">
                            Read it properly before choosing the correct option.
                          </p> */}

                                      {selectedAuction == 1 && (
                                        <>
                                          {/* minimum bid start */}
                                          <div
                                            className="glob_col_wrapp"
                                            style={{ marginBottom: "-10px" }}
                                          >
                                            <div className="glob_row_wrapp">
                                              <label
                                                htmlFor="product_value"
                                                className="form_input_label"
                                              >
                                                Enter minimum bid
                                              </label>

                                              <input
                                                type="number"
                                                name="product_value"
                                                className="product_val_input"
                                                placeholder=""
                                                onKeyPress={handleKeyPress}
                                                value={getminimumBid}
                                                onChange={(e) => {
                                                  // setMinimumBid(e.target.value);
                                                  const inputValue =
                                                    e.target.value;
                                                  if (inputValue < 0) {
                                                    setMinimumBid("");
                                                    setErrorMessage1(
                                                      "Please enter valid minimum bid value"
                                                    );
                                                    setShowModal1(true);
                                                  } else {
                                                    setMinimumBid(inputValue);
                                                    console.log(
                                                      "isValid",
                                                      inputValue
                                                    );
                                                  }
                                                }}
                                              />
                                            </div>
                                          </div>
                                          {/* minimum bid end */}
                                          {/* auction duration start */}
                                          <div
                                            className="glob_col_wrapp"
                                            style={{ marginBottom: "-10px" }}
                                          >
                                            <div className="glob_row_wrapp">
                                              <label
                                                htmlFor="product_value"
                                                className="form_input_label"
                                              >
                                                Auction Duration
                                              </label>

                                              <input
                                                type="number"
                                                onKeyPress={handleKeyPress}
                                                name="product_value"
                                                className="product_val_input"
                                                placeholder=""
                                                value={getauctionDurtion}
                                                onChange={(e) => {
                                                  // setAuctoinDuration(e.target.value);
                                                  const inputValue =
                                                    e.target.value;
                                                  const isValid =
                                                    /^\d+$/.test(inputValue) &&
                                                    parseInt(inputValue, 10) >=
                                                      0;

                                                  if (!isValid) {
                                                    setAuctoinDuration("");
                                                    setErrorMessage1(
                                                      "Please enter a valid non-negative and decimal Auction Duration value"
                                                    );
                                                    setShowModal1(true);
                                                  } else {
                                                    setAuctoinDuration(
                                                      parseInt(inputValue, 10)
                                                    );
                                                    console.log(
                                                      "isValid",
                                                      inputValue
                                                    );
                                                  }
                                                }}
                                              />
                                            </div>
                                          </div>
                                          {/* auction duration end */}
                                        </>
                                      )}
                                    </div>
                                    {/* AUCTION BTN END */}

                                    {/* SHARE Aucemo BTN START */}
                                    {selectedAuction == 1 ? null : (
                                      <>
                                        <button
                                          className="share_nfb_btn"
                                          onClick={() => setSharenfbModal(true)}
                                        >
                                          Gift Aucemo to other users
                                        </button>
                                        {/* <p className="red_err_text">
                              Read it properly before choosing the correct
                              option.
                            </p> */}
                                        {/* SHARE Aucemo BTN END */}
                                        <div
                                          style={{
                                            width: "100%",
                                            margin: "10px auto",
                                          }}
                                        >
                                          {/* share lists start */}
                                          <div>
                                            {getnumber_of_bonds_created_for_share &&
                                            getnumber_of_bonds_created_for_share.length >
                                              0
                                              ? getnumber_of_bonds_created_for_share.map(
                                                  (item, index) => {
                                                    return (
                                                      <div
                                                        style={{
                                                          width: "100%",
                                                          display: "flex",
                                                          alignItems: "center",
                                                          justifyContent:
                                                            "space-between",
                                                        }}
                                                      >
                                                        <p
                                                          style={{
                                                            color:
                                                              "var(--color-white)",
                                                            fontSize: "12px",
                                                          }}
                                                        >
                                                          {
                                                            getnumber_of_bonds_created_for_share_name[
                                                              index
                                                            ]
                                                          }{" "}
                                                          - {item}
                                                        </p>
                                                        <button
                                                          onClick={() => {
                                                            handleShareDelete(
                                                              item,
                                                              index
                                                            );
                                                          }}
                                                        >
                                                          <img
                                                            src={
                                                              images.close_red
                                                            }
                                                            style={{
                                                              width: "24px",
                                                              height: "24px",
                                                              objectFit:
                                                                "contain",
                                                            }}
                                                            alt=""
                                                          />
                                                        </button>
                                                      </div>
                                                    );
                                                  }
                                                )
                                              : null}
                                          </div>
                                          {/* share lists end */}

                                          {/* club share lists start */}

                                          <div>
                                            {getclub_number_of_bonds_created_for_share &&
                                            getclub_number_of_bonds_created_for_share.length >
                                              0
                                              ? getclub_number_of_bonds_created_for_share.map(
                                                  (item, index) => {
                                                    return (
                                                      <div
                                                        style={{
                                                          width: "100%",
                                                          display: "flex",
                                                          alignItems: "center",
                                                          justifyContent:
                                                            "space-between",
                                                        }}
                                                      >
                                                        <p
                                                          style={{
                                                            color:
                                                              "var(--color-white)",
                                                            fontSize: "12px",
                                                          }}
                                                        >
                                                          {
                                                            getclub_number_of_bonds_created_for_share_name[
                                                              index
                                                            ]
                                                          }{" "}
                                                          - {item}
                                                        </p>
                                                        <button
                                                          onClick={() => {
                                                            handleClubShareDelete(
                                                              item,
                                                              index
                                                            );
                                                          }}
                                                        >
                                                          <img
                                                            src={
                                                              images.close_red
                                                            }
                                                            style={{
                                                              width: "24px",
                                                              height: "24px",
                                                              objectFit:
                                                                "contain",
                                                            }}
                                                            alt=""
                                                          />
                                                        </button>
                                                      </div>
                                                    );
                                                  }
                                                )
                                              : null}
                                          </div>

                                          {/* club share lists end */}
                                        </div>
                                      </>
                                    )}
                                    {/* SUBMIT BUTTON START */}

                                    {/* donate qrhunt start */}

                                    {/* {selectedAuction == 1 ? null : (
                          <>
                            <label
                              htmlFor="product_hastags"
                              className="form_input_label"
                            >
                              Donate for qr hunt{" "}
                              <span
                                className="details_home_icon"
                                onClick={() => {
                                  setShowModal1(true);
                                  setErrorMessage1("");
                                }}
                              >
                                {" "}
                                {String.fromCharCode(9432)}
                              </span>
                            </label>

                            <div
                              // key={index}
                              className="cp_medialink_signle_wrapp"
                            >
                              <input
                                style={{
                                  width: "100%",
                                  marginBottom: "10px",
                                }}
                                className="form_input_box"
                                placeholder="Donate"
                                placeholderTextColor="gray"
                                value={getdonetQrhunt}
                                onChange={(e) =>
                                  handleQrDonateInputChange(e.target.value)
                                }
                              />
                            </div>
                          </>
                        )} */}

                                    {/* donate qrhunt end */}
                                  </div>
                                  {/* buttons start */}
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "row",
                                      pt: 2,
                                    }}
                                  >
                                    <button
                                      style={{
                                        color: "var(--color-main)",
                                        border: "1px solid var(--color-main)",
                                        padding: "10px 14px",
                                        fontFamily: "var(--font-600)",
                                        fontSize: "12px",
                                        borderRadius: "8px",
                                        lineHeight: "12px",
                                      }}
                                      color="inherit"
                                      // disabled={activeStep === 0}
                                      onClick={handleBack}
                                      sx={{ mr: 1 }}
                                    >
                                      Back
                                    </button>
                                    <Box sx={{ flex: "1 1 auto" }} />
                                    {/* <button
                                      onClick={handleNext}
                                      style={{
                                        color: "var(--color-main)",
                                        border: "1px solid var(--color-main)",
                                        padding: "10px 14px",
                                        fontFamily: "var(--font-600)",
                                        fontSize: "12px",
                                        borderRadius: "8px",
                                        lineHeight: "12px",
                                      }}
                                    >
                                      NEXT
                                    </button> */}
                                    {activeStep !== steps.length &&
                                      (completed[activeStep] ? (
                                        <p
                                          // variant="caption"
                                          style={{
                                            display: "inline-block",
                                            color: "#fff",
                                            fontSize: "12px",
                                            fontFamily: "var(--font-400)",
                                            paddingLeft: "10px",
                                          }}
                                        >
                                          Step {activeStep + 1} already
                                          completed
                                        </p>
                                      ) : (
                                        <button
                                          onClick={() => {
                                            ValidateStep6();
                                          }}
                                          style={{
                                            color: "var(--color-main)",
                                            // border: "1px solid var(--color-main)",
                                            padding: "10px 14px",
                                            fontFamily: "var(--font-600)",
                                            fontSize: "12px",
                                            borderRadius: "8px",
                                            backgroundColor: "#fff",
                                            lineHeight: "12px",
                                            marginLeft: "10px",
                                          }}
                                        >
                                          SUBMIT
                                        </button>
                                      ))}
                                  </Box>
                                  {/* buttons end */}
                                </>
                              )}
                            </React.Fragment>
                          )}
                        </div>
                        {/* tabs end */}
                      </>
                    )}
                    {/* PRODUCT NAME START */}
                    {selectedCreator != 1 && selectedCreator != 3 && (
                      <>
                        <label
                          htmlFor="product_name"
                          className="form_input_label mt-2"
                        >
                          Asset Name/Title
                        </label>
                        <input
                          type="text"
                          name="product_name"
                          className="form_input_box"
                          placeholder="Enter Asset Name"
                          value={getproductname}
                          onChange={handleAssetsChange}
                        />
                      </>
                    )}
                    {/* PRODUCT NAME END */}

                    {selectedCreator == 0 && (
                      <>
                        {/* CREATOR2 NAME START */}
                        <label
                          htmlFor="2_creator_name"
                          className="form_input_label mt-2"
                        >
                          Enter Creator's Name
                          <span
                            className="details_home_icon"
                            onClick={() => {
                              setShowModal1(true);
                              setErrorMessage1(
                                "Respect the creators vision! Choose the profile of the asset creator to seek their permission for making Aucemo. Honor the artists creativity and craftsmanship by acknowledging their role in the Aucemo journey. Select the creator profile to connect with the person behind the asset and ensure you have their consent to transform it into a unique Aucemo."
                              );
                            }}
                          >
                            {String.fromCharCode(9432)}
                          </span>
                        </label>
                        <input
                          onClick={() => {
                            setCreatormodal(true);
                          }}
                          value={getcreatorname}
                          type="text"
                          name="2_creator_name"
                          className="form_input_box"
                          placeholder="Enter Creator Name"
                          // onChange={(e) => {
                          //   setGetcreatorname(e.target.value);
                          // }}
                        />
                        {/* CREATOR2 NAME END */}
                      </>
                    )}
                    {/* CREATOR AN OPTION SECTION START */}
                    {selectedCreator == 2 && (
                      <>
                        {/* CREATOR3 NAME START */}
                        <label
                          htmlFor="product_name"
                          className="form_input_label mt-2"
                        >
                          Creator Name
                        </label>
                        <input
                          type="text"
                          name="product_name"
                          className="form_input_box"
                          placeholder="Enter Creator Name"
                          value={getcreatornameText}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            // const words = inputValue.trim().split(/\s+/); // Split input into words

                            const containsEmoji =
                              /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}]/u.test(
                                inputValue
                              );

                            // setGetcreatornameText(e.target.value)
                            if (!containsEmoji) {
                              setGetcreatornameText(inputValue);
                            }
                          }}
                        />
                        {/* CREATOR3 NAME END */}

                        {/* CREATOR CONTACT START */}
                        <label
                          htmlFor="product_name"
                          className="form_input_label mt-2"
                        >
                          Creator Contact
                        </label>
                        <input
                          onKeyPress={handleKeyPress}
                          type="number"
                          name="product_name"
                          className="form_input_box"
                          placeholder="Enter mobile number"
                          value={getcreatornameContactNo}
                          onChange={handlePhoneNumberChange}
                          // onChange={(e) =>
                          //   setGetcreatornameContactNo(e.target.value)
                          // }
                        />
                        {/* CREATOR CONTACT END */}

                        {/* CREATOR EMAIL START */}
                        <label
                          htmlFor="product_name"
                          className="form_input_label mt-2"
                        >
                          Creator Email
                        </label>
                        <input
                          type="text"
                          name="product_name"
                          className="form_input_box"
                          placeholder="Enter email"
                          // onChange={(e) =>
                          //   setGetcreatornameEmail(e.target.value)
                          // }
                          value={getcreatornameEmail}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            // const words = inputValue.trim().split(/\s+/); // Split input into words

                            const containsEmoji =
                              /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}]/u.test(
                                inputValue
                              );

                            // setGetcreatornameText(e.target.value)
                            if (!containsEmoji) {
                              setGetcreatornameEmail(inputValue);
                            }
                          }}
                        />
                        {/* CREATOR EMAIL END */}
                      </>
                    )}
                    {/* CREATOR AN OPTION SECTION END */}

                    {/* Select Manager start */}
                    {/* {selectedCreator == 3 && (
                      <>
                        <label
                          htmlFor="product_name"
                          className="form_input_label mt-2"
                        >
                          Creator Name
                        </label>
                        <input
                          type="text"
                          name="product_name"
                          className="form_input_box"
                          placeholder="Please select your owner"
                          // onChange={(e) => {
                          //   setproductname(e.target.value);
                          // }}
                          // disabled
                          readOnly
                          value={getmanegerName}
                          onClick={() => {
                            setManagermodal(true);
                            console.log("managermodal", managermodal);
                          }}
                        />
                      </>
                    )} */}
                    {/* select manager end */}
                    {/* {selectedCreator != 1 &&  */}
                    <>
                      {selectedCreator != 1 && selectedCreator != 3 && (
                        <>
                          {/* UPLOAD AUTHNATICATION FILE START */}
                          <label
                            htmlFor="auth_file"
                            className="form_input_label mt-2"
                          >
                            Upload authentication file
                            <span
                              className="details_home_icon"
                              onClick={() => {
                                setShowModal1(true);
                                setErrorMessage1(
                                  "Verify the authenticity of your asset by uploading the authentication certificate file. Ensure it is a valid document that confirms the originality of your Aucemo. Protect your investment and contribute to a trustworthy community."
                                );
                              }}
                            >
                              {String.fromCharCode(9432)}
                            </span>
                          </label>
                          <div className="image-upload">
                            <label
                              htmlFor="file-input"
                              className="upload-button"
                            >
                              <img src={images.gallery_icon} alt="" />
                            </label>
                            <input
                              id="file-input"
                              type="file"
                              accept="image/*"
                              // capture="camera"
                              onChange={handleImageChange}
                            />
                            <div className="image-previews">
                              {selectedImages.map((image, index) => (
                                <div className="image-preview" key={index}>
                                  <img
                                    src={URL.createObjectURL(image)}
                                    alt={`Preview ${index}`}
                                  />
                                  <button
                                    className="delete-button"
                                    onClick={() => handleImageDelete(index)}
                                  >
                                    <img src={images.close_red} alt="" />
                                  </button>
                                </div>
                              ))}
                            </div>
                          </div>
                          {/* UPLOAD AUTHNATICATION FILE END */}
                          {/* UPLOAD MINIMUM 1 IMAGE START */}
                          <label
                            htmlFor="product_name"
                            className="form_input_label mt-2"
                          >
                            Upload minimum 1 image
                            <span
                              className="details_home_icon"
                              onClick={() => {
                                setShowModal1(true);
                                setErrorMessage1(
                                  "To ensure that your Aucemo stands out and captivates potential buyers, we kindly request you to upload 6 high-quality images of your asset. Here are some tips to make the most of your asset images: \n1. Showcasing the Asset: Capture clear, well-lit images that highlight the unique features, craftsmanship, and details of your asset. Showcase its beauty and functionality to entice potential buyers. \n2. Authenticity and Originality: Make sure the images accurately represent your asset. Avoid using stock photos or images that may mislead buyers. Authenticity is key to building trust with our community. \n3. Intriguing Perspectives: Experiment with different angles, perspectives, and compositions to create visual interest. Capture shots that evoke curiosity and make viewers want to explore your asset further. \n4. High Resolution: Upload images with high resolution to ensure clarity and sharpness. This allows potential buyers to zoom in and examine the intricate details of your asset. \n5. Contextualizing the Asset: If appropriate, consider including images that showcase your asset in real-life settings or demonstrate its usage. This helps potential buyers visualize how your asset fits into their lives. \n6. Multiple Views: Utilize the opportunity to upload multiple images to showcase various aspects of your asset. Include different angles, close-ups, and alternative views to provide a comprehensive understanding. By uploading compelling and visually appealing images, you enhance the overall appeal and desirability of your Aucemo. Remember, first impressions matter, and stunning visuals can significantly impact the success of your Aucemo in attracting potential buyers. Thank you for your attention to detail, and we look forward to seeing your captivating asset images!"
                                );
                              }}
                            >
                              {String.fromCharCode(9432)}
                            </span>
                          </label>
                          <div className="image-upload">
                            <>
                              <label
                                htmlFor="min_1_img"
                                className="upload-button"
                              >
                                <img src={images.gallery_icon} alt="" />
                              </label>
                              <input
                                id="min_1_img"
                                type="file"
                                accept="image/*"
                                // capture="camera"
                                multiple
                                onChange={handleImageChangeMin1Img}
                              />
                            </>

                            <div className="image-previews">
                              {selectedImagesMin1Img.map((image, index) => (
                                <div className="image-preview" key={index}>
                                  <img
                                    src={URL.createObjectURL(image)}
                                    alt={`Preview ${index}`}
                                  />

                                  <button
                                    className="delete-button"
                                    onClick={() => handleImageDelete2(index)}
                                  >
                                    <img src={images.close_red} alt="" />
                                  </button>
                                </div>
                              ))}
                            </div>
                          </div>
                          {/* UPLOAD MINIMUM 1 IMAGE END */}
                        </>
                      )}
                      {/* SELECT CATEGORY START */}
                      {(selectedCreator == "1" || selectedCreator == "3") && (
                        <>
                          {/* date of creation start */}

                          {/* SUBMIT BUTTON END */}
                        </>
                      )}
                      {/* SELECT CATEGORY END */}
                    </>
                    {/* } */}
                    {selectedCreator != "1" && selectedCreator != "3" && (
                      <button
                        disabled={isButtonDisabled}
                        onClick={() => {
                          // setButtonDisabled(true);
                          selectedCreator == 0
                            ? CreateproductNO()
                            : selectedCreator == 1
                            ? CreateproductYesOption()
                            : selectedCreator == 3
                            ? CreateproductManager()
                            : CreateproductNotPlatform();
                        }}
                        className="product_submit_btn"
                      >
                        {selectedCreator == 1 || selectedCreator == 3
                          ? "Save and Protect"
                          : "Submit"}
                      </button>
                    )}
                  </div>
                </div>
              </>
            ) : managermodal == true ? (
              <>
                {/* manager model start */}
                <div
                  className="creator_modal_main_warp"
                  style={{ zIndex: 112 }}
                >
                  <div className="creator_modal_base_warp">
                    <div className="cp_navbar_wrapp">
                      <img
                        onClick={() => {
                          setManagermodal(false);
                          // setSharenfbModal(true);
                        }}
                        src={images.back}
                        className="nav_back_btn"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="search mt-2">
                    <input
                      type="text"
                      placeholder="Search"
                      // value={getsearchData}
                      onChange={(e) => {
                        Search_manager(e.target.value);
                      }}
                    />
                    <img className="search-img" src={images.search} alt="" />
                  </div>
                  {getallmanager.map((item, index) => (
                    <div
                      onClick={() => {
                        // selecteduser(item);
                        setManagermodal(false);
                        setmanegerName(item.label);
                        setmanegerId(item.id);
                      }}
                      key={index}
                      className="item-container"
                    >
                      <img src={item.profile} alt={item.label} />
                      <div>{item.label}</div>
                    </div>
                  ))}
                </div>
                {/* manager modal end */}
              </>
            ) : (
              <>
                {/* creator model start */}
                <div
                  className="creator_modal_main_warp"
                  style={{ zIndex: 112 }}
                >
                  <div className="creator_modal_base_warp">
                    <div className="cp_navbar_wrapp">
                      <img
                        onClick={() => {
                          setCreatormodal(false);
                          // setSharenfbModal(true);
                        }}
                        src={images.back}
                        className="nav_back_btn"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="search mt-2">
                    <input
                      type="text"
                      placeholder="Search"
                      // value={getsearchData}
                      onChange={(e) => {
                        Search_user(e.target.value);
                      }}
                    />
                    <img className="search-img" src={images.search} alt="" />
                  </div>
                  {data_filter_data.map((item, index) => (
                    <div
                      onClick={() => {
                        // selecteduser(item);
                        setCreatormodal(false);
                        setGetcreatorname(item.label);
                        setGetcreatorid(item.id);
                      }}
                      key={index}
                      className="item-container"
                    >
                      <img src={item.profile} alt={item.label} />
                      <div>{item.label}</div>
                    </div>
                  ))}
                </div>
                {/* creator modal end */}
              </>
            )}

            {/* share owner modal start */}
            {getshareOwnerModal && (
              <div
                className="creator_modal_main_warp"
                style={{ zIndex: 112, background: "#000", position: "fixed" }}
              >
                <div className="creator_modal_base_warp">
                  <div className="cp_navbar_wrapp">
                    <img
                      onClick={() => {
                        setShareOwnerModal(false);
                        setSharenfbModal(true);
                      }}
                      src={images.back}
                      className="nav_back_btn"
                      alt=""
                    />
                  </div>
                </div>
                <div className="search mt-2">
                  <input
                    type="text"
                    placeholder="Search"
                    // value={getsearchData}
                    onChange={(e) => {
                      Search_user(e.target.value);
                    }}
                  />
                  <img className="search-img" src={images.search} alt="" />
                </div>
                <div
                  style={{ width: "100%", height: "80vh", overflow: "scroll" }}
                >
                  {data_filter_data.map((item, index) => (
                    <div
                      onClick={() => {
                        // selecteduser(item);
                        setShareOwnerModal(false);
                        setSharenfbModal(true);
                        setDonationOwnerName(item.label);
                        setDonationOwnerId(item.id);
                      }}
                      key={index}
                      className="item-container"
                    >
                      <img src={item.profile} alt={item.label} />
                      <div>{item.label}</div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {/* share owner modal end */}

            {/* share club modal start */}
            {getshareClubModal && (
              <div
                className="creator_modal_main_warp"
                style={{ zIndex: 112, background: "#000", position: "fixed" }}
              >
                <div className="creator_modal_base_warp">
                  <div className="cp_navbar_wrapp">
                    <img
                      onClick={() => {
                        setShareClubModal(false);
                        setSharenfbModal(true);
                      }}
                      src={images.back}
                      className="nav_back_btn"
                      alt=""
                    />
                  </div>
                </div>
                <div className="search mt-2">
                  <input
                    type="text"
                    placeholder="Search"
                    // value={getsearchData}
                    onChange={(e) => {
                      Search_clubs(e.target.value);
                    }}
                  />
                  <img className="search-img" src={images.search} alt="" />
                </div>
                <div
                  style={{ width: "100%", height: "80vh", overflow: "scroll" }}
                >
                  {getallclubs.map((item, index) => (
                    // console.log('item', item)
                    <div
                      onClick={() => {
                        // selecteduser(item);
                        setShareClubModal(false);
                        setSharenfbModal(true);
                        setDonationClubName(item.label);
                        setDonationClubId(item.club_id);
                        // console.log("item", item);
                      }}
                      key={index}
                      className="item-container"
                    >
                      <img src={item.profile} alt={item.label} />
                      <div>{item.label}</div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {/* share club modal end */}

            {showModal && (
              // <CusModal
              //   msg={errorMessage}
              //   setShowModal={setShowModal}
              //   showModal={showModal}
              // />
              <NewModal
                title=""
                msg={errorMessage}
                setShowModal={openmodal}
                closemodal={closemodal}
              />
            )}
            {getsharenfbModal && (
              <button
                // onClick={() => setSharenfbModal(false)}
                className="cusModal_main_wrapp"
                style={{ zIndex: 100 }}
              >
                <div
                  className="cusmodal_inner_wrapp"
                  // style={{ position: "fixed" }}
                >
                  <img src={images.logo} alt="" className="cm_logo" />
                  <p className="available_nfb_modal_heading">
                    How many Aucemo you want to gift ? <br /> Available Aucemo{" "}
                    {getavailableBond}
                  </p>
                  <div id="custom_radio" style={{ width: "90%" }}>
                    <label class="custom_radio">
                      <input
                        type="radio"
                        name="share_checkbox"
                        value={0}
                        checked={getisUserAndClub == 0}
                        onChange={handleShareUserAndClubChange}
                      />
                      <span class="checkmark"></span>
                      User
                    </label>
                    <label class="custom_radio">
                      <input
                        type="radio"
                        name="share_checkbox"
                        value={1}
                        checked={getisUserAndClub == 1}
                        onChange={handleShareUserAndClubChange}
                      />
                      <span class="checkmark"></span>
                      Club
                    </label>
                  </div>
                  <>
                    {getisUserAndClub == 0 ? (
                      <div className="cm_des_wrapp">
                        {/* <input
                className="available_nfb_modal_input"
                placeholder="Search Username"
              /> */}
                        <input
                          onClick={() => {
                            setShareOwnerModal(true);
                            // setDonationSelect(true);
                            setSharenfbModal(false);
                          }}
                          value={getdonationOwnerName}
                          type="text"
                          name="product_name"
                          className="available_nfb_modal_input"
                          placeholder="Search Username"
                          // onChange={(e) => {
                          //   setGetcreatorname(e.target.value);
                          // }}
                        />
                        <input
                          className="available_nfb_modal_input"
                          placeholder="Enter number of Aucemo"
                          value={getnoOfBond}
                          onChange={(e) => {
                            // setNoOfBond(e.target.value)
                            const inputValue = e.target.value;
                            const isValid =
                              /^\d+$/.test(inputValue) &&
                              parseInt(inputValue, 10) >= 0;

                            if (!isValid) {
                              setNoOfBond("");
                              setErrorMessage1(
                                "Please enter a valid number of Aucemo"
                              );
                              setShowModal1(true);
                            } else {
                              setNoOfBond(parseInt(inputValue, 10));
                              console.log("isValid", inputValue);
                            }
                          }}
                        />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "10%",
                          }}
                        >
                          <button
                            className="cm_btn"
                            onClick={() => {
                              if (getdonationOwnerName == "") {
                                alert("Search username and select");
                              } else if (getnoOfBond == null) {
                                alert("Please enter number of Aucemo");
                              } else if (getnoOfBond == 0) {
                                alert("You can't make a stock of zero Aucemo");
                              } else if (getnoOfBond > getavailableBond) {
                                alert(
                                  "Enter Aucemo value below " +
                                    getavailableBond +
                                    "."
                                );
                              } else if (ddd < getnoOfBond) {
                                setShowModal(true);
                                setErrorMessage(
                                  "Enter Aucemo value below " + ddd + "."
                                );
                              } else {
                                setnumber_of_bonds_created_for_share([
                                  ...getnumber_of_bonds_created_for_share,
                                  getnoOfBond,
                                ]);
                                setnumber_of_bonds_created_for_share_name([
                                  ...getnumber_of_bonds_created_for_share_name,
                                  getdonationOwnerName,
                                ]);
                                setnumber_of_bonds_created_for_share_id([
                                  ...getnumber_of_bonds_created_for_share_id,
                                  getdonationOwnerId,
                                ]);
                                setAvailableBond(
                                  parseInt(getavailableBond) -
                                    parseInt(getnoOfBond)
                                );
                                setNoOfBond(null);
                                setDonationOwnerName("");
                                setDonationOwnerId("");
                                setSharenfbModal(false);
                              }
                            }}
                          >
                            Save
                          </button>

                          <button
                            className="cm_btn"
                            onClick={() => {
                              setNoOfBond(null);
                              setNoOfBondClub(null);
                              setDonationOwnerName("");
                              setDonationClubName("");
                              setDonationOwnerId("");
                              setDonationClubId("");
                              setSharenfbModal(false);
                              setBodyScrollEnabled(true);
                            }}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className="cm_des_wrapp">
                        {/* <input
                className="available_nfb_modal_input"
                placeholder="Search Username"
              /> */}
                        <input
                          onClick={() => {
                            setShareClubModal(true);
                            // setDonationSelect(true);
                            setSharenfbModal(false);
                          }}
                          value={getdonationClubName}
                          type="text"
                          name="product_name"
                          className="available_nfb_modal_input"
                          placeholder="Search Username"
                          // onChange={(e) => {
                          //   setGetcreatorname(e.target.value);
                          // }}
                        />
                        <input
                          className="available_nfb_modal_input"
                          placeholder="Enter number of Aucemo"
                          value={getnoOfBondClub}
                          onChange={(e) => {
                            // setNoOfBondClub(e.target.value);
                            const inputValue = e.target.value;
                            const isValid =
                              /^\d+$/.test(inputValue) &&
                              parseInt(inputValue, 10) >= 0;

                            if (!isValid) {
                              setNoOfBondClub("");
                              setErrorMessage1(
                                "Please enter a valid number of Aucemo"
                              );
                              setShowModal1(true);
                            } else {
                              setNoOfBondClub(parseInt(inputValue, 10));
                              console.log("isValid", inputValue);
                            }
                          }}
                        />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "10%",
                          }}
                        >
                          <button
                            className="cm_btn"
                            onClick={() => {
                              if (getdonationClubName == "") {
                                alert("Search club and select");
                              } else if (getnoOfBondClub == null) {
                                alert("Please enter number of Aucemo");
                              } else if (getnoOfBondClub == 0) {
                                alert("You can't make a stock of zero Aucemo");
                              } else if (getnoOfBondClub > getavailableBond) {
                                alert(
                                  "Enter Aucemo value below " +
                                    getavailableBond +
                                    "."
                                );
                              } else if (ddd < getnoOfBondClub) {
                                setShowModal(true);
                                setErrorMessage(
                                  "Enter Aucemo value below " + ddd + "."
                                );
                              } else {
                                setclub_number_of_bonds_created_for_share([
                                  ...getclub_number_of_bonds_created_for_share,
                                  getnoOfBondClub,
                                ]);
                                setclub_number_of_bonds_created_for_share_name([
                                  ...getclub_number_of_bonds_created_for_share_name,
                                  getdonationClubName,
                                ]);
                                setclub_number_of_bonds_created_for_share_id([
                                  ...getclub_number_of_bonds_created_for_share_id,
                                  getdonationClubId,
                                ]);
                                setAvailableBond(
                                  parseInt(getavailableBond) -
                                    parseInt(getnoOfBondClub)
                                );
                                setNoOfBondClub(null);
                                setDonationClubName("");
                                setDonationClubId("");
                                setSharenfbModal(false);
                              }
                            }}
                          >
                            Save
                          </button>

                          <button
                            className="cm_btn"
                            onClick={() => {
                              setNoOfBond(null);
                              setNoOfBondClub(null);
                              setDonationOwnerName("");
                              setDonationClubName("");
                              setDonationOwnerId("");
                              setDonationClubId("");
                              setSharenfbModal(false);
                            }}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    )}
                  </>
                </div>
              </button>
            )}
          </>
        )}

        {/* attach video modal start */}
        {getattechVideoModal && (
          <div
            style={{
              position: "fixed",
              width: "100vw",
              height: "100vh",
              top: "0px",
              left: "0px",
              zIndex: 112,
            }}
          >
            <div className="page_main_wrapp">
              <div className="page_base_wrapp">
                <div className="page_navbar_wrapp" style={{ zIndex: 111 }}>
                  <div className="nav_back_wrapp">
                    <img
                      src={images.back}
                      className="nav_back_btn"
                      alt=""
                      onClick={() => setAttechVideoModal(false)}
                    />
                  </div>
                  <p className="nav_screen_name">Creator Videos</p>
                </div>
                <div
                  style={{
                    width: "100%",
                    color: "gray",
                    height: "2px",
                    margin: "10px auto",
                    minWidth: "300px",
                  }}
                ></div>

                {/* <div className="upload_video_btn_cp">
                  <Link to={"/editprofile"}>
                    <p className="upload_video_btn_cp_text">
                      Upload Video from profile
                    </p>
                  </Link>
                </div> */}

                <div className="av_videos_wrapp">
                  {getvideoArray && getvideoArray.length > 0 ? (
                    getvideoArray.map((item, index) => {
                      return (
                        <>
                          <div
                            // className="av_single_video"
                            style={{
                              width: "48%",
                              padding: "10px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              flexDirection: "column",
                              gap: 6,
                            }}
                          >
                            {/* <input
                            onClick={() => {
                              setVideoId(item.user_video_id);
                              setUserVideo(item.user_videos);
                              setAttechVideoModal(false);
                              console.log("item", item);
                            }}
                            type="checkbox"
                            className="av_of_checkbox"
                            checked={getvideoId === item.user_video_id}
                          /> */}
                            <div
                              style={{
                                width: "100%",
                                height: 80,
                                border: "2px dashed var(--color-gray)",
                                borderColor:
                                  getvideoId === item.user_video_id
                                    ? "var(--color-main)"
                                    : "var(--color-gray)",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                borderRadius: 12,
                              }}
                            >
                              <button
                                onClick={() => {
                                  setAttechVideoModal(false);
                                  setPreviewVideoModal(true);
                                  console.log("getuserVid", getuserVid);
                                }}
                                className="av_prev_btn"
                              >
                                Preview
                              </button>
                            </div>
                            <button
                              style={{
                                color: "#fff",
                                fontSize: "12px",
                                fontFamily: "var(--font-600)",
                                border: "1px solid white",
                                padding: "10px 14px",
                                borderRadius: "12px",
                                borderColor:
                                  getvideoId === item.user_video_id
                                    ? "var(--color-main)"
                                    : "white",
                                background:
                                  getvideoId === item.user_video_id
                                    ? "var(--color-main)"
                                    : "black",
                              }}
                              onClick={() => {
                                setVideoId(item.user_video_id);
                                setUserVideo(item.user_videos);
                                setAttechVideoModal(false);
                                console.log("item", item);
                              }}
                            >
                              Attach
                            </button>
                          </div>
                        </>
                      );
                    })
                  ) : (
                    <Link
                      className="novideo_btn"
                      // to={"/UserProfile"}
                      // state={{
                      //   user_id: userId,
                      // }}
                      to={"/editprofile"}
                    >
                      Upload Videos From Profile
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {/* attach video modal end */}

        {/* preview video modal start */}
        {getpreviewVideoModal && (
          <div
            style={{
              position: "fixed",
              width: "100vw",
              height: "100vh",
              top: "0px",
              left: "0px",
              zIndex: 112,
            }}
          >
            <div className="page_main_wrapp">
              <div className="page_base_wrapp">
                <div className="page_navbar_wrapp">
                  <div className="nav_back_wrapp">
                    <img
                      src={images.back}
                      className="nav_back_btn"
                      alt=""
                      onClick={() => setPreviewVideoModal(false)}
                    />
                  </div>
                  <p className="nav_screen_name">Video</p>
                </div>
                <video controls style={{ width: "100%", height: "90%" }}>
                  <source src={getuserVid} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>
        )}
        {/* preview video modal end */}

        {/* preview video 2 modal start */}
        {getpreviewVideoModal2 && (
          <div
            style={{
              position: "fixed",
              width: "100vw",
              height: "100vh",
              top: "0px",
              left: "0px",
              zIndex: 112,
            }}
          >
            <div className="page_main_wrapp">
              <div className="page_base_wrapp">
                <div className="page_navbar_wrapp">
                  <div className="nav_back_wrapp">
                    <img
                      src={images.back}
                      className="nav_back_btn"
                      alt=""
                      onClick={() => setPreviewVideoModal2(false)}
                    />
                  </div>
                  <p className="nav_screen_name">Preview</p>
                </div>
                <video controls style={{ width: "100%", height: "90%" }}>
                  <source src={getuserVid2} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>
        )}

        {/* preview video 2 modal end */}
        {/* keyur 10-10-2023 */}

        {/* global change modal start */}

        {getglobModalOpen && (
          <>
            {/* <div>
              <div className="modal_login" style={{ zIndex: 111 }}>
                <div
                  className="modal-content_login"
                >
                  <div className="modal_div1">
                    <img className="forgot_main" src={images.logo} alt="" />
                  </div>
                  <div className="alert_modal_div">
                    <br />
                    <p
                      className="alert-modal-message"
                      style={{
                        height: "80%",
                        overflowY: "scroll",
                      }}
                    >
                      {getglobModalMsg}
                    </p>
                    <br />
                  </div>
                  <hr className="hr_forgot" />
                  <br />
                  <div
                    style={{
                      width: "90%",
                      justifyContent: "space-between",
                      display: "flex",
                      margin: "0px auto",
                    }}
                  >
                    <button
                      className="close_forgot"
                      onClick={() => {
                        setBodyScrollEnabled(true);
                        let idString = "";
                        var demo = 0;
                        for (
                          let index = 0;
                          index < getallInterest.length;
                          index++
                        ) {
                          if (getallInterest[index].status == "2") {
                            demo = demo + 1;

                            if (index > 0) {
                              idString += ",";
                            }
                            idString += getallInterest[index].id;
                          }
                        }
                        setGlobModalOpen(false);
                        setIsLoading(true);
                        let formData = new FormData();

                        formData.append("product_name", getproductname);
                        formData.append("owner_name", getownerName);
                        formData.append("owner_id", getownerId);
                        formData.append("bond_interest_id", idString);
                        formData.append("globle_exclusive", selectedGlob);
                        formData.append("product_history", getProductStory);
                        formData.append(
                          "product_highlights",
                          getProductHighlight
                        );
                        formData.append("description", getProductDes);
                        formData.append("product_value", getProductValue);
                        formData.append(
                          "product_value_checker",
                          selectedAuction
                        );
                        formData.append("minimum_bid_amount", getminimumBid);
                        formData.append("auction_days", getauctionDurtion);
                        formData.append("product_hashtag", gethashtags);
                        formData.append("is_owner", selectedCreator);
                        formData.append("user_id", userId);
                        formData.append("user_video_id", getvideoId);
                        selectedImagesMin1Img.forEach((image, index) => {
                          formData.append("img_video[]", image);
                        });
                        selectedImagesAndVidio.forEach((image, index) => {
                          formData.append("media_upload[]", image);
                        });
                        selectedImages.forEach((image, index) => {
                          formData.append("proof_file", image);
                        });

                        selectedVideos.forEach((image, index) => {
                          formData.append("bond_video", image);
                        });

                        formData.append(
                          "date_of_creation",
                          // selectedDate
                          moment(selectedDate).format("YYYY-MM-DD")
                        );

                        let idString_usernmae = "";
                        let idString_number_of_bond = "";
                        let idString_user_id = "";
                        var demo = 0;
                        for (
                          let index = 0;
                          index < getnumber_of_bonds_created_for_share.length;
                          index++
                        ) {
                          demo = demo + 1;

                          if (index > 0) {
                            idString_usernmae += ",";
                            idString_number_of_bond += ",";
                            idString_user_id += ",";
                          }
                          idString_usernmae +=
                            getnumber_of_bonds_created_for_share_name[index];
                          idString_number_of_bond +=
                            getnumber_of_bonds_created_for_share[index];
                          idString_user_id +=
                            getnumber_of_bonds_created_for_share_id[index];
                        }

                        formData.append(
                          "bond_share_owner_name",
                          idString_usernmae
                        );
                        formData.append(
                          "bond_share_owner_id",
                          idString_user_id
                        );
                        formData.append(
                          "bond_share_total_assign",
                          idString_number_of_bond
                        );
                        // formData.append("total_qr_hunt_bond", getdonetQrhunt);

                        let club_idString_usernmae = "";
                        let club_idString_number_of_bond = "";
                        let club_idString_user_id = "";
                        var club_demo = 0;
                        for (
                          let index = 0;
                          index <
                          getclub_number_of_bonds_created_for_share.length;
                          index++
                        ) {
                          club_demo = club_demo + 1;

                          if (index > 0) {
                            club_idString_usernmae += ",";
                            club_idString_number_of_bond += ",";
                            club_idString_user_id += ",";
                          }
                          club_idString_usernmae +=
                            getclub_number_of_bonds_created_for_share_name[
                              index
                            ];
                          club_idString_number_of_bond +=
                            getclub_number_of_bonds_created_for_share[index];
                          club_idString_user_id +=
                            getclub_number_of_bonds_created_for_share_id[index];
                        }
                        formData.append(
                          "share_club_name",
                          club_idString_usernmae
                        );
                        formData.append("share_club_id", club_idString_user_id);
                        formData.append(
                          "share_total_assign",
                          club_idString_number_of_bond
                        );
                        formData.append("share_user_id", userId);

                        let linktag = "";
                        var demolinktag = 0;
                        for (
                          let index = 0;
                          index < inputValues.length;
                          index++
                        ) {
                          if (inputValues[index]) {
                            demolinktag = demolinktag + 1;

                            if (index > 0) {
                              linktag += ",";
                            }
                            linktag += inputValues[index];
                          }
                        }
                        formData.append("social_media_link", linktag);
                        for (var pair of formData.entries()) {
                          console.log(pair[0] + ", " + pair[1]);
                        }
                        console.log("formData ==>", formData);
                        axios
                          .post(create_bond, formData)
                          .then((Response) => {
                            setGlobModalOpen(false);
                            setButtonDisabled(false);
                            console.log("Response", Response);
                            setIsLoading(false);
                            if (Response.data.status == 1) {
                              navigate("/actionneed2");
                            }
                          })
                          .catch((err) => {
                            setGlobModalOpen(false);

                            setButtonDisabled(false);
                            console.log("err", err);
                            setIsLoading(false);
                          });
                      }}
                    >
                      Okay
                    </button>

                    <button
                      className="close_forgot"
                      onClick={() => {
                        setBodyScrollEnabled(true);
                        setGlobModalOpen(false);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div> */}

            <ReactModal
              isOpen={setGlobModalOpen}
              // onAfterOpen={afterOpenModal}
              onRequestClose={closemodal3}
              style={customStyles}
            >
              <div className="NewModal_main_wrapp">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img className="newmodal_logo" src={images.logo} alt="" />
                  <p className="newmodal_titel"></p>
                  {/* <p className="newmodal_titel">Alert</p> */}
                </div>

                {/* <div className="newmodal_des_wrapp"> */}
                <p className="newmodal_des">{getglobModalMsg}</p>

                <div className="modal_two_buton" style={{ width: "100%" }}>
                  <button
                    className="close_forgot"
                    onClick={() => {
                      setBodyScrollEnabled(true);
                      let idString = "";
                      var demo = 0;
                      for (
                        let index = 0;
                        index < getallInterest.length;
                        index++
                      ) {
                        if (getallInterest[index].status == "2") {
                          demo = demo + 1;

                          if (index > 0) {
                            idString += ",";
                          }
                          idString += getallInterest[index].id;
                        }
                      }
                      setGlobModalOpen(false);
                      setIsLoading(true);
                      let formData = new FormData();

                      formData.append("product_name", getproductname);
                      formData.append(
                        "owner_name",
                        selectedCreator == 3 ? getownerName : get_username
                      );
                      formData.append(
                        "owner_id",
                        selectedCreator == 3 ? getmanegerid : getownerId
                      );
                      formData.append("bond_interest_id", idString);
                      formData.append("globle_exclusive", selectedGlob);
                      formData.append("product_history", getProductStory);
                      formData.append(
                        "product_highlights",
                        getProductHighlight
                      );
                      formData.append("description", getProductDes);
                      formData.append("product_value", getProductValue);
                      formData.append("product_value_checker", selectedAuction);
                      formData.append("minimum_bid_amount", getminimumBid);
                      formData.append("auction_days", getauctionDurtion);
                      formData.append("product_hashtag", gethashtags);
                      formData.append("is_owner", selectedCreator);
                      formData.append("user_id", userId);
                      formData.append("user_video_id", getvideoId);
                      selectedImagesMin1Img.forEach((image, index) => {
                        formData.append("img_video[]", image);
                      });
                      selectedImagesAndVidio.forEach((image, index) => {
                        formData.append("media_upload[]", image);
                      });
                      selectedImages.forEach((image, index) => {
                        formData.append("proof_file", image);
                      });

                      selectedVideos.forEach((image, index) => {
                        formData.append("bond_video", image);
                      });

                      if (month == "" && day == "") {
                        formData.append("date_of_creation", year);
                      } else if (day == "") {
                        formData.append("date_of_creation", `${year}-${month}`);
                      } else {
                        formData.append(
                          "date_of_creation",
                          `${year}-${month}-${day}`
                        );
                      }

                      // formData.append(
                      //   "date_of_creation",
                      //   moment(selectedDate).format("YYYY-MM-DD")
                      // );

                      let idString_usernmae = "";
                      let idString_number_of_bond = "";
                      let idString_user_id = "";
                      var demo = 0;
                      for (
                        let index = 0;
                        index < getnumber_of_bonds_created_for_share.length;
                        index++
                      ) {
                        demo = demo + 1;

                        if (index > 0) {
                          idString_usernmae += ",";
                          idString_number_of_bond += ",";
                          idString_user_id += ",";
                        }
                        idString_usernmae +=
                          getnumber_of_bonds_created_for_share_name[index];
                        idString_number_of_bond +=
                          getnumber_of_bonds_created_for_share[index];
                        idString_user_id +=
                          getnumber_of_bonds_created_for_share_id[index];
                      }

                      formData.append(
                        "bond_share_owner_name",
                        idString_usernmae
                      );
                      formData.append("bond_share_owner_id", idString_user_id);
                      formData.append(
                        "bond_share_total_assign",
                        idString_number_of_bond
                      );
                      // formData.append("total_qr_hunt_bond", getdonetQrhunt);

                      let club_idString_usernmae = "";
                      let club_idString_number_of_bond = "";
                      let club_idString_user_id = "";
                      var club_demo = 0;
                      for (
                        let index = 0;
                        index <
                        getclub_number_of_bonds_created_for_share.length;
                        index++
                      ) {
                        club_demo = club_demo + 1;

                        if (index > 0) {
                          club_idString_usernmae += ",";
                          club_idString_number_of_bond += ",";
                          club_idString_user_id += ",";
                        }
                        club_idString_usernmae +=
                          getclub_number_of_bonds_created_for_share_name[index];
                        club_idString_number_of_bond +=
                          getclub_number_of_bonds_created_for_share[index];
                        club_idString_user_id +=
                          getclub_number_of_bonds_created_for_share_id[index];
                      }
                      formData.append(
                        "share_club_name",
                        club_idString_usernmae
                      );
                      formData.append("share_club_id", club_idString_user_id);
                      formData.append(
                        "share_total_assign",
                        club_idString_number_of_bond
                      );
                      formData.append("share_user_id", userId);

                      let linktag = "";
                      var demolinktag = 0;
                      for (let index = 0; index < inputValues.length; index++) {
                        if (inputValues[index]) {
                          demolinktag = demolinktag + 1;

                          if (index > 0) {
                            linktag += ",";
                          }
                          linktag += inputValues[index];
                        }
                      }
                      formData.append("social_media_link", linktag);
                      for (var pair of formData.entries()) {
                        console.log(pair[0] + ", " + pair[1]);
                      }
                      console.log("formData ==>", formData);
                      axios
                        .post(create_bond, formData)
                        .then((Response) => {
                          setGlobModalOpen(false);
                          setButtonDisabled(false);
                          console.log("Response", Response);
                          setIsLoading(false);
                          if (Response.data.status == 1) {
                            navigate("/actionneed2");
                          }
                        })
                        .catch((err) => {
                          setGlobModalOpen(false);

                          setButtonDisabled(false);
                          console.log("err", err);
                          setIsLoading(false);
                        });
                    }}
                  >
                    Okay
                  </button>

                  <button
                    className="close_forgot"
                    onClick={() => {
                      setBodyScrollEnabled(true);
                      setGlobModalOpen(false);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </ReactModal>
          </>
        )}

        {/* global change modal end */}
      </>
      {showModal1 && (
        // <AlertModal
        //   msg={errorMessage1}
        //   setShowModal={setShowModal1}
        //   title="Information"
        // />

        // <NewModal
        //   title="Information"
        //   msg={errorMessage1}
        //   setShowModal={openmodal1}
        //   closemodal={closemodal1}
        // />

        <V2Modal
          canclebtn={false}
          title=""
          msg={errorMessage1}
          setShowModal={setShowModal1}
          okfunction={closemodal1}
        />
      )}
      {isModalVisiblevideo && (
        <div
          className="modal_login"
          style={{ zIndex: 111, width: "100%", height: "95vh", bottom: "-5px" }}
        >
          <div
            className="select_nw_modal"
            style={{ height: "100%", background: "#333" }}
          >
            <div className="modal_div1">
              <img
                className="forgot_main"
                src="/static/media/logo.c9b7a18fb586e344723a.png"
                alt=""
              />
            </div>
            {getrecordedvideo ? (
              <div>
                {recordedChunks.length > 0 && (
                  <div>
                    <p>Recorded Video:</p>
                    <video controls width="400">
                      <source
                        src={URL.createObjectURL(recordedChunks[0])}
                        type="video/webm"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                )}
              </div>
            ) : (
              <>
                <div
                  style={{
                    marginBottom: "14px",
                    width: "100%",
                    borderRadius: "18px",
                    overflow: "hidden",
                    height: "60vh",
                  }}
                >
                  <Webcam
                    // audio={true}
                    // audio={{
                    //   echoCancellation: true,
                    //   noiseSuppression: true,
                    //   autoGainControl: true,
                    // }}
                    // width={"500"}
                    // height={"400"}
                    width={"100%"}
                    height={"100%"}
                    videoConstraints={{ facingMode: "user" }}
                    ref={videoRef}
                    mirrored={true}
                    // videoConstraints={videoConstraints}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {recording ? (
                    <>
                      <div
                        style={{
                          width: "90%",
                          height: "60px",
                          borderRadius: "28px",
                          backgroundColor: "#69696971",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          position: "relative",
                        }}
                      >
                        <p
                          style={{
                            position: "absolute",
                            left: "10px",
                            top: "20px",
                            fontFamily: "var(--font-600)",
                            color: "white",
                            fontSize: "14px",
                          }}
                        >
                          {recordingTime} seconds
                        </p>
                        {/* <button
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                          color: "white",
                          alignSelf: "center",
                        }}
                        onClick={stopRecording}
                      >
                        Stop Recording
                      </button> */}
                        <img
                          onClick={stopRecording}
                          src={images.stop_circle}
                          style={{
                            height: "50px",
                            width: "50px",
                            objectFit: "contain",
                          }}
                          alt=""
                        />
                      </div>
                    </>
                  ) : (
                    <div
                      style={{
                        width: "90%",
                        height: "60px",
                        borderRadius: "28px",
                        backgroundColor: "#69696971",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {/* <button
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                          color: "white",
                          alignSelf: "center",
                        }}
                        onClick={startRecording}
                      >
                        Start Recording
                      </button> */}
                      <img
                        onClick={startRecording}
                        src={images.start_circle}
                        style={{
                          height: "50px",
                          width: "50px",
                          objectFit: "contain",
                        }}
                        alt=""
                      />
                    </div>
                  )}
                </div>
              </>
            )}

            <button
              onClick={() => {
                showimage();
              }}
            ></button>
            {/* <hr className="hr_forgot" /> */}
            <br />
            <div className="modal_div">
              <button
                className="close_forgot"
                onClick={() => {
                  showimage();
                  setBodyScrollEnabled(true);
                }}
              >
                Cancel
              </button>
              {/* <button
                style={{ marginLeft: 40 }}
                className="close_forgot"
                onClick={() => {
                  console.log("recordedChunks", recordedChunks.length);
                  // handleVideoChange()
                  setIsModalVisiblevideo(false);
                  // setRecordedChunks([]);
                }}
              >
                Submit
              </button> */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateProduct;
