import React from "react";
import "./ShowAllFamilyMember.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import NewLoader from "../../common/newloder/NewLoader";
import { FaAngleLeft } from "react-icons/fa6";
import images from "../../constants/images";
import { useEffect } from "react";
import { useProfileContext } from "../../context/profile_context";
import axios from "axios";
import {
  delete_user_family,
  get_legacy_milestone,
  get_year_bond,
} from "../../utils/Constant";
import { ToastContainer, toast } from "react-toastify";
import qs from "qs";

const ShowAllMilestones = () => {
  const userId = localStorage.getItem("user_id");
  const navigate = useNavigate();
  const location = useLocation();
  const [getalldata, setAllData] = useState([]);
  const [getallsearchdata, setAllSearchData] = useState([]);
  const [getloading, setLoading] = useState(false);
  const { getProfile } = useProfileContext();

  const GetMilestones = async () => {
    setLoading(true);
    const parama = {
      legacy_year: location.state.year,
      user_id: location.state.user_id,
      is_login: location.state.user_id == userId ? 1 : 0,
    };
    await axios
      .post(get_legacy_milestone, parama)
      .then((res) => {
        setLoading(false);
        console.log("result", res.data.milestone_data);
        if (res.data.status == 1) {
          setAllData(res.data.milestone_data);
          setAllSearchData(res.data.milestone_data);
        } else {
          toast.error(res.data.message, {
            theme: "colored",
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
      });
  };

  const searchMilestone = (text) => {
    const newData = getallsearchdata.filter(function (item) {
      const creator = item.milestone_name
        ? item.milestone_name.toUpperCase()
        : "".toLowerCase();

      const textData = text.toUpperCase();
      return creator.indexOf(textData) > -1;
    });
    setAllData(newData);
    const newData1 = getyeararray.filter(function (item) {
      const creator = item.product_name
        ? item.product_name.toUpperCase()
        : "".toLowerCase();

      const textData = text.toUpperCase();
      return creator.indexOf(textData) > -1;
    });
    setyeararray(newData1);
    if (text.length == 0) {
      GetProductDetail();
    }
    console.log("newData -=-=->", newData1);
  };

  useEffect(() => {
    GetMilestones();
  }, []);

  //   const GetProfile = async () => {
  //     setLoading(true);
  //     const param = {
  //       target_user_id: location.state.target_user_id,
  //       user_id: location.state.user_id,
  //     };
  //     console.log("param -->", param);

  //     const data = await getProfile(param);
  //     console.log("profile data ->", data.result);

  //     setAllData(data.result.user_family);
  //     setLoading(false);
  //   };

  //   const DeleteFamilyMamber = async (id) => {
  //     setLoading(true);
  //     const param = {
  //       user_family_id: id,
  //     };

  //     await axios
  //       .post(delete_user_family, param)
  //       .then((res) => {
  //         setLoading(false);
  //         if (res.data.status == 1) {
  //           GetProfile();
  //         }
  //       })
  //       .catch((err) => {
  //         setLoading(false);
  //       });
  //   };

  //   useEffect(() => {
  //     console.log("getalldata", getalldata);
  //   }, []);

  useEffect(() => {
    GetProductDetail();
  }, []);

  const [getyeararray, setyeararray] = useState([]);

  const GetProductDetail = async () => {
    setLoading(true);
    const param = {
      year: location.state?.year,
      // year: 2024,
      target_user_id: location.state.target_user_id,
      // user_milestone_id: location.state.user_milestone_id,
    };

    console.log("param --==-->", param);
    await axios
      .post(get_year_bond, qs.stringify(param))
      .then((res) => {
        setLoading(false);
        if (res.data.status === 1) {
          console.log("res.data-getallyeae-->11", res.data);
          setyeararray(res.data.result);
        } else {
          console.log("product view err ->", res.data);
          // setIsModalVisible(true);
          // setMsg(res.data.message);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <div className="new_wrapp_container reletive_position">
      {getloading ? (
        <NewLoader />
      ) : (
        <div className="new_container">
          <div
            className="play_screen_main_wrapp"
            style={{ padding: "10px 5%" }}
          >
            <div className="legacy_dashbord_nav_sec">
              <button
                onClick={() => {
                  navigate(-1);
                }}
              >
                <FaAngleLeft color="var(--color-white)" size={20} />
              </button>
              <p
                style={{ fontsize: "18px", width: "60%", textAlign: "center" }}
              >
                {location.state?.year}
              </p>
              <div></div>
            </div>

            {/* all member start */}

            {/* search bar start */}
            <div
              className="myproduct_13_user_bid_main"
              style={{ marginBottom: "0px" }}
            >
              <input
                type="text"
                placeholder="Search"
                className="myproduct_13_user_bid_search"
                onChange={(e) => searchMilestone(e.target.value)}
              />
            </div>

            {/* search bar end */}
            {getalldata && getalldata.length == 0 ? null : (
              <p
                style={{
                  color: "white",
                  fontFamily: "var(--font-600)",
                  marginLeft: "10px",
                  // background: "red",
                  alignSelf: "flex-start",
                  marginTop: "5px",
                  marginBottom: "5px",
                }}
              >
                Milestones
              </p>
            )}
            <div
              className="all_family_member_wrapp"
              style={{ marginTop: "10px" }}
            >
              {getalldata && getalldata.length > 0
                ? getalldata.map((item, index) => {
                    return (
                      <>
                        <div
                          onClick={() => {
                            navigate("/MilestoneDashboard", {
                              state: {
                                user_id: location.state.user_id,
                                user_milestone_id: item.user_milestone_id,
                              },
                            });
                          }}
                          className="legacy_milestone_single_card"
                          style={{ height: "150px" }}
                        >
                          <img
                            src={item.imageLink[0]?.imageLink}
                            alt=""
                            className="legacy_milestone_single_card_img"
                          />
                          <div className="legacy_milestone_single_card_blur_wrapp">
                            <p>{item.milestone_name}</p>
                          </div>
                        </div>
                        {/* <p>{item.legacy_year}</p> */}
                      </>
                    );
                  })
                : null}
            </div>
            {getyeararray && getyeararray.length == 0 ? null : (
              <p
                style={{
                  color: "white",
                  fontFamily: "var(--font-600)",
                  marginLeft: "10px",
                  // background: "red",
                  alignSelf: "flex-start",
                  marginTop: "5px",
                  marginBottom: "5px",
                }}
              >
                aucemo
              </p>
            )}
            <div
              className="all_family_member_wrapp"
              style={{ marginTop: "10px" }}
            >
              {getyeararray && getyeararray.length > 0
                ? getyeararray.map((item, index) => {
                    return (
                      <>
                        <div
                          onClick={() => {
                            navigate("/ProductView", {
                              state: {
                                release_bond_id: item.release_bond_id,
                                product_id: item.product_id,
                                is_nfb: item.is_nfb,
                                uniq_code: item.uniq_code,
                                theme_index: item.theme_index,
                                share_by_user: item.share_by_user,
                                club_id: item.club_id,
                              },
                            });
                          }}
                          className="legacy_milestone_single_card"
                          style={{ height: "150px" }}
                        >
                          <img
                            src={item.product_img_video[0]?.img_video}
                            alt=""
                            className="legacy_milestone_single_card_img"
                          />
                          <div className="legacy_milestone_single_card_blur_wrapp">
                            <p>{item.product_name}</p>
                          </div>
                        </div>
                        {/* <p>{item.legacy_year}</p> */}
                      </>
                    );
                  })
                : null}
            </div>

            {/* all member end */}
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default ShowAllMilestones;
