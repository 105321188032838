import React from "react";
import "./NewAssetCreatorRequestList.css";
import NavbarSec from "../../common/NavbarSec";
import images from "../../constants/images";
import axios from "axios";
import { get_bond_verify_request } from "../../utils/Constant";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NewLoader from "../../common/newloder/NewLoader";
import { useEffect } from "react";
import { useState } from "react";
import qs from "qs";
import { Link } from "react-router-dom";

const NewAssetCreatorRequestList = () => {
  const userId = localStorage.getItem("user_id");
  const [isLoading, setLoading] = useState(false);
  const [getdata, setdata] = useState([]);

  const RequestList = async () => {
    const param = {
      user_id: userId,
    };

    await axios
      .post(get_bond_verify_request, qs.stringify(param))
      .then((response) => {
        setLoading(false);
        if (response.data.status === 1) {
          setdata(response.data.all_request);
        } else {
          toast.error(response.data.message, { theme: "colored" });
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  useEffect(() => {
    RequestList();
  }, []);

  if (isLoading) {
    return <NewLoader />;
  }

  return (
    <div className="new_wrapp_container reletive_position">
      <div className="new_container">
        <NavbarSec name="Request List" />

        <div className="newassetcreator_request_cards_wrapp">
          {getdata && getdata.length > 0
            ? getdata.map((item, index) => {
                return (
                  <div key={index} className="newassetcreator_request_card">
                    <div className="newassetcreator_request_card_img_wrapp">
                      <img src={item.profile} alt="" />
                    </div>
                    <div className="newassetcreator_request_card_name_wrapp">
                      <p>{item.user_name}</p>
                      {/* <span>{item.time}</span> */}
                      <span>{moment(item.time).calendar()}</span>
                    </div>
                    <Link
                      to={"/NewAssetCreatorScreen"}
                      state={{ data: item }}
                      className="newassetcreator_request_card_btn"
                    >
                      View Details
                    </Link>
                  </div>
                );
              })
            : null}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default NewAssetCreatorRequestList;
