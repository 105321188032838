import React, { useState } from "react";
import images from "../../constants/images";
import "./ProductView2.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import BackButton from "../../components/BackButton";
import { useRef } from "react";
import axios from "axios";
import { useEffect } from "react";
import {
  add_release_bond_whishlist,
  delete_whishlist,
  get_single_product_bond_detail,
  get_single_product_bond_detail_user,
  release_bond_like,
  user_adhar_is_verify,
} from "../../utils/Constant";
import qs from "qs";
import ProductCardViewsecond from "../../components/productcard/ProductCardViewsecond";
import ProductCardview from "../../components/productcard/ProductCardview";
import Productcardthree from "../../components/productcard/Productcardthree";
import AlertModal from "../../components/AlertModal";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Productviewcardone from "./Productviewcardone";
import Productviewcardtwo from "./Productviewcardtwo";
import Productviewcardfour from "./Productviewcardfour";
import Productviewcardfive from "./Productviewcardfive";
import Productviewcardsix from "./Productviewcardsix";
import Productviewcardseven from "./Productviewcardseven";
import Productviewcardeight from "./Productviewcardeight";
import Productviewcard13 from "./Productviewcard13";
import Productviewcard14 from "./Productviewcard14";
import Productviewcard15 from "./Productviewcard15";
import NewModal from "../../common/newmodal/NewModal";
import ProductNewFrame1 from "../../frames/productframes/ProductNewFrame1";
import ProductNewFrame2 from "../../frames/productframes/ProductNewFrame2";
import ProductNewFrame3 from "../../frames/productframes/ProductNewFrame3";
import ProductNewFrame4 from "../../frames/productframes/ProductNewFrame4";
import ProductNewFrame5 from "../../frames/productframes/ProductNewFrame5";
import ProductNewFrame6 from "../../frames/productframes/ProductNewFrame6";
import ProductNewFrame7 from "../../frames/productframes/ProductNewFrame7";
import ProductNewFrame8 from "../../frames/productframes/ProductNewframe8";
import ProductNewFrame9 from "../../frames/productframes/ProductNewFrame9";
import ProductNewFrame10 from "../../frames/productframes/ProductNewFrame10";
import V2Modal from "../../common/v2modal/V2Modal";
import { RiVerifiedBadgeFill } from "react-icons/ri";

const ProductView2 = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // video
  const videoRef = useRef(null);
  const userId = localStorage.getItem("user_id");
  const [showVideo, setShowVideo] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [msg, setMsg] = useState("");
  const [getmodalName, setModalName] = useState("");
  const [data, setData] = useState({});
  const [accept_bond_bid_id, setaccept_bond_bid_id] = useState("");
  const [release_bond_id, setrelease_bond_id] = useState("");
  const [current_highest_bid, setcurrent_highest_bid] = useState("");
  const [uniq_code, setuniq_code] = useState("");
  const [is_nfb, setis_nfb] = useState("");
  const [total_available_bond_bid, settotal_available_bond_bid] = useState("");
  const [minimum_bid_amount, setminimum_bid_amount] = useState("");
  const [product_id, setproduct_id] = useState("");
  const [partner_bond_benifit_data, setpartner_bond_benifit_data] =
    useState("");
  const [product_name, setproduct_name] = useState("");
  const [is_club_member, setis_club_member] = useState("");
  const [is_club_id, setis_club_id] = useState("");
  const [is_globle, setis_globle] = useState("");
  const [is_user_owner, setis_user_owner] = useState("");
  const [getproducthashtag, setProductHashtag] = useState([]);
  const [getuserLike, setUserLike] = useState(0);
  const [is_adhar, setIs_adhar] = useState(null);
  const [getaddharModal, setaddharModal] = useState(false);
  const [getwishlist, setwishlist] = useState("");
  const [getuserid, setuserid] = useState("");
  const [getclubid, setclubid] = useState("");
  const [is_club, setIsClub] = useState("");
  const [join_club_product, setjoin_club_product] = useState("");
  const [share_by_user, setshare_by_user] = useState("");
  const [getmediaLinks, setMediaLinks] = useState([]);
  const [getpurchased_amount, setPurchased_amount] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const savedScrollPosition = sessionStorage.getItem("scrollPosition");
    if (savedScrollPosition) {
      window.scrollTo(0, parseInt(savedScrollPosition, 10));
      sessionStorage.removeItem("scrollPosition");
      console.log("first", parseInt(savedScrollPosition, 10));
    }

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const closemodal = () => {
    setIsModalVisible(false);
  };
  const openmodal = () => {
    setIsModalVisible(true);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const GetProductDetail = async () => {
    setIsLoading(true);

    const param = {
      user_id: location.state?.user_id ? location.state.user_id : userId,
      release_bond_id: location.state.release_bond_id,
      product_id: location.state.product_id,
      uniq_code: location.state.uniq_code,
      is_nfb: location.state.is_nfb,
      accept_bond_bid_id: location.state.accept_bond_bid_id,
      share_by_user: location.state.share_by_user,
    };

    console.log("param --==-->", param);

    await axios
      .post(get_single_product_bond_detail_user, qs.stringify(param))
      .then((res) => {
        setIsLoading(false);
        if (res.data.status === 1) {
          console.log("res.data", res.data);
          const productHastags =
            (res.data.result && res.data.result.product_hashtag) || "";
          const data = res.data.result;
          console.log("Asset", JSON.stringify(data, null, 2));
          setwishlist(data.is_wishlist);
          setData(res.data.result);
          setPurchased_amount(res.data.purchased_amount);
          setaccept_bond_bid_id(data.accept_bond_bid_id);
          setrelease_bond_id(data.release_bond_id);
          setcurrent_highest_bid(data.current_highest_bid);
          setuniq_code(data.uniq_code);
          setis_nfb(data.is_nfb);
          setUserLike(data.user_like);
          settotal_available_bond_bid(data.total_available_bond_bid);
          setminimum_bid_amount(data.minimum_bid_amount);
          setproduct_id(data.product_id);
          // setpartner_bond_benifit_data(data.product_id);
          setpartner_bond_benifit_data(data.partner_bond_benifit_data);
          setproduct_name(data.product_name);
          setis_club_member(data.is_club_member);
          setis_club_id(data.is_club_id);
          setis_globle(data.globle_exclusive);
          setis_user_owner(data.is_user_owner);
          setProductHashtag(productHastags.split(" "));
          setuserid(data.owner_id);
          setclubid(data.is_club_id);
          setIsClub(data.is_club);
          setjoin_club_product(data.join_club_product);
          setshare_by_user(data.share_by_user);
          setMediaLinks(data.social_media_link);
          console.log("product view response ->", data.owner_list_data);
        } else {
          console.log("product view err ->", res.data);
          setIsModalVisible(true);
          setMsg(res.data.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  // adhar verify api start

  const isAdharVerified = async () => {
    const param = {
      user_id: location.state?.user_id ? location.state.user_id : userId,
    };

    await axios
      .post(user_adhar_is_verify, qs.stringify(param))
      .then((Response) => {
        setIsLoading(false);
        if (Response.data.status == 1) {
          setIs_adhar(1);
        } else {
          setIs_adhar(0);
        }
      })
      .catch((error) => {
        console.log("user_adhar_is_verify errr", error);
        setIs_adhar(0);
      });
  };

  // adhar verify api end

  const BondLike = async (release_bond_id, user_id, is_nfb) => {
    const param = {
      release_bond_id: release_bond_id,
      user_id: user_id,
      is_nfb: is_nfb,
    };

    try {
      const response = await axios.post(release_bond_like, qs.stringify(param));
      console.log("response.data", response.data);
      GetProductDetail();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleImageClick = () => {
    setShowVideo(true);
  };

  const handleVideoClick = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  };

  const handleVideoEnded = () => {
    setShowVideo(false);
    videoRef.current.currentTime = 0;
  };

  // video
  // api
  const currentUrl = window.location.href;
  const urlParts = currentUrl.split("/");
  const code = urlParts.slice(-2)[0];

  // images slider end

  // wishlist start

  const AddWishlist = () => {
    const param = {
      release_bond_id: location.state.release_bond_id,
      product_id: location.state.product_id,
      is_club: data.is_club,
      user_id: location.state?.user_id ? location.state.user_id : userId,
      is_nfb: location.state.is_nfb,
      uniq_code: location.state.uniq_code,
    };
    console.log("addparam--->", param);
    axios
      .post(add_release_bond_whishlist, param)
      .then((Response) => {
        console.log("====================================");
        console.log("Respons", JSON.stringify(Response.data, null, 2));
        console.log("====================================");
        if (Response.data.status == 1) {
          GetProductDetail();
        } else {
          console.log("add_release_bond_whishlist err", Response.data);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const DeleteWishlist = () => {
    const param = {
      whishlist_id: data.wishlist_id,
    };
    console.log("removeparam", param);
    axios
      .post(delete_whishlist, param)
      .then((Response) => {
        console.log("====================================");
        console.log("Respons", JSON.stringify(Response.data, null, 2));
        console.log("====================================");
        if (Response.data.status == 1) {
          GetProductDetail();
        } else {
          console.log("delete_whishlist err", Response.data);
        }
      })
      .catch((err) => {
        console.error("delete_whishlist errr", err);
      });
  };

  // wishlist end

  useEffect(() => {
    GetProductDetail();
    isAdharVerified();
    // console.log("--> uniq_code", location.state.uniq_code);
  }, []);
  // api

  // copy link functionality start

  const handleCopyLink = (link) => {
    const el = document.createElement("textarea");
    el.value = link;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    alert("Link copied to clipboard!");
  };

  const handleShare = async () => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: "aucemo.club",
          text: data.getqr_code_data,
          url: data.getqr_code_data,
        });
      } else {
        // Fallback for browsers that do not support Web Share API
        // You can implement a custom sharing solution here
        alert("Web Share API not supported on this browser.");
      }
    } catch (error) {
      console.error("Error sharing:", error.message);
    }
  };

  // copy link functionality end

  return (
    <div className="productview_main" style={{ position: "relative" }}>
      <div className="productview_2">
        <div className="productview_header">
          <div className="productview_main_header_notifi">
            <div className="productviewmain1">
              <BackButton />
            </div>
          </div>
        </div>
        {/* product view 2 slider start */}
        {/* <Slider {...settings}>
          {data.product_image_video && data.product_image_video.length > 0
            ? data.product_image_video.map((item, index) => {
                return (
                  <> */}
        {/* <div className="show_preview_main">
                      <img
                        src={item.img_video}
                        className="show_preview_i"
                        alt=""
                      />
                      <div className="del_ofshow">
                        <div className="del_ofshow1">
                          <p>{data.product_name} </p>
                          <p>{data.username}</p>
                        </div>
                        <div className="del_ofshow2">
                          <p>Dt: {data.created_at}</p>
                        </div>
                      </div>
                    </div> */}
        {/* <div className="show_preview_main">
                      <img
                        src={item.img_video}
                        className="show_preview_i"
                        alt=""
                      />
                      <div className="del_ofshow">
                        <b className="del_pro_nm_vi">{data.product_name} </b>
                        <div className="del_ofshow1">
                          <p className="del_pro_nm_vi2">{data.username}</p>
                        </div>
                        <div className="del_ofshow2">
                          <p className="del_pro_nm_vi3">
                            Dt: {data.created_at}
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })
            : null}
        </Slider> */}

        <div
          className="product_user_nm"
          style={{ margin: "10px auto", alignItems: "center" }}
          onClick={() => {
            if (getclubid == "" || getclubid == 0 || getclubid == null) {
              // this.props.navigation.navigate('UserProfile', {
              //   user_id: this.state.owner_id,
              // });
              // navigate("/UserProfile", {
              //   state: {
              //     user_id: data.user_id,
              //   },
              // });
              navigate(`/user/${data.owner_user_id}`);
            } else {
              navigate(`/`);
            }
          }}
        >
          <img src={data.user_profile} className="product_user_nm_img" alt="" />
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "center",
              flexDirection: "column",
              maxWidth: "60%",
              fontSize: "18px",
              marginRight: "10px",
            }}
          >
            <p className="product_user_nm_p" style={{}}>
              {data.username}
            </p>
            <p
              style={{
                color: "var(--color-main)",
                fontFamily: "var(--font-400)",
                fontSize: "12px",
                marginLeft: "10px",
              }}
            >
              {data.main_product_rank} buyer
            </p>
          </div>
          {data.is_verify == 1 ? (
            <RiVerifiedBadgeFill size={20} color="gold" />
          ) : null}
        </div>

        <div className="card_new_sub_main">
          {/* {location.state.theme_index == 6 ? (
            <Productviewcardone
              item={data}
              setIsModalOpen={setIsModalVisible}
              setMsg={setMsg}
              setModalName={setModalName}
            />
          ) : location.state.theme_index == 7 ? (
            <Productviewcardtwo
              item={data}
              setIsModalOpen={setIsModalVisible}
              setMsg={setMsg}
              setModalName={setModalName}
            />
          ) : location.state.theme_index == 8 ? (
            <Productviewcardfour
              item={data}
              setIsModalOpen={setIsModalVisible}
              setMsg={setMsg}
              setModalName={setModalName}
            />
          ) : location.state.theme_index == 9 ? (
            <Productviewcardfive
              item={data}
              setIsModalOpen={setIsModalVisible}
              setMsg={setMsg}
              setModalName={setModalName}
            />
          ) : location.state.theme_index == 10 ? (
            <Productviewcardsix
              item={data}
              setIsModalOpen={setIsModalVisible}
              setMsg={setMsg}
              setModalName={setModalName}
            />
          ) : location.state.theme_index == 11 ? (
            <Productviewcardseven
              item={data}
              setIsModalOpen={setIsModalVisible}
              setMsg={setMsg}
              setModalName={setModalName}
            />
          ) : location.state.theme_index == 12 ? (
            <Productviewcardeight
              item={data}
              setIsModalOpen={setIsModalVisible}
              setMsg={setMsg}
              setModalName={setModalName}
            />
          ) : location.state.theme_index == 13 ? (
            <Productviewcard13
              item={data}
              setIsModalOpen={setIsModalVisible}
              setMsg={setMsg}
              setModalName={setModalName}
            />
          ) : location.state.theme_index == 14 ? (
            <Productviewcard14
              item={data}
              setIsModalOpen={setIsModalVisible}
              setMsg={setMsg}
              setModalName={setModalName}
            />
          ) : location.state.theme_index == 15 ? (
            <Productviewcard15
              item={data}
              setIsModalOpen={setIsModalVisible}
              setMsg={setMsg}
              setModalName={setModalName}
            />
          ) :  */}
          {location.state.theme_index == 16 ? (
            <ProductNewFrame1
              item={data}
              setIsModalOpen={setIsModalVisible}
              setMsg={setMsg}
              setModalName={setModalName}
              windowWidth={windowWidth}
              userId={data.user_id}
            />
          ) : location.state.theme_index == 17 ? (
            <ProductNewFrame2
              item={data}
              setIsModalOpen={setIsModalVisible}
              setMsg={setMsg}
              setModalName={setModalName}
              windowWidth={windowWidth}
              userId={data.user_id}
            />
          ) : location.state.theme_index == 18 ? (
            <ProductNewFrame3
              item={data}
              setIsModalOpen={setIsModalVisible}
              setMsg={setMsg}
              setModalName={setModalName}
              windowWidth={windowWidth}
              userId={data.user_id}
            />
          ) : location.state.theme_index == 19 ? (
            <ProductNewFrame4
              item={data}
              setIsModalOpen={setIsModalVisible}
              setMsg={setMsg}
              setModalName={setModalName}
              windowWidth={windowWidth}
              userId={data.user_id}
            />
          ) : location.state.theme_index == 20 ? (
            <ProductNewFrame5
              item={data}
              setIsModalOpen={setIsModalVisible}
              setMsg={setMsg}
              setModalName={setModalName}
              windowWidth={windowWidth}
              userId={data.user_id}
            />
          ) : location.state.theme_index == 21 ? (
            <ProductNewFrame6
              item={data}
              setIsModalOpen={setIsModalVisible}
              setMsg={setMsg}
              setModalName={setModalName}
              windowWidth={windowWidth}
              userId={data.user_id}
            />
          ) : location.state.theme_index == 22 ? (
            <ProductNewFrame7
              item={data}
              setIsModalOpen={setIsModalVisible}
              setMsg={setMsg}
              setModalName={setModalName}
              windowWidth={windowWidth}
              userId={data.user_id}
            />
          ) : location.state.theme_index == 23 ? (
            <ProductNewFrame8
              item={data}
              setIsModalOpen={setIsModalVisible}
              setMsg={setMsg}
              setModalName={setModalName}
              windowWidth={windowWidth}
              userId={data.user_id}
            />
          ) : location.state.theme_index == 24 ? (
            <ProductNewFrame9
              item={data}
              setIsModalOpen={setIsModalVisible}
              setMsg={setMsg}
              setModalName={setModalName}
              windowWidth={windowWidth}
              userId={data.user_id}
            />
          ) : location.state.theme_index == 25 ? (
            <ProductNewFrame10
              item={data}
              setIsModalOpen={setIsModalVisible}
              setMsg={setMsg}
              setModalName={setModalName}
              windowWidth={windowWidth}
              userId={data.user_id}
            />
          ) : (
            <ProductNewFrame1
              item={data}
              setIsModalOpen={setIsModalVisible}
              setMsg={setMsg}
              setModalName={setModalName}
              windowWidth={windowWidth}
              userId={data.user_id}
            />
          )}
        </div>
        {/* product view 2 slider end */}
        <div
          className="productview_club_user"
          style={{ width: "100%", padding: "0px" }}
        >
          <div className="view_like_pro_2" style={{ margin: "25px auto" }}>
            {/* <button
              onClick={() => {
                // BondLike(data.release_bond_id, data.user_id, data.is_nfb)
                const param = {
                  release_bond_id: location.state.release_bond_id,
                  user_id: userId,
                  is_nfb: is_nfb,
                };
                console.log("param", param);
                axios
                  .post(
                    "https://www.ravi.host/api/release_bond_like",
                    qs.stringify(param)
                  )
                  .then((Response) => {
                    //  this._getData();
                    //  this._getsingledata();
                    GetProductDetail();
                    console.log("====================================");
                    console.log("Re", Response.data);
                    console.log("====================================");
                  });
              }}
              style={{
                color: getuserLike == 1 ? "#e51a4b" : "gray",
              }}
              className="pd_share_1"
            >
              <img
                src={getuserLike == 1 ? images.like_true : images.like}
                className="pd_share_img"
                alt=""
              />
              <p
                style={{
                  color: getuserLike == 1 ? "#e51a4b" : "gray",
                }}
                className="pd_nm"
              >
                Like
              </p>
            </button> */}
            <div className="pd_share_2">
              <img src={images.sharenfb} className="pd_share_img" alt="" />
              <p className="pd_nm">Share</p>
            </div>
          </div>
          {/* <Cardviewsecond end/> */}
          {/* <div className="detsil_product_nm2">
            <div className="product_del_part1">
              <p className="product_del_part_sub1">Next Release Date</p>
              <h1 className="product_del_part_sub2">{data.commitment_date}</h1>
            </div>
            <div className="product_del_part2">
              <p className="product_del_part_sub1">Released NFBs</p>
              <h1 className="product_del_part_sub2">
                {" "}
                {data.total_available_bond_bid} out of {data.bond_value}
              </h1>
            </div>
          </div>
          <div className="detsil_product_nm2">
            <div className="product_del_part1">
              <p className="product_del_part_sub1">NFBs Base Value</p>
              <h1 className="product_del_part_sub2">
                ₹{data.product_base_value}
              </h1>
            </div>
            <div className="product_del_part2">
              <p className="product_del_part_sub1">Minimum bid</p>
              <h1 className="product_del_part_sub2">
                ₹{data.minimum_bid_amount}
              </h1>
            </div>
          </div> */}
          <div className="pv2_productvalue_wrapp">
            <div className="pv2_productvalue_single_card">
              <p className="pv2_productvalue_value">
                ₹{data.product_base_value}
              </p>
              <p className="pv2_productvalue_name">aucemo Value</p>
            </div>
            <div className="pv2_productvalue_single_card">
              <p className="pv2_productvalue_value">₹{getpurchased_amount}</p>
              <p className="pv2_productvalue_name">Purchased Amount</p>
            </div>
          </div>
          {/* trade history start */}

          {/* {data.owner_list_data && data.owner_list_data.length > 0 && ( */}
          <>
            <div className="product_story1">
              <div className="product_view_hr"></div>
              <h1 className="pv_titel">
                Trade <br />
                History
              </h1>
            </div>
            {data.owner_list_data && data.owner_list_data.length > 0 ? (
              data.owner_list_data.map((item, index) => {
                // console.log("trade_history_data", data.owner_list_data);
                return (
                  <div
                    className="trad_hist_siglecard"
                    style={{ border: index == 0 ? "1px solid gold" : "" }}
                    onClick={() => {
                      navigate("/UserProfile", {
                        state: {
                          user_id: item.buyer_id,
                        },
                      });
                    }}
                    key={index}
                  >
                    <div className="th_img_wrapp">
                      <span className="list_number_pv">{index + 1}</span>
                      <img
                        src={item.buyer_profile}
                        className="list_img_of_resell"
                        alt=""
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <h1 className="th_buyer_name">{item.buyer}</h1>
                      <p
                        style={{
                          fontSize: "11px",
                          fontFamily: "var(--font-300)",
                          textAlign: "center",
                        }}
                      >
                        {item.date_time}
                      </p>
                    </div>

                    <p className="th_price">
                      ₹ {item.get_amount}/-{"  "}
                    </p>
                  </div>
                );
              })
            ) : (
              <div style={{ width: "100%", textAlign: "center" }}>
                <p
                  style={{
                    color: "#fff",
                    fontSize: "12px",
                    fontFamily: "var(--font-400)",
                  }}
                >
                  Place your bid now to become the proud first owner!
                </p>
              </div>
            )}
          </>
          {/* )} */}
          {data.owner_list_data && data.owner_list_data.length > 4 && (
            <Link
              style={{
                padding: "10px 20px",
                background: "var(--color-main)",
                color: "#fff",
                fontFamily: "var(--font-600)",
                fontSize: "14px",
                alignSelf: "flex-start",
                marginTop: "10px",
                borderRadius: "28px",
              }}
              onClick={() => {
                console.log("release_bond_id", location.state.release_bond_id);
                console.log("product_id", location.state.product_id);
                console.log("uniq_code", location.state.uniq_code);
                console.log("user_id", userId);
                console.log("is_nfb", location.state.is_nfb);
              }}
              to={"/Tradehistory"}
              state={{
                release_bond_id: location.state.release_bond_id,
                product_id: location.state.product_id,
                uniq_code: location.state.uniq_code,
                // uniq_code: uniq_code,
                is_nfb: location.state.is_nfb,
                userId: location.state?.user_id
                  ? location.state.user_id
                  : userId,
              }}
            >
              View All
            </Link>
          )}

          {/* trade history end */}
          {/* Highlights start */}
          {data.product_highlights == "" ||
          data.product_highlights === null ||
          data.product_highlights === undefined ? null : (
            <>
              <div className="product_story">
                <h1 className="pv_titel">Highlights</h1>
                <div className="product_view_hr"></div>
              </div>
              <p className="ser_p_produuct">{data.product_highlights}</p>
            </>
          )}
          {/* Highlights end */}
          {/* product story start */}
          {/* <p
            className="set_p_product"
            style={{ fontFamily: "var(--font-600)", fontSize: "18px" }}
          >
            Asset Story
          </p> */}
          {/* {data.user_product_history && data.user_product_history !== "" ? (
            <>
              <div className="product_story1">
                <h1 className="pv_titel" style={{ textAlign: "justify" }}>
                  Asset Story <br />
                </h1>
                <div className="product_view_hr"></div>
              </div>
              <div className="product_story_del">
                <p className="p_v_details">{data.user_product_history}</p>
              </div>
            </>
          ) : null} */}
          {/* product story end */}

          {/* phisical asset section start */}

          <div className="product_view_phisical_sec_wrapp">
            <p className="product_view_gradinat_text_heading">
              {data.product_name?.slice(0, 24)}
              {data.product_name?.length > 24 ? "..." : null}
            </p>
            <div className="phisical_asset_box_gradient_wrapp">
              <div className="phisical_asset_box_inner_wrapp">
                {/* image */}

                {data.product_image_video &&
                data.product_image_video.length > 0 ? (
                  <img
                    src={data.product_image_video[0].img_video}
                    alt="product img"
                    onClick={() => {
                      navigate("/ProductViewImg", {
                        state: {
                          img: data.product_image_video
                            ? data.product_image_video
                            : "",
                        },
                      });
                    }}
                    style={{ cursor: "pointer" }}
                  />
                ) : (
                  <img
                    src={
                      data.product_image_video
                        ? data.product_image_video
                            .filter((item) => item.image_type == "new")
                            .map((item) => item.img_video)[0] || null
                        : (data.bond_media_upload &&
                            data.bond_media_upload
                              .filter((item) => item.image_type == "new")
                              .map((item) => item.img_video)[0]) ||
                          null
                    }
                    alt="product img"
                    onClick={() => {
                      navigate("/ProductViewImg", {
                        state: {
                          img: data.product_image_video
                            ? data.product_image_video
                            : "",
                        },
                      });
                    }}
                    style={{ cursor: "pointer" }}
                  />
                )}

                {/* phisical story */}

                {data.user_product_history == "" ||
                data.user_product_history === null ||
                data.user_product_history === undefined ? null : (
                  <div className="phisical_asset_story_wrapp">
                    <p className="phisical_asset_story_heading">
                      Story Statement
                    </p>

                    <p className="p_v_details">{data.user_product_history}</p>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* phisical asset section end */}

          {/* product introduction start */}
          <>
            <div className="product_story">
              <div className="product_view_hr"></div>
              <h1 className="pv_titel">
                Authentication <br /> Video
              </h1>
            </div>
            <div className="pv_video_shows">
              {!showVideo ? (
                <>
                  <img
                    src={
                      data.bond_thumbnail == ""
                        ? images.thumbnail
                        : data.bond_thumbnail
                    }
                    className="video_bg_pv"
                    alt="Video Thumbnail"
                  />
                  <img
                    src={images.video}
                    className="video_plays"
                    onClick={handleImageClick}
                    alt=""
                  />
                </>
              ) : (
                <video
                  poster={data.bond_thumbnail}
                  ref={videoRef}
                  className="video_player_pv"
                  autoPlay
                  controls
                  onClick={handleVideoClick}
                  onEnded={handleVideoEnded}
                  src={data.bond_video}
                >
                  {/* <source
                src={data.bond_video}
                type="video/mp4" className="video_link"
              /> */}
                  {/* Add additional source tags for other video formats if necessary */}
                </video>
              )}
              {showVideo && (
                <img
                  src={images.video}
                  className="video_play1_pv"
                  onClick={handleVideoClick}
                  alt=""
                />
              )}
            </div>
          </>
          {/* product introduction end */}

          {/* cirtificate start */}
          {data.owner_proof ==
            "https://www.ravi.host/public/assets/club_owner_proof/" ||
          data.owner_proof ==
            "https://www.ravi.host/public/assets/owner_proof/" ||
          data.owner_proof == "" ? null : (
            <>
              <div className="product_story">
                <h1 className="pv_titel">Certificate</h1>
                <div className="product_view_hr"></div>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src={data.owner_proof}
                  style={{
                    width: "100%",
                    // height: "100px",
                    minWidth: "200px",
                    height: "200px",
                  }}
                  alt=""
                  onClick={() => {
                    navigate("/CirtificateViewImg", {
                      state: {
                        img: data.owner_proof ? data.owner_proof : "",
                      },
                    });
                  }}
                />
              </div>
            </>
          )}
          {/* cirtificate end */}

          {/* physical start */}

          {(data.physical_owner_name !== "" ||
            data.physical_asset_address !== "") && (
            <div style={{ width: "100%" }}>
              <div className="product_story">
                <div className="product_view_hr"></div>
                <h1 className="pv_titel">Physical Details</h1>
              </div>
              {/* owner name */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  margin: "10px auto",
                }}
              >
                {data.physical_owner_name && data.physical_owner_name !== "" ? (
                  <div className="phisical_asset_owner_name_wrapp">
                    <div>
                      <img src={images.new_profile_icon} alt="" />
                    </div>
                    <p>{data.physical_owner_name}</p>
                  </div>
                ) : null}

                {/* location */}
                {data.physical_asset_address &&
                data.physical_asset_address !== "" ? (
                  <div className="phisical_asset_owner_name_wrapp">
                    <div>
                      <img src={images.new_location_icon} alt="" />
                    </div>
                    <p>{data.physical_asset_address}</p>
                  </div>
                ) : null}
              </div>
            </div>
          )}

          {/* physical end */}

          {/* milestone start */}
          {data.user_milestone_id !== "" &&
            data.user_milestone_id !== null &&
            data.user_milestone_id !== undefined && (
              <>
                <div className="product_story">
                  <h1 className="pv_titel">Milestone</h1>
                  <div className="product_view_hr"></div>
                </div>

                <Link
                  className="productview_milestone_wrapp"
                  to="/MilestoneDashboard"
                  state={{
                    user_id: data.user_id,
                    user_milestone_id: data.user_milestone_id,
                  }}
                >
                  <img
                    src={
                      data.mile_image_link && data.mile_image_link[0]?.imageLink
                    }
                    alt=""
                  />
                  <div>
                    <div>
                      <p>{data.milestone_name}</p>
                    </div>
                  </div>
                </Link>
              </>
            )}
          {/* milestone end */}

          {/* Hashtags start */}
          {getproducthashtag && getproducthashtag.length > 0 && (
            <>
              <div className="product_story">
                <div className="product_view_hr"></div>
                <h1 className="pv_titel">Hashtags</h1>
              </div>

              <div className="hashtags_list">
                {getproducthashtag.map((item, index) => {
                  console.log("getproducthashtag", getproducthashtag);
                  return (
                    <p
                      style={{
                        backgroundColor:
                          index % 2 === 0 ? "#c04e57" : "#7a5555",
                        padding: "4px 16px",
                        // marginRight: "8px",
                        borderRadius: "20px",
                        fontFamily: "var(--font-i)",
                        fontSize: "12px",
                      }}
                      key={index}
                    >
                      {item}
                    </p>
                  );
                })}
              </div>
            </>
          )}
          {/* </div> */}
          {/* Hashtags end */}

          {/* benefits start */}
          {/* {data.partner_bond_benifit_data?.length !== 0 ||
            (data.product_bond_benifit_detail?.length !== 0 && ( */}
          <>
            {data.partner_bond_benifit_data?.length !== 0 ||
              (data.product_bond_benifit_detail?.length !== 0 && (
                <div className="product_story">
                  <h1 className="pv_titel">Benefits</h1>
                  <div className="product_view_hr"></div>
                </div>
              ))}
            {data.partner_bond_benifit_data &&
              data.partner_bond_benifit_data.length > 0 && (
                <>
                  {/* <br /> */}
                  <p
                    className="set_p_product"
                    style={{ fontFamily: "var(--font-600)", fontSize: "18px" }}
                  >
                    Partner Benefits
                  </p>
                  <div className="partner_b_set">
                    <img
                      src={data.partner_bond_benifit_data[0].benifit_icon}
                      className="partner_b_set_img"
                      alt=""
                    />
                    <div className="partner_b_set_details">
                      <p className="nm_pr_set1">
                        {data.partner_bond_benifit_data[0].benifit_name}
                      </p>
                      <p className="nm_pr_set2">
                        {data.partner_bond_benifit_data[0].partner_name}
                      </p>
                    </div>
                    <Link
                      className="partner_b_set_view"
                      to={"/ViewBenefits"}
                      state={{
                        partner: 1,
                        is_nfb: data.is_nfb,
                        partner_benifit_id:
                          data.partner_bond_benifit_data[0].partner_benifit_id,
                      }}
                    >
                      View
                    </Link>
                  </div>{" "}
                </>
              )}
            <br />
            {data.product_bond_benifit_detail &&
              data.product_bond_benifit_detail.length > 0 && (
                <>
                  <p
                    className="set_p_product"
                    style={{ fontFamily: "var(--font-600)", fontSize: "18px" }}
                  >
                    User Benefits
                  </p>
                  {data.product_bond_benifit_detail.map((item, index) => {
                    return (
                      <div className="user_benefit_new">
                        <div className="user_benefit_new_sub1">
                          <div className="user_benefit_img">
                            <img
                              src={item.reward_img_video[0].img_video}
                              className="user_benefit_img_sub"
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="user_benefit_new_sub2">
                          <b> {item.banifit_name}</b>
                          <b> {item.reward_name}</b>
                          <div className="benefit_hr_set"></div>
                          <Link
                            className="view_of_pr_benefit"
                            to={"/ViewBenefits"}
                            state={{
                              partner: 0,
                              is_nfb: data.is_nfb,
                              pro_bo_ben_id: item.pro_bo_ben_id,
                            }}
                          >
                            {" "}
                            View
                          </Link>
                        </div>
                      </div>
                    );
                  })}
                </>
              )}
          </>
          {/* ))} */}
          {/* benefits end */}

          {/* media start */}
          {data.bond_media_upload && data.bond_media_upload.length > 0 && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "10px",
                width: "100%",
              }}
            >
              <div className="product_story">
                <div className="product_view_hr"></div>
                <h1 className="pv_titel">Media</h1>
              </div>
              <div>
                {data.bond_media_upload.map((item, index) => {
                  console.log("media", item);
                  return (
                    <img
                      src={item.img_video}
                      style={{ width: "150px", height: "150px" }}
                      alt=""
                    />
                  );
                })}
              </div>
            </div>
          )}
          {/* media end */}
          {/* media links start */}
          {getmediaLinks && getmediaLinks.length > 0 ? (
            <>
              <div className="product_story">
                <h1 className="pv_titel">Media Link</h1>
                <div className="product_view_hr"></div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "6px",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }}
              >
                {getmediaLinks && getmediaLinks.length > 0
                  ? getmediaLinks.map((item, index) => {
                      console.log("item", item);
                      return (
                        <div key={index} className="ml_wrapp">
                          <a
                            href={item.media_link}
                            target="_blank"
                            className="ml_link_text"
                          >
                            <p>{item.media_link}</p>
                          </a>
                          <button
                            className="ml_copy_btn"
                            onClick={() => handleCopyLink(item.media_link)}
                          >
                            Copy
                          </button>
                        </div>
                      );
                    })
                  : null}
              </div>
            </>
          ) : null}
          {/* media links end */}
        </div>
        <div className="product_story">
          <div className="product_view_hr"></div>
          <h1 className="pv_titel">Creator</h1>
        </div>
        <div
          className="productview_club_user"
          style={{ width: "100%", padding: "0px", margin: "1rem auto" }}
        >
          {/* <div className="hasttagnew_pro" > */}
        </div>
        <div
          className="creator_details"
          onClick={() => {
            if (
              data.owner_id !== "" ||
              data.owner_id !== 0 ||
              data.owner_id !== null
            ) {
              // this.props.navigation.navigate('UserProfile', {
              //   user_id: this.state.owner_id,
              // });
              navigate("/UserProfile", {
                state: {
                  user_id: data.owner_id,
                },
              });
            } else {
            }
          }}
        >
          <div
            className="creator_details_details"
            style={{ width: "90%", margin: "10px auto" }}
          >
            <p
              className="set_p_product"
              style={{
                fontFamily: "var(--font-700)",
                fontSize: "18px",
                color: "var(--color-black)",
              }}
            >
              Creator
            </p>
            <p style={{ color: "#000" }}>{data.owner_bio}</p>
          </div>
          <img
            src={data.owner_profile}
            className="creator_details_img"
            alt=""
          />
          <div className="creator_details_sub">
            <h1 className="creator_details_sub_h1">{data.owner_name}</h1>
            <p className="creator_details_sub_p">Asset Creator</p>
          </div>
        </div>
        {/* need help start */}
        <button
          onClick={() => {
            navigate("/Report", {
              state: {
                release_bond_id: release_bond_id,
              },
            });
          }}
          className="need_pv_help"
          style={{ marginBottom: "30px", marginTop: "10px" }}
        >
          <div className="need_pv_help_1">
            <div className="need_pvpart1">
              <img src={images.need_help} className="need_pv_helpimg" alt="" />
            </div>
            <div className="need_pvpart2">
              <h1>Need help?</h1>
            </div>
            <div className="need_pvpart3">
              <img src={images.next} className="need_pv_helpimg2" alt="" />
            </div>
          </div>
        </button>
        {/* need help end */}
        <br />
      </div>

      {isModalVisible && (
        <>
          {/* <AlertModal
            title={getmodalName}
            msg={msg}
            setShowModal={setIsModalVisible}
          /> */}
          {/* <NewModal
            title=""
            msg={msg}
            setShowModal={openmodal}
            closemodal={closemodal}
          /> */}

          <V2Modal
            canclebtn={false}
            title=""
            msg={msg}
            setShowModal={setIsModalVisible}
            okfunction={closemodal}
          />
        </>
      )}
    </div>
  );
};

export default ProductView2;
