import React, { useEffect } from "react";
import "./collaboratenfb.css";
import images from "../../constants/images";
import { useLocation, useNavigate } from "react-router-dom";
import NavbarSec from "../../common/NavbarSec";
import Slider from "react-slick";
import { useState } from "react";
import axios from "axios";
import qs from "qs";
import Modal from "react-modal";
import AlertModal from "../../components/AlertModal";
import NewModal from "../../common/newmodal/NewModal";
import V2Modal from "../../common/v2modal/V2Modal";

Modal.setAppElement("#root");

const Collaboratenfb = () => {
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const userId = localStorage.getItem("user_id");

  const location = useLocation();
  const Navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [productData, setProductData] = useState({});
  const [product_name, setproduct_name] = useState("");
  const [description, setdescription] = useState("");
  const [created_at, setcreated_at] = useState("");
  const [userData, setUserData] = useState([]);
  const [selectedName, setSelectedName] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [data, setData] = useState([]);
  const [getmemberData, setMemberData] = useState([]);
  const [getsearchData, setSearchData] = useState([]);
  const [getisClub, setIsClub] = useState("none");
  const [getclubId, setClubId] = useState(0);
  const [getisGlobal, setIsGlobal] = useState(0);
  const [getclubName, setClubName] = useState("");

  // modal start
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleGlobalChange = (event) => {
    setIsGlobal(event.target.value);
  };

  // modal end

  useEffect(() => {
    getProductData();
  }, []);

  // --apis start

  const getProductData = () => {
    setIsLoading(true);

    const param = {
      product_id: location.state.product_id,
    };

    axios
      .post("https://www.ravi.host/api/preview_product", qs.stringify(param))
      .then((response) => {
        setIsLoading(false);
        if (response.data.status === 1) {
          console.log("response.data", response.data);
          setproduct_name(response.data.result.product_name);
          setdescription(response.data.result.description);
          setcreated_at(response.data.result.created_at);

          const demo = [];
          for (
            let index = 0;
            index < response.data.result.product_img_video.length;
            index++
          ) {
            demo.push(
              response.data.result.product_img_video[index].product_icon
            );
          }

          setProductData({ product_img_video: demo });
        } else {
          // alert(response.data.message);
          console.log("preview_product err ->", response.data.message);
        }
      });
  };

  const getAllUser = async () => {
    setSelectedName("");
    setSelectedId("");
    setIsLoading(true);

    const param = {
      user_id: userId,
    };

    await axios
      .post(
        "https://www.ravi.host/api/get_user_club_member_detail",
        qs.stringify(param)
      )
      .then((response) => {
        console.log("response---->", response.data);
        setIsLoading(false);
        if (response.data.status === 1) {
          setMemberData(response.data.result);
          setSearchData(response.data.result);
        } else {
          // alert(response.data.message);
          console.log(
            "get_user_club_member_detail err ->",
            response.data.message
          );
        }
      });
  };

  const getAllClub = () => {
    setSelectedName("");
    setSelectedId("");
    setIsLoading(true);

    const param = {
      user_id: userId,
    };

    axios
      .post(
        "https://www.ravi.host/api/get_user_club_member_detail",
        qs.stringify(param)
      )
      .then((response) => {
        setIsLoading(false);
        if (response.data.status === 1) {
          setData(response.data.result);
        } else {
          // alert(response.data.message);
          console.log(
            "get_user_club_member_detail err ->",
            response.data.message
          );
        }
      });
  };

  const getAllPartner = () => {
    setSelectedName("");
    setSelectedId("");
    setIsLoading(true);

    axios.get("https://www.ravi.host/api/get_all_partner").then((response) => {
      setIsLoading(false);
      if (response.data.status === 1) {
        setData(response.data.result);
      } else {
        // alert(response.data.message);
        console.log("get_all_partner err ->", response.data.message);
      }
    });
  };

  useEffect(() => {
    getAllUser();
    console.log("getmemberData", getmemberData);
  }, [getisClub]);

  const searchFilter = (text) => {
    const newData = getsearchData.filter(function (item) {
      console.log("text", text);
      const sdata = item.club_name
        ? item.club_name.toUpperCase()
        : "".toLowerCase();

      const textData = text.toUpperCase();
      return sdata.indexOf(textData) > -1;
    });
    setMemberData(newData);
    console.log("newData", newData);
  };

  const handleSubmit = () => {
    if (getisClub == "club" && getclubId == 0) {
      // alert("Please Select collaborator or none and continue");
      setShowModal(true);
      setErrorMessage("Please Select collaborator or none and continue");
    } else {
      const param = {
        product_id: location.state.product_id,
        colla_partner_club_none_id: getclubId,
        colla_partner_club_none:
          getisClub == "Partner"
            ? 1
            : getisClub == "Collaborator"
            ? 2
            : getisClub == "club"
            ? 3
            : 4,
        user_id: userId,
        is_global: getisClub == "club" ? getisGlobal : "None",
      };
      console.log("param--->", param);
      axios
        .post(
          "https://www.ravi.host/api/draft_bond_api_part_2",
          qs.stringify(param)
        )
        .then((Response) => {
          console.log("Response.data", Response.data);
          if (Response.data.status === 1) {
            // props.navigation.replace("NewAttech", {
            //   product_id: props.route.params.product_id,
            //   user_id: props.user_id,
            // });
            Navigate("/Createnfbs", {
              state: {
                product_id: location.state.product_id,
                user_id: userId,
                commitment_date_new: location.state.commitment_date_new,
                is_user: location.state.is_user,
              },
            });
          } else {
            alert(Response.data.message);
          }
        });
    }
  };

  // --apis end

  const closemodal = () => {
    setShowModal(false);
  };
  const openmodal = () => {
    setShowModal(true);
  };
  const [slideIndex, setSlideIndex] = useState(0);

  const sliderSettings = {
    dots: true, // Show dots for navigation
    infinite: true, // Infinite loop
    speed: 500, // Transition speed in milliseconds
    slidesToShow: 1, // Number of slides to show at once
    slidesToScroll: 1, // Number of slides to scroll per interaction
    afterChange: (currentSlide) => setSlideIndex(currentSlide),
    customPaging: (i) => (
      <div
        style={{
          width: "10px",
          height: "10px",
          margin: "10px 5px",
          borderRadius: "50%",
          backgroundColor: slideIndex === i ? "#da0e41" : "white",
          opacity: slideIndex === i ? 1 : 0.6,
        }}
      ></div>
    ),
  };
  return (
    <div className="page_main_wrapp">
      <div className="page_base_wrapp_2">
        <NavbarSec name="Collaborate Aucemo" backto="/myproduct" />
        <br />
        <Slider {...sliderSettings}>
          {" "}
          {/* Use the Slider component with your settings */}
          {productData.product_img_video &&
          productData.product_img_video.length > 0
            ? productData.product_img_video.map((item, index) => {
                return (
                  <div>
                    <img src={item} className="Collaboratenfb_img" alt="" />
                  </div>
                );
              })
            : null}
          {/* Add additional slides here */}
        </Slider>
        <p className="collaborate_titel" style={{ marginTop: "1.5rem" }}>
          {product_name}
        </p>
        <br />
        <p
          style={{ marginLeft: "10px", marginRight: "10px", fontSize: "14px" }}
        >
          Do you have any collaborators/partners ?{" "}
          <span
            className="details_home_icon"
            onClick={() => {
              setShowModal(true);
              setErrorMessage(
                "Are you the sole owner, or do you have collaborators? If you choose 'Collaborators,' please select your club from the list"
              );
            }}
          >
            {" "}
            {String.fromCharCode(9432)}
          </span>
        </p>
        <br />
        <div style={{ marginLeft: "10px", marginRight: "10px" }}>
          <select
            name=""
            id=""
            onChange={(e) => {
              setIsClub(e.target.value);
            }}
          >
            {/* <option className="collaborate_option" value="" selected disabled>
              Select
            </option> */}
            <option className="collaborate_option" value="none" selected>
              None
            </option>
            <option className="collaborate_option" value="club">
              Club
            </option>
            {/* <option className="collaborate_option" value="">
              none
            </option> */}
          </select>
        </div>
        {/* <br /> */}
        {/* SELECTED CLUB START */}
        {getisClub == "club" && (
          <div style={{ marginLeft: "10px", marginRight: "10px" }}>
            {/* <select
              name=""
              id=""
              // onChange={(e) => {
              //   setIsClub(e.target.value);
              // }}
              onClick={openModal}
            >
              <option className="collaborate_option" value={0} selected>
                {getclubName != "" ? getclubName : "Search11"}
              </option>
            </select> */}

            <button
              className="collaborate_search_option_btn"
              onClick={openModal}
            >
              <p>{getclubName != "" ? getclubName : "Search"}</p>
              <img
                // onClick={toggleShowPassword}
                src={images.down}
                alt=""
                // className="select_down_arrow"
                // style={{
                //   transform: opencityModal ? "rotate(180deg)" : "rotate(0deg)",
                // }}
              />
            </button>
          </div>
        )}
        {/* SELECTED CLUB END */}

        {/* global exclusive start */}

        {getisClub == "club" && (
          <div className="cbond_form_radio_wrapp" style={{ marginTop: "10px" }}>
            <div id="custom_radio" style={{ justifyContent: "flex-start" }}>
              <label class="custom_radio">
                <input
                  type="radio"
                  name="checkbox"
                  value={0}
                  checked={getisGlobal == 0}
                  onChange={handleGlobalChange}
                />
                <span class="checkmark"></span>
                Exclusive
              </label>
              <label class="custom_radio">
                <input
                  type="radio"
                  name="checkbox"
                  value={1}
                  checked={getisGlobal == 1}
                  onChange={handleGlobalChange}
                />
                <span class="checkmark"></span>
                Super Exclusive
              </label>
            </div>
          </div>
        )}

        {/* global exclusive end */}

        <br />

        <center>
          <button className="new_btn_wrapp" onClick={handleSubmit}>
            <p>Save and Promote</p>
          </button>
        </center>
      </div>

      {/* modal start */}
      {isModalOpen && (
        <div
          style={{
            backgroundColor: "black",
            padding: "20px 10px",
            margin: "0px auto",
            maxWidth: "var(--max-width-sm)",
            height: "100vh",
            position: "fixed",
            zIndex: 111,
            top: "0px",
            left: "0px",
          }}
          // className="serch_modal"
        >
          <div
            style={{ backgroundColor: "black", width: "100%", height: "100%" }}
            // className="collbrator_inner_modal"
          >
            <img
              style={{ width: "40px", height: "25px" }}
              src={images.back}
              // className="nav_back_btn"
              alt=""
              onClick={() => {
                closeModal();
              }}
            />
            <input
              style={{ marginTop: "20px" }}
              type="text"
              placeholder="Search"
              // value={getclubName}
              onChange={(e) => searchFilter(e.target.value)}
              className="search_box_in_modal"
            />

            <ul>
              {getmemberData && getmemberData.length > 0 ? (
                getmemberData.map((result) => (
                  <button
                    className="list_collab_btn"
                    style={{
                      padding: "8px 20px",
                      border: result.club_name
                        ? "1px solid green"
                        : "1px solid white",
                      borderRadius: "8px",
                    }}
                    key={result.club_id}
                    onClick={() => {
                      setClubName(result.club_name);
                      setClubId(result.club_id);
                      // setIsModalOpen(false);
                      closeModal();
                      console.log("getclubName", getclubName);
                    }}
                  >
                    <p
                      style={{
                        fontFamily: "var(--font-600)",
                        color:
                          getclubName == result.club_name ? "green" : "white",
                        marginLeft: "5%",
                        width: "100%",
                      }}
                    >
                      {result.club_name}
                    </p>
                  </button>
                ))
              ) : (
                <p
                  style={{
                    color: "white",
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  No item found.
                </p>
              )}
            </ul>
          </div>
          <button className="collbrator_inner_btn" onClick={closeModal}>
            Close
          </button>
        </div>
      )}
      {/* modal end */}
      {showModal && (
        // <AlertModal
        //   msg={errorMessage}
        //   setShowModal={setShowModal}
        //   title="Information"
        // />

        // <NewModal
        //   title=""
        //   msg={errorMessage}
        //   setShowModal={openmodal}
        //   closemodal={closemodal}
        // />

        <V2Modal
          canclebtn={false}
          title=""
          msg={errorMessage}
          setShowModal={setShowModal}
          okfunction={closemodal}
        />
      )}
    </div>
  );
};
export default Collaboratenfb;
