import React, { useState, useEffect, useRef } from "react";
import "./HistoryRarerez.css"; // Make sure to import your CSS file
import images from "../../constants/images"; // Make sure to import your image paths
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../common/footer/Footer";
import qs from "qs";
import axios from "axios";
import { useHomeContext } from "../../context/home_context";

import NavbarSec from "../../common/NavbarSec";

import AlertModal from "../../components/AlertModal";
import HistoryCard6 from "./HistoryCard6";
import HistoryCard7 from "./HistoryCard7";
import HistoryCard8 from "./HistoryCard8";
import HistoryCard10 from "./HistoryCard10";
import HistoryCard11 from "./HistoryCard11";
import HistoryCard12 from "./HistoryCard12";
import HistoryCard13 from "./HistoryCard13";
import HistoryCard9 from "./HistoryCard9";
import { add_original_file_asset } from "../../utils/Constant";
import NewModal from "../../common/newmodal/NewModal";
import V2Modal from "../../common/v2modal/V2Modal";
import NewFooter from "../../common/newfooter/NewFooter";
import HistoryCard14 from "./HistoryCard14";
import HistoryCard15 from "./HistoryCard15";
import HistoryNewFrame1 from "../../frames/historyframes/HistoryNewFrame1";
import HistoryNewFrame2 from "../../frames/historyframes/HistoryNewFrame2";
import HistoryNewFrame3 from "../../frames/historyframes/HistoryNewFrame3";
import HistoryNewFrame4 from "../../frames/historyframes/HistoryNewFrame4";
import HistoryNewFrame5 from "../../frames/historyframes/HistoryNewFrame5";
import HistoryNewFrame6 from "../../frames/historyframes/HistoryNewFrame6";
import HistoryNewFrame7 from "../../frames/historyframes/HistoryNewFrame7";
import HistoryNewFrame8 from "../../frames/historyframes/HistoryNewFrame8";
import HistoryNewFrame9 from "../../frames/historyframes/HistoryNewFrame9";
import HistoryNewFrame10 from "../../frames/historyframes/HistoryNewFrame10";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

const HistoryRarerez = () => {
  const { Mycollectionlike, collection_data, collection_loading } =
    useHomeContext();
  const [selectedCard, setSelectedCard] = useState(1); // Default to the first card
  const navigate = useNavigate();

  const handleCardChange = (cardNumber) => {
    setSelectedCard(cardNumber);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const savedScrollPosition = sessionStorage.getItem("scrollPosition");
    if (savedScrollPosition) {
      window.scrollTo(0, parseInt(savedScrollPosition, 10));
      sessionStorage.removeItem("scrollPosition");
      console.log("first", parseInt(savedScrollPosition, 10));
    }

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const renderSelectedCard = () => {
    // if (themeIndex === 6) {
    //   return (
    //     <HistoryCard6
    //       // data={getdata}
    //       // icon={setimage}
    //       // handleData={handleData}
    //       data={getdata}
    //       icon={setimage}
    //       onBondLikeSuccess={refreshData}
    //       handleData={handleData}
    //       setIsModalOpen={setIsModalOpen}
    //       setMsg={setMsg}
    //       setModalName={setModalName}
    //     />
    //   );
    // } else if (themeIndex === 7) {
    //   return (
    //     // <Purchasefourcard
    //     <HistoryCard7
    //       data={getdata}
    //       icon={setimage}
    //       onBondLikeSuccess={refreshData}
    //       handleData={handleData}
    //       setIsModalOpen={setIsModalOpen}
    //       setMsg={setMsg}
    //       setModalName={setModalName}
    //     />
    //   );
    // } else if (themeIndex === 8) {
    //   return (
    //     <HistoryCard8
    //       data={getdata}
    //       icon={setimage}
    //       onBondLikeSuccess={refreshData}
    //       handleData={handleData}
    //       setIsModalOpen={setIsModalOpen}
    //       setMsg={setMsg}
    //       setModalName={setModalName}
    //     />
    //   );
    // } else if (themeIndex === 9) {
    //   return (
    //     <HistoryCard9
    //       // <Purchaseshowcard
    //       data={getdata}
    //       icon={setimage}
    //       onBondLikeSuccess={refreshData}
    //       handleData={handleData}
    //       setIsModalOpen={setIsModalOpen}
    //       setMsg={setMsg}
    //       setModalName={setModalName}
    //       // setislikes={setislikes}
    //     />
    //   );
    // } else if (themeIndex === 10) {
    //   return (
    //     <HistoryCard10
    //       data={getdata}
    //       icon={setimage}
    //       onBondLikeSuccess={refreshData}
    //       handleData={handleData}
    //       setIsModalOpen={setIsModalOpen}
    //       setMsg={setMsg}
    //       setModalName={setModalName}
    //       // setislikes={setislikes}
    //     />
    //   );
    // } else if (themeIndex === 11) {
    //   return (
    //     <HistoryCard11
    //       data={getdata}
    //       icon={setimage}
    //       onBondLikeSuccess={refreshData}
    //       handleData={handleData}
    //       setIsModalOpen={setIsModalOpen}
    //       setMsg={setMsg}
    //       setModalName={setModalName}
    //       // setislikes={setislikes}
    //     />
    //   );
    // } else if (themeIndex === 12) {
    //   return (
    //     <HistoryCard12
    //       data={getdata}
    //       icon={setimage}
    //       onBondLikeSuccess={refreshData}
    //       handleData={handleData}
    //       setIsModalOpen={setIsModalOpen}
    //       setMsg={setMsg}
    //       setModalName={setModalName}
    //     />
    //   );
    // } else if (themeIndex === 13) {
    //   return (
    //     <HistoryCard13
    //       data={getdata}
    //       icon={setimage}
    //       onBondLikeSuccess={refreshData}
    //       handleData={handleData}
    //       setIsModalOpen={setIsModalOpen}
    //       setMsg={setMsg}
    //       setModalName={setModalName}
    //     />
    //   );
    // } else if (themeIndex === 14) {
    //   return (
    //     <HistoryCard14
    //       data={getdata}
    //       icon={setimage}
    //       onBondLikeSuccess={refreshData}
    //       handleData={handleData}
    //       setIsModalOpen={setIsModalOpen}
    //       setMsg={setMsg}
    //       setModalName={setModalName}
    //     />
    //   );
    // } else if (themeIndex === 15) {
    //   return (
    //     <HistoryCard15
    //       data={getdata}
    //       icon={setimage}
    //       onBondLikeSuccess={refreshData}
    //       handleData={handleData}
    //       setIsModalOpen={setIsModalOpen}
    //       setMsg={setMsg}
    //       setModalName={setModalName}
    //     />
    //   );
    // } else
    if (themeIndex === 16) {
      return (
        <HistoryNewFrame1
          data={getdata}
          icon={setimage}
          onBondLikeSuccess={refreshData}
          handleData={handleData}
          setIsModalOpen={setIsModalOpen}
          setMsg={setMsg}
          setModalName={setModalName}
          windowWidth={windowWidth}
        />
      );
    } else if (themeIndex === 17) {
      return (
        <HistoryNewFrame2
          data={getdata}
          icon={setimage}
          onBondLikeSuccess={refreshData}
          handleData={handleData}
          setIsModalOpen={setIsModalOpen}
          setMsg={setMsg}
          setModalName={setModalName}
          windowWidth={windowWidth}
        />
      );
    } else if (themeIndex === 18) {
      return (
        <HistoryNewFrame3
          data={getdata}
          icon={setimage}
          onBondLikeSuccess={refreshData}
          handleData={handleData}
          setIsModalOpen={setIsModalOpen}
          setMsg={setMsg}
          setModalName={setModalName}
          windowWidth={windowWidth}
        />
      );
    } else if (themeIndex === 19) {
      return (
        <HistoryNewFrame4
          data={getdata}
          icon={setimage}
          onBondLikeSuccess={refreshData}
          handleData={handleData}
          setIsModalOpen={setIsModalOpen}
          setMsg={setMsg}
          setModalName={setModalName}
          windowWidth={windowWidth}
        />
      );
    } else if (themeIndex === 20) {
      return (
        <HistoryNewFrame5
          data={getdata}
          icon={setimage}
          onBondLikeSuccess={refreshData}
          handleData={handleData}
          setIsModalOpen={setIsModalOpen}
          setMsg={setMsg}
          setModalName={setModalName}
          windowWidth={windowWidth}
        />
      );
    } else if (themeIndex === 21) {
      return (
        <HistoryNewFrame6
          data={getdata}
          icon={setimage}
          onBondLikeSuccess={refreshData}
          handleData={handleData}
          setIsModalOpen={setIsModalOpen}
          setMsg={setMsg}
          setModalName={setModalName}
          windowWidth={windowWidth}
        />
      );
    } else if (themeIndex === 22) {
      return (
        <HistoryNewFrame7
          data={getdata}
          icon={setimage}
          onBondLikeSuccess={refreshData}
          handleData={handleData}
          setIsModalOpen={setIsModalOpen}
          setMsg={setMsg}
          setModalName={setModalName}
          windowWidth={windowWidth}
        />
      );
    } else if (themeIndex === 23) {
      return (
        <HistoryNewFrame8
          data={getdata}
          icon={setimage}
          onBondLikeSuccess={refreshData}
          handleData={handleData}
          setIsModalOpen={setIsModalOpen}
          setMsg={setMsg}
          setModalName={setModalName}
          windowWidth={windowWidth}
        />
      );
    } else if (themeIndex === 24) {
      return (
        <HistoryNewFrame9
          data={getdata}
          icon={setimage}
          onBondLikeSuccess={refreshData}
          handleData={handleData}
          setIsModalOpen={setIsModalOpen}
          setMsg={setMsg}
          setModalName={setModalName}
          windowWidth={windowWidth}
        />
      );
    } else if (themeIndex === 25) {
      return (
        <HistoryNewFrame10
          data={getdata}
          icon={setimage}
          onBondLikeSuccess={refreshData}
          handleData={handleData}
          setIsModalOpen={setIsModalOpen}
          setMsg={setMsg}
          setModalName={setModalName}
          windowWidth={windowWidth}
        />
      );
    } else {
      return (
        <HistoryNewFrame1
          data={getdata}
          icon={setimage}
          onBondLikeSuccess={refreshData}
          handleData={handleData}
          setIsModalOpen={setIsModalOpen}
          setMsg={setMsg}
          setModalName={setModalName}
          windowWidth={windowWidth}
        />
      );
    }
  };

  const [ismodalopen, setIsModalOpen] = useState(false);
  const [getmsg, setMsg] = useState("");
  const [getmodalName, setModalName] = useState("");

  const [isLoading, setIsLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [setindex, setSetIndex] = useState(0);
  const [acceptBondBidId, setAcceptBondBidId] = useState("");
  const [setimage, setSetImage] = useState("");
  const [setname, setSetName] = useState("");
  const [setimage2, setSetImage2] = useState("");
  const [ownername, setOwnerName] = useState("");
  const [isNfb, setIsNfb] = useState("");
  const [uniqCode, setUniqCode] = useState("");
  const [isCollection, setIsCollection] = useState(1);
  const [bondId, setBondId] = useState("");
  const [isClub, setIsClub] = useState("");
  const [getdate, setGetDate] = useState("");
  const [releaseBondId, setReleaseBondId] = useState("");
  const [getqr, setGetQR] = useState("");
  const [productValue, setProductValue] = useState("");
  const [time, setTime] = useState("");
  const [themeIndex, setThemeIndex] = useState("");
  const [username, setUsername] = useState("");
  const [des, setDes] = useState("");
  const [getqrString, setGetQRString] = useState("");
  const [isNfbType, setIsNfbType] = useState("");
  const userId = localStorage.getItem("user_id");
  const [getamount, setamount] = useState("");
  const [getdata, setdata] = useState([]);
  const [islikes, setislikes] = useState(0);

  const refreshData = () => {
    const params = {
      user_id: userId,
    };
    // Call your data retrieval function here, e.g., Mycollectionlike(params)
    Mycollectionlike(params);
  };

  useEffect(() => {
    // fff();
    getData();
    // console.log("islikes", islikes);
  }, []);

  const handleData = () => {
    getData();
  };

  const getData = () => {
    setIsLoading(true);
    setProducts([]);

    const param = {
      user_id: userId,
    };

    axios
      .post(
        "https://www.ravi.host/api/get_user_bond_list_product",
        qs.stringify(param)
      )
      .then((Response) => {
        setIsLoading(false);
        if (Response.data.status === 1) {
          const result = Response.data.result[0];
          setdata(result);
          setProducts(Response.data.result);
          setSetIndex(0);
          setAcceptBondBidId(result.accept_bond_bid_id);
          setSetImage(
            result.bond_img_video[result.bond_img_video.length - 1].img_video
          );
          setSetName(result.product_name);
          setSetImage2(
            result.bond_img_video[result.bond_img_video.length - 1].img_video
          );
          setOwnerName(result.owner_name);
          setIsNfb(result.is_nfb);
          setUniqCode(result.uniq_code);
          setIsCollection(1);
          setBondId(result.product_id);
          setIsClub(result.is_club);
          setGetDate(result.created_at);
          setReleaseBondId(result.release_bond_id);
          setGetQR(result.qr_code);
          setProductValue(result.product_value);
          setTime(result.time);
          setThemeIndex(result.theme_index);
          setUsername(result.username);
          setDes(result.desc);
          setGetQRString(result.qr_string);
          setIsNfbType(result.is_nfb_type);
        } else {
          // Handle the case when the status is not 1
          // You can set a state variable or perform other actions as needed
          // For example, set a message state variable
          // setMessage(Response.data.message);
        }
        console.log("Response", Response.data);
      });
  };

  //disable body scrolling
  const [bodyScrollEnabled, setBodyScrollEnabled] = useState(true);
  useEffect(() => {
    if (ismodalopen) {
      // Disable body scrolling
      setBodyScrollEnabled(false);
    } else {
      // Enable body scrolling
      setBodyScrollEnabled(true);
    }
    if (bodyScrollEnabled) {
      document.body.style.overflow = "visible";
    } else {
      document.body.style.overflow = "hidden";
    }
  }, [ismodalopen, bodyScrollEnabled]);

  const closemodal = () => {
    setIsModalOpen(false);
  };
  const openmodal = () => {
    setIsModalOpen(true);
  };

  const sliderRef = useRef(null);

  const scrollLeft = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({
        left: -200,
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({
        left: 200,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="showcase" style={{ minHeight: "125vh" }}>
      {collection_loading == true ? (
        <div className="loader"></div>
      ) : (
        <>
          <>
            <div className="page_main_wrapp_nw reletive_position">
              <div
                className="page_base_wrapp_2"
                style={{ marginBottom: "10px", minHeight: "0vh" }}
              >
                {/* <NavbarSec name="Aucemo History" /> */}
                <div
                  className="legacy_dashbord_nav_sec"
                  style={{ margin: "10px auto" }}
                >
                  <button
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <FaAngleLeft color="var(--color-white)" size={20} />
                  </button>
                  <p>Aucemo History</p>
                  <div></div>
                  {/* <img
                onClick={() => {
                  navigate("/CreateLegacy");
                }}
                src={images.round_plus}
                alt=""
              /> */}
                </div>
              </div>
            </div>

            {products == 0 ? (
              <p
                className="show_p"
                style={{
                  textAlign: "center",
                  width: "90%",
                  margin: "10px auto",
                }}
              >
                You don't have any Aucemo in History, Please check in your
                collection.
              </p>
            ) : (
              <div className="showcase_details">
                {renderSelectedCard()}
                {/* <div
                  style={{
                    width: "100%",
                    // border: "1px solid red",
                    marginTop: "20px",
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "-10px",
                    justifyContent: "center",
                  }}
                >
                  <button
                    style={{
                      color: "var(--color-white)",
                      fontSize: "12px",
                      fontFamily: "var(--font-600)",
                      backgroundColor: "var(--color-main)",
                      borderRadius: "4px",
                      padding: "8px 16px",
                    }}
                    onClick={async () => {
                      const param = {
                        user_id: userId,
                        release_bond_id: getdata.release_bond_id,
                        product_id: getdata.product_id,
                        uniq_code: getdata.uniq_code,
                        is_nfb: getdata.is_nfb,
                        accept_bond_bid_id: getdata.accept_bond_bid_id,
                        share_by_user: getdata.share_by_user,
                        is_club: getdata.is_club,
                        creator_id: getdata.creator_id,
                      };

                      await axios
                        .post(add_original_file_asset, qs.stringify(param))
                        .then((res) => {
                          setIsLoading(false);
                          if (res.data.status === 1) {
                            console.log("res.data", res.data);
                            setIsModalOpen(true);
                            setMsg(res.data.message);
                          }
                        })
                        .catch((err) => {
                          setIsLoading(false);
                        });
                    }}
                  >
                    Request For Original File
                  </button>
                </div> */}
                <br />
                <div
                  style={{
                    margin: "10px auto",
                    width: "100%",
                    display: "flex",
                    whiteSpace: "nowrap",
                    overflowX: "auto",
                    maxWidth: "var(--max-width-sm)",
                    padding: "0px 10px",
                    gap: "10px",
                    marginTop: windowWidth > 786 ? "30px" : null,
                  }}
                  ref={sliderRef}
                >
                  {products && products.length > 0
                    ? products.map((item, index) => {
                        return (
                          <div
                            key={index}
                            onClick={() => {
                              setdata(item);
                              console.log(item.theme_index);
                              setIsCollection(1);
                              setSetIndex(index);
                              setSetImage(
                                item.bond_img_video[
                                  item.bond_img_video.length - 1
                                ].img_video
                              );
                              setSetImage2(
                                item.bond_img_video[
                                  item.bond_img_video.length - 1
                                ].img_video
                              );
                              setOwnerName(item.owner_name);
                              setIsNfb(item.is_nfb);
                              setUniqCode(item.uniq_code);
                              setBondId(item.product_id);
                              setIsClub(item.is_club);
                              setGetDate(item.created_at);
                              setReleaseBondId(item.release_bond_id);
                              setGetQR(item.qr_code);
                              setAcceptBondBidId(item.accept_bond_bid_id);
                              setamount(item.bid_amount);
                              setTime(item.time);
                              setThemeIndex(item.theme_index);
                              setIsNfbType(item.is_nfb_type);
                              setGetQRString(item.qr_string);
                              handleCardChange(1);
                            }}
                            style={{
                              width: "100px",
                              height: "40px",
                              borderRadius: "50%",
                              marginLeft: "6px",
                              // overflow: "hidden",
                            }}
                          >
                            <img
                              src={item.bond_img_video[0].img_video}
                              className={
                                setindex === index
                                  ? "chnages_img_btn"
                                  : "chnages_img_btn_border"
                              }
                              style={{
                                width: "40px",
                                height: "100%",
                                borderRadius: "100%",
                                objectFit: "cover",
                              }}
                              alt=""
                            />
                          </div>
                        );
                      })
                    : null}
                </div>
              </div>
            )}
          </>
        </>
      )}
      {/* <NewFooter></NewFooter> */}

      {ismodalopen && (
        <>
          {/* <AlertModal
            title={getmodalName}
            msg={getmsg}
            setShowModal={setIsModalOpen}
          /> */}
          {/* <NewModal
            title={getmodalName}
            msg={getmsg}
            setShowModal={openmodal}
            closemodal={closemodal}
          /> */}

          <V2Modal
            canclebtn={false}
            title={getmodalName}
            msg={getmsg}
            setShowModal={setIsModalOpen}
            okfunction={closemodal}
          />
        </>
      )}
    </div>
  );
};

export default HistoryRarerez;
