import React, { useState, useEffect } from "react";
import axios from "axios";
import qs from "qs";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import images from "../constants/images";
import {
  club_draft_bond_api_part_1,
  draft_bond_api_part_1,
} from "../utils/Constant";
import { ToastContainer, toast } from "react-toastify";

const ClubBondbox = () => {
  const userId = localStorage.getItem("user_id");
  const location = useLocation();
  const Navigate = useNavigate();
  const [agree, setAgree] = useState(false);
  const [getminusBond, setMinusBond] = useState(0);

  useEffect(() => {
    const isGlobal = location.state.is_globle === 1;
    const isOwner = location.state.is_owner === 1;

    setAgree(false);
    const minusBond = isGlobal ? 0 : isOwner ? 20 : 0;
    setMinusBond(minusBond);

    console.log("location.state", location.state);
  }, []);

  const handleAgreeToggle = (e) => {
    setAgree((e) => (e.targate.value === 1 ? 0 : 1));
  };

  const handleOkButton = () => {
    if (agree) {
      const params = {
        club_product_id: location.state.club_product_id,
        no_of_release_bond: location.state.no_of_release_bond,
        remaining_bond_release_date: location.state.remaining_bond_release_date,
        user_id: location.state.club_user_id,
        minimum_bid_amount: location.state.min_bid_amount,
        commitment_date: location.state.commitment_date,
        share_by_user: location.state.share_by_user,
        club_id: location.state.club_id,
      };

      axios
        .post(club_draft_bond_api_part_1, qs.stringify(params))
        .then((response) => {
          if (response.data.status === 1) {
            // props.navigation.replace("Addpartnercolabrationinbond", {
            //   product_id: location.state.product_id,
            // });
            Navigate("/ClubCreatenfbs", {
              state: {
                club_product_id: location.state.club_product_id,
                club_user_id: location.state.club_user_id,
                is_club_assign: location.state.is_club_assign,
                user_id: location.state.club_user_id,
                club_id: location.state.club_id,
                share_by_user: location.state.share_by_user,
                commitment_date_new: location.state.commitment_date_new,
              },
            });
            console.log("response", response);
          } else {
            // alert(response.data.message);
            toast.error(response.data.message, { theme: "colored" });
          }
        });
    } else {
      // alert("Please agree");
      toast.error("Please agree", { theme: "colored" });
    }
  };

  return (
    <div className="page_main_wrapp_box">
      <div className="page_base_wrappbox">
        <div className="bondbox_main">
          <center>
            <img src={images.logo} className="images_bondbox" alt="" />
            <p className="details_bondbox">
              {location.state.remaining_bond_release_date === ""
                ? "Your" + location.state.is_owner === 4
                  ? location.state.no_of_release_bond
                  : location.state.no_of_release_bond - 0
                : "Your" + location.state.is_owner === 4
                ? location.state.no_of_release_bond
                : location.state.no_of_release_bond - 0}{" "}
              &nbsp;Aucemo will be released now. {/* new line start */}
              {(location.state.commitment_date_new == "" ||
                location.state.commitment_date_new == undefined ||
                location.state.commitment_date_new == null) &&
                ` and aucemo.club charges ${
                  location.state.is_globle === 1 ? 10 : 20
                } aucemo,`}
              {/* new line end */}
              {/* {location.state.is_owner === 4
                ? null
                : " and  " +
                  // 0 +
                  getminusBond +
                  " bonds will be issued to aucemo.club.  "} */}
              {/* -------- */}
              {/* You will be reminded on{" "}
              {moment(location.state.remaining_bond_release_date).format(
                "D MMMM, YYYY"
              )}{" "}
              to release your remaining{" "}
              {location.state.remaining_bond -
                location.state.no_of_release_bond}{" "}
              Aucemo. */}
              {location.state.remaining_bond -
                location.state.no_of_release_bond ==
                0 || location.state.remaining_bond_release_date == ""
                ? null
                : `You will be reminded on 
              ${moment(location.state.remaining_bond_release_date).format(
                "D MMMM, YYYY"
              )}
              to release your remaining
              ${
                location.state.remaining_bond -
                location.state.no_of_release_bond
              }
              Aucemo.`}
              {/* ---------- */}
              {/* 28 Aucemo will be released now. and 20 Aucemo will be issued to
              Aucemo.club. You will be reminded on 30 September, 2023 to release
              your remaining 52 Aucemo. */}
            </p>
            <div
              className="bondbox_checkbox"
              style={{ justifyContent: "center" }}
            >
              {agree ? (
                <img
                  src={images.new_accepted_terms}
                  onClick={() => setAgree(false)}
                  alt=""
                  style={{ width: "20px", objectFit: "contain" }}
                />
              ) : (
                <img
                  src={images.new_notaccepted_terms}
                  alt=""
                  onClick={() => {
                    setAgree(true);
                  }}
                  style={{ width: "20px", objectFit: "contain" }}
                />
              )}
              <span style={{ padding: "0px" }}>I Agree</span>
            </div>
            <button
              style={{ width: "100px", height: "50px", padding: "0px" }}
              className="new_btn_wrapp mt-3"
              onClick={handleOkButton}
            >
              <p>Okay</p>
            </button>
          </center>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ClubBondbox;
