import React, { useState, useEffect } from "react";
import images from "../../constants/images";
import "./releasednfb.css";
import BackButton from "../../components/BackButton";
import {
  get_all_release_bond,
  add_showcase_my_collection,
} from "../../utils/Constant";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import NavbarSec from "../../common/NavbarSec";
import { FaAngleLeft } from "react-icons/fa";
import NewLoader from "../../common/newloder/NewLoader";

const Releasednfb = () => {
  const userId = localStorage.getItem("user_id");
  const [getreleasedata, setreleasedata] = useState([]);
  const [getsearchdata, setsearchdata] = useState([]);
  const [loading, setloading] = useState(false);

  useEffect(() => {
    getReleasebond();
  }, []);

  const searchFilter_branch = (text) => {
    const newData = getsearchdata.filter(function (item) {
      console.log("item", item.product_name);
      const employee = item.product_name
        ? item.product_name.toUpperCase()
        : "".toLowerCase();
      const number = String(item.created_at)
        ? String(item.created_at).toUpperCase()
        : "".toUpperCase();
      const stock = String(item.total_available_bond_bid)
        ? String(item.total_available_bond_bid).toUpperCase()
        : "".toUpperCase();

      const textData = text.toUpperCase();
      return (
        employee.indexOf(textData) > -1 ||
        number.indexOf(textData) > -1 ||
        stock.indexOf(textData) > -1
      );
    });
    setreleasedata(newData);
    console.log("asvsvsds", newData);
  };

  const getReleasebond = () => {
    setloading(true);
    const param = {
      user_id: userId,
    };
    axios
      .post(get_all_release_bond, param)
      .then((Response) => {
        setloading(false);

        if (Response.data.status == 1) {
          setreleasedata(Response.data.result);
          setsearchdata(Response.data.result);
        } else {
          console.log("get_all_release_bond else", Response.data);
        }
        // this.setState({
        //   // data: Response.data.result,
        //   task_arrayholder: Response.data.result,
        // });
      })
      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };

  const showcasemycollection = (item) => {
    setloading(true);
    const param = {
      user_id: userId,
      bond_id: item.bond_id,
      is_nfb: item.is_nfb,
      is_club: item.is_club,
      uniq_code: item.uniq_code,
      release_bond_id: item.release_bond_id,
      is_nfb_type: 2,
    };
    console.log(param);
    axios
      .post(add_showcase_my_collection, param)
      .then((Response) => {
        setloading(false);
        console.log(
          "Response.data.result",
          JSON.stringify(Response.data.message, null, 2)
        );
        console.log(
          "Response.data.+++",
          JSON.stringify(Response.data.status, null, 2)
        );
        if (Response.data.status == 1) {
          setloading(false);
          getReleasebond();
        } else {
          getReleasebond();
        }
      })
      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };

  const navigate = useNavigate();

  return (
    <div className="page_main_wrapp_nw reletive_position">
      {loading ? (
        <NewLoader />
      ) : (
        <>
          <div className="page_base_wrapp_2">
            <div className="web_released_menu">
              <div className="releasednfb_header">
                {/* <NavbarSec name="aucemo" /> */}
                <div
                  className="legacy_dashbord_nav_sec"
                  style={{ margin: "10px auto" }}
                >
                  <button
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <FaAngleLeft color="var(--color-white)" size={20} />
                  </button>
                  <p>aucemo</p>
                  <div></div>
                  {/* <img
                onClick={() => {
                  navigate("/CreateLegacy");
                }}
                src={images.round_plus}
                alt=""
              /> */}
                </div>
              </div>
              <div className="released__user">
                {getreleasedata.length <= 0 && (
                  <div className="released_nodata_text">
                    Start building your aucemo collection today! aucemo are
                    unique and valuable digital assets that represent your
                    connection to exclusive products and experiences. Explore
                    our marketplace, participate in auctions, and engage with
                    the aucemo.club community to acquire aucemo that resonate
                    with you.
                  </div>
                )}

                {/* <input
                  type="text"
                  placeholder="Search"
                  className="user_releasednfb_search"
                  onChange={(e) => {
                    searchFilter_branch(e.target.value);
                  }}
                /> */}
                <div style={{}} className="bid13_search">
                  <div
                    className="bids13_user_bid_main"
                    style={{ paddingTop: "10px" }}
                  >
                    <input
                      type="text"
                      placeholder="Search"
                      className="bids13_user_bid_search"
                      // value={searchTerm}
                      onChange={(e) => searchFilter_branch(e.target.value)}
                    />
                    {/* <img src={images.search} alt="" className="search_ic" /> */}
                  </div>
                </div>
              </div>
              {getreleasedata.length <= 0 ? (
                <>
                  <div
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <p
                      style={{
                        alignSelf: "center",
                        textAlign: "center",
                        fontFamily: "var(--font-600)",
                        color: "white",
                        fontSize: "14px",
                      }}
                    >
                      No data found
                    </p>
                  </div>
                </>
              ) : (
                <div className="nfb_releas">
                  {getreleasedata && getreleasedata.length > 0
                    ? getreleasedata.map((item, index) => {
                        return item.bond_img_video == 0 ? null : (
                          <div className="releasednfb_main">
                            <div className="releasednfb_base">
                              <img
                                src={item.bond_img_video[0].img_video}
                                alt=""
                                className="releasednfb_img"
                              />
                              <div className="reles_details">
                                <div className="reles_details_sub">
                                  <h1 className="reles_details_h1">
                                    {item.product_name}
                                  </h1>
                                  <h1 className="reles_details_h2">
                                    Released on - {item.created_at}
                                  </h1>
                                </div>

                                {/* benefits wrapp start */}

                                <div className="reles_details_benefits_wrapp">
                                  {item.product_bond_benifit_detail &&
                                  item.product_bond_benifit_detail.length > 0
                                    ? item.product_bond_benifit_detail.map(
                                        (itm, index) => {
                                          console.log(
                                            "item_benefit_data",
                                            item
                                          );
                                          return (
                                            <>
                                              <img
                                                key={index}
                                                src={
                                                  itm.reward_img_video[0]
                                                    ?.img_video
                                                }
                                                alt=""
                                                className="reles_details_single_benefit"
                                                style={{ marginRight: "10px" }}
                                              />
                                            </>
                                          );
                                        }
                                      )
                                    : null}
                                  {item.partner_bond_benifit_data &&
                                  item.partner_bond_benifit_data.length > 0
                                    ? item.partner_bond_benifit_data.map(
                                        (itm2, index) => {
                                          console.log(
                                            "item_benefit_data",
                                            item
                                          );
                                          return (
                                            <>
                                              <img
                                                key={index}
                                                src={itm2.benifit_icon}
                                                alt=""
                                                className="reles_details_single_benefit"
                                              />
                                            </>
                                          );
                                        }
                                      )
                                    : null}
                                </div>

                                {/* benefits wrapp end */}

                                <div className="releas_btn">
                                  <button className="stock_main">
                                    Stock {item.total_available_bond_bid}
                                  </button>
                                  {item.is_bid == 1 ? null : (
                                    <button
                                      onClick={() => {
                                        navigate("/Releasedattachbenefit", {
                                          state: {
                                            product_id: item.product_id,
                                          },
                                        });
                                      }}
                                      className="edit_benefit"
                                    >
                                      {item.partner_bond_benifit_data.length ==
                                        0 ||
                                      item.product_bond_benifit_detail.length ==
                                        0
                                        ? "Attach Benefit"
                                        : "Edit Benefit"}
                                    </button>
                                  )}
                                </div>
                                <div className="releas_btn2">
                                  <button
                                    style={{ marginBottom: "0px" }}
                                    onClick={() => {
                                      item.is_nfb == 3
                                        ? navigate("/ClubMybidslist", {
                                            state: {
                                              club_product_id: item.product_id,
                                              release_bond_id:
                                                item.release_bond_id,
                                              owner_id: item.owner_id,
                                              uniq_code: item.uniq_code,
                                              share_by_user: item.share_by_user,
                                              club_id: item.club_id,
                                              user_id: userId,
                                              product_name: item.product_name,
                                            },
                                          })
                                        : navigate("/mybidslist", {
                                            state: {
                                              product_id: item.product_id,
                                              release_bond_id:
                                                item.release_bond_id,
                                              uniq_code: item.uniq_code,
                                              product_name: item.product_name,
                                            },
                                          });
                                    }}
                                    className="view_bids"
                                  >
                                    View Bids ({item.bid_count})
                                  </button>
                                  <img
                                    onClick={() => {
                                      showcasemycollection(item);
                                    }}
                                    src={
                                      item.check_is_showcase == 1
                                        ? images.fav_new
                                        : images.fav_new_white
                                    }
                                    className="releas_btn2_img"
                                    alt=""
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    : null}
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Releasednfb;
