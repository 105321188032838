import React from "react";
import "./Clubprofile.css";
import images from "../../constants/images";
import { useState } from "react";
import Slider from "react-slick";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useBlockContext } from "../../context/nfb_blockclub_context";
import { useEffect } from "react";
import { FiSettings } from "react-icons/fi";
import { BiUser } from "react-icons/bi";
import axios from "axios";
import qs from "qs";
import {
  club_follow,
  club_get_all_release_bond,
  free_club_join,
  get_all_club_detail,
  get_club_follow,
} from "../../utils/Constant";
import AlertModal from "../../components/AlertModal";
import NewModal from "../../common/newmodal/NewModal";
import ReactModal from "react-modal";
import V2Modal from "../../common/v2modal/V2Modal";
import NewLoader from "../../common/newloder/NewLoader";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { ParallaxBanner } from "react-scroll-parallax";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    // <div
    //   className={className}
    //   style={{ ...style, display: "block", background: "red" }}
    //   onClick={onClick}
    // />
    <button
      className="nav-button left"
      onClick={onClick}
      style={{ padding: "10px 0px", width: "24px" }}
    >
      <FaAngleLeft color="var(--color-main)" size={22} />
    </button>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <button
      className="nav-button right"
      onClick={onClick}
      style={{
        justifyContent: "flex-end",
        padding: "10px 0px",
        width: "24px",
      }}
    >
      <FaAngleRight color="var(--color-main)" size={22} />
    </button>
  );
}

const Clubprofile = () => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "0px",
      backgroundColor: "none",
      border: "none",
      borderRadius: "12px",
    },
    overlay: {
      zIndex: 1000,
      backgroundColor: "rgba(0, 0, 0, 0.3)",
    },
  };
  const userId = localStorage.getItem("user_id");
  const navigate = useNavigate();
  const location = useLocation();

  const { GetAllClubDetail } = useBlockContext();
  const [isLoading, setIsLoading] = useState(false);

  const [slideIndex, setSlideIndex] = useState(0);
  const [getclubData, setClubData] = useState("");
  const [getallProducts, setAllProducts] = useState([]);
  const [getclub_id, setclub_id] = useState("");
  const [showalertModal, setShowAlertModal] = useState(false);
  const [showalertModal1, setShowAlertModal1] = useState(false);
  const [showalertMsg, setShowAlertMsg] = useState("");
  const [getfollowBtn, setFollowBtn] = useState(0);
  const [getclubStatus, setClubStatus] = useState("");
  const [club_addvertisement, setclub_addvertisement] = useState([]);
  const [facebook_link, setfacebook_link] = useState("");
  const [twitter_link, settwitter_link] = useState("");
  const [insta_link, setinsta_link] = useState("");
  const [linkdin_link, setlinkdin_link] = useState("");
  const [phone_number, setphone_number] = useState("");
  const [club_story, setclub_story] = useState("");
  const [club_history, setclub_history] = useState("");

  useEffect(() => {
    GetClubDetail();
    GetClubAllBond();
    GetClubFollow();
    console.log("getclubData", getclubData);
  }, []);

  // club detail start
  const GetClubDetail = async () => {
    setIsLoading(true);
    const param = {
      club_id: location.state.club_id,
      user_id: userId,
    };

    await axios
      .post(get_all_club_detail, param)
      .then((res) => {
        setIsLoading(false);
        if (res.data.status == 1) {
          const data = res.data;
          console.log("data", JSON.stringify(data.result, null, 2));
          setclub_addvertisement(data.result.club_addvertisement);
          setfacebook_link(data.result.facebook_link);
          settwitter_link(data.result.twitter_link);
          setinsta_link(data.result.insta_link);
          setlinkdin_link(data.result.linkdin_link);
          setphone_number(data.result.phone_number);
          setclub_story(data.result.club_story);
          setclub_history(data.result.club_history);
          setProducts(data.result.club_img_video);
          setClubData(data.result);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });

    // const data = await GetAllClubDetail(param);
  };
  // club detail end

  // club all bond start
  const GetClubAllBond = async () => {
    const param = {
      club_id: location.state.club_id,
    };
    await axios
      .post(club_get_all_release_bond, qs.stringify(param))
      .then((Response) => {
        if (Response.data.status == 1) {
          console.log("release", Response.data.result);
          setAllProducts(Response.data.result);
          setclub_id(Response.data.club_id);
        } else {
          // setShowAlertModal(true);
          // setShowAlertMsg(Response.data.message);
        }
      })
      .catch((error) => {
        setShowAlertModal(true);
        setShowAlertMsg(error);
      });
  };
  // club all bond end

  // club all follows start
  const GetClubFollow = () => {
    const param = {
      club_id: location.state.club_id,
      user_id: userId,
    };
    console.log("paramuser", param);

    axios
      .post(get_club_follow, qs.stringify(param))

      .then((Response) => {
        console.log("Response", Response);
        if (Response.data.status == 1) {
          setFollowBtn(Response.data.result[0].follow);
        } else {
          setShowAlertModal(true);
          setShowAlertMsg(Response.data.message);
        }
      })
      .catch((error) => {
        setShowAlertModal(true);
        setShowAlertMsg(error);
      });
  };
  // club all follows end

  // handle follow button start
  const handleFollow = () => {
    if (getclubData.is_on_off == 0) {
      setShowAlertModal(true);
      setShowAlertMsg("Currently club is not onboard.");
    } else {
      const param = {
        club_id: location.state.club_id,
        user_id: userId,
      };

      getfollowBtn == 1 ? setFollowBtn(0) : setFollowBtn(1);

      axios
        .post(club_follow, qs.stringify(param))
        .then((Response) => {
          console.log("Response", Response);
          GetClubDetail();
          GetClubAllBond();
          GetClubFollow();
        })
        .catch((error) => {
          setShowAlertModal(true);
          setShowAlertMsg(error);
        });
    }
  };
  // handle follow button end

  // join club btn start

  const handleJoinBtn = () => {
    if (getclubData.free_paid == 0) {
      const param = {
        user_id: userId,
        club_id: location.state.club_id,
      };
      console.log(param);
      axios.post(free_club_join, qs.stringify(param)).then((Response) => {
        console.log("zdvfsgvv", Response.data);
        if (Response.data.status == 1) {
          setShowAlertModal1(true);
          setShowAlertMsg(Response.data.message);
          // GetClubAllBond();
          // GetClubFollow();
          // window.location.reload();
        } else {
          setShowAlertModal1(true);
          setShowAlertMsg(Response.data.message);
          // GetClubAllBond();
          // GetClubFollow();
          // window.location.reload();
        }
      });
    } else {
      navigate("/clubmembership", {
        state: {
          club_id: location.state.club_id,
          user_id: userId,
          profile: getclubData.profile && getclubData.profile,
          name: getclubData.club_name,
          cover: getclubData.cover_photo && getclubData.cover_photo,
        },
      });
    }
  };

  // join club btn end

  const settings = {
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
    dots: true,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (currentSlide) => setSlideIndex(currentSlide),
    customPaging: (i) => (
      <div
        style={{
          width: "10px",
          height: "10px",
          margin: "10px 5px",
          borderRadius: "50%",
          backgroundColor: slideIndex === i ? "#da0e41" : "white",
          opacity: slideIndex === i ? 1 : 0.6,
        }}
      ></div>
    ),
    //    responsive: [
    //      {
    //        breakpoint: 2560,
    //        settings: {
    //          slidesToShow: 2,
    //          slidesToScroll: 1,
    //        },
    //      },
    //      {
    //        breakpoint: 1440,
    //        settings: {
    //          slidesToShow: 2,
    //          slidesToScroll: 1,
    //        },
    //      },
    //      {
    //        breakpoint: 768,
    //        settings: {
    //          slidesToShow: 1,
    //          slidesToScroll: 1,
    //        },
    //      },
    //    ],
  };

  const settings2 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (currentSlide) => setSlideIndex(currentSlide),
    customPaging: (i) => (
      <div
        style={{
          width: "10px",
          height: "10px",
          margin: "10px 5px",
          borderRadius: "50%",
          backgroundColor: slideIndex === i ? "#da0e41" : "white",
          opacity: slideIndex === i ? 1 : 0.6,
        }}
      ></div>
    ),
    //    responsive: [
    //      {
    //        breakpoint: 2560,
    //        settings: {
    //          slidesToShow: 2,
    //          slidesToScroll: 1,
    //        },
    //      },
    //      {
    //        breakpoint: 1440,
    //        settings: {
    //          slidesToShow: 2,
    //          slidesToScroll: 1,
    //        },
    //      },
    //      {
    //        breakpoint: 768,
    //        settings: {
    //          slidesToShow: 1,
    //          slidesToScroll: 1,
    //        },
    //      },
    //    ],
  };

  const [products, setProducts] = useState([]);
  const getData = () => {
    setIsLoading(true);

    const param = {
      club_id: location.state.club_id,
    };

    console.log("====================================");
    console.log("param", param);
    console.log("====================================");

    axios
      .post(
        "https://www.ravi.host/api/club_get_all_release_bond",
        qs.stringify(param)
      )
      .then((Response) => {
        setIsLoading(false);

        console.log("====================================");
        console.log(
          "=====================wwww===============",
          Response.data.result
        );

        if (Response.data.status === 1) {
          setAllProducts(Response.data.result);
        } else {
          // Handle the case when the response status is not 1
          // alert(Response.data.message);
        }

        console.log("Response============.", Response.data.club_id);
      })
      .catch((error) => {
        setIsLoading(false);
        // Handle any other errors that occurred during the request
        console.error(error);
      });
  };

  useEffect(() => {
    getData(); // Call the getData function when the component mounts
  }, []);

  const phoneNumber = phone_number;

  const handlePhoneClick = () => {
    // alert(phoneNumber);
    window.location.href = `tel:${phoneNumber}`;
  };

  const closemodal1 = () => {
    setShowAlertModal1(false);
  };

  const closemodal = () => {
    setShowAlertModal(false);
  };
  const openmodal = () => {
    setShowAlertModal(true);
  };

  const getCoverPhotoLuminance = (imageUrl, callback) => {
    const img = new Image();
    img.crossOrigin = "Anonymous";
    img.src = imageUrl;

    img.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0, img.width, img.height);

      const imageData = ctx.getImageData(0, 0, img.width, img.height).data;
      let r, g, b, avg;
      let colorSum = 0;

      for (let x = 0, len = imageData.length; x < len; x += 4) {
        r = imageData[x];
        g = imageData[x + 1];
        b = imageData[x + 2];

        avg = Math.floor((r + g + b) / 3);
        colorSum += avg;
      }

      const brightness = Math.floor(colorSum / (img.width * img.height));
      callback(brightness > 128 ? "black" : "white"); // Set threshold as 128
    };
  };

  const [arrowColor, setArrowColor] = useState("white");
  useEffect(() => {
    if (getclubData.cover_photo) {
      getCoverPhotoLuminance(getclubData.cover_photo, setArrowColor);
    }
  }, [getclubData.cover_photo]);

  return (
    <div className="userprfile_main_wrapp">
      {isLoading ? (
        <NewLoader />
      ) : (
        <div className="userprfile_base_wrapp">
          {/* banner start */}
          <div className="user_profile_first_wrapp">
            {/* <img
              style={{ backgroundColor: "#000" }}
              src={getclubData.cover_photo}
              alt=""
              className="up_banner_img"
            /> */}
            <ParallaxBanner
              layers={[{ image: getclubData.cover_photo, speed: -15 }]}
              className="up_banner_img aspect-[2/1]"
            />
            <button
              className="up_topbanner_back"
              onClick={() => {
                if (location.state && location.state.is_alltopclub == 1) {
                  navigate("/AllTopClub");
                } else if (
                  location.state &&
                  location.state.is_alltopclub == 2
                ) {
                  navigate("/clubs");
                } else {
                  navigate("/Sponsors", {
                    state: { user_id: location.state.user_id },
                  });
                }
              }}
            >
              {/* <img src={images.back} alt="" /> */}
              <FaAngleLeft color={arrowColor} size={30} />
            </button>
          </div>
          {/* banner end */}

          {/* club Profile start */}
          <div
            className="up_profile_name_wrapp"
            style={{
              justifyContent:
                getclubData?.is_owner == 1 ? "space-between" : "flex-start",
            }}
          >
            <img
              src={
                getclubData.profile ? getclubData.profile : images.mask_profile
              }
              className="up_profile_img"
              alt=""
              onClick={() => {
                // setCropmodal(true);
                navigate("/ProductViewImg2", {
                  state: {
                    img: getclubData.profile
                      ? getclubData.profile
                      : images.mask_profile,
                  },
                });
              }}
            />
            <p className="up_user_name">{getclubData.club_name}</p>
            {getclubData?.is_owner == 1 ? (
              <Link
                style={{
                  width: "30px",
                  height: "30px",
                  marginTop: "30px",
                  marginLeft: "20%",
                }}
                to="/clubsetting"
                state={{
                  club_id: location.state.club_id,
                }}
              >
                <FiSettings
                  color="#fff"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
              </Link>
            ) : null}
          </div>
          {/* club Profile end */}

          {/* club followers start */}
          <div className="cp_follower_wrapp">
            <div className="follow_1sec_sigle_wrapp">
              <p>{getallProducts?.length}</p>
              <span>Aucemo</span>
            </div>
            <div className="cp_follower_vr"></div>
            <Link
              to="/Cluballfollowers"
              state={{ club_id: location.state.club_id }}
              className="follow_1sec_sigle_wrapp"
            >
              <p>{getclubData.club_follow_count}</p>
              <span>Follower</span>
            </Link>
            <div className="cp_follower_vr"></div>
            <button
              onClick={() => {
                getclubData &&
                  getclubData.club_member.length > 0 &&
                  navigate("/clubmembers", {
                    state: {
                      club_id: location.state.club_id,
                    },
                  });
              }}
              className="follow_1sec_sigle_wrapp"
            >
              <p style={{ height: "24px" }}>
                {getclubData.club_member?.length}
              </p>
              <span>Members</span>
            </button>
          </div>
          <p className="cp_clubowner_name">{getclubData.club_bio}</p>
          {/* club followers end */}

          {/* club follow button start */}
          <button className="cp_follow_btn" onClick={handleFollow}>
            {getfollowBtn == 1 ? "Following" : "Follow"}
          </button>
          {/* club follow button end */}

          {/* join btn start */}
          {/* {getclubData.is_on_off == 0 ? (
          <button
            onClick={() => {
              alert("The club is currently off, and you cannot join.");
            }}
            className="cp_follow_btn"
          >
            {getclubData.free_paid == 0 ? "Join Free" : "Join"}
          </button>
        ) : ( */}
          <>
            {getclubData.is_member == 2 ? (
              <button className="cp_follow_btn">
                <BiUser
                  style={{ width: "15px", height: "15px" }}
                  color="#fff"
                />
                Requested
              </button>
            ) : getclubData.is_member == 1 ||
              getclubData?.is_owner == 1 ? null : (
              <button className="cp_follow_btn" onClick={handleJoinBtn}>
                {getclubData.is_on_off == 0
                  ? ""
                  : getclubData.free_paid == 0
                  ? "Join Free"
                  : "Join"}
              </button>
            )}
          </>
          {/* )} */}
          {getclubData.is_member == 1 ? (
            <button className="cp_follow_btn">
              {getclubData.free_paid == 0
                ? "Free Membership"
                : "Plan Expires on " +
                  getclubData.club_package2[0]?.expire_package}
            </button>
          ) : null}
          {/* join btn end */}

          {/* founder start */}
          {getclubData.manager_detail &&
            getclubData.manager_detail.length > 0 && (
              <>
                <p className="founder_heading">Founders</p>
                <div className="cp_founder_wrapp">
                  <Slider {...settings}>
                    {getclubData.manager_detail.map((item, index) => {
                      return (
                        <>
                          <button
                            onClick={() => {
                              console.log("item: ", item.user_id);
                              navigate("/UserProfile", {
                                state: { user_id: item.user_id },
                              });
                            }}
                            className="cp_founder_single_card"
                          >
                            <img src={item.profile} alt="" />
                            <p>{item.firstname}</p>
                          </button>
                        </>
                      );
                    })}
                  </Slider>
                </div>
              </>
            )}
          {/* founder end */}

          {/* exclusive nfbs start */}
          {getclubData.latest_accept_five_bond &&
            getclubData.latest_accept_five_bond.length > 0 && (
              <>
                <div className="all_nfbs_main_wrapp">
                  <p>Exclusive Aucemo</p>
                  {getclubData.latest_accept_five_bond.length == 0 ? null : (
                    <Link
                      to={"/Exclusiveclubnfb"}
                      state={{ item: getclubData.latest_accept_five_bond }}
                    >
                      View all
                    </Link>
                  )}
                </div>
                <div className="up_mynfbs_products_wrapp">
                  {getclubData.latest_accept_five_bond
                    .slice(0, 5)
                    .map((itm, ind) => {
                      console.log("itm", itm);
                      return (
                        <div
                          onClick={() => {
                            navigate("/ProductView", {
                              state: {
                                release_bond_id: itm.release_bond_id,
                                product_id: itm.product_id,
                                is_nfb: itm.is_nfb,
                                uniq_code: itm.uniq_code,
                                theme_index: itm.theme_index,
                                share_by_user: itm.share_by_user,
                                club_id: itm.club_id,
                              },
                            });
                          }}
                          className="up_mynfb_single_product"
                          key={ind}
                        >
                          <img
                            src={itm.product_image_video[0].img_video}
                            alt=""
                          />
                          <p>
                            {itm.product_name} | {itm.total_bond}
                          </p>
                        </div>
                      );
                    })}
                </div>
              </>
            )}
          {/* exclusive nfbs end */}

          {/* 1st buyer royalties nfb start */}
          {getclubData.first_buyer_nfb &&
            getclubData.first_buyer_nfb.length > 0 && (
              <>
                <div className="all_nfbs_main_wrapp">
                  <p>Royalty Aucemo (1st buyer)</p>
                  {getclubData.first_buyer_nfb.length == 0 ? null : (
                    <Link
                      to={"/Royltyclubnfb"}
                      state={{ club_id: location.state.club_id }}
                    >
                      View all
                    </Link>
                  )}
                </div>

                <div className="up_mynfbs_products_wrapp">
                  {getclubData.first_buyer_nfb.slice(0, 5).map((itm, ind) => {
                    console.log("itm", itm);
                    return (
                      <div
                        onClick={() => {
                          navigate("/ProductView", {
                            state: {
                              release_bond_id: itm.release_bond_id,
                              product_id: itm.product_id,
                              is_nfb: itm.is_nfb,
                              uniq_code: itm.uniq_code,
                              theme_index: itm.theme_index,
                              share_by_user: itm.share_by_user,
                              club_id: itm.club_id,
                            },
                          });
                        }}
                        className="up_mynfb_single_product"
                        key={ind}
                      >
                        <img
                          src={itm.product_image_video[0].img_video}
                          alt=""
                        />
                        <p>
                          {itm.product_name} | {itm.total_bond}
                        </p>
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          {/* 1st buyer royalties nfb end */}

          {/* 2nd buyer royalties nfb start */}
          {getclubData.second_buyer_nfb &&
            getclubData.second_buyer_nfb.length > 0 && (
              <>
                <div className="all_nfbs_main_wrapp">
                  <p>Royalty Aucemo (2nd buyer)</p>
                  {getclubData.second_buyer_nfb.length == 0 ? null : (
                    <Link
                      to={"/Royltyclubsecound"}
                      state={{ club_id: location.state.club_id }}
                    >
                      View all
                    </Link>
                  )}
                </div>

                <div className="up_mynfbs_products_wrapp">
                  {getclubData.second_buyer_nfb.slice(0, 5).map((itm, ind) => {
                    console.log("itm", itm);
                    return (
                      <div
                        onClick={() => {
                          navigate("/ProductView", {
                            state: {
                              release_bond_id: itm.release_bond_id,
                              product_id: itm.product_id,
                              is_nfb: itm.is_nfb,
                              uniq_code: itm.uniq_code,
                              theme_index: itm.theme_index,
                              share_by_user: itm.share_by_user,
                              club_id: itm.club_id,
                            },
                          });
                        }}
                        className="up_mynfb_single_product"
                        key={ind}
                      >
                        <img
                          src={itm.product_image_video[0].img_video}
                          alt=""
                        />
                        <p>
                          {itm.product_name} | {itm.total_bond}
                        </p>
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          {/* 2nd buyer royalties nfb end */}

          {/* all nfbs start */}
          {getallProducts && getallProducts.length > 0 && (
            <>
              <div className="all_nfbs_main_wrapp">
                <p>All Aucemo : {getallProducts.length}</p>
                {getallProducts.length == 0 ? null : (
                  <Link
                    to={"/Cluballnfb"}
                    state={{ club_id: location.state.club_id }}
                  >
                    View all
                  </Link>
                )}
              </div>

              <div className="up_mynfbs_products_wrapp">
                {getallProducts.slice(0, 5).map((itm, ind) => {
                  return (
                    <div
                      onClick={() => {
                        navigate("/ProductView", {
                          state: {
                            release_bond_id: itm.release_bond_id,
                            product_id: itm.product_id,
                            is_nfb: itm.is_nfb,
                            uniq_code: itm.uniq_code,
                            theme_index: itm.theme_index,
                            share_by_user: itm.share_by_user,
                            club_id: itm.club_id,
                          },
                        });
                      }}
                      className="up_mynfb_single_product"
                    >
                      <img src={itm.bond_img_video[0].img_video} alt="" />
                      <p>
                        {itm.product_name} | {itm.total_bond}
                      </p>
                    </div>
                  );
                })}
              </div>
            </>
          )}
          {/* all nfbs end */}

          {/* cp four box wrapp start */}
          {getclubData?.is_owner == 1 ? (
            <div className="cp_fourbox_wrapp" style={{ marginTop: "24px" }}>
              <div className="cp_fourbox_sec_1">
                <button
                  onClick={() => {
                    if (getclubData.is_on_off == 0) {
                      setShowAlertModal(true);
                      setShowAlertMsg("Currently club is not onboard.");
                    } else {
                      navigate("/ClubCreateProduct", {
                        state: {
                          club_id: location.state.club_id,
                        },
                      });
                    }
                  }}
                  className="cp_fourbox_singlebox_1"
                  style={{ background: "var(--color-main)" }}
                >
                  <p>Protect Assets</p>
                  <img src={images.createnfb1} alt="" />
                </button>
                <button
                  onClick={() => {
                    if (getclubData.is_on_off == 0) {
                      setShowAlertModal(true);
                      setShowAlertMsg("Currently club is not onboard.");
                    } else {
                      navigate("/clubmyproduct", {
                        state: { club_id: location.state.club_id },
                      });
                    }
                  }}
                  className="cp_fourbox_singlebox_1"
                  style={{ background: "var(--color-alt)" }}
                >
                  <p>My Assets</p>
                  <img src={images.myproducts1} alt="" />
                </button>
              </div>
              <div className="cp_fourbox_sec_2">
                <button
                  onClick={() => {
                    if (getclubData.is_on_off == 0) {
                      setShowAlertModal(true);
                      setShowAlertMsg("Currently club is not onboard.");
                    } else {
                      navigate("/clubreleasednfb", {
                        state: { club_id: location.state.club_id },
                      });
                    }
                  }}
                  className="cp_fourbox_singlebox_2"
                  style={{ background: "var(--color-main)" }}
                >
                  <p>My Aucemo</p>
                  <img src={images.logo_white} alt="" />
                </button>
                <button
                  onClick={() => {
                    if (getclubData.is_on_off == 0) {
                      setShowAlertModal(true);
                      setShowAlertMsg("Currently club is not onboard.");
                    } else {
                      navigate("/club_wallet", {
                        state: { club_id: location.state.club_id },
                      });
                    }
                  }}
                  // to="/club_wallet"
                  // state={{ club_id: location.state.club_id }}
                  className="cp_fourbox_singlebox_2"
                  style={{ background: "var(--color-alt)" }}
                >
                  <p>My Wallets</p>
                  <img src={images.mywallets1} alt="" />
                </button>
              </div>
            </div>
          ) : null}

          {/* cp four box wrapp end */}

          {/* club Received Aucemo start */}
          {getclubData?.is_owner == 1 ? (
            <button
              onClick={() => {
                if (getclubData.is_on_off == 0) {
                  setShowAlertModal(true);
                  setShowAlertMsg("Currently club is not onboard.");
                } else {
                  navigate("/ClubReceivedNfb", {
                    state: { club_id: location.state.club_id },
                  });
                }
              }}
              // to="/ClubReceivedNfb"
              // state={{ club_id: location.state.club_id }}
              className="club_receivednfb_wrapp"
            >
              <img
                src={images.Partner_Reward_Request}
                className="mynfbS_img"
                // style={{ borderRadius: "12px" }}
                alt=""
              />
              <h1 className="h1_mynfbs_details">Received Aucemo</h1>
            </button>
          ) : null}

          {/* club Received Aucemo end */}

          {/* cp gallery start */}
          {products.length == 0 ? null : (
            <>
              <div className="all_nfbs_main_wrapp">
                <p>Gallery</p>
                {/* <Link></Link> */}
              </div>

              <div className="up_mynfbs_products_wrapp">
                {products &&
                  products.slice(0, 5).map((item, index) => {
                    console.log("Adding", item);
                    return (
                      <>
                        <img
                          src={item.img_video}
                          alt=""
                          className="cp_gallery_img"
                          onClick={() => {
                            navigate("/ProductViewImg2", {
                              state: {
                                img: item.img_video ? item.img_video : "",
                              },
                            });
                          }}
                        />
                        {/* <p>
                    {item.product_name} | {item.total_bond}
                  </p> */}
                      </>
                    );
                  })}
              </div>
            </>
          )}
          {/* cp gallery end */}

          {/* cp sponsors start */}
          {club_addvertisement && club_addvertisement.length > 0 ? (
            <>
              <div className="all_nfbs_main_wrapp">
                <p>Sponsors</p>
                {/* <Link></Link> */}
              </div>
              {club_addvertisement &&
                club_addvertisement.map((item, index) => {
                  return (
                    <div className="up_mynfbs_products_wrapp">
                      <img
                        src={item.banner}
                        alt=""
                        className="cp_gallery_single_img"
                      />
                    </div>
                  );
                })}
            </>
          ) : null}
          {/* cp sponsors end */}

          {/* cp social details start */}
          {facebook_link == null &&
          insta_link == null &&
          twitter_link == null &&
          linkdin_link == null &&
          phone_number == null ? null : (
            <>
              <div className="all_nfbs_main_wrapp">
                {/* <p>Social details</p> */}
              </div>

              <div className="cp_social_wrapp">
                {facebook_link == null ? null : (
                  <a href={facebook_link} target="_blank">
                    <img
                      src={images.facebook}
                      alt=""
                      className="cp_social_img"
                    />
                  </a>
                )}
                {twitter_link == null ? null : (
                  <a href={twitter_link} target="_blank">
                    <img
                      src={images.twitter}
                      alt=""
                      className="cp_social_img"
                    />
                  </a>
                )}
                {insta_link == null ? null : (
                  <a href={insta_link} target="_blank">
                    <img
                      src={images.instagram}
                      alt=""
                      className="cp_social_img"
                    />
                  </a>
                )}
                {linkdin_link == null ? null : (
                  <a href={linkdin_link} target="_blank">
                    <img
                      src={images.linkedin}
                      alt=""
                      className="cp_social_img"
                    />
                  </a>
                )}
                <button
                  onClick={() => {
                    handlePhoneClick();
                  }}
                >
                  <img src={images.callclub} alt="" className="cp_social_img" />
                </button>
              </div>
            </>
          )}
          {/* cp social details end */}

          {/* cp history start */}

          {club_history === "" || club_history === null ? null : (
            <>
              <div className="all_nfbs_main_wrapp">{/* <p>History</p> */}</div>

              <p className="cp_history_des">{club_history}</p>
            </>
          )}
          {/* cp history end */}

          {/* cp Story start */}

          {club_story == "" || club_story == null ? null : (
            <>
              <div className="all_nfbs_main_wrapp">
                <p>Story</p>
              </div>

              <p className="cp_history_des">{club_story}</p>
            </>
          )}
          {/* cp Story end */}
          {showalertModal && typeof showalertMsg == "string" && (
            // <AlertModal
            //   title=""
            //   msg={showalertMsg}
            //   setShowModal={setShowAlertModal}
            // />
            // <NewModal
            //   title=""
            //   msg={showalertMsg}
            //   setShowModal={openmodal}
            //   closemodal={closemodal}
            // />

            <V2Modal
              canclebtn={false}
              title=""
              msg={showalertMsg}
              setShowModal={setShowAlertModal}
              okfunction={closemodal}
            />
          )}
        </div>
      )}
      {showalertModal1 && (
        // <div className="modal_login">
        //   <div className="modal-content_login">
        //     <div className="modal_div1">
        //       <img className="forgot_main" src={images.logo} alt="" />
        //     </div>
        //     <div className="modal_div">
        //       {/* <b>{""}</b> */}
        //       <br />
        //       <p className="modal-message"> {showalertMsg}</p>
        //       <br />
        //     </div>
        //     <hr className="hr_forgot" />
        //     <br />
        //     <div className="modal_div">
        //       <button
        //         className="close_forgot"
        //         onClick={() => {
        //           GetClubAllBond();
        //           GetClubFollow();
        //           setShowAlertModal1(false);
        //           window.location.reload();
        //         }}
        //       >
        //         Okay
        //       </button>
        //     </div>
        //   </div>
        // </div>

        // <ReactModal
        //   isOpen={setShowAlertModal1}
        //   // onAfterOpen={afterOpenModal}
        //   onRequestClose={closemodal1}
        //   style={customStyles}
        // >
        //   <div className="NewModal_main_wrapp">
        //     <div
        //       style={{
        //         display: "flex",
        //         flexDirection: "column",
        //         alignItems: "center",
        //         justifyContent: "center",
        //       }}
        //     >
        //       <img className="newmodal_logo" src={images.logo} alt="" />
        //       <p className="newmodal_titel"></p>
        //       {/* <p className="newmodal_titel">Alert</p> */}
        //     </div>

        //     {/* <div className="newmodal_des_wrapp"> */}
        //     <p className="newmodal_des">{showalertMsg}</p>

        //     <button
        //       className="newmodal_btn"
        //       onClick={() => {
        //         GetClubAllBond();
        //         GetClubFollow();
        //         setShowAlertModal1(false);
        //         window.location.reload();
        //       }}
        //     >
        //       Okay
        //     </button>
        //   </div>
        // </ReactModal>

        <V2Modal
          canclebtn={false}
          title=""
          msg={showalertMsg}
          setShowModal={setShowAlertModal1}
          okfunction={() => {
            GetClubAllBond();
            GetClubFollow();
            setShowAlertModal1(false);
            window.location.reload();
          }}
        />
      )}
      {/* {showalertModal && (
        <>
          <AlertModal
            title="Alert"
            msg={showalertMsg}
            setShowModal={setShowAlertModal}
          />
        </>
      )} */}
    </div>
  );
};

export default Clubprofile;
