import React, { useEffect, useRef, useState } from "react";
import "./editmyproduct.css";
import NavbarSec from "../../common/NavbarSec";
import images from "../../constants/images";
import { useLocation, useNavigate } from "react-router-dom";
import {
  AssetLink,
  edit_profile,
  get_all_Resale_interest_product,
  get_all_club_2,
  get_manager_all_user,
  getall_user,
  resale_user_get_product,
} from "../../utils/Constant";
import axios from "axios";
import qs from "qs";
import CusModal from "../../common/cusmodal/CusModal";
import moment from "moment";
import NewModal from "../../common/newmodal/NewModal";
import V2Modal from "../../common/v2modal/V2Modal";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { toast } from "react-toastify";

const Editmyproduct = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [getcustomerarray, setcustomerarray] = useState([]);
  const [getallInteresrs, setAllInterests] = useState([]);
  const userId = localStorage.getItem("user_id");
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [getimg_video, setimg_video] = useState([]);
  const [getbond_img_video, setbond_img_video] = useState([]);

  // user gift states start
  const [getavailableBond, setAvailableBond] = useState(
    location.state.remaining_bond
  );
  const [getsharenfbModal, setSharenfbModal] = useState(false);
  const [getisUserAndClub, setIsUserAndClub] = useState(0);
  const [getshareOwnerModal, setShareOwnerModal] = useState(false);
  const [getalluser, setalluser] = useState([]);
  const [data_filter_data, setdata_filter_data] = useState([]);
  const [getthumbImg, setthumbImg] = useState("");
  const [getuserThumbImg, setUserThumbImg] = useState("");

  const [getdonationOwnerName, setDonationOwnerName] = useState("");
  const [getnoOfBond, setNoOfBond] = useState(null);
  const [
    getnumber_of_bonds_created_for_share,
    setnumber_of_bonds_created_for_share,
  ] = useState([]);
  const [
    getnumber_of_bonds_created_for_share_name,
    setnumber_of_bonds_created_for_share_name,
  ] = useState([]);
  const [
    getnumber_of_bonds_created_for_share_id,
    setnumber_of_bonds_created_for_share_id,
  ] = useState([]);
  const [getdonationOwnerId, setDonationOwnerId] = useState("");
  // user gift states end

  // club gift states start
  const [getshareClubModal, setShareClubModal] = useState(false);
  const [getnoOfBondClub, setNoOfBondClub] = useState(null);
  const [getdonationClubName, setDonationClubName] = useState("");
  const [getdonationClubId, setDonationClubId] = useState("");
  const [getsearchallclubs, setSearchallClubs] = useState([]);
  const [getallclubs, setallClubs] = useState([]);

  const [
    getclub_number_of_bonds_created_for_share,
    setclub_number_of_bonds_created_for_share,
  ] = useState([]);
  const [
    getclub_number_of_bonds_created_for_share_name,
    setclub_number_of_bonds_created_for_share_name,
  ] = useState([]);
  const [
    getclub_number_of_bonds_created_for_share_id,
    setclub_number_of_bonds_created_for_share_id,
  ] = useState([]);
  // club gift states end

  // qrhunt share states start
  const [getdonetQrhunt, setDonetQrhunt] = useState(0);
  const ddd = getavailableBond - getdonetQrhunt;
  // qrhunt share states end

  // manager states start
  const [getallmanager, setallmanager] = useState([]);
  const [getsearchManagerData, setSearchManagerData] = useState([]);
  // manager states end

  useEffect(() => {
    // GetResell();
    allInterest();

    if (location.state && location.state.interest_data) {
      location.state.interest_data.map((itm, index) => {
        const updatedArray = getallInteresrs;
        // updatedArray[index].sa =
        const ddd =
          itm.interest_id === getallInteresrs.map((it) => it.interest_id);

        // this.setState({myArray: updatedArray});
        console.log("itmzx", itm);
        console.log("ddd", ddd);
        console.log("itmzxa", updatedArray);
      });
    }

    setimg_video(location.state.product_media_upload);
    setbond_img_video(location.state.bond_img_video);

    console.log("location.state -->", location.state);
  }, []);

  const GetResell = () => {
    // const param = {
    //   accept_bond_bid_id: location.state.accept_bond_bid_id,
    //   user_id: userId,
    // };
    // console.log(param);
    axios
      .get("https://www.ravi.host/api/get_all_interest")
      .then((Response) => {
        console.log("Success", Response.data.result);
        setcustomerarray(Response.data.result);
        setAllInterests(Response.data.result);
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  const updateStatusBasedOnInterests = (mainArray, selectedArray) => {
    const updatedArray = mainArray.map((item) => {
      const selectedInterest = selectedArray.find(
        (interest) => interest.interest_id == item.id
      );
      if (selectedInterest) {
        return { ...item, status: 2 };
      }
      console.log("item aa", item);

      return item;
    });
    return updatedArray;
  };

  const allInterest = async () => {
    await axios
      .get("https://www.ravi.host/api/get_all_interest")
      .then((Response) => {
        setcustomerarray(Response.data.result);
        setAllInterests(Response.data.result);

        const resultArray = updateStatusBasedOnInterests(
          Response.data.result,
          location.state.interest_data
        );
        // this.setState({
        //   allIntrests: resultArray,
        //   custome_array: resultArray,
        // });
        setcustomerarray(resultArray);
        setAllInterests(resultArray);

        console.log("====================================", resultArray);
        // console.log(
        //   'new_allintrreset',
        //   qs.stringify(this.state.new_allintrreset),
        // );
        console.log("====================================");
      })
      .catch((err) => {
        // this.setState({
        //   isLoading: false,
        // });
        console.log("err", err);
      });
  };

  const [product_name, setProductName] = useState(
    location.state && location.state.product_name
  );
  const [owner_name, setOwnerName] = useState(
    location.state && location.state.owner_name
  );
  const [description, setDescription] = useState(
    location.state && location.state.description
  );
  const [productstory, setproductstory] = useState(
    location.state && location.state.product_history
  );
  const [producthighlights, setproducthighlights] = useState(
    location.state && location.state.product_highlights
  );
  const [product_value, setProductValue] = useState(
    location.state && location.state.product_value
  );
  const [is_owner, setIsOwner] = useState(
    location.state && location.state.is_owner
  );
  const [product_id, setProductId] = useState("");
  const [yy, setYY] = useState(
    location.state && location.state.date_of_creation
      ? moment(location.state.date_of_creation).format("YYYY")
      : ""
  );
  const [mm, setMM] = useState(
    location.state && location.state.date_of_creation
      ? moment(location.state.date_of_creation).format("MM")
      : ""
  );
  const [dd, setDD] = useState(
    location.state && location.state.date_of_creation
      ? moment(location.state.date_of_creation).format("DD")
      : ""
  );
  const [product_price, setProductPrice] = useState(0);
  const [images_array, setImagesArray] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [cusmsg, setCusmsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [getthumbnailindex, setthumbnailindex] = useState();
  const [min_bid_amount, setMinBidAmount] = useState("");
  const [selectedImagesAndVidio, setSelectedImagesAndVidio] = useState([]);
  const [selectedImagesMin1Img, setSelectedImagesMin1Img] = useState([]);

  useEffect(() => {
    // getData();
    // if (location.state && location.state.product_media_upload) {
    //   location.state.product_media_upload.map((itm) => {
    //     // this.setState({
    //     //   images_array: [...this.state.images_array, itm.img_video],
    //     // });
    //     //   this.setState(prevState => ({
    //     //     images_array: [...prevState.images_array, itm.img_video],
    //     //   }));
    //     setImagesArray(...images_array, itm.img_video);
    //   });
    // }
  }, []);

  const handleImageDelete2back = (index, itm) => {
    console.log("itm", itm);

    const param = {
      media_id: itm.media_id,
    };
    axios
      .post(
        "https://www.ravi.host/api/bond_media_img_video_delete",
        qs.stringify(param)
      )
      .then((Response) => {
        if (Response.data.status == 1) {
          console.log("get_club_join_fees res", Response);
          const newImages = [...getimg_video];
          newImages.splice(index, 1);
          setimg_video(newImages);
        }
      })
      .catch((err) => {
        console.log("get_club_join_fees err", err);
      });
  };

  const handleImageAndVideoChange = (e) => {
    const files = Array.from(e.target.files);
    // setImageFiles(e.target.files[0]);
    setSelectedImagesAndVidio((prevSelectedImages) => [
      ...prevSelectedImages,
      ...files,
    ]);
  };

  const handleImageAndVideoDelete = (index) => {
    const newImages = [...selectedImagesAndVidio];
    newImages.splice(index, 1);
    setSelectedImagesAndVidio(newImages);
  };

  const handleImageAndVideoDelete2 = (index) => {
    const newImages = [...selectedImagesMin1Img];
    newImages.splice(index, 1);
    setSelectedImagesMin1Img(newImages);
  };

  const handleImageAndVideoChange2 = (e) => {
    const files = Array.from(e.target.files);
    // setImageFiles(e.target.files[0]);
    setSelectedImagesMin1Img((prevSelectedImages) => [
      ...prevSelectedImages,
      ...files,
    ]);
  };

  const editProduct = () => {
    let idString = "";
    var demo = 0;
    for (let index = 0; index < getcustomerarray.length; index++) {
      if (getcustomerarray[index].status == "2") {
        demo = demo + 1;
        if (index > 0) {
          idString += ",";
        }
        idString += getcustomerarray[index].id;
      }
    }
    console.log("qqedss");

    if (product_name == "") {
      setErrorMessage("Enter asset name");
      setShowModal(true);
    } else if (idString == "") {
      // alert('Select category');

      setErrorMessage("Select category");
      setShowModal(true);
    } else if (
      selectedImagesMin1Img.length == 0 &&
      getbond_img_video.length == 0
    ) {
      // alert('Select category');
      setErrorMessage("Upload atlist one image");
      setShowModal(true);
    } else {
      setIsLoading(true);
      let formData = new FormData();
      formData.append("is_nfb", location.state.is_nfb);
      formData.append("user_id", userId);
      formData.append("product_id", location.state.product_id);
      formData.append("product_name", product_name);
      formData.append("product_highlights", producthighlights);
      formData.append("product_history", productstory);
      formData.append("bond_interest_id", idString);

      //  this.state.images_array.map((i) =>
      //    i == "uri"
      //      ? null
      //      : formData.append("media_upload[]", {
      //          uri: Platform.OS === "android" ? i : i.replace("file://", ""),
      //          name: "name",
      //          type: "image/jpeg",
      //        })
      //  );

      selectedImagesAndVidio.forEach((image, index) => {
        formData.append("media_upload[]", image);
      });

      selectedImagesMin1Img.forEach((image, index) => {
        formData.append("img_video[]", image);
      });

      let idString_usernmae = "";
      let idString_number_of_bond = "";
      let idString_user_id = "";
      var demo = 0;
      for (
        let index = 0;
        index < getnumber_of_bonds_created_for_share.length;
        index++
      ) {
        demo = demo + 1;

        if (index > 0) {
          idString_usernmae += ",";
          idString_number_of_bond += ",";
          idString_user_id += ",";
        }
        idString_usernmae += getnumber_of_bonds_created_for_share_name[index];
        idString_number_of_bond += getnumber_of_bonds_created_for_share[index];
        idString_user_id += getnumber_of_bonds_created_for_share_id[index];
      }

      formData.append("bond_share_owner_name", idString_usernmae);
      formData.append("bond_share_owner_id", idString_user_id);
      formData.append("bond_share_total_assign", idString_number_of_bond);
      // formData.append("total_qr_hunt_bond", getdonetQrhunt);

      let club_idString_usernmae = "";
      let club_idString_number_of_bond = "";
      let club_idString_user_id = "";
      var club_demo = 0;
      for (
        let index = 0;
        index < getclub_number_of_bonds_created_for_share.length;
        index++
      ) {
        club_demo = club_demo + 1;

        if (index > 0) {
          club_idString_usernmae += ",";
          club_idString_number_of_bond += ",";
          club_idString_user_id += ",";
        }
        club_idString_usernmae +=
          getclub_number_of_bonds_created_for_share_name[index];
        club_idString_number_of_bond +=
          getclub_number_of_bonds_created_for_share[index];
        club_idString_user_id +=
          getclub_number_of_bonds_created_for_share_id[index];
      }
      formData.append("share_club_name", club_idString_usernmae);
      formData.append("share_club_id", club_idString_user_id);
      formData.append("share_total_assign", club_idString_number_of_bond);
      formData.append("share_user_id", userId);
      formData.append("bond_value", location.state.remaining_bond);
      formData.append("is_owner", 1);

      formData.append("owner_id", location.state.owner_id);
      formData.append("owner_name", location.state.owner_name);

      console.log("formData 11", JSON.stringify(formData, null, 2));

      axios
        .post("https://www.ravi.host/api/edit_bond_product_2", formData)
        .then((Response) => {
          console.log("Response", Response);
          setIsLoading(true);

          if (Response.data.status == 1) {
            // this.props.navigation.goBack("");
            //   this.props.navigation.replace('ProductUploadSendForApproval', {
            //     is_owner: this.state.is_owner,
            //   });
            // alert(Response.data);
            navigate("/myproduct");
          } else {
            // alert(Response.data.message);
          }
        })
        .catch((err) => {
          // this.setState({
          //   isLoading: false,
          // });
          setIsLoading(true);
          console.log("err", err);
        });
    }
  };

  const toggleInterestStatus = (index) => {
    const updatedArray = [...getcustomerarray];
    updatedArray[index].status = updatedArray[index].status === 1 ? 2 : 1;
    setcustomerarray(updatedArray);
  };

  const createProduct = () => {
    if (min_bid_amount == "") {
      setErrorMessage("Enter minimum bid amount");
      setShowModal(true);
    } else {
      if (min_bid_amount >= product_value) {
        setIsLoading(true);

        let idString = "";
        var demo = 0;
        for (let index = 0; index < getcustomerarray.length; index++) {
          if (getcustomerarray[index].status == "2") {
            demo = demo + 1;

            if (index > 0) {
              idString += ",";
            }
            idString += getcustomerarray[index].interest_id;
          }
        }

        console.log("Final idString: ", idString);

        let formData = new FormData();

        formData.append(
          "accept_bond_bid_id",
          location.state.accept_bond_bid_id
        );
        formData.append("resale_product_id", location.state.resale_product_id);
        formData.append("product_name", product_name);
        formData.append("owner_name", owner_name);
        formData.append("product_id", product_id);
        formData.append("bond_value", "1");
        let date = yy + "-" + mm + "-" + dd;
        formData.append("date_of_creation", date.substr(0, 10));
        formData.append("description", description);
        formData.append("product_value", product_value);
        formData.append("is_owner", is_owner);
        formData.append("bond_interest_id", idString);
        formData.append("owner_user_id", userId);
        formData.append("minimum_bid_amount", min_bid_amount);
        images_array.forEach((i) => {
          if (i !== "uri") {
            formData.append("img_video[]", {
              uri: i, // Assuming the URI is already in the correct format for web
              name: "name",
              type: "image/jpeg",
            });
          }
        });

        formData.append("is_nfb", location.state.is_nfb);

        console.log("formdata-------->", JSON.stringify(formData, null, 2));

        axios
          .post("https://www.ravi.host/api/add_resale_user_product", formData)
          .then((Response) => {
            if (Response.data.status === 1) {
              // alert(Response.data.message);
              setErrorMessage(Response.data.message);
              setShowModal(true);
              // navigation.replace('ResellProduct');
              // navigation.replace('NewAttechResellbond', {
              //   product_id: Response.data.resale_product_id,
              // });
            } else {
              setErrorMessage(Response.data.message);
              setShowModal(true);
              // setIsModalVisible(true);
              // setCusmsg(Response.data.message);
            }
            // setIsLoading(false);
          });
      } else {
        setErrorMessage(
          "Please enter minimum bid value " + product_value / 100
        );
        setShowModal(true);
        // setIsModalVisible(true);
        // setCusmsg('Please enter minimum bid value ' + product_value / 100);
      }
    }
  };

  //disable body scrolling

  const [bodyScrollEnabled, setBodyScrollEnabled] = useState(true);

  useEffect(() => {
    if (showModal) {
      // Disable body scrolling
      setBodyScrollEnabled(false);
    } else {
      // Enable body scrolling
      setBodyScrollEnabled(true);
    }
    if (bodyScrollEnabled) {
      document.body.style.overflow = "visible";
    } else {
      document.body.style.overflow = "hidden";
    }
  }, [showModal, bodyScrollEnabled]);

  const closemodal = () => {
    setShowModal(false);
  };
  const openmodal = () => {
    setShowModal(true);
  };

  const sliderRef = useRef(null);

  const scrollLeft = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({
        left: -200,
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({
        left: 200,
        behavior: "smooth",
      });
    }
  };

  // ---------------------- share functionality start ---------------------

  const handleShareUserAndClubChange = (event) => {
    setIsUserAndClub(event.target.value);

    // new change
    // setDonationOwnerName('');
    // setDonationClubName('');
    // new change

    console.log("event.target.value", event.target.value);
    // setnumber_of_bonds_created_for_share([]);
    // {
    //   (selectedCreator == 1 || selectedCreator == 3) && GetEditProfileApi();
    // }
  };

  const Search_user = (value) => {
    const newData = getalluser.filter(function (item) {
      const employee = item.label ? item.label.toUpperCase() : "".toUpperCase();
      const number = item.phone ? item.phone.toUpperCase() : "".toUpperCase();
      const email = item.email ? item.email.toUpperCase() : "".toUpperCase();

      const textData = value.toUpperCase();
      return (
        employee.indexOf(textData) > -1 ||
        number.indexOf(textData) > -1 ||
        email.indexOf(textData) > -1
      );
    });
    setdata_filter_data(newData);
    console.log("asvsvsds", newData);
  };

  const Search_clubs = (value) => {
    const newData = getsearchallclubs.filter(function (item) {
      const employee = item.club_name
        ? item.club_name.toUpperCase()
        : "".toUpperCase();
      const number = item.phone_number
        ? item.phone_number.toUpperCase()
        : "".toUpperCase();

      // const email = item.email ? item.email.toUpperCase() : "".toUpperCase();

      const textData = value.toUpperCase();
      return employee.indexOf(textData) > -1 || number.indexOf(textData) > -1;
    });
    // console.log("newData", newData);
    setallClubs(newData);
    console.log("asvsvsds", newData);
  };

  // share nfb list delete btn start

  const handleShareDelete = (item, index) => {
    setAvailableBond(
      (prevAvailableNoOfBond) => prevAvailableNoOfBond + parseInt(item)
    );

    const updatedDataName = [...getnumber_of_bonds_created_for_share_name];
    updatedDataName.splice(index, 1);
    setnumber_of_bonds_created_for_share_name(updatedDataName);

    const updatedDataShare = [...getnumber_of_bonds_created_for_share];
    updatedDataShare.splice(index, 1);
    setnumber_of_bonds_created_for_share(updatedDataShare);

    const updatedDataId = [...getnumber_of_bonds_created_for_share_id];
    updatedDataId.splice(index, 1);
    setnumber_of_bonds_created_for_share_id(updatedDataId);
  };

  // share nfb list delete btn end

  // share club nfb list delete btn start

  const handleClubShareDelete = (item, index) => {
    setAvailableBond(
      (prevAvailableNoOfBond) => prevAvailableNoOfBond + parseInt(item)
    );

    const updatedDataName = [...getclub_number_of_bonds_created_for_share_name];
    updatedDataName.splice(index, 1);
    setclub_number_of_bonds_created_for_share_name(updatedDataName);

    const updatedDataShare = [...getclub_number_of_bonds_created_for_share];
    updatedDataShare.splice(index, 1);
    setclub_number_of_bonds_created_for_share(updatedDataShare);

    const updatedDataId = [...getclub_number_of_bonds_created_for_share_id];
    updatedDataId.splice(index, 1);
    setclub_number_of_bonds_created_for_share_id(updatedDataId);
  };

  // share club nfb list delete btn end

  // ------------------ share functionality

  // user clubs and manager get apis start

  const getUser = () => {
    const param = {
      user_id: userId,
    };
    axios
      .post(getall_user, param)
      .then((Response) => {
        // console.log(
        //   "Response.data.result",
        //   JSON.stringify(Response.data.result, null, 2)
        // );
        setalluser(Response.data.result);
        // this.setState({
        //   // data: Response.data.result,
        //   task_arrayholder: Response.data.result,
        // });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllClubs = () => {
    const param = {
      user_id: userId,
    };
    axios
      .post(get_all_club_2, param)
      .then((Response) => {
        // console.log(
        //   "Response.data.result",
        //   JSON.stringify(Response.data.result, null, 2)
        // );
        setallClubs(Response.data.result);
        setSearchallClubs(Response.data.result);
        // this.setState({
        //   // data: Response.data.result,
        //   task_arrayholder: Response.data.result,
        // });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getManager = async () => {
    const param = {
      user_id: userId,
    };
    await axios
      .post(get_manager_all_user, param)
      .then((Response) => {
        setallmanager(Response.data.result);
        setSearchManagerData(Response.data.result);
        // this.setState({
        //   // data: Response.data.result,
        //   task_arrayholder: Response.data.result,
        // });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // user clubs and manager get apis end

  useEffect(() => {
    getUser();
    getAllClubs();
    getManager();
  }, []);

  return (
    <>
      <div className="new_wrapp_container reletive_position">
        <div className="new_container">
          {/* navbar start */}
          <div
            className="legacy_dashbord_nav_sec"
            style={{ margin: "10px auto" }}
          >
            <button
              onClick={() => {
                navigate(-1);
              }}
            >
              <FaAngleLeft color="var(--color-white)" size={20} />
            </button>
            <p>Edit aucemo</p>
            <div></div>
          </div>
          {/* navbar end */}

          <div className="npasset_main_wrapp mb-3">
            <div
              className="npasset_inner_page_wrapp"
              style={{ marginBottom: "0px", padding: "1rem 10px" }}
            >
              {/* Asset name start */}
              <div className="creator_name_wrapp mb-2">
                <p>Asset Name/Title</p>
                <div>
                  <input
                    type="text"
                    placeholder="Enter name"
                    value={product_name}
                    readOnly
                    disabled
                    style={{ background: "#565656" }}
                  />
                </div>
              </div>
              {/* Asset name end */}

              {/* category start */}
              <div className="resell_div2 mb-2" style={{ paddingTop: "0px" }}>
                <p className="re_title">Category</p>
                <div style={{ position: "relative", width: "100%" }}>
                  <button
                    className="nav-button left"
                    onClick={scrollLeft}
                    style={{
                      padding: "8px",
                      top: "30%",
                      left: "0px",
                      width: "36px",
                    }}
                  >
                    <FaAngleLeft color="var(--color-main)" size={22} />
                  </button>
                  <div
                    ref={sliderRef}
                    style={{
                      padding: "0px 2rem",
                    }}
                    className="select_resel_cato"
                  >
                    {getallInteresrs &&
                      getallInteresrs.map((item, index) => {
                        console.log("item", item);
                        return (
                          <button
                            onClick={() => toggleInterestStatus(index)}
                            className="select_resel_cato_sub"
                          >
                            <div
                              className={"img_resel_new"}
                              style={{
                                borderColor: item.status == 2 ? "red" : "gray",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              {/* <p>{item.status}</p> */}
                              <img
                                src={
                                  item.status == 2
                                    ? AssetLink + item.new_color_icon
                                    : AssetLink + item.new_icon
                                }
                                className="img_of_resell"
                                alt=""
                              />
                            </div>
                            {/* <p>{item.name}</p> */}
                            <p
                              style={{
                                textAlign: "center",
                                // width: "%",
                                fontSize: "10px",
                                fontFamily: "var(--font-600)",
                                alignSelf: "center",
                                // marginTop: "5px",
                              }}
                            >
                              {item.name && item.name.length > 10
                                ? `${item.name.slice(0, 10)}...`
                                : item.name}
                            </p>
                          </button>
                        );
                      })}
                  </div>
                  <button
                    className="nav-button right"
                    onClick={scrollRight}
                    style={{
                      justifyContent: "flex-end",
                      padding: "8px",
                      top: "30%",
                      width: "36px",
                      right: "0px",
                    }}
                  >
                    <FaAngleRight color="var(--color-main)" size={22} />
                  </button>
                </div>
              </div>
              {/* category end */}

              {/* Date of creation start */}
              <div className="mb-2">
                <p className="re_title">Date of creation</p>
                <div
                  className="datepicker_inputs_main_wrapp"
                  style={{ margin: "0px" }}
                >
                  <div className="datepicker_single_input">
                    <select value={dd} style={{ background: "#565656" }}>
                      <option value={dd} disabled>
                        {dd}
                      </option>
                    </select>
                  </div>
                  -
                  <div className="datepicker_single_input">
                    <select value={mm} style={{ background: "#565656" }}>
                      <option value={mm} disabled>
                        {mm}
                      </option>
                    </select>
                  </div>
                  -
                  <div className="datepicker_single_input">
                    <select value={yy} style={{ background: "#565656" }}>
                      <option value={yy} disabled selected>
                        {yy}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              {/* Date of creation end */}

              {/* description start */}
              <div className="npasset_yes_sec_wrapp mb-2">
                <p
                  style={{
                    textAlign: "left",
                    width: "100%",
                    fontSize: "12px",
                    marginTop: "10px",
                    lineHeight: "14px",
                  }}
                >
                  Description
                </p>
                <div
                  className="des_textarea_wrapp"
                  style={{ marginTop: "10px" }}
                >
                  <textarea
                    name="product_des"
                    rows="6"
                    className="form_input_box"
                    placeholder="Type Here"
                    value={description}
                    readOnly
                    disabled={true}
                    style={{ background: "#565656" }}
                  ></textarea>
                </div>
              </div>
              {/* description end */}

              {/* Asset story start */}
              <div className="npasset_yes_sec_wrapp mb-2">
                <p
                  style={{
                    textAlign: "left",
                    width: "100%",
                    fontSize: "12px",
                    marginTop: "10px",
                    lineHeight: "14px",
                  }}
                >
                  Asset story/statement
                </p>
                <div
                  className="des_textarea_wrapp"
                  style={{ marginTop: "10px" }}
                >
                  <textarea
                    name="product_des"
                    rows="6"
                    className="form_input_box"
                    placeholder="Type Here"
                    value={
                      productstory == null || productstory == "null"
                        ? ""
                        : productstory
                    }
                    // readOnly
                    onChange={(e) => {
                      setproductstory(e.target.value);
                    }}
                  ></textarea>
                </div>
              </div>
              {/* Asset story end */}

              {/* Asset highlight start */}
              <div className="npasset_yes_sec_wrapp mb-2">
                <p
                  style={{
                    textAlign: "left",
                    width: "100%",
                    fontSize: "12px",
                    marginTop: "10px",
                    lineHeight: "14px",
                  }}
                >
                  Asset highlight
                </p>
                <div
                  className="des_textarea_wrapp"
                  style={{ marginTop: "10px" }}
                >
                  <textarea
                    name="product_des"
                    rows="6"
                    className="form_input_box"
                    placeholder="Type Here"
                    value={
                      producthighlights == null || producthighlights == "null"
                        ? ""
                        : producthighlights
                    }
                    // readOnly
                    onChange={(e) => {
                      setproducthighlights(e.target.value);
                    }}
                  ></textarea>
                </div>
              </div>
              {/* Asset highlight end */}

              {/* curunt owner start */}
              <div className="create_club_userjoin_wrapp mb-2">
                <p style={{ lineHeight: "125%" }}>
                  Are you the current owner ?
                </p>

                <button>
                  <img
                    src={
                      is_owner !== 0
                        ? images.new_accepted_terms
                        : images.new_notaccepted_terms
                    }
                    alt=""
                    // onClick={handleTermChange}
                  />
                  <p>Yes</p>
                </button>

                <button>
                  <img
                    src={
                      is_owner === 0
                        ? images.new_accepted_terms
                        : images.new_notaccepted_terms
                    }
                    alt=""
                    // onClick={handleTermChange}
                  />
                  <p>No</p>
                </button>
              </div>
              {/* curunt owner end */}

              {/* Owner name start */}
              <div className="creator_name_wrapp mb-2">
                <p>Enter owner's name</p>
                <div>
                  <input
                    type="text"
                    placeholder="Enter name"
                    value={owner_name}
                    // onChange={(e) =>}
                    readOnly
                    disabled
                    style={{ background: "#565656" }}
                  />
                </div>
              </div>
              {/* Owner name end */}

              {/* upload media images start */}
              <div
                className="npasset_yes_sec_wrapp"
                style={{ alignItems: "flex-start" }}
              >
                <p
                  style={{
                    fontSize: "12px",
                    width: "100%",
                    textAlign: "left",
                  }}
                >
                  Upload media images
                </p>

                <div
                  className="new_creator_video_wrapp"
                  style={{ margin: "6px auto 1rem auto" }}
                >
                  <label
                    htmlFor="min_1_img"
                    className="new_creator_video_upload_button"
                  >
                    <img src={images.new_upload_logo} alt="" />
                  </label>
                  <input
                    id="min_1_img"
                    type="file"
                    accept="image/*"
                    // capture="camera"
                    multiple
                    maxLength={6}
                    onChange={handleImageAndVideoChange}
                  />
                </div>
                {selectedImagesAndVidio &&
                  selectedImagesAndVidio.length > 0 && (
                    <div
                      className="new_min1_img_preview_wrapp"
                      style={{ marginBottom: "1rem" }}
                    >
                      {selectedImagesAndVidio.map((image, index) => (
                        <div
                          className="new_min1_img_preview_single"
                          key={index}
                        >
                          <img
                            src={URL.createObjectURL(image)}
                            alt={`Preview ${index}`}
                          />

                          <button
                            className="delete-button"
                            onClick={() => handleImageAndVideoDelete(index)}
                          >
                            <img src={images.close_red} alt="" />
                          </button>
                        </div>
                      ))}
                    </div>
                  )}
                {getimg_video && getimg_video.length > 0 && (
                  <div
                    className="new_min1_img_preview_wrapp"
                    style={{ marginBottom: "1rem" }}
                  >
                    {getimg_video.map((itm, ind) => (
                      <div className="new_min1_img_preview_single" key={ind}>
                        <img src={itm.img_video} alt={`Preview ${ind}`} />

                        <button
                          className="delete-button"
                          onClick={() => handleImageDelete2back(ind, itm)}
                        >
                          <img src={images.close_red} alt="" />
                        </button>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              {/* upload media images end */}

              {/* select at least one image start */}
              <div
                className="npasset_yes_sec_wrapp"
                style={{ alignItems: "flex-start" }}
              >
                <p
                  style={{
                    fontSize: "12px",
                    width: "100%",
                    textAlign: "left",
                  }}
                >
                  Upload covor image
                </p>
                <div
                  className="new_creator_video_wrapp"
                  style={{ margin: "6px auto 1rem auto" }}
                >
                  <label
                    htmlFor="media_img_vid"
                    className="new_creator_video_upload_button"
                  >
                    <img src={images.new_upload_logo} alt="" />
                  </label>
                  <input
                    id="media_img_vid"
                    type="file"
                    accept="image/*"
                    // capture="camera"
                    multiple
                    onChange={handleImageAndVideoChange2}
                  />
                </div>
                <div
                  className="new_min1_img_preview_wrapp"
                  style={{ marginBottom: "1rem" }}
                >
                  {selectedImagesMin1Img.map((image, index) => (
                    <div className="new_min1_img_preview_single" key={index}>
                      <img
                        src={URL.createObjectURL(image)}
                        alt={`Preview ${index}`}
                      />

                      <button
                        className="delete-button"
                        onClick={() => handleImageAndVideoDelete2(index)}
                      >
                        <img src={images.close_red} alt="" />
                      </button>
                    </div>
                  ))}
                  {getbond_img_video && getbond_img_video.length > 0
                    ? getbond_img_video.map((itm, ind) => {
                        return (
                          <div
                            className="new_min1_img_preview_single"
                            key={ind}
                          >
                            <img src={itm.img_video} alt={`Preview ${ind}`} />
                          </div>
                        );
                      })
                    : null}
                </div>
              </div>
              {/* select at least one image end */}

              {/* asset value start */}
              <div className="creator_name_wrapp mb-2">
                <p>Enter asset value</p>

                <div>
                  <input
                    type="text"
                    placeholder="Enter value"
                    value={product_value}
                    // onChange={(e) =>}
                    readOnly
                    disabled
                    style={{ background: "#565656" }}
                  />
                </div>
              </div>
              {/* asset value end */}

              {/*gift wrapp start */}
              {location.state.is_gift == 0 &&
                location.state.remaining_bond !== 0 && (
                  <>
                    {/*gift btn start */}

                    <button
                      className="new_btn_wrapp mx-1 mt-3"
                      style={{ marginBottom: "3rem" }}
                      onClick={() => setSharenfbModal(true)}
                    >
                      <p style={{ fontSize: "14px" }}>
                        Gift Aucemo to other user
                      </p>
                    </button>

                    {/*gift btn end */}

                    {/* share list start */}
                    {getnumber_of_bonds_created_for_share.length > 0 ||
                    getclub_number_of_bonds_created_for_share.length > 0 ? (
                      <div
                        style={{
                          width: "95%",
                          margin: "0px auto 3rem auto",
                          border: "1px dashed var(--color-gray)",
                          padding: "10px",
                          borderRadius: "8px",
                        }}
                      >
                        {/* share lists start */}
                        <div>
                          {getnumber_of_bonds_created_for_share &&
                          getnumber_of_bonds_created_for_share.length > 0
                            ? getnumber_of_bonds_created_for_share.map(
                                (item, index) => {
                                  return (
                                    <div
                                      style={{
                                        width: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <p
                                        style={{
                                          color: "var(--color-white)",
                                          fontSize: "12px",
                                        }}
                                      >
                                        {
                                          getnumber_of_bonds_created_for_share_name[
                                            index
                                          ]
                                        }{" "}
                                        - {item}
                                      </p>
                                      <button
                                        onClick={() => {
                                          handleShareDelete(item, index);
                                        }}
                                      >
                                        <img
                                          src={images.close_red}
                                          style={{
                                            width: "24px",
                                            height: "24px",
                                            objectFit: "contain",
                                          }}
                                          alt=""
                                        />
                                      </button>
                                    </div>
                                  );
                                }
                              )
                            : null}
                        </div>
                        {/* share lists end */}

                        {/* club share lists start */}

                        <div>
                          {getclub_number_of_bonds_created_for_share &&
                          getclub_number_of_bonds_created_for_share.length > 0
                            ? getclub_number_of_bonds_created_for_share.map(
                                (item, index) => {
                                  return (
                                    <div
                                      style={{
                                        width: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <p
                                        style={{
                                          color: "var(--color-white)",
                                          fontSize: "12px",
                                        }}
                                      >
                                        {
                                          getclub_number_of_bonds_created_for_share_name[
                                            index
                                          ]
                                        }{" "}
                                        - {item}
                                      </p>
                                      <button
                                        onClick={() => {
                                          handleClubShareDelete(item, index);
                                        }}
                                      >
                                        <img
                                          src={images.close_red}
                                          style={{
                                            width: "24px",
                                            height: "24px",
                                            objectFit: "contain",
                                          }}
                                          alt=""
                                        />
                                      </button>
                                    </div>
                                  );
                                }
                              )
                            : null}
                        </div>

                        {/* club share lists end */}
                      </div>
                    ) : null}
                    {/* share list end */}
                  </>
                )}

              {/*gift wrapp end */}

              {/* submit button start */}

              <button
                onClick={() => {
                  // createProduct();
                  editProduct();
                }}
                className="new_btn_wrapp mt-3"
              >
                <p>Submit</p>
              </button>

              {/* submit button end */}
            </div>
          </div>
        </div>
        {showModal && (
          // <CusModal
          //   msg={errorMessage}
          //   setShowModal={setShowModal}
          //   showModal={showModal}
          // />
          // <NewModal
          //   title=""
          //   msg={errorMessage}
          //   setShowModal={openmodal}
          //   closemodal={closemodal}
          // />

          <V2Modal
            canclebtn={false}
            title=""
            msg={errorMessage}
            setShowModal={setShowModal}
            okfunction={closemodal}
          />
        )}

        {/* aucemo gift modal start */}
        {getsharenfbModal && (
          <div
            // onClick={() => setSharenfbModal(false)}
            className="newasset_gift_rarere_main_wrapp"
            style={{ zIndex: 100 }}
          >
            <div className="newasset_gift_rarere_base_wrapp">
              <div className="gift_rare_fixed_logo_wrapp">
                <img src={images.logo} alt="" />
              </div>
              <p className="gift_rare_modal_heading">
                How many Aucemo you want to gift ? <br /> Available Aucemo{" "}
                {getavailableBond}
              </p>
              <div id="custom_radio" style={{ width: "95%" }}>
                <label class="custom_radio">
                  <input
                    type="radio"
                    name="share_checkbox"
                    value={0}
                    checked={getisUserAndClub == 0}
                    onChange={handleShareUserAndClubChange}
                  />
                  <span class="checkmark"></span>
                  User
                </label>
                <label class="custom_radio">
                  <input
                    type="radio"
                    name="share_checkbox"
                    value={1}
                    checked={getisUserAndClub == 1}
                    onChange={handleShareUserAndClubChange}
                  />
                  <span class="checkmark"></span>
                  Club
                </label>
              </div>
              <>
                {getisUserAndClub == 0 ? (
                  <>
                    {/* user inputs start */}
                    <div className="gift_rare_modal_inputs_wrapp">
                      <input
                        onClick={() => {
                          setShareOwnerModal(true);
                          // setDonationSelect(true);
                          setSharenfbModal(false);
                        }}
                        value={getdonationOwnerName}
                        type="text"
                        name="product_name"
                        // className="available_nfb_modal_input"
                        className="gift_rare_modal_input"
                        placeholder="Search Username"
                      />
                      <input
                        className="gift_rare_modal_input"
                        placeholder="Enter number of Aucemo"
                        value={getnoOfBond}
                        onChange={(e) => {
                          // setNoOfBond(e.target.value)
                          const inputValue = e.target.value;
                          const isValid =
                            /^\d+$/.test(inputValue) &&
                            parseInt(inputValue, 10) >= 0;

                          if (!isValid) {
                            setNoOfBond("");
                            toast.error(
                              "Please enter a valid number of Aucemo",
                              {
                                theme: "colored",
                              }
                            );
                          } else {
                            setNoOfBond(parseInt(inputValue, 10));
                            console.log("isValid", inputValue);
                          }
                        }}
                      />
                      <div
                        style={{
                          margin: "1rem auto",
                        }}
                        className="v2modal_fixed_btns_wrapp"
                      >
                        <button
                          className="v2modal_btn1"
                          onClick={() => {
                            setNoOfBond(null);
                            setNoOfBondClub(null);
                            setDonationOwnerName("");
                            setDonationClubName("");
                            setDonationOwnerId("");
                            setDonationClubId("");
                            setSharenfbModal(false);
                            // setBodyScrollEnabled(true);
                          }}
                        >
                          Cancel
                        </button>

                        <button
                          className="v2modal_btn2"
                          onClick={() => {
                            if (getdonationOwnerName == "") {
                              alert("Search username and select");
                            } else if (getnoOfBond == null) {
                              alert("Please enter number of Aucemo");
                            } else if (getnoOfBond == 0) {
                              alert("You can't make a stock of zero Aucemo");
                            } else if (getnoOfBond > getavailableBond) {
                              alert(
                                "Enter Aucemo value below " +
                                  getavailableBond +
                                  "."
                              );
                            } else if (ddd < getnoOfBond) {
                              toast.error(
                                "Enter Aucemo value below " + ddd + ".",
                                { theme: "colored" }
                              );
                            } else {
                              setnumber_of_bonds_created_for_share([
                                ...getnumber_of_bonds_created_for_share,
                                getnoOfBond,
                              ]);
                              setnumber_of_bonds_created_for_share_name([
                                ...getnumber_of_bonds_created_for_share_name,
                                getdonationOwnerName,
                              ]);
                              setnumber_of_bonds_created_for_share_id([
                                ...getnumber_of_bonds_created_for_share_id,
                                getdonationOwnerId,
                              ]);
                              setAvailableBond(
                                parseInt(getavailableBond) -
                                  parseInt(getnoOfBond)
                              );
                              setNoOfBond(null);
                              setDonationOwnerName("");
                              setDonationOwnerId("");
                              setSharenfbModal(false);
                            }
                          }}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                    {/* user inputs end */}
                  </>
                ) : (
                  // club inputs start
                  <div className="gift_rare_modal_inputs_wrapp">
                    <input
                      onClick={() => {
                        setShareClubModal(true);
                        // setDonationSelect(true);
                        setSharenfbModal(false);
                      }}
                      value={getdonationClubName}
                      type="text"
                      name="product_name"
                      className="gift_rare_modal_input"
                      placeholder="Search Club name"
                    />
                    <input
                      className="gift_rare_modal_input"
                      placeholder="Enter number of Aucemo"
                      value={getnoOfBondClub}
                      onChange={(e) => {
                        // setNoOfBondClub(e.target.value);
                        const inputValue = e.target.value;
                        const isValid =
                          /^\d+$/.test(inputValue) &&
                          parseInt(inputValue, 10) >= 0;

                        if (!isValid) {
                          setNoOfBondClub("");
                          toast.error("Please enter a valid number of Aucemo", {
                            theme: "colored",
                          });
                        } else {
                          setNoOfBondClub(parseInt(inputValue, 10));
                          console.log("isValid", inputValue);
                        }
                      }}
                    />
                    <div
                      style={{
                        margin: "1rem auto",
                      }}
                      className="v2modal_fixed_btns_wrapp"
                    >
                      <button
                        className="v2modal_btn1"
                        onClick={() => {
                          setNoOfBond(null);
                          setNoOfBondClub(null);
                          setDonationOwnerName("");
                          setDonationClubName("");
                          setDonationOwnerId("");
                          setDonationClubId("");
                          setSharenfbModal(false);
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        className="v2modal_btn2"
                        onClick={() => {
                          if (getdonationClubName == "") {
                            // alert("Search club and select");
                            toast.error("Search club and select", {
                              theme: "colored",
                            });
                          } else if (getnoOfBondClub == null) {
                            // alert("Please enter number of Aucemo");
                            toast.error("Please enter number of Aucemo", {
                              theme: "colored",
                            });
                          } else if (getnoOfBondClub == 0) {
                            // alert("You can't make a stock of zero Aucemo");
                            toast.error(
                              "You can't make a stock of zero Aucemo",
                              { theme: "colored" }
                            );
                          } else if (getnoOfBondClub > getavailableBond) {
                            toast.error(
                              "Enter Aucemo value below " +
                                getavailableBond +
                                ".",
                              { theme: "colored" }
                            );
                          } else if (ddd < getnoOfBondClub) {
                            toast.error(
                              "Enter Aucemo value below " + ddd + ".",
                              { theme: "colored" }
                            );
                          } else {
                            setclub_number_of_bonds_created_for_share([
                              ...getclub_number_of_bonds_created_for_share,
                              getnoOfBondClub,
                            ]);
                            setclub_number_of_bonds_created_for_share_name([
                              ...getclub_number_of_bonds_created_for_share_name,
                              getdonationClubName,
                            ]);
                            setclub_number_of_bonds_created_for_share_id([
                              ...getclub_number_of_bonds_created_for_share_id,
                              getdonationClubId,
                            ]);
                            setAvailableBond(
                              parseInt(getavailableBond) -
                                parseInt(getnoOfBondClub)
                            );
                            setNoOfBondClub(null);
                            setDonationClubName("");
                            setDonationClubId("");
                            setSharenfbModal(false);
                          }
                        }}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                  // club inputs end
                )}
              </>
            </div>
          </div>
        )}
        {/* aucemo gift modal end */}

        {/* share owner modal start */}
        {getshareOwnerModal && (
          <div
            className="creator_modal_main_warp"
            style={{ zIndex: 112, background: "#000", position: "fixed" }}
          >
            <div className="creator_modal_base_warp">
              <div className="cp_navbar_wrapp">
                <img
                  onClick={() => {
                    setShareOwnerModal(false);
                    setSharenfbModal(true);
                  }}
                  src={images.back}
                  className="nav_back_btn"
                  alt=""
                />
              </div>
            </div>
            <div className="search mt-2">
              <input
                type="text"
                placeholder="Search"
                // value={getsearchData}
                onChange={(e) => {
                  Search_user(e.target.value);
                }}
              />
              <img className="search-img" src={images.search} alt="" />
            </div>
            <div style={{ width: "100%", height: "80vh", overflow: "scroll" }}>
              {data_filter_data.map((item, index) => (
                <div
                  onClick={() => {
                    // selecteduser(item);
                    setShareOwnerModal(false);
                    setSharenfbModal(true);
                    setDonationOwnerName(item.label);
                    setDonationOwnerId(item.id);
                  }}
                  key={index}
                  className="item-container"
                >
                  <img src={item.profile} alt={item.label} />
                  <div>{item.label}</div>
                </div>
              ))}
            </div>
          </div>
        )}
        {/* share owner modal end */}

        {/* share club modal start */}
        {getshareClubModal && (
          <div
            className="creator_modal_main_warp"
            style={{ zIndex: 112, background: "#000", position: "fixed" }}
          >
            <div className="creator_modal_base_warp">
              <div className="cp_navbar_wrapp">
                <img
                  onClick={() => {
                    setShareClubModal(false);
                    setSharenfbModal(true);
                  }}
                  src={images.back}
                  className="nav_back_btn"
                  alt=""
                />
              </div>
            </div>
            <div className="search mt-2">
              <input
                type="text"
                placeholder="Search"
                // value={getsearchData}
                onChange={(e) => {
                  Search_clubs(e.target.value);
                }}
              />
              <img className="search-img" src={images.search} alt="" />
            </div>
            <div style={{ width: "100%", height: "80vh", overflow: "scroll" }}>
              {getallclubs.map((item, index) => (
                // console.log('item', item)
                <div
                  onClick={() => {
                    // selecteduser(item);
                    setShareClubModal(false);
                    setSharenfbModal(true);
                    setDonationClubName(item.label);
                    setDonationClubId(item.club_id);
                    // console.log("item", item);
                  }}
                  key={index}
                  className="item-container"
                >
                  <img src={item.profile} alt={item.label} />
                  <div>{item.label}</div>
                </div>
              ))}
            </div>
          </div>
        )}
        {/* share club modal end */}
      </div>
    </>
    // <div className="page_main_wrapp">
    //   {isLoading ? (
    //     <div className="loader"></div>
    //   ) : (
    //     <>
    //       <div className="page_base_wrapp_2">
    //         <NavbarSec name="Edit aucemo" />
    //         {/* <div className="resell_div">
    //       <p className="re_title">Enter minimum bid</p>
    //       <input
    //         onChange={(e) => {
    //           setMinBidAmount(e.target.value);
    //         }}
    //         type="number"
    //         className="resell_input_nm"
    //       />
    //     </div> */}
    //         <div className="re_div_hr"></div>
    //         <div className="resell_div2">
    //           <p className="re_title">Asset Name/Title</p>
    //           <input
    //             type="text"
    //             readOnly
    //             name="re_title"
    //             id=""
    //             className="resell_input_title"
    //             // placeholder={product_name}
    //             value={product_name}
    //             // onChange={(e) => setProductName(e.target.value)}
    //           />
    //         </div>
    //         <div className="resell_div2">
    //           <p className="re_title">Category</p>
    //           <div style={{ position: "relative", width: "100%" }}>
    //             <button
    //               className="nav-button left"
    //               onClick={scrollLeft}
    //               style={{
    //                 padding: "8px",
    //                 top: "30%",
    //                 left: "0px",
    //                 width: "36px",
    //               }}
    //             >
    //               <FaAngleLeft color="var(--color-main)" size={22} />
    //             </button>
    //             <div
    //               ref={sliderRef}
    //               style={{
    //                 padding: "0px 2rem",
    //               }}
    //               className="select_resel_cato"
    //             >
    //               {getallInteresrs &&
    //                 getallInteresrs.map((item, index) => {
    //                   console.log("item", item);
    //                   return (
    //                     <button
    //                       onClick={() => toggleInterestStatus(index)}
    //                       className="select_resel_cato_sub"
    //                     >
    //                       <div
    //                         className={"img_resel_new"}
    //                         style={{
    //                           borderColor: item.status == 2 ? "red" : "gray",
    //                         }}
    //                       >
    //                         {/* <p>{item.status}</p> */}
    //                         <img
    //                           src={
    //                             "https://www.ravi.host/public/assets/interest/" +
    //                             item.icon
    //                           }
    //                           className="img_of_resell"
    //                           alt=""
    //                         />
    //                       </div>
    //                       {/* <p>{item.name}</p> */}
    //                       <p
    //                         style={{
    //                           textAlign: "center",
    //                           // width: "%",
    //                           fontSize: "10px",
    //                           fontFamily: "var(--font-600)",
    //                           alignSelf: "center",
    //                           // marginTop: "5px",
    //                         }}
    //                       >
    //                         {item.name && item.name.length > 10
    //                           ? `${item.name.slice(0, 10)}...`
    //                           : item.name}
    //                       </p>
    //                     </button>
    //                   );
    //                 })}
    //             </div>
    //             <button
    //               className="nav-button right"
    //               onClick={scrollRight}
    //               style={{
    //                 justifyContent: "flex-end",
    //                 padding: "8px",
    //                 top: "30%",
    //                 width: "36px",
    //                 right: "0px",
    //               }}
    //             >
    //               <FaAngleRight color="var(--color-main)" size={22} />
    //             </button>
    //           </div>
    //         </div>
    //         <br />
    //         <div className="resell_div_3">
    //           <p className="re_title2">Date of creation</p>
    //           <input
    //             value={dd}
    //             type="number"
    //             className="resell_input_title2"
    //           />{" "}
    //           &nbsp;/
    //           <input value={mm} type="number" className="resell_input_title2" />
    //           &nbsp;/
    //           <input value={yy} type="number" className="resell_input_title2" />
    //         </div>
    //         <div className="resell_div2">
    //           <p className="re_title2">Description</p>
    //           <textarea
    //             name=""
    //             id=""
    //             cols="30"
    //             rows="10"
    //             value={description}
    //             className="resell_input_title"
    //             readOnly
    //           ></textarea>
    //         </div>
    //         <div className="resell_div2">
    //           <p className="re_title2">Asset story/Statement</p>
    //           <textarea
    //             name=""
    //             id=""
    //             cols="30"
    //             rows="10"
    //             value={
    //               productstory == null || productstory == "null"
    //                 ? ""
    //                 : productstory
    //             }
    //             className="form_input_box"
    //             onChange={(e) => {
    //               setproductstory(e.target.value);
    //             }}
    //             // readOnly
    //           ></textarea>
    //         </div>
    //         <div className="resell_div2">
    //           <p className="re_title2">Asset Highlights</p>
    //           <textarea
    //             name=""
    //             id=""
    //             cols="30"
    //             rows="10"
    //             value={
    //               producthighlights == null || producthighlights == "null"
    //                 ? ""
    //                 : producthighlights
    //             }
    //             className="form_input_box"
    //             onChange={(e) => {
    //               setproducthighlights(e.target.value);
    //             }}
    //             // readOnly
    //           ></textarea>
    //         </div>
    //         <div className="resell_div_4">
    //           <p className="re_title4">Are you the current owner ?</p>
    //           <div className="radio_btn_resel">
    //             <input
    //               type="radio"
    //               readOnly
    //               checked={is_owner !== 0}
    //               className="resell_radio_title"
    //             />
    //             Yes
    //             <input
    //               type="radio"
    //               readOnly
    //               checked={is_owner == 0}
    //               className="resell_radio_title"
    //             />{" "}
    //             No
    //           </div>
    //         </div>
    //         <div className="resell_div2">
    //           <p className="re_title">Enter owner's name</p>
    //           <input
    //             value={owner_name}
    //             type="number"
    //             readOnly
    //             className="resell_input_title"
    //             placeholder={owner_name}
    //           />
    //         </div>
    //         <div className="resell_div2">
    //           {/* <p className="re_title">Upload images/videos</p> */}
    //           {/* <div className="img_of_list">
    //         {images_array &&
    //           images_array.map((item, index) => {
    //             return item == "uri" ? null : (
    //               <img
    //                 onClick={() => {
    //                   setthumbnailindex(index);
    //                 }}
    //                 src={item}
    //                 className={
    //                   getthumbnailindex == index
    //                     ? "img_of_resellist_border"
    //                     : "img_of_resellist"
    //                 }
    //                 alt=""
    //               />
    //             );
    //           })}
    //       </div> */}

    //           {/* UPLOAD MINIMUM 1 IMAGE START */}
    //           <label htmlFor="product_name" className="form_input_label mt-2">
    //             Upload media images
    //           </label>
    //           <div className="image-upload">
    //             <label htmlFor="min_1_img" className="upload-button">
    //               <img src={images.gallery_icon} alt="" />
    //             </label>
    //             <input
    //               id="min_1_img"
    //               type="file"
    //               multiple
    //               onChange={handleImageAndVideoChange}
    //             />
    //             <div className="image-previews">
    //               {selectedImagesAndVidio.map((image, index) => (
    //                 <div className="image-preview" key={index}>
    //                   <img
    //                     src={URL.createObjectURL(image)}
    //                     alt={`Preview ${index}`}
    //                   />
    //                   <button
    //                     className="delete-button"
    //                     onClick={() => handleImageAndVideoDelete(index)}
    //                   >
    //                     <img src={images.close_red} alt="" />
    //                   </button>
    //                 </div>
    //               ))}

    //               {getimg_video && getimg_video.length > 0
    //                 ? getimg_video.map((itm, ind) => {
    //                     return (
    //                       <div className="image-preview" key={ind}>
    //                         <img src={itm.img_video} alt={`Preview ${ind}`} />
    //                         <button
    //                           className="delete-button"
    //                           onClick={() => handleImageDelete2back(ind, itm)}
    //                         >
    //                           <img src={images.close_red} alt="" />
    //                         </button>
    //                       </div>
    //                     );
    //                   })
    //                 : null}
    //             </div>
    //           </div>
    //           {/* UPLOAD MINIMUM 1 IMAGE END */}

    //           {/* UPLOAD MINIMUM 1 IMAGE START */}
    //           <label htmlFor="product_name" className="form_input_label mt-2">
    //             Upload images
    //           </label>
    //           <div className="image-upload">
    //             <label htmlFor="media_img_vid" className="upload-button">
    //               <img src={images.gallery_icon} alt="" />
    //             </label>
    //             <input
    //               id="media_img_vid"
    //               type="file"
    //               multiple
    //               onChange={handleImageAndVideoChange2}
    //             />
    //             <div className="image-previews">
    //               {selectedImagesMin1Img.map((image, index) => (
    //                 <div className="image-preview" key={index}>
    //                   <img
    //                     src={URL.createObjectURL(image)}
    //                     alt={`Preview ${index}`}
    //                   />
    //                   <button
    //                     className="delete-button"
    //                     onClick={() => handleImageAndVideoDelete2(index)}
    //                   >
    //                     <img src={images.close_red} alt="" />
    //                   </button>
    //                 </div>
    //               ))}

    //               {getbond_img_video && getbond_img_video.length > 0
    //                 ? getbond_img_video.map((itm, ind) => {
    //                     return (
    //                       <div className="image-preview" key={ind}>
    //                         <img src={itm.img_video} alt={`Preview ${ind}`} />
    //                       </div>
    //                     );
    //                   })
    //                 : null}
    //             </div>
    //           </div>
    //           {/* UPLOAD MINIMUM 1 IMAGE END */}

    //           {/* <div className="image-upload">
    //             <label htmlFor="media_img_vid" className="upload-button">
    //               <img src={images.gallery_icon} alt="" />
    //             </label>
    //             <input
    //               id="media_img_vid"
    //               type="file"
    //               multiple
    //               onChange={handleImageAndVideoChange}
    //             />
    //             <div className="image-previews">
    //               {selectedImagesAndVidio.map((image, index) => (
    //                 <div className="image-preview" key={index}>
    //                   <img
    //                     src={URL.createObjectURL(image)}
    //                     alt={`Preview ${index}`}
    //                   />
    //                   <button
    //                     className="delete-button"
    //                     onClick={() => handleImageAndVideoDelete(index)}
    //                   >
    //                     <img src={images.close_red} alt="" />
    //                   </button>
    //                 </div>
    //               ))}
    //             </div>
    //           </div> */}
    //         </div>
    //         <div className="resell_div">
    //           <p className="re_title3">Enter asset value</p>
    //           <input
    //             // value={product_value}
    //             placeholder={product_value}
    //             readOnly
    //             type="number"
    //             className="resell_input_title3"
    //           />
    //         </div>
    //         <br />
    //         <center>
    //           <button
    //             onClick={() => {
    //               // createProduct();
    //               editProduct();
    //             }}
    //             className="new_btn_wrapp mt-3"
    //           >
    //             {/* Resell */}
    //             <p>Done</p>
    //           </button>
    //         </center>
    //         <br />
    //       </div>

    // </div>
  );
};

export default Editmyproduct;
