import React, { useEffect, useState } from "react";
import images from "../../constants/images";
import "./mymemberships.css";
import { Link, useNavigate } from "react-router-dom";
import BackButton from "../../components/BackButton";
import axios from "axios";
import qs from "qs";
import { get_user_club_member_detail } from "../../utils/Constant";
import { FaAngleLeft } from "react-icons/fa";

const Mymemberships = () => {
  const navigate = useNavigate();
  const userId = localStorage.getItem("user_id");
  const [isloading, setIsLoading] = useState(false);
  const [getdata, setData] = useState([]);
  const [getisMembers, setIsMembers] = useState(false);
  const [getisOk, setIsOk] = useState(null);

  useEffect(() => {
    GetMemberData();
  }, []);

  const GetMemberData = async () => {
    setIsLoading(true);

    const param = {
      user_id: userId,
    };

    await axios
      .post(get_user_club_member_detail, qs.stringify(param))
      .then((Response) => {
        if (Response.data.status == 1) {
          setData(Response.data.result);
          setIsMembers(false);
          setIsLoading(false);
          setIsOk(0);
        } else {
          setIsOk(1);
        }
      })
      .catch((err) => {
        console.log("get_club_join_fees err", err);
        setIsMembers(true);
        setIsLoading(false);
      });
  };

  return (
    <div className="mymemberships_main">
      <div className="mymemberships_2">
        {/* <div className="mymemberships_header">
          <div className="mymemberships_main_header_notifi">
            <div className="mymembershipsmain1">
              <BackButton />
            </div>
            <div className="mymembershipsmain2">
            </div>
          </div>
        </div> */}

        <div
          className="legacy_dashbord_nav_sec"
          style={{ margin: "10px auto" }}
        >
          <button
            onClick={() => {
              navigate(-1);
            }}
          >
            <FaAngleLeft color="var(--color-white)" size={20} />
          </button>
          <p>My Membership</p>
          <div></div>
          {/* <img
                onClick={() => {
                  navigate("/CreateLegacy");
                }}
                src={images.round_plus}
                alt=""
              /> */}
        </div>
        <div className="mymemberships_club_user">
          {getdata && getdata.length > 0
            ? getdata.map((item, index) => {
                console.log("item -->", item);
                return (
                  <Link
                    to="/Clubprofile"
                    state={{ club_id: item.club_id }}
                    className="mymemberships_details"
                  >
                    <img
                      src={item.cover_photo}
                      className="mymemberships_details_img1"
                      alt=""
                    />
                    <div>
                      <p>{item.club_name}</p>
                      <img
                        src={item.profile}
                        className="mymemberships_details_img2"
                        alt=""
                      />
                    </div>
                  </Link>
                );
              })
            : null}
        </div>

        {/* my membership nodata text start */}

        {getisOk === 1 && (
          <>
            <p
              style={{
                color: "var(--color-white)",
                fontSize: "16px",
                fontFamily: "var(--font-600)",
                width: "100%",
                margin: "10px auto",
                textAlign: "center",
                marginTop: "20px",
              }}
            >
              Join a Club now and unlock limitless possibilities with Aucemo! Be
              part of an exclusive community, access unique benefits, and embark
              on a journey of collective success. Join today and elevate your
              Aucemo experience!
            </p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Link
                to={"/clubs"}
                className="new_btn_wrapp mx-1"
                style={{
                  width: "60%",
                }}
              >
                Join Clubs
              </Link>
            </div>
          </>
        )}

        {/* my membership nodata text end */}
      </div>
    </div>
  );
};

export default Mymemberships;
