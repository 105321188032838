import React, { useEffect, useRef, useState } from "react";
// import "./creatednfbs";
// import images from "../../constants/images";
// import NavbarSec from "../../common/NavbarSec";
// import Cardnfb from "./Cardnfb";
// import Cardnfbsecond from "./Cardnfbsecond";
// import Cardnfbthree from "./Cardnfbthree";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import qs from "qs";
import Cardnfbsecond from "./createnfb2/Cardnfbsecond";
import Cardnfbthree from "./createnfb2/Cardnfbthree";
import Cardnfbfour from "./createnfb2/Cardnfbfour";
import Cardnfbfive from "./createnfb2/Cardnfbfive";
import Cardnfbsix from "./createnfb2/Cardnfbsix";
import Cardnfbseven from "./createnfb2/Cardnfbseven";
import Cardnfbeight from "./createnfb2/Cardnfbeight";
import Cardnfb from "./createnfb2/Cardnfb";
import NavbarSec from "../common/NavbarSec";
import images from "../constants/images";
import {
  club_preview_product,
  preview_product,
  update_bond_img,
  update_club_bond_img,
} from "../utils/Constant";
import AlertModal from "../components/AlertModal";
import Cardnfb13 from "./createnfb2/Cardnfb13";
import Card14 from "./createnfb2/Card14";
import Card15 from "./createnfb2/Card15";
import getCroppedImg from "../pages/createnfb2/createImage";
import Cropper from "react-easy-crop";
import NewModal from "../common/newmodal/NewModal";
import ReactModal from "react-modal";
import NewNfbFrame1 from "../frames/nfbframes/NewNfbFrame1";
import NewNfbFrame2 from "../frames/nfbframes/NewNfbFrame2";
import NewNfbFrame3 from "../frames/nfbframes/NewNfbFrame3";
import NewNfbFrame4 from "../frames/nfbframes/NewNfbFrame4";
import NewNfbFrame5 from "../frames/nfbframes/NewNfbFrame5";
import NewNfbFrame6 from "../frames/nfbframes/NewNfbFrame6";
import NewNfbFrame7 from "../frames/nfbframes/NewNfbFrame7";
import NewNfbFrame8 from "../frames/nfbframes/NewNfbFrame8";
import NewNfbFrame9 from "../frames/nfbframes/NewNfbFrame9";
import NewNfbFrame10 from "../frames/nfbframes/NewNfbFrame10";
import V2Modal from "../common/v2modal/V2Modal";
import { ToastContainer, toast } from "react-toastify";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import {
  NewEditNfbFrame1,
  NewEditNfbFrame10,
  NewEditNfbFrame2,
  NewEditNfbFrame3,
  NewEditNfbFrame4,
  NewEditNfbFrame5,
  NewEditNfbFrame6,
  NewEditNfbFrame7,
  NewEditNfbFrame8,
  NewEditNfbFrame9,
} from "../frames/neweditnfbframes";
import { IoClose } from "react-icons/io5";

const ClubCreatenfbs = () => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "0px",
      backgroundColor: "none",
      border: "none",
      borderRadius: "12px",
    },
    overlay: {
      zIndex: 1000,
      backgroundColor: "rgba(0, 0, 0, 0.3)",
    },
  };
  const navigate = useNavigate();
  const tic = localStorage.getItem("theme_index_club");
  const fic = localStorage.getItem("frames_index_club");

  const [selectedFrame, setSelectedFrame] = useState(
    fic == "" ||
      fic == null ||
      fic == "null" ||
      fic == "undefined" ||
      fic == undefined
      ? "frames1"
      : fic
  );
  const [getsearchdata, setsearchdata] = useState([]);
  const [loading, setloading] = useState(false);
  const [getok, setok] = useState(null);
  const userId = localStorage.getItem("user_id");
  const [showModal, setShowModal] = useState(false);
  const [showinfoModal, setShowInfoModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [no_of_bond, setno_of_bond] = useState("");
  const handleFrameClick = (frame) => {
    setSelectedFrame(frame);
  };

  // const carddata = () => {

  // }

  const [getdata, setdata] = useState([]);

  const location = useLocation();

  useEffect(() => {
    Previewproduct();
    ReleaseProduct();
    GetAllData();

    console.log("location.state.---->", location.state);
  }, []);

  const [qr_code, setqr_code] = useState("");
  const [owner_name, setowner_name] = useState("");
  const [creator_name, setcreator_name] = useState("");
  const [product_name, setproduct_name] = useState("");
  const [description, setdescription] = useState("");
  const [created_at, setcreated_at] = useState("");
  const [no_of_release_bond, setno_of_release_bond] = useState("");
  const [max_bond, setmax_bond] = useState(0);
  const [theme_index, settheme_index] = useState(
    tic == "" ||
      tic == null ||
      tic == "null" ||
      tic == "undefined" ||
      tic == undefined
      ? 16
      : tic
  );
  const [assign_no_of_bond, setassign_no_of_bond] = useState(0);
  const [remaining_bond_release_date, setremaining_bond_release_date] =
    useState("");
  const [product_value, setproduct_value] = useState("");
  const [product_img_video_carosel, setproduct_img_video_carosel] =
    useState("");
  const [product_img_video_carosel3, setproduct_img_video_carosel3] =
    useState("");

  const [available_no_of_bond, setavailable_no_of_bond] = useState(0);
  const [attched_benifit_data, setattched_benifit_data] = useState([]);
  const [bond_img_id, setbond_img_id] = useState("");
  const [img_index, set_img_index] = useState(0);

  const [ismodalopen, setIsModalOpen] = useState(false);
  const [getmsg, setMsg] = useState("");
  const [getmodalName, setModalName] = useState("");

  // crop start
  const minZoom = 0.4;
  const selectedImageRef = useRef(null);

  const [getcropmodal, setCropmodal] = useState(false);
  const [imageSrc, setImageSrc] = useState("");
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(minZoom);
  const [croppedImageBlob, setCroppedImageBlob] = useState(null);
  const [selectedImageSrc, setSelectedImageSrc] = useState(null);
  const [getsubmitmodal, setSubmitModal] = useState(false);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [getall_uniqcode, setAll_uniqcode] = useState("");

  const [getsetImgsModal, setSetImgsModal] = useState(false);

  const openSetImgsModal = () => {
    setSetImgsModal(!getsetImgsModal);
  };

  const onCropChange = (crop) => {
    setCrop(crop);
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    console.log(croppedArea, croppedAreaPixels);
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const onZoomChange = (zoom) => {
    setZoom(zoom);
  };

  const showCroppedImage = async () => {
    const croppedImage = await getCroppedImg(
      imageSrc,
      croppedAreaPixels,
      "https://aucemo.club/logos_back2.png"
    );
    console.log(croppedImage);
    setCroppedImageBlob(croppedImage);
    console.log("blobii", URL.createObjectURL(croppedImage));

    // setCroppedImage(URL.createObjectURL(croppedImage));
    // setCropData(URL.createObjectURL(croppedImage));

    setSelectedImageSrc(URL.createObjectURL(croppedImage));
    setCropmodal(false);
    setSubmitModal(true);
    // setImage("");
  };

  const deleteimage = async (club_img_id) => {
    console.log("vishal", club_img_id);

    try {
      // Fetch the image as a binary blob
      setloading(true);

      let formData = new FormData();
      formData.append("club_img_id", club_img_id);

      console.log("param------>", formData);
      axios
        .post("https://www.ravi.host/api/delete_club_crop_image", formData)
        .then((Response) => {
          console.log("acdascs", Response.data.result);
          const data = Response.data.result;
          setloading(false);
          if (Response.data.status == 1) {
            Previewproduct();
            ReleaseProduct();
            GetAllData();
            console.log("Response.data", Response.data);
            setSubmitModal(false);
          } else {
            setSubmitModal(false);
            // setok(1);
          }
          // this.setState({
          //   // data: Response.data.result,
          //   task_arrayholder: Response.data.result,
          // });
        })
        .catch((err) => {
          setloading(false);
          console.log(err);
          setSubmitModal(false);
        });
    } catch (error) {
      console.error("Error fetching image:", error);
    }
  };

  const handleImageSubmit = () => {
    // dataURItoBlob(croppedImage);
    setCropImage(croppedImageBlob);
  };

  const setCropImage = (image) => {
    console.log("image", image);
    setloading(true);
    let formData = new FormData();
    formData.append("club_product_id", location.state.club_product_id);
    formData.append("img_video[]", image);
    console.log("param------>", formData);
    axios
      .post(update_club_bond_img, formData)
      .then((Response) => {
        console.log("acdascs", Response.data.result);
        const data = Response.data.result;
        setloading(false);
        if (Response.data.status == 1) {
          Previewproduct();
          ReleaseProduct();
          GetAllData();
          console.log("Response.data", Response.data);
          setSubmitModal(false);
        } else {
          setSubmitModal(false);
          // setok(1);
        }
        // this.setState({
        //   // data: Response.data.result,
        //   task_arrayholder: Response.data.result,
        // });
      })
      .catch((err) => {
        setloading(false);
        console.log(err);
        setSubmitModal(false);
      });
  };

  // crop end

  const Previewproduct = () => {
    setloading(true);
    const param = {
      club_product_id: location.state.club_product_id,
      user_id: location.state.club_user_id,
      club_id: location.state.club_id,
      share_by_user: location.state.share_by_user,
    };
    console.log("param------>", param);
    axios
      .post(club_preview_product, param)
      .then((Response) => {
        console.log("acdascs -=-=->", Response.data.result);
        const data = Response.data.result;
        setloading(false);
        if (Response.data.status == 1) {
          setdata(data);
          setqr_code(data.qr_code);
          setowner_name(data.owner_name);
          setcreator_name(data.creator_name);
          setproduct_name(data.product_name);
          setdescription(data.description);
          setcreated_at(data.created_at);
          setno_of_release_bond(data.no_of_release_bond);
          setremaining_bond_release_date(data.remaining_bond_release_date);
          setproduct_value(data.product_value);
          setproduct_img_video_carosel(data.product_img_video);
          // setproduct_img_video_carosel3(data.product_img_video[0].product_icon);
          setbond_img_id(data.product_img_video[0].club_img_id);
        } else {
          // setok(1);
        }
        // this.setState({
        //   // data: Response.data.result,
        //   task_arrayholder: Response.data.result,
        // });
      })
      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };

  let selectedCardComponent;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const savedScrollPosition = sessionStorage.getItem("scrollPosition");
    if (savedScrollPosition) {
      window.scrollTo(0, parseInt(savedScrollPosition, 10));
      sessionStorage.removeItem("scrollPosition");
      console.log("first", parseInt(savedScrollPosition, 10));
    }

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (getdata) {
    // if (theme_index == 0) {
    //   selectedCardComponent = (
    //     // <Cardnfbthree data={getdata} icon={product_img_video_carosel3} />
    //     <Cardnfbsecond
    //       data={getdata}
    //       icon={product_img_video_carosel3}
    //       setIsModalOpen={setIsModalOpen}
    //       setMsg={setMsg}
    //       setModalName={setModalName}
    //     />
    //   );
    // } else if (theme_index == 6) {
    //   selectedCardComponent = (
    //     <Cardnfb
    //       data={getdata}
    //       icon={product_img_video_carosel3}
    //       setIsModalOpen={setIsModalOpen}
    //       setMsg={setMsg}
    //       setModalName={setModalName}
    //     />
    //   );
    // } else if (theme_index == 7) {
    //   selectedCardComponent = (
    //     <Cardnfbsecond
    //       data={getdata}
    //       icon={product_img_video_carosel3}
    //       setIsModalOpen={setIsModalOpen}
    //       setMsg={setMsg}
    //       setModalName={setModalName}
    //     />
    //   );
    // } else if (theme_index == 8) {
    //   selectedCardComponent = (
    //     <Cardnfbfour
    //       data={getdata}
    //       icon={product_img_video_carosel3}
    //       setIsModalOpen={setIsModalOpen}
    //       setMsg={setMsg}
    //       setModalName={setModalName}
    //     />
    //   );
    // } else if (theme_index == 9) {
    //   selectedCardComponent = (
    //     <Cardnfbfive
    //       data={getdata}
    //       icon={product_img_video_carosel3}
    //       setIsModalOpen={setIsModalOpen}
    //       setMsg={setMsg}
    //       setModalName={setModalName}
    //     />
    //   );
    // } else if (theme_index == 10) {
    //   selectedCardComponent = (
    //     <Cardnfbsix
    //       data={getdata}
    //       icon={product_img_video_carosel3}
    //       setIsModalOpen={setIsModalOpen}
    //       setMsg={setMsg}
    //       setModalName={setModalName}
    //     />
    //   );
    // } else if (theme_index == 11) {
    //   selectedCardComponent = (
    //     <Cardnfbseven
    //       data={getdata}
    //       icon={product_img_video_carosel3}
    //       setIsModalOpen={setIsModalOpen}
    //       setMsg={setMsg}
    //       setModalName={setModalName}
    //     />
    //   );
    // } else if (theme_index == 12) {
    //   selectedCardComponent = (
    //     <Cardnfbeight
    //       data={getdata}
    //       icon={product_img_video_carosel3}
    //       setIsModalOpen={setIsModalOpen}
    //       setMsg={setMsg}
    //       setModalName={setModalName}
    //     />
    //   );
    // } else if (theme_index == 13) {
    //   selectedCardComponent = (
    //     <Cardnfb13
    //       data={getdata}
    //       icon={product_img_video_carosel3}
    //       setIsModalOpen={setIsModalOpen}
    //       setMsg={setMsg}
    //       setModalName={setModalName}
    //     />
    //   );
    // } else if (theme_index == 14) {
    //   selectedCardComponent = (
    //     <Card14
    //       data={getdata}
    //       icon={product_img_video_carosel3}
    //       setIsModalOpen={setIsModalOpen}
    //       setMsg={setMsg}
    //       setModalName={setModalName}
    //     />
    //   );
    // } else if (theme_index == 15) {
    //   selectedCardComponent = (
    //     <Card15
    //       data={getdata}
    //       icon={product_img_video_carosel3}
    //       setIsModalOpen={setIsModalOpen}
    //       setMsg={setMsg}
    //       setModalName={setModalName}
    //     />
    //   );
    // } else
    if (theme_index == 16) {
      selectedCardComponent = (
        // <NewNfbFrame1
        //   item={getdata}
        //   icon={product_img_video_carosel3}
        //   setIsModalOpen={setIsModalOpen}
        //   setMsg={setMsg}
        //   setModalName={setModalName}
        //   windowWidth={windowWidth}
        // />
        <NewEditNfbFrame1
          item={getdata}
          icon={product_img_video_carosel3}
          setIsModalOpen={setIsModalOpen}
          setMsg={setMsg}
          setModalName={setModalName}
          windowWidth={windowWidth}
          openSetImgsModal={openSetImgsModal}
        />
      );
    } else if (theme_index == 17) {
      selectedCardComponent = (
        <NewEditNfbFrame2
          item={getdata}
          icon={product_img_video_carosel3}
          setIsModalOpen={setIsModalOpen}
          setMsg={setMsg}
          setModalName={setModalName}
          windowWidth={windowWidth}
          openSetImgsModal={openSetImgsModal}
        />
      );
    } else if (theme_index == 18) {
      selectedCardComponent = (
        <NewEditNfbFrame3
          item={getdata}
          icon={product_img_video_carosel3}
          setIsModalOpen={setIsModalOpen}
          setMsg={setMsg}
          setModalName={setModalName}
          windowWidth={windowWidth}
          openSetImgsModal={openSetImgsModal}
        />
      );
    } else if (theme_index == 19) {
      selectedCardComponent = (
        <NewEditNfbFrame4
          item={getdata}
          icon={product_img_video_carosel3}
          setIsModalOpen={setIsModalOpen}
          setMsg={setMsg}
          setModalName={setModalName}
          windowWidth={windowWidth}
          openSetImgsModal={openSetImgsModal}
        />
      );
    } else if (theme_index == 20) {
      selectedCardComponent = (
        <NewEditNfbFrame5
          item={getdata}
          icon={product_img_video_carosel3}
          setIsModalOpen={setIsModalOpen}
          setMsg={setMsg}
          setModalName={setModalName}
          windowWidth={windowWidth}
          openSetImgsModal={openSetImgsModal}
        />
      );
    } else if (theme_index == 21) {
      selectedCardComponent = (
        <NewEditNfbFrame6
          item={getdata}
          icon={product_img_video_carosel3}
          setIsModalOpen={setIsModalOpen}
          setMsg={setMsg}
          setModalName={setModalName}
          windowWidth={windowWidth}
          openSetImgsModal={openSetImgsModal}
        />
      );
    } else if (theme_index == 22) {
      selectedCardComponent = (
        <NewEditNfbFrame7
          item={getdata}
          icon={product_img_video_carosel3}
          setIsModalOpen={setIsModalOpen}
          setMsg={setMsg}
          setModalName={setModalName}
          windowWidth={windowWidth}
          openSetImgsModal={openSetImgsModal}
        />
      );
    } else if (theme_index == 23) {
      selectedCardComponent = (
        <NewEditNfbFrame8
          item={getdata}
          icon={product_img_video_carosel3}
          setIsModalOpen={setIsModalOpen}
          setMsg={setMsg}
          setModalName={setModalName}
          windowWidth={windowWidth}
          openSetImgsModal={openSetImgsModal}
        />
      );
    } else if (theme_index == 24) {
      selectedCardComponent = (
        <NewEditNfbFrame9
          item={getdata}
          icon={product_img_video_carosel3}
          setIsModalOpen={setIsModalOpen}
          setMsg={setMsg}
          setModalName={setModalName}
          windowWidth={windowWidth}
          openSetImgsModal={openSetImgsModal}
        />
      );
    } else if (theme_index == 25) {
      selectedCardComponent = (
        <NewEditNfbFrame10
          item={getdata}
          icon={product_img_video_carosel3}
          setIsModalOpen={setIsModalOpen}
          setMsg={setMsg}
          setModalName={setModalName}
          windowWidth={windowWidth}
          openSetImgsModal={openSetImgsModal}
        />
      );
    }
  }

  // get all data start

  const GetAllData = () => {
    setattched_benifit_data([]);
    const param = {
      club_product_id: location.state.club_product_id,
      user_id: location.state.club_user_id,
      commitment_date: location.state.commitment_date_new,
    };

    axios
      .post(
        "https://www.ravi.host/api/club_list_of_product_no_of_bond_assign_benifit",
        qs.stringify(param)
      )
      .then((Response) => {
        if (Response.data.status == 1) {
          console.log("Response", Response);
          setattched_benifit_data(Response.data.result);
          const uniqCodesArray = Response.data.result.map(
            (item) => item.uniq_code
          );
          const commaSeparatedCodes = uniqCodesArray.join(",");

          setAll_uniqcode(commaSeparatedCodes);
        } else {
        }
      });
  };

  // get all data end

  // release product start

  const ReleaseProduct = () => {
    const param = {
      club_product_id: location.state.club_product_id,
      user_id: location.state.club_user_id,
      share_by_user: location.state.share_by_user,
      club_id: location.state.club_id,
    };

    axios
      .post(
        "https://www.ravi.host/api/get_no_of_release_bond_club",
        qs.stringify(param)
      )
      .then((Response) => {
        console.log(
          "====================================release product,",
          JSON.stringify(Response.data, null, 2)
        );

        if (Response.data.status == 1) {
          setavailable_no_of_bond(Response.data.result.available_no_of_bond);
          setmax_bond(Response.data.result.no_of_release_bond);
          setassign_no_of_bond(Response.data.result.assign_no_of_bond);
          // this.setState({
          //   max_bond: Response.data.result.no_of_release_bond,
          //   available_no_of_bond: Response.data.result.available_no_of_bond,
          //   assign_no_of_bond: Response.data.result.assign_no_of_bond,
          //   // description:
          // });
        } else {
          // this.setState({
          //   isModalVisible: true,
          //   cusmsg: Response.data.message,
          // });
        }
      });
  };

  // release product end

  // enter nfbs modal logic start
  const BenefitNFBs = () => {
    if (no_of_bond == 0) {
      // alert("Please enter number of aucemo");
      toast.error("Please enter number of aucemo", { theme: "colored" });
    } else if (no_of_bond > available_no_of_bond) {
      // alert("Enter aucemo value below release value of aucemo");
      toast.error("Enter aucemo value below release value of aucemo", {
        theme: "colored",
      });
    } else {
      navigate("/ClubAttachbenefit", {
        state: {
          club_product_id: location.state.club_product_id,
          number_of_bond: no_of_bond,
          club_user_id: location.state.club_user_id,
          club_id: location.state.club_id,
          share_by_user: location.state.share_by_user,
        },
      });
    }
  };

  // enter nfbs modal logic end

  // place bid start

  const releaseBond = () => {
    setShowInfoModal(false);
    setIsLoading(true);
    if (available_no_of_bond == 0) {
      const param1 = {
        uniq_code: getall_uniqcode,
        share_by_user: location.state.share_by_user,
      };
      console.log("param1", param1);
      axios
        .post(
          "https://www.ravi.host/api/club_user_product_uniq_code",
          qs.stringify(param1)
        )
        .then((Response) => {
          console.log("club_user_product_uniq_code ->Response", Response);
          if (Response.data.status == 1) {
            const productAssignIds = Response.data.result.map(
              (item) => item.product_assign_id
            );
            const commaSeparatedIds = productAssignIds.join(",");
            console.log("productAssignIds", commaSeparatedIds);

            const param = {
              club_product_id: location.state.club_product_id,
              user_id: location.state.club_user_id,
              no_of_release_bond: max_bond,
              theme_index: theme_index,
              club_img_id: bond_img_id,
              action_user_id: userId,
              is_club_assign: location.state.is_club_assign,
              club_id: location.state.club_id,
              share_by_user: location.state.share_by_user,
              product_assign_id: commaSeparatedIds,
            };
            axios
              .post(
                "https://www.ravi.host/api/club_release_bond",
                qs.stringify(param)
              )
              .then((Response) => {
                setIsLoading(false);
                if (Response.data.status === 1) {
                  // props.navigation.replace("BottomTabNavigator", {
                  //   user_id: userId,
                  // });
                  navigate("/homescreen2");
                } else {
                  // setCusmsg(Response.data.message);
                  // setIsModalVisible(true);
                }
              });
          } else {
            // getmsg(Response.data.message);
            // setIsModalOpen(true);
            toast.error(Response.data.message, { theme: "colored" });
          }
        });
    } else {
      const chars =
        "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
      let randomString = "";
      for (let i = 0; i < 4; i++) {
        const randomIndex = Math.floor(Math.random() * chars.length);
        randomString += chars.charAt(randomIndex);
      }
      const param = {
        club_product_id: location.state.club_product_id,
        no_of_bond: available_no_of_bond,
        action_user_id: userId,
        user_id: location.state.club_user_id,
        uniq_code: randomString,
        theme_index: theme_index,
        club_img_id: bond_img_id,
        club_id: location.state.club_id,
        share_by_user: location.state.share_by_user,
      };
      axios
        .post(
          "https://www.ravi.host/api/club_release_bond_without_benifit",
          qs.stringify(param)
        )
        .then((Response) => {
          // setIsModalVisible(false);
          if (Response.data.status === 1) {
            localStorage.removeItem("theme_index_club");
            localStorage.removeItem("frames_index_club");

            const finalString = `${getall_uniqcode},${randomString}`;

            console.log("finalString", finalString);
            const param11 = {
              uniq_code: getall_uniqcode == "" ? randomString : finalString,
              share_by_user: location.state.share_by_user,
            };
            console.log("param11", param11);

            axios
              .post(
                "https://www.ravi.host/api/club_user_product_uniq_code",
                qs.stringify(param11)
              )
              .then((Response) => {
                if (Response.data.status == 1) {
                  // Extract product_assign_id values
                  const productAssignIds = Response.data.result.map(
                    (item) => item.product_assign_id
                  );

                  // Convert the array to a comma-separated string
                  const commaSeparatedIds = productAssignIds.join(",");
                  console.log("productAssignIds", commaSeparatedIds);

                  const param = {
                    club_product_id: location.state.club_product_id,
                    // club_user_id: location.state.club_user_id,
                    user_id: location.state.club_user_id,
                    action_user_id: userId,
                    no_of_release_bond: max_bond,
                    theme_index: theme_index,
                    club_img_id: bond_img_id,
                    is_club_assign: location.state.is_club_assign,
                    club_id: location.state.club_id,
                    share_by_user: location.state.share_by_user,
                    product_assign_id: commaSeparatedIds,
                  };
                  setIsLoading(true);
                  axios
                    .post(
                      "https://www.ravi.host/api/club_release_bond",
                      qs.stringify(param)
                    )
                    .then((Response) => {
                      setIsLoading(false);
                      if (Response.data.status === 1) {
                        // props.navigation.replace("BottomTabNavigator", {
                        //   user_id: props.user_id,
                        // });
                        navigate("/homescreen2");
                      } else {
                        // setCusmsg(Response.data.message);
                        // setIsModalVisible(true);
                      }
                    });
                } else {
                  // getmsg(Response.data.message);
                  // setIsModalOpen(true);
                  toast.error(Response.data.message, { theme: "colored" });
                }
              });
          } else {
            // setCusmsg(Response.data.message);
            // setIsModalVisible(true);
          }
        });
    }
  };

  // const releaseBond = () => {
  //   setIsLoading(true);

  //   if (available_no_of_bond == 0) {
  //     const param = {
  //       product_id: location.state.product_id,
  //       user_id: userId,
  //       no_of_release_bond: max_bond,
  //       theme_index: theme_index,
  //       bond_img_id: bond_img_id,
  //     };

  //     console.log("params1---->", param);

  //     axios
  //       .post(
  //         release_bond
  //         // qs.stringify(param)
  //       )
  //       .then((Response) => {
  //         setIsLoading(false);
  //         console.log("Response.data", Response.data);
  //         if (Response.data.status === 1) {
  //           // props.navigation.replace("BottomTabNavigator", {
  //           //   user_id: props.user_id,
  //           // });
  //         } else {
  //           // setCusmsg(Response.data.message);
  //           // setIsModalVisible(true);
  //         }
  //       });
  //   } else {
  //     const chars =
  //       "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ!@#$%^&*()_+~`|}{[]:;?><,.=";
  //     let randomString = "";
  //     for (let i = 0; i < 4; i++) {
  //       const randomIndex = Math.floor(Math.random() * chars.length);
  //       randomString += chars.charAt(randomIndex);
  //     }

  //     const param = {
  //       product_id: location.state.product_id,
  //       no_of_bond: available_no_of_bond,
  //       user_id: userId,
  //       uniq_code: randomString,
  //       theme_index: theme_index,
  //       bond_img_id: bond_img_id,
  //     };
  //     console.log("params2---->", param);

  //     axios
  //       .post(
  //         "https://www.ravi.host/api/release_bond_without_benifit"
  //         // qs.stringify(param)
  //       )
  //       .then((Response) => {
  //         // setIsModalVisible(false);
  //         if (Response.data.status === 1) {
  //           const param = {
  //             product_id: location.state.product_id,
  //             user_id: userId,
  //             no_of_release_bond: max_bond,
  //             theme_index: theme_index,
  //             bond_img_id: bond_img_id,
  //           };
  //           console.log("params3---->", param);

  //           setIsLoading(true);

  //           axios
  //             .post(
  //               "https://www.ravi.host/api/release_bond"
  //               // qs.stringify(param)
  //             )
  //             .then((Response) => {
  //               setIsLoading(false);
  //               if (Response.data.status === 1) {
  //                 // props.navigation.replace("BottomTabNavigator", {
  //                 //   user_id: user,
  //                 // });
  //                 navigate("/homescreen2");
  //               } else {
  //                 // setCusmsg(Response.data.message);
  //                 // setIsModalVisible(true);
  //               }
  //             });
  //         } else {
  //           // setCusmsg(Response.data.message);
  //           // setIsModalVisible(true);
  //         }
  //       });
  //   }
  // };

  // place bid end

  //disable body scrolling
  const [bodyScrollEnabled, setBodyScrollEnabled] = useState(true);
  useEffect(() => {
    if (ismodalopen) {
      // Disable body scrolling
      setBodyScrollEnabled(false);
    } else {
      // Enable body scrolling
      setBodyScrollEnabled(true);
    }
    if (bodyScrollEnabled) {
      document.body.style.overflow = "visible";
    } else {
      document.body.style.overflow = "hidden";
    }
  }, [ismodalopen, bodyScrollEnabled]);

  const closemodal1 = () => {
    setShowInfoModal(false);
  };

  const closemodal = () => {
    setIsModalOpen(false);
  };
  const openmodal = () => {
    setIsModalOpen(true);
  };
  const sliderRef = useRef(null);

  const scrollLeft = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({
        left: -200,
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({
        left: 200,
        behavior: "smooth",
      });
    }
  };
  return (
    <div className="page_main_wrapp">
      <div className="page_base_wrapp_2">
        {/* <NavbarSec name="Create Aucemo" /> */}
        <div
          style={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "30px",
            background: "black",
            zIndex: 11,
          }}
        >
          <div className="navsec_back_arrow">
            <img
              src={images.back}
              // className="nav_back_btn"
              alt=""
              onClick={() => {
                localStorage.removeItem("theme_index_club");
                localStorage.removeItem("frames_index_club");
                navigate(-1);
              }}
            />
          </div>

          <b
            style={{
              color: "#fff",
              fontfamily: "var(--font-600)",
              fontsize: "16px",
            }}
          >
            Create Aucemo
          </b>
        </div>
        {isLoading == true ? (
          <div className="loader"></div>
        ) : (
          <div>
            <div className="view_card_nfb">{selectedCardComponent}</div>
            {/* <div className="view_card_nfb">
              <p>Set Image</p>
              <br />
              {product_img_video_carosel &&
                product_img_video_carosel.map((i, index) => {
                  return (
                    <img
                      src={i.product_icon}
                      className={
                        index != img_index
                          ? "set_images_nfb_border"
                          : "set_images_nfb"
                      }
                      alt=""
                      onClick={() => {
                        // console.log("dscv");
                        set_img_index(index);
                        setbond_img_id(i.club_img_id);
                        setproduct_img_video_carosel3(i.product_icon);
                        console.log("bond_img_id", bond_img_id);
                        setImageSrc(i.product_icon);
                        setCropmodal(true);
                      }}
                    />
                  );
                })}
            </div> */}
            <p>Set frames</p>
            <div style={{ position: "relative", width: "100%" }}>
              <button
                className="nav-button left"
                onClick={scrollLeft}
                style={{ padding: "10px 0px", width: "24px" }}
              >
                <FaAngleLeft color="var(--color-main)" size={22} />
              </button>
              <div
                ref={sliderRef}
                style={{ padding: "6px 1.5rem" }}
                className="view_card_nfb2"
              >
                <br />
                {/* Frame Selection */}
                {/* <img
              src={images.firstcard}
              className={
                theme_index == 0
                  ? `frames_img ${theme_index == 0 ? "selected" : ""}`
                  : "frames_img_border"
              }
              alt=""
              onClick={() => {
                settheme_index(0);
                handleFrameClick("frames3");
              }}
            /> */}
                {/* <img
                src={images.yellowcard}
                className={
                  theme_index == 6
                    ? `frames_img ${theme_index == 6 ? "selected" : ""}`
                    : "frames_img_border"
                }
                alt=""
                onClick={() => {
                  settheme_index(6);
                  // handleFrameClick("frames1");
                  localStorage.setItem("theme_index_club", 6);
                  handleFrameClick("frames1");
                  localStorage.setItem("frames_index_club", "frames1");
                }}
              />
              <img
                src={images.redcard}
                className={
                  theme_index == 7
                    ? `frames_img ${theme_index == 7 ? "selected" : ""}`
                    : "frames_img_border"
                }
                alt=""
                onClick={() => {
                  settheme_index(7);
                  // handleFrameClick("frames2");
                  localStorage.setItem("theme_index_club", 7);
                  handleFrameClick("frames2");
                  localStorage.setItem("frames_index_club", "frames2");
                }}
              />
              <img
                src={images.Card8Frame}
                className={
                  theme_index == 8
                    ? `frames_img ${theme_index == 8 ? "selected" : ""}`
                    : "frames_img_border"
                }
                alt=""
                onClick={() => {
                  settheme_index(8);
                  // handleFrameClick("frames4");
                  localStorage.setItem("theme_index_club", 8);
                  handleFrameClick("frames4");
                  localStorage.setItem("frames_index_club", "frames4");
                }}
              />
              <img
                src={images.Card9Frame}
                className={
                  theme_index == 9
                    ? `frames_img ${theme_index == 9 ? "selected" : ""}`
                    : "frames_img_border"
                }
                alt=""
                onClick={() => {
                  settheme_index(9);
                  // handleFrameClick("frames5");
                  localStorage.setItem("theme_index_club", 9);
                  handleFrameClick("frames5");
                  localStorage.setItem("frames_index_club", "frames5");
                }}
              />
              <img
                src={images.Card10Frame}
                className={
                  theme_index == 10
                    ? `frames_img ${theme_index == 10 ? "selected" : ""}`
                    : "frames_img_border"
                }
                alt=""
                onClick={() => {
                  settheme_index(10);
                  // handleFrameClick("frames6");
                  localStorage.setItem("theme_index_club", 10);
                  handleFrameClick("frames6");
                  localStorage.setItem("frames_index_club", "frames6");
                }}
              />
              <img
                src={images.Card11Frame}
                className={
                  theme_index == 11
                    ? `frames_img ${theme_index == 11 ? "selected" : ""}`
                    : "frames_img_border"
                }
                alt=""
                onClick={() => {
                  settheme_index(11);
                  // handleFrameClick("frames7");
                  localStorage.setItem("theme_index_club", 11);
                  handleFrameClick("frames7");
                  localStorage.setItem("frames_index_club", "frames7");
                }}
              />
              <img
                src={images.Card12Frame}
                className={
                  theme_index == 12
                    ? `frames_img ${theme_index == 12 ? "selected" : ""}`
                    : "frames_img_border"
                }
                alt=""
                onClick={() => {
                  settheme_index(12);
                  // handleFrameClick("frames8");
                  localStorage.setItem("theme_index_club", 12);
                  handleFrameClick("frames8");
                  localStorage.setItem("frames_index_club", "frames8");
                }}
              />
              <img
                src={images.card7_hr_thub}
                className={
                  theme_index == 13
                    ? `frames_img ${theme_index == 13 ? "selected" : ""}`
                    : "frames_img_border"
                }
                alt=""
                onClick={() => {
                  settheme_index(13);
                  // handleFrameClick("frames8");
                  localStorage.setItem("theme_index_club", 13);
                  handleFrameClick("frames9");
                  localStorage.setItem("frames_index_club", "frames9");
                }}
              />
              <img
                src={images.frame14}
                className={
                  theme_index == 14
                    ? `frames_img ${theme_index == 14 ? "selected" : ""}`
                    : "frames_img_border"
                }
                alt=""
                onClick={() => {
                  settheme_index(14);
                  // handleFrameClick("frames8");
                  localStorage.setItem("theme_index_club", 14);
                  handleFrameClick("frames10");
                  localStorage.setItem("frames_index_club", "frames10");
                }}
              />
              <img
                src={images.frame15}
                className={
                  theme_index == 15
                    ? `frames_img ${theme_index == 15 ? "selected" : ""}`
                    : "frames_img_border"
                }
                alt=""
                onClick={() => {
                  settheme_index(15);
                  // handleFrameClick("frames8");
                  localStorage.setItem("theme_index_club", 15);
                  handleFrameClick("frames11");
                  localStorage.setItem("frames_index_club", "frames11");
                }}
              /> */}
                <img
                  src={images.new_frame_1_nfb}
                  className={
                    theme_index == 16
                      ? `frames_img ${theme_index == 16 ? "selected" : ""}`
                      : "frames_img_border"
                  }
                  alt=""
                  onClick={() => {
                    settheme_index(16);
                    // handleFrameClick("frames8");
                    localStorage.setItem("theme_index_club", 16);
                    handleFrameClick("frames12");
                    localStorage.setItem("frames_index_club", "frames12");
                  }}
                />
                <img
                  src={images.new_frame_2_nfb}
                  className={
                    theme_index == 17
                      ? `frames_img ${theme_index == 17 ? "selected" : ""}`
                      : "frames_img_border"
                  }
                  alt=""
                  onClick={() => {
                    settheme_index(17);
                    // handleFrameClick("frames8");
                    localStorage.setItem("theme_index_club", 17);
                    handleFrameClick("frames13");
                    localStorage.setItem("frames_index_club", "frames13");
                  }}
                />
                <img
                  src={images.new_frame_3_nfb}
                  className={
                    theme_index == 18
                      ? `frames_img ${theme_index == 18 ? "selected" : ""}`
                      : "frames_img_border"
                  }
                  alt=""
                  onClick={() => {
                    settheme_index(18);
                    // handleFrameClick("frames8");
                    localStorage.setItem("theme_index_club", 18);
                    handleFrameClick("frames14");
                    localStorage.setItem("frames_index_club", "frames14");
                  }}
                />
                <img
                  src={images.new_frame_4_nfb}
                  className={
                    theme_index == 19
                      ? `frames_img ${theme_index == 19 ? "selected" : ""}`
                      : "frames_img_border"
                  }
                  alt=""
                  onClick={() => {
                    settheme_index(19);
                    // handleFrameClick("frames8");
                    localStorage.setItem("theme_index_club", 19);
                    handleFrameClick("frames15");
                    localStorage.setItem("frames_index_club", "frames15");
                  }}
                />
                <img
                  src={images.new_frame_5_nfb}
                  className={
                    theme_index == 20
                      ? `frames_img ${theme_index == 20 ? "selected" : ""}`
                      : "frames_img_border"
                  }
                  alt=""
                  onClick={() => {
                    settheme_index(20);
                    // handleFrameClick("frames8");
                    localStorage.setItem("theme_index_club", 20);
                    handleFrameClick("frames16");
                    localStorage.setItem("frames_index_club", "frames16");
                  }}
                />
                <img
                  src={images.new_frame_6_nfb}
                  className={
                    theme_index == 21
                      ? `frames_img ${theme_index == 21 ? "selected" : ""}`
                      : "frames_img_border"
                  }
                  alt=""
                  onClick={() => {
                    settheme_index(21);
                    // handleFrameClick("frames8");
                    localStorage.setItem("theme_index_club", 21);
                    handleFrameClick("frames17");
                    localStorage.setItem("frames_index_club", "frames17");
                  }}
                />
                <img
                  src={images.new_frame_7_nfb}
                  className={
                    theme_index == 22
                      ? `frames_img ${theme_index == 22 ? "selected" : ""}`
                      : "frames_img_border"
                  }
                  alt=""
                  onClick={() => {
                    settheme_index(22);
                    // handleFrameClick("frames8");
                    localStorage.setItem("theme_index_club", 22);
                    handleFrameClick("frames18");
                    localStorage.setItem("frames_index_club", "frames18");
                  }}
                />
                <img
                  src={images.new_frame_8_nfb}
                  className={
                    theme_index == 23
                      ? `frames_img ${theme_index == 23 ? "selected" : ""}`
                      : "frames_img_border"
                  }
                  alt=""
                  onClick={() => {
                    settheme_index(23);
                    // handleFrameClick("frames8");
                    localStorage.setItem("theme_index_club", 23);
                    handleFrameClick("frames19");
                    localStorage.setItem("frames_index_club", "frames19");
                  }}
                />
                <img
                  src={images.new_frame_9_nfb}
                  className={
                    theme_index == 24
                      ? `frames_img ${theme_index == 24 ? "selected" : ""}`
                      : "frames_img_border"
                  }
                  alt=""
                  onClick={() => {
                    settheme_index(24);
                    // handleFrameClick("frames8");
                    localStorage.setItem("theme_index_club", 24);
                    handleFrameClick("frames20");
                    localStorage.setItem("frames_index_club", "frames20");
                  }}
                />
                <img
                  src={images.new_frame_10_nfb}
                  className={
                    theme_index == 25
                      ? `frames_img ${theme_index == 25 ? "selected" : ""}`
                      : "frames_img_border"
                  }
                  alt=""
                  onClick={() => {
                    settheme_index(25);
                    // handleFrameClick("frames8");
                    localStorage.setItem("theme_index_club", 25);
                    handleFrameClick("frames21");
                    localStorage.setItem("frames_index_club", "frames21");
                  }}
                />
              </div>
              <button
                className="nav-button right"
                onClick={scrollRight}
                style={{
                  justifyContent: "flex-end",
                  padding: "10px 0px",
                  width: "24px",
                }}
              >
                <FaAngleRight color="var(--color-main)" size={22} />
              </button>
            </div>
            {available_no_of_bond == 0 ? null : (
              <button
                className="attach_nfbs_nm"
                onClick={() => setShowModal(true)}
              >
                <p>Attach Benefit to remaining {available_no_of_bond} Aucemo</p>
              </button>
            )}
            <br />
            <div className="attach_nfbs_nm2">
              <p style={{ fontSize: "12px", fontFamily: "var(--font-600)" }}>
                You can attach benefits on {available_no_of_bond} Aucemo.
              </p>
              <p className="nm_attach_f">
                Attach benefits to your Aucemo and make them irresistible. Your
                added benefits enhance the value of your Aucemo. Get creative
                and watch them become highly sought after!
              </p>
              <Link
                className="createbenefit_btn"
                state={{ club_product_id: location.state.club_product_id }}
                to={"/Createclubbenefit"}
              >
                Create benefits
              </Link>
            </div>
            <div className="view_benefit_main">
              {attched_benifit_data && attched_benifit_data.length > 0 && (
                <b>Benefits Attached</b>
              )}
              {attched_benifit_data && attched_benifit_data.length > 0
                ? attched_benifit_data.map((item, index) => {
                    console.log("item", item);
                    return (
                      <div className="list_benefit_view">
                        <div className="part_benefit">
                          <div className="part_benefit1">
                            <div className="sub_list_benefits">
                              <div>
                                <b className="nm_nfbs_list">Aucemo</b>
                                <br />
                                <b className="nm_nfbs_list1">
                                  {item.no_of_bond}
                                </b>
                              </div>
                              <Link
                                className="manage_link"
                                to="/ClubAttachbenefit"
                                state={{
                                  club_product_id:
                                    location.state.club_product_id,
                                  number_of_bond: item.no_of_bond,
                                  uniq_code: item.uniq_code,
                                  club_user_id: location.state.club_user_id,
                                  club_id: location.state.club_id,
                                  share_by_user: location.state.share_by_user,
                                }}
                              >
                                Manage
                              </Link>
                            </div>
                          </div>
                          <div className="part_benefit2">
                            {/* partner data start */}
                            {item.partner_bond_benifit_data &&
                            item.partner_bond_benifit_data.length > 0
                              ? item.partner_bond_benifit_data.map(
                                  (itm, ind) => {
                                    return (
                                      <div className="view_sub_benefit">
                                        <img
                                          src={itm.benifit_icon}
                                          className="img_view_1"
                                          alt=""
                                        />
                                        <p className="benefit_nm_sub">
                                          {itm.benifit_name}
                                        </p>
                                        <p className="benefit_nm_sub">
                                          {itm.partner_name}
                                        </p>
                                      </div>
                                    );
                                  }
                                )
                              : null}

                            {/* partner data end */}

                            {/* own benefit data start */}

                            {item.product_bond_benifit_detail &&
                            item.product_bond_benifit_detail.length > 0
                              ? item.product_bond_benifit_detail.map(
                                  (data, ind) => {
                                    return (
                                      <div className="view_sub_benefit">
                                        <img
                                          src={
                                            data.reward_img_video[0].img_video
                                          }
                                          className="img_view_1"
                                          alt=""
                                        />
                                        <p className="benefit_nm_sub">
                                          {data.reward_name}
                                        </p>
                                      </div>
                                    );
                                  }
                                )
                              : null}

                            {/* own benefit data end */}
                          </div>
                        </div>
                      </div>
                    );
                  })
                : null}
            </div>
            <center>
              <button
                className="new_btn_wrapp mt-3"
                onClick={() => setShowInfoModal(true)}
              >
                <p style={{ width: "90%" }}>
                  {attched_benifit_data && attched_benifit_data.length > 0
                    ? "Launch Aucemo"
                    : "Launch Aucemo Without Benefit"}
                </p>
              </button>
            </center>
          </div>
        )}
      </div>
      {showModal && (
        <div className="v2modal_main_wrapp">
          <div className="v2modal_inner_wrapp">
            <div className="v2modal_fixed_logo_wrapp">
              <img src={images.logo} alt="" />
            </div>
            <button
              className="v2modal_close_img"
              onClick={() => {
                setShowModal(false);
              }}
            >
              <img
                src={images.new_close_logo}
                className="v2modal_close_img"
                alt=""
              />
            </button>

            <h4 className="v2modal_fixed_heading">
              Enter Aucemo (Max {available_no_of_bond})
            </h4>

            <div>
              {/* {mpin.map((digit, index) => { */}
              {/* return ( */}
              <input
                // key={index}
                type="tel"
                value={no_of_bond}
                className="newattach_modal_input"
                // onChange={(e) => setno_of_bond(e.target.value)}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  const isValid = /^\d+$/.test(inputValue);

                  if (no_of_bond > available_no_of_bond) {
                    setno_of_bond("");
                    // setModalName(
                    //   "Enter Aucemo value below release value of Aucemo"
                    // );
                    // setIsModalOpen(true);
                    toast.error(
                      "Enter Aucemo value below release value of Aucemo",
                      { theme: "colored" }
                    );
                  } else if (!isValid) {
                    setno_of_bond("");
                    // setModalName("Please enter a valid number of Aucemo");
                    // setIsModalOpen(true);
                    toast.error("Please enter a valid number of Aucemo", {
                      theme: "colored",
                    });
                  } else {
                    setno_of_bond(parseInt(inputValue));
                    console.log("isValid", inputValue);
                  }
                }}
                // maxLength={1}
                placeholder="Enter number of aucemo"
                // ref={(input) => (mpinRefs.current[index] = input)}
              />
              {/* ); */}
              {/* })} */}
            </div>
            <div className="newattech_hr"></div>
            <div
              style={{
                width: "80%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                margin: "0px auto",
              }}
            >
              <button
                className="v2modal_btn1"
                onClick={() => {
                  setno_of_bond(0);
                  setShowModal(false);
                }}
              >
                Cancel
              </button>
              <button className="v2modal_btn2" onClick={BenefitNFBs}>
                Okay
              </button>
            </div>
          </div>
        </div>
      )}

      {/* information modal start */}

      {showinfoModal && (
        // <div className="newattach_modal">
        //   <div className="modal-content1">
        //     <center>
        //       <img src={images.logo} className="newattach_modal_img2" alt="" />
        //     </center>
        //     <p
        //       style={{
        //         fontSize: "16px",
        //         fontFamily: "var(--font-600)",
        //         color: "#fff",
        //         marginTop: "-30px",
        //       }}
        //     >
        //       Information
        //     </p>
        //     <p
        //       style={{
        //         width: "90%",
        //         margin: "10px auto",
        //         fontFamily: "var(--font-400)",
        //         fontSize: "12px",
        //       }}
        //     >
        //       Before releasing the Aucemo, Please take a moment to carefully
        //       verify the details and the benefits attached
        //     </p>
        //     <div
        //       style={{
        //         display: "flex",
        //         alignItems: "center",
        //         justifyContent: "center",
        //         width: "90%",
        //         margin: "10px auto",
        //         marginTop: "20px",
        //         gap: "50px",
        //       }}
        //     >
        //       <button
        //         className="new_attech_modal_btn"
        //         onClick={() => {
        //           releaseBond();
        //         }}
        //       >
        //         Okay
        //       </button>
        //       <button
        //         className="new_attech_modal_btn"
        //         onClick={() => {
        //           setShowInfoModal(false);
        //         }}
        //       >
        //         Cancel
        //       </button>
        //     </div>
        //   </div>
        // </div>

        // <ReactModal
        //   isOpen={setShowInfoModal}
        //   // onAfterOpen={afterOpenModal}
        //   onRequestClose={closemodal1}
        //   style={customStyles}
        // >
        //   <div className="NewModal_main_wrapp">
        //     <div
        //       style={{
        //         display: "flex",
        //         flexDirection: "column",
        //         alignItems: "center",
        //         justifyContent: "center",
        //       }}
        //     >
        //       <img className="newmodal_logo" src={images.logo} alt="" />
        //       <p className="newmodal_titel"></p>
        //       {/* <p className="newmodal_titel">Alert</p> */}
        //     </div>

        //     {/* <div className="newmodal_des_wrapp"> */}
        //     <p className="newmodal_des">
        //       Before releasing the Aucemo, Please take a moment to carefully
        //       verify the details and the benefits attached
        //     </p>

        //     <div className="modal_two_buton" style={{ width: "100%" }}>
        //       <button className="close_forgot" onClick={() => releaseBond()}>
        //         Okay
        //       </button>
        //       <button className="close_forgot" onClick={closemodal1}>
        //         Cancel
        //       </button>
        //     </div>
        //   </div>
        // </ReactModal>

        <V2Modal
          canclebtn={true}
          title=""
          msg="Before releasing the Aucemo, Please take a moment to carefully verify the details and the benefits attached"
          setShowModal={setShowInfoModal}
          okfunction={releaseBond}
        />
      )}

      {/* information modal end */}
      {ismodalopen && (
        <>
          {/* <AlertModal
            title={getmodalName}
            msg={getmsg}
            setShowModal={setIsModalOpen}
          /> */}
          {/* <NewModal
            title=""
            msg={errorMessage}
            setShowModal={openmodal}
            closemodal={closemodal}
          /> */}

          <V2Modal
            canclebtn={false}
            title=""
            msg={errorMessage}
            setShowModal={setIsModalOpen}
            okfunction={closemodal}
          />
        </>
      )}

      {/* crop modal start */}

      {getcropmodal && (
        <div className="crop_modal_main_wrapp">
          <div
            // className="crop_modal_base_wrapp"
            style={{
              background: "#000",
              width: "100%",
              maxHeight: "90vh",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                maxWidth: "90%",
                margin: "10px auto",
                height: "90vh",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <button
                onClick={showCroppedImage}
                // variant="contained"
                // color="primary"
                // classes={{ root: classes.cropButton }}
                style={{
                  color: "#fff",
                  display: "block",
                  zIndex: 1,
                  background: "var(--color-main)",
                  borderRadius: "4px",
                  padding: "10px 16px",
                  fontFamily: "var(--font-600)",
                  fontSize: "14px",
                }}
              >
                Show Img
              </button>
              <div
                style={{
                  display: "block",
                  width: "100%",
                  height: "500px",
                  maxWidth: "300px",
                }}
              >
                <Cropper
                  minZoom={minZoom}
                  image={imageSrc}
                  crop={crop}
                  zoom={zoom}
                  aspect={3 / 4}
                  restrictPosition={false}
                  onCropChange={onCropChange}
                  onCropComplete={onCropComplete}
                  onZoomChange={onZoomChange}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {/* crop modal end */}

      {/* submit img modal start */}

      {getsubmitmodal && (
        <div className="crop_modal_main_wrapp">
          <div
            // className="crop_modal_base_wrapp"
            style={{
              background: "#000",
              width: "100%",
              height: "90vh",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div className="image-editor-container">
              <div className="image-container">
                {/* <img
                  src={fixedImageSrc}
                  alt="Fixed Image"
                  className="fixed-image"
                  onClick={handleFixedImageClick}
                /> */}
                {selectedImageSrc && (
                  <img
                    src={selectedImageSrc}
                    alt="Selected Image"
                    className="selected-image"
                    ref={selectedImageRef}
                  />
                )}
              </div>
              {/* <input
                type="file"
                onChange={handleImageSelect}
                accept="image/*"
              /> */}
              <div
                style={{
                  width: "90%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: "30px",
                }}
              >
                <button
                  onClick={() => {
                    setSubmitModal(false);
                  }}
                  style={{
                    color: "white",
                    fontFamily: "var(--font-600)",
                    backgroundColor: "var(--color-main)",
                    padding: "10px 18px",
                    borderRadius: "8px",
                  }}
                >
                  Cancel
                </button>
                <button
                  onClick={handleImageSubmit}
                  style={{
                    color: "white",
                    fontFamily: "var(--font-600)",
                    backgroundColor: "var(--color-main)",
                    padding: "10px 18px",
                    borderRadius: "8px",
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* set image modal start */}

      {getsetImgsModal && (
        <>
          <div
            className="crop_modal_main_wrapp"
            style={{
              background: "#00000060",
              zIndex: 222,
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "flex-end",
              flexDirection: "column",
            }}
          >
            <div
              // className="crop_modal_base_wrapp"
              style={{
                background: "#000",
                width: "100%",
                // minHight: "60vh",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                // justifyContent: "center",
                borderTopRightRadius: "28px",
                borderTopLeftRadius: "28px",
                boxShadow: "0px 0px 10px rgba(90, 89, 89, 0.5)",
                maxWidth: "var(--max-width-sm)",
                margin: "0px auto",
                minHeight: "60vh",
                paddingTop: "20px",
              }}
            >
              <div
                style={{
                  width: "90%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  margin: "10px",
                }}
              >
                <button
                  onClick={() => {
                    setSetImgsModal(false);
                  }}
                >
                  <IoClose size={20} color="#fff" />
                </button>
              </div>
              <div className="view_card_nfb" style={{ margin: "1rem" }}>
                <p>Set Image</p>
                <br />
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "flex-start",
                    gap: "10px",
                  }}
                >
                  {product_img_video_carosel &&
                    product_img_video_carosel.map((i, index) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "150px",
                            // paddingLeft: "20px",
                          }}
                        >
                          <img
                            src={i.product_icon}
                            className={"set_images_nfb"}
                            alt=""
                            onClick={() => {
                              if (i.image_type == "new") {
                                set_img_index(index);
                                setbond_img_id(i.club_img_id);
                                setproduct_img_video_carosel3(i.product_icon);
                                setImageSrc(i.product_icon);
                                // setOrignal(i.product_icon);
                                setSetImgsModal(false);
                                // setCropmodal(true);
                                console.log("getcropmodal", getcropmodal);
                              } else {
                                set_img_index(index);
                                setbond_img_id(i.club_img_id);
                                setproduct_img_video_carosel3(i.product_icon);
                                setImageSrc(i.product_icon);
                                // setOrignal(i.product_icon);
                                setCropmodal(true);
                                setSetImgsModal(false);
                                console.log("getcropmodal", getcropmodal);
                              }
                              // console.log("dscv");
                            }}
                          />
                          {i.image_type == "new" ? (
                            <button
                              onClick={() => {
                                deleteimage(i.club_img_id);
                              }}
                              // variant="contained"
                              // color="primary"
                              // classes={{ root: classes.cropButton }}
                              style={{
                                color: "#fff",
                                // display: "block",
                                // zIndex: 1,
                                background: "var(--color-main)",
                                borderRadius: "4px",
                                padding: "10px 16px",
                                fontFamily: "var(--font-600)",
                                fontSize: "14px",
                                marginTop: "10px",
                                // marginLeft: "30px",
                                // width: "50%",
                              }}
                            >
                              Delete
                            </button>
                          ) : null}
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {/* set image modal end */}

      <ToastContainer />
      {/* submit img modal end */}
    </div>
  );
};

export default ClubCreatenfbs;
