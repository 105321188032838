import React, { useEffect, useState } from "react";
import images from "../constants/images";
import axios from "axios";
import BackButton from "../components/BackButton";
import { useLocation } from "react-router-dom";
import {
  club_accept_reject_bond,
  get_club_requested_bond_list,
} from "../utils/Constant";
import ReactModal from "react-modal";
import V2Modal from "../common/v2modal/V2Modal";

const NFbsdata = [
  {
    id: 1,
    name: "Asset Name",
    image: images.media1,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus delectus magnam nemo quas ipsum laudantium maiores repudiandae enim doloribus illo?",
    price: "1.37 lac",
    username: "User Name",
    userimg: images.mask_profile,
  },
  {
    id: 2,
    name: "Asset Name",
    image: images.media2,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus delectus magnam nemo quas ipsum laudantium maiores repudiandae enim doloribus illo?",
    price: "1.37 lac",
    username: "User Name",
    userimg: images.mask_profile,
  },
  {
    id: 3,
    name: "Asset Name",
    image: images.media3,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus delectus magnam nemo quas ipsum laudantium maiores repudiandae enim doloribus illo?",
    price: "1.37 lac",
    username: "User Name",
    userimg: images.mask_profile,
  },
];

const ClubReceivedNfb = () => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "0px",
      backgroundColor: "none",
      border: "none",
      borderRadius: "12px",
    },
    overlay: {
      zIndex: 1000,
      backgroundColor: "rgba(0, 0, 0, 0.3)",
    },
  };
  const userId = localStorage.getItem("user_id");
  const location = useLocation();
  const [getreceiveddata, setreceiveddata] = useState([]);
  const [getsearchdata, setsearchdata] = useState([]);
  const [loading, setloading] = useState(false);
  const [acceptmodel, setacceptmodel] = useState(false);
  const [getassignid, setassignid] = useState("");
  const [getis_nfb, setis_nfb] = useState("");
  const [getcon, setcon] = useState("");
  useEffect(() => {
    getReleasebond();
  }, []);

  const getReleasebond = () => {
    setloading(true);
    const param = {
      club_id: location.state.club_id,
    };
    console.log(param);
    axios
      .post(get_club_requested_bond_list, param)
      .then((Response) => {
        setloading(false);

        if (Response.data.status == 1) {
          console.log(Response.data.result);
          setreceiveddata(Response.data.result);
          setsearchdata(Response.data.result);
        } else {
        }

        // this.setState({
        //   // data: Response.data.result,
        //   task_arrayholder: Response.data.result,
        // });
      })
      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };

  const closemodal = () => {
    setacceptmodel(false);
  };
  const openmodal = () => {
    setacceptmodel(true);
  };
  const acceptdata = () => {
    setloading(true);
    const param = {
      user_id: userId,
      assign_id: getassignid,
      status: getcon,
      is_nfb: getis_nfb,
    };
    console.log(param);
    axios
      .post(club_accept_reject_bond, param)
      .then((Response) => {
        setloading(false);

        if (Response.data.status == 1) {
          getReleasebond();
          window.location.reload();
          closemodal();
        } else {
        }
      })
      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };
  const searchFilter_branch = (text) => {
    const newData = getsearchdata.filter(function (item) {
      console.log("item", item.product_name);
      const employee = item.product_name
        ? item.product_name.toUpperCase()
        : "".toLowerCase();
      const number = String(item.apply_date)
        ? String(item.apply_date).toUpperCase()
        : "".toUpperCase();

      const textData = text.toUpperCase();
      return employee.indexOf(textData) > -1 || number.indexOf(textData) > -1;
    });
    setreceiveddata(newData);
    console.log("asvsvsds", newData);
  };

  return (
    <div className="page_main_wrapp">
      {loading ? (
        <div className="loader"></div>
      ) : (
        <>
          <div className="page_base_wrapp_2">
            <div className="page_navbar_wrapp">
              <div className="nav_back_wrapp">
                <BackButton />
              </div>
              <p className="nav_screen_name">Received Aucemo</p>
            </div>
            {/* <div className="released__user">
              <input
                type="text"
                placeholder="Search"
                className="user_releasednfb_search"
                onChange={(e) => {
                  searchFilter_branch(e.target.value);
                }}
              />
            </div> */}
            <div style={{}} className="bid13_search">
              <div
                className="bids13_user_bid_main"
                style={{ paddingTop: "10px" }}
              >
                <input
                  type="text"
                  placeholder="Search"
                  className="bids13_user_bid_search"
                  // value={searchTerm}
                  onChange={(e) => searchFilter_branch(e.target.value)}
                />
                {/* <img src={images.search} alt="" className="search_ic" /> */}
              </div>
            </div>
            {getreceiveddata == 0 ? (
              <>
                <div
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <p style={{ alignSelf: "center", textAlign: "center" }}>
                    No data found
                  </p>
                </div>
              </>
            ) : (
              <div className="recevied_products_wrapp">
                {getreceiveddata &&
                  getreceiveddata.map((item, index) => {
                    return item.bond_img_video[0] == undefined ? null : (
                      <div className="recevied_sigle_product_wrapp">
                        <img
                          src={item.bond_img_video[0].img_video}
                          alt=""
                          className="recevied_sigle_product_img"
                        />
                        <div className="recevied_sigle_product_blur">
                          <h5 className="recevied_sigle_product_name">
                            {item.product_name}
                          </h5>
                          <p className="recevied_sigle_product_text">
                            Applied on - {item.apply_date}
                          </p>
                          <p className="recevied_sigle_product_text">
                            Assigned by - {item.owner_name}
                          </p>
                          <p className="recevied_sigle_product_text">
                            Assigned Aucemo - {item.no_of_assign_bond}
                          </p>
                          <p className="recevied_sigle_product_text">
                            Asset Value - {item.product_value}
                          </p>

                          <div className="recevied_sigle_product_btns_wrapp">
                            <button
                              onClick={() => {
                                setassignid(item.assign_id);
                                setacceptmodel(true);
                                setis_nfb(item.is_nfb);
                                setcon(0);
                              }}
                            >
                              Accept
                            </button>
                            <button
                              onClick={() => {
                                setassignid(item.assign_id);
                                setacceptmodel(true);
                                setis_nfb(item.is_nfb);
                                setcon(1);
                              }}
                            >
                              Reject
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
          {acceptmodel === true ? (
            // <div className="modal_login">
            //   <div className="modal-content_login">
            //     <div className="modal_div1">
            //       <img className="forgot_main" src={images.logo} alt="" />
            //     </div>
            //     <div className="modal_div">
            //       <b></b>
            //       <br />
            //       <p className="modal-message">
            //         Are you sure ? This action cannot be reversed.
            //       </p>
            //       <br />
            //     </div>
            //     <hr className="hr_forgot" />
            //     <br />
            //     <div className="modal_two_buton">
            //       <button className="close_forgot" onClick={closemodal}>
            //         Cancel
            //       </button>
            //       <button className="close_forgot" onClick={acceptdata}>
            //         Okay
            //       </button>
            //     </div>
            //   </div>
            // </div>
            // <ReactModal
            //   isOpen={setacceptmodel}
            //   // onAfterOpen={afterOpenModal}
            //   onRequestClose={closemodal}
            //   style={customStyles}
            // >
            //   <div className="NewModal_main_wrapp">
            //     <div
            //       style={{
            //         display: "flex",
            //         flexDirection: "column",
            //         alignItems: "center",
            //         justifyContent: "center",
            //       }}
            //     >
            //       <img className="newmodal_logo" src={images.logo} alt="" />
            //       <p className="newmodal_titel"></p>
            //       {/* <p className="newmodal_titel">Alert</p> */}
            //     </div>

            //     {/* <div className="newmodal_des_wrapp"> */}
            //     <p className="newmodal_des">
            //       Are you sure ? This action cannot be reversed.
            //     </p>

            //     <div className="modal_two_buton" style={{ width: "100%" }}>
            //       <button className="close_forgot" onClick={acceptdata}>
            //         Okay
            //       </button>
            //       <button className="close_forgot" onClick={closemodal}>
            //         Cancel
            //       </button>
            //     </div>
            //   </div>
            // </ReactModal>

            <V2Modal
              canclebtn={true}
              title=""
              msg="Are you sure ? This action cannot be reversed."
              setShowModal={setacceptmodel}
              okfunction={acceptdata}
            />
          ) : null}
        </>
      )}
    </div>
  );
};

export default ClubReceivedNfb;
