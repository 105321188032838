import React, { useState } from "react";
import "./mynfbcollection.css";
import images from "../../constants/images";
import { useLocation, useNavigate } from "react-router-dom";
import NavbarSec from "../../common/NavbarSec";
import axios from "axios";
import qs from "qs";
import { get_user_profile } from "../../utils/Constant";
import AlertModal from "../../components/AlertModal";
import { useEffect } from "react";
import NewModal from "../../common/newmodal/NewModal";
import V2Modal from "../../common/v2modal/V2Modal";
import { FaAngleLeft } from "react-icons/fa";
import NewLoader from "../../common/newloder/NewLoader";

const Mynfbcollection = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userId = localStorage.getItem("user_id");
  const [isInputVisible, setInputVisible] = useState(false);
  const [isloading, setIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState([]);
  const [data, setData] = useState([]);
  const [ismodalOpen, setIsModalOpen] = useState(false);
  const [getmsg, setMsg] = useState("");

  useEffect(() => {
    GetProfile();
  }, []);

  const GetProfile = async () => {
    setIsLoading(true);
    const param = {
      target_user_id: location.state.target_user_id,
      user_id: userId,
    };

    console.log("====================================");
    console.log("param", param);
    console.log("====================================");

    await axios
      .post(get_user_profile, qs.stringify(param))
      .then((Response) => {
        setIsLoading(false);
        console.log("Response.data", Response.data);

        if (Response.data.status == 1) {
          setData(Response.data.result.product_detail);
          setSearchValue(Response.data.result.product_detail);
        } else {
          setIsModalOpen(false);
          setMsg(Response.data.message);
        }
      })
      .catch((err) => {
        setIsModalOpen(false);
        setMsg(err);
        setIsLoading(false);
      });
  };

  const handleSearchClick = () => {
    setInputVisible(!isInputVisible);
  };

  const searchFilterCreators = (text) => {
    const newData = searchValue.filter(function (item) {
      const creator = item.product_name
        ? item.product_name.toUpperCase()
        : "".toLowerCase();

      const textData = text.toUpperCase();
      return creator.indexOf(textData) > -1;
    });
    setData(newData);
    console.log("newData -=-=->", newData);
  };

  const handleInputChange = (e) => {
    searchFilterCreators(e.target.value);
  };

  const closemodal = () => {
    setIsModalOpen(false);
  };
  const openmodal = () => {
    setIsModalOpen(true);
  };
  return (
    <div className="page_main_wrapp">
      {isloading ? (
        <div
          style={{
            width: "100vw",
            height: "50vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <NewLoader />
        </div>
      ) : (
        <div className="page_base_wrapp_2">
          <>
            <div className="mynfbc_nav_hd">
              {/* <img
                src={images.back}
                className="mynfbc_nav_img1"
                alt=""
                onClick={() => navigate(-1)}
              /> */}
              <button
                className="mynfbc_nav_img1"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <FaAngleLeft color="var(--color-white)" size={20} />
              </button>
              <p className="mynfbc_nav_nm">My Aucemo Collection</p>
              <img
                src={images.search}
                className="mynfbc_nav_img"
                alt=""
                onClick={handleSearchClick}
              />
            </div>
            <br />
            {isInputVisible && (
              <div style={{}} className="bid13_search">
                <div
                  className="bids13_user_bid_main"
                  style={{ paddingTop: "5px" }}
                >
                  <input
                    type="text"
                    placeholder="Search"
                    className="bids13_user_bid_search"
                    // value={searchTerm}
                    onChange={handleInputChange}
                  />
                  {/* <img src={images.search} alt="" className="search_ic" /> */}
                </div>
              </div>
            )}
          </>
          <div className="mynfbcclubnfb_main_details">
            {data.map((item, index) => (
              <button
                onClick={() => {
                  navigate("/ProductView", {
                    state: {
                      release_bond_id: item.release_bond_id,
                      product_id: item.product_id,
                      is_nfb: item.is_nfb,
                      uniq_code: item.uniq_code,
                      theme_index: item.theme_index,
                      share_by_user: item.share_by_user,
                      club_id: item.club_id,
                    },
                  });
                }}
                key={index}
                className="mynfbcclubnfb_main"
              >
                <img
                  src={item.product_img_video[0].img_video}
                  className="mynfbcclubnfb_img"
                  alt=""
                />
                <div
                  className="mynfbcclubnfb_nm"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "4px",
                  }}
                >
                  <p className="mynfbcclubnfb_number1">{item.product_name}</p>
                  <p
                    style={{
                      fontFamily: "var(--font-400)",
                      fontSize: "12px",
                      color: "white",
                    }}
                  >
                    {item.owner_name}
                  </p>
                </div>
              </button>
            ))}
          </div>
        </div>
      )}

      {ismodalOpen && (
        <>
          {/* <AlertModal title="" msg={getmsg} setShowModal={setIsModalOpen} /> */}
          {/* <NewModal
            title=""
            msg={getmsg}
            setShowModal={openmodal}
            closemodal={closemodal}
          /> */}

          <V2Modal
            canclebtn={false}
            title=""
            msg={getmsg}
            setShowModal={setIsModalOpen}
            okfunction={closemodal}
          />
        </>
      )}
    </div>
  );
};

export default Mynfbcollection;
