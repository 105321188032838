import React, { useState, useEffect } from "react";
import "./bondbox.css";
import axios from "axios";
import qs from "qs";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import images from "../../constants/images";
import { draft_bond_api_part_1 } from "../../utils/Constant";
import AlertModal from "../../components/AlertModal";
import NewModal from "../../common/newmodal/NewModal";
import V2Modal from "../../common/v2modal/V2Modal";
import { ToastContainer, toast } from "react-toastify";

const Bondbox = () => {
  const userId = localStorage.getItem("user_id");
  const location = useLocation();
  const Navigate = useNavigate();
  const [agree, setAgree] = useState(false);
  const [getminusBond, setMinusBond] = useState(
    location.state.is_globle === 1 ? 0 : 0
  );
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  useEffect(() => {
    const isGlobal = location.state.is_globle === 1;
    const isOwner = location.state.is_owner === 1;

    const minusBond = isGlobal ? 0 : isOwner ? 20 : 0;
    setAgree(false);
    console.log("location.state", location.state);
  }, []);

  useEffect(() => {
    setMinusBond(
      location.state.is_owner === 1
        ? location.state.is_globle === 1
          ? 10
          : 20
        : 0
    );
    console.log(
      "location.state.remaining_bond_release_date",
      location.state.remaining_bond_release_date
    );
  }, [location.state]);

  const handleAgreeToggle = (e) => {
    setAgree((e) => (e.targate.value === 1 ? 0 : 1));
  };

  const handleOkButton = () => {
    if (agree) {
      const params = {
        product_id: location.state.product_id,
        no_of_release_bond: location.state.no_of_release_bond,
        remaining_bond_release_date: location.state.remaining_bond_release_date,
        user_id: userId,
        minimum_bid_amount: location.state.min_bid_amount,
        commitment_date: location.state.commitment_date,
      };

      axios
        .post(draft_bond_api_part_1, qs.stringify(params))
        .then((response) => {
          if (response.data.status === 1) {
            // props.navigation.replace("Addpartnercolabrationinbond", {
            //   product_id: location.state.product_id,
            // });
            Navigate("/Collaboratenfb", {
              state: {
                product_id: location.state.product_id,
                commitment_date_new: location.state.commitment_date_new,
                is_user: location.state.is_user,
              },
            });
            console.log("response", response);
          } else {
            alert(response.data.message);
          }
        });
    } else {
      // alert("Please agree");
      // setShowModal(true);
      // setErrorMessage("Please agree");
      toast.error("Please agree", { theme: "colored" });
    }
  };

  const closemodal = () => {
    setShowModal(false);
  };
  const openmodal = () => {
    setShowModal(true);
  };

  return (
    <div className="page_main_wrapp_box">
      <div className="page_base_wrappbox">
        <div className="bondbox_main">
          <center>
            <img src={images.logo} className="images_bondbox" alt="" />
            <p
              className="details_bondbox"
              style={{ margin: "0px auto", width: "90%" }}
            >
              {location.state.remaining_bond_release_date === ""
                ? "Your" + location.state.is_owner === 4
                  ? location.state.no_of_release_bond
                  : location.state.no_of_release_bond - 0
                : "Your" + location.state.is_owner === 4
                ? location.state.no_of_release_bond
                : location.state.no_of_release_bond - 0}{" "}
              &nbsp;aucemo will be released now.
              {/* new line start */}
              {location.state.is_gift == 0 &&
                location.state.commitment_date_new == "" &&
                ` and aucemo.club charges ${
                  location.state.is_globle === 1 ? 10 : 20
                } aucemo,`}
              {/* new line end */}
              {/* {location.state.is_owner === 4
                ? null
                : " and  " +
                  `${getminusBond}` +
                  " aucemo will be issued to aucemo.club.  "} */}{" "}
              {location.state.remaining_bond -
                location.state.no_of_release_bond ==
                0 || location.state.remaining_bond_release_date == ""
                ? null
                : `You will be reminded on 
              ${moment(location.state.remaining_bond_release_date).format(
                "D MMMM, YYYY"
              )}
              to release your remaining
              ${
                location.state.remaining_bond -
                location.state.no_of_release_bond
              }
              aucemo`}
              {/* 28 aucemo will be released now. and 20 aucemo will be issued to
              aucemo.club. You will be reminded on 30 September, 2023 to release
              your remaining 52 aucemo. */}{" "}
            </p>
            <div
              className="bondbox_checkbox"
              style={{ justifyContent: "center" }}
            >
              {/* <input
                type="checkbox"
                className="check_box_new"
                value={agree}
                onChange={(e) => {
                  console.log(e.target.value);
                  setAgree(!agree);
                }}
              /> */}
              {agree ? (
                <img
                  src={images.new_accepted_terms}
                  onClick={() => setAgree(false)}
                  alt=""
                  style={{ width: "20px", objectFit: "contain" }}
                />
              ) : (
                <img
                  src={images.new_notaccepted_terms}
                  alt=""
                  onClick={() => {
                    setAgree(true);
                  }}
                  style={{ width: "20px", objectFit: "contain" }}
                />
              )}
              <span style={{ padding: "0px" }}>I Agree</span>
            </div>
            <div className="bond_hr"></div>
            <button
              className="new_btn_wrapp mt-3"
              style={{ width: "100px", height: "50px", padding: "0px" }}
              onClick={handleOkButton}
            >
              <p>Okay</p>
            </button>
          </center>
        </div>
      </div>
      {showModal && (
        // <AlertModal
        //   msg={errorMessage}
        //   setShowModal={setShowModal}
        //   // title="Information"
        // />
        // <NewModal
        //   title=""
        //   msg={errorMessage}
        //   setShowModal={openmodal}
        //   closemodal={closemodal}
        // />

        <V2Modal
          canclebtn={false}
          title=""
          msg={errorMessage}
          setShowModal={setShowModal}
          okfunction={closemodal}
        />
      )}
      <ToastContainer />
    </div>
  );
};

export default Bondbox;
