import React, { useState, useEffect, useRef } from "react";
import "./approvalcreate.css";
import images from "../../constants/images";
import { useHomeContext } from "../../context/home_context";
import CusModal from "../../common/cusmodal/CusModal";
import {
  bond_img_video_delete,
  create_bond,
  create_bond_v2,
  edit_profile,
  get_all_club_2,
  get_user_profile_video,
  getall_user,
} from "../../utils/Constant";
import axios from "axios";
import { useProfileContext } from "../../context/profile_context";
import moment from "moment/moment";
import qs from "qs";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import NavbarSec from "../../common/NavbarSec";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AlertModal from "../../components/AlertModal";
import RecordRTC from "recordrtc";
import Webcam from "react-webcam";
import styled from "styled-components";
import {
  Box,
  Button,
  Step,
  StepConnector,
  Stepper,
  Typography,
  stepConnectorClasses,
} from "@mui/material";
import { FaCheck } from "react-icons/fa6";
import NewModal from "../../common/newmodal/NewModal";
import V2Modal from "../../common/v2modal/V2Modal";

// -------------------

const CustomStepButton = ({ label, completed, onClick, isActive, index }) => {
  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      {/* <StepButton
        color={isActive ? "primary" : "white"} // Use "primary" color for the active step
        onClick={onClick}
      >
      </StepButton> */}
      <div
        style={{
          width: "20px",
          height: "20px",
          borderRadius: "50%",
          background: completed || isActive ? "var(--color-main)" : "white",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          // border: isActive ? "1px solid var(--color-main)" : "none",
          // Use "green" background for completed steps
        }}
      >
        {completed ? (
          <FaCheck size={14} color="#fff" />
        ) : (
          <p
            style={{
              color: isActive ? "#fff" : "#000",
              fontSize: "12px",
              fontFamily: "var(--font-600)",
            }}
          >
            {index + 1}
          </p>
        )}
      </div>
      {/* <p
        style={{
          color: "#fff",
          fontSize: "12px",
          fontFamily: "var(--font-400)",
        }}
      >
        {label}
      </p> */}
    </div>
  );
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 2,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: "var(--color-main)",
      height: "1px",
      paddingTop: "2px",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: "var(--color-main)",
      height: "1px",
      paddingTop: "2px",

      // backgroundImage:
      //   "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 1,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
    paddingTop: "2px",
    width: "100%",
  },
}));

const CustomStepper = ({
  activeStep,
  completed,
  steps,
  handleStep,
  handleBack,
  handleNext,
  handleComplete,
  handleReset,
}) => {
  return (
    <Box sx={{ width: "100%", marginTop: "10px" }}>
      <Stepper
        nonLinear
        activeStep={activeStep}
        connector={<ColorlibConnector />}
      >
        {steps.map((label, index) => (
          <Step
            key={label}
            completed={completed[index]}
            sx={{ paddingLeft: "0px", paddingRight: "0px" }}
          >
            <CustomStepButton
              label={label}
              completed={completed[index]}
              onClick={handleStep(index)}
              isActive={index === activeStep}
              index={index}
            />
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

// --------------------

const Approvalcreate = () => {
  const { is_loading, getAllInterest } = useHomeContext();
  const { profile_loading, edit_profile_data, EditProfile } =
    useProfileContext();
  const navigate = useNavigate();
  const location = useLocation();

  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedImagesAndVidio, setSelectedImagesAndVidio] = useState([]);
  const [selectedImagesMin1Img, setSelectedImagesMin1Img] = useState([]);

  const [getattechVideoModal, setAttechVideoModal] = useState(false);
  const [getpreviewVideoModal, setPreviewVideoModal] = useState(false);
  const [getpreviewVideoModal2, setPreviewVideoModal2] = useState(false);
  const [getvideoId, setVideoId] = useState("");
  const [getuserVid, setUserVideo] = useState("");
  const [getuserVid2, setUserVideo2] = useState("");
  const [getvideoArray, setVideoArray] = useState([]);

  const [selectedCreator, setSelectedCreator] = useState(1);
  const [selectedGlob, setSelectedGlob] = useState(0);
  const [selectedAuction, setSelectedAuction] = useState(0);
  const [getallInterest, setAllInterest] = useState([]);
  const [getInterestArray, setInterestArray] = useState([]);
  const [gethashtags, setHashtags] = useState("");
  const [getproductname, setproductname] = useState(
    location.state && location.state.product_name
      ? location.state.product_name
      : ""
  );
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [getcreatorname, setGetcreatorname] = useState("");
  const [getcreatorid, setGetcreatorid] = useState("");
  const [creatormodal, setCreatormodal] = useState(false);
  const [getseatch, searchFilter] = useState("");
  const [getalluser, setalluser] = useState([]);
  const [getallclubs, setallClubs] = useState([]);
  const [getsearchallclubs, setSearchallClubs] = useState([]);
  const [data_filter_data, setdata_filter_data] = useState([]);
  const [getimageFiles, setImageFiles] = useState([]);
  const userId = localStorage.getItem("user_id");
  // ---
  const [getcreatornameText, setGetcreatornameText] = useState("");
  const [getcreatornameContactNo, setGetcreatornameContactNo] = useState(null);
  const [getcreatornameEmail, setGetcreatornameEmail] = useState("");
  const [getProductDes, setProductDes] = useState("");
  const [getProductStory, setProductStory] = useState("");
  const [getProductHighlight, setProductHighlight] = useState("");
  const [getProductValue, setProductValue] = useState(null);
  const [getminimumBid, setMinimumBid] = useState(null);
  const [getauctionDurtion, setAuctoinDuration] = useState(null);
  const [getsharenfbModal, setSharenfbModal] = useState(false);
  const [getuserlistModal, setUserListModal] = useState(false);
  const [getavailableBond, setAvailableBond] = useState(80);
  const [getnoOfBond, setNoOfBond] = useState(null);
  const [getnoOfBondClub, setNoOfBondClub] = useState(null);
  const [getdonationOwnerName, setDonationOwnerName] = useState("");
  const [getdonationOwnerId, setDonationOwnerId] = useState("");
  const [getdonationClubName, setDonationClubName] = useState("");
  const [getdonationClubId, setDonationClubId] = useState("");
  const [getisLoading, setIsLoading] = useState(false);
  const [getshareOwnerModal, setShareOwnerModal] = useState(false);
  const [getshareClubModal, setShareClubModal] = useState(false);

  const [getdonetQrhunt, setDonetQrhunt] = useState(0);

  const [getownerName, setOwnerName] = useState("");
  const [getownerId, setOwnerId] = useState("");

  const [getimg_video, setimg_video] = useState([]);

  const [showModal1, setShowModal1] = useState(false);
  const [errorMessage1, setErrorMessage1] = useState("");

  useEffect(() => {
    console.log("location.state", location.state);
  }, []);

  useEffect(() => {
    getimg_video &&
      getimg_video.length > 0 &&
      getimg_video.map((itm, ind) => {
        const imageObject = {
          uri: itm.img_video,
          name: `media_img_${ind}`,
          type: "image/png",
        };

        console.log("imageObject", imageObject);
      });
  }, [getimg_video]);

  const [
    getnumber_of_bonds_created_for_share,
    setnumber_of_bonds_created_for_share,
  ] = useState([]);

  const [
    getnumber_of_bonds_created_for_share_name,
    setnumber_of_bonds_created_for_share_name,
  ] = useState([]);

  const [
    getnumber_of_bonds_created_for_share_id,
    setnumber_of_bonds_created_for_share_id,
  ] = useState([]);

  const [
    number_of_bond_for_current_owner,
    setnumber_of_bond_for_current_owner,
  ] = useState("");

  // clubs start ---

  const [getisUserAndClub, setIsUserAndClub] = useState(0);

  const [
    getclub_number_of_bonds_created_for_share,
    setclub_number_of_bonds_created_for_share,
  ] = useState([]);

  const [
    getclub_number_of_bonds_created_for_share_name,
    setclub_number_of_bonds_created_for_share_name,
  ] = useState([]);

  const [
    getclub_number_of_bonds_created_for_share_id,
    setclub_number_of_bonds_created_for_share_id,
  ] = useState([]);

  const handleShareUserAndClubChange = (event) => {
    setIsUserAndClub(event.target.value);
    console.log("event.target.value", event.target.value);
    // setnumber_of_bonds_created_for_share([]);
    // {
    //   (selectedCreator == 1 || selectedCreator == 3) && GetEditProfileApi();
    // }
  };

  // clubs end----
  const [getglobModalOpen, setGlobModalOpen] = useState(false);
  const [getglobModalMsg, setGlobModalMsg] = useState(
    "Non-Exclusive Commitment: Dear Asset Uploader, Thank you for considering aucemo.club as a platform to create digital assets of your asset. We appreciate your interest. Here are the terms for your non-exclusive commitment: Platform Fee: aucemo.club will charge 20 Aucemo of the asset as the platform fee. This fee allows us to provide you with a robust platform and comprehensive support. Royalty for Life: As a gesture of our partnership, you will receive a 5% royalty for life from the sales of the Aucemo. This means that for every Aucemo sold, you will earn a share of the revenue, ensuring a continuous stream of passive income. We believe in fostering a mutually beneficial relationship and are excited to have your asset on our platform. Lets collaborate to make your Aucemo a success!"
  );

  const closemodal = () => {
    setShowModal(false);
  };
  const openmodal = () => {
    setShowModal(true);
  };

  const closemodal1 = () => {
    setShowModal1(false);
  };
  const openmodal1 = () => {
    setShowModal1(true);
  };

  // Tab Functionality start

  const steps = ["Step-1", "Step-2", "Step-3", "Step-4", "Step-5", "Step-6"];

  const [activeStep, setActiveStep] = React.useState(0);

  const [completed, setCompleted] = React.useState({});
  const [gettab1Completed, setTab1Completed] = useState(false);
  const [gettab2Completed, setTab2Completed] = useState(false);
  const [gettab3Completed, setTab3Completed] = useState(false);
  const [gettab4Completed, setTab4Completed] = useState(false);
  const [gettab5Completed, setTab5Completed] = useState(false);

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    console.log("activeStep", newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  const ValidateStep1 = () => {
    let idString = "";
    var demo = 0;
    for (let index = 0; index < getallInterest.length; index++) {
      if (getallInterest[index].status == "2") {
        demo = demo + 1;

        if (index > 0) {
          idString += ",";
        }
        idString += getallInterest[index].id;
      }
    }
    if (getproductname == "") {
      setErrorMessage("Enter asset name");
      setShowModal(true);
    } else if (idString == "") {
      setErrorMessage("Please Select category");
      setShowModal(true);
    } else if (
      selectedDate2 == null ||
      selectedDate2 == "" ||
      selectedDate2 == undefined
    ) {
      setErrorMessage("Please select date");
      setShowModal(true);
    } else {
      setTab1Completed(true);
      handleComplete();
      // console.log("selectedDate", moment(selectedDate2).format("YYYY-MM-DD"));
    }
  };

  const ValidateStep2 = () => {
    if (getProductDes == "") {
      setErrorMessage("Enter description");
      setShowModal(true);
    } else {
      setTab2Completed(true);
      handleComplete();
    }
  };

  const ValidateStep3 = () => {
    if (selectedImages.length <= 0) {
      setErrorMessage("Upload owner file");
      setShowModal(true);
    } else {
      setTab3Completed(true);
      handleComplete();
    }
  };

  const ValidateStep4 = () => {
    if (getvideoId == "") {
      setErrorMessage("Please attach creator video");
      setShowModal(true);
    } else if (selectedVideos.length <= 0) {
      setErrorMessage("Upload video");
      setShowModal(true);
    } else {
      setTab4Completed(true);
      handleComplete();
    }
  };

  const ValidateStep5 = () => {
    if (
      selectedAuction == 0 &&
      (getProductValue == null || getProductValue == "" || getProductValue == 0)
    ) {
      setErrorMessage("Enter Asset value");
      setShowModal(true);
    } else {
      setTab5Completed(true);
      handleComplete();
    }
  };

  const ValidateStep6 = () => {
    if (
      selectedAuction == 1 &&
      (getauctionDurtion == null ||
        getauctionDurtion == "" ||
        getauctionDurtion == 0)
    ) {
      setErrorMessage("Enter Auction Days");
      setShowModal(true);
    } else if (
      selectedAuction == 1 &&
      (getminimumBid == null || getminimumBid == "" || getminimumBid == 0)
    ) {
      setErrorMessage("Enter minimum bid");
      setShowModal(true);
    } else {
      // setTab4Completed(true);
      CreateProduct();
    }
  };

  // Tab Functionality end

  // video functionality start

  const videoRef = useRef(null);
  const [recording, setRecording] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [getrecordedvideo, setrecordedvideo] = useState(false);
  const [recordingTime, setRecordingTime] = useState(60);
  const [isRecordingstatus, setRecordingstatus] = useState(false);
  const [isModalVisiblevideo, setIsModalVisiblevideo] = useState(false);
  const [selectedVideos, setSelectedVideos] = useState([]);

  useEffect(() => {
    let timerInterval;
    const minDuration = 30;

    if (isRecordingstatus) {
      timerInterval = setInterval(() => {
        setRecordingTime((prevTime) => {
          const newTime = prevTime - 1;

          // Check if the recording time is less than the minimum duration
          if (newTime < minDuration) {
            // Display an error or take other actions
            console.error(
              `Recording time should be at least ${minDuration} seconds.`
            );
          }

          // Check if the recording time reaches 1 second
          if (newTime <= 1) {
            stopRecording1(); // Stop recording or take other actions
          }

          return newTime;
        });
      }, 1000);
    }

    return () => {
      clearInterval(timerInterval);
    };
  }, [isRecordingstatus]);

  const resetCountdown = () => {
    setIsModalVisiblevideo(false);
    setRecordingTime(60);
  };
  const showimage = () => {
    stopRecording();
    setRecordedChunks([]);
    setSelectedVideos([]);
    resetCountdown();
    console.log(videoRef.current);
  };

  const recorderRef = useRef(null);

  const stopRecording = () => {
    setRecording(false);
    const minDuration = 30;
    const maxDuration = 60;
    if (recorderRef.current) {
      recorderRef.current.stopRecording(() => {
        const blob = recorderRef.current.getBlob();

        console.log("blob---->", blob.length);
        // Use the current value of recordingTime from the useEffect
        if (60 - recordingTime < minDuration) {
          // Display an alert or take other actions for insufficient duration
          alert(
            `Video duration should be between ${minDuration}s and ${maxDuration}s.`
          );
          setRecordedChunks([]);
          setSelectedVideos([]);
        } else {
          // Continue with handling the recorded video
          setRecordedChunks([blob]);
          setSelectedVideos([blob]);
          setRecordingTime(60);
          setIsModalVisiblevideo(false);

          // Stop the media stream to release the camera
          const stream = videoRef.current.srcObject;
          if (stream) {
            const tracks = stream.getTracks();
            tracks.forEach((track) => track.stop());
          }
        }
      });
    }
  };
  const stopRecording1 = () => {
    setRecording(false);
    const minDuration = 30;
    const maxDuration = 60;

    if (recorderRef.current) {
      recorderRef.current.stopRecording(() => {
        const blob = recorderRef.current.getBlob();
        console.log("blob---->", blob.length);
        const stream = videoRef.current.srcObject;
        if (stream) {
          const tracks = stream.getTracks();
          tracks.forEach((track) => track.stop());
        }
        // if (recordingTime < minDuration) {
        //   // console.error(
        //   //   `Recording time should be at least ${minDuration} seconds.`
        //   // );
        //   alert(
        //     `Video duration should be between ${minDuration}s and ${maxDuration}s.`
        //   );
        //   setRecordedChunks([]);
        //   setSelectedVideos([]);
        // } else {
        setRecordedChunks([blob]);
        setSelectedVideos([blob]);
        setRecordingTime(60);
        setIsModalVisiblevideo(false);
        // }
      });
    }
  };

  const startRecording = () => {
    setRecordingstatus(true);
    setRecordingTime(60);
    if (videoRef.current) {
      navigator.mediaDevices
        .getUserMedia({
          video: true,
          audio: {
            echoCancellation: true,
            noiseSuppression: true,
            autoGainControl: true,
          },
        })
        .then((stream) => {
          const recorder = RecordRTC(stream, {
            type: "video",
            mimeType: "video/webm",
          });
          recorder.startRecording();
          recorderRef.current = recorder;
          setRecording(true);
          setrecordedvideo(false);
          videoRef.current.srcObject = stream;
        })
        .catch((error) => {
          console.error("Error accessing media devices:", error);
        });
    }
  };

  // video functionality end

  const handleAssetsChange = (e) => {
    const inputValue = e.target.value;
    const words = inputValue.trim().split(/\s+/); // Split input into words

    const containsEmoji =
      /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}]/u.test(
        inputValue
      );

    // Check if the length of the input is less than or equal to 10 and does not contain emojis
    if (words.length <= 10 && !containsEmoji) {
      setproductname(inputValue);
    }
  };

  useEffect(() => {
    console.log(
      "getnumber_of_bonds_created_for_share",
      getnumber_of_bonds_created_for_share
    );
  }, [getnumber_of_bonds_created_for_share]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(1);
  const [selectedReasonOption, setSelectedReasonOption] = useState(null);
  const [getreasonMsg, setReasonMsg] = useState("");

  const handleOptionCreatorChange = (e) => {
    setSelectedOption(e.target.value);
    if (e.target.value == 0) {
      setIsModalOpen(true);
    } else {
      setIsModalOpen(false);
    }
  };

  // image use Effect start

  useEffect(() => {
    selectedImages.forEach((image, index) => {
      // formData.append("proof_file", image);
      console.log("jj -- >image", image);
    });
  }, []);

  // image use Effect end

  const handleReasonOptionChange = (e) => {
    setSelectedReasonOption(e.target.value);
  };

  // video start

  const [getdonationSelect, setDonationSelect] = useState(false);
  // const handleVideoChange = (e) => {
  //   const files = Array.from(e.target.files);
  //   setSelectedVideos([...selectedVideos, ...files]);
  // };
  const handleVideoChange = async (e) => {
    const files = Array.from(e.target.files);

    // Assuming you have a minimum and maximum duration in seconds
    const minDuration = 30; // Minimum duration in seconds
    const maxDuration = 60; // Maximum duration in seconds

    // Validate each video file
    for (const file of files) {
      const video = document.createElement("video");
      video.src = URL.createObjectURL(file);

      // Wait for the video metadata to load
      await new Promise((resolve) => {
        video.onloadedmetadata = resolve;
      });

      const duration = video.duration;

      if (duration < minDuration || duration > maxDuration) {
        // Video duration is outside the allowed range
        alert(
          `Video duration should be between ${minDuration}s and ${maxDuration}s.`
        );
      } else {
        // Video is within the allowed range, add it to the state
        setSelectedVideos([...selectedVideos, file]);
      }

      // Clean up the video element
      video.remove();
    }
  };

  const handleVideoDelete = (index) => {
    const newVideos = [...selectedVideos];
    newVideos.splice(index, 1);
    setSelectedVideos(newVideos);
  };

  // video end

  useEffect(() => {
    if (getvideoArray.length === 1) {
      getvideoArray &&
        getvideoArray.map((i, index) => {
          console.log("sdcdsc", i);
          setVideoId(i.user_video_id);
          setUserVideo(i.user_videos);
          // setAttechVideoModal(false);
        });
    }
  }, [getvideoArray]);

  // date Piker

  // const [selectedDate, setSelectedDate] = useState(null);
  // const [getdd, setdd] = useState("");
  // const [getmm, setmm] = useState("");
  // const [getyy, setyy] = useState("");
  // const currentDate = moment().subtract(1, "days").toDate();

  // useEffect(() => {
  //   const formattedDate = moment(selectedDate).format("DD");
  //   const formattedDate1 = moment(selectedDate).format("MM");
  //   const formattedDate2 = moment(selectedDate).format("YYYY");
  //   setdd(formattedDate);
  //   setmm(formattedDate1);
  //   setyy(formattedDate2);
  // }, []);

  // date Piker

  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDate2, setSelectedDate2] = useState(null);
  const [getdd, setdd] = useState("");
  const [getmm, setmm] = useState("");
  const [getyy, setyy] = useState("");
  const currentDate = moment().subtract(1, "days").toDate();

  // useEffect(() => {
  //   const formattedDate = moment(selectedDate).format("DD");
  //   const formattedDate1 = moment(selectedDate).format("MM");
  //   const formattedDate2 = moment(selectedDate).format("YYYY");
  //   setdd(formattedDate);
  //   setmm(formattedDate1);
  //   setyy(formattedDate2);
  // }, []);

  const [startDate, setStartDate] = useState(new Date());
  const [startDate2, setStartDate2] = useState(new Date());
  const [isyear, setIsYear] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const handleChange = (e) => {
    setIsOpen(!isOpen);
    setStartDate(e);
    // setStartDate2(e);
    console.log("e", moment(e).format("YYYY-MM-DD"));
    setIsYear(false);
    setSelectedDate(moment(e).format("YYYY-MM-DD"));
  };
  const handleChange2 = (e) => {
    setIsOpen2(!isOpen2);

    // setStartDate2(e);
    setStartDate(e);
    setIsYear(true);
    console.log("e", moment(e).format("YYYY"));
    setSelectedDate(moment(e).format("YYYY"));
  };
  const handleClick = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
    setIsOpen2(false);
  };
  const handleClick2 = (e) => {
    e.preventDefault();
    setIsOpen2(!isOpen2);
    setIsOpen(false);
  };

  // date end

  const handleDateChange = (date) => {
    setSelectedDate2(date);

    // const formattedDate = moment(date).format("DD");
    // const formattedDate1 = moment(date).format("MM");
    // const formattedDate2 = moment(date).format("YYYY");
    // setdd(formattedDate);
    // setmm(formattedDate1);
    // setyy(formattedDate2);

    // console.log("date", getdd, getmm, getyy);
  };

  const fetchData = async () => {
    try {
      const intrestdata = await getAllInterest();
      console.log("Fetched data:", intrestdata); // Log fetched data
      setAllInterest(intrestdata.result);
      console.log("getallInterest", getallInterest);
    } catch (error) {
      console.error("Error fetching interest data:", error);
    }
  };

  const GetEditProfileApi = async () => {
    const param = {
      user_id: userId,
    };
    await axios
      .post(edit_profile, qs.stringify(param))
      .then((Response) => {
        if (Response.data.status == 1) {
          setOwnerName(Response.data.result.username);
          setOwnerId(Response.data.result.id);
        }
      })
      .catch((err) => {
        console.log("get_club_join_fees err", err);
      });
  };

  const GetUserVideos = async () => {
    const param = {
      user_id: userId,
    };
    await axios
      .post(get_user_profile_video, qs.stringify(param))
      .then((Response) => {
        if (Response.data.status == 1) {
          setVideoArray(Response.data.result.user_videos);
        }
      })
      .catch((err) => {
        console.log("get_user_profile_video err", err);
      });
  };

  useEffect(() => {
    GetEditProfileApi();
    fetchData();
    GetUserVideos();
    // handleAddImageChangeMin1Img();
    // console.log("img_video", location.state.img_video);
    setimg_video(location.state.img_video);
  }, []);

  useEffect(() => {
    console.log(getuserlistModal);
  }, [getuserlistModal]);

  // reject api start
  const Reject = () => {
    if (selectedReasonOption == null) {
      setErrorMessage("Please select a message type");
      setShowModal(true);
    } else {
      const param = {
        user_id: userId,
        product_id: location.state.product_id,
        status: 0,
        message: getreasonMsg,
        is_nfb: 0,
        extra_message:
          selectedReasonOption == 1
            ? "I am not the creator of this asset"
            : selectedReasonOption == 2
            ? "I  am not interested in launching Aucemo at the moment"
            : "Other Reason",
      };

      console.log("reject param", param);
      axios
        .post(
          "https://www.ravi.host/api/add_user_pending_verify_product",
          qs.stringify(param)
        )
        .then((Response) => {
          console.log("====================================");
          console.log(":Re", Response.data);
          console.log("====================================");
          if (Response.data.status == 1) {
            setIsModalOpen(false);
            // this.props.navigation.goBack();
            setIsModalOpen(false);
            navigate("/assetsapproval");
          } else {
            setErrorMessage(Response.data.message);
            setShowModal(true);
          }
        });
    }
  };
  // reject api end

  const handleOptionChange = (event) => {
    setSelectedCreator(event.target.value);
    setnumber_of_bonds_created_for_share([]);
    {
      selectedCreator == 1 && GetEditProfileApi();
    }
  };

  const handleGlobOptionChange = (event) => {
    setSelectedGlob(event.target.value);
    setnumber_of_bonds_created_for_share([]);
    setclub_number_of_bonds_created_for_share([]);
    console.log("handleGlobOptionChange", event.target.value);
    setnumber_of_bond_for_current_owner("");
    setAvailableBond(event.target.value == 1 ? 90 : 80);
    setGlobModalMsg(
      event.target.value == 1
        ? "Exclusive Commitment: Dear Asset Uploader, Thank you for choosing aucemo.club as your platform to create digital assets of your asset exclusively. We are excited to collaborate with you on this journey. Here are the terms for your exclusive commitment: Platform Fee: aucemo.club will take only 10 Aucemo of this particular asset as the platform fee. This ensures that you retain a significant portion of your Aucemo for potential sales. Royalty for Life: As a token of appreciation for your exclusive commitment, you will receive a 10% royalty for life from the sales of the Aucemo. This means that for every Aucemo sold, you will earn a share of the revenue, providing you with ongoing passive income. We value your trust in aucemo.club, and we are committed to helping you maximize the potential of your asset through our platform. Let's work together to create a thriving marketplace for your Aucemo! Best regards"
        : "Non-Exclusive Commitment: Dear Asset Uploader, Thank you for considering aucemo.club as a platform to create digital assets of your asset. We appreciate your interest. Here are the terms for your non-exclusive commitment: Platform Fee: aucemo.club will charge 20 Aucemo of the asset as the platform fee. This fee allows us to provide you with a robust platform and comprehensive support. Royalty for Life: As a gesture of our partnership, you will receive a 5% royalty for life from the sales of the Aucemo. This means that for every Aucemo sold, you will earn a share of the revenue, ensuring a continuous stream of passive income. We believe in fostering a mutually beneficial relationship and are excited to have your asset on our platform. Lets collaborate to make your Aucemo a success!"
    );
  };

  const handleAuctionOptionChange = (event) => {
    setSelectedAuction(event.target.value);
    setMinimumBid(null);
    setAuctoinDuration(null);
    console.log("setSelectedAuction", event.target.value);
  };

  // share nfb list delete btn start

  const handleShareDelete = (item, index) => {
    setAvailableBond(
      (prevAvailableNoOfBond) => prevAvailableNoOfBond + parseInt(item)
    );

    const updatedDataName = [...getnumber_of_bonds_created_for_share_name];
    updatedDataName.splice(index, 1);
    setnumber_of_bonds_created_for_share_name(updatedDataName);

    const updatedDataShare = [...getnumber_of_bonds_created_for_share];
    updatedDataShare.splice(index, 1);
    setnumber_of_bonds_created_for_share(updatedDataShare);

    const updatedDataId = [...getnumber_of_bonds_created_for_share_id];
    updatedDataId.splice(index, 1);
    setnumber_of_bonds_created_for_share_id(updatedDataId);
  };

  // share nfb list delete btn end

  // share club nfb list delete btn start

  const handleClubShareDelete = (item, index) => {
    setAvailableBond(
      (prevAvailableNoOfBond) => prevAvailableNoOfBond + parseInt(item)
    );

    const updatedDataName = [...getclub_number_of_bonds_created_for_share_name];
    updatedDataName.splice(index, 1);
    setclub_number_of_bonds_created_for_share_name(updatedDataName);

    const updatedDataShare = [...getclub_number_of_bonds_created_for_share];
    updatedDataShare.splice(index, 1);
    setclub_number_of_bonds_created_for_share(updatedDataShare);

    const updatedDataId = [...getclub_number_of_bonds_created_for_share_id];
    updatedDataId.splice(index, 1);
    setclub_number_of_bonds_created_for_share_id(updatedDataId);
  };

  // share club nfb list delete btn end

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    // setImageFiles(e.target.files[0]);
    setSelectedImages((prevSelectedImages) => [
      ...prevSelectedImages,
      ...files,
    ]);
  };

  const handleImageAndVideoChange = (e) => {
    const files = Array.from(e.target.files);
    // setImageFiles(e.target.files[0]);
    setSelectedImagesAndVidio((prevSelectedImages) => [
      ...prevSelectedImages,
      ...files,
    ]);
  };

  useEffect(() => {
    console.log("selectedImagesMin1Img", selectedImagesMin1Img);
    if (selectedImagesMin1Img.length > 6) {
      setSelectedImagesMin1Img([]);
      setErrorMessage("You can select maximum of 6 images.");
      setShowModal(true);
    } else {
    }
  }, [selectedImagesMin1Img]);

  const handleImageChangeMin1Img = (e) => {
    const files = Array.from(e.target.files);
    // setImageFiles(e.target.files[0]);
    setSelectedImagesMin1Img((prevSelectedImages) => [
      ...prevSelectedImages,
      ...files,
    ]);
  };

  // const handleAddImageChangeMin1Img = (e) => {
  //   const files2 = location.state.img_video;
  //   // setImageFiles(e.target.files[0]);
  //   setSelectedImagesMin1Img((prevSelectedImages) => [
  //     ...prevSelectedImages,
  //     ...files2,
  //   ]);

  //   console.log("selectedImagesMin1Img", selectedImagesMin1Img);
  // };

  const handleImageDelete = (index) => {
    const newImages = [...selectedImages];
    newImages.splice(index, 1);
    setSelectedImages(newImages);
  };

  const handleImageDelete2 = (index) => {
    const newImages = [...selectedImagesMin1Img];
    newImages.splice(index, 1);
    setSelectedImagesMin1Img(newImages);
  };

  const handleImageDelete2back = (index, itm) => {
    console.log("itm", itm);

    const param = {
      bond_img_id: itm.bond_img_id,
    };
    axios
      .post(bond_img_video_delete, qs.stringify(param))
      .then((Response) => {
        if (Response.data.status == 1) {
          console.log("get_club_join_fees res", Response);
          const newImages = [...getimg_video];
          newImages.splice(index, 1);
          setimg_video(newImages);
        }
      })
      .catch((err) => {
        console.log("get_club_join_fees err", err);
      });
  };

  const handleImageAndVideoDelete = (index) => {
    const newImages = [...selectedImagesAndVidio];
    newImages.splice(index, 1);
    setSelectedImagesAndVidio(newImages);
  };

  // image convert into blob start

  // Example function to convert URL to Blob
  async function urlToBlob(url) {
    const response = await fetch(url);
    const blob = await response.blob();
    return blob;
  }

  // Usage in your component

  // image convert into blob end

  // not on platform creator api start
  const CreateProduct = async () => {
    let idString = "";
    var demo = 0;
    for (let index = 0; index < getallInterest.length; index++) {
      if (getallInterest[index].status == "2") {
        demo = demo + 1;

        if (index > 0) {
          idString += ",";
        }
        idString += getallInterest[index].id;
      }
    }
    if (getproductname == "") {
      setErrorMessage("Enter asset name");
      setShowModal(true);
    } else if (idString == "") {
      setErrorMessage("Please Select category");
      setShowModal(true);
    } else if (getProductDes == "") {
      setErrorMessage("Enter description");
      setShowModal(true);
    } else if (selectedImages.length <= 0) {
      setErrorMessage("Upload owner file");
      setShowModal(true);
    } else if (selectedVideos.length <= 0) {
      setErrorMessage("Upload video");
      setShowModal(true);
    } else if (
      selectedDate2 == null ||
      selectedDate2 == "" ||
      selectedDate2 == undefined
    ) {
      setErrorMessage("Please select date");
      setShowModal(true);
    } else if (
      (selectedAuction == 0 && getProductValue == null) ||
      getProductValue == "" ||
      getProductValue == 0
    ) {
      setErrorMessage("Enter Asset value");
      setShowModal(true);
    } else if (getvideoId == "") {
      setErrorMessage("Please attach video");
      setShowModal(true);
    } else {
      setGlobModalOpen(true);
    }
  };
  // not on platform creator api end

  const getUser = () => {
    const param = {
      user_id: userId,
    };
    axios
      .post(getall_user, param)
      .then((Response) => {
        // console.log(
        //   "Response.data.result",
        //   JSON.stringify(Response.data.result, null, 2)
        // );
        setalluser(Response.data.result);
        // this.setState({
        //   // data: Response.data.result,
        //   task_arrayholder: Response.data.result,
        // });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllClubs = () => {
    const param = {
      user_id: userId,
    };
    axios
      .post(get_all_club_2, param)
      .then((Response) => {
        console.log(
          "Response.data.result",
          JSON.stringify(Response.data.result, null, 2)
        );
        setallClubs(Response.data.result);
        setSearchallClubs(Response.data.result);
        // this.setState({
        //   // data: Response.data.result,
        //   task_arrayholder: Response.data.result,
        // });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getUser();
    getAllClubs();
  }, []);

  const selecteduser = (item) => {
    setCreatormodal(false);
    // setSharenfbModal(true);
    // setDonationOwnerName(item.label);
    // setDonationOwnerId(item.id);

    setGetcreatorname(item.label);
    setGetcreatorid(item.id);
  };

  const Search_user = (value) => {
    const newData = getalluser.filter(function (item) {
      const employee = item.label ? item.label.toUpperCase() : "".toUpperCase();
      const number = item.phone ? item.phone.toUpperCase() : "".toUpperCase();
      const email = item.email ? item.email.toUpperCase() : "".toUpperCase();

      const textData = value.toUpperCase();
      return (
        employee.indexOf(textData) > -1 ||
        number.indexOf(textData) > -1 ||
        email.indexOf(textData) > -1
      );
    });
    setdata_filter_data(newData);
    console.log("asvsvsds", newData);
  };

  const Search_clubs = (value) => {
    const newData = getsearchallclubs.filter(function (item) {
      const employee = item.club_name
        ? item.club_name.toUpperCase()
        : "".toUpperCase();
      const number = item.phone_number
        ? item.phone_number.toUpperCase()
        : "".toUpperCase();

      // const email = item.email ? item.email.toUpperCase() : "".toUpperCase();

      const textData = value.toUpperCase();
      return employee.indexOf(textData) > -1 || number.indexOf(textData) > -1;
    });
    // console.log("newData", newData);
    setallClubs(newData);
    console.log("asvsvsds", newData);
  };

  // ----- handle blur start

  // const handleBlur = () => {
  //   setAvailableBond(
  //     parseInt(getavailableBond) - parseInt(number_of_bond_for_current_owner)
  //   );
  //   console.log("getavailableBond", number_of_bond_for_current_owner);
  // };

  const handleBlur = () => {
    setAvailableBond(
      parseInt(getavailableBond) - parseInt(number_of_bond_for_current_owner)
    );
    setnumber_of_bonds_created_for_share((prevArray) => [
      ...prevArray,
      number_of_bond_for_current_owner,
    ]);
    setnumber_of_bonds_created_for_share_name((prevData) => [
      ...prevData,
      location.state.username,
    ]);
    setnumber_of_bonds_created_for_share_id((prevData) => [
      ...prevData,
      location.state.user_id,
    ]);
  };

  // ----- handle blur end

  // share nfb to qr hunt start

  const handleQrDonateInputChange = (data) => {
    // Ensure that the entered value is not greater than 70
    if (parseInt(data) <= getavailableBond || data === "") {
      // this.setState({ donate: data });
      setDonetQrhunt(data);
    } else {
      setShowModal1(true);
      setErrorMessage1("Enter valid bond");
    }
  };

  // share nfb to qr hunt end

  // media links start

  const [inputValues, setInputValues] = useState([""]);

  const validateSocialMediaLink = (link) => {
    // if (
    //   link.startsWith("https://www.facebook.com/") ||
    //   link.startsWith("https://facebook.com/")
    // ) {
    //   return true;
    // }
    // if (
    //   link.startsWith("https://www.instagram.com/") ||
    //   link.startsWith("https://instagram.com/")
    // ) {
    //   return true;
    // }

    // // Validation logic for YouTube
    // if (
    //   link.startsWith("https://www.youtube.com/") ||
    //   link.startsWith("https://youtube.com/")
    // ) {
    //   return true;
    // }

    // // Validation logic for LinkedIn
    // if (
    //   link.startsWith("https://www.linkedin.com/") ||
    //   link.startsWith("https://linkedin.com/")
    // ) {
    //   return true;
    // }

    // // Validation logic for Twitter
    // if (
    //   link.startsWith("https://www.twitter.com/") ||
    //   link.startsWith("https://twitter.com/")
    // ) {
    //   return true;
    // }

    // return false;
    const urlPattern =
      /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;

    // Test if the inputValue matches the URL pattern
    return urlPattern.test(link);
  };

  const handleInputChange = (text, index) => {
    const updatedInputValues = [...inputValues];
    updatedInputValues[index] = text;
    setInputValues(updatedInputValues);
  };

  const handleAddValue = () => {
    const isValid = inputValues.every(validateSocialMediaLink);

    if (isValid) {
      setInputValues([...inputValues, ""]); // Add a new empty input field
    } else {
      // Handle invalid input
      console.log("Please enter valid social media links for all fields.");
      setShowModal(true);
      setErrorMessage("Please enter valid social media links");
    }
  };

  const handleRemoveValue = () => {
    if (inputValues.length > 1) {
      const updatedInputValues = [...inputValues];
      updatedInputValues.pop(); // Remove the last input field
      setInputValues(updatedInputValues);
    }
  };

  // media links end

  const ddd = getavailableBond - getdonetQrhunt;

  //disable body scrolling

  const [bodyScrollEnabled, setBodyScrollEnabled] = useState(true);

  useEffect(() => {
    if (
      isModalOpen ||
      showModal ||
      creatormodal ||
      getshareClubModal ||
      getshareOwnerModal ||
      getsharenfbModal ||
      showModal1 ||
      getattechVideoModal ||
      getglobModalOpen
    ) {
      // Disable body scrolling
      setBodyScrollEnabled(false);
    } else {
      // Enable body scrolling
      setBodyScrollEnabled(true);
    }
    if (bodyScrollEnabled) {
      document.body.style.overflow = "visible";
    } else {
      document.body.style.overflow = "hidden";
    }
  }, [
    isModalOpen,
    showModal,
    creatormodal,
    getshareClubModal,
    getshareOwnerModal,
    getsharenfbModal,
    showModal1,
    getattechVideoModal,
    bodyScrollEnabled,
    getglobModalOpen,
  ]);

  return (
    <div className="page_main_wrapp">
      <>
        {getisLoading ? (
          <div className="loader"></div>
        ) : (
          <>
            {creatormodal == false ? (
              <>
                <div className="page_base_wrapp_2">
                  <NavbarSec name="Protect Asset" />

                  <>
                    <CustomStepper
                      activeStep={activeStep}
                      completed={completed}
                      steps={steps}
                      handleStep={handleStep}
                      handleBack={handleBack}
                      handleNext={handleNext}
                      handleComplete={handleComplete}
                      handleReset={handleReset}
                    />

                    <div style={{ width: "100%" }}>
                      {completed.length === steps.length ? (
                        <React.Fragment>
                          <Typography sx={{ mt: 2, mb: 1 }}>
                            All steps completed - you&apos;re finished
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              pt: 2,
                            }}
                          >
                            <Box sx={{ flex: "1 1 auto" }} />
                            <Button onClick={handleReset}>Reset</Button>
                          </Box>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          {activeStep === 0 && (
                            <>
                              <div
                                style={{
                                  width: "100%",
                                  padding: "10px 0px",
                                  // border: "1px solid green",
                                  margin: "10px auto",
                                }}
                              >
                                {/* PRODUCT NAME START */}
                                <label
                                  htmlFor="product_name"
                                  className="form_input_label"
                                >
                                  Asset Title/Name
                                </label>

                                <input
                                  type="text"
                                  name="product_name"
                                  className="form_input_box"
                                  placeholder="Enter Asset Name"
                                  value={getproductname}
                                  // onChange={(e) => {
                                  //   setproductname(e.target.value);
                                  // }}
                                  onChange={handleAssetsChange}
                                />
                                {/* PRODUCT NAME END */}

                                {/* NEW ARE YOU CREATOR START */}
                                <div className="glob_col_wrapp">
                                  <div className="glob_row_wrapp">
                                    <label
                                      htmlFor="glob_exclusive"
                                      className="form_input_label"
                                    >
                                      Are you the <br /> creator ?
                                    </label>
                                    <div id="custom_radio">
                                      <label class="custom_radio">
                                        <input
                                          type="radio"
                                          // name="glob_exclusive"
                                          value={1}
                                          checked={selectedOption == 1}
                                          onChange={handleOptionCreatorChange}
                                        />
                                        <span class="checkmark"></span>
                                        Yes
                                      </label>
                                      <label class="custom_radio">
                                        <input
                                          type="radio"
                                          // name="glob_exclusive"
                                          value={0}
                                          checked={selectedOption == 0}
                                          onChange={handleOptionCreatorChange}
                                        />
                                        <span class="checkmark"></span>
                                        No
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                {/* NEW ARE YOU CREATOR END */}
                                {selectedCreator == 1 && (
                                  <input
                                    type="text"
                                    name="product_name"
                                    className="form_input_box"
                                    placeholder={getownerName}
                                    // onChange={(e) => {
                                    //   setproductname(e.target.value);
                                    // }}
                                    disabled
                                    value={getownerName}
                                  />
                                )}
                                {/* CREATOR FIRST SECTION START */}
                                <label
                                  htmlFor="category"
                                  className="form_input_label mt-2"
                                >
                                  Select Category
                                </label>

                                {is_loading ? (
                                  <div className="loader"></div>
                                ) : (
                                  <div className="product_interests_wrapp">
                                    {getallInterest &&
                                      getallInterest.length > 0 &&
                                      getallInterest.map((item, index) => {
                                        return (
                                          <>
                                            <button
                                              onClick={() => {
                                                const updatedArray = [
                                                  ...getallInterest,
                                                ];
                                                updatedArray[index].status =
                                                  updatedArray[index].status ===
                                                  1
                                                    ? 2
                                                    : 1;

                                                // this.setState({ myArray: updatedArray });
                                                setInterestArray(updatedArray);

                                                console.log(
                                                  "===================================="
                                                );
                                                console.log(
                                                  "getallInterest",
                                                  getallInterest
                                                );
                                                console.log(
                                                  "===================================="
                                                );
                                              }}
                                              className="interest_sigle_btn"
                                              style={{
                                                border: "1px solid",
                                                borderColor:
                                                  item.status == 2
                                                    ? "var(--color-main)"
                                                    : "var(--color-gray)",
                                              }}
                                            >
                                              <img
                                                src={
                                                  "https://www.ravi.host/public/assets/interest/" +
                                                  item.icon
                                                }
                                                className="interest_sigle_icon"
                                                alt=""
                                              />
                                              <p className="interest_sigle_icon_name">
                                                {item.name}
                                              </p>
                                            </button>
                                          </>
                                        );
                                      })}
                                  </div>
                                )}

                                {/* CREATOR FIRST SECTION END */}

                                {/*new date of creation start */}
                                {/* date of creation start */}
                                <div
                                  className="glob_row_wrapp"
                                  style={{ marginTop: "18px" }}
                                >
                                  <label
                                    htmlFor="glob_exclusive"
                                    className="form_input_label"
                                  >
                                    Date of creation
                                  </label>

                                  <>
                                    <div className="custom-date-picker">
                                      <div className="date-input">
                                        {/* <input
                                  style={{
                                    width: "38px",
                                    padding: "5px 10px",
                                    marginBottom: "5px",
                                  }}
                                  type="text"
                                  placeholder="DD"
                                  readOnly
                                  // value={moment(startDate).format("DD")}
                                  value={
                                    isyear
                                      ? "-"
                                      : moment(startDate).format("DD")
                                  }
                                  // onFocus={() => setSelectedDate(new Date())}
                                  onClick={handleClick}
                                />
                                <span style={{ padding: "0px", margin: "0px" }}>
                                  /
                                </span>
                                <input
                                  style={{
                                    width: "38px",
                                    padding: "5px 10px",
                                    marginBottom: "5px",
                                  }}
                                  type="text"
                                  readOnly
                                  placeholder="MM"
                                  maxDate={(new Date(), 0)}
                                  value={
                                    isyear
                                      ? "-"
                                      : moment(startDate).format("MM")
                                  }
                                  // onFocus={() => setSelectedDate(new Date())}
                                  onClick={handleClick}
                                />
                                <span style={{ padding: "0px", margin: "0px" }}>
                                  /
                                </span>
                                <input
                                  style={{
                                    width: "51px",
                                    padding: "5px 10px",
                                    marginBottom: "5px",
                                  }}
                                  type="text"
                                  placeholder="YYYY"
                                  readOnly
                                  value={moment(startDate).format("YYYY")}
                                  onClick={handleClick2}
                                  // onFocus={toggleYearPicker}
                                /> */}

                                        <DatePicker
                                          selected={selectedDate2}
                                          onChange={handleDateChange}
                                          placeholderText="DD/MM/YYYY"
                                          dateFormat="dd/MM/yyyy"
                                          maxDate={currentDate}
                                          showYearDropdown
                                          showMonthDropdown
                                          scrollableYearDropdown
                                          yearDropdownItemNumber={10}
                                          dropdownMode="select"
                                          isClearable
                                          // disabledKeyboardNavigation
                                          minDate={new Date(1300, 0, 1)}
                                        />
                                      </div>

                                      {isOpen && (
                                        <DatePicker
                                          selected={startDate}
                                          onChange={handleChange}
                                          inline
                                          openToDate={new Date()}
                                        />
                                      )}

                                      {isOpen2 && (
                                        <DatePicker
                                          selected={startDate2}
                                          onChange={handleChange2}
                                          inline
                                          showYearPicker
                                        />
                                      )}
                                      {/* 
                              {selectedDate && !showYearPicker && (
                                <DatePicker
                                  selected={selectedDate}
                                  onChange={(date) => setSelectedDate(date)}
                                  dateFormat="dd/MM/yyyy"
                                />
                              )} */}
                                    </div>
                                  </>

                                  {/* date picker end */}
                                </div>
                                {/* date of creation end */}
                                {/*new date of creation end */}
                              </div>

                              {/* buttons start */}
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  pt: 2,
                                }}
                              >
                                <Box sx={{ flex: "1 1 auto" }} />
                                <button
                                  onClick={handleNext}
                                  style={{
                                    color: "var(--color-main)",
                                    border: "1px solid var(--color-main)",
                                    padding: "10px 14px",
                                    fontFamily: "var(--font-600)",
                                    fontSize: "12px",
                                    borderRadius: "8px",
                                    lineHeight: "12px",
                                  }}
                                >
                                  NEXT
                                </button>
                                {activeStep !== steps.length &&
                                  (completed[activeStep] ? (
                                    <p
                                      // variant="caption"
                                      style={{
                                        display: "inline-block",
                                        color: "#fff",
                                        fontSize: "12px",
                                        fontFamily: "var(--font-400)",
                                        padding: "6px 12px",
                                      }}
                                    >
                                      Step {activeStep + 1} already completed
                                    </p>
                                  ) : (
                                    <button
                                      onClick={() => {
                                        ValidateStep1();
                                      }}
                                      style={{
                                        color: "var(--color-main)",
                                        // border: "1px solid var(--color-main)",
                                        padding: "10px 14px",
                                        fontFamily: "var(--font-600)",
                                        fontSize: "12px",
                                        borderRadius: "8px",
                                        backgroundColor: "#fff",
                                        lineHeight: "12px",
                                        marginLeft: "10px",
                                      }}
                                    >
                                      SUBMIT
                                    </button>
                                  ))}
                              </Box>
                              {/* buttons end */}
                            </>
                          )}

                          {activeStep === 1 && (
                            <>
                              <div
                                style={{
                                  width: "100%",
                                  padding: "10px 0px",
                                  // border: "1px solid green",
                                  margin: "10px auto",
                                }}
                              >
                                {/* PRODUCT DESCRIPTION START  */}
                                <label
                                  htmlFor="product_des"
                                  className="form_input_label mt-2"
                                >
                                  Asset Description
                                </label>
                                <textarea
                                  name="product_des"
                                  rows="6"
                                  className="form_input_box"
                                  value={getProductDes}
                                  onChange={(e) =>
                                    setProductDes(e.target.value)
                                  }
                                ></textarea>
                                {/* PRODUCT DESCRIPTION END  */}

                                {/* PRODUCT STORY START  */}
                                <label
                                  htmlFor="product_story"
                                  className="form_input_label mt-2"
                                >
                                  Asset Story/Statement
                                </label>
                                <textarea
                                  name="product_story"
                                  rows="6"
                                  className="form_input_box"
                                  value={getProductStory}
                                  onChange={(e) =>
                                    setProductStory(e.target.value)
                                  }
                                ></textarea>
                                {/* PRODUCT STORY END  */}

                                {/* PRODUCT HIGHLIGHT START  */}
                                <label
                                  htmlFor="product_highlight"
                                  className="form_input_label mt-2"
                                >
                                  Asset Highlights
                                </label>
                                <textarea
                                  name="product_highlight"
                                  rows="6"
                                  className="form_input_box"
                                  value={getProductHighlight}
                                  onChange={(e) =>
                                    setProductHighlight(e.target.value)
                                  }
                                ></textarea>
                                {/* PRODUCT HIGHLIGHT END  */}
                                {/* HASHTAGS START */}
                                <label
                                  htmlFor="product_hastags"
                                  className="form_input_label mt-2"
                                >
                                  Hashtags
                                </label>
                                <textarea
                                  onChange={(e) => {
                                    const data = e.target.value;
                                    const updatedValue = data.replace(
                                      /\s+/g,
                                      "#"
                                    );

                                    setHashtags(updatedValue);

                                    let formattedText = data.replace(
                                      /(\s|^)(\w+)/g,
                                      "$1#$2"
                                    );
                                    formattedText = formattedText.replace(
                                      /##/g,
                                      "#"
                                    ); // Remove duplicate hashtags
                                    setHashtags(formattedText);
                                  }}
                                  value={gethashtags}
                                  name="product_hastags"
                                  rows="5"
                                  className="form_input_box"
                                ></textarea>
                                {/* HASHTAGS END */}
                              </div>

                              {/* buttons start */}
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  pt: 2,
                                }}
                              >
                                <button
                                  style={{
                                    color: "var(--color-main)",
                                    border: "1px solid var(--color-main)",
                                    padding: "10px 14px",
                                    fontFamily: "var(--font-600)",
                                    fontSize: "12px",
                                    borderRadius: "8px",
                                    lineHeight: "12px",
                                  }}
                                  color="inherit"
                                  // disabled={activeStep === 0}
                                  onClick={handleBack}
                                  sx={{ mr: 1 }}
                                >
                                  Back
                                </button>
                                <Box sx={{ flex: "1 1 auto" }} />
                                <button
                                  onClick={handleNext}
                                  style={{
                                    color: "var(--color-main)",
                                    border: "1px solid var(--color-main)",
                                    padding: "10px 14px",
                                    fontFamily: "var(--font-600)",
                                    fontSize: "12px",
                                    borderRadius: "8px",
                                    lineHeight: "12px",
                                  }}
                                >
                                  NEXT
                                </button>
                                {activeStep !== steps.length &&
                                  (completed[activeStep] ? (
                                    <p
                                      // variant="caption"
                                      style={{
                                        display: "inline-block",
                                        color: "#fff",
                                        fontSize: "12px",
                                        fontFamily: "var(--font-400)",
                                        padding: "6px 12px",
                                      }}
                                    >
                                      Step {activeStep + 1} already completed
                                    </p>
                                  ) : (
                                    <button
                                      onClick={() => {
                                        ValidateStep2();
                                      }}
                                      style={{
                                        color: "var(--color-main)",
                                        // border: "1px solid var(--color-main)",
                                        padding: "10px 14px",
                                        fontFamily: "var(--font-600)",
                                        fontSize: "12px",
                                        borderRadius: "8px",
                                        backgroundColor: "#fff",
                                        lineHeight: "12px",
                                        marginLeft: "10px",
                                      }}
                                    >
                                      SUBMIT
                                    </button>
                                  ))}
                              </Box>
                              {/* buttons end */}
                            </>
                          )}

                          {activeStep === 2 && (
                            <>
                              <div
                                style={{
                                  width: "100%",
                                  padding: "10px 0px",
                                  // border: "1px solid red",
                                  margin: "10px auto",
                                }}
                              >
                                {/* UPLOAD AUTHNATICATION FILE START */}
                                <label
                                  htmlFor="auth_file"
                                  className="form_input_label mt-2"
                                >
                                  Upload Authentication File
                                </label>
                                <div className="image-upload">
                                  <label
                                    htmlFor="file-input"
                                    className="upload-button"
                                  >
                                    <img src={images.gallery_icon} alt="" />
                                  </label>
                                  <input
                                    id="file-input"
                                    type="file"
                                    accept="image/*"
                                    onChange={handleImageChange}
                                  />
                                  <div className="image-previews">
                                    {selectedImages.map((image, index) => (
                                      <div
                                        className="image-preview"
                                        key={index}
                                      >
                                        <img
                                          src={URL.createObjectURL(image)}
                                          alt={`Preview ${index}`}
                                        />
                                        <button
                                          className="delete-button"
                                          onClick={() =>
                                            handleImageDelete(index)
                                          }
                                        >
                                          <img src={images.close_red} alt="" />
                                        </button>
                                      </div>
                                    ))}

                                    {/* {getimg_video && getimg_video.length > 0
                          ? getimg_video.map((itm, ind) => {
                              return ( */}
                                    <div className="image-preview">
                                      <img
                                        src={location.state.owner_proof}
                                        alt={`Preview`}
                                      />
                                      {/* <button
                                    className="delete-button"
                                    onClick={() =>
                                      handleImageDelete2back(ind, itm)
                                    }
                                  >
                                    <img src={images.close_red} alt="" />
                                  </button> */}
                                    </div>
                                    {/* );
                             })
                           : null} */}
                                  </div>
                                </div>
                                {/* UPLOAD AUTHNATICATION FILE END */}
                                {/* UPLOAD MINIMUM 1 IMAGE START */}
                                <label
                                  htmlFor="product_name"
                                  className="form_input_label mt-2"
                                >
                                  Upload minimum 1 image
                                </label>
                                <div className="image-upload">
                                  <label
                                    htmlFor="min_1_img"
                                    className="upload-button"
                                  >
                                    <img src={images.gallery_icon} alt="" />
                                  </label>
                                  <input
                                    id="min_1_img"
                                    type="file"
                                    accept="image/*"
                                    multiple
                                    onChange={handleImageChangeMin1Img}
                                  />
                                  <div className="image-previews">
                                    {selectedImagesMin1Img.map(
                                      (image, index) => (
                                        <div
                                          className="image-preview"
                                          key={index}
                                        >
                                          <img
                                            src={URL.createObjectURL(image)}
                                            alt={`Preview ${index}`}
                                          />
                                          <button
                                            className="delete-button"
                                            onClick={() =>
                                              handleImageDelete2(index)
                                            }
                                          >
                                            <img
                                              src={images.close_red}
                                              alt=""
                                            />
                                          </button>
                                        </div>
                                      )
                                    )}

                                    {getimg_video && getimg_video.length > 0
                                      ? getimg_video.map((itm, ind) => {
                                          return (
                                            <div
                                              className="image-preview"
                                              key={ind}
                                            >
                                              <img
                                                src={itm.img_video}
                                                alt={`Preview ${ind}`}
                                              />
                                              <button
                                                className="delete-button"
                                                onClick={() =>
                                                  handleImageDelete2back(
                                                    ind,
                                                    itm
                                                  )
                                                }
                                              >
                                                <img
                                                  src={images.close_red}
                                                  alt=""
                                                />
                                              </button>
                                            </div>
                                          );
                                        })
                                      : null}
                                  </div>
                                </div>
                                {/* UPLOAD MINIMUM 1 IMAGE END */}

                                {/* UPLOAD IMAGE/VIDEO START */}
                                <label
                                  htmlFor="upload_media"
                                  className="form_input_label mt-2"
                                >
                                  Upload media images
                                </label>

                                <div className="image-upload">
                                  <label
                                    htmlFor="media_img_vid"
                                    className="upload-button"
                                  >
                                    <img src={images.gallery_icon} alt="" />
                                  </label>
                                  <input
                                    id="media_img_vid"
                                    type="file"
                                    accept="image/*"
                                    multiple
                                    onChange={handleImageAndVideoChange}
                                  />
                                  <div className="image-previews">
                                    {selectedImagesAndVidio.map(
                                      (image, index) => (
                                        <div
                                          className="image-preview"
                                          key={index}
                                        >
                                          <img
                                            src={URL.createObjectURL(image)}
                                            alt={`Preview ${index}`}
                                          />
                                          <button
                                            className="delete-button"
                                            onClick={() =>
                                              handleImageAndVideoDelete(index)
                                            }
                                          >
                                            <img
                                              src={images.close_red}
                                              alt=""
                                            />
                                          </button>
                                        </div>
                                      )
                                    )}
                                  </div>
                                </div>
                                {/* UPLOAD IMAGE/VIDEO END */}
                              </div>

                              {/* buttons start */}
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  pt: 2,
                                }}
                              >
                                <button
                                  style={{
                                    color: "var(--color-main)",
                                    border: "1px solid var(--color-main)",
                                    padding: "10px 14px",
                                    fontFamily: "var(--font-600)",
                                    fontSize: "12px",
                                    borderRadius: "8px",
                                    lineHeight: "12px",
                                  }}
                                  color="inherit"
                                  // disabled={activeStep === 0}
                                  onClick={handleBack}
                                  sx={{ mr: 1 }}
                                >
                                  Back
                                </button>
                                <Box sx={{ flex: "1 1 auto" }} />
                                <button
                                  onClick={handleNext}
                                  style={{
                                    color: "var(--color-main)",
                                    border: "1px solid var(--color-main)",
                                    padding: "10px 14px",
                                    fontFamily: "var(--font-600)",
                                    fontSize: "12px",
                                    borderRadius: "8px",
                                    lineHeight: "12px",
                                  }}
                                >
                                  NEXT
                                </button>
                                {activeStep !== steps.length &&
                                  (completed[activeStep] ? (
                                    <p
                                      // variant="caption"
                                      style={{
                                        display: "inline-block",
                                        color: "#fff",
                                        fontSize: "12px",
                                        fontFamily: "var(--font-400)",
                                        padding: "6px 12px",
                                      }}
                                    >
                                      Step {activeStep + 1} already completed
                                    </p>
                                  ) : (
                                    <button
                                      onClick={() => {
                                        ValidateStep3();
                                      }}
                                      style={{
                                        color: "var(--color-main)",
                                        // border: "1px solid var(--color-main)",
                                        padding: "10px 14px",
                                        fontFamily: "var(--font-600)",
                                        fontSize: "12px",
                                        borderRadius: "8px",
                                        backgroundColor: "#fff",
                                        lineHeight: "12px",
                                        marginLeft: "10px",
                                      }}
                                    >
                                      SUBMIT
                                    </button>
                                  ))}
                              </Box>
                              {/* buttons end */}
                            </>
                          )}

                          {activeStep === 3 && (
                            <>
                              <div
                                style={{
                                  width: "100%",
                                  padding: "10px 0px",
                                  // border: "1px solid green",
                                  margin: "10px auto",
                                }}
                              >
                                {/* Attach video section start */}
                                <label
                                  htmlFor="creator_video"
                                  className="form_input_label"
                                >
                                  Attach Creator Video
                                  {/* <span
                                      className="details_home_icon"
                                      onClick={() => {
                                        setShowModal1(true);
                                        setErrorMessage1(
                                          "Bring your Aucemo to life! Record a captivating video showcasing your asset in all its glory. Show or speak about its unique features, craftsmanship, or the story behind its creation. Remember to keep it between 20 seconds to 1 minute for the best impact."
                                        );
                                      }}
                                    >
                                      {" "}
                                      {String.fromCharCode(9432)}
                                    </span> */}
                                </label>
                                <div
                                  style={{ width: "100%", marginTop: "8px" }}
                                >
                                  <button
                                    onClick={() => {
                                      setAttechVideoModal(true);
                                    }}
                                    className="attach_video_btn_cp"
                                    style={{ marginBottom: "10px" }}
                                  >
                                    Attach Video
                                  </button>
                                </div>
                                {/* Attach video section end */}

                                {/* CREATOR VIDEO START */}
                                <label
                                  htmlFor="creator_video"
                                  className="form_input_label"
                                >
                                  Record Asset Video{" "}
                                  <span
                                    className="details_home_icon"
                                    onClick={() => {
                                      setShowModal1(true);
                                      setErrorMessage1(
                                        "Bring your Aucemo to life! Record a captivating video showcasing your asset in all its glory. Show or speak about its unique features, craftsmanship, or the story behind its creation. Remember to keep it between 30 seconds to 1 minute for the best impact."
                                      );
                                    }}
                                  >
                                    {" "}
                                    {String.fromCharCode(9432)}
                                  </span>
                                </label>

                                {/* <div className="image-upload">
                          {selectedVideos && selectedVideos.length <= 0 ? (
                            <>
                              <label
                                htmlFor="creator_vid"
                                className="upload-button"
                              >
                                <img src={images.gallery_icon} alt="" />
                              </label>
                              <input
                                id="creator_vid"
                                type="file"
                                accept="video/*"
                                capture="camera"
                                // multiple
                                onChange={handleVideoChange}
                              />
                            </>
                          ) : (
                            <div className="image-previews">
                              {selectedVideos.map((image, index) => (
                                <div
                                  className="image-preview"
                                  key={index}
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    border: "1px dashed gray",
                                    borderRadius: "8px",
                                  }}
                                >
                             
                                  <button
                                    onClick={() => {
                                      setPreviewVideoModal2(true);
                                      setUserVideo2(URL.createObjectURL(image));
                                      console.log(
                                        "aaa",
                                        URL.createObjectURL(image)
                                      );
                                    }}
                                    style={{
                                      color: "var(--color-main)",
                                      fontSize: "12px",
                                      fontFamily: "var(--font-600)",
                                    }}
                                  >
                                    Preview
                                  </button>
                                  <button
                                    className="delete-button"
                                    onClick={() => handleVideoDelete(index)}
                                  >
                                    <img src={images.close_red} alt="" />
                                  </button>
                                </div>
                              ))}
                            </div>
                          )}
                        </div> */}

                                <div className="image-upload">
                                  {selectedVideos &&
                                  selectedVideos.length <= 0 ? (
                                    <>
                                      <label
                                        onClick={() => {
                                          setIsModalVisiblevideo(true);
                                          setRecordedChunks([]);
                                          setrecordedvideo(false);
                                        }}
                                        htmlFor="creator_vid"
                                        className="upload-button"
                                      >
                                        <img src={images.gallery_icon} alt="" />
                                      </label>
                                      <input
                                        disabled
                                        id="creator_vid"
                                        type="file"
                                        accept="video/*"
                                        // multiple
                                        capture="camera"
                                        onChange={(e) => {
                                          handleVideoChange(e);
                                          // setIsModalVisiblevideo(true);
                                        }}
                                      />
                                      {/* <button onClick={handleVideoChange}>
                                Start Recording
                              </button>
                              <button onClick={stopRecording}>
                                Stop Recording
                              </button>
                              <video ref={videoRef} autoPlay muted /> */}
                                    </>
                                  ) : (
                                    <div className="image-previews">
                                      {selectedVideos.map((image, index) => (
                                        <div
                                          className="image-preview"
                                          key={index}
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            border: "1px dashed gray",
                                            borderRadius: "8px",
                                          }}
                                        >
                                          {/* <img
                                  src={URL.createObjectURL(image)}
                                  alt={`Preview ${index}`}
                                /> */}
                                          <button
                                            onClick={() => {
                                              setPreviewVideoModal2(true);
                                              setUserVideo2(
                                                URL.createObjectURL(image)
                                              );
                                            }}
                                            style={{
                                              color: "var(--color-main)",
                                              fontSize: "12px",
                                              fontFamily: "var(--font-600)",
                                            }}
                                          >
                                            Preview
                                          </button>
                                          <button
                                            className="delete-button"
                                            onClick={() =>
                                              handleVideoDelete(index)
                                            }
                                          >
                                            <img
                                              src={images.close_red}
                                              alt=""
                                            />
                                          </button>
                                        </div>
                                      ))}
                                    </div>
                                  )}
                                </div>
                                {/* CREATOR VIDEO END */}
                              </div>
                              {/* buttons start */}
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  pt: 2,
                                }}
                              >
                                <button
                                  style={{
                                    color: "var(--color-main)",
                                    border: "1px solid var(--color-main)",
                                    padding: "10px 14px",
                                    fontFamily: "var(--font-600)",
                                    fontSize: "12px",
                                    borderRadius: "8px",
                                    lineHeight: "12px",
                                  }}
                                  color="inherit"
                                  // disabled={activeStep === 0}
                                  onClick={handleBack}
                                  sx={{ mr: 1 }}
                                >
                                  Back
                                </button>
                                <Box sx={{ flex: "1 1 auto" }} />
                                <button
                                  onClick={handleNext}
                                  style={{
                                    color: "var(--color-main)",
                                    border: "1px solid var(--color-main)",
                                    padding: "10px 14px",
                                    fontFamily: "var(--font-600)",
                                    fontSize: "12px",
                                    borderRadius: "8px",
                                    lineHeight: "12px",
                                  }}
                                >
                                  NEXT
                                </button>
                                {activeStep !== steps.length &&
                                  (completed[activeStep] ? (
                                    <p
                                      // variant="caption"
                                      style={{
                                        display: "inline-block",
                                        color: "#fff",
                                        fontSize: "12px",
                                        fontFamily: "var(--font-400)",
                                        padding: "6px 12px",
                                      }}
                                    >
                                      Step {activeStep + 1} already completed
                                    </p>
                                  ) : (
                                    <button
                                      onClick={() => {
                                        ValidateStep4();
                                      }}
                                      style={{
                                        color: "var(--color-main)",
                                        // border: "1px solid var(--color-main)",
                                        padding: "10px 14px",
                                        fontFamily: "var(--font-600)",
                                        fontSize: "12px",
                                        borderRadius: "8px",
                                        backgroundColor: "#fff",
                                        lineHeight: "12px",
                                        marginLeft: "10px",
                                      }}
                                    >
                                      SUBMIT
                                    </button>
                                  ))}
                              </Box>
                              {/* buttons end */}
                            </>
                          )}

                          {activeStep === 4 && (
                            <>
                              <div
                                style={{
                                  width: "100%",
                                  padding: "10px 0px",
                                  // border: "1px solid green",
                                  margin: "10px auto",
                                }}
                              >
                                {/* PRODUCT VALUE START */}
                                {selectedAuction == 0 && (
                                  <div className="glob_col_wrapp">
                                    <div className="glob_row_wrapp">
                                      <label
                                        htmlFor="product_value"
                                        className="form_input_label"
                                      >
                                        Enter asset value
                                      </label>

                                      <input
                                        type="number"
                                        name="product_value"
                                        className="product_val_input"
                                        placeholder=""
                                        value={getProductValue}
                                        onChange={(e) => {
                                          const inputValue = e.target.value;
                                          // const isValid = /^\d+$/.test(inputValue); // Checks if the value is a positive integer

                                          // if (inputValue < 0) {
                                          //   setProductValue("");
                                          //   setErrorMessage1(
                                          //     "Please enter valid asset value"
                                          //   );
                                          //   setShowModal1(true);
                                          // } else {
                                          //   setProductValue(inputValue);
                                          //   console.log("isValid", inputValue);
                                          // }
                                          const isValid =
                                            /^\d+$/.test(inputValue) &&
                                            parseInt(inputValue, 10) >= 0;

                                          if (!isValid) {
                                            setProductValue("");
                                            setErrorMessage1(
                                              "Please enter valid asset value"
                                            );
                                            setShowModal1(true);
                                          } else {
                                            setProductValue(
                                              parseInt(inputValue, 10)
                                            );
                                            console.log("isValid", inputValue);
                                          }
                                        }}
                                      />
                                    </div>
                                  </div>
                                )}
                                {/* PRODUCT VALUE END */}

                                {/* links start */}

                                <label
                                  htmlFor="product_name"
                                  className="form_input_label mt-2"
                                >
                                  Media Links
                                </label>

                                <>
                                  {inputValues.map((value, index) => (
                                    <div
                                      key={index}
                                      className="cp_medialink_signle_wrapp"
                                    >
                                      <input
                                        style={{
                                          width: "80%",
                                          marginBottom: "0px",
                                        }}
                                        className="form_input_box"
                                        placeholder="Enter a Link"
                                        placeholderTextColor="gray"
                                        value={value}
                                        onChange={(e) =>
                                          handleInputChange(
                                            e.target.value,
                                            index
                                          )
                                        }
                                      />
                                      {index === inputValues.length - 1 ? (
                                        <div className="cp_ml_btns_wrapp">
                                          <button
                                            className="cp_ml_plus_btn"
                                            onClick={handleAddValue}
                                          >
                                            +
                                          </button>
                                          {inputValues.length > 1 && (
                                            <button
                                              className="cp_ml_mius_btn"
                                              onClick={handleRemoveValue}
                                            >
                                              -
                                            </button>
                                          )}
                                        </div>
                                      ) : null}
                                    </div>
                                  ))}
                                </>

                                {/* links end */}
                              </div>
                              {/* buttons start */}
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  pt: 2,
                                }}
                              >
                                <button
                                  style={{
                                    color: "var(--color-main)",
                                    border: "1px solid var(--color-main)",
                                    padding: "10px 14px",
                                    fontFamily: "var(--font-600)",
                                    fontSize: "12px",
                                    borderRadius: "8px",
                                    lineHeight: "12px",
                                  }}
                                  color="inherit"
                                  // disabled={activeStep === 0}
                                  onClick={handleBack}
                                  sx={{ mr: 1 }}
                                >
                                  Back
                                </button>
                                <Box sx={{ flex: "1 1 auto" }} />
                                <button
                                  onClick={handleNext}
                                  style={{
                                    color: "var(--color-main)",
                                    border: "1px solid var(--color-main)",
                                    padding: "10px 14px",
                                    fontFamily: "var(--font-600)",
                                    fontSize: "12px",
                                    borderRadius: "8px",
                                    lineHeight: "12px",
                                  }}
                                >
                                  NEXT
                                </button>
                                {activeStep !== steps.length &&
                                  (completed[activeStep] ? (
                                    <p
                                      // variant="caption"
                                      style={{
                                        display: "inline-block",
                                        color: "#fff",
                                        fontSize: "12px",
                                        fontFamily: "var(--font-400)",
                                        padding: "6px 12px",
                                      }}
                                    >
                                      Step {activeStep + 1} already completed
                                    </p>
                                  ) : (
                                    <button
                                      onClick={() => {
                                        ValidateStep5();
                                      }}
                                      style={{
                                        color: "var(--color-main)",
                                        // border: "1px solid var(--color-main)",
                                        padding: "10px 14px",
                                        fontFamily: "var(--font-600)",
                                        fontSize: "12px",
                                        borderRadius: "8px",
                                        backgroundColor: "#fff",
                                        lineHeight: "12px",
                                        marginLeft: "10px",
                                      }}
                                    >
                                      SUBMIT
                                    </button>
                                  ))}
                              </Box>
                              {/* buttons end */}
                            </>
                          )}

                          {activeStep === 5 && (
                            <>
                              <div
                                style={{
                                  width: "100%",
                                  padding: "10px 0px",
                                  // border: "1px solid green",
                                  margin: "10px auto",
                                }}
                              >
                                {/* GLOBAL EXCLUSIVE START */}
                                <div className="glob_col_wrapp">
                                  <div className="glob_row_wrapp">
                                    <label
                                      htmlFor="glob_exclusive"
                                      className="form_input_label"
                                    >
                                      Global Exclusive
                                    </label>
                                    <div id="custom_radio">
                                      <label class="custom_radio">
                                        <input
                                          type="radio"
                                          name="glob_exclusive"
                                          value={1}
                                          checked={selectedGlob == 1}
                                          onChange={handleGlobOptionChange}
                                        />
                                        <span class="checkmark"></span>
                                        Yes
                                      </label>
                                      <label class="custom_radio">
                                        <input
                                          type="radio"
                                          name="glob_exclusive"
                                          value={0}
                                          checked={selectedGlob == 0}
                                          onChange={handleGlobOptionChange}
                                        />
                                        <span class="checkmark"></span>
                                        No
                                      </label>
                                    </div>
                                  </div>
                                  {/* <p className="red_err_text">
                            Read it properly before choosing the correct option.
                          </p> */}
                                </div>
                                {/* GLOBAL EXCLUSIVE END */}

                                {/* AUCTION BTN START */}
                                <div className="glob_col_wrapp">
                                  <div className="glob_row_wrapp">
                                    <label
                                      htmlFor="auction"
                                      className="form_input_label"
                                    >
                                      Start auction to set base value?
                                    </label>
                                    <div id="custom_radio">
                                      <label class="custom_radio">
                                        <input
                                          type="radio"
                                          name="auction"
                                          value={1}
                                          checked={selectedAuction == 1}
                                          onChange={handleAuctionOptionChange}
                                        />
                                        <span class="checkmark"></span>
                                        Yes
                                      </label>
                                      <label class="custom_radio">
                                        <input
                                          type="radio"
                                          name="auction"
                                          value={0}
                                          checked={selectedAuction == 0}
                                          onChange={handleAuctionOptionChange}
                                        />
                                        <span class="checkmark"></span>
                                        No
                                      </label>
                                    </div>
                                  </div>
                                  {/* <p className="red_err_text">
                            Read it properly before choosing the correct option.
                          </p> */}

                                  {selectedAuction == 1 && (
                                    <>
                                      {/* minimum bid start */}
                                      <div
                                        className="glob_col_wrapp"
                                        style={{ marginBottom: "-10px" }}
                                      >
                                        <div className="glob_row_wrapp">
                                          <label
                                            htmlFor="product_value"
                                            className="form_input_label"
                                          >
                                            Enter minimum bid
                                          </label>

                                          <input
                                            type="number"
                                            name="product_value"
                                            className="product_val_input"
                                            placeholder=""
                                            value={getminimumBid}
                                            onChange={(e) => {
                                              // const inputValue = e.target.value;
                                              // if (inputValue < 0) {
                                              //   setMinimumBid(0);
                                              //   setErrorMessage1(
                                              //     "Please enter valid minimum bid value"
                                              //   );
                                              //   setShowModal1(true);
                                              // } else {
                                              //   setMinimumBid(inputValue);
                                              //   console.log("isValid", inputValue);
                                              // }
                                              const inputValue = e.target.value;
                                              const isValid =
                                                /^\d+$/.test(inputValue) &&
                                                parseInt(inputValue, 10) >= 0;

                                              if (!isValid) {
                                                setMinimumBid("");
                                                setErrorMessage1(
                                                  "Please enter valid minimum bid value"
                                                );
                                                setShowModal1(true);
                                              } else {
                                                setMinimumBid(
                                                  parseInt(inputValue, 10)
                                                );
                                                console.log(
                                                  "isValid",
                                                  inputValue
                                                );
                                              }
                                            }}
                                          />
                                        </div>
                                      </div>
                                      {/* minimum bid end */}
                                      {/* auction duration start */}
                                      <div
                                        className="glob_col_wrapp"
                                        style={{ marginBottom: "-10px" }}
                                      >
                                        <div className="glob_row_wrapp">
                                          <label
                                            htmlFor="product_value"
                                            className="form_input_label"
                                          >
                                            Auction Duration
                                          </label>

                                          <input
                                            type="number"
                                            name="product_value"
                                            className="product_val_input"
                                            placeholder=""
                                            value={getauctionDurtion}
                                            onChange={(e) => {
                                              const inputValue = e.target.value;
                                              const isValid =
                                                /^\d+$/.test(inputValue) &&
                                                parseInt(inputValue, 10) >= 0;

                                              if (!isValid) {
                                                setAuctoinDuration("");
                                                setErrorMessage1(
                                                  "Please enter a valid non-negative and decimal Auction Duration value"
                                                );
                                                setShowModal1(true);
                                              } else {
                                                setAuctoinDuration(
                                                  parseInt(inputValue, 10)
                                                );
                                                console.log(
                                                  "isValid",
                                                  inputValue
                                                );
                                              }
                                            }}
                                          />
                                        </div>
                                      </div>
                                      {/* auction duration end */}
                                    </>
                                  )}
                                </div>
                                {/* AUCTION BTN END */}

                                {/* HOW MANY SHARE Aucemo START */}

                                {/* <div> */}
                                {selectedAuction == 0 && (
                                  <>
                                    <label
                                      htmlFor="product_name"
                                      className="form_input_label mt-2"
                                    >
                                      How many Aucemo you want to gift to{" "}
                                      <span
                                        style={{ color: "var(--color-main)" }}
                                      >
                                        {location.state.username &&
                                          location.state.username}
                                      </span>
                                    </label>
                                    {/* <input
                              type="number"
                              name="product_name"
                              className="form_input_box"
                              // placeholder="Enter Creator Name"
                              value={number_of_bond_for_current_owner}
                              onChange={(e) => {
                                // const inputValue = e.target.value;
                                // const isValid =
                                //   /^\d+$/.test(inputValue) &&
                                //   parseInt(inputValue, 10) >= 0;

                                // if (inputValue !== 0 && !isValid) {
                                //   setErrorMessage("Invalid value");
                                //   setnumber_of_bond_for_current_owner(0);
                                //   setShowModal(true);
                                // } else
                                if (selectedGlob == 1) {
                                  if (e.target.value > 90) {
                                    setErrorMessage(
                                      "You can't share more then 90 Aucemo."
                                    );
                                    setnumber_of_bond_for_current_owner(0);
                                    setShowModal(true);
                                  }
                                } else if (selectedGlob == 0) {
                                  if (e.target.value > 80) {
                                    setErrorMessage(
                                      "You can't share more then 80 Aucemo."
                                    );
                                    setShowModal(true);
                                    setnumber_of_bond_for_current_owner(0);
                                  }
                                } else {
                                  setnumber_of_bond_for_current_owner(
                                    e.target.value
                                  );
                                }
                              }}
                              // onBlur={handleBlur}
                              onBlur={() => {
                                setAvailableBond(
                                  parseInt(getavailableBond) -
                                    parseInt(number_of_bond_for_current_owner)
                                );

                                setnumber_of_bonds_created_for_share_name(
                                  (prevData) => [
                                    ...prevData,
                                    location.state.username,
                                  ]
                                );
                                setnumber_of_bonds_created_for_share_id(
                                  (prevData) => [
                                    ...prevData,
                                    location.state.user_id,
                                  ]
                                );
                                setnumber_of_bonds_created_for_share(
                                  (prevData) => [
                                    ...prevData,
                                    number_of_bond_for_current_owner,
                                  ]
                                );
                              }}
                            /> */}
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        width: "100%",
                                      }}
                                    >
                                      <input
                                        type="number"
                                        name="product_name"
                                        className="form_input_box"
                                        style={{
                                          marginBottom: "0px",
                                          marginRight: "10px",
                                        }}
                                        // placeholder="Enter Creator Name"
                                        value={number_of_bond_for_current_owner}
                                        onChange={(e) => {
                                          const inputValue =
                                            e.target.value.replace(
                                              /[^0-9]/g,
                                              ""
                                            );
                                          setnumber_of_bond_for_current_owner(
                                            inputValue
                                          );
                                          if (selectedGlob == 1) {
                                            const inputValue =
                                              e.target.value.replace(
                                                /[^0-9]/g,
                                                ""
                                              );
                                            // const isValid = /^\d+$/.test(inputValue);
                                            // if (!isValid) {
                                            //   setErrorMessage("Value is invalid");
                                            //   setnumber_of_bond_for_current_owner(0);
                                            //   setShowModal(true);
                                            // } else
                                            if (inputValue > 90) {
                                              setErrorMessage(
                                                "You can't share more then 90 Aucemo."
                                              );
                                              setnumber_of_bond_for_current_owner(
                                                ""
                                              );
                                              setShowModal(true);
                                            }
                                          } else if (selectedGlob == 0) {
                                            const inputValue =
                                              e.target.value.replace(
                                                /[^0-9]/g,
                                                ""
                                              );
                                            // const isValid = /^\d+$/.test(inputValue);
                                            // if (!isValid) {
                                            //   setErrorMessage("Value is invalid");
                                            //   setnumber_of_bond_for_current_owner(0);
                                            //   setShowModal(true);
                                            // } else
                                            if (inputValue > 80) {
                                              setErrorMessage(
                                                "You can't share more then 80 Aucemo."
                                              );
                                              setShowModal(true);
                                              setnumber_of_bond_for_current_owner(
                                                ""
                                              );
                                            }
                                          }
                                        }}
                                        // onBlur={handleBlur}
                                        // onBlur={() => {
                                        //   setAvailableBond(
                                        //     parseInt(getavailableBond) -
                                        //       parseInt(number_of_bond_for_current_owner)
                                        //   );

                                        //   setnumber_of_bonds_created_for_share_name(
                                        //     (prevData) => [
                                        //       ...prevData,
                                        //       location.state.username,
                                        //     ]
                                        //   );
                                        //   setnumber_of_bonds_created_for_share_id(
                                        //     (prevData) => [
                                        //       ...prevData,
                                        //       location.state.user_id,
                                        //     ]
                                        //   );
                                        //   setnumber_of_bonds_created_for_share(
                                        //     (prevData) => [
                                        //       ...prevData,
                                        //       number_of_bond_for_current_owner,
                                        //     ]
                                        //   );
                                        // }}
                                      />
                                      <button
                                        style={{
                                          padding: "10px 20px",
                                          backgroundColor: "var(--color-main)",
                                          borderRadius: "28px",
                                          color: "#fff",
                                          fontFamily: "var(--font-600)",
                                          fontSize: "12px",
                                        }}
                                        onClick={() => {
                                          if (
                                            number_of_bond_for_current_owner ==
                                            ""
                                          ) {
                                            alert(
                                              "Please enter number of Aucemo"
                                            );
                                          } else if (
                                            number_of_bond_for_current_owner ==
                                            0
                                          ) {
                                            alert(
                                              "You can't make a stock of zero Aucemo"
                                            );
                                          } else if (
                                            number_of_bond_for_current_owner >
                                            getavailableBond
                                          ) {
                                            alert(
                                              "Enter Aucemo value below " +
                                                getavailableBond +
                                                "."
                                            );
                                          } else {
                                            // setnumber_of_bonds_created_for_share([
                                            //   ...getnumber_of_bonds_created_for_share,
                                            //   number_of_bond_for_current_owner,
                                            // ]);
                                            // setnumber_of_bonds_created_for_share_name([
                                            //   ...getnumber_of_bonds_created_for_share_name,
                                            //   location.state.username,
                                            // ]);
                                            // setnumber_of_bonds_created_for_share_id([
                                            //   ...getnumber_of_bonds_created_for_share_id,
                                            //   location.state.user_id,
                                            //   // getdonationOwnerId,
                                            // ]);
                                            // setAvailableBond(
                                            //   parseInt(getavailableBond) -
                                            //     parseInt(
                                            //       number_of_bond_for_current_owner
                                            //     )
                                            // );
                                            // setnumber_of_bond_for_current_owner("");
                                            // // setDonationOwnerName("");
                                            // // setDonationOwnerId("");
                                            // // setSharenfbModal(false);

                                            setnumber_of_bonds_created_for_share_name(
                                              (prevData) => [
                                                ...prevData,
                                                location.state.username,
                                              ]
                                            );
                                            setnumber_of_bonds_created_for_share_id(
                                              (prevData) => [
                                                ...prevData,
                                                location.state.user_id,
                                              ]
                                            );
                                            setnumber_of_bonds_created_for_share(
                                              (prevData) => [
                                                ...prevData,
                                                number_of_bond_for_current_owner,
                                              ]
                                            );

                                            setAvailableBond(
                                              parseInt(getavailableBond) -
                                                parseInt(
                                                  number_of_bond_for_current_owner
                                                )
                                            );
                                          }
                                        }}
                                      >
                                        Save
                                      </button>
                                    </div>
                                  </>
                                )}
                                {/* </div> */}

                                {/* HOW MANY SHARE Aucemo END */}

                                {/* SHARE Aucemo BTN START */}
                                {selectedAuction == 1 ? null : (
                                  <>
                                    <button
                                      className="share_nfb_btn"
                                      onClick={() => setSharenfbModal(true)}
                                    >
                                      Gift Aucemo with other users
                                    </button>
                                    {/* <p className="red_err_text">
                              Read it properly before choosing the correct
                              option.
                            </p> */}
                                    {/* SHARE Aucemo BTN END */}

                                    {/* share lists start */}
                                    <div
                                      style={{
                                        width: "100%",
                                        margin: "10px auto",
                                      }}
                                    >
                                      {getnumber_of_bonds_created_for_share &&
                                      getnumber_of_bonds_created_for_share.length >
                                        0
                                        ? getnumber_of_bonds_created_for_share.map(
                                            (item, index) => {
                                              return (
                                                <>
                                                  {item == 0 ? (
                                                    handleShareDelete(
                                                      item,
                                                      index
                                                    )
                                                  ) : (
                                                    <div
                                                      style={{
                                                        width: "100%",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent:
                                                          "space-between",
                                                      }}
                                                    >
                                                      <p
                                                        style={{
                                                          color:
                                                            "var(--color-white)",
                                                          fontSize: "12px",
                                                        }}
                                                      >
                                                        {
                                                          getnumber_of_bonds_created_for_share_name[
                                                            index
                                                          ]
                                                        }{" "}
                                                        - {item}
                                                      </p>
                                                      <button
                                                        onClick={() => {
                                                          handleShareDelete(
                                                            item,
                                                            index
                                                          );
                                                        }}
                                                      >
                                                        <img
                                                          src={images.close_red}
                                                          style={{
                                                            width: "24px",
                                                            height: "24px",
                                                            objectFit:
                                                              "contain",
                                                          }}
                                                          alt=""
                                                        />
                                                      </button>
                                                    </div>
                                                  )}
                                                </>
                                              );
                                            }
                                          )
                                        : null}
                                      {/* share lists end */}

                                      {/* club share lists start */}

                                      <div>
                                        {getclub_number_of_bonds_created_for_share &&
                                        getclub_number_of_bonds_created_for_share.length >
                                          0
                                          ? getclub_number_of_bonds_created_for_share.map(
                                              (item, index) => {
                                                return (
                                                  <div
                                                    style={{
                                                      width: "100%",
                                                      display: "flex",
                                                      alignItems: "center",
                                                      justifyContent:
                                                        "space-between",
                                                    }}
                                                  >
                                                    <p
                                                      style={{
                                                        color:
                                                          "var(--color-white)",
                                                        fontSize: "12px",
                                                      }}
                                                    >
                                                      {
                                                        getclub_number_of_bonds_created_for_share_name[
                                                          index
                                                        ]
                                                      }{" "}
                                                      - {item}
                                                    </p>
                                                    <button
                                                      onClick={() => {
                                                        handleClubShareDelete(
                                                          item,
                                                          index
                                                        );
                                                      }}
                                                    >
                                                      <img
                                                        src={images.close_red}
                                                        style={{
                                                          width: "24px",
                                                          height: "24px",
                                                          objectFit: "contain",
                                                        }}
                                                        alt=""
                                                      />
                                                    </button>
                                                  </div>
                                                );
                                              }
                                            )
                                          : null}
                                      </div>

                                      {/* club share lists end */}
                                    </div>

                                    {/* buttons start */}
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        pt: 2,
                                      }}
                                    >
                                      <button
                                        style={{
                                          color: "var(--color-main)",
                                          border: "1px solid var(--color-main)",
                                          padding: "10px 14px",
                                          fontFamily: "var(--font-600)",
                                          fontSize: "12px",
                                          borderRadius: "8px",
                                          lineHeight: "12px",
                                        }}
                                        color="inherit"
                                        // disabled={activeStep === 0}
                                        onClick={handleBack}
                                        sx={{ mr: 1 }}
                                      >
                                        Back
                                      </button>
                                      <Box sx={{ flex: "1 1 auto" }} />
                                      <button
                                        onClick={handleNext}
                                        style={{
                                          color: "var(--color-main)",
                                          border: "1px solid var(--color-main)",
                                          padding: "10px 14px",
                                          fontFamily: "var(--font-600)",
                                          fontSize: "12px",
                                          borderRadius: "8px",
                                          lineHeight: "12px",
                                        }}
                                      >
                                        NEXT
                                      </button>
                                      {activeStep !== steps.length &&
                                        (completed[activeStep] ? (
                                          <p
                                            // variant="caption"
                                            style={{
                                              display: "inline-block",
                                              color: "#fff",
                                              fontSize: "12px",
                                              fontFamily: "var(--font-400)",
                                              padding: "6px 12px",
                                            }}
                                          >
                                            Step {activeStep + 1} already
                                            completed
                                          </p>
                                        ) : (
                                          <button
                                            onClick={() => {
                                              ValidateStep6();
                                            }}
                                            style={{
                                              color: "var(--color-main)",
                                              // border: "1px solid var(--color-main)",
                                              padding: "10px 14px",
                                              fontFamily: "var(--font-600)",
                                              fontSize: "12px",
                                              borderRadius: "8px",
                                              backgroundColor: "#fff",
                                              lineHeight: "12px",
                                              marginLeft: "10px",
                                            }}
                                          >
                                            SUBMIT
                                          </button>
                                        ))}
                                    </Box>
                                    {/* buttons end */}
                                  </>
                                )}
                                {/* SHARE Aucemo BTN END */}
                              </div>
                            </>
                          )}
                        </React.Fragment>
                      )}
                    </div>
                  </>

                  <div className="product_form_wrapp">
                    {/* ARE YOU CREATOR START */}
                    {/* <label
                      htmlFor="product_name"
                      className="form_input_label mt-2"
                    >
                      Are you the creator ?
                    </label>
                    <div className="cbond_form_radio_wrapp">
                      <div id="custom_radio">
                        <label class="custom_radio">
                          <input
                            type="radio"
                            name="checkbox"
                            value={1}
                            checked={selectedCreator == 1}
                            onChange={handleOptionChange}
                          />
                          <span class="checkmark"></span>
                          Yes
                        </label>
                        <label class="custom_radio">
                          <input
                            type="radio"
                            name="checkbox"
                            value={0}
                            checked={selectedCreator == 0}
                            onChange={handleOptionChange}
                          />
                          <span class="checkmark"></span>
                          No
                        </label>
                        <label class="custom_radio">
                          <input
                            type="radio"
                            name="checkbox"
                            value={2}
                            checked={selectedCreator == 2}
                            onChange={handleOptionChange}
                          />
                          <span class="checkmark"></span>
                          Creator not on platform
                        </label>
                      </div>
                    </div> */}
                    {/* ARE YOU CREATOR END */}

                    {/* SELECT CATEGORY START */}
                    {selectedCreator == "1" && (
                      <>
                        {/*old date of creation start */}
                        {/* <div
                          className="glob_row_wrapp"
                          style={{ marginTop: "18px" }}
                        >
                          <label
                            htmlFor="glob_exclusive"
                            className="form_input_label"
                          >
                            Date of creation
                          </label>

                          <DatePicker
                            selected={selectedDate}
                            onChange={handleDateChange}
                            dateFormat="MM/dd/yyyy"
                            maxDate={currentDate}
                            isClearable
                            placeholderText="Select a date"
                          />
                        </div> */}
                        {/*old date of creation end */}

                        {/* donate qrhunt start */}

                        {/* {selectedAuction == 1 ? null : (
                          <>
                            <label
                              htmlFor="product_hastags"
                              className="form_input_label"
                            >
                              Donate for qr hunt{" "}
                              <span
                                className="details_home_icon"
                                onClick={() => {
                                  setShowModal1(true);
                                  setErrorMessage1("");
                                }}
                              >
                                {" "}
                                {String.fromCharCode(9432)}
                              </span>
                            </label>

                            <div
                              // key={index}
                              className="cp_medialink_signle_wrapp"
                            >
                              <input
                                style={{
                                  width: "100%",
                                  marginBottom: "10px",
                                }}
                                className="form_input_box"
                                placeholder="Donate"
                                placeholderTextColor="gray"
                                value={getdonetQrhunt}
                                onChange={(e) =>
                                  handleQrDonateInputChange(e.target.value)
                                }
                              />
                            </div>
                          </>
                        )} */}

                        {/* donate qrhunt end */}

                        {/* SUBMIT BUTTON START */}

                        {/* SUBMIT BUTTON END */}
                      </>
                    )}
                    {/* SELECT CATEGORY END */}

                    {/* <button
                      onClick={() => {
                        CreateProduct();
                      }}
                      className="product_submit_btn"
                    >
                      Save and Protect
                    </button> */}
                  </div>
                </div>
              </>
            ) : (
              <>
                {/* creator model start */}
                <div
                  className="creator_modal_main_warp"
                  style={{ zIndex: 112 }}
                >
                  <div className="creator_modal_base_warp">
                    <div className="cp_navbar_wrapp">
                      <img
                        onClick={() => {
                          setCreatormodal(false);
                          // setSharenfbModal(true);
                        }}
                        src={images.back}
                        className="nav_back_btn"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="search mt-2">
                    <input
                      type="text"
                      placeholder="Search"
                      // value={getsearchData}
                      onChange={(e) => {
                        Search_user(e.target.value);
                      }}
                    />
                    <img className="search-img" src={images.search} alt="" />
                  </div>
                  {data_filter_data.map((item, index) => (
                    <div
                      onClick={() => {
                        // selecteduser(item);
                        setCreatormodal(false);
                        setGetcreatorname(item.label);
                        setGetcreatorid(item.id);
                      }}
                      key={index}
                      className="item-container"
                    >
                      <img src={item.profile} alt={item.label} />
                      <div>{item.label}</div>
                    </div>
                  ))}
                </div>
                {/* creator modal end */}
              </>
            )}

            {/* share owner modal start */}
            {getshareOwnerModal && (
              <div
                className="creator_modal_main_warp"
                style={{ zIndex: 112, background: "#000", position: "fixed" }}
              >
                <div className="creator_modal_base_warp">
                  <div className="cp_navbar_wrapp">
                    <img
                      onClick={() => {
                        setShareOwnerModal(false);
                        setSharenfbModal(true);
                      }}
                      src={images.back}
                      className="nav_back_btn"
                      alt=""
                    />
                  </div>
                </div>
                <div className="search mt-2">
                  <input
                    type="text"
                    placeholder="Search"
                    // value={getsearchData}
                    onChange={(e) => {
                      Search_user(e.target.value);
                    }}
                  />
                  <img className="search-img" src={images.search} alt="" />
                </div>
                <div
                  style={{ width: "100%", height: "80vh", overflow: "hidden" }}
                >
                  {data_filter_data.map((item, index) => (
                    <div
                      onClick={() => {
                        // selecteduser(item);
                        setShareOwnerModal(false);
                        setSharenfbModal(true);
                        setDonationOwnerName(item.label);
                        setDonationOwnerId(item.id);
                      }}
                      key={index}
                      className="item-container"
                    >
                      <img src={item.profile} alt={item.label} />
                      <div>{item.label}</div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {/* share owner modal end */}

            {/* share club modal start */}
            {getshareClubModal && (
              <div
                className="creator_modal_main_warp"
                style={{ zIndex: 112, background: "#000", position: "fixed" }}
              >
                <div className="creator_modal_base_warp">
                  <div className="cp_navbar_wrapp">
                    <img
                      onClick={() => {
                        setShareClubModal(false);
                        setSharenfbModal(true);
                      }}
                      src={images.back}
                      className="nav_back_btn"
                      alt=""
                    />
                  </div>
                </div>
                <div className="search mt-2">
                  <input
                    type="text"
                    placeholder="Search"
                    // value={getsearchData}
                    onChange={(e) => {
                      Search_clubs(e.target.value);
                    }}
                  />
                  <img className="search-img" src={images.search} alt="" />
                </div>
                <div
                  style={{ width: "100%", height: "80vh", overflow: "auto" }}
                >
                  {getallclubs.map((item, index) => (
                    // console.log('item', item)
                    <div
                      onClick={() => {
                        // selecteduser(item);
                        setShareClubModal(false);
                        setSharenfbModal(true);
                        setDonationClubName(item.label);
                        setDonationClubId(item.club_id);
                        // console.log("item", item);
                      }}
                      key={index}
                      className="item-container"
                    >
                      <img src={item.profile} alt={item.label} />
                      <div>{item.label}</div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {/* share club modal end */}

            {showModal && (
              // <CusModal
              //   msg={errorMessage}
              //   setShowModal={setShowModal}
              //   showModal={showModal}
              // />

              // <NewModal
              //   title=""
              //   msg={errorMessage}
              //   setShowModal={openmodal}
              //   closemodal={closemodal}
              // />

              <V2Modal
                canclebtn={false}
                title=""
                msg={errorMessage}
                setShowModal={setShowModal}
                okfunction={closemodal}
              />
            )}

            {/* old user share modal start */}

            {/* {getsharenfbModal && (
              <button className="cusModal_main_wrapp" style={{ zIndex: 100 }}>
                <div className="cusmodal_inner_wrapp">
                  <img src={images.logo} alt="" className="cm_logo" />
                  <p className="available_nfb_modal_heading">
                    How many Aucemo you want to share ? <br /> Available Aucemo{" "}
                    {getavailableBond}
                  </p>
                  <div className="cm_des_wrapp">
                    <input
                      onClick={() => {
                        setShareOwnerModal(true);

                        setSharenfbModal(false);
                      }}
                      value={getdonationOwnerName}
                      type="text"
                      name="product_name"
                      className="available_nfb_modal_input"
                      placeholder="Search Username"
                    />
                    <input
                      className="available_nfb_modal_input"
                      placeholder="Enter number of Aucemo"
                      value={getnoOfBond}
                      onChange={(e) => setNoOfBond(e.target.value)}
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "10%",
                      }}
                    >
                      <button
                        className="cm_btn"
                        onClick={() => {
                          if (getdonationOwnerName == "") {
                            alert("Search username and select");
                          } else if (getnoOfBond == null) {
                            alert("Please enter number of Aucemo");
                          } else if (getnoOfBond == 0) {
                            alert("You can't make a stock of zero Aucemo");
                          } else if (getnoOfBond > getavailableBond) {
                            alert(
                              "Enter Aucemo value below " + getavailableBond + "."
                            );
                          } else if (ddd < getnoOfBond) {
                            setShowModal(true);
                            setErrorMessage(
                              "Enter Aucemo value below " + ddd + "."
                            );
                          } else {
                            setnumber_of_bonds_created_for_share([
                              ...getnumber_of_bonds_created_for_share,
                              getnoOfBond,
                            ]);
                            setnumber_of_bonds_created_for_share_name([
                              ...getnumber_of_bonds_created_for_share_name,
                              getdonationOwnerName,
                            ]);
                            setnumber_of_bonds_created_for_share_id([
                              ...getnumber_of_bonds_created_for_share_id,
                              getdonationOwnerId,
                            ]);
                            setAvailableBond(
                              parseInt(getavailableBond) - parseInt(getnoOfBond)
                            );
                            setNoOfBond(0);
                            setDonationOwnerName("");
                            setDonationOwnerId("");
                            setSharenfbModal(false);
                          }
                        }}
                      >
                        Save
                      </button>

                      <button
                        className="cm_btn"
                        onClick={() => {
                          setNoOfBond(0);
                          setDonationOwnerName("");
                          setDonationOwnerId("");
                          setSharenfbModal(false);
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </button>
            )} */}

            {/* old user share modal end */}

            {/* new user and club share modal start */}

            {getsharenfbModal && (
              <button
                // onClick={() => setSharenfbModal(false)}
                className="cusModal_main_wrapp"
                style={{ zIndex: 100 }}
              >
                <div
                  className="cusmodal_inner_wrapp"
                  // style={{ position: "fixed" }}
                >
                  <img src={images.logo} alt="" className="cm_logo" />
                  <p className="available_nfb_modal_heading">
                    How many Aucemo you want to share ? <br /> Available
                    Aucemo {getavailableBond}
                  </p>
                  <div id="custom_radio" style={{ width: "90%" }}>
                    <label class="custom_radio">
                      <input
                        type="radio"
                        name="share_checkbox"
                        value={0}
                        checked={getisUserAndClub == 0}
                        onChange={handleShareUserAndClubChange}
                      />
                      <span class="checkmark"></span>
                      User
                    </label>
                    <label class="custom_radio">
                      <input
                        type="radio"
                        name="share_checkbox"
                        value={1}
                        checked={getisUserAndClub == 1}
                        onChange={handleShareUserAndClubChange}
                      />
                      <span class="checkmark"></span>
                      Club
                    </label>
                  </div>
                  <>
                    {getisUserAndClub == 0 ? (
                      <div className="cm_des_wrapp">
                        {/* <input
                className="available_nfb_modal_input"
                placeholder="Search Username"
              /> */}
                        <input
                          onClick={() => {
                            setShareOwnerModal(true);
                            // setDonationSelect(true);
                            setSharenfbModal(false);
                          }}
                          value={getdonationOwnerName}
                          type="text"
                          name="product_name"
                          className="available_nfb_modal_input"
                          placeholder="Search Username"
                          // onChange={(e) => {
                          //   setGetcreatorname(e.target.value);
                          // }}
                        />
                        <input
                          className="available_nfb_modal_input"
                          placeholder="Enter number of Aucemo"
                          value={getnoOfBond}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            const isValid =
                              /^\d+$/.test(inputValue) &&
                              parseInt(inputValue, 10) >= 0;
                            if (inputValue !== 0 && !isValid) {
                              setNoOfBond("");
                              setErrorMessage1(
                                "Please enter a valid number of Aucemo"
                              );
                              setShowModal1(true);
                            } else {
                              setNoOfBond(parseInt(inputValue, 10));
                              console.log("isValid", inputValue);
                            }
                          }}
                        />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "10%",
                          }}
                        >
                          <button
                            className="cm_btn"
                            onClick={() => {
                              if (getdonationOwnerName == "") {
                                alert("Search username and select");
                              } else if (getnoOfBond == null) {
                                alert("Please enter number of Aucemo");
                              } else if (getnoOfBond == 0) {
                                alert("You can't make a stock of zero Aucemo");
                              } else if (getnoOfBond > getavailableBond) {
                                alert(
                                  "Enter Aucemo value below " +
                                    getavailableBond +
                                    "."
                                );
                              } else if (ddd < getnoOfBond) {
                                setShowModal(true);
                                setErrorMessage(
                                  "Enter Aucemo value below " + ddd + "."
                                );
                              } else {
                                setnumber_of_bonds_created_for_share([
                                  ...getnumber_of_bonds_created_for_share,
                                  getnoOfBond,
                                ]);
                                setnumber_of_bonds_created_for_share_name([
                                  ...getnumber_of_bonds_created_for_share_name,
                                  getdonationOwnerName,
                                ]);
                                setnumber_of_bonds_created_for_share_id([
                                  ...getnumber_of_bonds_created_for_share_id,
                                  getdonationOwnerId,
                                ]);
                                setAvailableBond(
                                  parseInt(getavailableBond) -
                                    parseInt(getnoOfBond)
                                );
                                setNoOfBond(null);
                                setDonationOwnerName("");
                                setDonationOwnerId("");
                                setSharenfbModal(false);
                              }
                            }}
                          >
                            Save
                          </button>

                          <button
                            className="cm_btn"
                            onClick={() => {
                              setNoOfBond(null);
                              setNoOfBondClub(null);
                              setDonationOwnerName("");
                              setDonationClubName("");
                              setDonationOwnerId("");
                              setDonationClubId("");
                              setSharenfbModal(false);
                            }}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className="cm_des_wrapp">
                        {/* <input
                className="available_nfb_modal_input"
                placeholder="Search Username"
              /> */}
                        <input
                          onClick={() => {
                            setShareClubModal(true);
                            // setDonationSelect(true);
                            setSharenfbModal(false);
                          }}
                          value={getdonationClubName}
                          type="text"
                          name="product_name"
                          className="available_nfb_modal_input"
                          placeholder="Search Username"
                          // onChange={(e) => {
                          //   setGetcreatorname(e.target.value);
                          // }}
                        />
                        <input
                          className="available_nfb_modal_input"
                          placeholder="Enter number of Aucemo"
                          value={getnoOfBondClub}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            const isValid =
                              /^\d+$/.test(inputValue) &&
                              parseInt(inputValue, 10) >= 0;

                            if (!isValid) {
                              setNoOfBondClub("");
                              setErrorMessage1(
                                "Please enter a valid number of Aucemo"
                              );
                              setShowModal1(true);
                            } else {
                              setNoOfBondClub(parseInt(inputValue, 10));
                              console.log("isValid", inputValue);
                            }
                          }}
                        />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "10%",
                          }}
                        >
                          <button
                            className="cm_btn"
                            onClick={() => {
                              if (getdonationClubName == "") {
                                alert("Search club and select");
                              } else if (getnoOfBondClub == null) {
                                alert("Please enter number of Aucemo");
                              } else if (getnoOfBondClub == 0) {
                                alert("You can't make a stock of zero Aucemo");
                              } else if (getnoOfBondClub > getavailableBond) {
                                alert(
                                  "Enter Aucemo value below " +
                                    getavailableBond +
                                    "."
                                );
                              } else if (ddd < getnoOfBondClub) {
                                setShowModal(true);
                                setErrorMessage(
                                  "Enter Aucemo value below " + ddd + "."
                                );
                              } else {
                                setclub_number_of_bonds_created_for_share([
                                  ...getclub_number_of_bonds_created_for_share,
                                  getnoOfBondClub,
                                ]);
                                setclub_number_of_bonds_created_for_share_name([
                                  ...getclub_number_of_bonds_created_for_share_name,
                                  getdonationClubName,
                                ]);
                                setclub_number_of_bonds_created_for_share_id([
                                  ...getclub_number_of_bonds_created_for_share_id,
                                  getdonationClubId,
                                ]);
                                setAvailableBond(
                                  parseInt(getavailableBond) -
                                    parseInt(getnoOfBondClub)
                                );
                                setNoOfBondClub(null);
                                setDonationClubName("");
                                setDonationClubId("");
                                setSharenfbModal(false);
                              }
                            }}
                          >
                            Save
                          </button>

                          <button
                            className="cm_btn"
                            onClick={() => {
                              setNoOfBond(null);
                              setNoOfBondClub(null);
                              setDonationOwnerName("");
                              setDonationClubName("");
                              setDonationOwnerId("");
                              setDonationClubId("");
                              setSharenfbModal(false);
                            }}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    )}
                  </>
                </div>
              </button>
            )}

            {/* new user and club share modal end */}

            {/* creator massage start */}

            {isModalOpen && (
              <div className="approvalcreate_modal">
                <div className="approvalcreate_content">
                  <center>
                    <img
                      src={images.logo}
                      className="approvalcreate_logo"
                      alt=""
                    />
                    <p
                      style={{
                        fontSize: "18px",
                        fontFamily: "var(--font-600)",
                      }}
                      className="approvalcreate_h2"
                    >
                      Reject Reason
                    </p>
                  </center>
                  <div className="nfb_approval_new">
                    <div className="nfb_approval_new_2">
                      <input
                        type="radio"
                        className="input_new_approval"
                        onChange={handleReasonOptionChange}
                        value={1}
                        checked={selectedReasonOption == 1}
                      />
                      <span className="nfb_approval_new_1">
                        I am not the creator of this asset
                      </span>
                    </div>
                    <div className="nfb_approval_new_2">
                      <input
                        type="radio"
                        className="input_new_approval"
                        onChange={handleReasonOptionChange}
                        value={2}
                        checked={selectedReasonOption == 2}
                      />
                      <span className="nfb_approval_new_1">
                        I am not interested in launching Aucemo at the moment
                      </span>
                    </div>
                    <div className="nfb_approval_new_2">
                      <input
                        type="radio"
                        className="input_new_approval"
                        onChange={handleReasonOptionChange}
                        value={3}
                        checked={selectedReasonOption == 3}
                      />
                      <span className="nfb_approval_new_1">Other Reason</span>
                    </div>
                  </div>
                  <br />
                  <input
                    type="text"
                    className="approval_input_new"
                    placeholder="Enter Message"
                    onChange={(e) => setReasonMsg(e.target.value)}
                  />
                  <hr />
                  <br />
                  {/* <button onClick={() => setIsModalOpen(false)}>Close Modal</button> */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <button
                      className="approvalcreate_btn"
                      style={{ width: "45%" }}
                      onClick={Reject}
                    >
                      Submit
                    </button>
                    <button
                      className="approvalcreate_btn"
                      style={{ width: "45%" }}
                      onClick={() => {
                        setIsModalOpen(false);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            )}
            {/* creator massage end */}
          </>
        )}
        {/* attach video modal start */}
        {getattechVideoModal && (
          <div
            style={{
              position: "fixed",
              width: "100vw",
              height: "100vh",
              top: "0px",
              left: "0px",
              zIndex: 112,
            }}
          >
            <div className="page_main_wrapp">
              <div className="page_base_wrapp_2">
                <div className="page_navbar_wrapp">
                  <div className="nav_back_wrapp">
                    <img
                      src={images.back}
                      className="nav_back_btn"
                      alt=""
                      onClick={() => setAttechVideoModal(false)}
                    />
                  </div>
                  <p className="nav_screen_name">Creator Videos</p>
                </div>

                <div className="av_videos_wrapp">
                  {getvideoArray && getvideoArray.length > 0 ? (
                    getvideoArray.map((item, index) => {
                      console.log("112211", item);
                      return (
                        <div
                          //  className="av_single_video"
                          style={{
                            width: "48%",
                            padding: "10px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                            gap: 6,
                          }}
                        >
                          {/* <input
                              onClick={() => {
                                setVideoId(item.user_video_id);
                                setUserVideo(item.user_videos);
                                setAttechVideoModal(false);
                                console.log("item", item);
                              }}
                              type="checkbox"
                              className="av_of_checkbox"
                              checked={getvideoId === item.user_video_id}
                            />
                            <button
                              onClick={() => {
                                setAttechVideoModal(false);
                                setPreviewVideoModal(true);
                                console.log("getuserVid", getuserVid);
                              }}
                              className="av_prev_btn"
                            >
                              Preview
                            </button> */}

                          <div
                            style={{
                              width: "100%",
                              height: 80,
                              border: "2px dashed var(--color-gray)",
                              borderColor:
                                getvideoId === item.user_video_id
                                  ? "var(--color-main)"
                                  : "var(--color-gray)",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: 12,
                            }}
                          >
                            <button
                              onClick={() => {
                                setAttechVideoModal(false);
                                setPreviewVideoModal(true);
                                console.log("getuserVid", getuserVid);
                              }}
                              className="av_prev_btn"
                            >
                              Preview
                            </button>
                          </div>
                          <button
                            style={{
                              color: "#fff",
                              fontSize: "12px",
                              fontFamily: "var(--font-600)",
                              border: "1px solid white",
                              padding: "4px 12px",
                              borderRadius: "12px",
                              borderColor:
                                getvideoId === item.user_video_id
                                  ? "var(--color-main)"
                                  : "white",
                              background:
                                getvideoId === item.user_video_id
                                  ? "var(--color-main)"
                                  : "black",
                            }}
                            onClick={() => {
                              setVideoId(item.user_video_id);
                              setUserVideo(item.user_videos);
                              setAttechVideoModal(false);
                              console.log("item", item);
                            }}
                          >
                            Attach
                          </button>
                        </div>
                      );
                    })
                  ) : (
                    <Link
                      className="novideo_btn"
                      to={"/UserProfile"}
                      state={{
                        user_id: userId,
                      }}
                    >
                      Upload Videos From Profile
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {/* attach video modal end */}
        {/* preview video modal start */}
        {getpreviewVideoModal && (
          <div
            style={{
              position: "fixed",
              width: "100vw",
              height: "100vh",
              top: "0px",
              left: "0px",
              zIndex: 112,
            }}
          >
            <div className="page_main_wrapp">
              <div className="page_base_wrapp_2">
                <div className="page_navbar_wrapp">
                  <div className="nav_back_wrapp">
                    <img
                      src={images.back}
                      className="nav_back_btn"
                      alt=""
                      onClick={() => setPreviewVideoModal(false)}
                    />
                  </div>
                  <p className="nav_screen_name">Video</p>
                </div>
                <video controls style={{ width: "100%", height: "90%" }}>
                  <source src={getuserVid} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>
        )}
        {/* preview video modal end */}
        {/* preview video 2 modal start */}
        {getpreviewVideoModal2 && (
          <div
            style={{
              position: "fixed",
              width: "100vw",
              height: "100vh",
              top: "0px",
              left: "0px",
              zIndex: 112,
            }}
          >
            <div className="page_main_wrapp">
              <div className="page_base_wrapp_2">
                <div className="page_navbar_wrapp">
                  <div className="nav_back_wrapp">
                    <img
                      src={images.back}
                      className="nav_back_btn"
                      alt=""
                      onClick={() => setPreviewVideoModal2(false)}
                    />
                  </div>
                  <p className="nav_screen_name">Preview</p>
                </div>
                <video controls style={{ width: "100%", height: "90%" }}>
                  <source src={getuserVid2} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>
        )}
        {/* preview video 2 modal end */}
        {/* new record video modal start */}
        {isModalVisiblevideo && (
          <div
            className="modal_login"
            style={{
              zIndex: 111,
              width: "100%",
              height: "95vh",
              bottom: "-5px",
            }}
          >
            <div
              className="select_nw_modal"
              style={{ height: "100%", background: "#333" }}
            >
              <div className="modal_div1">
                <img
                  className="forgot_main"
                  src="/static/media/logo.c9b7a18fb586e344723a.png"
                  alt=""
                />
              </div>
              {getrecordedvideo ? (
                <div>
                  {recordedChunks.length > 0 && (
                    <div>
                      <p>Recorded Video:</p>
                      <video controls width="400">
                        <source
                          src={URL.createObjectURL(recordedChunks[0])}
                          type="video/webm"
                        />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  )}
                </div>
              ) : (
                <>
                  <div
                    style={{
                      marginBottom: "14px",
                      width: "100%",
                      borderRadius: "18px",
                      overflow: "hidden",
                      height: "60vh",
                    }}
                  >
                    <Webcam
                      // audio={true}
                      // audio={{
                      //   echoCancellation: true,
                      //   noiseSuppression: true,
                      //   autoGainControl: true,
                      // }}
                      // width={"500"}
                      // height={"400"}
                      width={"100%"}
                      height={"100%"}
                      videoConstraints={{ facingMode: "user" }}
                      ref={videoRef}
                      mirrored={true}
                      // videoConstraints={videoConstraints}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {recording ? (
                      <>
                        <div
                          style={{
                            width: "90%",
                            height: "60px",
                            borderRadius: "28px",
                            backgroundColor: "#69696971",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            position: "relative",
                          }}
                        >
                          <p
                            style={{
                              position: "absolute",
                              left: "10px",
                              top: "20px",
                              fontFamily: "var(--font-600)",
                              color: "white",
                              fontSize: "14px",
                            }}
                          >
                            {recordingTime} seconds
                          </p>
                          {/* <button
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                          color: "white",
                          alignSelf: "center",
                        }}
                        onClick={stopRecording}
                      >
                        Stop Recording
                      </button> */}
                          <img
                            onClick={stopRecording}
                            src={images.stop_circle}
                            style={{
                              height: "50px",
                              width: "50px",
                              objectFit: "contain",
                            }}
                            alt=""
                          />
                        </div>
                      </>
                    ) : (
                      <div
                        style={{
                          width: "90%",
                          height: "60px",
                          borderRadius: "28px",
                          backgroundColor: "#69696971",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {/* <button
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                          color: "white",
                          alignSelf: "center",
                        }}
                        onClick={startRecording}
                      >
                        Start Recording
                      </button> */}
                        <img
                          onClick={startRecording}
                          src={images.start_circle}
                          style={{
                            height: "50px",
                            width: "50px",
                            objectFit: "contain",
                          }}
                          alt=""
                        />
                      </div>
                    )}
                  </div>
                </>
              )}

              <button
                onClick={() => {
                  showimage();
                }}
              ></button>
              {/* <hr className="hr_forgot" /> */}
              <br />
              <div className="modal_div">
                <button
                  className="close_forgot"
                  onClick={() => {
                    showimage();
                    setBodyScrollEnabled(true);
                  }}
                >
                  Cancel
                </button>
                {/* <button
                style={{ marginLeft: 40 }}
                className="close_forgot"
                onClick={() => {
                  console.log("recordedChunks", recordedChunks.length);
                  // handleVideoChange()
                  setIsModalVisiblevideo(false);
                  // setRecordedChunks([]);
                }}
              >
                Submit
              </button> */}
              </div>
            </div>
          </div>
        )}{" "}
        {/* new record video modal end */}
      </>

      {/* alert modal start */}

      {showModal1 && (
        // <AlertModal
        //   msg={errorMessage1}
        //   setShowModal={setShowModal1}
        //   title="Information"
        // />

        // <NewModal
        //   title="Information"
        //   msg={errorMessage1}
        //   setShowModal={openmodal1}
        //   closemodal={closemodal1}
        // />

        <V2Modal
          canclebtn={false}
          title=""
          msg={errorMessage1}
          setShowModal={setShowModal1}
          okfunction={closemodal1}
        />
      )}

      {/* alert modal end */}

      {/* global change modal start */}

      {getglobModalOpen && (
        <>
          <div>
            <div className="modal_login" style={{ zIndex: 111 }}>
              <div
                className="modal-content_login"
                // style={{ maxHeight: "90vh" }}
              >
                <div className="modal_div1">
                  <img className="forgot_main" src={images.logo} alt="" />
                </div>
                <div className="alert_modal_div">
                  {/* <b>{title ? title : ""}</b> */}
                  <br />
                  <p
                    className="alert-modal-message"
                    style={{
                      height: "80%",
                      // border: "1px solid red",
                      overflowY: "scroll",
                    }}
                  >
                    {getglobModalMsg}
                  </p>
                  <br />
                </div>
                <hr className="hr_forgot" />
                <br />
                <div
                  // className="modal_div"
                  style={{
                    width: "90%",
                    justifyContent: "space-between",
                    display: "flex",
                    margin: "0px auto",
                  }}
                >
                  <button
                    className="close_forgot"
                    onClick={() => {
                      let idString = "";
                      var demo = 0;
                      for (
                        let index = 0;
                        index < getallInterest.length;
                        index++
                      ) {
                        if (getallInterest[index].status == "2") {
                          demo = demo + 1;

                          if (index > 0) {
                            idString += ",";
                          }
                          idString += getallInterest[index].id;
                        }
                      }

                      setBodyScrollEnabled(true);
                      setGlobModalOpen(false);
                      setIsLoading(true);
                      let formData = new FormData();
                      formData.append("product_name", getproductname);
                      // formData.append("owner_name", getownerName);
                      // formData.append("owner_id", getownerId);
                      formData.append(
                        "date_of_creation",
                        moment(selectedDate2).format("YYYY-MM-DD")
                      );
                      formData.append("description", getProductDes);
                      formData.append("product_value", getProductValue);
                      formData.append("is_owner", "4");
                      formData.append("bond_interest_id", idString);
                      formData.append("user_id", location.state.owner_id);
                      formData.append("product_hashtag", gethashtags);
                      formData.append("globle_exclusive", selectedGlob);
                      formData.append("minimum_bid_amount", getminimumBid);
                      formData.append("product_value_checker", selectedAuction);
                      formData.append("auction_days", getauctionDurtion);
                      selectedImagesMin1Img.forEach((image, index) => {
                        formData.append("img_video[]", image);
                      });

                      // getimg_video &&
                      //   getimg_video.length > 0 &&
                      //   getimg_video.map((itm, ind) => {
                      //     formData.append("img_video[]", {
                      //       uri: itm.img_video,
                      //       name: "name",
                      //       type: "image/jpeg",
                      //     });
                      //     // formData.append("img_video[]", itm.img_video, `media_img_${ind}.jpg`);
                      //   });

                      selectedImagesAndVidio.forEach((image, index) => {
                        formData.append("media_upload[]", image);
                      });

                      formData.append("product_id", location.state.product_id);
                      formData.append("product_history", getProductStory);
                      formData.append(
                        "product_highlights",
                        getProductHighlight
                      );
                      formData.append("is_nfb", location.state.is_nfb);

                      formData.append("club_id", location.state.club_id);

                      selectedImages.forEach((image, index) => {
                        formData.append("proof_file", image);
                      });

                      selectedVideos.forEach((image, index) => {
                        formData.append("bond_video", image);
                      });

                      // setnumber_of_bonds_created_for_share_name((prevData) => [
                      //   ...prevData,
                      //   location.state.username,
                      // ]);
                      // setnumber_of_bonds_created_for_share_id((prevData) => [
                      //   ...prevData,
                      //   location.state.user_id,
                      // ]);
                      // setnumber_of_bonds_created_for_share((prevData) => [
                      //   ...prevData,
                      //   number_of_bond_for_current_owner,
                      // ]);

                      let idString_usernmae = "";
                      let idString_number_of_bond = "";
                      let idString_user_id = "";
                      var demo = 0;

                      const staticName = location.state.username;
                      const staticNumberOfBond =
                        number_of_bond_for_current_owner == ""
                          ? 0
                          : number_of_bond_for_current_owner;
                      const staticUserId = location.state.user_id;

                      for (
                        let index = 0;
                        index < getnumber_of_bonds_created_for_share.length;
                        index++
                      ) {
                        demo = demo + 1;

                        if (index > 0) {
                          idString_usernmae += ",";
                          idString_number_of_bond += ",";
                          idString_user_id += ",";
                        }
                        idString_usernmae +=
                          getnumber_of_bonds_created_for_share_name[index];
                        idString_number_of_bond +=
                          getnumber_of_bonds_created_for_share[index];
                        idString_user_id +=
                          getnumber_of_bonds_created_for_share_id[index];
                      }
                      if (number_of_bond_for_current_owner == "") {
                        idString_usernmae += `${staticName}`;
                        idString_number_of_bond += `${staticNumberOfBond}`;
                        idString_user_id += `${staticUserId}`;
                      }

                      // formData.append("bond_share_owner_name", idString_usernmae);
                      // formData.append("bond_share_owner_id", idString_user_id);

                      formData.append(
                        "bond_share_owner_name",
                        idString_usernmae == ""
                          ? location.state.username
                          : idString_usernmae
                      );
                      formData.append(
                        "bond_share_owner_id",
                        idString_user_id == ""
                          ? location.state.user_id
                          : idString_user_id
                      );
                      formData.append(
                        "bond_share_total_assign",
                        idString_number_of_bond == ""
                          ? 0
                          : idString_number_of_bond
                      );
                      // formData.append("total_qr_hunt_bond", getdonetQrhunt);

                      // club share functionality start
                      let club_idString_usernmae = "";
                      let club_idString_number_of_bond = "";
                      let club_idString_user_id = "";
                      var club_demo = 0;
                      for (
                        let index = 0;
                        index <
                        getclub_number_of_bonds_created_for_share.length;
                        index++
                      ) {
                        club_demo = club_demo + 1;

                        if (index > 0) {
                          club_idString_usernmae += ",";
                          club_idString_number_of_bond += ",";
                          club_idString_user_id += ",";
                        }
                        club_idString_usernmae +=
                          getclub_number_of_bonds_created_for_share_name[index];
                        club_idString_number_of_bond +=
                          getclub_number_of_bonds_created_for_share[index];
                        club_idString_user_id +=
                          getclub_number_of_bonds_created_for_share_id[index];
                      }
                      formData.append(
                        "share_club_name",
                        club_idString_usernmae
                      );
                      formData.append("share_club_id", club_idString_user_id);
                      formData.append(
                        "share_total_assign",
                        club_idString_number_of_bond
                      );
                      formData.append("share_user_id", userId);
                      // club share functionality end

                      formData.append("user_video_id", getvideoId);

                      let linktag = "";
                      var demolinktag = 0;
                      for (let index = 0; index < inputValues.length; index++) {
                        if (inputValues[index]) {
                          demolinktag = demolinktag + 1;

                          if (index > 0) {
                            linktag += ",";
                          }
                          linktag += inputValues[index];
                        }
                      }
                      formData.append("social_media_link", linktag);

                      const formDataObject = {};
                      formData.forEach((value, key) => {
                        formDataObject[key] = value;
                      });
                      console.log("FormData: create_bond_v2", formDataObject);
                      // setIsLoading(false);

                      axios
                        .post(create_bond_v2, formData)
                        .then((Response) => {
                          console.log("Response", Response);
                          setIsLoading(false);
                          setGlobModalOpen(false);

                          if (Response.data.status == 1) {
                            navigate("/actionneed2");
                          } else {
                            setErrorMessage(Response.data.message);
                            setShowModal(true);
                          }
                        })
                        .catch((err) => {
                          console.log("err", err);
                          setIsLoading(false);
                          setGlobModalOpen(false);
                        });
                    }}
                  >
                    Okay
                  </button>

                  <button
                    className="close_forgot"
                    onClick={() => {
                      setBodyScrollEnabled(true);
                      setGlobModalOpen(false);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {/* global change modal end */}
    </div>
  );
};

export default Approvalcreate;
