import { useEffect, useState } from "react";
import Slider from "react-slick";
// import "./NewHomePage.css";
import images from "../../constants/images";
import "./MilestoneDashboard.css";
import { FaAngleLeft } from "react-icons/fa6";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import NewLoader from "../../common/newloder/NewLoader";
import {
  add_joyride,
  get_milestone_details,
  get_user_joyride,
} from "../../utils/Constant";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import qs from "qs";
import CustomJoyride from "../../utils/CustomJoyride";
import ReactJoyride from "react-joyride";

const steps = [
  {
    target: ".md_joy_1",
    content: "it's show platform total assets and total assets value",
    disableBeacon: true,

    spotlightPadding: 20,
  },
  {
    target: ".md_joy_2",
    content: "it's show platform total assets and total assets value",

    spotlightPadding: 20,
  },
  {
    target: ".md_joy_3",
    content: "it's show platform total assets and total assets value",

    spotlightPadding: 20,
  },
];

const MilestoneDashboard = () => {
  const userId = localStorage.getItem("user_id");
  const navigate = useNavigate();
  const location = useLocation();
  const [imageIndex, setImageIndex] = useState(0);
  const [images_slider, setimages_slider] = useState([
    // "https://www.ravi.host/blockchain_2/public/assets/user_profile/647499461690537548.png",
    // "https://www.ravi.host/blockchain_2/public/assets/user_profile/647499461690537548.png",
    // "https://www.ravi.host/blockchain_2/public/assets/user_profile/647499461690537548.png",
    // "https://www.ravi.host/blockchain_2/public/assets/user_profile/647499461690537548.png",
  ]);
  const [assetcontent, setassetcontent] = useState([
    { img: images.assetimg1, title: "Asset Name1" },
    { img: images.assetimg2, title: "Asset Name2" },
  ]);

  const settings = {
    centerMode: true,
    centerPadding: images_slider.length > 1 ? "180px" : "0px",
    slidesToShow: 1,
    focusOnSelect: true,
    dots: false,
    arrows: false,
    infinite: true,
    responsive: [
      {
        breakpoint: 2140,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "40px",
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "40px",
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "40px",
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "40px",
          slidesToShow: 1,
        },
      },
    ],
  };

  // useEffect(() => {
  //   if (images_slider.length === 2) {
  //     const newImagesSlider = [...images_slider];
  //     newImagesSlider.unshift(images_slider[1]);
  //     setimages_slider(newImagesSlider);
  //   }
  // }, [images_slider]);

  useEffect(() => {
    Getmilestonedetails();
  }, []);

  /// get api start
  const [getloading, setLoading] = useState(false);
  const [get_image_link, set_image_link] = useState([]);
  const [get_media_link, set_media_link] = useState([]);
  const [getmilestonedata, setmilestonedata] = useState([]);
  const [getmilestoneAssets, setmilestoneAssets] = useState([]);
  const [getmilestone_name, setmilestone_name] = useState("");
  const [getuser_id, setuser_id] = useState("");

  const [run, setRun] = useState(false);

  const Getmilestonedetails = async () => {
    setLoading(true);
    const formdata = new FormData();
    formdata.append("user_milestone_id", location.state.user_milestone_id);
    formdata.append("is_login", location.state.user_id == userId ? 1 : 0);
    await axios
      .post(get_milestone_details, formdata, {})
      .then((res) => {
        setLoading(false);
        console.log("res", res.data.milestone_details);
        const data = res.data.milestone_details;
        if (res.data.status === 1) {
          setmilestonedata(data[0]);
          set_image_link(data[0].m_image_link);
          setimages_slider(data[0].m_media_link);
          setmilestoneAssets(data[0].milestone_bond);
          setStatus(data[0].is_status);
          setmilestone_name(data[0].milestone_name);
          setuser_id(data[0].user_id);
          // setuser_id(data[0].user_id);
        } else {
          toast.error(res.data.message, {
            theme: "colored",
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
      });
  };

  // get api end

  const [status, setStatus] = useState(null);

  const Milestoneonof = (newStatus) => {
    console.log(newStatus);
    setLoading(true);

    const param = {
      user_milestone_id: location.state.user_milestone_id,
      is_status: newStatus,
    };

    console.log(param);

    axios
      .post(
        "https://www.ravi.host/api/user_hide_unhide_milestone",
        qs.stringify(param)
      )
      .then((Response) => {
        console.log(
          "Responseclubsettings----->",
          JSON.stringify(Response.data, null, 2)
        );
        if (Response.data.status == 1) {
          Getmilestonedetails();
        } else {
          Getmilestonedetails();
          toast.error(Response.data.message, {
            theme: "colored",
          });
          // setIsModalVisible(true);
          // setCusmsg(Response.data.message);
        }

        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        // Handle any other errors that occurred during the request
        console.error(error);
      });
  };

  // JOYRIDE function start
  useEffect(() => {
    // const hasSeenTour = localStorage.getItem("hasSeenTour");
    // if (!hasSeenTour) {
    //   setRun(true);
    // }

    const param = { user_id: userId };
    const existingNames = ["milestone_dashbord_joyride"];

    axios
      .post(get_user_joyride, qs.stringify(param))
      .then((response) => {
        const data = response.data;

        if (data && data.status === 1 && Array.isArray(data.joyride)) {
          const hasExistingNames = data.joyride.some((item) => {
            console.log("Item received from API:", item);
            return existingNames.includes(item);
          });

          if (hasExistingNames) {
            setRun(false);
          } else {
            setRun(true);
          }
        } else {
          setRun(true); // Default to true if no data or incorrect format
        }
      })
      .catch((error) => {
        setRun(false); // Optionally set run to false in case of an error
      });
  }, []);

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    const finishedStatuses = ["finished", "skipped"];

    if (finishedStatuses.includes(status)) {
      // localStorage.setItem("hasSeenTour", "true");
      // setRun(false);

      const param = {
        user_id: userId,
        joyride_name: "milestone_dashbord_joyride",
      };

      axios.post(add_joyride, qs.stringify(param)).then((Response) => {
        const data = Response.data;
        if (data.status == 1) {
          setRun(false);
        }
      });
    }
  };
  // JOYRIDE function end

  return (
    <>
      {/* {!getloading && (
        <ReactJoyride
          steps={steps}
          run={run}
          continuous={true}
          showProgress={false}
          callback={handleJoyrideCallback}
          disableScrolling={true}
          disableOverlayClose={true}
          styles={{
            options: {
              zIndex: 10000,
            },
            spotlight: {
              // backgroundColor: "transparent",
            },
          }}
        />
      )} */}
      <div className="main_milestone">
        {getloading ? (
          <NewLoader />
        ) : (
          <>
            <div className="legacy_dashbord_nav_sec">
              <button
                onClick={() => {
                  navigate(-1);
                }}
              >
                <FaAngleLeft color="var(--color-white)" size={20} />
              </button>
              <p>Milestone Details</p>
              <div style={{ width: "10%" }}></div>
            </div>
            {getmilestoneAssets && !getmilestoneAssets.length == 0 ? null : (
              <>
                {location.state.user_id == userId ? (
                  <div
                    style={{
                      width: "95%",
                      paddingLeft: "20px",
                      marginBottom: "0px",
                      marginTop: "10px",
                    }}
                    className="details_ofset_1 md_joy_2"
                  >
                    <p className="set_club_sub_nm">Milestone active/inactive</p>
                    <input
                      className="set_chek"
                      type="checkbox"
                      id="checkbox2"
                      checked={status == 1}
                      onChange={() => {
                        Milestoneonof(status == 1 ? 0 : 1);
                      }}
                    />
                    <label
                      // style={{ border: status == 1 ? "red" : "gray" }}
                      className={status == 1 ? "setlabel" : "setlabel1"}
                      for="checkbox2"
                    ></label>
                  </div>
                ) : null}
              </>
            )}
            <div className="cardd md_joy_1">
              <div className="cardback">
                <img
                  src={
                    get_image_link && get_image_link.length > 0
                      ? get_image_link[0]?.imageLink
                      : null
                  }
                  alt=""
                />
                <h1 className="titlecard">{getmilestone_name}</h1>
                <div className="under"></div>
              </div>
            </div>
            {getmilestonedata?.date_of_creation ? (
              <div
                style={{
                  marginTop: "10px",
                  marginLeft: "30px",
                  display: "flex",
                  gap: "10px",
                }}
              >
                <p
                  style={{
                    fontFamily: "var(--font-400)",
                    fontSize: "16px",
                    color: "var(--color-white)",
                  }}
                >
                  {" "}
                  Date of creation :-
                </p>
                <p
                  style={{
                    color: "var(--color-main)",
                    fontFamily: "var(--font-600)",
                    fontSize: "16px",
                  }}
                >
                  {getmilestonedata?.date_of_creation}
                </p>
              </div>
            ) : null}
            <div className="para1">{getmilestonedata?.description}</div>
            {/* slider start */}
            {/* {images_slider && images_slider.length > 0 ? (
            <>
              <h2 className="slidetitle">Milestone highlights</h2>
              <div className="slider">
                <Slider {...settings} className="mile-slider">
                  {images_slider.map((img, idx) => (
                    <div
                      className={
                        idx === imageIndex
                          ? "milestoneslide activeSlide"
                          : "slide"
                      }
                    >
                      <img
                        style={{ objectFit: "cover" }}
                        src={img.media_link}
                        alt={img.media_link}
                      />
                    </div>
                  ))}
                </Slider>
              </div>
            </>
          ) : null} */}
            {/* slider end */}
            <div className="asset_main">
              <div className="asset_title">
                {getmilestoneAssets && getmilestoneAssets.length > 0 && (
                  <div className="asset_title_left">Assets</div>
                )}
                {/* <div className="asset_title_right">View All</div> */}
              </div>
              <div className="asst_content md_joy_3">
                {getmilestoneAssets && getmilestoneAssets.length > 0 ? (
                  <div
                    className="asst_content_bonds_wrapp"
                    style={{
                      width: userId == location.state.user_id ? "70%" : "100%",
                    }}
                  >
                    {getmilestoneAssets.map((item, index) => {
                      console.log("item", item);
                      return (
                        <Link
                          className="content1"
                          key={item.bond_id}
                          to={"/MilestoneProductView"}
                          state={{
                            user_id: location.state.user_id,
                            user_milestone_id: location.state.user_milestone_id,
                            bond_id: item.bond_id,
                          }}
                        >
                          <img src={item.bond_img_video[0]?.img_video} alt="" />
                          {/* <p>{item.product_name}</p> */}
                          <p>
                            {item.product_name?.slice(0, 10)}
                            {""}
                            {item.product_name.length > 10 ? "..." : null}
                          </p>
                        </Link>
                      );
                    })}
                  </div>
                ) : null}
                {userId == getuser_id && (
                  <div
                    onClick={() => {
                      navigate("/NewLegacyProtectAsset", {
                        state: {
                          user_milestone_id: location.state.user_milestone_id,
                        },
                      });
                    }}
                    className="square"
                  >
                    <img src={images.squarep} alt="" />
                    <p>Protect Assets</p>
                  </div>
                )}
              </div>
            </div>
            {/* <div className="btndiv">
            <button className="btnmile">Save Milestone</button>
          </div> */}
          </>
        )}
        <ToastContainer />
      </div>
    </>
  );
};

export default MilestoneDashboard;
