import React, { useState } from "react";
import "./Mynfb.css";
import images from "../../constants/images";
import { Link, useNavigate } from "react-router-dom";
import NavbarSec from "../../common/NavbarSec";
import { useEffect } from "react";
import axios from "axios";
import qs from "qs";
import { get_user_accepted_bond } from "../../utils/Constant";
import { FaAngleLeft } from "react-icons/fa";

const Mynfbs = () => {
  const navigate = useNavigate();

  const userId = localStorage.getItem("user_id");
  const [products, setProducts] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [msg, setMsg] = useState(
    "Currently, you don't have any purchased Aucemo."
  );

  const GetAcceptedData = async () => {
    setProducts([]);

    const param = {
      user_id: userId,
    };
    await axios
      .post(get_user_accepted_bond, qs.stringify(param))
      .then((Response) => {
        console.log("====================================");
        console.log("=======?", Response.data);
        console.log("====================================");

        if (Response.data.status == 1) {
          setProducts(Response.data.result);
        } else {
          // setIsModalVisible(true);
          setMsg(Response.data.message);
        }
        console.log("Respnose", Response.data);
      });
  };

  useEffect(() => {
    GetAcceptedData();
  }, []);

  return (
    <div className="page_main_wrapp_nw reletive_position">
      <img src={images.new_bg_circule} alt="" className="back_circule_shadow" />
      <div className="page_base_wrapp_2">
        <div
          className="legacy_dashbord_nav_sec"
          style={{ margin: "10px auto" }}
        >
          <button
            onClick={() => {
              navigate("/Sidemenu");
            }}
          >
            <FaAngleLeft color="var(--color-white)" size={20} />
          </button>
          <p>My Aucemo</p>
          <div></div>
          {/* <img
                onClick={() => {
                  navigate("/CreateLegacy");
                }}
                src={images.round_plus}
                alt=""
              /> */}
        </div>
        {/* <NavbarSec name="My Aucemo" backto="/Sidemenu" /> */}
        <div className="mynfb_main">
          <Link to={"/releasednfb"} className="mynfb_header">
            <img src={images.logo} className="mynfbS_img" alt="" />
            <p className="h1_mynfbs_details">Released Aucemo</p>
          </Link>
          <Link to={"/resellnfb"} className="mynfb_header">
            <img
              src={images.new_launch_nfb_new}
              className="mynfbS_img"
              alt=""
            />
            <p className="h1_mynfbs_details">Resell Aucemo</p>
          </Link>
          <Link to={"/ReceivedNfbs"} className="mynfb_header">
            <img
              src={images.Partner_Reward_Request}
              className="mynfbS_img"
              alt=""
            />
            <p className="h1_mynfbs_details">Received Aucemo</p>
          </Link>
          <Link to={"/purchasednfbs"} className="mynfb_header">
            <img src={images.collection} className="mynfbS_img" alt="" />
            <p className="h1_mynfbs_details">Purchased Aucemo</p>
          </Link>
          <Link to={"/wishlist"} className="mynfb_header">
            <img src={images.favorite} className="mynfbS_img" alt="" />
            <p className="h1_mynfbs_details">Wishlist</p>
          </Link>
          <Link to={"/auction2"} className="mynfb_header">
            <img src={images.product_auction} className="mynfbS_img" alt="" />
            <p className="h1_mynfbs_details">My Auction</p>
          </Link>
          <Link to={"/HistoryRarerez"} className="mynfb_header">
            <img src={images.rhistory} className="mynfbS_img" alt="" />
            <p className="h1_mynfbs_details">Aucemo History</p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Mynfbs;
