import React, { useState } from "react";
import "./NewHomePage.css";
import Slider from "react-slick";
import images from "../../constants/images";
import Footer from "../../common/footer/Footer";
import { useHomeContext } from "../../context/home_context";
import { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import qs from "qs";
import AlertModal from "../../components/AlertModal";
import {
  add_joyride,
  get_total_available_bond_and_value,
  get_user_joyride,
} from "../../utils/Constant";
import Marquee from "react-fast-marquee";
import NewModal from "../../common/newmodal/NewModal";
import ContentLoader from "react-content-loader";
import InstagramSkeleton from "../../components/instagramskeleton/InstagramSkeleton";
import NewNavbar from "../../common/newnavbar/NewNavbar";
import NewFooter from "../../common/newfooter/NewFooter";
import NewLoader from "../../common/newloder/NewLoader";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CardSkeletonLoaderhome from "../../components/CardSkeletonLoaderhome";

import { Swiper, SwiperSlide } from "swiper/react";
import Joyride, { CallBackProps, STATUS, Step } from "react-joyride";
import { useRef } from "react";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import CustomJoyride from "../../utils/CustomJoyride";
import { RiVerifiedBadgeFill } from "react-icons/ri";

const interest = [
  { id: 1, name: "all" },
  { id: 1, name: "entertanment" },
];

const steps = [
  {
    target: ".home_joy_2",
    content: "it's show platform total assets and total assets value",
    disableBeacon: true,

    spotlightPadding: 20,
  },
  {
    target: ".home_joy_1",
    content: "it's show platform total assets and total assets value",
    // disableBeacon: true,

    spotlightPadding: 20,
  },

  {
    target: ".home_joy_3",
    content: "it's show platform total assets and total assets value",
    // disableBeacon: true,

    spotlightPadding: 20,
  },
  {
    target: ".home_joy_4",
    content: "it's show platform total assets and total assets value",
    // disableBeacon: true,

    spotlightPadding: 20,
  },
  {
    target: ".home_joy_5",
    content: "it's show platform total assets and total assets value",
    // disableBeacon: true,

    spotlightPadding: 20,
  },
  {
    target: ".home_joy_6",
    content: "it's show platform total assets and total assets value",
    // disableBeacon: true,

    spotlightPadding: 20,
  },
  {
    target: ".home_joy_7",
    content: "it's show platform total assets and total assets value",
    // disableBeacon: true,

    spotlightPadding: 20,
  },
  {
    target: ".home_joy_8",
    content: "it's show platform total assets and total assets value",
    // disableBeacon: true,

    spotlightPadding: 20,
  },
];

const NewHomePage = () => {
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [run, setRun] = useState(false);
  //
  const {
    getDashboardlatest5,
    is_loading,
    home_latest5_data,
    latest5_loading,
  } = useHomeContext();
  const location = useLocation();
  const userId = localStorage.getItem("user_id");
  const [slideIndex, setSlideIndex] = useState(0);
  const [slideIndex2, setSlideIndex2] = useState(0);
  const [slideIndex3, setSlideIndex3] = useState(0);
  const [slideIndex4, setSlideIndex4] = useState(0);
  const [slideIndex5, setSlideIndex5] = useState(0);
  const [isloading, setIsLoading] = useState(is_loading);
  const [getintrestsName, setIntrestName] = useState("All");
  const [getallIntrests, setAllIntrest] = useState([]);
  const [getlatestFiveData, setlatestFiveData] = useState([]);
  // const [getfooterName, setFooterName] = useState("home");
  const [bodyScrollEnabled, setBodyScrollEnabled] = useState(true);
  const [gettotalRarerez, settotalRarerez] = useState("");
  const [gettotalRarerezValue, settotalRarerezValue] = useState("");

  const closemodal = () => {
    setShowModal(false);
  };
  const openmodal = () => {
    setShowModal(true);
  };

  useEffect(() => {
    const timer = setTimeout(() => setIsLoading(false), 2000); // Replace with actual loading logic
    return () => clearTimeout(timer);
  }, []);

  // JOYRIDE function start
  // useEffect(() => {
  //   const hasSeenTour = localStorage.getItem("hasSeenTour");
  //   if (!hasSeenTour) {
  //     setRun(true);
  //   }
  // }, []);

  // const handleJoyrideCallback = (data) => {
  //   const { status } = data;
  //   const finishedStatuses = ["finished", "skipped"];

  //   if (finishedStatuses.includes(status)) {
  //     localStorage.setItem("hasSeenTour", "true");
  //     setRun(false);
  //   }
  // };
  // JOYRIDE function end

  // JOYRIDE function start
  useEffect(() => {
    // const hasSeenTour = localStorage.getItem("hasSeenTour");
    // if (!hasSeenTour) {
    //   setRun(true);
    // }

    const param = { user_id: userId };
    const existingNames = ["home_joyride"];

    axios
      .post(get_user_joyride, qs.stringify(param))
      .then((response) => {
        const data = response.data;

        if (data && data.status === 1 && Array.isArray(data.joyride)) {
          const hasExistingNames = data.joyride.some((item) => {
            console.log("Item received from API:", item);
            return existingNames.includes(item);
          });

          if (hasExistingNames) {
            console.log("One or more items already exist in the array.");
            setRun(false);
          } else {
            console.log("No items exist in the existingNames array.");
            setRun(true);
          }
        } else {
          console.log("Joyride data is empty or not in the expected format.");
          setRun(true); // Default to true if no data or incorrect format
        }
      })
      .catch((error) => {
        console.error("Error fetching joyride data:", error); // Handle errors
        setRun(false); // Optionally set run to false in case of an error
      });
  }, []);

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    const finishedStatuses = ["finished", "skipped"];

    if (finishedStatuses.includes(status)) {
      // localStorage.setItem("hasSeenTour", "true");
      // setRun(false);

      const param = {
        user_id: userId,
        joyride_name: "home_joyride",
      };

      axios.post(add_joyride, qs.stringify(param)).then((Response) => {
        const data = Response.data;
        console.log("jalll", data);
        if (data.status == 1) {
          setRun(false);
        }
      });
    }
  };
  // JOYRIDE function end

  useEffect(() => {
    if (showModal) {
      // Disable body scrolling
      setBodyScrollEnabled(false);
    } else {
      // Enable body scrolling
      setBodyScrollEnabled(true);
    }
    if (bodyScrollEnabled) {
      document.body.style.overflow = "visible";
    } else {
      document.body.style.overflow = "hidden";
    }
  }, [showModal, bodyScrollEnabled]);

  useEffect(() => {
    Latest5Api();
    AllRarerez();
    console.log("latestFiveRecords", home_latest5_data);
    console.log("location.pathname", location.pathname);
  }, []);

  const Latest5Api = async () => {
    const params = {
      user_id: JSON.parse(userId),
    };
    console.log("login param", params);

    const data = await getDashboardlatest5(params);
    if (data) {
      setAllIntrest(data.result?.user_interest);
      setlatestFiveData(data.result?.latestFiveRecords);
      setFilteredData(data.result?.latestFiveRecords);
    }
    console.log("user_interest", data.result?.latestFiveRecords);
  };

  const AllRarerez = async () => {
    setIsLoading(true);
    await axios
      .get(get_total_available_bond_and_value)
      .then((res) => {
        console.log("res.data", res.data);
        setIsLoading(false);
        if (res.data.status == 1) {
          settotalRarerez(res.data.total_bond);
          // const fixedData = res.data.total_bond_value;
          settotalRarerezValue(res.data.total_bond_value);
          console.log("gettotalRarerezValue", gettotalRarerezValue);
        } else {
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1.1,
    slidesToScroll: 1,
    afterChange: (currentSlide) => setSlideIndex(currentSlide),
    customPaging: (i) => (
      <div
        style={{
          width: "10px",
          height: "10px",
          margin: "10px 5px",
          borderRadius: "50%",
          backgroundColor: slideIndex === i ? "#da0e41" : "white",
          opacity: slideIndex === i ? 1 : 0.6,
        }}
      ></div>
    ),
  };
  const settings2 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow:
      home_latest5_data &&
      home_latest5_data.latest_five_not_accepted?.length > 1
        ? 2
        : 1,
    slidesToScroll: 1,
    afterChange: (currentSlide) => setSlideIndex2(currentSlide),
    customPaging: (i) => (
      <div
        style={{
          width: "10px",
          height: "10px",
          margin: "10px 5px",
          borderRadius: "50%",
          backgroundColor: slideIndex2 === i ? "#da0e41" : "white",
          opacity: slideIndex2 === i ? 1 : 0.6,
        }}
      ></div>
    ),
    // responsive: [
    //   {
    //     breakpoint: 2560,
    //     settings: {
    //       slidesToShow:
    //         home_latest5_data &&
    //         home_latest5_data.latest_five_not_accepted?.length > 1
    //           ? 2
    //           : 1,
    //       slidesToScroll: 1,
    //     },
    //   },
    //   {
    //     breakpoint: 1440,
    //     settings: {
    //       slidesToShow:
    //         home_latest5_data &&
    //         home_latest5_data.latest_five_not_accepted?.length > 1
    //           ? 2
    //           : 1,
    //       slidesToScroll: 1,
    //     },
    //   },
    //   {
    //     breakpoint: 768,
    //     settings: {
    //       slidesToShow:
    //         home_latest5_data &&
    //         home_latest5_data.latest_five_not_accepted?.length > 1
    //           ? 2
    //           : 1,
    //       slidesToScroll: 1,
    //     },
    //   },
    // ],
  };

  const settings3 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow:
      home_latest5_data &&
      home_latest5_data.latest_single_accepted_bond?.length > 1
        ? 2
        : 1,
    slidesToScroll: 1,
    afterChange: (currentSlide) => setSlideIndex3(currentSlide),
    customPaging: (i) => (
      <div
        style={{
          width: "10px",
          height: "10px",
          margin: "10px 5px",
          borderRadius: "50%",
          backgroundColor: slideIndex3 === i ? "#da0e41" : "white",
          opacity: slideIndex3 === i ? 1 : 0.6,
        }}
      ></div>
    ),
    // responsive: [
    //   {
    //     breakpoint: 2560,
    //     settings: {
    //       slidesToShow:
    //         home_latest5_data &&
    //         home_latest5_data.latest_single_accepted_bond?.length > 1
    //           ? 2
    //           : 1,
    //       slidesToScroll: 1,
    //     },
    //   },
    //   {
    //     breakpoint: 1440,
    //     settings: {
    //       slidesToShow:
    //         home_latest5_data &&
    //         home_latest5_data.latest_single_accepted_bond?.length > 1
    //           ? 2
    //           : 1,
    //       slidesToScroll: 1,
    //     },
    //   },
    //   {
    //     breakpoint: 768,
    //     settings: {
    //       slidesToShow:
    //         home_latest5_data &&
    //         home_latest5_data.latest_single_accepted_bond?.length > 1
    //           ? 2
    //           : 1,
    //       slidesToScroll: 1,
    //     },
    //   },
    // ],
  };

  const settings4 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow:
      home_latest5_data && home_latest5_data.reverse_record?.length > 1 ? 2 : 1,
    slidesToScroll: 1,
    afterChange: (currentSlide) => setSlideIndex4(currentSlide),
    customPaging: (i) => (
      <div
        style={{
          width: "10px",
          height: "10px",
          margin: "10px 5px",
          borderRadius: "50%",
          backgroundColor: slideIndex4 === i ? "#da0e41" : "white",
          opacity: slideIndex4 === i ? 1 : 0.6,
        }}
      ></div>
    ),
    // responsive: [
    //   {
    //     breakpoint: 2560,
    //     settings: {
    //       slidesToShow:
    //         home_latest5_data && home_latest5_data.reverse_record?.length > 1
    //           ? 2
    //           : 1,
    //       slidesToScroll: 1,
    //     },
    //   },
    //   {
    //     breakpoint: 1440,
    //     settings: {
    //       slidesToShow:
    //         home_latest5_data && home_latest5_data.reverse_record?.length > 1
    //           ? 2
    //           : 1,
    //       slidesToScroll: 1,
    //     },
    //   },
    //   {
    //     breakpoint: 768,
    //     settings: {
    //       slidesToShow:
    //         home_latest5_data && home_latest5_data.reverse_record?.length > 1
    //           ? 2
    //           : 1,
    //       slidesToScroll: 1,
    //     },
    //   },
    // ],
  };

  const settings5 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow:
      home_latest5_data && home_latest5_data.user_array?.length > 1 ? 2 : 1,
    slidesToScroll: 1,
    afterChange: (currentSlide) => setSlideIndex5(currentSlide),
    customPaging: (i) => (
      <div
        style={{
          width: "10px",
          height: "10px",
          margin: "10px 5px",
          borderRadius: "50%",
          backgroundColor: slideIndex5 === i ? "#da0e41" : "white",
          opacity: slideIndex5 === i ? 1 : 0.6,
        }}
      ></div>
    ),
    // responsive: [
    //   {
    //     breakpoint: 2560,
    //     settings: {
    //       slidesToShow: 4,
    //       slidesToScroll: 1,
    //     },
    //   },
    //   {
    //     breakpoint: 1440,
    //     settings: {
    //       slidesToShow: 3,
    //       slidesToScroll: 1,
    //     },
    //   },
    //   {
    //     breakpoint: 768,
    //     settings: {
    //       slidesToShow:
    //         home_latest5_data && home_latest5_data.user_array?.length > 1
    //           ? 2
    //           : 1,
    //       slidesToScroll: 1,
    //     },
    //   },
    // ],
  };

  const [isAdhar, setIsAdhar] = useState(null);

  useEffect(() => {
    const param = {
      user_id: userId,
    };

    axios
      .post(
        "https://www.ravi.host/api/user_adhar_is_verify",
        qs.stringify(param)
      )
      .then((Response) => {
        if (Response.data.status === 1) {
          setIsAdhar(1);
        } else {
          setIsAdhar(0);
        }
      });
  }, []);

  const navigate = useNavigate();
  const [getindex, setindex] = useState(0);
  const [getname, setname] = useState("All");

  const [filteredData, setFilteredData] = useState([]);

  const filterData = (name) => {
    if (name) {
      const filteredDatas = getlatestFiveData.filter((item) =>
        item.bond_interest.some((bond_interest) => bond_interest.name === name)
      );
      if (filteredDatas.length == 0) {
        setFilteredData(getlatestFiveData);
      } else {
        setFilteredData(filteredDatas);
      }
      console.log("filtered data", JSON.stringify(filteredDatas, null, 2));
    } else {
      // If the name parameter is empty, show all data
      setFilteredData(getlatestFiveData);
      console.log("filtered home_latest5_data", filteredData);
    }
  };

  // loder start

  const rows = 2;
  const columns = 5;
  const coverHeight = 85;
  const coverWidth = 65;
  const padding = 5;
  const speed = 1;

  const coverHeightWithPadding = coverHeight + padding;
  const coverWidthWithPadding = coverWidth + padding;
  const initial = 35;
  const covers = Array(columns * rows).fill(1);

  // loder end

  const SkeletonLoader = () => (
    <div style={{ marginTop: "40px", margin: "10px auto" }}>
      <ContentLoader
        speed={4}
        width={400}
        height={660}
        viewBox="0 0 400 660"
        backgroundColor="#333"
        foregroundColor="#464646"
        // {...props}
      >
        <rect x="28" y="100" rx="0" ry="0" width="356" height="172" />
        <rect x="28" y="308" rx="0" ry="0" width="158" height="40" />
        <rect x="228" y="379" rx="0" ry="0" width="156" height="114" />
        <rect x="28" y="379" rx="0" ry="0" width="155" height="116" />
        <rect x="227" y="308" rx="0" ry="0" width="158" height="40" />
        <rect x="28" y="525" rx="0" ry="0" width="155" height="116" />
        <rect x="228" y="525" rx="0" ry="0" width="156" height="114" />
      </ContentLoader>
    </div>
  );

  // const [run, setRun] = useState(false);

  // const steps = [
  //   {
  //     target: ".step1", // CSS selector of the target element
  //     content: "This is step 1", // Content of the step
  //     color: "red",
  //   },
  //   {
  //     target: ".step2",
  //     content: "This is step 2",
  //   },
  //   {
  //     target: ".step3",
  //     content: "This is step 3",
  //   },
  // ];

  // const handleTourEnd = (data) => {
  //   const { status } = data;

  //   if (status === STATUS.FINISHED) {
  //     console.log("Tour ended");
  //     setRun(false);
  //   }
  // };

  const sliderRef = useRef(null);

  const scrollLeft = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({
        left: -200,
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({
        left: 200,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="homenew_13_main_wrap reletive_position">
      {/* <div>
        <button onClick={() => setRun(true)}>Start Tour</button> */}
      {/* <Joyride
          steps={steps}
          run={run}
          continuous={true}
          showProgress={true}
          showSkipButton={true}
          scrollToFirstStep={true}
          disableOverlayClose={true}
          disableScrollParentFix={true}
          disableCloseOnEsc={true}
          disableScrolling={true}
          styles={{
            options: {
              arrowColor: "#fff",
              backgroundColor: "#007bff",
              overlayColor: "rgba(0, 0, 0, 0.5)",
              primaryColor: "#fff",
              textColor: "#333",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            },
          }}
          callback={handleTourEnd}
        /> */}
      <img
        src={images.new_bg_circule}
        alt=""
        className="back_circule_shadow_left"
      />
      {/* <img
        src={images.new_bg_circule_2}
        alt=""
        className="back_circule_shadow_white"
      /> */}

      {/* <div className="back_circule_shadow_white"></div> */}
      <NewNavbar />

      {isloading ? (
        <NewLoader />
      ) : (
        <div className="homenew_13_base_wrapp">
          {/* <CustomJoyride
            steps={steps}
            run={run}
            showProgress={false}
            callback={handleJoyrideCallback}
            styles={{
              options: {
                zIndex: 100000,
                // primaryColor: "var(--color-main)",
              },

              spotlight: {
                backgroundColor: "transparent",
              },
            }}
          /> */}
          <div className="homenew_13_first_slider_wrapp">
            <div className="new_marquee_wrapp home_joy_1">
              <Marquee pauseOnClick>
                <div className="homenew_13_htb_single_sqare ">
                  <span>Number of Assets :</span>
                  <p>{gettotalRarerez}</p>
                  <h1 className="homenew_13_dot_partn1">.</h1>
                </div>

                <div className="homenew_13_htb_single_sqare">
                  <span>Assets Value :</span>
                  <p>₹{gettotalRarerezValue}</p>
                  <h1 className="homenew_13_dot_partn2">.</h1>
                </div>
              </Marquee>
            </div>
            {/* <div className="homenew_13_select_set_new">
              <div
                onClick={() => {
                  setname("All");
                  setFilteredData(getlatestFiveData);
                }}
                className="homenew_13_select_set_new_sub1"
              >
                <p
                  className={`my-p ${
                    getname === "All" ? "custom custom-border" : "custom"
                  }`}
                >
                  All
                </p>
              </div>

              <div className="homenew_13_select_set_new_sub2">
                {getallIntrests &&
                  getallIntrests.map((item, index) => {
                    console.log("item", item);
                    const gradientBorderClass =
                      getname === item.name ? "custom custom-border" : "custom";

                    return (
                      <div
                        onClick={() => {
                          filterData(item.name);
                          setname(item.name);
                        }}
                        key={index}
                      >
                        <p className={`my-p ${gradientBorderClass}`}>
                          {item.name}
                        </p>
                      </div>
                    );
                  })}
              </div>
            </div> */}
            <div />
            {/* ======slider start */}
            <div className="homenew_13_first_slide_card_home">
              {latest5_loading ? (
                // <ContentLoader
                //   speed={2}
                //   width="100%"
                //   height={400}
                //   viewBox="0 0 100% 400"
                //   backgroundColor={"#333"}
                //   foregroundColor={"#999"}
                //   // style={{maxWidth:"420px"}}
                // >
                //   <rect x="12" y="10" rx="5" ry="5" width="94%" height="378" />
                // </ContentLoader>
                <CardSkeletonLoaderhome />
              ) : (
                <>
                  {/* <Slider {...settings}>
                  {filteredData && filteredData.length > 0
                    ? filteredData.map((item, index) => {
                        return (
                          <div
                            className="homenew_13_first_slide_card"
                            key={item.product_id}
                          >
                            <img
                              className="new_homecard_back_img"
                              src={images.home_blank_card}
                              alt=""
                            />
                            <div className="new_home_card_user_wrapp">
                              <div className="new_home_card_user_img">
                                <img src={item?.profile} alt="" />
                              </div>
                              <p>{item.username}</p>
                            </div>
                            <img
                              src={
                                item.product_image_video
                                  ? item.product_image_video[
                                      item.product_image_video.length - 1
                                    ].img_video
                                  : item.bond_media_upload[
                                      item.bond_media_upload.length - 1
                                    ]?.img_video
                              }
                              alt=""
                              className="new_home_card_img"
                            />
                            <div
                              // style={{ zIndex: 11 }}
                              className="new_home_card_name_wrapp"
                            >
                              <p>
                                {item.product_name &&
                                item.product_name.length > 26
                                  ? item.product_name.slice(0, 26)
                                  : item.product_name}
                              </p>
                              <div className="homenew_13_u_line"></div>
                            </div>
                            <div
                              className="new_home_card_asset_wrapp"
                              onClick={() => {
                                navigate("/productview", {
                                  state: {
                                    release_bond_id: item.release_bond_id,
                                    product_id: item.product_id,
                                    is_nfb: item.is_nfb,
                                    uniq_code: item.uniq_code,
                                    theme_index: item.theme_index,
                                    share_by_user: item.share_by_user,
                                    club_id: item.club_id,
                                  },
                                });
                              }}
                            >
                              <div className="new_home_card_asset_single">
                                <p>Asset Value</p>
                                <h4>₹{item.product_value}</h4>
                              </div>
                              <div className="new_home_card_asset_single">
                                <p>Minimum Bid</p>
                                <h4>₹{item.minimum_bid_amount}</h4>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    : null}
                </Slider> */}
                  <>
                    <Swiper
                      // width={340}
                      centeredSlides={true}
                      className="mySwiper"
                      // spaceBetween={15}
                      effect="fade"
                      slidesPerView={1.1}
                      // style={{ left: "15px" }}
                    >
                      {filteredData && filteredData.length > 0
                        ? filteredData.map((item, index) => {
                            return (
                              <SwiperSlide>
                                <div
                                  className="homenew_13_first_slide_card home_joy_3"
                                  key={item.product_id}
                                  style={{
                                    flexDirection: "column",
                                    left: "0px",
                                  }}
                                  onClick={() => {
                                    navigate("/productview", {
                                      state: {
                                        release_bond_id: item.release_bond_id,
                                        product_id: item.product_id,
                                        is_nfb: item.is_nfb,
                                        uniq_code: item.uniq_code,
                                        theme_index: item.theme_index,
                                        share_by_user: item.share_by_user,
                                        club_id: item.club_id,
                                      },
                                    });
                                  }}
                                >
                                  <img
                                    className="new_homecard_back_img"
                                    src={images.home_blank_card}
                                    alt=""
                                  />
                                  <div className="new_home_card_user_wrapp">
                                    <div className="new_home_card_user_img">
                                      <img src={item?.profile} alt="" />
                                    </div>
                                    <p>{item.username}</p>
                                    {item.is_verify == 1 ? (
                                      <RiVerifiedBadgeFill
                                        size={20}
                                        color="gold"
                                      />
                                    ) : null}
                                  </div>
                                  {/* <img
                                    src={
                                      item.product_image_video
                                        ? item.product_image_video[
                                            item.product_image_video.length - 1
                                          ].img_video
                                        : item.bond_media_upload[
                                            item.bond_media_upload.length - 1
                                          ]?.img_video
                                    }
                                    alt=""
                                    className="new_home_card_img"
                                  /> */}
                                  {item.product_image_video &&
                                  item.product_image_video.length > 0 ? (
                                    <img
                                      src={
                                        item.product_image_video[0].img_video
                                      }
                                      className="new_home_card_img"
                                      alt=""
                                    />
                                  ) : (
                                    <img
                                      src={
                                        item.product_image_video
                                          ? item.product_image_video
                                              .filter(
                                                (item) =>
                                                  item.image_type == "new"
                                              )
                                              .map(
                                                (item) => item.img_video
                                              )[0] || null
                                          : (item.bond_media_upload &&
                                              item.bond_media_upload
                                                .filter(
                                                  (item) =>
                                                    item.image_type == "new"
                                                )
                                                .map(
                                                  (item) => item.img_video
                                                )[0]) ||
                                            null
                                      }
                                      className="new_home_card_img"
                                      alt=""
                                    />
                                  )}
                                  <div
                                    // style={{ zIndex: 11 }}
                                    className="new_home_card_name_wrapp"
                                  >
                                    <p>
                                      {item.product_name &&
                                      item.product_name.length > 22
                                        ? item.product_name.slice(0, 22)
                                        : item.product_name}
                                    </p>
                                    <div className="homenew_13_u_line"></div>
                                  </div>
                                  <div className="new_home_card_asset_wrapp">
                                    <div className="new_home_card_asset_single">
                                      <p>Asset Value</p>
                                      <h4>₹{item.product_value}</h4>
                                    </div>
                                    <div className="new_home_card_asset_single">
                                      <p>Minimum Bid</p>
                                      <h4>₹{item.minimum_bid_amount}</h4>
                                    </div>
                                  </div>
                                </div>
                              </SwiperSlide>
                            );
                          })
                        : null}
                    </Swiper>
                  </>
                </>
              )}
            </div>
            {/* =======slider end */}

            {/* =======auction btns start */}
            <div className="homenew_13_auction_btn">
              <Link to="/Auction" className="home_joy_2">
                <img
                  src={images.product_auction13}
                  alt=""
                  className="homenew_13_auction_btn_home"
                />
                Auction
              </Link>
              <Link to={"/clubs"} className="home_joy_3">
                <img
                  src={images.club_white_h}
                  alt=""
                  className="homenew_13_auction_btn_home"
                />
                Clubs
              </Link>
            </div>
            {/* =======auction btns end */}
            {/* ---------------------category start--------------------------- */}
            <div className="homenew_13_viewmore_wrapp">
              <h4>Sections</h4>
              {/* <Link to={""}>View all</Link> */}
            </div>
            <div
              style={{ position: "relative", width: "100%" }}
              className="home_joy_4"
            >
              <button
                className="nav-button left"
                onClick={scrollLeft}
                style={{
                  padding: "8px",
                  top: "30%",
                  left: "0px",
                  width: "36px",
                }}
              >
                <FaAngleLeft color="var(--color-main)" size={22} />
              </button>
              <div
                className="homenew_13_section_main_row"
                ref={sliderRef}
                style={{
                  padding: "0px 2rem",
                }}
              >
                <Link to={"/RoyaltyNfbs"}>
                  <div
                    className="homenew_13_each_section"
                    style={{
                      background:
                        "linear-gradient(130.32deg, #004d99 0%, #3399ff 58.01%)",
                    }}
                  >
                    Royalty Aucemo
                    <br />
                    (1st Buyer)
                  </div>
                </Link>
                <Link to={"/RoyaltyNfbs2"}>
                  <div
                    className="homenew_13_each_section"
                    style={{
                      background:
                        "linear-gradient(130.32deg, #50276a 0%, #cb68fd 58.01%)",
                    }}
                  >
                    Royalty Aucemo <br />
                    (2st Buyer)
                  </div>
                </Link>
                <Link to={"/TopCreators"}>
                  <div
                    className="homenew_13_each_section"
                    style={{
                      background:
                        "linear-gradient(130.32deg, #0e2e3a 0%, #0F5748 58.01%)",
                    }}
                  >
                    {" "}
                    Top Creator{" "}
                  </div>
                </Link>
                <Link to={"/feed"}>
                  <div
                    className="homenew_13_each_section"
                    style={{
                      background:
                        "linear-gradient(130.32deg, #FF004C80 0%, #CAACFF36 58.01%)",
                    }}
                  >
                    {" "}
                    Top Aucemo{" "}
                  </div>
                </Link>
                <Link to={"/AllTopClub"}>
                  <div
                    className="homenew_13_each_section"
                    style={{
                      background:
                        "linear-gradient(130.32deg, #FF004C80 0%, #004d99 58.01%)",
                    }}
                  >
                    {" "}
                    Top Clubs{" "}
                  </div>
                </Link>

                <Link to={"/FreeBenefit"}>
                  <div
                    className="homenew_13_each_section"
                    style={{
                      background:
                        "linear-gradient(130.32deg, rgb(90 145 200) 0%, rgb(71 81 255) 58.01%)",
                    }}
                  >
                    Collect & Wins
                  </div>
                </Link>
              </div>

              <button
                className="nav-button right"
                onClick={scrollRight}
                style={{
                  justifyContent: "flex-end",
                  padding: "8px",
                  top: "30%",
                  width: "36px",
                  right: "0px",
                }}
              >
                <FaAngleRight color="var(--color-main)" size={22} />
              </button>
            </div>
            {/* ---------------------category end--------------------------- */}
          </div>
          <NewFooter getlocation={location.pathname} />
        </div>
      )}
      {/* </div> */}
    </div>
  );
};

export default NewHomePage;
