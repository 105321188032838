import React, { useEffect, useState } from "react";
import "./NewNavbar.css";
import images from "../../constants/images";
import { Link, useNavigate } from "react-router-dom";
import { useProfileContext } from "../../context/profile_context";
import axios from "axios";
import qs from "qs";
import { GiHamburgerMenu } from "react-icons/gi";
import V2Modal from "../v2modal/V2Modal";

const NewNavbar = () => {
  const userId = localStorage.getItem("user_id");
  const { profile_loading, edit_profile_data, EditProfile } =
    useProfileContext();
  const [isLoading, setIsLoading] = useState(false);
  const [getcwatyModal, setCwatyModal] = useState(false);

  const closeCwatyModal = () => {
    setCwatyModal(false);
  };

  const GetProfile = async () => {
    setIsLoading(true);
    const param = {
      user_id: userId,
    };

    const data = await EditProfile(param);
    console.log("data 111", data.result);
  };

  useEffect(() => {
    GetProfile();
  }, []);

  const [isAdhar, setIsAdhar] = useState(null);

  useEffect(() => {
    const param = {
      user_id: userId,
    };

    axios
      .post(
        "https://www.ravi.host/api/user_adhar_is_verify",
        qs.stringify(param)
      )
      .then((Response) => {
        if (Response.data.status === 1) {
          setIsAdhar(1);
        } else {
          setIsAdhar(0);
        }
      });
  }, []);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [cusmsg, setCusmsg] = useState("");

  const navigate = useNavigate();

  const ccc = () => {
    navigate("/wallet");
  };
  const ccc1 = () => {
    navigate("/Sidemenu");
  };

  return (
    <div className="nav13_main_wrapp">
      <div className="nav13_base_wrapp">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          <Link to={"/usermenu"} className="nav13_profile_wrapp">
            <div>
              <img
                src={
                  edit_profile_data.profile === ""
                    ? images.logo
                    : edit_profile_data.profile
                }
                className="nav13_img_of_usermenu"
                alt=""
              />
            </div>
          </Link>
          {/* ===== */}

          {/* cwaty btn start */}
          <button
            onClick={() => {
              setCwatyModal(true);
            }}
            className="nav13_profile_wrapp"
          >
            <div>
              <img
                src={images.cwaty_logo}
                className="nav13_img_of_usermenu"
                alt=""
              />
            </div>
          </button>
          {/* cwaty btn end */}
        </div>

        <div className="nav13_btns_wrapp">
          <Link to={"/homesearch"} className="nav13_btn">
            <img src={images.search_icon} alt="" />
          </Link>

          <Link to={"/wallet"} className="nav13_btn home_joy_2">
            <img src={images.money_icon} alt="" />
          </Link>

          <button
            onClick={() => {
              ccc1();
            }}
            className="nav13_btn"
          >
            <img src={images.dot3} alt="" />
            {/* <GiHamburgerMenu color="var(--color-main)" size={24} /> */}
          </button>
        </div>
        {/* ========= */}
      </div>
      {isModalVisible && (
        <div className="modal_login">
          <div className="modal-content_login">
            <div className="modal_div1">
              <img className="forgot_main" src={images.logo} alt="" />
            </div>
            <div className="modal_div">
              <b>{""}</b>
              <br />
              <p className="modal-message"> {cusmsg}</p>
              <br />
            </div>
            <hr className="hr_forgot" />
            <br />
            <div className="modal_div">
              <button
                className="close_forgot"
                onClick={() => {
                  setIsModalVisible(false);
                }}
              >
                Okay
              </button>
            </div>
          </div>
        </div>
      )}
      {getcwatyModal && (
        <V2Modal
          // </ReactModa
          canclebtn={false}
          title=""
          msg="This feature will be available soon in aucemo.club"
          setShowModal={setCwatyModal}
          okfunction={closeCwatyModal}
        />
      )}
    </div>
  );
};

export default NewNavbar;
