import React, { useState } from "react";
import "./aadharcard.css";
import images from "../../constants/images";
import { useLocation, useNavigate } from "react-router-dom";
import NavbarSec from "../../common/NavbarSec";
import axios from "axios";
import qs from "qs";
import { useEffect } from "react";
import NewModal from "../../common/newmodal/NewModal";
import V2Modal from "../../common/v2modal/V2Modal";

const Aadharcard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [aadharNumber, setAadharNumber] = useState("");
  const [isValidAadhar, setIsValidAadhar] = useState(true);
  const [getcapchaImg, setCapchaImg] = useState("");
  const [getcapchaCode, setCapchaCode] = useState("");
  const [gettranzactionId, setTranzactionId] = useState("");

  useEffect(() => {
    GetCapcha();
  }, []);

  const handleAadharInputChange = (event) => {
    const inputValue = event.target.value;

    // Remove non-numeric characters and hyphens
    const formattedValue = inputValue.replace(/[^\d-]/g, "");

    setAadharNumber(formattedValue);

    // Check if the input matches the "4-4-4" format
    const isValid = /^\d{4}-\d{4}-\d{4}$/.test(formattedValue);
    setIsValidAadhar(isValid);
  };

  const handleSubmit = () => {
    if (isValidAadhar) {
      // Handle the submission logic here
      // You can navigate to the next page or perform further actions
      // navigate("/nextPage");
    } else {
      alert(
        "Please enter a valid Aadhaar number in the format '1234-1234-1234'"
      );
    }
  };

  const [textInputValue, setTextInputValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [cusmsg, setCusmsg] = useState("");
  const [bodyScrollEnabled, setBodyScrollEnabled] = useState(true);

  // const userId = localStorage.getItem("user_id");

  // const user_id = useSelector((state) => state.userDetails.user_id);
  // const dispatch = useDispatch();

  useEffect(() => {
    if (isModalVisible) {
      // Disable body scrolling
      setBodyScrollEnabled(false);
    } else {
      // Enable body scrolling
      setBodyScrollEnabled(true);
    }
    if (bodyScrollEnabled) {
      document.body.style.overflow = "visible";
    } else {
      document.body.style.overflow = "hidden";
    }
  }, [isModalVisible, bodyScrollEnabled]);

  const generateRandomString = (length) => {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charactersLength);
      result += characters.charAt(randomIndex);
    }

    return result;
  };

  const handleTextInputChange = (text) => {
    // Remove non-numeric characters
    const formattedText = text.replace(/\D/g, "");

    // Add space after every 4th digit
    const formattedWithSpaces = formattedText.replace(/(\d{4})/g, "$1 ");

    // Update the state with the formatted value
    setTextInputValue(formattedWithSpaces);
  };

  const formatAadhaarNumber = (inputValue) => {
    // Remove any existing spaces
    let formattedText = inputValue.replace(/ /g, "");

    if (formattedText.length > 12) {
      formattedText = formattedText.slice(0, 12); // Limit the input to 12 characters
    }

    if (formattedText.length > 0) {
      // Add space after every 4th digit
      formattedText = formattedText.match(new RegExp(".{1,4}", "g")).join(" ");
    }

    return formattedText;
  };

  const formatAadhaarNumber1 = (inputValue) => {
    // Remove any existing spaces
    let formattedText = inputValue.replace(/ /g, "");

    if (formattedText.length > 12) {
      formattedText = formattedText.slice(0, 12); // Limit the input to 12 characters
    }

    if (formattedText.length > 0) {
      // Add space after every 4th digit
      formattedText = formattedText.match(new RegExp(".{1,4}", "g")).join(" ");
    }

    // Replace consecutive spaces with a single space
    formattedText = formattedText.replace(/\s+/g, " ");
    setTextInputValue(formattedText);
    const tdata = formattedText.replace(/ /g, "");

    console.log("formattedText", tdata);
    return formattedText;
  };

  const getTrimmedValue = () => {
    return textInputValue.replace(/ /g, ""); // Remove all spaces from the value
  };

  const GetCapcha = () => {
    setIsLoading(true);

    // Make the API request
    axios
      .get("https://www.ravi.host/api/adhar_init")
      .then((response) => {
        setIsLoading(false);
        if (response.data.status === 1) {
          console.log("response.data", response.data);
          setCapchaImg(response.data.result.file_path);
          setTranzactionId(response.data.result.transaction_id);
        } else {
          setIsModalVisible(true);
          setCusmsg(response.data.massage);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      });
  };

  const updateText = () => {
    const tdata = textInputValue.replace(/ /g, "");
    console.log("tdata", tdata);
    if (tdata.length <= 0) {
      setIsModalVisible(true);
      setCusmsg("Please Enter aadhaar card number");
      return;
    } else if (tdata.length !== 12) {
      setIsModalVisible(true);
      setCusmsg(
        "Invalid Input \n \n Aadhaar card number should be exactly 12 digits"
      );
    } else if (getcapchaCode == "") {
      setIsModalVisible(true);
      setCusmsg("Please enter capcha code");
    } else {
      setIsLoading(true);
      const randomStr = generateRandomString(10);
      const trimmedValue = getTrimmedValue();
      const param = {
        transaction_id: gettranzactionId,
        adhar_no: tdata,
        captcha_code: getcapchaCode,
      };
      // Make the API request
      axios
        .post(
          "https://www.ravi.host/api/adhar_generate_otp",
          qs.stringify(param)
        )
        .then((response) => {
          if (response.data.status === 1) {
            console.log("response.data", response.data);
            setIsLoading(false);
            navigate("/aadharcardotp", {
              state: {
                adhar_no: response.data.result.adhar_no,
                transaction_id: response.data.result.transaction_id,
                user_id: location.state.user_id,
              },
            });
          } else {
            setIsModalVisible(true);
            setCusmsg("Please enter valid Aadhaar Number");
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.error(error);
        });

      setTextInputValue(textInputValue);
    }
  };

  // const handleKeyPress = (e) => {
  //   // Check if the pressed key is a dot
  //   if (!(e.charCode >= 48 && e.charCode <= 57) && e.charCode !== 8) {
  //     e.preventDefault();
  //   }
  // };

  const handleKeyPress = (e) => {
    // Check if the pressed key is a digit or a space
    if (!(e.key.match(/[0-9]/) || e.key === " " || e.key === "Backspace")) {
      e.preventDefault();
    }
  };

  const closemodal = () => {
    setIsModalVisible(false);
  };
  const openmodal = () => {
    setIsModalVisible(true);
  };

  return (
    <div className="page_main_wrapp">
      {isLoading ? (
        <div className="loader"></div>
      ) : (
        <div className="page_base_wrapp_2">
          {/* <NavbarSec name="eKYC" /> */}
          {/* navbar start */}

          <div
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "30px",
              background: "black",
              fontSize: "20px",
              marginTop: "17px",
              zIndex: 11,
            }}
          >
            <div className="navsec_back_arrow">
              <img
                src={images.back}
                // className="nav_back_btn"
                alt=""
                onClick={() => navigate("/")}
              />
            </div>

            <p className="addhar_headind">Complete your eKYC</p>
          </div>

          {/* navbar end */}
          <div>
            <br />
            <center>
              <p
                style={{
                  fontfamily: "var(--font-400)",
                  color: "white",
                  fontSize: "14px",
                  opacity: "0.8",
                  width: "90%",
                  marginTop: "22px",
                }}
              >
                Aadhaar eKYC is crucial for activating user accounts on our
                aucemo.club platform due to its secure and efficient identity
                verification process.
              </p>
              <button
                onClick={() => {
                  setIsModalVisible(true);
                  setCusmsg(
                    "Aadhaar eKYC is crucial for activating user accounts on our aucemo.club platform due to its secure and efficient identity verification process. By leveraging Aadhaar eKYC, we ensure a streamlined and trustworthy registration experience, validating users' identities swiftly and accurately. This not only enhances security measures but also expedites the account activation process, offering a seamless access experience to our platform's valuable services. Without completing eKYC, user accounts cannot be activated, prioritizing data security and authenticity within our platform."
                  );
                }}
                style={{
                  border: "1px solid white",
                  fontFamily: "var(--font-400)",
                  color: "white",
                  fontSize: "12px",
                  borderRadius: "18px",
                  padding: "6px 12px",
                  marginTop: "10px",
                }}
              >
                Read More
              </button>
            </center>
            <br />
            {/* <div className="aadharcard_div">
              <img src={images.aadharcard} className="aadharcard_img" alt="" />
              <input
                // type="text"
                // placeholder="Enter Aadhaar Number"
                // className={`aadharcard_input ${isValidAadhar ? "" : "invalid"}`}
                // value={textInputValue}
                // onChange={(e) => handleTextInputChange(e.target.value)}

                type="text"
                placeholder="Enter Aadhaar Number"
                className={`aadharcard_input ${isValidAadhar ? "" : "invalid"}`}
                value={textInputValue}
                onChange={(e) => handleTextInputChange(e.target.value)}
              />
            </div> */}

            {/* aadhaar card number start */}

            <div className="addhar_no_main_wrapp">
              {/* <img src={getcapchaImg} className="aadhar_capcha_img" alt="" /> */}
              <p
                style={{
                  fontSize: 12,
                  fontFamily: "var(--font-400)",
                  color: "#fff",
                  marginTop: "0px",
                }}
              >
                Enter Aadhaar Number
              </p>
              <input
                style={{
                  background: "#161616",
                  borderRadius: "6px",
                  padding: "12px 16px",
                }}
                onKeyPress={handleKeyPress}
                className="form_input_box"
                // placeholder="Enter your Aadhaar number"
                placeholderTextColor="gray"
                placeholder="Please Enter aadhaar card number"
                value={textInputValue}
                onChange={(e) => formatAadhaarNumber1(e.target.value)}
                // type="number"
                inputMode="numeric"
              />
            </div>

            {/* aadhaar card number end */}

            {/* capcha start */}

            <div className="addhar_capcha_main_wrapp">
              <p
                style={{
                  fontSize: 12,
                  fontFamily: "var(--font-400)",
                  color: "#fff",
                  // marginTop: 30,
                }}
              >
                Enter the Character you see
              </p>
              <div className="aadhaar_capcha_wrapp">
                <img src={getcapchaImg} className="aadhar_capcha_img" alt="" />
                <button onClick={GetCapcha}>
                  <img
                    src={images.re_capcha_icon}
                    style={{
                      width: "35px",
                      height: "35px",
                      objectFit: "contain",
                    }}
                    alt=""
                  />
                </button>
              </div>
              <input
                // style={{
                //   width: "80%",
                //   marginBottom: "0px",
                // }}
                style={{
                  // background: "#161616",
                  borderRadius: "6px",
                  padding: "12px 16px",
                }}
                className="form_input_box"
                placeholder="Enter Capcha code"
                placeholderTextColor="gray"
                // value={value}
                onChange={(e) => setCapchaCode(e.target.value)}
              />
            </div>

            {/* capcha end */}
          </div>
          <div style={{ textAlign: "center" }}>
            <button
              className="aadhaar_smt_btn"
              onClick={() => {
                updateText();
              }}
            >
              Submit
            </button>
          </div>
        </div>
      )}

      {isModalVisible && (
        // <div className="modal_login">
        //   <div className="modal-content_login">
        //     <div className="modal_div1">
        //       <img className="forgot_main" src={images.logo} alt="" />
        //     </div>
        //     <div className="modal_div">
        //       <b>{""}</b>
        //       <br />
        //       <p
        //         className="modal-message"
        //         style={{ fontFamily: "var(--font-400)" }}
        //       >
        //         {" "}
        //         {cusmsg}
        //       </p>
        //       <br />
        //     </div>
        //     <hr className="hr_forgot" />
        //     <br />
        //     <div className="modal_div">
        //       <button
        //         className="close_forgot"
        //         onClick={() => {
        //           setIsModalVisible(false);
        //         }}
        //       >
        //         Okay
        //       </button>
        //     </div>
        //   </div>
        // </div>
        // <NewModal
        //   title=""
        //   msg={cusmsg}
        //   setShowModal={openmodal}
        //   closemodal={closemodal}
        // />

        <V2Modal
          canclebtn={false}
          title=""
          msg={cusmsg}
          setShowModal={setIsModalVisible}
          okfunction={closemodal}
        />
      )}
    </div>
  );
};

export default Aadharcard;
