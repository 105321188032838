import React, { useState } from "react";
import "./NewProtectAsset.css";
import { FaAngleLeft } from "react-icons/fa6";
import images from "../../constants/images";
import { useHomeContext } from "../../context/home_context";
import axios, { HttpStatusCode } from "axios";
import qs from "qs";
import {
  AssetLink,
  add_user_pending_bond_step_1,
  add_user_pending_bond_step_10,
  add_user_pending_bond_step_11,
  add_user_pending_bond_step_12,
  add_user_pending_bond_step_13,
  add_user_pending_bond_step_2,
  add_user_pending_bond_step_3,
  add_user_pending_bond_step_4,
  add_user_pending_bond_step_5,
  add_user_pending_bond_step_6,
  add_user_pending_bond_step_7,
  add_user_pending_bond_step_8,
  add_user_pending_bond_step_9,
  create_bond,
  delete_user_bond_media_link,
  edit_profile,
  get_all_club_2,
  get_all_interest,
  get_legacy_milestone,
  get_manager_all_user,
  get_user_profile_video,
  get_user_year_list,
  getall_user,
} from "../../utils/Constant";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NewLoader from "../../common/newloder/NewLoader";
import { useEffect } from "react";
import Webcam from "react-webcam";
import { useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import RecordRTC from "recordrtc";
import moment from "moment";

const NewEditProtectAsset = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const recorderRef = useRef(null);
  const YesToatalPage = 16;
  const userId = localStorage.getItem("user_id");
  const [isLoading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [uploadPercentage, setUploadPercentage] = useState(0);

  const [step, setstep] = useState(0);

  // no states start
  const [nocurrentPage, setNoCurrentPage] = useState(1);
  const [creatormodal, setCreatormodal] = useState(false);
  const [getcreatorname, setGetcreatorname] = useState("");
  const [getcreatorid, setGetcreatorid] = useState("");
  const NoToatalPage = 3;
  // no states end

  // creator not platform states start
  const [notplatformCurrentPage, setNotPlatformCurrentPage] = useState(1);
  const NotPlatformToatalPage = 3;
  const [getcreatornameText, setGetcreatornameText] = useState("");
  const [getcreatornameEmail, setGetcreatornameEmail] = useState("");
  // creator not platform states end

  //   yes no states start
  const [getselectedBtnIndex, setselectedBtnIndex] = useState(1);
  const [getpendingBondId, setPendingBondId] = useState("");
  //   yes no states end

  // step 2 states start
  const [getownerName, setOwnerName] = useState("");
  const [getownerId, setOwnerId] = useState("");
  const [getproductname, setproductname] = useState("");

  const [getphysicalOwnerName, setPhysicalOwnerName] = useState("");
  const [getphysicalAddress, setPhysicalAddress] = useState("");

  // media links
  const [getcreatornameContactNo, setGetcreatornameContactNo] = useState(null);
  const [inputValues, setInputValues] = useState([""]);
  const [inputValues1, setInputValues1] = useState([]);

  // step 2 states end

  // des states start
  const [getProductDes, setProductDes] = useState("");
  const [getProductStory, setProductStory] = useState("");
  const [getProductHighlight, setProductHighlight] = useState("");
  const [gethashtags, setHashtags] = useState("");
  // des states end

  //   interests start
  const [getallInterest, setAllInterest] = useState([]);
  const [selectedInterests, setSelectedInterests] = useState([]);
  const [selectedIds, setSelectedIds] = useState("");
  //   interests end

  // creator video start
  const [recording, setRecording] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [getbond_video_im, setbond_video_im] = useState("");
  const [getbondlink, setbondlink] = useState("");
  const [getrecordedvideo, setrecordedvideo] = useState(false);
  const [recordingTime, setRecordingTime] = useState(60);
  const [getuserVid2, setUserVideo2] = useState("");
  const [getconditionvideo, setconditionvideo] = useState(false);
  const [isRecordingstatus, setRecordingstatus] = useState(false);
  const [isModalVisiblevideo, setIsModalVisiblevideo] = useState(false);
  const [getpreviewVideoModal2, setPreviewVideoModal2] = useState(false);
  const [getvideo, setVideo] = useState("");
  // creator video end

  // attach videp states start
  const [getattechVideoModal, setAttechVideoModal] = useState(false);
  const [getvideoArray, setVideoArray] = useState([]);
  const [getvideoId, setVideoId] = useState("");
  const [getpreviewVideoModal, setPreviewVideoModal] = useState(false);
  const [getuserVid, setUserVideo] = useState("");
  const [linkvalid, setvalid] = useState(false);
  const [media_link, setmedia_link] = useState([]);

  // attach videp states end

  // select min 1 image functionality start
  const [selectedImages, setSelectedImages] = useState(null);
  const [selectedauthorFileURL, setSelectedAuthorFileURL] = useState("");
  const [getowner_proof_im, setowner_proof_im] = useState("");
  const [selectedImagesMin1Img, setSelectedImagesMin1Img] = useState([]);
  const [selectedImagesMin1Imgpreview, setSelectedImagesMin1Imgpreview] =
    useState([]);
  const [selectedImagesAndVidio, setSelectedImagesAndVidio] = useState([]);
  const [selectedImagesAndVidiopreview, setSelectedImagesAndVidiopreview] =
    useState([]);
  // select min 1 image functionality end

  // aution states start
  const [selectedAuction, setSelectedAuction] = useState(0);
  const [getminimumBid, setMinimumBid] = useState(null);
  const [getauctionDurtion, setAuctoinDuration] = useState(null);
  const [getProductValue, setProductValue] = useState(null);
  // aution states end

  // global execlusive states start
  const [selectedGlob, setSelectedGlob] = useState(1);
  const [getglobModalMsg, setGlobModalMsg] = useState(
    "Exclusive Commitment: Dear Asset Uploader, Thank you for choosing aucemo.club as your platform to create digital assets of your asset exclusively. We are excited to collaborate with you on this journey. Here are the terms for your exclusive commitment: Platform Fee: aucemo.club will take only 10 Aucemo of this particular asset as the platform fee. This ensures that you retain a significant portion of your Aucemo for potential sales. Royalty for Life: As a token of appreciation for your exclusive commitment, you will receive a 10% royalty for life from the sales of the Aucemo. This means that for every Aucemo sold, you will earn a share of the revenue, providing you with ongoing passive income. We value your trust in aucemo.club, and we are committed to helping you maximize the potential of your asset through our platform. Let's work together to create a thriving marketplace for your Aucemo! Best regards"
  );
  // global execlusive states end

  // manager states start
  const [getallmanager, setallmanager] = useState([]);
  const [getsearchManagerData, setSearchManagerData] = useState([]);
  // manager states end

  // user gift states start
  const [getavailableBond, setAvailableBond] = useState(90);
  const [getsharenfbModal, setSharenfbModal] = useState(false);
  const [getisUserAndClub, setIsUserAndClub] = useState(0);
  const [getshareOwnerModal, setShareOwnerModal] = useState(false);
  const [getalluser, setalluser] = useState([]);
  const [data_filter_data, setdata_filter_data] = useState([]);
  const [getthumbImg, setthumbImg] = useState("");
  const [getuserThumbImg, setUserThumbImg] = useState("");

  const [getdonationOwnerName, setDonationOwnerName] = useState("");
  const [getnoOfBond, setNoOfBond] = useState(null);
  const [
    getnumber_of_bonds_created_for_share,
    setnumber_of_bonds_created_for_share,
  ] = useState([]);
  const [
    getnumber_of_bonds_created_for_share_name,
    setnumber_of_bonds_created_for_share_name,
  ] = useState([]);
  const [
    getnumber_of_bonds_created_for_share_id,
    setnumber_of_bonds_created_for_share_id,
  ] = useState([]);
  const [getdonationOwnerId, setDonationOwnerId] = useState("");
  // user gift states end

  // club gift states start
  const [getshareClubModal, setShareClubModal] = useState(false);
  const [getnoOfBondClub, setNoOfBondClub] = useState(null);
  const [getdonationClubName, setDonationClubName] = useState("");
  const [getdonationClubId, setDonationClubId] = useState("");
  const [getsearchallclubs, setSearchallClubs] = useState([]);
  const [getallclubs, setallClubs] = useState([]);

  const [
    getclub_number_of_bonds_created_for_share,
    setclub_number_of_bonds_created_for_share,
  ] = useState([]);
  const [
    getclub_number_of_bonds_created_for_share_name,
    setclub_number_of_bonds_created_for_share_name,
  ] = useState([]);
  const [
    getclub_number_of_bonds_created_for_share_id,
    setclub_number_of_bonds_created_for_share_id,
  ] = useState([]);
  // club gift states end

  // qrhunt share states start
  const [getdonetQrhunt, setDonetQrhunt] = useState(0);
  const ddd = getavailableBond - getdonetQrhunt;
  // qrhunt share states end

  // ----------- Creator Not On Platform states start ----------------
  const [tick, settick] = useState(false);

  // ----------- Creator Not On Platform states end ------------------

  useEffect(() => {
    console.log(
      "location.state.item123",
      JSON.stringify(location.state.item, null, 2)
    );
    const data = location.state.item;

    console.log("12223", data);
    Getpandingbond(data.pending_bond_id);

    const selectedInterests = data.interest_data.map((item) => ({
      ...item,
      isHighlighted: true,
    }));

    const selectedIds = selectedInterests
      .map((interest) => interest.id)
      .join(", ");
    const interestData = data.interest_data
      ? data.interest_data.map((interest) => ({
          id: interest.interest_id,
          name: interest.interest_name,
        }))
      : [];
    const mediaLinks = data.media_link
      ? data.media_link.map((link) => link.social_media_link)
      : data.media_link.length == 0
      ? [""]
      : [""];
    setselectedBtnIndex(data.is_owner);
    setproductname(data.product_name);
    setPhysicalOwnerName(data.physical_owner_name);
    setPhysicalAddress(data.physical_asset_address);
    setOwnerName(data.owner_name);
    setOwnerId(data.owner_id);
    setSelectedInterests(interestData);
    // setAllInterests2(data.interest_data);
    setSelectedIds(interestData.map((interest) => interest.id).join(", "));
    // interestData.map((item) => {
    //   handleInterestSelection(item);
    // });
    setYear(
      data.date_of_creation == null || data.date_of_creation == ""
        ? currentYear
        : moment(data.date_of_creation).format("YYYY")
    );
    setMonth(
      data.date_of_creation == null || data.date_of_creation == ""
        ? ""
        : data.date_of_creation.length == 4
        ? ""
        : moment(data.date_of_creation).format("MM")
    );
    setDay(
      data.date_of_creation == null || data.date_of_creation == ""
        ? ""
        : data.date_of_creation.length == 4
        ? ""
        : moment(data.date_of_creation).format("DD")
    );
    setCurrentPage(data.step + 1);
    setstep(data.step);
    setPendingBondId(data.pending_bond_id);
    setProductDes(data.description);
    setProductStory(data.product_history);
    setProductHighlight(data.product_highlights);
    setHashtags(data.product_hashtag == null ? "" : data.product_hashtag);
    setSelectedVideos([data.bond_video]);
    setbondlink(data.bond_video);
    setbond_video_im(data.bond_video_im);
    setSelectedImages([data.owner_proof]);
    setowner_proof_im(data.owner_proof_im);
    setSelectedAuthorFileURL(data.owner_proof);
    // setSelectedImagesMin1Imgpreview(data.bond_img_video);
    setSelectedImagesAndVidiopreview(data.product_media_upload);
    setmedia_link(data.media_link);
    setVideoId(data.user_video_id);
    setUserVideo(data.user_profile_video);
    if (location.state && location.state.legacy_year) {
      setSelectedYear(location.state.legacy_year);
      // alert(location.state.legacy_year);
    } else {
      selectmilestoneid(data.user_milestone_id);
      setSelectedYear(data.user_milestone_year);
    }
    if (data.user_thumbnail && data.user_thumbnail !== "") {
      setUserThumbImg(data.user_thumbnail);
    }

    if (data.bond_thumbnail && data.bond_thumbnail !== "") {
      setthumbImg(data.bond_thumbnail);
    }
  }, []);

  // ---------------------- share functionality start ---------------------

  const handleShareUserAndClubChange = (event) => {
    setIsUserAndClub(event.target.value);

    // new change
    // setDonationOwnerName('');
    // setDonationClubName('');
    // new change

    console.log("event.target.value", event.target.value);
    // setnumber_of_bonds_created_for_share([]);
    // {
    //   (selectedCreator == 1 || selectedCreator == 3) && GetEditProfileApi();
    // }
  };

  const Search_user = (value) => {
    const newData = getalluser.filter(function (item) {
      const employee = item.label ? item.label.toUpperCase() : "".toUpperCase();
      const number = item.phone ? item.phone.toUpperCase() : "".toUpperCase();
      const email = item.email ? item.email.toUpperCase() : "".toUpperCase();

      const textData = value.toUpperCase();
      return (
        employee.indexOf(textData) > -1 ||
        number.indexOf(textData) > -1 ||
        email.indexOf(textData) > -1
      );
    });
    setdata_filter_data(newData);
    console.log("asvsvsds", newData);
  };

  const Search_clubs = (value) => {
    const newData = getsearchallclubs.filter(function (item) {
      const employee = item.club_name
        ? item.club_name.toUpperCase()
        : "".toUpperCase();
      const number = item.phone_number
        ? item.phone_number.toUpperCase()
        : "".toUpperCase();

      // const email = item.email ? item.email.toUpperCase() : "".toUpperCase();

      const textData = value.toUpperCase();
      return employee.indexOf(textData) > -1 || number.indexOf(textData) > -1;
    });
    // console.log("newData", newData);
    setallClubs(newData);
    console.log("asvsvsds", newData);
  };

  // share nfb list delete btn start

  const handleShareDelete = (item, index) => {
    setAvailableBond(
      (prevAvailableNoOfBond) => prevAvailableNoOfBond + parseInt(item)
    );

    const updatedDataName = [...getnumber_of_bonds_created_for_share_name];
    updatedDataName.splice(index, 1);
    setnumber_of_bonds_created_for_share_name(updatedDataName);

    const updatedDataShare = [...getnumber_of_bonds_created_for_share];
    updatedDataShare.splice(index, 1);
    setnumber_of_bonds_created_for_share(updatedDataShare);

    const updatedDataId = [...getnumber_of_bonds_created_for_share_id];
    updatedDataId.splice(index, 1);
    setnumber_of_bonds_created_for_share_id(updatedDataId);
  };

  // share nfb list delete btn end

  // share club nfb list delete btn start

  const handleClubShareDelete = (item, index) => {
    setAvailableBond(
      (prevAvailableNoOfBond) => prevAvailableNoOfBond + parseInt(item)
    );

    const updatedDataName = [...getclub_number_of_bonds_created_for_share_name];
    updatedDataName.splice(index, 1);
    setclub_number_of_bonds_created_for_share_name(updatedDataName);

    const updatedDataShare = [...getclub_number_of_bonds_created_for_share];
    updatedDataShare.splice(index, 1);
    setclub_number_of_bonds_created_for_share(updatedDataShare);

    const updatedDataId = [...getclub_number_of_bonds_created_for_share_id];
    updatedDataId.splice(index, 1);
    setclub_number_of_bonds_created_for_share_id(updatedDataId);
  };

  // share club nfb list delete btn end

  // ------------------ share functionality end ---------------------------

  // asset name handlechange start
  const handleAssetsChange = (e) => {
    const inputValue = e.target.value;
    const words = inputValue.trim().split(/\s+/); // Split input into words

    const containsEmoji =
      /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}]/u.test(
        inputValue
      );

    // Check if the length of the input is less than or equal to 10 and does not contain emojis
    if (words.length <= 10 && !containsEmoji) {
      setproductname(inputValue);
    }
  };

  // physical owner name
  const handlePhysicalOwnerNameChange = (e) => {
    const inputValue = e.target.value;
    const words = inputValue.trim().split(/\s+/); // Split input into words

    const containsEmoji =
      /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}]/u.test(
        inputValue
      );

    // Check if the length of the input is less than or equal to 10 and does not contain emojis
    if (words.length <= 10 && !containsEmoji) {
      setPhysicalOwnerName(inputValue);
    }
  };

  // physical address
  const handlePhysicalAddressChange = (e) => {
    const inputValue = e.target.value;
    const words = inputValue.trim().split(/\s+/); // Split input into words

    const containsEmoji =
      /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}]/u.test(
        inputValue
      );

    // Check if the length of the input is less than or equal to 10 and does not contain emojis
    if (words.length <= 10 && !containsEmoji) {
      setPhysicalAddress(inputValue);
    }
  };

  // asset name handlechange end

  // hashtages functioanlity start
  const handleHashtagsChange = (e) => {
    const data = e.target.value;

    const updatedValue = data.replace(/\s+/g, "#");
    setHashtags(updatedValue);

    let formattedText = data.replace(/(\s|^)(\w+)/g, "$1#$2");
    formattedText = formattedText.replace(/##/g, "#"); // Remove duplicate hashtags

    // Limit to 10 words
    setHashtags(formattedText);
  };
  // hashtages functioanlity end

  // media links start

  const validateSocialMediaLink = (link) => {
    // Validation logic for Instagram
    if (link.startsWith("https://") || link.startsWith("http://")) {
      return true;
    }
    if (link.startsWith("Https://") || link.startsWith("Http://")) {
      return true;
    }
    if (link.startsWith("https://") || link.startsWith("http://")) {
      return true;
    }

    // Validation logic for YouTube
    if (link.startsWith("https://") || link.startsWith("http://")) {
      return true;
    }

    // Validation logic for LinkedIn
    if (link.startsWith("https://") || link.startsWith("http://")) {
      return true;
    }

    // Validation logic for Twitter
    if (link.startsWith("https://") || link.startsWith("http://")) {
      return true;
    }

    return false; // If none of the above conditions match, consider it an invalid link
  };
  const handleAddValue = () => {
    const isValid = inputValues.every(validateSocialMediaLink);

    if (isValid) {
      setvalid(true);
      setInputValues([...inputValues, ""]); // Add a new empty input field
    } else {
      // Handle invalid input
      toast.error("Please enter valid social media links", {
        theme: "colored",
      });
    }
  };
  const handleInputChange = (text, index) => {
    const updatedInputValues = [...inputValues];
    updatedInputValues[index] = text;
    console.log("text", text);
    setInputValues(updatedInputValues);
  };

  const handleRemoveValue = () => {
    if (inputValues.length > 1) {
      const updatedInputValues = [...inputValues];
      updatedInputValues.pop(); // Remove the last input field
      setInputValues(updatedInputValues);
    }
  };

  const handlePhoneNumberChange = (event) => {
    const value = event.target.value;
    const numericValue = value.replace(/\D/g, ""); // Remove non-digit characters

    if (numericValue.length <= 10) {
      setGetcreatornameContactNo(numericValue);
      // setErrorMessage("");
    } else {
      // If more than 10 digits, trim to the first 10 digits
      const trimmedValue = numericValue.slice(0, 10);
      setGetcreatornameContactNo(trimmedValue);
      console.log("trimmedValue", trimmedValue);
      // setphonedisable()
      // alert("Please enter up to 10 digits.");
      // setcusmsg("Please valid phone number.");
      // setShowModal(true);
    }
  };

  const deleteMediaLink = async (media_link_id) => {
    setLoading(true);
    const formdata = new FormData();
    formdata.append("pending_bond_social_media_link_id", media_link_id);
    await axios
      .post(delete_user_bond_media_link, formdata)
      .then((response) => {
        setLoading(false);
        if (response.data.status === 1) {
          // toast.success(response.data.message, { theme: "colored" });
          const updatedInputValues = media_link.filter(
            (item) => item.pending_bond_social_media_link_id !== media_link_id
          );
          setmedia_link(updatedInputValues);
        } else {
          toast.error(response.data.message, { theme: "colored" });
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  // media links end

  // asset value keypress satart

  const handleKeyPress = (e) => {
    // Check if the pressed key is a dot
    if (!(e.charCode >= 48 && e.charCode <= 57) && e.charCode !== 8) {
      e.preventDefault();
    }
  };

  // asset value keypress end

  // intrest api start
  const fetchInterests = async () => {
    setLoading(true);
    await axios
      .get(get_all_interest)
      .then((response) => {
        setLoading(false);
        if (response.data.status === 1) {
          setAllInterest(response.data.result);
        } else {
          toast.error(response.data.message, { theme: "colored" });
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  //////
  const delete_atlistoneimg = async (pending_bond_img_id) => {
    setLoading(true);
    const formdata = new FormData();
    formdata.append("pending_bond_img_id", pending_bond_img_id);
    await axios
      .post("https://www.ravi.host/api/delete_pending_bond_image", formdata, {})
      .then((response) => {
        setLoading(false);
        if (response.data.status === 1) {
          // toast.success(response.data.message, { theme: "colored" });
        } else {
          toast.error(response.data.message, { theme: "colored" });
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };
  const delete_mediaimg = async (pending_media_id) => {
    setLoading(true);
    const formdata = new FormData();
    formdata.append("pending_media_id", pending_media_id);
    await axios
      .post(
        "https://www.ravi.host/api/delete_pending_bond_media_upload",
        formdata,
        {}
      )
      .then((response) => {
        setLoading(false);
        if (response.data.status === 1) {
          // toast.success(response.data.message, { theme: "colored" });
        } else {
          toast.error(response.data.message, { theme: "colored" });
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const handleInterestSelection = (item) => {
    const updatedSelectedInterests = [...selectedInterests];
    let updatedSelectedIds = selectedIds;

    if (isInterestSelected(item)) {
      const index = updatedSelectedInterests.findIndex(
        (interest) => interest.id === item.id
      );
      updatedSelectedInterests.splice(index, 1);
    } else {
      updatedSelectedInterests.push(item);
    }

    updatedSelectedIds = updatedSelectedInterests
      .map((interest) => interest.id)
      .join(", ");

    setSelectedInterests(updatedSelectedInterests);
    setSelectedIds(updatedSelectedIds);
  };

  const isInterestSelected = (item) => {
    return selectedInterests.some((interest) => interest.id === item.id);
  };
  // intrest api end

  // datepicker functionality start
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;
  const currentDate = new Date().getDate();
  const [year, setYear] = useState(currentYear);
  const [month, setMonth] = useState("");
  const [day, setDay] = useState("");

  const daysInMonth = (year, month) => {
    return new Date(year, month, 0).getDate();
  };

  const handleYearChange = (e) => {
    const selectedYear = parseInt(e.target.value);
    if (selectedYear <= currentYear) {
      setYear(selectedYear);
      if (selectedYear === currentYear) {
        setMonth(currentMonth);
        setDay(currentDate);
      }
    }
    // Reset month and day if the year changes
    setMonth("");
    setDay("");
  };

  const handleMonthChange = (e) => {
    setMonth(e.target.value);
    // Reset day if the month changes
    setDay("");
  };

  const handleDayChange = (e) => {
    setDay(e.target.value);
  };

  const formatValue = (value) => {
    return value < 10 ? `0${value}` : `${value}`;
  };

  const days = Array.from(
    { length: daysInMonth(year, month) },
    (_, i) => i + 1
  );

  // datepicker functionality end

  // creator video functionality start
  const handleVideoChange = async (e) => {
    const files = Array.from(e.target.files);

    // Assuming you have a minimum and maximum duration in seconds
    const minDuration = 20; // Minimum duration in seconds
    const maxDuration = 60; // Maximum duration in seconds

    // Validate each video file
    for (const file of files) {
      const video = document.createElement("video");
      video.src = URL.createObjectURL(file);

      // Wait for the video metadata to load
      await new Promise((resolve) => {
        video.onloadedmetadata = resolve;
      });

      const duration = video.duration;

      if (duration < minDuration || duration > maxDuration) {
        // Video duration is outside the allowed range
        toast.error(
          `Video duration should be between ${minDuration}s and ${maxDuration}s.`,
          { theme: "colored" }
        );
      } else {
        // Video is within the allowed range, add it to the state
        setSelectedVideos([...files]);
      }

      // Clean up the video element
      video.remove();
    }
  };

  const handleVideoDelete = () => {
    // const newVideos = [...selectedVideos];
    // newVideos.splice(index, 1);

    setSelectedVideos([]);
    // setSelectedVideos(newVideos);
    setRecordedChunks([]);
    setPreviewVideoModal2(false);
  };

  // --------------------- recording functions start --------------------------------

  // stop recording function
  const StopRecording = () => {
    setthumbImg("");
    setRecording(false);
    setRecordingstatus(false);
    setbondlink("");
    const minDuration = 30;
    const maxDuration = 60;
    if (recorderRef.current) {
      recorderRef.current.stopRecording(() => {
        const blob = recorderRef.current.getBlob();

        console.log("blob---->", blob.length);
        // Use the current value of recordingTime from the useEffect
        if (60 - recordingTime < minDuration) {
          // Display an alert or take other actions for insufficient duration
          toast.error(
            `Video duration should be between ${minDuration}s and ${maxDuration}s.`,
            { theme: "colored" }
          );
          setRecordedChunks([]);
          setSelectedVideos([]);
        } else {
          // Continue with handling the recorded video
          setRecordedChunks([blob]);
          setSelectedVideos([blob]);
          setRecordingTime(60);
          setIsModalVisiblevideo(false);
          setconditionvideo(true);
          // Stop the media stream to release the camera
          const stream = videoRef.current.srcObject;
          if (stream) {
            const tracks = stream.getTracks();
            tracks.forEach((track) => track.stop());
          }
        }
      });
    }
  };

  // stop recording function2
  // const stopRecording1 = () => {
  //   setRecording(false);
  //   const minDuration = 30;
  //   const maxDuration = 180;

  //   if (recorderRef.current) {
  //     recorderRef.current.stopRecording(() => {
  //       const blob = recorderRef.current.getBlob();
  //       const stream = videoRef.current.srcObject;
  //       if (stream) {
  //         const tracks = stream.getTracks();
  //         tracks.forEach((track) => track.stop());
  //       }
  //       setRecordedChunks([blob]);
  //       setSelectedVideos([blob]);
  //       setRecordingTime(180);
  //       setIsModalVisiblevideo(false);
  //       // }
  //     });
  //   }
  // };

  // start recording

  const startRecording = () => {
    setthumbImg("");
    setRecordingstatus(true);
    setRecordingTime(60);
    if (videoRef.current) {
      navigator.mediaDevices
        .getUserMedia({
          video: true,
          audio: {
            echoCancellation: true,
            noiseSuppression: true,
            autoGainControl: true,
          },
        })
        .then((stream) => {
          const recorder = RecordRTC(stream, {
            type: "video",
            mimeType: "video/webm",
          });
          recorder.startRecording();
          recorderRef.current = recorder;
          setRecording(true);
          setrecordedvideo(false);
          videoRef.current.srcObject = stream;
        })
        .catch((error) => {
          console.error("Error accessing media devices:", error);
          // Handle errors related to accessing media devices
          if (error.name === "NotAllowedError") {
            // Alert the user that they need to allow camera and microphone permissions
            // alert(
            //   "Please allow camera and microphone access to record a video."
            // );
            toast.error(
              "Please allow camera and microphone access to record a video.",
              { theme: "colored" }
            );
          } else if (error.name === "NotFoundError") {
            // Alert the user that no camera or microphone was found
            // alert("No camera or microphone found.");
            toast.error("No camera or microphone found.", { theme: "colored" });
          } else {
            // Alert for generic errors
            // alert("Error accessing media devices. Please try again later.");
            toast.error(
              "Error accessing media devices. Please try again later.",
              { theme: "colored" }
            );
          }
        });
    }
  };

  // --------------------- recording functions end ----------------------------------

  // useEffect(() => {
  //   let timerInterval;
  //   const minDuration = 30;

  //   if (isRecordingstatus) {
  //     timerInterval = setInterval(() => {
  //       setRecordingTime((prevTime) => {
  //         const newTime = prevTime - 1;

  //         // Check if the recording time is less than the minimum duration
  //         if (newTime < minDuration) {
  //           // Display an error or take other actions
  //           console.error(
  //             `Recording time should be at least ${minDuration} seconds.`
  //           );
  //         }

  //         // Check if the recording time reaches 1 second
  //         if (newTime <= 1) {
  //           StopRecording(); // Stop recording or take other actions
  //         }

  //         return newTime;
  //       });
  //     }, 1000);
  //   }

  //   return () => {
  //     clearInterval(timerInterval);
  //   };
  // }, [isRecordingstatus]);

  useEffect(() => {
    let timerInterval;

    if (isRecordingstatus && recordingTime > 0) {
      timerInterval = setInterval(() => {
        setRecordingTime((prevTime) => {
          const newTime = prevTime - 1;
          // Check if the recording time reaches 00:00
          if (newTime <= 0) {
            StopRecording(); // Stop recording when time is 00:00
            return 0; // Set time to 0
          }
          return newTime;
        });
      }, 1000);
    } else {
      // If not recording or time is 00:00, clear the interval
      clearInterval(timerInterval);
    }

    // Return cleanup function to clear the interval when component unmounts or when isRecordingstatus or recordingTime changes
    return () => clearInterval(timerInterval);
  }, [isRecordingstatus, recordingTime]);

  const resetCountdown = () => {
    setIsModalVisiblevideo(false);
    setRecordingTime(60);
  };

  const showimage = () => {
    StopRecording();
    setRecordedChunks([]);
    setSelectedVideos([]);
    resetCountdown();
    console.log(videoRef.current);
    // Stop the media stream to release the camera
    const stream = videoRef.current.srcObject;
    if (stream) {
      const tracks = stream.getTracks();
      tracks.forEach((track) => track.stop());
    }
  };

  // creator video functionality end

  // ------------ select minimum 1 image functionality start -----------------

  useEffect(() => {
    console.log("selectedImagesMin1Img", selectedImagesMin1Img);
    if (selectedImagesMin1Img.length > 10) {
      setSelectedImagesMin1Img([]);
      // setErrorMessage("You can select maximum of 10 images.");
      // setShowModal(true);
      toast.error("You can select maximum of 10 images.", { theme: "colored" });
    } else {
    }
  }, [selectedImagesMin1Img]);

  const handleImageChangeMin1Img = (e) => {
    const files = Array.from(e.target.files);
    console.log("files:", files.length);
    if (files.length == 11) {
    } else {
      setSelectedImagesMin1Img((prevSelectedImages) => [
        ...prevSelectedImages,
        ...files,
      ]);
    }
    // setImageFiles(e.target.files[0]);
  };

  const handleImageDelete2 = (index) => {
    const newImages = [...selectedImagesMin1Img];
    newImages.splice(index, 1);
    setSelectedImagesMin1Img(newImages);
  };
  const handleImageDelete2preview = (index) => {
    const newImages = [...selectedImagesMin1Imgpreview];
    newImages.splice(index, 1);
    setSelectedImagesMin1Imgpreview(newImages);
  };

  // ------------ select minimum 1 image functionality end -----------------

  // ------- auth file functionality start ----------
  const [getpdfcon, setpdfcon] = useState(false);
  const handleImageChange = (e) => {
    const file = e.target.files[0]; // Get the first file from the input
    if (file) {
      if (file.type === "application/pdf") {
        setpdfcon(true);
      } else if (file.type.startsWith("image/")) {
        setpdfcon(false);
      }
      setSelectedImages(file);
      setowner_proof_im("");
      console.log("reader.result", file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedAuthorFileURL(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageDelete = (index) => {
    setSelectedImages(null);
  };

  // ------- auth file functionality end ----------

  // ------- media upload functionality start ----------
  const handleImageAndVideoChange = (e) => {
    const files = Array.from(e.target.files);
    // setImageFiles(e.target.files[0]);
    setSelectedImagesAndVidio((prevSelectedImages) => [
      ...prevSelectedImages,
      ...files,
    ]);
  };

  const handleImageAndVideoDelete = (index) => {
    const newImages = [...selectedImagesAndVidio];
    newImages.splice(index, 1);
    setSelectedImagesAndVidio(newImages);
  };
  const handleImageAndVideoDeletepre = (index) => {
    const newImages = [...selectedImagesAndVidiopreview];
    newImages.splice(index, 1);
    setSelectedImagesAndVidiopreview(newImages);
  };

  // ------- media upload functionality end ----------

  // attach video functionality start

  const GetUserVideos = async () => {
    const param = {
      user_id: userId,
    };
    await axios
      .post(get_user_profile_video, qs.stringify(param))
      .then((Response) => {
        console.log("Response", Response);
        if (Response.data.status == 1) {
          setVideoArray(Response.data.result.user_videos);
          if (Response.data.result.user_videos.length === 1) {
            Response.data.result.user_videos &&
              Response.data.result.user_videos.map((i, index) => {
                console.log("sdcdsc----------->", i);
                setVideoId(i.user_video_id);
                setUserVideo(i.user_videos);
                if (i.user_thumbnail && i.user_thumbnail !== "") {
                  setUserThumbImg(i.user_thumbnail);
                }
                // this.setState({
                //   video_index: index,
                //   videosetid: i.user_video_id,
                //   videoset: i.user_videos,
                //   // videomodel: false,
                // });
                // setAttechVideoModal(false);
              });
          }
        }
      })
      .catch((err) => {
        console.log("get_user_profile_video err", err);
      });
  };

  // attach video functionality end

  // aution functionality start
  const handleAuctionOptionChange = (id) => {
    setSelectedAuction(id);
    setMinimumBid(null);
    setAuctoinDuration(null);
    setProductValue(null);
    console.log("setSelectedAuction", id);
  };
  // aution functionality end

  // global execlusive functioanlity start
  const handleGlobOptionChange = (id) => {
    setSelectedGlob(id);
    setnumber_of_bonds_created_for_share([]);
    setclub_number_of_bonds_created_for_share([]);
    console.log("handleGlobOptionChange", id);

    setAvailableBond(id == 1 ? 90 : 80);
    setGlobModalMsg(
      id == 1
        ? "Exclusive Commitment: Dear Asset Uploader, Thank you for choosing aucemo.club as your platform to create digital assets of your asset exclusively. We are excited to collaborate with you on this journey. Here are the terms for your exclusive commitment: Platform Fee: aucemo.club will take only 10 Aucemo of this particular asset as the platform fee. This ensures that you retain a significant portion of your Aucemo for potential sales. Royalty for Life: As a token of appreciation for your exclusive commitment, you will receive a 10% royalty for life from the sales of the Aucemo. This means that for every Aucemo sold, you will earn a share of the revenue, providing you with ongoing passive income. We value your trust in aucemo.club, and we are committed to helping you maximize the potential of your asset through our platform. Let's work together to create a thriving marketplace for your Aucemo! Best regards"
        : "Non-Exclusive Commitment: Dear Asset Uploader, Thank you for considering aucemo.club as a platform to create digital assets of your asset. We appreciate your interest. Here are the terms for your non-exclusive commitment: Platform Fee: aucemo.club will charge 20 Aucemo of the asset as the platform fee. This fee allows us to provide you with a robust platform and comprehensive support. Royalty for Life: As a gesture of our partnership, you will receive a 5% royalty for life from the sales of the Aucemo. This means that for every Aucemo sold, you will earn a share of the revenue, ensuring a continuous stream of passive income. We believe in fostering a mutually beneficial relationship and are excited to have your asset on our platform. Lets collaborate to make your Aucemo a success!"
    );
  };
  // global execlusive functioanlity end

  const Getpandingbond = (pending_bond_id) => {
    const param = {
      pending_bond_id: pending_bond_id,
    };
    console.log("csazca", param);
    axios
      .post(
        "https://www.ravi.host/api/get_single_pending_bond_img",
        qs.stringify(param)
      )
      .then((Response) => {
        console.log("====================================");
        console.log(
          ":Getpandingbonddata",
          JSON.stringify(Response.data.user_pending_bond, null, 2)
        );
        console.log("====================================");
        const data = Response.data.user_pending_bond;
        if (Response.data.status == 1) {
          setSelectedImagesMin1Imgpreview(data[0].bond_img_video);
        } else {
          // setErrorMessage(Response.data.message);
          // setShowModal(true);
          toast.error(Response.data.message, {
            theme: "colored",
          });
        }
      });
  };

  // ------------- yes button validation start ----------------

  const nextPage = () => {
    if (currentPage == 1) {
      setCurrentPage(currentPage + 1);
    } else if (currentPage == 2) {
      Step2Validation();
    } else if (currentPage == 3) {
      Step3Validation();
    } else if (currentPage == 4) {
      Step4Validation();
    } else if (currentPage == 5) {
      Step5Validation();
    } else if (currentPage == 6) {
      Step6Validation();
    } else if (currentPage == 7) {
      Step7Validation();
    } else if (currentPage == 8) {
      Step8Validation();
    } else if (currentPage == 9) {
      Step9Validation();
    } else if (currentPage == 10) {
      Step10Validation();
    } else if (currentPage == 11) {
      Step11Validation();
    } else if (currentPage == 12) {
      Step12Validation();
    } else if (currentPage == 13) {
      Step13Validation();
    } else if (currentPage == 14) {
      Step14Validation();
    } else if (currentPage == 15) {
      Step15Validation();
    } else if (currentPage == 16) {
      CreateYesProductApi();
    }
  };
  const prevPage = () => setCurrentPage(currentPage - 1);

  const Step2Validation = async () => {
    if (getownerName === "") {
      toast.error("Please enter creator name", { theme: "colored" });
    } else if (getproductname === "") {
      toast.error("Please enter Asset name", { theme: "colored" });
    } else {
      let formData = new FormData();
      formData.append("product_name", getproductname);
      formData.append("owner_name", getownerName);
      formData.append("owner_id", getownerId);
      formData.append("is_owner", getselectedBtnIndex);
      formData.append("user_id", userId);
      formData.append("pending_bond_id", getpendingBondId);

      formData.append("physical_owner_name", getphysicalOwnerName);
      formData.append("physical_asset_address", getphysicalAddress);
      // let linktag = "";
      // var demolinktag = 0;
      // for (let index = 0; index < inputValues.length; index++) {
      //   if (inputValues[index]) {
      //     demolinktag = demolinktag + 1;
      //     if (index > 0) {
      //       linktag += ",";
      //     }
      //     linktag += inputValues[index];
      //   }
      // }
      // formData.append("social_media_link", linktag);

      try {
        setLoading(true);
        const response = await CreateYesProductFunction(
          add_user_pending_bond_step_1,
          formData
        );
        console.log("Response 1", response);
        setLoading(false);
        if (response.status == 1) {
          setPendingBondId(response.pending_bond_id);
          setCurrentPage(currentPage + 1);
        }
      } catch (error) {
        console.error("Error", error);
        setLoading(false);
      }
    }
  };

  const Step3Validation = async () => {
    console.log("selectedInterests", selectedInterests);
    if (selectedInterests.length < 1) {
      toast.error("Please Select Category", { theme: "colored" });
    } else {
      let formData = new FormData();
      formData.append("bond_interest_id", selectedIds);
      formData.append("pending_bond_id", getpendingBondId);
      try {
        setLoading(true);
        const response = await CreateYesProductFunction(
          add_user_pending_bond_step_2,
          formData
        );
        console.log("Response 2", response);
        setLoading(false);
        if (response.status == 1) {
          setCurrentPage(currentPage + 1);
          setUploadPercentage(0);
        }
      } catch (error) {
        console.error("Error", error);
        setLoading(false);
      }
    }
  };

  const Step4Validation = async () => {
    if (year == "") {
      toast.error("Please Select Date of creation", { theme: "colored" });
    }
    // else if (selectedYear == null || selectedYear == "") {
    //   toast.error("Please Select a year of Milestone", { theme: "colored" });
    // } else if (getselectmilestoneid == "" || getselectmilestoneid == null) {
    //   toast.error("Please Select your Milestone", { theme: "colored" });
    // }
    else {
      let formData = new FormData();
      if (month == "" && day == "") {
        formData.append("date_of_creation", year);
      } else if (day == "") {
        formData.append("date_of_creation", `${year}-${month}`);
      } else {
        formData.append("date_of_creation", `${year}-${month}-${day}`);
      }
      formData.append("pending_bond_id", getpendingBondId);
      formData.append("user_milestone_id", getselectmilestoneid);

      try {
        setLoading(true);
        const response = await CreateYesProductFunction(
          add_user_pending_bond_step_3,
          formData
        );
        console.log("Response", response);
        setLoading(false);
        if (response.status == 1) {
          setCurrentPage(currentPage + 1);
        }
      } catch (error) {
        console.error("Error", error);
        setLoading(false);
      }
    }
  };

  const Step5Validation = async () => {
    if (
      getProductDes == "" ||
      getProductDes == null ||
      getProductDes == "null"
    ) {
      toast.error("Please enter description", { theme: "colored" });
    } else {
      let formData = new FormData();
      formData.append("description", getProductDes);
      formData.append("pending_bond_id", getpendingBondId);

      try {
        setLoading(true);
        const response = await CreateYesProductFunction(
          add_user_pending_bond_step_4,
          formData
        );
        console.log("Response", response);
        setLoading(false);
        if (response.status == 1) {
          setCurrentPage(currentPage + 1);
        }
      } catch (error) {
        console.error("Error", error);
        setLoading(false);
      }
    }
  };

  const Step6Validation = async () => {
    if (getProductStory == "null") {
      toast.error("Please enter valid story or history", { theme: "colored" });
    } else {
      let formData = new FormData();
      formData.append("product_history", getProductStory);
      formData.append("pending_bond_id", getpendingBondId);

      try {
        setLoading(true);
        const response = await CreateYesProductFunction(
          add_user_pending_bond_step_5,
          formData
        );
        console.log("Response", response);
        setLoading(false);
        if (response.status == 1) {
          setCurrentPage(currentPage + 1);
        }
      } catch (error) {
        console.error("Error", error);
        setLoading(false);
      }
    }
  };

  const Step7Validation = async () => {
    if (getProductHighlight == "null") {
      toast.error("Please enter valid highlights", { theme: "colored" });
    } else {
      let formData = new FormData();
      formData.append("product_highlights", getProductHighlight);
      formData.append("pending_bond_id", getpendingBondId);
      try {
        setLoading(true);
        const response = await CreateYesProductFunction(
          add_user_pending_bond_step_6,
          formData
        );
        console.log("Response", response);
        setLoading(false);
        if (response.status == 1) {
          setCurrentPage(currentPage + 1);
        }
      } catch (error) {
        console.error("Error", error);
        setLoading(false);
      }
    }
  };

  const Step8Validation = async () => {
    let formData = new FormData();
    formData.append("product_hashtag", gethashtags);
    formData.append("pending_bond_id", getpendingBondId);

    try {
      setLoading(true);
      const response = await CreateYesProductFunction(
        add_user_pending_bond_step_7,
        formData
      );
      console.log("Response", response);
      setLoading(false);
      if (response.status == 1) {
        setCurrentPage(currentPage + 1);
      }
    } catch (error) {
      console.error("Error", error);
      setLoading(false);
    }
  };

  const Step9Validation = async () => {
    if (selectedVideos.length <= 0 || selectedVideos == "") {
      toast.error("Please record one asset video", { theme: "colored" });
    } else {
      let formData = new FormData();
      formData.append("is_type", step >= 8 ? "edit" : "");
      if (getconditionvideo == true) {
        selectedVideos.forEach((image, index) => {
          formData.append("bond_video", image);
        });
      } else {
        formData.append("bond_video", getbond_video_im);
      }

      // formData.append("pending_bond_id", 1);
      formData.append("pending_bond_id", getpendingBondId);
      try {
        // const agent = new HttpStatusCode.Agent({ rejectUnauthorized: false });

        setLoading(true);
        const response = await CreateYesProductFunction(
          add_user_pending_bond_step_8,
          // "https://www.ravi.host/api/add_user_pending_bond_step_8",
          formData
          // ,
          // {
          //   onUploadProgress: (progressEvent) => {
          //     const percentage = Math.round(
          //       (progressEvent.loaded * 100) / progressEvent.total
          //     );
          //     console.log("Upload Percentage:", percentage);
          //     // Update the state to show the progress
          //     setUploadPercentage(percentage);
          //   },
          // }
        );
        console.log("Response", response);
        // setLoading(false);
        if (response.status == 1) {
          setLoading(false);

          setCurrentPage(currentPage + 1);
          if (response.thumbnail && response.thumbnail !== "") {
            setthumbImg(response.thumbnail);
          } else {
            setLoading(false);
          }
        }
      } catch (error) {
        console.error("Error", error);
        setLoading(false);
      }
    }
  };

  const Step10Validation = async () => {
    if (selectedauthorFileURL == "") {
      toast.error("Please upload owner file", { theme: "colored" });
    } else {
      let formData = new FormData();
      formData.append("is_type", getowner_proof_im == "" ? "edit" : "");
      // selectedImages.forEach((image, index) => {
      formData.append(
        "proof_file",
        getowner_proof_im == "" ? selectedImages : getowner_proof_im
      );
      // });
      formData.append("pending_bond_id", getpendingBondId);

      try {
        setLoading(true);
        const response = await CreateYesProductFunction(
          add_user_pending_bond_step_9,
          formData
        );
        console.log("Response", response);
        setLoading(false);
        if (response.status == 1) {
          setCurrentPage(currentPage + 1);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error", error);
        setLoading(false);
      }
    }
  };

  const Step11Validation = async () => {
    if (
      selectedImagesMin1Img.length <= 0 &&
      selectedImagesMin1Imgpreview.length <= 0
    ) {
      toast.error("Please upload at least one image.", { theme: "colored" });
    } else {
      let formData = new FormData();
      formData.append(
        "is_type",
        selectedImagesMin1Img.length == 0 ? "" : "edit"
      );
      selectedImagesMin1Img.forEach((image, index) => {
        formData.append("img_video[]", image);
      });
      formData.append("pending_bond_id", getpendingBondId);

      try {
        setLoading(true);
        const response = await CreateYesProductFunction(
          add_user_pending_bond_step_10,
          formData
        );
        console.log("Response", response);
        setLoading(false);
        if (response.status == 1) {
          setCurrentPage(currentPage + 1);
          setSelectedImagesMin1Img([]);
          Getpandingbond(getpendingBondId);
        }
      } catch (error) {
        console.error("Error", error);
        setLoading(false);
      }
    }
  };

  const Step12Validation = async () => {
    let formData = new FormData();
    selectedImagesAndVidio.forEach((image, index) => {
      formData.append("media_upload[]", image);
    });
    formData.append("pending_bond_id", getpendingBondId);
    let linktag = "";
    var demolinktag = 0;
    for (let index = 0; index < inputValues.length; index++) {
      if (inputValues[index]) {
        demolinktag = demolinktag + 1;

        if (index > 0) {
          linktag += ",";
        }
        linktag += inputValues[index];
      }
    }
    formData.append(
      "is_type",
      selectedImagesAndVidio.length == 0 ? "" : "edit"
    );
    if (inputValues == [""]) {
      formData.append("social_media_link", "");
    } else {
      formData.append("social_media_link", linktag);
    }
    // if (linkvalid == true) {
    // } else {
    //   const lastItemIndex = selectedImagesAndVidio.length - 1;
    //   const lastItem = selectedImagesAndVidio[lastItemIndex];
    //   formData.append("media_upload[]", lastItem); // Append only the last item
    // }
    try {
      setLoading(true);
      const response = await CreateYesProductFunction(
        add_user_pending_bond_step_11,
        formData
      );
      console.log("Response", response);
      setLoading(false);
      if (response.status == 1) {
        setCurrentPage(currentPage + 1);
      }
    } catch (error) {
      console.error("Error", error);
      setLoading(false);
    }
  };

  const Step13Validation = async () => {
    if (getvideoId == "") {
      toast.error("Please attach one creator video.", { theme: "colored" });
    } else {
      let formData = new FormData();
      formData.append("user_id", userId);
      formData.append("user_video_id", getvideoId);
      formData.append("pending_bond_id", getpendingBondId);

      try {
        setLoading(true);
        const response = await CreateYesProductFunction(
          add_user_pending_bond_step_12,
          formData
        );
        console.log("Response", response);
        setLoading(false);
        if (response.status == 1) {
          setCurrentPage(currentPage + 1);
        }
      } catch (error) {
        console.error("Error", error);
        setLoading(false);
      }
    }
  };

  const Step14Validation = async () => {
    let formData = new FormData();
    formData.append("globle_exclusive", selectedGlob);
    formData.append("pending_bond_id", getpendingBondId);

    try {
      setLoading(true);
      const response = await CreateYesProductFunction(
        add_user_pending_bond_step_13,
        formData
      );
      console.log("Response", response);
      setLoading(false);
      if (response.status == 1) {
        setCurrentPage(currentPage + 1);
      }
    } catch (error) {
      console.error("Error", error);
      setLoading(false);
    }
  };

  const Step15Validation = () => {
    console.log("selectedAuction", selectedAuction);
    console.log("getProductValue", getProductValue);
    if (
      selectedAuction == 0 &&
      (getProductValue == null || getProductValue == "" || getProductValue == 0)
    ) {
      toast.error("Please enter asset value", { theme: "colored" });
    } else if (
      selectedAuction == 1 &&
      (getminimumBid == null || getminimumBid == "" || getminimumBid == 0)
    ) {
      toast.error("Enter minimum bid", { theme: "colored" });
    } else if (
      selectedAuction == 1 &&
      (getauctionDurtion == null ||
        getauctionDurtion == "" ||
        getauctionDurtion == 0)
    ) {
      toast.error("Enter Auction Days", { theme: "colored" });
    } else {
      setCurrentPage(currentPage + 1);
    }
  };

  const CreateYesProductApi = async () => {
    setLoading(true);
    let formData = new FormData();

    formData.append("product_name", getproductname);
    formData.append("owner_name", getownerName);

    // formData.append(
    //   "owner_name",
    //   selectedCreator == 3 ? getownerName : get_username
    // );
    formData.append("owner_id", getownerId);
    // formData.append(
    //   "owner_id",
    //   selectedCreator == 3 ? getmanegerid : getownerId
    // );
    formData.append("bond_interest_id", selectedIds);
    formData.append("globle_exclusive", selectedGlob);
    formData.append("product_history", getProductStory);
    formData.append("product_highlights", getProductHighlight);
    formData.append("description", getProductDes);
    formData.append("product_value", getProductValue);
    formData.append("product_value_checker", selectedAuction);
    formData.append("minimum_bid_amount", getminimumBid);
    formData.append("auction_days", getauctionDurtion);
    formData.append("product_hashtag", gethashtags);
    formData.append("is_owner", getselectedBtnIndex);
    formData.append("user_id", userId);
    formData.append("user_video_id", getvideoId);
    // selectedImagesMin1Img.forEach((image, index) => {
    //   formData.append("img_video[]", image);
    // });
    // selectedImagesAndVidio.forEach((image, index) => {
    //   formData.append("media_upload[]", image);
    // });
    // selectedImages.forEach((image, index) => {
    //   formData.append("proof_file", image);
    // });

    // selectedVideos.forEach((image, index) => {
    //   formData.append("bond_video", image);
    // });

    if (month == "" && day == "") {
      formData.append("date_of_creation", year);
    } else if (day == "") {
      formData.append("date_of_creation", `${year}-${month}`);
    } else {
      formData.append("date_of_creation", `${year}-${month}-${day}`);
    }

    // formData.append(
    //   "date_of_creation",
    //   moment(selectedDate).format("YYYY-MM-DD")
    // );

    let idString_usernmae = "";
    let idString_number_of_bond = "";
    let idString_user_id = "";
    var demo = 0;
    for (
      let index = 0;
      index < getnumber_of_bonds_created_for_share.length;
      index++
    ) {
      demo = demo + 1;

      if (index > 0) {
        idString_usernmae += ",";
        idString_number_of_bond += ",";
        idString_user_id += ",";
      }
      idString_usernmae += getnumber_of_bonds_created_for_share_name[index];
      idString_number_of_bond += getnumber_of_bonds_created_for_share[index];
      idString_user_id += getnumber_of_bonds_created_for_share_id[index];
    }

    formData.append("bond_share_owner_name", idString_usernmae);
    formData.append("bond_share_owner_id", idString_user_id);
    formData.append("bond_share_total_assign", idString_number_of_bond);
    // formData.append("total_qr_hunt_bond", getdonetQrhunt);

    let club_idString_usernmae = "";
    let club_idString_number_of_bond = "";
    let club_idString_user_id = "";
    var club_demo = 0;
    for (
      let index = 0;
      index < getclub_number_of_bonds_created_for_share.length;
      index++
    ) {
      club_demo = club_demo + 1;

      if (index > 0) {
        club_idString_usernmae += ",";
        club_idString_number_of_bond += ",";
        club_idString_user_id += ",";
      }
      club_idString_usernmae +=
        getclub_number_of_bonds_created_for_share_name[index];
      club_idString_number_of_bond +=
        getclub_number_of_bonds_created_for_share[index];
      club_idString_user_id +=
        getclub_number_of_bonds_created_for_share_id[index];
    }
    formData.append("share_club_name", club_idString_usernmae);
    formData.append("share_club_id", club_idString_user_id);
    formData.append("share_total_assign", club_idString_number_of_bond);
    formData.append("share_user_id", userId);

    let linktag = "";
    var demolinktag = 0;
    for (let index = 0; index < inputValues.length; index++) {
      if (inputValues[index]) {
        demolinktag = demolinktag + 1;

        if (index > 0) {
          linktag += ",";
        }
        linktag += inputValues[index];
      }
    }
    formData.append("social_media_link", linktag);
    formData.append("pending_bond_id", getpendingBondId);
    formData.append("user_milestone_id", getselectmilestoneid);

    for (var pair of formData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }
    console.log("formData ==>", formData);
    axios
      .post("https://www.ravi.host/api/create_bond_aniket", formData, {
        onUploadProgress: (progressEvent) => {
          const percentage = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          console.log("Upload Percentage:", percentage);
          // Update the state to show the progress
          setUploadPercentage(percentage);
        },
      })
      .then((Response) => {
        // setGlobModalOpen(false);
        // setButtonDisabled(false);
        console.log("Response", Response);
        setLoading(false);
        if (Response.data.status == 1) {
          navigate("/actionneed2");
        }
      })
      .catch((err) => {
        setUploadPercentage(0);
        console.log("err", err);
        setLoading(false);
      });
  };

  const CreateYesProductFunction = async (url, FormData) => {
    try {
      const response = await axios.post(url, FormData, {
        onUploadProgress: (progressEvent) => {
          const percentage = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          console.log("Upload Percentage:", percentage);
          // Update the state to show the progress
          setUploadPercentage(percentage);
        },
      });
      return response.data; // Return response data
    } catch (error) {
      setUploadPercentage(0);
      throw error; // Rethrow the error to be handled by the caller
    }
  };
  // ------------- yes button validation end ------------------

  // ------------- no button validation start ------------------

  const nonextPage = () => {
    if (nocurrentPage == 1) {
      NoStep1Validation();
    } else if (nocurrentPage == 2) {
      NoStep2Validation();
    }
  };

  const noprevPage = () => setNoCurrentPage(nocurrentPage - 1);

  const NoStep1Validation = () => {
    if (getproductname == "") {
      toast.error("Enter asset name", { theme: "colored" });
    } else if (getcreatorname == "") {
      toast.error("Select owner name", { theme: "colored" });
    } else {
      setNoCurrentPage(nocurrentPage + 1);
    }
  };

  const NoStep2Validation = () => {
    if (selectedauthorFileURL == "") {
      toast.error("Please upload authentication  file", { theme: "colored" });
    } else {
      setNoCurrentPage(nocurrentPage + 1);
    }
  };

  const CreateNoProductApi = async () => {
    if (selectedImagesMin1Img.length <= 0) {
      toast.error("Please upload at least one image.", { theme: "colored" });
    } else {
      setLoading(true);
      let formData = new FormData();

      formData.append("product_name", getproductname);
      formData.append("owner_name", getcreatorname);
      formData.append("owner_id", getcreatorid);
      formData.append("is_owner", 0);
      formData.append("user_id", userId);
      selectedImagesMin1Img.forEach((image, index) => {
        formData.append("img_video[]", image);
      });

      formData.append("proof_file", selectedImages);

      for (var pair of formData.entries()) {
        console.log(pair[0] + ", " + pair[1]);
      }
      console.log("formData ==>", formData);
      axios
        .post(create_bond, formData, {
          onUploadProgress: (progressEvent) => {
            const percentage = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            console.log("Upload Percentage:", percentage);
            // Update the state to show the progress
            setUploadPercentage(percentage);
          },
        })
        .then((Response) => {
          console.log("Response", Response);
          setLoading(false);
          if (Response.data.status == 1) {
            navigate("/actionneed2");
          }
        })
        .catch((err) => {
          setUploadPercentage(0);
          console.log("err", err);
          setLoading(false);
        });
    }
  };

  // ------------- no button validation end --------------------

  // ------------- notplatform validations start ---------------

  const notPlatfoemNextPage = () => {
    if (notplatformCurrentPage == 1) {
      // NoStep1Validation();
      NotPlatformStep1Validation();
    } else if (notplatformCurrentPage == 2) {
      // NoStep2Validation();
      NotPlatformStep2Validation();
    }
  };

  const notPlatfoemPrevPage = () => {
    if (notplatformCurrentPage == 1) {
      setselectedBtnIndex(1);
    } else if (notplatformCurrentPage == 2) {
      setNotPlatformCurrentPage(notplatformCurrentPage - 1);
    }
  };

  const NotPlatformStep1Validation = () => {
    if (getproductname == "") {
      toast.error("Enter asset name", { theme: "colored" });
    } else if (getcreatornameText == "") {
      toast.error("Enter creator name", { theme: "colored" });
    } else if (
      getcreatornameContactNo == "" ||
      getcreatornameContactNo == null
    ) {
      toast.error("Enter creator contact number", { theme: "colored" });
    } else if (getcreatornameEmail == "") {
      toast.error("Enter creator email", { theme: "colored" });
    } else {
      setNotPlatformCurrentPage(notplatformCurrentPage + 1);
    }
  };

  const NotPlatformStep2Validation = () => {
    if (selectedauthorFileURL == "") {
      toast.error("Please upload authentication  file", { theme: "colored" });
    } else {
      setNotPlatformCurrentPage(notplatformCurrentPage + 1);
    }
  };

  const CreateNotPlatformProductApi = async () => {
    if (selectedImagesMin1Img.length <= 0) {
      toast.error("Please upload at least one image.", { theme: "colored" });
    } else {
      setLoading(true);
      let formData = new FormData();

      formData.append("product_name", getproductname);
      formData.append("is_owner", 2);
      formData.append("creator_name", getcreatornameText);
      formData.append("creator_contact", getcreatornameContactNo);
      formData.append("creator_email", getcreatornameEmail);
      formData.append("user_id", userId);

      selectedImagesMin1Img.forEach((image, index) => {
        formData.append("img_video[]", image);
      });

      formData.append("proof_file", selectedImages);

      for (var pair of formData.entries()) {
        console.log(pair[0] + ", " + pair[1]);
      }
      console.log("formData ==>", formData);
      axios
        .post(create_bond, formData, {
          onUploadProgress: (progressEvent) => {
            const percentage = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            console.log("Upload Percentage:", percentage);
            // Update the state to show the progress
            setUploadPercentage(percentage);
          },
        })
        .then((Response) => {
          console.log("Response", Response);
          setLoading(false);
          if (Response.data.status == 1) {
            navigate("/actionneed2");
          }
        })
        .catch((err) => {
          setUploadPercentage(0);
          console.log("err", err);
          setLoading(false);
        });
    }
  };

  // ------------- notplatform validations end ------------------

  // user clubs and manager get apis start

  const getUser = () => {
    const param = {
      user_id: userId,
    };
    axios
      .post(getall_user, param)
      .then((Response) => {
        // console.log(
        //   "Response.data.result",
        //   JSON.stringify(Response.data.result, null, 2)
        // );
        setalluser(Response.data.result);
        // this.setState({
        //   // data: Response.data.result,
        //   task_arrayholder: Response.data.result,
        // });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllClubs = () => {
    const param = {
      user_id: userId,
    };
    axios
      .post(get_all_club_2, param)
      .then((Response) => {
        // console.log(
        //   "Response.data.result",
        //   JSON.stringify(Response.data.result, null, 2)
        // );
        setallClubs(Response.data.result);
        setSearchallClubs(Response.data.result);
        // this.setState({
        //   // data: Response.data.result,
        //   task_arrayholder: Response.data.result,
        // });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getManager = async () => {
    const param = {
      user_id: userId,
    };
    await axios
      .post(get_manager_all_user, param)
      .then((Response) => {
        setallmanager(Response.data.result);
        setSearchManagerData(Response.data.result);
        // this.setState({
        //   // data: Response.data.result,
        //   task_arrayholder: Response.data.result,
        // });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const GetEditProfileApi = async () => {
    const param = {
      user_id: userId,
    };
    await axios
      .post(edit_profile, qs.stringify(param))
      .then((Response) => {
        if (Response.data.status == 1) {
          setOwnerName(Response.data.result.username);
          setOwnerId(Response.data.result.id);
        }
      })
      .catch((err) => {
        console.log("get_club_join_fees err", err);
      });
  };

  // user clubs and manager get apis end

  useEffect(() => {
    fetchInterests();
    GetUserVideos();
    getUser();
    getAllClubs();
    getManager();
    GetEditProfileApi();

    console.log("getvideoId", getvideoId);
  }, []);

  useEffect(() => {
    console.log("selectedIds", selectedIds);
    console.log("selectedIds length", selectedIds.length);
  }, [selectedIds]);

  /// milestone api start
  const [getallLegacyYear, setAllLegacyYear] = useState([]);
  const [selectedYear, setSelectedYear] = useState(null);
  const [getlegacyId, setLegacyId] = useState(null);

  const GetYears = async () => {
    setLoading(true);
    const parama = {
      user_id: userId,
    };
    await axios
      .post(get_user_year_list, parama)
      .then((res) => {
        setLoading(false);
        if (res.data.status == 1) {
          setAllLegacyYear(res.data.legacy_year);
          // setSelectedYear(res.data.legacy_year[0]);
          // selectedYear
          setLegacyId(res.data.legacy_id);
          // GetMilestones(res.data.legacy_year[0]);
        } else {
          toast.error(res.data.message, {
            theme: "colored",
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
      });
  };

  useEffect(() => {
    GetYears();
  }, []);

  const [milestonedata, setmilestonedata] = useState([]);

  const GetMilestones = async (year) => {
    setLoading(true);
    const parama = {
      legacy_year: year,
      user_id: userId,
    };
    await axios
      .post(get_legacy_milestone, parama)
      .then((res) => {
        setLoading(false);
        console.log("result", res.data.milestone_data);
        if (res.data.status == 1) {
          setmilestonedata(res.data.milestone_data);
        } else {
          toast.error(res.data.message, {
            theme: "colored",
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
      });
  };

  const handleYearChangeMilestone = (e) => {
    selectmilestoneid("");
    const selectedYear = parseInt(e.target.value);
    setSelectedYear(selectedYear);
    GetMilestones(selectedYear);
  };

  useEffect(() => {
    if (selectedYear) {
      GetMilestones(selectedYear);
    }
  }, [selectedYear]);

  const [getselectmilestoneid, selectmilestoneid] = useState("");

  // milestone api end

  return (
    <div className="new_wrapp_container reletive_position">
      <div className="new_container">
        <div className="back_arrow_wrapp">
          <button
            onClick={() => {
              if (getselectedBtnIndex != 1) {
                console.log("1", 1);
                setselectedBtnIndex(1);
              } else {
                navigate("/myproduct");
                console.log("12", 13);
              }
            }}
          >
            <FaAngleLeft color="var(--color-white)" size={20} />
          </button>
          <div className="npasset_main_wrapp">
            <h2 className="npasset_heading">Protect Asset</h2>
            <div className="npasset_heading_border"></div>

            {/* page inner content wrapp start */}
            {isLoading ? (
              <NewLoader />
            ) : (
              <div className="npasset_inner_page_wrapp">
                {getselectedBtnIndex == 1 && currentPage == 1 ? (
                  <>
                    {/* screen1 yes section start */}
                    <div className="npasset_yes_sec_wrapp">
                      <p>
                        Do you own the Intellectual Property Rights of this
                        asset ?
                      </p>
                      <div className="npasset_yes_sec_btns_wrapp">
                        <button
                          onClick={() => {
                            setselectedBtnIndex(1);
                            setnumber_of_bonds_created_for_share([]);
                          }}
                          style={{
                            background:
                              getselectedBtnIndex == 1
                                ? "linear-gradient( 180deg,#ff004c 0%,rgba(202, 172, 255, 0.43) 100%)"
                                : "gray",
                            opacity: getselectedBtnIndex == 1 ? 1 : 0.8,
                            height: "140px",
                          }}
                        >
                          <div>Yes</div>
                        </button>
                        <button
                          onClick={() => {
                            setselectedBtnIndex(0);
                            setnumber_of_bonds_created_for_share([]);
                          }}
                          style={{
                            background:
                              getselectedBtnIndex == 0
                                ? "linear-gradient( 180deg,#ff004c 0%,rgba(202, 172, 255, 0.43) 100%)"
                                : "gray",
                            opacity: getselectedBtnIndex == 0 ? 1 : 0.8,
                            height: "140px",
                          }}
                        >
                          <div>No</div>
                        </button>
                        <button
                          onClick={() => {
                            setselectedBtnIndex(2);
                            setnumber_of_bonds_created_for_share([]);
                          }}
                          style={{
                            background:
                              getselectedBtnIndex == 2
                                ? "linear-gradient( 180deg,#ff004c 0%,rgba(202, 172, 255, 0.43) 100%)"
                                : "gray",
                            opacity: getselectedBtnIndex == 2 ? 1 : 0.8,
                            height: "60px",
                            gridColumn: "span 2",
                          }}
                        >
                          <div>Creator not on platfrom</div>
                        </button>
                      </div>
                    </div>
                    {/* screen1 yes section end */}
                  </>
                ) : getselectedBtnIndex == 1 && currentPage == 2 ? (
                  <>
                    <div className="npasset_yes_sec_wrapp">
                      <p>Enter Details</p>
                      <div className="enter_detail_main_wrapp">
                        <div className="creator_name_wrapp">
                          <p>Creator Name</p>
                          <div>
                            <input
                              type="text"
                              placeholder="Enter creator name"
                              value={getownerName}
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="creator_name_wrapp">
                          <p>Asset Name/Title </p>
                          <div>
                            <input
                              type="text"
                              placeholder="Enter name/title"
                              value={getproductname}
                              onChange={handleAssetsChange}
                            />
                          </div>
                        </div>

                        {/* physical owner name */}
                        <div className="creator_name_wrapp">
                          <p>Physical Asset Ownwer Name</p>
                          <div>
                            <input
                              type="text"
                              placeholder="Enter physical Asset owner name"
                              value={getphysicalOwnerName}
                              onChange={handlePhysicalOwnerNameChange}
                            />
                          </div>
                        </div>

                        {/* physical address */}
                        <div
                          className="creator_name_wrapp"
                          style={{ marginBottom: "3rem" }}
                        >
                          <p>Physical Asset Address</p>
                          <div>
                            <input
                              type="text"
                              placeholder="Enter physical asset address"
                              value={getphysicalAddress}
                              onChange={handlePhysicalAddressChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : getselectedBtnIndex == 1 && currentPage == 3 ? (
                  <>
                    <div className="npasset_yes_sec_wrapp">
                      <p>Select Category</p>

                      <div
                        className="newinterest_boxes_main_wrapp"
                        style={{ marginTop: "2rem" }}
                      >
                        <div className="newinterest_boxes_gradiant"></div>

                        {/* single interest start */}
                        {getallInterest && getallInterest.length > 0
                          ? getallInterest.map((item, index) => {
                              return (
                                <button
                                  key={item.id}
                                  onClick={() => handleInterestSelection(item)}
                                  className="newinterest_single_box"
                                >
                                  <img
                                    src={
                                      isInterestSelected(item)
                                        ? AssetLink + item.new_color_icon
                                        : AssetLink + item.new_icon
                                    }
                                    alt=""
                                  />
                                  <p
                                    style={{
                                      color: isInterestSelected(item)
                                        ? "var(--color-main)"
                                        : "var(--color-white)",
                                    }}
                                  >
                                    {" "}
                                    {item.name}
                                  </p>
                                </button>
                              );
                            })
                          : null}
                        {/* single interest end */}
                      </div>
                    </div>
                  </>
                ) : getselectedBtnIndex == 1 && currentPage == 4 ? (
                  <>
                    <div className="npasset_yes_sec_wrapp">
                      <p>Date Of Creation</p>

                      {/* datepicker inputs start */}

                      <div className="datepicker_inputs_main_wrapp">
                        <div className="datepicker_single_input">
                          <select value={day} onChange={handleDayChange}>
                            <option value="" disabled>
                              DD
                            </option>
                            {days.map((d) => (
                              <option
                                key={d}
                                value={d >= 10 ? d : 0 + "" + d}
                                disabled={
                                  year === currentYear &&
                                  month === currentMonth &&
                                  d > currentDate
                                }
                              >
                                {formatValue(d)}
                              </option>
                            ))}
                          </select>
                        </div>
                        -
                        <div className="datepicker_single_input">
                          <select value={month} onChange={handleMonthChange}>
                            <option value="" disabled>
                              MM
                            </option>
                            {Array.from({ length: 12 }, (_, i) => i + 1).map(
                              (m) => (
                                <option
                                  key={m}
                                  value={m >= 10 ? m : 0 + "" + m}
                                  disabled={
                                    year === currentYear && m > currentMonth
                                  }
                                >
                                  {formatValue(m)}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                        -
                        <div className="datepicker_single_input">
                          <select value={year} onChange={handleYearChange}>
                            <option value="" disabled>
                              YYYY
                            </option>
                            {Array.from(
                              {
                                length: currentYear - 1300 + 1,
                              },
                              (_, i) => currentYear - i
                            ).map((y) => (
                              <option key={y} value={y}>
                                {y}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      {/* vishal milestone year start */}
                      <p>Select year of Milestone</p>
                      <div className="datepicker_single_inputmilestone">
                        <select
                          value={selectedYear}
                          onChange={handleYearChangeMilestone}
                        >
                          <option value="" disabled>
                            YYYY
                          </option>
                          {getallLegacyYear &&
                            getallLegacyYear.map((year) => (
                              <option key={year} value={year}>
                                {year}
                              </option>
                            ))}
                        </select>
                      </div>
                      {/* milestone year end */}
                      {/* miestone cards start */}

                      {selectedYear !== null &&
                      selectedYear !== "" &&
                      selectedYear !== undefined &&
                      milestonedata &&
                      milestonedata.length == 0 ? (
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            marginTop: "50px",
                          }}
                        >
                          <p
                            style={{
                              textAlign: "center",
                              fontSize: "14px",
                              fontFamily: "var(--font-600)",
                              color: "var(--color-white)",
                            }}
                          >
                            No Milestone found
                          </p>
                          <button
                            style={{
                              textAlign: "center",
                              fontSize: "16px",
                              fontFamily: "var(--font-600)",
                              color: "var(--color-main)",
                              marginTop: "8px",
                            }}
                            onClick={() => {
                              setmilestonedata([]);
                              navigate("/CreateMilestone", {
                                state: {
                                  legacy_year: selectedYear,
                                  edit_param_protect: 1,
                                  item: location.state.item,
                                },
                              });
                            }}
                          >
                            Create New
                          </button>
                        </div>
                      ) : (
                        <div
                          className="legacy_milestone_cards_wrapp"
                          style={{ width: "100%", marginBottom: "50px" }}
                        >
                          {milestonedata && milestonedata.length > 0 && (
                            <div
                              onClick={() => {
                                // console.log("item", item);
                                selectmilestoneid("");
                                console.log(
                                  "getselectmilestoneid",
                                  getselectmilestoneid
                                );
                              }}
                              className={
                                getselectmilestoneid == ""
                                  ? "border_milestone_wrapp"
                                  : "legacy_milestone_single_card"
                              }
                              style={{
                                background: "#000",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                border: "1px solid",
                                borderColor:
                                  getselectmilestoneid == "" ? "red" : "gray",
                              }}
                            >
                              <p
                                style={{
                                  fontFamily: "var(--font-600)",
                                  // color: "white",
                                  fontSize: "1rem",
                                  color:
                                    getselectmilestoneid == "" ? "red" : "gray",
                                }}
                              >
                                None
                              </p>
                            </div>
                          )}
                          {milestonedata && milestonedata.length > 0
                            ? milestonedata.map((item, index) => {
                                console.log("item", item);
                                return (
                                  <div
                                    onClick={() => {
                                      console.log("item", item);
                                      selectmilestoneid(item.user_milestone_id);
                                    }}
                                    className={
                                      getselectmilestoneid ==
                                      item.user_milestone_id
                                        ? "border_milestone_wrapp"
                                        : "legacy_milestone_single_card"
                                    }
                                  >
                                    <img
                                      src={item.imageLink[0].imageLink}
                                      alt=""
                                      className="legacy_milestone_single_card_img"
                                    />
                                    <div className="legacy_milestone_single_card_blur_wrapp">
                                      <p>{item.milestone_name}</p>
                                    </div>
                                  </div>
                                );
                              })
                            : null}
                        </div>
                      )}
                      {/* miestone cards end */}
                      {/* datepicker inputs end */}
                    </div>
                  </>
                ) : getselectedBtnIndex == 1 && currentPage == 5 ? (
                  <>
                    <div className="npasset_yes_sec_wrapp">
                      <p>
                        Provide a brief description or summary of your asset
                      </p>

                      {/* description box start */}

                      <div className="des_textarea_wrapp">
                        <textarea
                          name="product_des"
                          rows="6"
                          className="form_input_box"
                          placeholder="Type Here"
                          value={getProductDes}
                          onChange={(e) => setProductDes(e.target.value)}
                        ></textarea>
                      </div>
                      {/* description box end */}
                    </div>
                  </>
                ) : getselectedBtnIndex == 1 && currentPage == 6 ? (
                  <>
                    <div className="npasset_yes_sec_wrapp">
                      <p>Provide a story or history of your asset</p>

                      {/* Story box start */}

                      <div className="des_textarea_wrapp">
                        <textarea
                          name="product_des"
                          rows="6"
                          className="form_input_box"
                          placeholder="Type Here"
                          value={getProductStory}
                          onChange={(e) => setProductStory(e.target.value)}
                        ></textarea>
                      </div>
                      {/* Story box end */}
                    </div>
                  </>
                ) : getselectedBtnIndex == 1 && currentPage == 7 ? (
                  <>
                    <div className="npasset_yes_sec_wrapp">
                      <p style={{ width: "100%" }}>
                        Craft a narrative or statement that highlights key
                        aspects or features of your assets.
                      </p>

                      {/* highlight box start */}

                      <div className="des_textarea_wrapp">
                        <textarea
                          name="product_des"
                          rows="6"
                          className="form_input_box"
                          placeholder="Type Here"
                          value={getProductHighlight}
                          onChange={(e) => setProductHighlight(e.target.value)}
                        ></textarea>
                      </div>
                      {/* highlight box end */}
                    </div>
                  </>
                ) : getselectedBtnIndex == 1 && currentPage == 8 ? (
                  <>
                    <div className="npasset_yes_sec_wrapp">
                      <p>Enter engaging hashtags to increase visibility.</p>
                      {/* hashtag box start */}
                      <div className="des_textarea_wrapp">
                        <textarea
                          name="product_des"
                          rows="6"
                          className="form_input_box"
                          placeholder="Type Here"
                          onChange={handleHashtagsChange}
                          disabled={
                            gethashtags.trim().split(/\s+/).length >= 10
                          }
                          value={gethashtags}
                        ></textarea>
                      </div>
                      {/* hashtag box start */}
                    </div>
                  </>
                ) : getselectedBtnIndex == 1 && currentPage == 9 ? (
                  <>
                    <div className="npasset_yes_sec_wrapp">
                      <p>Please upload one Asset Video.</p>

                      {/* creator video start */}
                      <div className="new_creator_video_wrapp">
                        {(selectedVideos && selectedVideos.length <= 0) ||
                        selectedVideos == "" ? (
                          <>
                            <label
                              onClick={() => {
                                setIsModalVisiblevideo(true);
                                setRecordedChunks([]);
                                setrecordedvideo(false);
                              }}
                              htmlFor="creator_vid"
                              // className="upload-button"
                              className="new_creator_video_upload_button"
                            >
                              <img src={images.new_upload_logo} alt="" />
                            </label>
                            <input
                              disabled
                              id="creator_vid"
                              type="file"
                              accept="video/*"
                              // multiple
                              capture="camera"
                              onChange={(e) => {
                                handleVideoChange(e);
                                // setIsModalVisiblevideo(true);
                              }}
                            />
                          </>
                        ) : (
                          <div
                            className="image-previews"
                            style={{
                              width: "100%",
                              height: "100%",
                              justifyContent: "center",
                            }}
                          >
                            {selectedVideos.map((image, index) => (
                              // alert(image),
                              <div
                                className="image-preview"
                                key={index}
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  borderRadius: "10px",
                                  width: "100%",
                                  height: "100%",
                                  background: "#000",
                                }}
                              >
                                {/* <img
                                  src={URL.createObjectURL(image)}
                                  alt={`Preview ${index}`}
                                /> */}
                                <button
                                  onClick={() => {
                                    setPreviewVideoModal2(true);
                                    if (getbondlink) {
                                      setUserVideo2(image);
                                    } else {
                                      setUserVideo2(URL.createObjectURL(image));
                                    }
                                  }}
                                  // disabled={gettab3Completed}
                                  style={{
                                    color: "var(--color-main)",
                                    fontSize: "12px",
                                    fontFamily: "var(--font-600)",
                                  }}
                                >
                                  {getthumbImg != "" ? (
                                    <div
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        position: "relative",
                                      }}
                                    >
                                      <img
                                        src={getthumbImg}
                                        alt=""
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                          objectFit: "cover",
                                          margin: "0px",
                                          objectPosition: "top",
                                        }}
                                      />
                                      <img
                                        src={images.play_white}
                                        style={{
                                          width: "30px",
                                          height: "30px",
                                          objectFit: "contain",
                                          margin: "0px",
                                          position: "absolute",
                                          transform: "translate(-50%, -50%)",
                                          left: "50%",
                                          top: "50%",
                                          zIndex: 11,
                                        }}
                                        alt=""
                                      />
                                    </div>
                                  ) : (
                                    "Preview"
                                  )}
                                </button>
                                <button
                                  className="delete-button"
                                  onClick={() => handleVideoDelete(index)}
                                  style={{ right: "14px" }}
                                >
                                  <img src={images.close_red} alt="" />
                                </button>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                      {/* creator video end */}
                      {/* {uploadPercentage > 0 && uploadPercentage < 100 && (
                        <div className="progress-wrapper">
                          <div className="progress-bar-container">
                            <progress
                              value={uploadPercentage}
                              max="100"
                              className="progress"
                            />
                            <div className="progress-text">
                              {`Video Uploading ${uploadPercentage}%`}
                            </div>
                          </div>
                        </div>
                      )} */}
                    </div>
                  </>
                ) : getselectedBtnIndex == 1 && currentPage == 10 ? (
                  <>
                    <div className="npasset_yes_sec_wrapp">
                      <p>Please upload one authentication file.</p>

                      {/* select at least one image start */}

                      <div className="new_creator_video_wrapp">
                        <label
                          // onClick={() => {
                          //   setIsModalVisiblevideo(true);
                          //   setRecordedChunks([]);
                          //   setrecordedvideo(false);
                          // }}
                          htmlFor="file-input"
                          // className="upload-button"
                          className="new_creator_video_upload_button"
                        >
                          <img src={images.new_upload_logo} alt="" />
                        </label>
                        <input
                          id="file-input"
                          type="file"
                          accept="image/*,.pdf"
                          // capture="camera"
                          onChange={handleImageChange}
                        />
                      </div>
                      <div className="new_min1_img_preview_wrapp">
                        {/* {selectedImages &&
                          selectedImages.map((image, index) => {
                            console.log("img", image.type);
                            console.log("selectedImages", selectedImages);
                            return ( */}
                        <>
                          {/* pdf or image condition */}
                          {/* {image.type.startsWith("image/") ? (
                                <div
                                  className="new_min1_img_preview_single"
                                  style={{
                                    gridColumn: "span 3",
                                    height: "160px",
                                  }}
                                  key={index}
                                >
                                  <img
                                    src={URL.createObjectURL(image)}
                                    alt={`Preview ${index}`}
                                  />

                                  <button
                                    className="delete-button"
                                    onClick={() => handleImageDelete(index)}
                                  >
                                    <img src={images.close_red} alt="" />
                                  </button>
                                </div>
                              ) : (
                                <div
                                  className="new_min1_img_preview_single"
                                  style={{
                                    gridColumn: "span 3",
                                    height: "160px",
                                  }}
                                  key={index}
                                >
                                  <img
                                    src={images.pdf_icon}
                                    alt={`Preview ${index}`}
                                  />

                                  <button
                                    className="delete-button"
                                    onClick={() => handleImageDelete(index)}
                                  >
                                    <img src={images.close_red} alt="" />
                                  </button>
                                </div>
                              )} */}
                          {selectedauthorFileURL !== "" && (
                            <div
                              className="new_min1_img_preview_single"
                              style={{
                                gridColumn: "span 3",
                                height: "160px",
                              }}
                              // key={index}
                            >
                              {getowner_proof_im == "" ? (
                                <>
                                  {getpdfcon == true ? (
                                    <img
                                      src={images.pdf_icon}
                                      alt={`Preview `}
                                    />
                                  ) : (
                                    <img
                                      src={selectedauthorFileURL}
                                      alt={`Preview `}
                                    />
                                  )}
                                  {/* {console.log(selectedauthorFileURL)} */}
                                </>
                              ) : (
                                <>
                                  {selectedauthorFileURL.endsWith(".pdf") ? (
                                    <a
                                      href={selectedauthorFileURL}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      download="document.pdf"
                                      style={
                                        {
                                          // background: "#000",
                                          // width: "100%",
                                          // height: "100%",
                                        }
                                      }
                                    >
                                      <img
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                          objectFit: "contain",
                                        }}
                                        src={images.pdf_icon}
                                        alt=""
                                      />
                                    </a>
                                  ) : (
                                    <img src={selectedauthorFileURL} alt="" />
                                  )}
                                </>
                              )}
                              <button
                                className="delete-button"
                                onClick={() => {
                                  setSelectedAuthorFileURL("");
                                  setowner_proof_im("");
                                  handleImageDelete();
                                }}
                              >
                                <img src={images.close_red} alt="" />
                              </button>
                            </div>
                          )}
                        </>
                        {/* );
                          })} */}
                      </div>

                      {/* select at least one image end */}
                    </div>
                  </>
                ) : getselectedBtnIndex == 1 && currentPage == 11 ? (
                  <>
                    <div className="npasset_yes_sec_wrapp">
                      <p>Please upload at least one image.</p>

                      {/* select at least one image start */}

                      <div className="new_creator_video_wrapp">
                        <label
                          // onClick={() => {
                          //   setIsModalVisiblevideo(true);
                          //   setRecordedChunks([]);
                          //   setrecordedvideo(false);
                          // }}
                          htmlFor="min_1_img"
                          // className="upload-button"
                          className="new_creator_video_upload_button"
                        >
                          <img src={images.new_upload_logo} alt="" />
                        </label>
                        <input
                          id="min_1_img"
                          type="file"
                          accept="image/*"
                          // capture="camera"
                          multiple
                          onChange={handleImageChangeMin1Img}
                        />
                      </div>
                      <div className="new_min1_img_preview_wrapp">
                        {selectedImagesMin1Img.map((image, index) => (
                          <div
                            className="new_min1_img_preview_single"
                            key={index}
                          >
                            <img
                              src={URL.createObjectURL(image)}
                              alt={`Preview ${index}`}
                            />

                            <button
                              className="delete-button"
                              onClick={() => handleImageDelete2(index)}
                            >
                              <img src={images.close_red} alt="" />
                            </button>
                          </div>
                        ))}
                      </div>
                      <div className="new_min1_img_preview_wrapp">
                        {selectedImagesMin1Imgpreview.map((image, index) => (
                          <div
                            className="new_min1_img_preview_single"
                            key={index}
                          >
                            <img
                              src={image.img_video}
                              alt={`Preview ${index}`}
                            />

                            <button
                              className="delete-button"
                              onClick={() => {
                                handleImageDelete2preview(index);
                                delete_atlistoneimg(image.pending_bond_img_id);
                              }}
                            >
                              <img src={images.close_red} alt="" />
                            </button>
                          </div>
                        ))}
                      </div>

                      {/* select at least one image end */}
                    </div>
                  </>
                ) : getselectedBtnIndex == 1 && currentPage == 12 ? (
                  <>
                    <div className="npasset_yes_sec_wrapp">
                      <p>Please upload media images</p>

                      {/* select at least one image start */}

                      <div className="new_creator_video_wrapp">
                        <label
                          htmlFor="media_img_vid"
                          className="new_creator_video_upload_button"
                        >
                          <img src={images.new_upload_logo} alt="" />
                        </label>
                        <input
                          id="media_img_vid"
                          type="file"
                          accept="image/*"
                          multiple
                          // capture="camera"
                          onChange={handleImageAndVideoChange}
                        />
                      </div>
                      <div
                        className="new_min1_img_preview_wrapp"
                        style={{ marginBottom: "10px" }}
                      >
                        {selectedImagesAndVidio.map((image, index) => (
                          <div
                            className="new_min1_img_preview_single"
                            // style={{ gridColumn: "span 3", height: "160px" }}
                            key={index}
                          >
                            <img
                              src={URL.createObjectURL(image)}
                              alt={`Preview ${index}`}
                            />

                            <button
                              className="delete-button"
                              onClick={() => handleImageAndVideoDelete(index)}
                            >
                              <img src={images.close_red} alt="" />
                            </button>
                          </div>
                        ))}
                      </div>
                      <div
                        className="new_min1_img_preview_wrapp"
                        style={{ marginBottom: "10px" }}
                      >
                        {selectedImagesAndVidiopreview.map((image, index) => (
                          <div
                            className="new_min1_img_preview_single"
                            // style={{ gridColumn: "span 3", height: "160px" }}
                            key={index}
                          >
                            <img
                              src={image.img_video}
                              alt={`Preview ${index}`}
                            />

                            <button
                              className="delete-button"
                              onClick={() => {
                                handleImageAndVideoDeletepre(index);
                                delete_mediaimg(image.pending_media_id);
                              }}
                            >
                              <img src={images.close_red} alt="" />
                            </button>
                          </div>
                        ))}
                      </div>

                      {/* select at least one image end */}

                      {/* media links */}
                      <div
                        className="media_links_section_wrapp"
                        style={{ marginBottom: "3rem" }}
                      >
                        <p>Enter Media Links</p>
                        {media_link && media_link.length > 0
                          ? media_link.map((value, index) => {
                              // console.log("value", value);
                              return (
                                <div
                                  className="new_otp_input_container"
                                  style={{ padding: "0px" }}
                                >
                                  <div
                                    className="new_otp_input_1st_sec"
                                    style={{ width: "15%" }}
                                  >
                                    <div className="verify_email_logo_wrapp">
                                      <img
                                        src={images.new_link_icon}
                                        className="verify_email_logo"
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                  <div
                                    className="new_otp_input_2nd_sec"
                                    style={{ width: "75%" }}
                                  >
                                    {/* <input type="email" value={getemail} className="" /> */}
                                    <input
                                      readOnly
                                      type="email"
                                      name=""
                                      id=""
                                      className="verify_email_input"
                                      value={value.social_media_link}
                                      // onChange={(e) =>
                                      //   handleInputChange(e.target.value, index)
                                      // }
                                    />
                                  </div>
                                  {media_link.length > 1 && (
                                    <button
                                      className="cp_ml_mius_btn"
                                      // onClick={handleRemoveValue}
                                      onClick={() => {
                                        deleteMediaLink(
                                          value.pending_bond_social_media_link_id
                                        );
                                      }}
                                    >
                                      -
                                    </button>
                                  )}
                                </div>
                              );
                            })
                          : null}
                        {inputValues.map((value, index) => (
                          <div
                            className="new_otp_input_container"
                            style={{ padding: "0px" }}
                          >
                            <div
                              className="new_otp_input_1st_sec"
                              style={{ width: "15%" }}
                            >
                              <div className="verify_email_logo_wrapp">
                                <img
                                  src={images.new_link_icon}
                                  className="verify_email_logo"
                                  alt=""
                                />
                              </div>
                            </div>
                            <div
                              className="new_otp_input_2nd_sec"
                              style={{ width: "75%" }}
                            >
                              {/* <input type="email" value={getemail} className="" /> */}
                              <input
                                type="email"
                                name=""
                                id=""
                                className="verify_email_input"
                                value={value}
                                onChange={(e) =>
                                  handleInputChange(e.target.value, index)
                                }
                              />
                            </div>
                            {inputValues.length > 1 && (
                              <button
                                className="cp_ml_mius_btn"
                                onClick={handleRemoveValue}
                              >
                                -
                              </button>
                            )}
                          </div>
                        ))}

                        <button
                          onClick={handleAddValue}
                          className="new_gradiant_border_btn mt-3"
                        >
                          <div>Add More Link</div>
                        </button>
                      </div>
                      {/* medialinks end */}
                    </div>
                  </>
                ) : getselectedBtnIndex == 1 && currentPage == 13 ? (
                  <>
                    <div className="npasset_yes_sec_wrapp">
                      <p>Please attach one creator video.</p>

                      {/* select at least one image start */}

                      <div className="new_creator_video_wrapp">
                        <label
                          htmlFor="media_img_vid"
                          className="new_creator_video_upload_button"
                          onClick={() => {
                            setAttechVideoModal(true);
                          }}
                        >
                          <img src={images.new_upload_logo} alt="" />
                        </label>
                      </div>
                      {getuserVid !== "" ? (
                        <button
                          onClick={() => {
                            setPreviewVideoModal(true);
                          }}
                          className="new_attach_video_privew_btn"
                          style={{ height: "170px" }}
                        >
                          {getuserThumbImg !== "" ? (
                            <div
                              style={{
                                width: "100%",
                                height: "100%",
                                position: "relative",
                              }}
                            >
                              <img
                                src={getuserThumbImg}
                                alt=""
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                  margin: "0px",
                                  objectPosition: "start",
                                }}
                              />
                              <img
                                src={images.play_white}
                                style={{
                                  width: "30px",
                                  height: "30px",
                                  objectFit: "contain",
                                  margin: "0px",
                                  position: "absolute",
                                  transform: "translate(-50%, -50%)",
                                  left: "50%",
                                  top: "50%",
                                  zIndex: 11,
                                }}
                                alt=""
                              />
                            </div>
                          ) : (
                            "Preview"
                          )}
                        </button>
                      ) : null}

                      {/* select at least one image end */}
                    </div>
                  </>
                ) : getselectedBtnIndex == 1 && currentPage == 14 ? (
                  <>
                    <div className="npasset_yes_sec_wrapp">
                      <p>Global Exclusive </p>
                      {/* global exclusive buttons start */}
                      <div className="npasset_yes_sec_btns_wrapp">
                        <button
                          onClick={() => {
                            handleGlobOptionChange(1);
                          }}
                          style={{
                            background:
                              selectedGlob == 1
                                ? "linear-gradient( 180deg,#ff004c 0%,rgba(202, 172, 255, 0.43) 100%)"
                                : "gray",
                            opacity: selectedGlob == 1 ? 1 : 0.8,
                            height: "140px",
                          }}
                        >
                          <div>Yes</div>
                        </button>
                        <button
                          onClick={() => {
                            handleGlobOptionChange(0);
                          }}
                          style={{
                            background:
                              selectedGlob == 0
                                ? "linear-gradient( 180deg,#ff004c 0%,rgba(202, 172, 255, 0.43) 100%)"
                                : "gray",
                            opacity: selectedGlob == 0 ? 1 : 0.8,
                            height: "140px",
                          }}
                        >
                          <div>No</div>
                        </button>
                      </div>
                      {/* global exclusive buttons end */}
                    </div>

                    {/* global execlusive text start */}

                    <p className="npasset_globel_exe_text">
                      {selectedGlob == 1
                        ? `Exclusive Commitment: Dear Asset Uploader, Thank
                        you for choosing aucemo.club as your platform to create
                        digital assets of your asset exclusively. We are excited
                        to collaborate with you on this journey. Here are the
                        terms for your exclusive commitment: Platform Fee:
                        aucemo.club will take only 10 Aucemo of this
                        particular asset as the platform fee. This ensures that
                        you retain a significant portion of your Aucemo for
                        potential sales. Royalty for Life: As a token of
                        appreciation for your exclusive commitment, you will
                        receive a 10% royalty for life from the sales of the
                        Aucemo. This means that for every Aucemo sold, you
                        will earn a share of the revenue, providing you with
                        ongoing passive income. We value your trust in
                        aucemo.club, and we are committed to helping you
                        maximize the potential of your asset through our
                        platform. Let's work together to create a thriving
                        marketplace for your Aucemo! Best regards `
                        : `Non-Exclusive Commitment:\n\nDear Asset Uploader,
                        Thank you for considering aucemo.club as a platform to
                        create digital assets of your asset. We appreciate your
                        interest. Here are the terms for your non-exclusive
                        commitment: Platform Fee: aucemo.club will charge 20
                        Aucemo of the asset as the platform fee. This fee
                        allows us to provide you with a robust platform and
                        comprehensive support. Royalty for Life: As a gesture of
                        our partnership, you will receive a 5% royalty for life
                        from the sales of the Aucemo. This means that for every
                        Aucemo sold, you will earn a share of the revenue,
                        ensuring a continuous stream of passive income. We
                        believe in fostering a mutually beneficial relationship
                        and are excited to have your asset on our platform.
                        Let's collaborate to make your Aucemo a success!`}
                    </p>

                    {/* global execlusive text end */}
                  </>
                ) : getselectedBtnIndex == 1 && currentPage == 15 ? (
                  <>
                    <div className="npasset_yes_sec_wrapp">
                      {selectedAuction == 0 && (
                        <>
                          <p>Enter Asset Value</p>
                          <div className="new_asset_value_sec_main_wrapp">
                            <input
                              type="text"
                              value="₹"
                              readOnly
                              className="new_asset_value_input_1"
                              placeholder="₹"
                            />
                            <input
                              type="number"
                              className="new_asset_value_input_2"
                              value={getProductValue}
                              onKeyPress={handleKeyPress}
                              onChange={(e) => {
                                // Ensure that the entered value is a positive integer
                                const inputValue = e.target.value;
                                const isValid = /^\d+$/.test(inputValue); // Checks if the value is a positive integer

                                if (inputValue <= 0) {
                                  setProductValue("");
                                  // setErrorMessage1(
                                  //   "Please enter valid asset value"
                                  // );
                                  // setShowModal1(true);
                                  toast.error(
                                    "Please enter valid asset value",
                                    {
                                      theme: "colored",
                                    }
                                  );
                                } else {
                                  setProductValue(inputValue);
                                  console.log("isValid", inputValue);
                                }
                              }}
                            />
                            <p className="new_asset_value_input_2_static_sign">
                              /-
                            </p>
                          </div>
                          <p>OR</p>
                        </>
                      )}
                      <p style={{ margin: "1rem auto", width: "100%" }}>
                        Start auction to set Asset base value?
                      </p>
                      {/* auction buttons start */}
                      <div className="npasset_yes_sec_btns_wrapp">
                        <button
                          onClick={() => {
                            handleAuctionOptionChange(1);
                          }}
                          style={{
                            background:
                              selectedAuction == 1
                                ? "linear-gradient( 180deg,#ff004c 0%,rgba(202, 172, 255, 0.43) 100%)"
                                : "gray",
                            opacity: selectedAuction == 1 ? 1 : 0.8,
                            height: "140px",
                          }}
                        >
                          <div>Yes</div>
                        </button>
                        <button
                          onClick={() => {
                            handleAuctionOptionChange(0);
                          }}
                          style={{
                            background:
                              selectedAuction == 0
                                ? "linear-gradient( 180deg,#ff004c 0%,rgba(202, 172, 255, 0.43) 100%)"
                                : "gray",
                            opacity: selectedAuction == 0 ? 1 : 0.8,
                            height: "140px",
                          }}
                        >
                          <div>No</div>
                        </button>
                      </div>
                      {/* auction buttons end */}

                      {/* auction input boxes start */}
                      {selectedAuction == 1 && (
                        <div className="newasset_auction_inputs_main_wrapp">
                          <>
                            <label htmlFor="minimum_bid">
                              Enter Minimum Bid
                            </label>
                            <div className="mb-3">
                              <input
                                type="text"
                                onKeyPress={handleKeyPress}
                                value={getminimumBid}
                                onChange={(e) => {
                                  // setMinimumBid(e.target.value);
                                  const inputValue = e.target.value;
                                  if (inputValue < 0) {
                                    setMinimumBid("");
                                    toast.error(
                                      "Please enter valid minimum bid value",
                                      { theme: "colored" }
                                    );
                                  } else {
                                    setMinimumBid(inputValue);
                                    console.log("isValid", inputValue);
                                  }
                                }}
                              />
                            </div>
                          </>
                          <>
                            <label htmlFor="auction_duration">
                              Auction Duration
                            </label>
                            <div className="mb-3">
                              <input
                                type="text"
                                onKeyPress={handleKeyPress}
                                placeholder=""
                                value={getauctionDurtion}
                                onChange={(e) => {
                                  // setAuctoinDuration(e.target.value);
                                  const inputValue = e.target.value;
                                  const isValid =
                                    /^\d+$/.test(inputValue) &&
                                    parseInt(inputValue, 10) >= 0;

                                  if (!isValid) {
                                    setAuctoinDuration("");
                                    // setErrorMessage1(
                                    //   "Please enter a valid non-negative and decimal Auction Duration value"
                                    // );
                                    // setShowModal1(true);
                                    toast.error(
                                      "Please enter a valid non-negative and decimal Auction Duration value",
                                      { theme: "colored" }
                                    );
                                  } else {
                                    setAuctoinDuration(
                                      parseInt(inputValue, 10)
                                    );
                                    console.log("isValid", inputValue);
                                  }
                                }}
                              />
                            </div>
                          </>
                        </div>
                      )}
                      {selectedAuction == 0 && (
                        <>
                          {/*gift btn start */}

                          {/* <button
                            className="new_btn_wrapp mx-1 mt-3"
                            style={{ marginBottom: "3rem" }}
                            onClick={() => setSharenfbModal(true)}
                          >
                            <p style={{ fontSize: "14px" }}>
                              Gift Aucemo to other user
                            </p>
                          </button> */}

                          {/*gift btn end */}

                          {/* share list start */}
                          {getnumber_of_bonds_created_for_share.length > 0 ||
                          getclub_number_of_bonds_created_for_share.length >
                            0 ? (
                            <div
                              style={{
                                width: "95%",
                                margin: "0px auto 3rem auto",
                                border: "1px dashed var(--color-gray)",
                                padding: "10px",
                                borderRadius: "8px",
                              }}
                            >
                              {/* share lists start */}
                              <div>
                                {getnumber_of_bonds_created_for_share &&
                                getnumber_of_bonds_created_for_share.length > 0
                                  ? getnumber_of_bonds_created_for_share.map(
                                      (item, index) => {
                                        return (
                                          <div
                                            style={{
                                              width: "100%",
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <p
                                              style={{
                                                color: "var(--color-white)",
                                                fontSize: "12px",
                                              }}
                                            >
                                              {
                                                getnumber_of_bonds_created_for_share_name[
                                                  index
                                                ]
                                              }{" "}
                                              - {item}
                                            </p>
                                            <button
                                              onClick={() => {
                                                handleShareDelete(item, index);
                                              }}
                                            >
                                              <img
                                                src={images.close_red}
                                                style={{
                                                  width: "24px",
                                                  height: "24px",
                                                  objectFit: "contain",
                                                }}
                                                alt=""
                                              />
                                            </button>
                                          </div>
                                        );
                                      }
                                    )
                                  : null}
                              </div>
                              {/* share lists end */}

                              {/* club share lists start */}

                              <div>
                                {getclub_number_of_bonds_created_for_share &&
                                getclub_number_of_bonds_created_for_share.length >
                                  0
                                  ? getclub_number_of_bonds_created_for_share.map(
                                      (item, index) => {
                                        return (
                                          <div
                                            style={{
                                              width: "100%",
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <p
                                              style={{
                                                color: "var(--color-white)",
                                                fontSize: "12px",
                                              }}
                                            >
                                              {
                                                getclub_number_of_bonds_created_for_share_name[
                                                  index
                                                ]
                                              }{" "}
                                              - {item}
                                            </p>
                                            <button
                                              onClick={() => {
                                                handleClubShareDelete(
                                                  item,
                                                  index
                                                );
                                              }}
                                            >
                                              <img
                                                src={images.close_red}
                                                style={{
                                                  width: "24px",
                                                  height: "24px",
                                                  objectFit: "contain",
                                                }}
                                                alt=""
                                              />
                                            </button>
                                          </div>
                                        );
                                      }
                                    )
                                  : null}
                              </div>

                              {/* club share lists end */}
                            </div>
                          ) : null}
                          {/* share list end */}
                        </>
                      )}
                      {/* auction input boxes end */}
                    </div>
                  </>
                ) : getselectedBtnIndex == 1 && currentPage == 16 ? (
                  <>
                    <div className="npasset_yes_sec_wrapp">
                      <div className="global_msg_screen_main_wrapp">
                        <img
                          src={images.new_success_img}
                          className="success_img"
                          alt=""
                        />
                        <p
                          className="npasset_globel_exe_text"
                          style={{
                            textAlign: "center",
                            fontSize: "1rem",
                            fontFamily: "var(--font-600)",
                          }}
                        >
                          {getglobModalMsg}
                        </p>
                      </div>
                    </div>
                  </>
                ) : null}

                {/* ------------------- select no screen wrapp start ---------------------- */}
                {getselectedBtnIndex == 0 && (
                  <>
                    <div className="npasset_yes_sec_wrapp">
                      {nocurrentPage == 1 ? (
                        <>
                          <div className="new_proasset_inner_div">
                            <div className="new_proasset_input_icon_div">
                              <img
                                src={images.asset_name_logo}
                                alt=""
                                className="new_pro_input_icon"
                              />
                              <input
                                type="text"
                                name="product_name"
                                placeholder="Enter asset name"
                                className="new_proasset_input"
                                value={getproductname}
                                onChange={handleAssetsChange}
                              />
                            </div>
                            <div className="npasset_input_border"></div>

                            <div className="new_proasset_input_icon_div">
                              <img
                                src={images.new_user_icon}
                                alt=""
                                className="new_pro_input_icon"
                              />
                              <input
                                // value={getcreatorname}
                                type="text"
                                placeholder="Select Creator Name"
                                className="new_proasset_input"
                                onClick={() => {
                                  setCreatormodal(true);
                                }}
                                value={getcreatorname}
                              />
                              <img
                                src={images.selector_icon}
                                alt=""
                                className="new_pro_select_icon"
                              />
                            </div>
                            <div className="npasset_input_border"></div>
                          </div>
                        </>
                      ) : nocurrentPage == 2 ? (
                        <>
                          <div className="new_proasset_title_div">
                            <p>Please upload one authentication file.</p>
                          </div>
                          <div className="new_creator_video_wrapp">
                            <label
                              htmlFor="file-input"
                              className="new_creator_video_upload_button"
                            >
                              <img src={images.new_upload_logo} alt="" />
                            </label>
                            <input
                              id="file-input"
                              type="file"
                              accept="image/*,.pdf"
                              onChange={handleImageChange}
                            />
                          </div>
                          <div className="new_min1_img_preview_wrapp">
                            {/* {selectedImages.map((image, index) => {
                              console.log("img", image.type);
                              return (
                                <>
                                  {image.type.startsWith("image/") ? (
                                    <div
                                      className="new_min1_img_preview_single"
                                      style={{
                                        gridColumn: "span 3",
                                        height: "160px",
                                      }}
                                      key={index}
                                    >
                                      <img
                                        src={URL.createObjectURL(image)}
                                        alt={`Preview ${index}`}
                                      />

                                      <button
                                        className="delete-button"
                                        onClick={() => handleImageDelete(index)}
                                      >
                                        <img src={images.close_red} alt="" />
                                      </button>
                                    </div>
                                  ) : (
                                    <div
                                      className="new_min1_img_preview_single"
                                      style={{
                                        gridColumn: "span 3",
                                        height: "160px",
                                      }}
                                      key={index}
                                    >
                                      <img
                                        src={images.pdf_icon}
                                        alt={`Preview ${index}`}
                                      />

                                      <button
                                        className="delete-button"
                                        onClick={() => handleImageDelete(index)}
                                      >
                                        <img src={images.close_red} alt="" />
                                      </button>
                                    </div>
                                  )}
                                </>
                              );
                            })} */}
                            {selectedauthorFileURL !== "" && (
                              <div
                                className="new_min1_img_preview_single"
                                style={{
                                  gridColumn: "span 3",
                                  height: "160px",
                                }}
                                // key={index}
                              >
                                <img
                                  src={selectedauthorFileURL}
                                  alt={`Preview`}
                                />

                                <button
                                  className="delete-button"
                                  onClick={() => {
                                    setSelectedAuthorFileURL("");
                                    setowner_proof_im("");
                                    handleImageDelete();
                                  }}
                                >
                                  <img src={images.close_red} alt="" />
                                </button>
                              </div>
                            )}
                          </div>
                        </>
                      ) : nocurrentPage == 3 ? (
                        <>
                          <div className="npasset_yes_sec_wrapp">
                            <p>Please upload cover image.</p>

                            {/* select at least one image start */}
                            <div className="new_creator_video_wrapp">
                              <label
                                htmlFor="min_1_img"
                                // className="upload-button"
                                className="new_creator_video_upload_button"
                              >
                                <img src={images.new_upload_logo} alt="" />
                              </label>
                              <input
                                id="min_1_img"
                                type="file"
                                accept="image/*"
                                // capture="camera"
                                multiple
                                onChange={handleImageChangeMin1Img}
                              />
                            </div>
                            <div className="new_min1_img_preview_wrapp">
                              {selectedImagesMin1Img.map((image, index) => (
                                <div
                                  className="new_min1_img_preview_single"
                                  key={index}
                                >
                                  <img
                                    src={URL.createObjectURL(image)}
                                    alt={`Preview ${index}`}
                                  />
                                  <button
                                    className="delete-button"
                                    onClick={() => handleImageDelete2(index)}
                                  >
                                    <img src={images.close_red} alt="" />
                                  </button>
                                </div>
                              ))}
                            </div>
                            {/* <div className="new_proasset_submit_btn">
                            <button className="new_proasset_submit_btn">
                              Submit
                            </button>
                          </div> */}

                            <button
                              className="new_btn_wrapp mx-1 mt-3"
                              style={{ marginBottom: "3rem" }}
                              onClick={() => {
                                CreateNoProductApi();
                              }}
                            >
                              <p style={{ fontSize: "14px" }}>Submit</p>
                            </button>
                            {/* select at least one image end */}
                          </div>
                        </>
                      ) : null}

                      {nocurrentPage !== NoToatalPage ? (
                        <div
                          className="npasset_page_change_btns_wrapp"
                          style={{ justifyContent: "flex-end" }}
                        >
                          <button
                            onClick={() => {
                              if (nocurrentPage === 1) {
                                setselectedBtnIndex(1);
                              } else {
                                noprevPage();
                              }
                            }}
                            // disabled={nocurrentPage === 1}
                          >
                            <img src={images.new_pa_left} alt="" />
                          </button>

                          <button
                            onClick={nonextPage}
                            disabled={nocurrentPage === NoToatalPage}
                          >
                            <img src={images.new_pa_right} alt="" />
                          </button>
                        </div>
                      ) : null}
                    </div>
                  </>
                )}
                {/* select no screen wrapp end */}

                {/* select creater not on platform screen wrapp start */}
                {getselectedBtnIndex == 2 && (
                  <>
                    <div className="npasset_yes_sec_wrapp">
                      {notplatformCurrentPage == 1 ? (
                        <div className="third_option_1_main">
                          {/* <div className="third_option_1">
                        {tick === true ? (
                          <img
                            src={images.new_accepted_terms}
                            alt=""
                            onClick={() => settick(false)}
                          />
                        ) : (
                          ""
                        )}
                        {tick === false ? (
                          <img
                            src={images.new_notaccepted_terms}
                            alt=""
                            onClick={() => settick(true)}
                          />
                        ) : (
                          ""
                        )}
                        <p>Protect Asset on behalf of owner</p>
                      </div> */}
                          <div className="input_op1">
                            <img src={images.asset_name_logo} alt="" />
                            <input
                              className="input_op1_input"
                              type="text"
                              placeholder="Asset Name/Title"
                              value={getproductname}
                              onChange={handleAssetsChange}
                            />
                          </div>
                          <div className="in_line"></div>

                          <div className="input_op1">
                            <img src={images.new_user_icon} alt="" />
                            <input
                              className="input_op1_input"
                              type="text"
                              placeholder="Creator Name"
                              value={getcreatornameText}
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                // const words = inputValue.trim().split(/\s+/); // Split input into words

                                const containsEmoji =
                                  /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}]/u.test(
                                    inputValue
                                  );

                                // setGetcreatornameText(e.target.value)
                                if (!containsEmoji) {
                                  setGetcreatornameText(inputValue);
                                }
                              }}
                            />
                          </div>
                          <div className="in_line"></div>

                          <div className="input_op1">
                            <img src={images.new_phone_icon} alt="" />
                            <input
                              className="input_op1_input"
                              type="number"
                              placeholder="Creator Contact"
                              value={getcreatornameContactNo}
                              onChange={handlePhoneNumberChange}
                            />
                          </div>
                          <div className="in_line"></div>

                          <div className="input_op1">
                            <img
                              src={images.new_email_logo}
                              alt=""
                              className="img_email"
                            />
                            <input
                              className="input_op1_input"
                              type="email"
                              placeholder="Creator Email"
                              value={getcreatornameEmail}
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                // const words = inputValue.trim().split(/\s+/); // Split input into words

                                const containsEmoji =
                                  /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}]/u.test(
                                    inputValue
                                  );

                                // setGetcreatornameText(e.target.value)
                                if (!containsEmoji) {
                                  setGetcreatornameEmail(inputValue);
                                }
                              }}
                            />
                          </div>
                          <div className="in_line"></div>
                        </div>
                      ) : notplatformCurrentPage == 2 ? (
                        <>
                          <div className="new_proasset_title_div">
                            <p>Please upload one authentication file.</p>
                          </div>
                          <div className="new_creator_video_wrapp">
                            <label
                              htmlFor="file-input"
                              className="new_creator_video_upload_button"
                            >
                              <img src={images.new_upload_logo} alt="" />
                            </label>
                            <input
                              id="file-input"
                              type="file"
                              accept="image/*,.pdf"
                              onChange={handleImageChange}
                            />
                          </div>
                          <div className="new_min1_img_preview_wrapp">
                            {/* {selectedImages.map((image, index) => {
                              console.log("img", image.type);
                              return (
                                <>
                                  {image.type.startsWith("image/") ? (
                                    <div
                                      className="new_min1_img_preview_single"
                                      style={{
                                        gridColumn: "span 3",
                                        height: "160px",
                                      }}
                                      key={index}
                                    >
                                      <img
                                        src={URL.createObjectURL(image)}
                                        alt={`Preview ${index}`}
                                      />

                                      <button
                                        className="delete-button"
                                        onClick={() => handleImageDelete(index)}
                                      >
                                        <img src={images.close_red} alt="" />
                                      </button>
                                    </div>
                                  ) : (
                                    <div
                                      className="new_min1_img_preview_single"
                                      style={{
                                        gridColumn: "span 3",
                                        height: "160px",
                                      }}
                                      key={index}
                                    >
                                      <img
                                        src={images.pdf_icon}
                                        alt={`Preview ${index}`}
                                      />

                                      <button
                                        className="delete-button"
                                        onClick={() => handleImageDelete(index)}
                                      >
                                        <img src={images.close_red} alt="" />
                                      </button>
                                    </div>
                                  )}
                                </>
                              );
                            })} */}
                            {selectedauthorFileURL !== "" && (
                              <div
                                className="new_min1_img_preview_single"
                                style={{
                                  gridColumn: "span 3",
                                  height: "160px",
                                }}
                                // key={index}
                              >
                                <img
                                  src={selectedauthorFileURL}
                                  alt={`Preview`}
                                />

                                <button
                                  className="delete-button"
                                  onClick={() => {
                                    setSelectedAuthorFileURL("");
                                    setowner_proof_im("");
                                    handleImageDelete();
                                  }}
                                >
                                  <img src={images.close_red} alt="" />
                                </button>
                              </div>
                            )}
                          </div>
                        </>
                      ) : notplatformCurrentPage == 3 ? (
                        <>
                          <div className="npasset_yes_sec_wrapp">
                            <p>Please upload cover image.</p>

                            {/* select at least one image start */}
                            <div className="new_creator_video_wrapp">
                              <label
                                htmlFor="min_1_img"
                                // className="upload-button"
                                className="new_creator_video_upload_button"
                              >
                                <img src={images.new_upload_logo} alt="" />
                              </label>
                              <input
                                id="min_1_img"
                                type="file"
                                accept="image/*"
                                // capture="camera"
                                multiple
                                onChange={handleImageChangeMin1Img}
                              />
                            </div>
                            <div className="new_min1_img_preview_wrapp">
                              {selectedImagesMin1Img.map((image, index) => (
                                <div
                                  className="new_min1_img_preview_single"
                                  key={index}
                                >
                                  <img
                                    src={URL.createObjectURL(image)}
                                    alt={`Preview ${index}`}
                                  />
                                  <button
                                    className="delete-button"
                                    onClick={() => handleImageDelete2(index)}
                                  >
                                    <img src={images.close_red} alt="" />
                                  </button>
                                </div>
                              ))}
                            </div>
                            {/* <div className="new_proasset_submit_btn">
                            <button className="new_proasset_submit_btn">
                              Submit
                            </button>
                          </div> */}

                            <button
                              className="new_btn_wrapp mx-1 mt-3"
                              style={{ marginBottom: "3rem" }}
                              onClick={() => {
                                CreateNotPlatformProductApi();
                              }}
                            >
                              <p style={{ fontSize: "14px" }}>Submit</p>
                            </button>
                            {/* select at least one image end */}
                          </div>
                        </>
                      ) : null}
                      {notplatformCurrentPage !== NotPlatformToatalPage ? (
                        <div
                          className="npasset_page_change_btns_wrapp"
                          style={{ justifyContent: "flex-end" }}
                        >
                          <button
                            onClick={() => {
                              // setselectedBtnIndex(1);
                              // setNotPlatformCurrentPage(1)
                              notPlatfoemPrevPage();
                            }}
                          >
                            <img src={images.new_pa_left} alt="" />
                          </button>
                          <button
                            onClick={() => {
                              notPlatfoemNextPage();
                            }}
                          >
                            <img src={images.new_pa_right} alt="" />
                          </button>
                        </div>
                      ) : null}
                    </div>
                  </>
                )}
                {/* select creater not on platform screen wrapp end */}
              </div>
            )}
            {/* page inner content wrapp end */}
          </div>

          {/* page changes buttons wrapp start */}

          <>
            {getselectedBtnIndex == 1 && (
              <div
                className="npasset_page_change_btns_wrapp"
                style={
                  {
                    // display:
                    //   uploadPercentage === 0 || uploadPercentage === 100
                    //     ? "flex"
                    //     : "none",
                  }
                }
              >
                {/* {uploadPercentage > 0 && uploadPercentage < 100 ? (
                  <div
                    className="progress-wrapper"
                    style={{ margin: "20% auto" }}
                  >
                    <div className="progress-bar-container">
                      <progress
                        value={uploadPercentage}
                        max="100"
                        // className="progress-"
                      />
                      <div className="progress-text">{`${uploadPercentage}%`}</div>
                    </div>
                  </div>
                ) : null} */}
                <button onClick={prevPage} disabled={currentPage === 1}>
                  <img src={images.new_pa_left} alt="" />
                </button>
                <p>
                  {currentPage} / {YesToatalPage}
                </p>
                <button
                  onClick={nextPage}
                  disabled={currentPage > YesToatalPage}
                >
                  <img src={images.new_pa_right} alt="" />
                </button>
              </div>
            )}
          </>

          {/* page changes buttons wrapp end */}
        </div>
      </div>

      {/* video modal start */}
      {isModalVisiblevideo && (
        <div
          className="modal_login"
          style={{ zIndex: 111, width: "100%", height: "95vh", bottom: "-5px" }}
        >
          <div
            className="select_nw_modal"
            style={{ height: "100%", background: "#333" }}
          >
            <div className="modal_div1">
              <img
                className="forgot_main"
                src="/static/media/logo.c9b7a18fb586e344723a.png"
                alt=""
              />
            </div>
            {getrecordedvideo ? (
              <div>
                {recordedChunks.length > 0 && (
                  <div>
                    <p>Recorded Video:</p>
                    <video controls width="400">
                      <source
                        src={URL.createObjectURL(recordedChunks[0])}
                        type="video/webm"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                )}
              </div>
            ) : (
              <>
                <div
                  style={{
                    marginBottom: "14px",
                    width: "100%",
                    borderRadius: "18px",
                    overflow: "hidden",
                    height: "60vh",
                  }}
                >
                  <Webcam
                    width={"100%"}
                    height={"100%"}
                    videoConstraints={{ facingMode: "user" }}
                    ref={videoRef}
                    mirrored={true}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {recording ? (
                    <>
                      <div
                        style={{
                          width: "90%",
                          height: "60px",
                          borderRadius: "28px",
                          backgroundColor: "#69696971",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          position: "relative",
                        }}
                      >
                        <p
                          style={{
                            position: "absolute",
                            left: "10px",
                            top: "20px",
                            fontFamily: "var(--font-600)",
                            color: "white",
                            fontSize: "14px",
                          }}
                        >
                          {recordingTime} seconds
                        </p>
                        {/* <button
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                          color: "white",
                          alignSelf: "center",
                        }}
                        onClick={stopRecording}
                      >
                        Stop Recording
                      </button> */}
                        <img
                          onClick={StopRecording}
                          src={images.stop_circle}
                          style={{
                            height: "50px",
                            width: "50px",
                            objectFit: "contain",
                          }}
                          alt=""
                        />
                      </div>
                    </>
                  ) : (
                    <div
                      style={{
                        width: "90%",
                        height: "60px",
                        borderRadius: "28px",
                        backgroundColor: "#69696971",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {/* <button
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                          color: "white",
                          alignSelf: "center",
                        }}
                        onClick={startRecording}
                      >
                        Start Recording
                      </button> */}
                      <img
                        onClick={startRecording}
                        src={images.start_circle}
                        style={{
                          height: "50px",
                          width: "50px",
                          objectFit: "contain",
                        }}
                        alt=""
                      />
                    </div>
                  )}
                </div>
              </>
            )}

            <button
              onClick={() => {
                showimage();
              }}
            ></button>
            {/* <hr className="hr_forgot" /> */}
            <br />
            <div className="modal_div">
              <button
                className="v2modal_btn2"
                onClick={() => {
                  showimage();
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      {/* video modal end */}

      {/* preview video modal start */}
      {getpreviewVideoModal2 && (
        <div
          style={{
            position: "fixed",
            width: "100vw",
            height: "100vh",
            top: "0px",
            left: "0px",
            zIndex: 112,
          }}
        >
          <div className="page_main_wrapp">
            <div className="page_base_wrapp">
              <div className="page_navbar_wrapp">
                <div className="nav_back_wrapp">
                  <img
                    src={images.back}
                    className="nav_back_btn"
                    alt=""
                    onClick={() => setPreviewVideoModal2(false)}
                  />
                </div>
                <p className="nav_screen_name">Video</p>
              </div>
              <video
                controls
                style={{ width: "100%", height: "90%" }}
                poster={getthumbImg !== "" ? getthumbImg : ""}
              >
                <source src={getuserVid2} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
      )}
      {/* preview video modal end */}

      {/* attach video modal start */}
      {getattechVideoModal && (
        <div
          style={{
            position: "fixed",
            width: "100vw",
            height: "100vh",
            top: "0px",
            left: "0px",
            zIndex: 112,
          }}
        >
          <div className="page_main_wrapp">
            <div className="page_base_wrapp">
              <div className="page_navbar_wrapp" style={{ zIndex: 111 }}>
                <div className="nav_back_wrapp">
                  <img
                    src={images.back}
                    className="nav_back_btn"
                    alt=""
                    onClick={() => setAttechVideoModal(false)}
                  />
                </div>
                <p className="nav_screen_name">Creator Videos</p>
              </div>
              <div
                style={{
                  width: "100%",
                  color: "gray",
                  height: "2px",
                  margin: "10px auto",
                  minWidth: "300px",
                }}
              ></div>

              {/* <div className="upload_video_btn_cp">
                  <Link to={"/editprofile"}>
                    <p className="upload_video_btn_cp_text">
                      Upload Video from profile
                    </p>
                  </Link>
                </div> */}

              <div className="av_videos_wrapp">
                {getvideoArray && getvideoArray.length > 0 ? (
                  getvideoArray.map((item, index) => {
                    return (
                      <>
                        <div
                          // className="av_single_video"
                          style={{
                            width: "48%",
                            padding: "10px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                            gap: 6,
                          }}
                        >
                          {/* <input
                            onClick={() => {
                              setVideoId(item.user_video_id);
                              setUserVideo(item.user_videos);
                              setAttechVideoModal(false);
                              console.log("item", item);
                            }}
                            type="checkbox"
                            className="av_of_checkbox"
                            checked={getvideoId === item.user_video_id}
                          /> */}
                          <div
                            style={{
                              width: "100%",
                              height: "80px",
                              border: "2px dashed var(--color-gray)",
                              borderColor:
                                getvideoId === item.user_video_id
                                  ? "var(--color-main)"
                                  : "var(--color-gray)",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: 12,
                              maxWidth: "138px",
                            }}
                          >
                            <button
                              onClick={() => {
                                // setAttechVideoModal(false);
                                setPreviewVideoModal(true);
                                console.log("getuserVid", getuserVid);
                              }}
                              className="av_prev_btn"
                            >
                              {item.user_thumbnail &&
                              item.user_thumbnail != "" ? (
                                <div
                                  style={{
                                    width: "100%",
                                    height: "78px",
                                    position: "relative",
                                    minWidth: "134px",
                                    borderRadius: "14px",
                                    overflow: "hidden",
                                  }}
                                >
                                  <img
                                    src={item.user_thumbnail}
                                    alt=""
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "cover",
                                      margin: "0px",
                                      objectPosition: "top",
                                    }}
                                  />
                                  <img
                                    src={images.play_white}
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                      objectFit: "contain",
                                      margin: "0px",
                                      position: "absolute",
                                      transform: "translate(-50%, -50%)",
                                      left: "50%",
                                      top: "50%",
                                      zIndex: 11,
                                    }}
                                    alt=""
                                  />
                                </div>
                              ) : (
                                "Preview"
                              )}
                            </button>
                          </div>
                          <button
                            style={{
                              color: "#fff",
                              fontSize: "12px",
                              fontFamily: "var(--font-600)",
                              border: "1px solid white",
                              padding: "10px 14px",
                              borderRadius: "12px",
                              borderColor:
                                getvideoId === item.user_video_id
                                  ? "var(--color-main)"
                                  : "white",
                              background:
                                getvideoId === item.user_video_id
                                  ? "var(--color-main)"
                                  : "black",
                            }}
                            onClick={() => {
                              setVideoId(item.user_video_id);
                              setUserVideo(item.user_videos);
                              setAttechVideoModal(false);
                              if (
                                item.user_thumbnail &&
                                item.user_thumbnail !== ""
                              ) {
                                setUserThumbImg(item.user_thumbnail);
                              }
                              console.log("item", item);
                            }}
                          >
                            Attach
                          </button>
                        </div>
                      </>
                    );
                  })
                ) : (
                  <Link
                    className="novideo_btn"
                    // to={"/UserProfile"}
                    // state={{
                    //   user_id: userId,
                    // }}
                    to={"/editprofile"}
                  >
                    Upload Videos From Profile
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {/* attach video modal end */}

      {/* preview video modal start */}
      {getpreviewVideoModal && (
        <div
          style={{
            position: "fixed",
            width: "100vw",
            height: "100vh",
            top: "0px",
            left: "0px",
            zIndex: 112,
          }}
        >
          <div className="page_main_wrapp">
            <div className="page_base_wrapp">
              <div className="page_navbar_wrapp">
                <div className="nav_back_wrapp">
                  <img
                    src={images.back}
                    className="nav_back_btn"
                    alt=""
                    onClick={() => setPreviewVideoModal(false)}
                  />
                </div>
                <p className="nav_screen_name">Video</p>
              </div>
              <video controls style={{ width: "100%", height: "90%" }}>
                <source src={getuserVid} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
      )}
      {/* preview video modal end */}

      {/* aucemo gift modal start */}
      {getsharenfbModal && (
        <div
          // onClick={() => setSharenfbModal(false)}
          className="newasset_gift_rarere_main_wrapp"
          style={{ zIndex: 100 }}
        >
          <div className="newasset_gift_rarere_base_wrapp">
            <div className="gift_rare_fixed_logo_wrapp">
              <img src={images.logo} alt="" />
            </div>
            <p className="gift_rare_modal_heading">
              How many Aucemo you want to gift ? <br /> Available Aucemo{" "}
              {getavailableBond}
            </p>
            <div id="custom_radio" style={{ width: "95%" }}>
              <label class="custom_radio">
                <input
                  type="radio"
                  name="share_checkbox"
                  value={0}
                  checked={getisUserAndClub == 0}
                  onChange={handleShareUserAndClubChange}
                />
                <span class="checkmark"></span>
                User
              </label>
              <label class="custom_radio">
                <input
                  type="radio"
                  name="share_checkbox"
                  value={1}
                  checked={getisUserAndClub == 1}
                  onChange={handleShareUserAndClubChange}
                />
                <span class="checkmark"></span>
                Club
              </label>
            </div>
            <>
              {getisUserAndClub == 0 ? (
                <>
                  {/* user inputs start */}
                  <div className="gift_rare_modal_inputs_wrapp">
                    <input
                      onClick={() => {
                        setShareOwnerModal(true);
                        // setDonationSelect(true);
                        setSharenfbModal(false);
                      }}
                      value={getdonationOwnerName}
                      type="text"
                      name="product_name"
                      // className="available_nfb_modal_input"
                      className="gift_rare_modal_input"
                      placeholder="Search Username"
                    />
                    <input
                      className="gift_rare_modal_input"
                      placeholder="Enter number of Aucemo"
                      value={getnoOfBond}
                      onChange={(e) => {
                        // setNoOfBond(e.target.value)
                        const inputValue = e.target.value;
                        const isValid =
                          /^\d+$/.test(inputValue) &&
                          parseInt(inputValue, 10) >= 0;

                        if (!isValid) {
                          setNoOfBond("");
                          toast.error("Please enter a valid number of Aucemo", {
                            theme: "colored",
                          });
                        } else {
                          setNoOfBond(parseInt(inputValue, 10));
                          console.log("isValid", inputValue);
                        }
                      }}
                    />
                    <div
                      style={{
                        margin: "1rem auto",
                      }}
                      className="v2modal_fixed_btns_wrapp"
                    >
                      <button
                        className="v2modal_btn1"
                        onClick={() => {
                          setNoOfBond(null);
                          setNoOfBondClub(null);
                          setDonationOwnerName("");
                          setDonationClubName("");
                          setDonationOwnerId("");
                          setDonationClubId("");
                          setSharenfbModal(false);
                          // setBodyScrollEnabled(true);
                        }}
                      >
                        Cancel
                      </button>

                      <button
                        className="v2modal_btn2"
                        onClick={() => {
                          if (getdonationOwnerName == "") {
                            alert("Search username and select");
                          } else if (getnoOfBond == null) {
                            alert("Please enter number of Aucemo");
                          } else if (getnoOfBond == 0) {
                            alert("You can't make a stock of zero Aucemo");
                          } else if (getnoOfBond > getavailableBond) {
                            alert(
                              "Enter Aucemo value below " +
                                getavailableBond +
                                "."
                            );
                          } else if (ddd < getnoOfBond) {
                            toast.error(
                              "Enter Aucemo value below " + ddd + ".",
                              { theme: "colored" }
                            );
                          } else {
                            setnumber_of_bonds_created_for_share([
                              ...getnumber_of_bonds_created_for_share,
                              getnoOfBond,
                            ]);
                            setnumber_of_bonds_created_for_share_name([
                              ...getnumber_of_bonds_created_for_share_name,
                              getdonationOwnerName,
                            ]);
                            setnumber_of_bonds_created_for_share_id([
                              ...getnumber_of_bonds_created_for_share_id,
                              getdonationOwnerId,
                            ]);
                            setAvailableBond(
                              parseInt(getavailableBond) - parseInt(getnoOfBond)
                            );
                            setNoOfBond(null);
                            setDonationOwnerName("");
                            setDonationOwnerId("");
                            setSharenfbModal(false);
                          }
                        }}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                  {/* user inputs end */}
                </>
              ) : (
                // club inputs start
                <div className="gift_rare_modal_inputs_wrapp">
                  <input
                    onClick={() => {
                      setShareClubModal(true);
                      // setDonationSelect(true);
                      setSharenfbModal(false);
                    }}
                    value={getdonationClubName}
                    type="text"
                    name="product_name"
                    className="gift_rare_modal_input"
                    placeholder="Search Club name"
                  />
                  <input
                    className="gift_rare_modal_input"
                    placeholder="Enter number of Aucemo"
                    value={getnoOfBondClub}
                    onChange={(e) => {
                      // setNoOfBondClub(e.target.value);
                      const inputValue = e.target.value;
                      const isValid =
                        /^\d+$/.test(inputValue) &&
                        parseInt(inputValue, 10) >= 0;

                      if (!isValid) {
                        setNoOfBondClub("");
                        toast.error("Please enter a valid number of Aucemo", {
                          theme: "colored",
                        });
                      } else {
                        setNoOfBondClub(parseInt(inputValue, 10));
                        console.log("isValid", inputValue);
                      }
                    }}
                  />
                  <div
                    style={{
                      margin: "1rem auto",
                    }}
                    className="v2modal_fixed_btns_wrapp"
                  >
                    <button
                      className="v2modal_btn1"
                      onClick={() => {
                        setNoOfBond(null);
                        setNoOfBondClub(null);
                        setDonationOwnerName("");
                        setDonationClubName("");
                        setDonationOwnerId("");
                        setDonationClubId("");
                        setSharenfbModal(false);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className="v2modal_btn2"
                      onClick={() => {
                        if (getdonationClubName == "") {
                          // alert("Search club and select");
                          toast.error("Search club and select", {
                            theme: "colored",
                          });
                        } else if (getnoOfBondClub == null) {
                          // alert("Please enter number of Aucemo");
                          toast.error("Please enter number of Aucemo", {
                            theme: "colored",
                          });
                        } else if (getnoOfBondClub == 0) {
                          // alert("You can't make a stock of zero Aucemo");
                          toast.error("You can't make a stock of zero Aucemo", {
                            theme: "colored",
                          });
                        } else if (getnoOfBondClub > getavailableBond) {
                          toast.error(
                            "Enter Aucemo value below " +
                              getavailableBond +
                              ".",
                            { theme: "colored" }
                          );
                        } else if (ddd < getnoOfBondClub) {
                          toast.error("Enter Aucemo value below " + ddd + ".", {
                            theme: "colored",
                          });
                        } else {
                          setclub_number_of_bonds_created_for_share([
                            ...getclub_number_of_bonds_created_for_share,
                            getnoOfBondClub,
                          ]);
                          setclub_number_of_bonds_created_for_share_name([
                            ...getclub_number_of_bonds_created_for_share_name,
                            getdonationClubName,
                          ]);
                          setclub_number_of_bonds_created_for_share_id([
                            ...getclub_number_of_bonds_created_for_share_id,
                            getdonationClubId,
                          ]);
                          setAvailableBond(
                            parseInt(getavailableBond) -
                              parseInt(getnoOfBondClub)
                          );
                          setNoOfBondClub(null);
                          setDonationClubName("");
                          setDonationClubId("");
                          setSharenfbModal(false);
                        }
                      }}
                    >
                      Save
                    </button>
                  </div>
                </div>
                // club inputs end
              )}
            </>
          </div>
        </div>
      )}
      {/* aucemo gift modal end */}

      {/* share owner modal start */}
      {getshareOwnerModal && (
        <div
          className="creator_modal_main_warp"
          style={{ zIndex: 112, background: "#000", position: "fixed" }}
        >
          <div className="creator_modal_base_warp">
            <div className="cp_navbar_wrapp">
              <img
                onClick={() => {
                  setShareOwnerModal(false);
                  setSharenfbModal(true);
                }}
                src={images.back}
                className="nav_back_btn"
                alt=""
              />
            </div>
          </div>
          <div className="search mt-2">
            <input
              type="text"
              placeholder="Search"
              // value={getsearchData}
              onChange={(e) => {
                Search_user(e.target.value);
              }}
            />
            <img className="search-img" src={images.search} alt="" />
          </div>
          <div style={{ width: "100%", height: "80vh", overflow: "scroll" }}>
            {data_filter_data.map((item, index) => (
              <div
                onClick={() => {
                  // selecteduser(item);
                  setShareOwnerModal(false);
                  setSharenfbModal(true);
                  setDonationOwnerName(item.label);
                  setDonationOwnerId(item.id);
                }}
                key={index}
                className="item-container"
              >
                <img src={item.profile} alt={item.label} />
                <div>{item.label}</div>
              </div>
            ))}
          </div>
        </div>
      )}
      {/* share owner modal end */}

      {/* share club modal start */}
      {getshareClubModal && (
        <div
          className="creator_modal_main_warp"
          style={{ zIndex: 112, background: "#000", position: "fixed" }}
        >
          <div className="creator_modal_base_warp">
            <div className="cp_navbar_wrapp">
              <img
                onClick={() => {
                  setShareClubModal(false);
                  setSharenfbModal(true);
                }}
                src={images.back}
                className="nav_back_btn"
                alt=""
              />
            </div>
          </div>
          <div className="search mt-2">
            <input
              type="text"
              placeholder="Search"
              // value={getsearchData}
              onChange={(e) => {
                Search_clubs(e.target.value);
              }}
            />
            <img className="search-img" src={images.search} alt="" />
          </div>
          <div style={{ width: "100%", height: "80vh", overflow: "scroll" }}>
            {getallclubs.map((item, index) => (
              // console.log('item', item)
              <div
                onClick={() => {
                  // selecteduser(item);
                  setShareClubModal(false);
                  setSharenfbModal(true);
                  setDonationClubName(item.label);
                  setDonationClubId(item.club_id);
                  // console.log("item", item);
                }}
                key={index}
                className="item-container"
              >
                <img src={item.profile} alt={item.label} />
                <div>{item.label}</div>
              </div>
            ))}
          </div>
        </div>
      )}
      {/* share club modal end */}

      {/* creator model start */}
      {creatormodal ? (
        <div
          className="creator_modal_main_warp"
          style={{ zIndex: 1112, background: "var(--color-black)" }}
        >
          <div className="creator_modal_base_warp">
            <div className="cp_navbar_wrapp">
              <img
                onClick={() => {
                  setCreatormodal(false);
                  // setSharenfbModal(true);
                }}
                src={images.back}
                className="nav_back_btn"
                alt=""
              />
            </div>
          </div>
          <div className="search mt-2">
            <input
              type="text"
              placeholder="Search"
              // value={getsearchData}
              onChange={(e) => {
                Search_user(e.target.value);
              }}
            />
            <img className="search-img" src={images.search} alt="" />
          </div>
          {data_filter_data.map((item, index) => (
            <div
              onClick={() => {
                // selecteduser(item);
                setCreatormodal(false);
                setGetcreatorname(item.label);
                setGetcreatorid(item.id);
              }}
              key={index}
              className="item-container"
            >
              <img src={item.profile} alt={item.label} />
              <div>{item.label}</div>
            </div>
          ))}
        </div>
      ) : null}
      {/* creator modal end */}
      <ToastContainer />
    </div>
  );
};

export default NewEditProtectAsset;
