import React, { useState } from "react";
import "./Mynfb.css";
import images from "../../constants/images";
import { Link, useNavigate } from "react-router-dom";
import NavbarSec from "../../common/NavbarSec";
import { useEffect } from "react";
import axios from "axios";
import qs from "qs";
import { get_user_accepted_bond } from "../../utils/Constant";
import { FaAngleLeft } from "react-icons/fa";

const Legal = () => {
  const userId = localStorage.getItem("user_id");
  const [products, setProducts] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [msg, setMsg] = useState(
    "Currently, you don't have any purchased Aucemo."
  );

  const navigate = useNavigate();

  const GetAcceptedData = async () => {
    setProducts([]);

    const param = {
      user_id: userId,
    };
    await axios
      .post(get_user_accepted_bond, qs.stringify(param))
      .then((Response) => {
        console.log("====================================");
        console.log("=======?", Response.data);
        console.log("====================================");

        if (Response.data.status == 1) {
          setProducts(Response.data.result);
        } else {
          // setIsModalVisible(true);
          setMsg(Response.data.message);
        }
        console.log("Respnose", Response.data);
      });
  };

  useEffect(() => {
    GetAcceptedData();
  }, []);

  return (
    <div className="new_wrapp_container reletive_position">
      <img src={images.new_bg_circule} alt="" className="back_circule_shadow" />

      <div className="new_container">
        {/* <NavbarSec name="Legal" /> */}
        <div
          className="play_screen_main_wrapp"
          style={{ padding: "10px 0%", alignItems: "baseline" }}
        >
          <div className="legacy_dashbord_nav_sec" style={{ width: "90%" }}>
            <button
              onClick={() => {
                navigate(-1);
              }}
            >
              <FaAngleLeft color="var(--color-white)" size={20} />
            </button>
            <p>Legal</p>
            <div></div>
          </div>

          {/* <div className="mynfb_main">
          <Link to={"/releasednfb"} className="mynfb_header">
            <img src={images.logo} className="mynfbS_img" alt="" />
            <p className="h1_mynfbs_details">Released NFBs</p>
          </Link>
          <Link to={"/resellnfb"} className="mynfb_header">
            <img
              src={images.new_launch_nfb_new}
              className="mynfbS_img"
              alt=""
            />
            <p className="h1_mynfbs_details">Resell NFBs</p>
          </Link>
          <Link to={"/ReceivedNfbs"} className="mynfb_header">
            <img
              src={images.Partner_Reward_Request}
              className="mynfbS_img"
              alt=""
            />
            <p className="h1_mynfbs_details">Received NFBs</p>
          </Link>
          <Link to={"/purchasednfbs"} className="mynfb_header">
            <img src={images.collection} className="mynfbS_img" alt="" />
            <p className="h1_mynfbs_details">Purchased NFBs</p>
          </Link>
          <Link to={"/wishlist"} className="mynfb_header">
            <img src={images.favorite} className="mynfbS_img" alt="" />
            <p className="h1_mynfbs_details">Wishlist</p>
          </Link>
          <Link to={"/auction2"} className="mynfb_header">
            <img src={images.product_auction} className="mynfbS_img" alt="" />
            <p className="h1_mynfbs_details">My Auction</p>
          </Link>
        </div> */}
          <div style={{ marginTop: "30px" }} className="menu_list">
            <img src={images.terms} className="images_icon_menu" alt="" />
            <Link to={"/termsandcondition"}>
              <p
                className="menu_list_p"
                style={{
                  fontFamily: "var(--font-600)",
                  fontweight: "350",
                  fontSize: "14px",
                }}
              >
                Terms & Conditions
              </p>
            </Link>
          </div>
          <div style={{ marginTop: "30px" }} className="menu_list">
            <img src={images.Privacy} className="images_icon_menu" alt="" />
            <Link to={"/PrivacyPolicy"}>
              <p
                className="menu_list_p"
                style={{
                  fontFamily: "var(--font-600)",
                  fontweight: "350",
                  fontSize: "14px",
                }}
              >
                Privacy Policy
              </p>
            </Link>
          </div>
          <div style={{ marginTop: "30px" }} className="menu_list">
            <img src={images.cancelation} className="images_icon_menu" alt="" />
            <Link to={"/Refund"}>
              <p
                className="menu_list_p"
                style={{
                  fontFamily: "var(--font-600)",
                  fontweight: "350",
                  fontSize: "14px",
                }}
              >
                Refund & Cancellations
              </p>
            </Link>
          </div>
          <div style={{ marginTop: "30px" }} className="menu_list">
            <img
              src={images.shipping_box}
              className="images_icon_menu"
              alt=""
            />
            <Link to={"/ShippingPolicy"}>
              <p
                className="menu_list_p"
                style={{
                  fontFamily: "var(--font-600)",
                  fontweight: "350",
                  fontSize: "14px",
                }}
              >
                Shipping & Delivery Policy
              </p>
            </Link>
          </div>
          {/* <div style={{ marginTop: "30px" }} className="menu_list">
          <img src={images.support} className="images_icon_menu" alt="" />
          <Link to={"/ContactUs"}>
            <p className="menu_list_p">Contact Us</p>
          </Link>
        </div> */}
        </div>
      </div>
    </div>
  );
};

export default Legal;
