import React, { useEffect, useRef, useState } from "react";
import "./bidlist.css";
import images from "../../constants/images";
import { useLocation, useNavigate } from "react-router-dom";
import NavbarSec from "../../common/NavbarSec";
import Cardthree from "../../components/feedcard/cardthree";
import {
  get_user_balance,
  platform_fees,
  update_bid,
  user_Check_m_pin,
  user_Send_otp_for_bond,
} from "../../utils/Constant";
import axios from "axios";
import CusModal from "../../common/cusmodal/CusModal";
import NewModal from "../../common/newmodal/NewModal";
import ReactModal from "react-modal";
import V2Modal from "../../common/v2modal/V2Modal";
import { loadStripe } from "@stripe/stripe-js";
import Urls from "../../utils/Urls";

const Bidlist = () => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "0px",
      backgroundColor: "none",
      border: "none",
      borderRadius: "12px",
    },
    overlay: {
      zIndex: 1000,
      backgroundColor: "rgba(0, 0, 0, 0.3)",
    },
  };
  const location = useLocation();
  const stripePromise = loadStripe(
    "pk_live_51ODMf4SIZS16AN382v1WLRYA0m5T0xqKIjkWwJCYo2eLmEVR6JlSxQM1MIgvQNl6NJewKCQBR2F6JqMqhKrhm5O7000ONaUVem"
  );
  const [loading, setloading] = useState(false);
  const [getok, setok] = useState(null);
  const userId = localStorage.getItem("user_id");
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [fees_count, setfees_count] = useState(0);
  const [company_royalty, setcompany_royalty] = useState(0);
  const [creator_royalty, setcreator_royalty] = useState(0);
  const [first_buyer_royalty, setfirst_buyer_royalty] = useState(0);
  const [second_buyer_royalty, setsecond_buyer_royalty] = useState(0);
  const [mpin_status, setmpin_status] = useState("");
  const [total_amount_bid, settotal_amount_bid] = useState(0);
  const [my_balance, setmy_balance] = useState(0);
  const [walletmassage, setwalletmassage] = useState("");
  const [walletmodel, setwalletmodel] = useState(false);
  const [isModalOpen, setisisModalOpen] = useState(false);

  useEffect(() => {
    getReleasebond();
    Getcheckbalance();
  }, []);

  const getReleasebond = () => {
    setloading(true);
    const param = {
      bid_amount: location.state.amount,
      bid_id: location.state.bid_id,
      share_by_user: location.state.share_by_user,
      user_id: userId,
    };
    console.log(param);
    axios
      .post("https://www.ravi.host/api/platform_fees_2", param)
      .then((Response) => {
        console.log("acdascs", Response.data.result);
        setloading(false);
        if (Response.data.status == 1) {
          const data = Response.data.result;
          setfees_count(data.platform_fee);
          setcompany_royalty(data.company_royalty);
          setcreator_royalty(data.creator_royalty);
          setfirst_buyer_royalty(data.first_buyer_royalty);
          setsecond_buyer_royalty(data.second_buyer_royalty);
          setmpin_status(data.is_m_pin);
          settotal_amount_bid(
            parseFloat(location.state.amount) +
              parseFloat(fees_count) +
              parseFloat(company_royalty) +
              parseFloat(creator_royalty) +
              parseFloat(first_buyer_royalty) +
              parseFloat(second_buyer_royalty)
          );
          setPlatform_fees_data(data);
        } else {
          setfees_count(0);
        }
        // this.setState({
        //   // data: Response.data.result,
        //   task_arrayholder: Response.data.result,
        // });
      })
      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };
  const Getcheckbalance = () => {
    setloading(true);
    const param = {
      user_id: userId,
    };
    console.log(param);
    axios
      .post(get_user_balance, param)
      .then((Response) => {
        console.log("acdascs", Response.data);
        setloading(false);
        if (Response.data.status == 1) {
          const data = Response.data;
          setmy_balance(data.total);
        } else {
          setmy_balance(0);
        }
        // this.setState({
        //   // data: Response.data.result,
        //   task_arrayholder: Response.data.result,
        // });
      })
      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };

  const navigate = useNavigate();

  const Usercheckotp = () => {
    if (parseInt(total_amount_bid) <= parseInt(my_balance)) {
      if (mpin_status == 1) {
        setisisModalOpen(true);
      } else {
        setloading(true);
        const param = {
          user_id: userId,
        };
        console.log(param);
        axios
          .post(user_Send_otp_for_bond, param)
          .then((Response) => {
            console.log("acdascs", Response.data);
            setloading(false);
            if (Response.data.status == 1) {
              navigate("/mpin", {
                state: {
                  otp: Response.data.user_otp,
                },
              });
            } else {
              setErrorMessage(Response.data.message);
              setShowModal(true);
            }
            // this.setState({
            //   // data: Response.data.result,
            //   task_arrayholder: Response.data.result,
            // });
          })
          .catch((err) => {
            setloading(false);
            console.log(err);
          });
      }
    } else {
      // setwalletmodel('')
      setwalletmodel(true);
    }
  };

  const Updatebid = () => {
    const otpValueEmail = mpin.join("");
    console.log("otpValueEmail", otpValueEmail);
    if (otpValueEmail == "") {
      setShowModal(true);
      setErrorMessage("Please enter mpin");
    } else {
      const param = {
        user_id: userId,
        m_pin: otpValueEmail,
      };
      console.log("parammpin", param);
      axios
        .post(user_Check_m_pin, param)
        .then((Response) => {
          console.log("acdascs", Response.data);
          setloading(false);
          if (Response.data.status == 1) {
            bidplace();
          } else {
            setErrorMessage(Response.data.message);
            setShowModal(true);
          }
          // this.setState({
          //   // data: Response.data.result,
          //   task_arrayholder: Response.data.result,
          // });
        })
        .catch((err) => {
          setloading(false);
          console.log(err);
        });
    }
  };

  const bidplace = () => {
    const param = {
      user_id: userId,
      bid_id: location.state.bid_id,
      bid_amount: location.state.bid_amount_back,
      total:
        parseFloat(location.state.amount) +
        parseFloat(fees_count) +
        parseFloat(company_royalty) +
        parseFloat(creator_royalty) +
        parseFloat(first_buyer_royalty) +
        parseFloat(second_buyer_royalty),
    };
    axios
      .post(update_bid, param)
      .then((Response) => {
        setloading(false);
        if (Response.data.status == 1) {
          // navigate("/mpin", {
          //   state: {
          //     otp: Response.data.user_otp,
          //   },
          // });
          // setErrorMessage(Response.data.message);
          // setShowModal(true);
          navigate("/bids");
          setisisModalOpen(false);
        } else {
          setErrorMessage(Response.data.message);
          setShowModal(true);
        }
        // this.setState({
        //   // data: Response.data.result,
        //   task_arrayholder: Response.data.result,
        // });
      })
      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };

  const [mpin, setMpin] = useState(["", "", "", ""]);
  const mpinRefs = useRef([]);

  const handleMpinChange = (index, value) => {
    if (/^\d*$/.test(value) && value.length <= 1) {
      const newMpin = [...mpin];
      newMpin[index] = value;
      setMpin(newMpin);
      if (value !== "" && index < mpin.length - 1) {
        mpinRefs.current[index + 1].focus();
      }
    }
  };

  const closemodal = () => {
    setShowModal(false);
  };
  const openmodal = () => {
    setShowModal(true);
  };
  const [getplatform_fees_data, setPlatform_fees_data] = useState({});
  const saveDataToSessionStorage = (key, data) => {
    const storedData = JSON.parse(sessionStorage.getItem(key)) || [];
    storedData.push(data);
    sessionStorage.setItem(key, JSON.stringify(storedData));
  };
  const makePayment = async () => {
    try {
      const apiUrl = "https://www.ravi.host/api/stripe_payment";
      const datas = {
        user_id: userId,
        amount: 1, // Replace with the desired amount
        // amount: getplatform_fees_data.stripe_total, // Replace with the desired amount
        success_url: ` ${Urls.main_url}SuccessUpdatebid`, // Replace with your success URL
        fail_url: `${Urls.main_url}Fail`, // Replace with your fail URL
      };
      console.log("dddd", datas);
      const response = await axios.post(apiUrl, datas);
      console.log("Response: " + JSON.stringify(response.data.success));
      // Handle the response data as needed
      // setResponsesession(response.data.session_id);
      if (response.data.success === true) {
        saveDataToSessionStorage("myItems", getplatform_fees_data);
        saveDataToSessionStorage("location_data", location.state);
        // saveDataToSessionStorage("total_ammount", total_amount_bid);
        localStorage.setItem("allres", JSON.stringify(response.data));
        const tttt =
          parseFloat(location.state.amount) +
          parseFloat(fees_count) +
          parseFloat(company_royalty) +
          parseFloat(creator_royalty) +
          parseFloat(first_buyer_royalty) +
          parseFloat(second_buyer_royalty);
        localStorage.setItem("updatetotal", JSON.stringify(tttt));

        const stripe = await stripePromise;
        await stripe.redirectToCheckout({
          sessionId: response.data.session_id,
        });
      } else {
        alert("something went wrong ");
        sessionStorage.clear();
      }
    } catch (error) {
      // Handle errors
      console.error("Error making payment:", error);
    }
  };

  return (
    <div className="page_main_wrapp">
      {/* <div className="page_base_wrapp_b"> */}
      <div
        className="bidlist"
        style={{ maxWidth: "var(--max-width-sm)", margin: "0px auto" }}
      >
        <center>
          {/* <img src={images.logo} className="bidlist_img" alt="" /> */}
          <div className="v2modal_fixed_logo_wrapp">
            <img src={images.logo} alt="" />
          </div>
        </center>
        <div className="bid_total">
          <div className="nm_bid_total">
            <p className="nm_bis_1">Aucemo Bid </p>
            <p className="nm_bis_1">Processing Fees</p>
            <p className="nm_bis_1">Platform commission</p>
            {creator_royalty == 0 ? null : (
              <p className="nm_bis_1">Creator Royalty</p>
            )}
            {first_buyer_royalty == 0 ? null : (
              <p className="nm_bis_1"> First Buyer</p>
            )}
            {second_buyer_royalty == 0 ? null : (
              <p className="nm_bis_1"> Second Buyer</p>
            )}
          </div>
          <div className="amnt_bid_list">
            <p className="nm_bis_2">₹{location.state.amount}</p>
            <p className="nm_bis_2">₹{fees_count}</p>
            <p className="nm_bis_2">₹{company_royalty}</p>
            {creator_royalty == 0 ? null : (
              <p className="nm_bis_2">₹{creator_royalty}</p>
            )}
            {first_buyer_royalty == 0 ? null : (
              <p className="nm_bis_2">₹{first_buyer_royalty}</p>
            )}
            {second_buyer_royalty == 0 ? null : (
              <p className="nm_bis_1">{second_buyer_royalty}</p>
            )}
          </div>
        </div>
        <hr />
        <div className="bid_total">
          <div className="nm_bid_total">
            <p className="total_nm_list">Total</p>
          </div>
          <div className="amnt_bid_list">
            <p className="total_nm_list">
              ₹
              {parseFloat(
                parseFloat(location.state.amount) +
                  //   parseFloat(this.state.benifit_price) +
                  parseFloat(fees_count) +
                  parseFloat(company_royalty) +
                  parseFloat(creator_royalty) +
                  parseFloat(first_buyer_royalty) +
                  parseFloat(second_buyer_royalty)
              ).toFixed(2)}
            </p>
          </div>
        </div>
        <div className="list_bid_btn">
          <button
            onClick={() => {
              navigate("/bids");
            }}
            className="v2modal_btn2"
            style={{ background: "gray" }}
          >
            Cancel
          </button>
          <button
            onClick={() => {
              // Usercheckotp();
              makePayment();
              // setisisModalOpen(true);
            }}
            className="v2modal_btn2"
          >
            Place bid
          </button>
        </div>
      </div>
      {/* </div> */}
      {showModal && (
        // <CusModal
        //   msg={errorMessage}
        //   setShowModal={setShowModal}
        //   showModal={showModal}
        // />

        // <NewModal
        //   title=""
        //   msg={errorMessage}
        //   setShowModal={openmodal}
        //   closemodal={closemodal}
        // />

        <V2Modal
          canclebtn={false}
          title=""
          msg={errorMessage}
          setShowModal={setShowModal}
          okfunction={closemodal}
        />
      )}
      {walletmodel && (
        // <div className="cusModal_main_wrapp">
        //   <div className="cusmodal_inner_wrapp">
        //     <img src={images.logo} alt="" className="cm_logo" />
        //     <p className="cm_heading"></p>
        //     <div className="cm_des_wrapp">
        //       <p className="cm_des">Please recharge your wallet now</p>
        //       <div className="cm_hr"></div>
        //       <button className="cm_btn" onClick={() => navigate("/wallet")}>
        //         Okay
        //       </button>
        //     </div>
        //   </div>
        // </div>

        // <ReactModal
        //   isOpen={setwalletmodel}
        //   onRequestClose={closemodal}
        //   style={customStyles}
        // >
        //   <div className="NewModal_main_wrapp">
        //     <div
        //       style={{
        //         display: "flex",
        //         flexDirection: "column",
        //         alignItems: "center",
        //         justifyContent: "center",
        //       }}
        //     >
        //       <img className="newmodal_logo" src={images.logo} alt="" />
        //       <p className="newmodal_titel"></p>
        //     </div>

        //     <p className="newmodal_des">Please recharge your wallet now</p>

        //     <button
        //       className="newmodal_btn"
        //       onClick={() => navigate("/wallet")}
        //     >
        //       Okay
        //     </button>
        //   </div>
        // </ReactModal>

        <V2Modal
          canclebtn={false}
          title=""
          msg="Please recharge your wallet now"
          setShowModal={setwalletmodel}
          okfunction={() => {
            navigate("/wallet");
            setwalletmodel(false);
          }}
        />
      )}
      {/* <div className="list_bid_btn">
        <button className="list_bid_btn1">Cancel</button>
        <button className="list_bid_btn1">Place bid</button>
      </div> */}
      {/* Step 4: Render the modal when isModalOpen is true */}
      {isModalOpen && (
        // Apply CSS styles for the bottom modal
        <div className="modal_bottom">
          <div className="modal-content1">
            <center>
              <img src={images.logo} className="bidlist_img2" alt="" />
            </center>
            <p className="bidslist_new_nm">Enter m-Pin</p>
            <div>
              <div className="otp_input_container">
                {mpin.map((digit, index) => {
                  return (
                    <input
                      key={index}
                      // type="tel"
                      type="password"
                      value={digit}
                      className="mobile_int_otp2"
                      onChange={(e) => handleMpinChange(index, e.target.value)}
                      maxLength={1}
                      ref={(input) => (mpinRefs.current[index] = input)}
                      inputMode="numeric"
                    />
                  );
                })}
              </div>
            </div>
            <div className="list_bid_btn2">
              <button
                className="list_bid_btn3"
                onClick={() => {
                  setisisModalOpen(false);
                }}
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  Updatebid();
                }}
                className="list_bid_btn1"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Bidlist;
