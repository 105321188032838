import React, { useState } from "react";
import "./aadharcard.css";
import images from "../../constants/images";
import { useLocation, useNavigate } from "react-router-dom";
import NavbarSec from "../../common/NavbarSec";
import axios from "axios";
import qs from "qs";
import { useEffect } from "react";
import NewModal from "../../common/newmodal/NewModal";

const Gst = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [aadharNumber, setAadharNumber] = useState("");
  const [panNumber, setPanNumber] = useState("");
  const [isValidAadhar, setIsValidAadhar] = useState(true);
  const [getcapchaImg, setCapchaImg] = useState("");
  const [getcapchaCode, setCapchaCode] = useState("");
  const [gettranzactionId, setTranzactionId] = useState("");

  //   useEffect(() => {
  //     GetCapcha();
  //   }, []);

  const [textInputValue, setTextInputValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [cusmsg, setCusmsg] = useState("");
  const [bodyScrollEnabled, setBodyScrollEnabled] = useState(true);
  const [isValid, setIsValid] = useState(true);

  // const userId = localStorage.getItem("user_id");

  // const user_id = useSelector((state) => state.userDetails.user_id);
  // const dispatch = useDispatch();

  useEffect(() => {
    if (isModalVisible) {
      // Disable body scrolling
      setBodyScrollEnabled(false);
    } else {
      // Enable body scrolling
      setBodyScrollEnabled(true);
    }
    if (bodyScrollEnabled) {
      document.body.style.overflow = "visible";
    } else {
      document.body.style.overflow = "hidden";
    }
  }, [isModalVisible, bodyScrollEnabled]);

  const validatePanNumber = () => {
    // Regular expression for PAN card validation
    const panRegex = /^([A-Z]){5}([0-9]){4}([A-Z]){1}$/;

    if (panRegex.test(panNumber.toUpperCase())) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  const skipGst = () => {
    setIsLoading(true);
    const param = {
      // gst_number: panNumber,
      user_id: location.state.user_id,
    };
    // Make the API request
    axios
      .post("https://www.ravi.host/api/user_gst_skip", qs.stringify(param))
      .then((response) => {
        if (response.data.status === 1) {
          console.log("response.data", response.data);
          setIsLoading(false);
          // navigate("/aadharcardotp", {
          //   state: {
          //     adhar_no: response.data.result.adhar_no,
          //     transaction_id: response.data.result.transaction_id,
          //     user_id: location.state.user_id,
          //   },
          // });
          navigate("/Newbankaccountadd", {
            state: {
              user_id: location.state.user_id,
            },
          });
        } else {
          setIsModalVisible(true);
          setCusmsg("Please enter valid GST Number");
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      });

    setTextInputValue(textInputValue);
  };

  const updateText = () => {
    if (panNumber === "") {
      setIsModalVisible(true);
      setCusmsg("Please Enter GST number");
      return;
    }
    //  else if (!isValid) {
    //   setIsModalVisible(true);
    //   setCusmsg("Invalid PAN card number. Please enter a valid PAN.");
    // }
    else {
      setIsLoading(true);
      const param = {
        gst_number: panNumber,
        user_id: location.state.user_id,
      };
      // Make the API request
      axios
        .post("https://www.ravi.host/api/gst_user", qs.stringify(param))
        .then((response) => {
          if (response.data.status === 1) {
            console.log("response.data", response.data);
            setIsLoading(false);
            // navigate("/aadharcardotp", {
            //   state: {
            //     adhar_no: response.data.result.adhar_no,
            //     transaction_id: response.data.result.transaction_id,
            //     user_id: location.state.user_id,
            //   },
            // });
            navigate("/Newbankaccountadd", {
              state: {
                user_id: location.state.user_id,
              },
            });
          } else {
            setIsModalVisible(true);
            setCusmsg("Please enter valid GST Number");
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.error(error);
        });

      setTextInputValue(textInputValue);
    }
  };

  // const handleKeyPress = (e) => {
  //   // Check if the pressed key is a dot
  //   if (!(e.charCode >= 48 && e.charCode <= 57) && e.charCode !== 8) {
  //     e.preventDefault();
  //   }
  // };

  const handleKeyPress = (e) => {
    // Check if the pressed key is a digit or a space
    if (!(e.key.match(/[0-9]/) || e.key === " " || e.key === "Backspace")) {
      e.preventDefault();
    }
  };

  const closemodal = () => {
    setIsModalVisible(false);
  };
  const openmodal = () => {
    setIsModalVisible(true);
  };

  return (
    <div className="page_main_wrapp" style={{ maxHeight: "100vh" }}>
      {isLoading ? (
        <div className="loader"></div>
      ) : (
        <div className="page_base_wrapp_2" style={{ height: "98%" }}>
          {/* <NavbarSec name="eKYC" /> */}
          {/* navbar start */}

          <div
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "30px",
              background: "black",
              zIndex: 11,
            }}
          >
            <div className="navsec_back_arrow">
              <img
                src={images.back}
                // className="nav_back_btn"
                alt=""
                onClick={() => navigate("/")}
              />
            </div>

            <b
              style={{
                color: "#fff",
                fontfamily: "var(--font-600)",
                fontsize: "14px",
              }}
            >
              GST
            </b>
          </div>

          {/* navbar end */}
          <div>
            <br />
            <center>
              <p
                style={{
                  fontfamily: "var(--font-400)",
                  color: "white",
                  fontSize: "14px",
                  opacity: "0.8",
                  width: "90%",
                }}
              >
                Incorporating GST verification holds paramount importance in the
                activation of user accounts on our Aucemo.club website.
              </p>
              <button
                onClick={() => {
                  setIsModalVisible(true);
                  setCusmsg(
                    "Incorporating GST verification holds paramount importance in the activation of user accounts on our Aucemo.club website. Leveraging the GST verification process ensures a thorough and dependable validation of user identities, fostering a secure and seamless registration experience. By embracing GST verification, we fortify our platform's security measures and accelerate the account activation process, enabling users prompt and authenticated access to our array of services. Completion of GST verification stands as a prerequisite for activating user accounts, underscoring our dedication to data security and maintaining authenticity within our platform."
                  );
                }}
                style={{
                  border: "1px solid white",
                  fontFamily: "var(--font-400)",
                  color: "white",
                  fontSize: "12px",
                  borderRadius: "18px",
                  padding: "6px 12px",
                  marginTop: "10px",
                }}
              >
                Read More
              </button>
            </center>
            <br />
            {/* aadhaar card number start */}

            <div className="addhar_no_main_wrapp">
              {/* <img src={getcapchaImg} className="aadhar_capcha_img" alt="" /> */}
              <p
                style={{
                  fontSize: 12,
                  fontFamily: "var(--font-400)",
                  color: "#fff",
                  marginTop: 30,
                }}
              >
                Enter GST Number
              </p>
              <input
                style={{
                  background: "#161616",
                  borderRadius: "6px",
                  padding: "12px 16px",
                }}
                // onKeyPress={handleKeyPress}
                className="form_input_box"
                id="panNumber"
                placeholderTextColor="gray"
                value={panNumber}
                // onChange={(e) => setPanNumber(e.target.value)}
                onChange={(e) => {
                  const inputValue = e.target.value.toUpperCase();
                  setPanNumber(inputValue);
                  //   validatePanNumber(); // Validate as the user types
                }}
                type="text"
              />
            </div>

            {/* aadhaar card number end */}
          </div>
          <button
            className="login-button mx-1"
            onClick={() => {
              updateText();
            }}
            style={{ marginTop: " 16%" }}
          >
            SUBMIT
          </button>
          <button
            className="login-button mx-1"
            onClick={() => {
              skipGst();
            }}
          >
            SKIP
          </button>
        </div>
      )}

      {isModalVisible && (
        // <div className="modal_login">
        //   <div
        //     className="modal-content_login"
        //     style={{ backgroundColor: "#333333" }}
        //   >
        //     <div className="modal_div1">
        //       <img className="forgot_main" src={images.logo} alt="" />
        //     </div>
        //     <div className="modal_div">
        //       <b>{""}</b>
        //       <br />
        //       <p
        //         className="modal-message"
        //         style={{ fontFamily: "var(--font-400)" }}
        //       >
        //         {" "}
        //         {cusmsg}
        //       </p>
        //       <br />
        //     </div>
        //     <hr className="hr_forgot" />
        //     <br />
        //     <div className="modal_div">
        //       <button
        //         className="close_forgot"
        //         onClick={() => {
        //           setIsModalVisible(false);
        //         }}
        //       >
        //         Okay
        //       </button>
        //     </div>
        //   </div>
        // </div>

        <NewModal
          title=""
          msg={cusmsg}
          setShowModal={openmodal}
          closemodal={closemodal}
        />
      )}
    </div>
  );
};

export default Gst;
