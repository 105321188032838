import axios from "axios";
import React, { useContext, useEffect, useReducer } from "react";
import {
  GET_USER_WALLET_BEGIN,
  GET_USER_WALLET_FAIL,
  GET_USER_WALLET_SUCCESS,
  GET_USER_WALLET_RECHARGE_BEGIN,
  GET_USER_WALLET_RECHARGE_FAIL,
  GET_USER_WALLET_RECHARGE_SUCCESS,
  GET_USER_WALLET_WITHDREW_BEGIN,
  GET_USER_WALLET_WITHDREW_FAIL,
  GET_USER_WALLET_WITHDREW_SUCCESS,
} from "../Action";
import {
  user_wallet,
  recharge_user_wallet,
  user_withdrawal_request,
} from "../utils/Constant";
import { useLocation, useNavigate } from "react-router-dom";
import user_wallet_reducer from "../reducer/user_wallet_reducer";

const initialState = {
  get_user_wallet_loading: false,
  get_userwallet_data: [],
  get_userwallet_recharge_data: "",
  get_user_wallet_recharge_loading: false,
  total_amount: 0,
  get_user_wallet_withdrew_loading: false,
  get_userwallet_withdrew_data: "",
};

const Userwalletcontext = React.createContext();
export const UserWalletProvider = ({ children }) => {
  const [state, dispatch] = useReducer(user_wallet_reducer, initialState);

  // get all club

  const get_user_wallet = async (params, setcusmodal1, setcusmsg1) => {
    console.log("params wallet", params);
    dispatch({ type: GET_USER_WALLET_BEGIN });
    try {
      const response = await axios.post(user_wallet, params);
      const userwalletdata = response.data;
      //   console.log("====iii", userwalletdata);
      if (userwalletdata.status == 1) {
        dispatch({ type: GET_USER_WALLET_SUCCESS, payload: userwalletdata });
      } else {
        dispatch({ type: GET_USER_WALLET_FAIL });
        // setcusmodal1(false);
        // setcusmsg1(response.data.message);
      }
    } catch (error) {
      dispatch({ type: GET_USER_WALLET_FAIL });
    }
  };

  // get_user_wallet_recharge
  const getUserWalletRecharge = (
    params,
    setcusmodal1,
    setcusmsg1,
    setAddLoading
  ) => {
    console.log("params_recharge", params);
    dispatch({ type: GET_USER_WALLET_RECHARGE_BEGIN });
    try {
      setAddLoading(true);
      const response = axios.post(recharge_user_wallet, params);
      console.log("====reachrgeS", response.data.status);
      if (response.data.status == 1) {
        dispatch({
          type: GET_USER_WALLET_RECHARGE_SUCCESS,
          payload: response.data,
        });
        setcusmodal1(true);
        setAddLoading(false);
        setcusmsg1(response.data.message);
        sessionStorage.clear();
        localStorage.removeItem("allres");
        sessionStorage.removeItem("add_amount");
        sessionStorage.removeItem("allres");
      } else {
        setcusmodal1(true);
        setAddLoading(false);
        setcusmsg1(response.data.message);
        // sessionStorage.clear();
        // localStorage.removeItem("allres");
      }
    } catch (error) {
      setAddLoading(false);
      dispatch({ type: GET_USER_WALLET_RECHARGE_FAIL });
      // sessionStorage.clear();
      // localStorage.removeItem("allres");
    }
  };

  // get USER_WALLET_ WITHDREW

  const getUserWalletwithdrew = async (
    params,
    setcusmodal1,
    setcusmsg1,
    setWidLoading
  ) => {
    console.log("params_withdrew", params);
    dispatch({ type: GET_USER_WALLET_WITHDREW_BEGIN });
    try {
      setWidLoading(true);
      const response = await axios.post(user_withdrawal_request, params);
      console.log("====withdrew", response.data.status);
      if (response.data.status == 1) {
        dispatch({
          type: GET_USER_WALLET_WITHDREW_SUCCESS,
          payload: response.data,
        });
        setWidLoading(false);
        setcusmodal1(true);
        setcusmsg1(response.data.message);
      } else {
        setWidLoading(false);
        setcusmodal1(true);
        setcusmsg1(response.data.message);
      }
    } catch (error) {
      setWidLoading(false);
      dispatch({ type: GET_USER_WALLET_WITHDREW_FAIL });
    }
  };

  return (
    <Userwalletcontext.Provider
      value={{
        ...state,
        get_user_wallet,
        getUserWalletRecharge,
        getUserWalletwithdrew,
      }}
    >
      {children}
    </Userwalletcontext.Provider>
  );
};

export const useWalletContext = () => {
  return useContext(Userwalletcontext);
};
