import React from "react";
import "./EditShareAuction.css";
import NavbarSec from "../../common/NavbarSec";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import qs from "qs";
import Slider from "react-slick";
import images from "../../constants/images";
import { get_all_club_2, get_manager_all_user } from "../../utils/Constant";
import ReactModal from "react-modal";
import V2Modal from "../../common/v2modal/V2Modal";

const EditShareAuction = () => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "0px",
      backgroundColor: "none",
      border: "none",
      borderRadius: "12px",
    },
    overlay: {
      zIndex: 1000,
      backgroundColor: "rgba(0, 0, 0, 0.3)",
    },
  };
  const location = useLocation();
  const navigate = useNavigate();
  const userId = localStorage.getItem("user_id");

  const [ismodalvisible_api, setIsModalVisibleapi] = useState(false);
  //   const [data_filter_data, setDataFilterData] = useState([]);
  const [taskArrayHolder, setTaskArrayHolder] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedId, setSelectedId] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setModalIsVisible] = useState(false);
  const [cusmsg, setCusmsg] = useState("");
  const [productImgVideo, setProductImgVideo] = useState([]);
  const [getproductDetails, setProductDetails] = useState({});

  const [getavailableBond, setAvailableBond] = useState(
    location.state.remaining_bond
  );
  const [getsharenfbModal, setSharenfbModal] = useState(false);
  const [getminimumBid, setMinimumBid] = useState("");
  const [getauctionDurtion, setAuctoinDuration] = useState("");
  const [getuserlistModal, setUserListModal] = useState(false);
  const [getnoOfBond, setNoOfBond] = useState(null);
  const [getnoOfBondClub, setNoOfBondClub] = useState(null);
  const [getdonationOwnerName, setDonationOwnerName] = useState("");
  const [getdonationOwnerId, setDonationOwnerId] = useState("");
  const [getdonationClubName, setDonationClubName] = useState("");
  const [getdonationClubId, setDonationClubId] = useState("");
  const [getshareOwnerModal, setShareOwnerModal] = useState(false);
  const [getshareClubModal, setShareClubModal] = useState(false);
  const [getalluser, setalluser] = useState([]);
  const [getallclubs, setallClubs] = useState([]);
  const [getsearchallclubs, setSearchallClubs] = useState([]);
  const [data_filter_data, setdata_filter_data] = useState([]);
  const [getallmanager, setallmanager] = useState([]);
  const [getsearchManagerData, setSearchManagerData] = useState([]);
  const [getownerName, setOwnerName] = useState("");
  const [getownerId, setOwnerId] = useState("");
  const [getisUserAndClub, setIsUserAndClub] = useState(0);
  const [errorMessage1, setErrorMessage1] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showModal1, setShowModal1] = useState(false);
  const [getdonetQrhunt, setDonetQrhunt] = useState(0);

  const [
    getnumber_of_bonds_created_for_share,
    setnumber_of_bonds_created_for_share,
  ] = useState([]);

  const [
    getnumber_of_bonds_created_for_share_name,
    setnumber_of_bonds_created_for_share_name,
  ] = useState([]);

  const [
    getnumber_of_bonds_created_for_share_id,
    setnumber_of_bonds_created_for_share_id,
  ] = useState([]);

  // club and

  const [
    getclub_number_of_bonds_created_for_share,
    setclub_number_of_bonds_created_for_share,
  ] = useState([]);

  const [
    getclub_number_of_bonds_created_for_share_name,
    setclub_number_of_bonds_created_for_share_name,
  ] = useState([]);

  const [
    getclub_number_of_bonds_created_for_share_id,
    setclub_number_of_bonds_created_for_share_id,
  ] = useState([]);

  const ddd = getavailableBond - getdonetQrhunt;

  const bondissueSettings = {
    dots: true, // Show dots for navigation
    infinite: true, // Infinite loop
    speed: 500, // Transition speed in milliseconds
    slidesToShow: 1, // Number of slides to show at once
    slidesToScroll: 1, // Number of slides to scroll per interaction
  };

  useEffect(() => {
    getUser();
    getAllClubs();
    getManager();
    getProductDetails();
  }, []);

  const getUser = () => {
    setIsLoading(true);
    const param = { userId };
    axios
      .post("https://www.ravi.host/api/getall_user", qs.stringify(param))
      .then((response) => {
        setIsLoading(false);
        setTaskArrayHolder(response.data.result);
        setalluser(response.data.result);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error fetching user data:", error);
      });
  };

  const getAllClubs = () => {
    const param = {
      user_id: userId,
    };
    axios
      .post(get_all_club_2, param)
      .then((Response) => {
        console.log(
          "Response.data.result",
          JSON.stringify(Response.data.result, null, 2)
        );
        setallClubs(Response.data.result);
        setSearchallClubs(Response.data.result);
        // this.setState({
        //   // data: Response.data.result,
        //   task_arrayholder: Response.data.result,
        // });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getManager = async () => {
    const param = {
      user_id: userId,
    };
    await axios
      .post(get_manager_all_user, param)
      .then((Response) => {
        console.log(
          "Response.data.result",
          JSON.stringify(Response.data.result, null, 2)
        );
        setallmanager(Response.data.result);
        setSearchManagerData(Response.data.result);
        // this.setState({
        //   // data: Response.data.result,
        //   task_arrayholder: Response.data.result,
        // });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getProductDetails = () => {
    setIsLoading(true);
    const param = { product_id: location.state.product_id, user_id: userId };
    axios
      .post("https://www.ravi.host/api/preview_product", qs.stringify(param))
      .then((response) => {
        console.log("response", response.data.result.product_img_video);
        setIsLoading(false);
        if (response.data.status === 1) {
          setProductDetails(response.data.result);
          setProductImgVideo(response.data.result.product_img_video);
        } else {
          setModalIsVisible(true);
          setCusmsg(response.data.message);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error fetching product details:", error);
      });
  };

  const handleShareUserAndClubChange = (event) => {
    setIsUserAndClub(event.target.value);
    console.log("event.target.value", event.target.value);
    // setnumber_of_bonds_created_for_share([]);
    // {
    //   (selectedCreator == 1 || selectedCreator == 3) && GetEditProfileApi();
    // }
  };

  const Search_user = (value) => {
    const newData = getalluser.filter(function (item) {
      const employee = item.label ? item.label.toUpperCase() : "".toUpperCase();
      const number = item.phone ? item.phone.toUpperCase() : "".toUpperCase();
      const email = item.email ? item.email.toUpperCase() : "".toUpperCase();

      const textData = value.toUpperCase();
      return (
        employee.indexOf(textData) > -1 ||
        number.indexOf(textData) > -1 ||
        email.indexOf(textData) > -1
      );
    });
    setdata_filter_data(newData);
    // console.log("asvsvsds", newData);
    console.log("asvsvsds", getalluser);
  };

  const Search_manager = (value) => {
    const newData = getsearchManagerData.filter(function (item) {
      const employee = item.label ? item.label.toUpperCase() : "".toUpperCase();
      const number = item.phone ? item.phone.toUpperCase() : "".toUpperCase();
      // const email = item.email ? item.email.toUpperCase() : "".toUpperCase();

      const textData = value.toUpperCase();
      return employee.indexOf(textData) > -1 || number.indexOf(textData) > -1;
    });
    setallmanager(newData);
    console.log("asvsvsds", newData);
  };

  const Search_clubs = (value) => {
    const newData = getsearchallclubs.filter(function (item) {
      const employee = item.club_name
        ? item.club_name.toUpperCase()
        : "".toUpperCase();
      const number = item.phone_number
        ? item.phone_number.toUpperCase()
        : "".toUpperCase();

      // const email = item.email ? item.email.toUpperCase() : "".toUpperCase();

      const textData = value.toUpperCase();
      return employee.indexOf(textData) > -1 || number.indexOf(textData) > -1;
    });
    // console.log("newData", newData);
    setallClubs(newData);
    console.log("asvsvsds", newData);
  };
  // share nfb list delete btn start

  const handleShareDelete = (item, index) => {
    setAvailableBond(
      (prevAvailableNoOfBond) => prevAvailableNoOfBond + parseInt(item)
    );

    const updatedDataName = [...getnumber_of_bonds_created_for_share_name];
    updatedDataName.splice(index, 1);
    setnumber_of_bonds_created_for_share_name(updatedDataName);

    const updatedDataShare = [...getnumber_of_bonds_created_for_share];
    updatedDataShare.splice(index, 1);
    setnumber_of_bonds_created_for_share(updatedDataShare);

    const updatedDataId = [...getnumber_of_bonds_created_for_share_id];
    updatedDataId.splice(index, 1);
    setnumber_of_bonds_created_for_share_id(updatedDataId);
  };

  // share nfb list delete btn end

  // share club nfb list delete btn start

  const handleClubShareDelete = (item, index) => {
    setAvailableBond(
      (prevAvailableNoOfBond) => prevAvailableNoOfBond + parseInt(item)
    );

    const updatedDataName = [...getclub_number_of_bonds_created_for_share_name];
    updatedDataName.splice(index, 1);
    setclub_number_of_bonds_created_for_share_name(updatedDataName);

    const updatedDataShare = [...getclub_number_of_bonds_created_for_share];
    updatedDataShare.splice(index, 1);
    setclub_number_of_bonds_created_for_share(updatedDataShare);

    const updatedDataId = [...getclub_number_of_bonds_created_for_share_id];
    updatedDataId.splice(index, 1);
    setclub_number_of_bonds_created_for_share_id(updatedDataId);
  };

  // share club nfb list delete btn end

  const closemodal1 = () => {
    setIsModalVisibleapi(false);
  };

  return (
    <div className="page_main_wrapp">
      {isLoading ? (
        <div className="loader"></div>
      ) : (
        <div className="page_base_wrapp_2">
          <NavbarSec name="Share Aucemo" />
          <div className="esa_first_wrapp">
            <p>1. Product</p>
            <div className="esa_hr" />
          </div>
          <br />
          <Slider {...bondissueSettings}>
            {/* Use the Slider component with your settings */}
            {getproductDetails &&
            getproductDetails.product_img_video &&
            getproductDetails.product_img_video.length > 0
              ? getproductDetails.product_img_video.map((item, index) => {
                  console.log("item", item);
                  return (
                    <div>
                      <img
                        src={item.product_icon}
                        className="Collaboratenfb_img"
                        alt=""
                      />
                    </div>
                  );
                })
              : null}
            {/* Add additional slides here */}
          </Slider>

          <div className="esa_third_wrapp">
            <p className="esa_name">{getproductDetails.product_name}</p>
            <p className="esa_pvd">₹ {getproductDetails.product_value_data}</p>
          </div>
          <p className="esa_pv">
            Minimum Aucemo value : &nbsp;₹ {getproductDetails.product_value}
          </p>
          <br />
          <button
            onClick={() => setSharenfbModal(true)}
            className="esa_share_btn"
          >
            Gift Aucemo with other users
          </button>

          <div style={{ width: "100%", margin: "10px auto" }}>
            {/* share lists start */}
            <div>
              {getnumber_of_bonds_created_for_share &&
              getnumber_of_bonds_created_for_share.length > 0
                ? getnumber_of_bonds_created_for_share.map((item, index) => {
                    return (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <p
                          style={{
                            color: "var(--color-white)",
                            fontSize: "12px",
                          }}
                        >
                          {getnumber_of_bonds_created_for_share_name[index]} -{" "}
                          {item}
                        </p>
                        <button
                          onClick={() => {
                            handleShareDelete(item, index);
                          }}
                        >
                          <img
                            src={images.close_red}
                            style={{
                              width: "24px",
                              height: "24px",
                              objectFit: "contain",
                            }}
                            alt=""
                          />
                        </button>
                      </div>
                    );
                  })
                : null}
            </div>
            {/* share lists end */}

            {/* club share lists start */}

            <div>
              {getclub_number_of_bonds_created_for_share &&
              getclub_number_of_bonds_created_for_share.length > 0
                ? getclub_number_of_bonds_created_for_share.map(
                    (item, index) => {
                      return (
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <p
                            style={{
                              color: "var(--color-white)",
                              fontSize: "12px",
                            }}
                          >
                            {
                              getclub_number_of_bonds_created_for_share_name[
                                index
                              ]
                            }{" "}
                            - {item}
                          </p>
                          <button
                            onClick={() => {
                              handleClubShareDelete(item, index);
                            }}
                          >
                            <img
                              src={images.close_red}
                              style={{
                                width: "24px",
                                height: "24px",
                                objectFit: "contain",
                              }}
                              alt=""
                            />
                          </button>
                        </div>
                      );
                    }
                  )
                : null}
            </div>

            {/* club share lists end */}
          </div>

          {/* <br /> */}
          {/* <br /> */}
          <button
            className="esa_save_btn"
            onClick={() => {
              setIsModalVisibleapi(true);
            }}
          >
            Save and Share
          </button>
        </div>
      )}

      {/* gift modal start */}

      {getsharenfbModal && (
        <button
          // onClick={() => setSharenfbModal(false)}
          className="cusModal_main_wrapp"
          style={{ zIndex: 100 }}
        >
          <div
            className="cusmodal_inner_wrapp"
            // style={{ position: "fixed" }}
          >
            <img src={images.logo} alt="" className="cm_logo" />
            <p className="available_nfb_modal_heading">
              How many Aucemo you want to gift ? <br /> Available Aucemo{" "}
              {getavailableBond}
            </p>
            <div id="custom_radio" style={{ width: "90%" }}>
              <label class="custom_radio">
                <input
                  type="radio"
                  name="share_checkbox"
                  value={0}
                  checked={getisUserAndClub == 0}
                  onChange={handleShareUserAndClubChange}
                />
                <span class="checkmark"></span>
                User
              </label>
              <label class="custom_radio">
                <input
                  type="radio"
                  name="share_checkbox"
                  value={1}
                  checked={getisUserAndClub == 1}
                  onChange={handleShareUserAndClubChange}
                />
                <span class="checkmark"></span>
                Club
              </label>
            </div>
            <>
              {getisUserAndClub == 0 ? (
                <div className="cm_des_wrapp">
                  {/* <input
                className="available_nfb_modal_input"
                placeholder="Search Username"
              /> */}
                  <input
                    onClick={() => {
                      setShareOwnerModal(true);
                      // setDonationSelect(true);
                      setSharenfbModal(false);
                    }}
                    value={getdonationOwnerName}
                    type="text"
                    name="product_name"
                    className="available_nfb_modal_input"
                    placeholder="Search Username"
                    // onChange={(e) => {
                    //   setGetcreatorname(e.target.value);
                    // }}
                  />
                  <input
                    className="available_nfb_modal_input"
                    placeholder="Enter number of Aucemo"
                    value={getnoOfBond}
                    onChange={(e) => {
                      // setNoOfBond(e.target.value)
                      const inputValue = e.target.value;
                      const isValid =
                        /^\d+$/.test(inputValue) &&
                        parseInt(inputValue, 10) >= 0;

                      if (!isValid) {
                        setNoOfBond("");
                        setErrorMessage1(
                          "Please enter a valid number of Aucemo"
                        );
                        setShowModal1(true);
                      } else {
                        setNoOfBond(parseInt(inputValue, 10));
                        console.log("isValid", inputValue);
                      }
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "10%",
                    }}
                  >
                    <button
                      className="cm_btn"
                      onClick={() => {
                        if (getdonationOwnerName == "") {
                          alert("Search username and select");
                        } else if (getnoOfBond == null) {
                          alert("Please enter number of Aucemo");
                        } else if (getnoOfBond == 0) {
                          alert("You can't make a stock of zero Aucemo");
                        } else if (getnoOfBond > getavailableBond) {
                          alert(
                            "Enter Aucemo value below " +
                              getavailableBond +
                              "."
                          );
                        } else if (ddd < getnoOfBond) {
                          setShowModal(true);
                          setErrorMessage(
                            "Enter Aucemo value below " + ddd + "."
                          );
                        } else {
                          setnumber_of_bonds_created_for_share([
                            ...getnumber_of_bonds_created_for_share,
                            getnoOfBond,
                          ]);
                          setnumber_of_bonds_created_for_share_name([
                            ...getnumber_of_bonds_created_for_share_name,
                            getdonationOwnerName,
                          ]);
                          setnumber_of_bonds_created_for_share_id([
                            ...getnumber_of_bonds_created_for_share_id,
                            getdonationOwnerId,
                          ]);
                          setAvailableBond(
                            parseInt(getavailableBond) - parseInt(getnoOfBond)
                          );
                          setNoOfBond(null);
                          setDonationOwnerName("");
                          setDonationOwnerId("");
                          setSharenfbModal(false);
                        }
                      }}
                    >
                      Save
                    </button>

                    <button
                      className="cm_btn"
                      onClick={() => {
                        setNoOfBond(null);
                        setNoOfBondClub(null);
                        setDonationOwnerName("");
                        setDonationClubName("");
                        setDonationOwnerId("");
                        setDonationClubId("");
                        setSharenfbModal(false);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              ) : (
                <div className="cm_des_wrapp">
                  {/* <input
                className="available_nfb_modal_input"
                placeholder="Search Username"
              /> */}
                  <input
                    onClick={() => {
                      setShareClubModal(true);
                      // setDonationSelect(true);
                      setSharenfbModal(false);
                    }}
                    value={getdonationClubName}
                    type="text"
                    name="product_name"
                    className="available_nfb_modal_input"
                    placeholder="Search Username"
                    // onChange={(e) => {
                    //   setGetcreatorname(e.target.value);
                    // }}
                  />
                  <input
                    className="available_nfb_modal_input"
                    placeholder="Enter number of Aucemo"
                    value={getnoOfBondClub}
                    onChange={(e) => {
                      // setNoOfBondClub(e.target.value);
                      const inputValue = e.target.value;
                      const isValid =
                        /^\d+$/.test(inputValue) &&
                        parseInt(inputValue, 10) >= 0;

                      if (!isValid) {
                        setNoOfBondClub("");
                        setErrorMessage1(
                          "Please enter a valid number of Aucemo"
                        );
                        setShowModal1(true);
                      } else {
                        setNoOfBondClub(parseInt(inputValue, 10));
                        console.log("isValid", inputValue);
                      }
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "10%",
                    }}
                  >
                    <button
                      className="cm_btn"
                      onClick={() => {
                        if (getdonationClubName == "") {
                          alert("Search club and select");
                        } else if (getnoOfBondClub == null) {
                          alert("Please enter number of Aucemo");
                        } else if (getnoOfBondClub == 0) {
                          alert("You can't make a stock of zero Aucemo");
                        } else if (getnoOfBondClub > getavailableBond) {
                          alert(
                            "Enter Aucemo value below " +
                              getavailableBond +
                              "."
                          );
                        } else if (ddd < getnoOfBondClub) {
                          setShowModal(true);
                          setErrorMessage(
                            "Enter Aucemo value below " + ddd + "."
                          );
                        } else {
                          setclub_number_of_bonds_created_for_share([
                            ...getclub_number_of_bonds_created_for_share,
                            getnoOfBondClub,
                          ]);
                          setclub_number_of_bonds_created_for_share_name([
                            ...getclub_number_of_bonds_created_for_share_name,
                            getdonationClubName,
                          ]);
                          setclub_number_of_bonds_created_for_share_id([
                            ...getclub_number_of_bonds_created_for_share_id,
                            getdonationClubId,
                          ]);
                          setAvailableBond(
                            parseInt(getavailableBond) -
                              parseInt(getnoOfBondClub)
                          );
                          setNoOfBondClub(null);
                          setDonationClubName("");
                          setDonationClubId("");
                          setSharenfbModal(false);
                        }
                      }}
                    >
                      Save
                    </button>

                    <button
                      className="cm_btn"
                      onClick={() => {
                        setNoOfBond(null);
                        setNoOfBondClub(null);
                        setDonationOwnerName("");
                        setDonationClubName("");
                        setDonationOwnerId("");
                        setDonationClubId("");
                        setSharenfbModal(false);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              )}
            </>
          </div>
        </button>
      )}

      {/* gift modal end */}

      {/* share owner modal start */}
      {getshareOwnerModal && (
        <div
          className="creator_modal_main_warp"
          style={{ zIndex: 112, background: "#000", position: "fixed" }}
        >
          <div className="creator_modal_base_warp">
            <div className="cp_navbar_wrapp">
              <img
                onClick={() => {
                  setShareOwnerModal(false);
                  setSharenfbModal(true);
                }}
                src={images.back}
                className="nav_back_btn"
                alt=""
              />
            </div>
          </div>
          <div className="search mt-2">
            <input
              type="text"
              placeholder="Search"
              // value={getsearchData}
              onChange={(e) => {
                Search_user(e.target.value);
              }}
            />
            <img className="search-img" src={images.search} alt="" />
          </div>
          <div style={{ width: "100%", height: "80vh", overflow: "scroll" }}>
            {data_filter_data.map((item, index) => (
              <div
                onClick={() => {
                  // selecteduser(item);
                  setShareOwnerModal(false);
                  setSharenfbModal(true);
                  setDonationOwnerName(item.label);
                  setDonationOwnerId(item.id);
                }}
                key={index}
                className="item-container"
              >
                <img src={item.profile} alt={item.label} />
                <div>{item.label}</div>
              </div>
            ))}
          </div>
        </div>
      )}
      {/* share owner modal end */}

      {/* share club modal start */}
      {getshareClubModal && (
        <div
          className="creator_modal_main_warp"
          style={{ zIndex: 112, background: "#000", position: "fixed" }}
        >
          <div className="creator_modal_base_warp">
            <div className="cp_navbar_wrapp">
              <img
                onClick={() => {
                  setShareClubModal(false);
                  setSharenfbModal(true);
                }}
                src={images.back}
                className="nav_back_btn"
                alt=""
              />
            </div>
          </div>
          <div className="search mt-2">
            <input
              type="text"
              placeholder="Search"
              // value={getsearchData}
              onChange={(e) => {
                Search_clubs(e.target.value);
              }}
            />
            <img className="search-img" src={images.search} alt="" />
          </div>
          <div style={{ width: "100%", height: "80vh", overflow: "scroll" }}>
            {getallclubs.map((item, index) => (
              // console.log('item', item)
              <div
                onClick={() => {
                  // selecteduser(item);
                  setShareClubModal(false);
                  setSharenfbModal(true);
                  setDonationClubName(item.label);
                  setDonationClubId(item.club_id);
                  // console.log("item", item);
                }}
                key={index}
                className="item-container"
              >
                <img src={item.profile} alt={item.label} />
                <div>{item.label}</div>
              </div>
            ))}
          </div>
        </div>
      )}
      {/* share club modal end */}

      {/* share api modal start */}
      {ismodalvisible_api && (
        // <div className="modal_login">
        //   <div className="modal-content_login">
        //     <div className="modal_div1">
        //       <img className="forgot_main" src={images.logo} alt="" />
        //     </div>
        //     <div className="modal_div">
        //       {/* <b>Information</b> */}
        //       <br />
        //       <p className="modal-message">
        //         Are you sure you want to share Aucemo with selected user ?
        //       </p>
        //       <br />
        //     </div>
        //     <hr className="hr_forgot" />
        //     <br />
        //     <div className="modal_two_buton">
        //       <button
        //         style={{ width: 150 }}
        //         className="close_forgot"
        //         onClick={() => {
        //           setIsLoading(true);
        //           setIsModalVisibleapi(false);
        //           let formData = new FormData();

        //           let idString_usernmae = "";
        //           let idString_number_of_bond = "";
        //           let idString_user_id = "";
        //           var demo = 0;
        //           for (
        //             let index = 0;
        //             index < getnumber_of_bonds_created_for_share.length;
        //             index++
        //           ) {
        //             demo = demo + 1;

        //             if (index > 0) {
        //               idString_usernmae += ",";
        //               idString_number_of_bond += ",";
        //               idString_user_id += ",";
        //             }
        //             idString_usernmae +=
        //               getnumber_of_bonds_created_for_share_name[index];
        //             idString_number_of_bond +=
        //               getnumber_of_bonds_created_for_share[index];
        //             idString_user_id +=
        //               getnumber_of_bonds_created_for_share_id[index];
        //           }

        //           formData.append("bond_share_owner_name", idString_usernmae);
        //           formData.append("bond_share_owner_id", idString_user_id);
        //           formData.append(
        //             "bond_share_total_assign",
        //             idString_number_of_bond
        //           );

        //           let club_idString_usernmae = "";
        //           let club_idString_number_of_bond = "";
        //           let club_idString_user_id = "";
        //           var club_demo = 0;
        //           for (
        //             let index = 0;
        //             index < getclub_number_of_bonds_created_for_share.length;
        //             index++
        //           ) {
        //             club_demo = club_demo + 1;

        //             if (index > 0) {
        //               club_idString_usernmae += ",";
        //               club_idString_number_of_bond += ",";
        //               club_idString_user_id += ",";
        //             }
        //             club_idString_usernmae +=
        //               getclub_number_of_bonds_created_for_share_name[index];
        //             club_idString_number_of_bond +=
        //               getclub_number_of_bonds_created_for_share[index];
        //             club_idString_user_id +=
        //               getclub_number_of_bonds_created_for_share_id[index];
        //           }
        //           formData.append("share_club_name", club_idString_usernmae);
        //           formData.append("share_club_id", club_idString_user_id);
        //           formData.append(
        //             "share_total_assign",
        //             club_idString_number_of_bond
        //           );
        //           formData.append("product_id", location.state.product_id);
        //           formData.append("share_user_id", userId);

        //           for (var pair of formData.entries()) {
        //             console.log(pair[0] + ", " + pair[1]);
        //           }
        //           console.log("formData ==>", formData);
        //           axios
        //             .post(
        //               "https://www.ravi.host/api/after_auction_share_bond",
        //               formData
        //             )
        //             .then((Response) => {
        //               //  setButtonDisabled(false);
        //               console.log("Response", Response);
        //               setIsLoading(false);
        //               if (Response.data.status == 1) {
        //                 navigate("/myproduct");
        //               }
        //             })
        //             .catch((err) => {
        //               //  setButtonDisabled(false);
        //               console.log("err", err);
        //               setIsLoading(false);
        //             });
        //         }}
        //       >
        //         Yes
        //       </button>
        //       <button
        //         style={{ width: 150 }}
        //         className="close_forgot"
        //         /*  onClick={() => {
        //           localStorage.setItem("modalbidconfirm", "true");
        //           setconformationmodel(false);
        //           // {
        //           // if (location.state.currenbt_stock !== 0) {
        //           // Placebiddone();
        //           // }
        //         }} */
        //       >
        //         No
        //       </button>
        //     </div>
        //   </div>
        // </div>

        // <ReactModal
        //   isOpen={setIsModalVisibleapi}
        //   // onAfterOpen={afterOpenModal}
        //   onRequestClose={closemodal1}
        //   style={customStyles}
        // >
        //   <div className="NewModal_main_wrapp">
        //     <div
        //       style={{
        //         display: "flex",
        //         flexDirection: "column",
        //         alignItems: "center",
        //         justifyContent: "center",
        //       }}
        //     >
        //       <img className="newmodal_logo" src={images.logo} alt="" />
        //       <p className="newmodal_titel"></p>
        //       {/* <p className="newmodal_titel">Alert</p> */}
        //     </div>

        //     {/* <div className="newmodal_des_wrapp"> */}
        //     <p className="newmodal_des">
        //       Are you sure you want to share Aucemo with selected user ?
        //     </p>

        //     <div className="modal_two_buton" style={{ width: "100%" }}>
        //       <button
        //         className="close_forgot"
        //         onClick={() => {
        //           setIsLoading(true);
        //           setIsModalVisibleapi(false);
        //           let formData = new FormData();

        //           let idString_usernmae = "";
        //           let idString_number_of_bond = "";
        //           let idString_user_id = "";
        //           var demo = 0;
        //           for (
        //             let index = 0;
        //             index < getnumber_of_bonds_created_for_share.length;
        //             index++
        //           ) {
        //             demo = demo + 1;

        //             if (index > 0) {
        //               idString_usernmae += ",";
        //               idString_number_of_bond += ",";
        //               idString_user_id += ",";
        //             }
        //             idString_usernmae +=
        //               getnumber_of_bonds_created_for_share_name[index];
        //             idString_number_of_bond +=
        //               getnumber_of_bonds_created_for_share[index];
        //             idString_user_id +=
        //               getnumber_of_bonds_created_for_share_id[index];
        //           }

        //           formData.append("bond_share_owner_name", idString_usernmae);
        //           formData.append("bond_share_owner_id", idString_user_id);
        //           formData.append(
        //             "bond_share_total_assign",
        //             idString_number_of_bond
        //           );

        //           let club_idString_usernmae = "";
        //           let club_idString_number_of_bond = "";
        //           let club_idString_user_id = "";
        //           var club_demo = 0;
        //           for (
        //             let index = 0;
        //             index < getclub_number_of_bonds_created_for_share.length;
        //             index++
        //           ) {
        //             club_demo = club_demo + 1;

        //             if (index > 0) {
        //               club_idString_usernmae += ",";
        //               club_idString_number_of_bond += ",";
        //               club_idString_user_id += ",";
        //             }
        //             club_idString_usernmae +=
        //               getclub_number_of_bonds_created_for_share_name[index];
        //             club_idString_number_of_bond +=
        //               getclub_number_of_bonds_created_for_share[index];
        //             club_idString_user_id +=
        //               getclub_number_of_bonds_created_for_share_id[index];
        //           }
        //           formData.append("share_club_name", club_idString_usernmae);
        //           formData.append("share_club_id", club_idString_user_id);
        //           formData.append(
        //             "share_total_assign",
        //             club_idString_number_of_bond
        //           );
        //           formData.append("product_id", location.state.product_id);
        //           formData.append("share_user_id", userId);

        //           for (var pair of formData.entries()) {
        //             console.log(pair[0] + ", " + pair[1]);
        //           }
        //           console.log("formData ==>", formData);
        //           axios
        //             .post(
        //               "https://www.ravi.host/api/after_auction_share_bond",
        //               formData
        //             )
        //             .then((Response) => {
        //               //  setButtonDisabled(false);
        //               console.log("Response", Response);
        //               setIsLoading(false);
        //               if (Response.data.status == 1) {
        //                 navigate("/myproduct");
        //               }
        //             })
        //             .catch((err) => {
        //               //  setButtonDisabled(false);
        //               console.log("err", err);
        //               setIsLoading(false);
        //             });
        //         }}
        //       >
        //         Yes
        //       </button>
        //       <button className="close_forgot" onClick={closemodal1}>
        //         No
        //       </button>
        //     </div>
        //   </div>
        // </ReactModal>

        <V2Modal
          canclebtn={true}
          title=""
          msg="Are you sure you want to share Aucemo with selected user ?"
          setShowModal={setIsModalVisibleapi}
          okfunction={() => {
            setIsLoading(true);
            setIsModalVisibleapi(false);
            let formData = new FormData();

            let idString_usernmae = "";
            let idString_number_of_bond = "";
            let idString_user_id = "";
            var demo = 0;
            for (
              let index = 0;
              index < getnumber_of_bonds_created_for_share.length;
              index++
            ) {
              demo = demo + 1;

              if (index > 0) {
                idString_usernmae += ",";
                idString_number_of_bond += ",";
                idString_user_id += ",";
              }
              idString_usernmae +=
                getnumber_of_bonds_created_for_share_name[index];
              idString_number_of_bond +=
                getnumber_of_bonds_created_for_share[index];
              idString_user_id +=
                getnumber_of_bonds_created_for_share_id[index];
            }

            formData.append("bond_share_owner_name", idString_usernmae);
            formData.append("bond_share_owner_id", idString_user_id);
            formData.append("bond_share_total_assign", idString_number_of_bond);

            let club_idString_usernmae = "";
            let club_idString_number_of_bond = "";
            let club_idString_user_id = "";
            var club_demo = 0;
            for (
              let index = 0;
              index < getclub_number_of_bonds_created_for_share.length;
              index++
            ) {
              club_demo = club_demo + 1;

              if (index > 0) {
                club_idString_usernmae += ",";
                club_idString_number_of_bond += ",";
                club_idString_user_id += ",";
              }
              club_idString_usernmae +=
                getclub_number_of_bonds_created_for_share_name[index];
              club_idString_number_of_bond +=
                getclub_number_of_bonds_created_for_share[index];
              club_idString_user_id +=
                getclub_number_of_bonds_created_for_share_id[index];
            }
            formData.append("share_club_name", club_idString_usernmae);
            formData.append("share_club_id", club_idString_user_id);
            formData.append("share_total_assign", club_idString_number_of_bond);
            formData.append("product_id", location.state.product_id);
            formData.append("share_user_id", userId);

            for (var pair of formData.entries()) {
              console.log(pair[0] + ", " + pair[1]);
            }
            console.log("formData ==>", formData);
            axios
              .post(
                "https://www.ravi.host/api/after_auction_share_bond",
                formData
              )
              .then((Response) => {
                //  setButtonDisabled(false);
                console.log("Response", Response);
                setIsLoading(false);
                if (Response.data.status == 1) {
                  navigate("/myproduct");
                }
              })
              .catch((err) => {
                //  setButtonDisabled(false);
                console.log("err", err);
                setIsLoading(false);
              });
          }}
        />
      )}
      {/* share api modal end */}
    </div>
  );
};

export default EditShareAuction;
