import React, { useEffect, useState } from "react";
import "./ViewMyCandW.css";
import NavbarSec from "../../common/NavbarSec";
import images from "../../constants/images";
import axios from "axios";
import qs from "qs";
import {
  IoIosCheckmarkCircleOutline,
  IoIosCloseCircleOutline,
} from "react-icons/io";
import NewLoader from "../../common/newloder/NewLoader";
import { FaAngleLeft, FaCheck } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { IoClose } from "react-icons/io5";
import { FiCheck } from "react-icons/fi";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { start_user_c_and_w } from "../../utils/Constant";

const ViewMyCandW = () => {
  const userId = localStorage.getItem("user_id");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  // vishal code start
  const [getdata, setData] = useState([]);
  const [getuserdata, setUserData] = useState([]);

  const _getData = () => {
    const param = {
      pending_c_and_w_id: location.state.pending_c_and_w_id,
    };
    console.log(param);
    setIsLoading(true);

    axios
      .post(
        "https://www.ravi.host/api/get_user_pending_c_and_w_details",
        qs.stringify(param)
      )
      .then((response) => {
        console.log("res", response.data);
        setIsLoading(false);

        if (response.data.status == 1) {
          setData(response.data.result);
          // setUserData(response.data.result.user_c_and_w_data);
        } else {
          setData([]);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const _start_candw = async (id) => {
    setIsLoading(true);
    const param = {
      pending_c_and_w_id: id,
    };
    await axios
      .post(start_user_c_and_w, param)
      .then((res) => {
        setIsLoading(false);
        if (res.data.status == 1) {
          toast.success(`${res.data.message}`, { theme: "colored" });
          _getData();
        } else {
          toast.success(`${res.data.message}`, { theme: "colored" });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast.success(`${err}`, { theme: "colored" });
      });
  };

  useEffect(() => {
    _getData();

    console.log("new Date", new Date());
  }, []);

  const TodayDate = new Date();

  return (
    <div className="new_wrapp_container reletive_position">
      <img
        src={images.new_bg_circule}
        alt=""
        className="back_circule_shadow_left"
      />
      {isLoading ? (
        <NewLoader />
      ) : (
        <div className="new_container">
          <div
            className="play_screen_main_wrapp"
            style={{ padding: "10px 5%" }}
          >
            <div className="legacy_dashbord_nav_sec">
              <button
                onClick={() => {
                  navigate(-1);
                }}
              >
                <FaAngleLeft color="var(--color-white)" size={20} />
              </button>
              <p>C & W Requests</p>
              <div></div>
            </div>

            {/* recevied list start */}

            <>
              {getdata.map((item, index) => {
                return (
                  <div className="view_candw_data_main_wrapp">
                    <div className="view_candw_img_wrapp">
                      <img src={item.c_and_w_image[0]?.img_video} alt="" />
                    </div>
                    <div className="view_candw_name_wrapp">
                      <p className="view_candw_reward_name">
                        {item.reward_name}
                      </p>
                      <p className="view_candw_reward_valid_time">
                        <span>Valid Thrued :</span> (
                        {item.c_and_w_time == ""
                          ? "Unlimited"
                          : moment(item.c_and_w_time).format("DD-MM-YYYY")}
                        )
                      </p>
                    </div>

                    <div className="view_candw_userdata_wrapp">
                      {item.user_c_and_w_data &&
                      item.user_c_and_w_data.length > 0
                        ? item.user_c_and_w_data.map((itm, ind) => {
                            return (
                              <div className="family_req_receved_single_card">
                                <img src={itm.img_video} alt="" />
                                <div className="family_req_receved_single_card_name">
                                  <h4>{itm.bond_name}</h4>
                                  {/* <p>Request Content</p> */}
                                  {/* <p>20h</p> */}
                                </div>
                                <button className="family_req_receved_single_card_lrg_btn">
                                  <p>
                                    {itm.is_accept == 1
                                      ? "Accepted"
                                      : itm.is_accept == 2
                                      ? "Rejected"
                                      : "Pending"}
                                  </p>
                                </button>
                              </div>
                            );
                          })
                        : null}
                    </div>

                    <div className="view_candw_addasset_btn_wrapp">
                      {item.c_and_w_time == "" && item.status == 2 ? (
                        <button
                          onClick={() => {
                            navigate("/SelectCandWBond", {
                              state: {
                                pending_c_and_w_id: item.pending_c_and_w_id,
                              },
                            });
                          }}
                        >
                          Add More Assets
                        </button>
                      ) : (
                        moment(TodayDate).isBefore(
                          moment(item.c_and_w_time),
                          "day"
                        ) &&
                        item.status == 2 && (
                          <button
                            onClick={() => {
                              navigate("/SelectCandWBond", {
                                state: {
                                  pending_c_and_w_id: item.pending_c_and_w_id,
                                },
                              });
                            }}
                          >
                            Add More Assets
                          </button>
                        )
                      )}
                    </div>

                    {item.c_and_w_time == "" &&
                    item.is_start == 1 &&
                    item.status != 3 ? (
                      <button
                        className="new_btn_wrapp mx-1 mt-3"
                        onClick={() => {
                          _start_candw(item.pending_c_and_w_id);
                        }}
                      >
                        <p> Start C & W </p>
                      </button>
                    ) : (
                      moment(TodayDate).isBefore(
                        moment(item.c_and_w_time),
                        "day"
                      ) &&
                      item.is_start == 1 &&
                      item.status != 3 && (
                        <button
                          className="new_btn_wrapp mx-1 mt-3"
                          onClick={() => {
                            _start_candw(item.pending_c_and_w_id);
                          }}
                        >
                          <p> Start C & W </p>
                        </button>
                      )
                    )}
                  </div>
                );
              })}
            </>

            {/* recevied list end */}
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default ViewMyCandW;
