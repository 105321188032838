import React from "react";
import "./ProofIdentity.css";
import images from "../../constants/images";
import NewLoader from "../../common/newloder/NewLoader";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Slider from "react-slick";

const ProofIdentity = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [getloading, setLoading] = useState(false);
  const [slideIndex, setSlideIndex] = useState(0);

  const settings = {
    dots: true,
    infinite: true,
    // speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    pauseOnHover: true,
    speed: 2000,
    autoplaySpeed: 4000,
    afterChange: (currentSlide) => setSlideIndex(currentSlide),
    customPaging: (i) => (
      <div
        style={{
          width: "10px",
          height: "10px",
          margin: "10px 5px",
          borderRadius: "50%",
          backgroundColor: slideIndex === i ? "#da0e41" : "white",
          opacity: slideIndex === i ? 1 : 0.6,
          marginTop: "30px",
        }}
      ></div>
    ),
    responsive: [
      {
        breakpoint: 2560,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="new_wrapp_container reletive_position">
      <img src={images.new_bg_circule} alt="" className="back_circule_shadow" />

      {getloading ? (
        <NewLoader />
      ) : (
        <div className="new_container">
          <div className="play_screen_main_wrapp">
            <div
              className="play_screen_banner_container"
              style={{ height: "15vh", minHeight: "100px" }}
            >
              <img
                src={images.new_logo}
                className="play_screen_banner"
                style={{ height: "64px", maxWidth: "200px" }}
                alt=""
              />
            </div>

            <div className="otp_screen_text_container">
              <h4>Building Trust and Security</h4>
              <p>
                At aucemo, authenticity and security are paramount. To ensure a
                safe and reliable environment for everyone, we require users to
                complete a verification process. This includes:
              </p>
            </div>

            <div
              className="pv_imgs_wrapp"
              style={{
                width: "100%",
                margin: "10px auto",
                minHeight: "25vh",
                marginBottom: "8vh",
              }}
            >
              <Slider {...settings}>
                <div
                  className="otp_screen_text_container"
                  style={{
                    margin: "6px auto",
                  }}
                >
                  <h4 style={{ margin: "0px", color: "var(--color-main)" }}>
                    Aadhaar Verification:
                  </h4>
                  <p>
                    This confirms your identity and protects against fraudulent
                    activity.
                  </p>
                </div>
                <div
                  className="otp_screen_text_container"
                  style={{ margin: "6px auto" }}
                >
                  <h4 style={{ margin: "0px", color: "var(--color-main)" }}>
                    PAN Verification:
                  </h4>
                  <p>
                    It allows for secure and transparent financial transactions
                    within the aucemo ecosystem.
                  </p>
                </div>
                <div
                  className="otp_screen_text_container"
                  style={{ margin: "6px auto" }}
                >
                  <h4 style={{ margin: "0px", color: "var(--color-main)" }}>
                    Bank Account Details:
                  </h4>
                  <p>
                    This enables seamless withdrawals and earning potential
                    through aucemo collectibles sales.
                  </p>
                </div>
              </Slider>
            </div>

            {/* <div className="new_proof_identity_btns_wrapp">
              <div>
                <p>1. Aadhaar Card</p>
              </div>
              <div>
                <p>2. PAN Card</p>
              </div>
              <div>
                <p>3. GSTIN Card</p>
              </div>
              <div>
                <p>4. Bank Details</p>
              </div>
            </div> */}
            {/* btn */}
            <button
              onClick={() => {
                navigate("/NewAadhaarCard", {
                  state: {
                    user_id: location.state.user_id,
                  },
                });
              }}
              className="new_btn_wrapp mx-1 mt-3"
              style={{ width: "90%" }}
            >
              <p>Next</p>
            </button>
            {/* btn */}
          </div>
        </div>
      )}
    </div>
  );
};

export default ProofIdentity;
