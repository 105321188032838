import React, { useEffect, useState } from "react";
import "./ReceivedNfbs.css";
import images from "../../constants/images";
import {
  get_user_requested_bond_list,
  user_accept_reject_bond,
} from "../../utils/Constant";
import axios from "axios";
import BackButton from "../../components/BackButton";
import NavbarSec from "../../common/NavbarSec";
import { useNavigate } from "react-router-dom";
import NewModal from "../../common/newmodal/NewModal";
import ReactModal from "react-modal";
import { FaAngleLeft } from "react-icons/fa";
import NewLoader from "../../common/newloder/NewLoader";

const NFbsdata = [
  {
    id: 1,
    name: "Asset Name",
    image: images.media1,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus delectus magnam nemo quas ipsum laudantium maiores repudiandae enim doloribus illo?",
    price: "1.37 lac",
    username: "User Name",
    userimg: images.mask_profile,
  },
  {
    id: 2,
    name: "Asset Name",
    image: images.media2,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus delectus magnam nemo quas ipsum laudantium maiores repudiandae enim doloribus illo?",
    price: "1.37 lac",
    username: "User Name",
    userimg: images.mask_profile,
  },
  {
    id: 3,
    name: "Asset Name",
    image: images.media3,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus delectus magnam nemo quas ipsum laudantium maiores repudiandae enim doloribus illo?",
    price: "1.37 lac",
    username: "User Name",
    userimg: images.mask_profile,
  },
];

const ReceivedNfbs = () => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "0px",
      backgroundColor: "none",
      border: "none",
      borderRadius: "12px",
    },
    overlay: {
      zIndex: 1000,
      backgroundColor: "rgba(0, 0, 0, 0.3)",
    },
  };

  const userId = localStorage.getItem("user_id");
  const [getreceiveddata, setreceiveddata] = useState([]);
  const [getsearchdata, setsearchdata] = useState([]);
  const [loading, setloading] = useState(false);
  const [acceptmodel, setacceptmodel] = useState(false);
  const [getsuccessmodel, setSuccessModal] = useState(false);
  const [getsuccessmsg, setSuccessMsg] = useState("");
  const [getassignid, setassignid] = useState("");
  const [getis_nfb, setis_nfb] = useState("");
  const [getcon, setcon] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    getReleasebond();
  }, []);

  const getReleasebond = () => {
    setloading(true);
    const param = {
      user_id: userId,
    };
    console.log(param);
    axios
      .post(get_user_requested_bond_list, param)
      .then((Response) => {
        setloading(false);

        if (Response.data.status == 1) {
          console.log(Response.data.result);
          setreceiveddata(Response.data.result);
          setsearchdata(Response.data.result);
        } else {
        }

        // this.setState({
        //   // data: Response.data.result,
        //   task_arrayholder: Response.data.result,
        // });
      })
      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };

  const closemodal = () => {
    setacceptmodel(false);
  };

  const closemodal1 = () => {
    setSuccessModal(false);
  };

  const acceptdata = () => {
    setloading(true);
    const param = {
      user_id: userId,
      assign_id: getassignid,
      status: getcon == 0 ? 0 : 1,
      is_nfb: getis_nfb,
    };
    console.log(param);
    axios
      .post(user_accept_reject_bond, param)
      .then((Response) => {
        setloading(false);

        if (Response.data.status == 1) {
          getReleasebond();
          window.location.reload();
          closemodal();
        } else {
        }
      })
      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };
  const searchFilter_branch = (text) => {
    const newData = getsearchdata.filter(function (item) {
      console.log("item", item.product_name);
      const employee = item.product_name
        ? item.product_name.toUpperCase()
        : "".toLowerCase();
      const number = String(item.apply_date)
        ? String(item.apply_date).toUpperCase()
        : "".toUpperCase();
      const stock = String(item.total_available_bond_bid)
        ? String(item.total_available_bond_bid).toUpperCase()
        : "".toUpperCase();

      const textData = text.toUpperCase();
      return (
        employee.indexOf(textData) > -1 ||
        number.indexOf(textData) > -1 ||
        stock.indexOf(textData) > -1
      );
    });
    setreceiveddata(newData);
    console.log("asvsvsds", newData);
  };
  return (
    <div className="page_main_wrapp_nw reletive_position">
      {loading ? (
        <NewLoader />
      ) : (
        <>
          <div className="page_base_wrapp_2">
            {/* <div className="page_navbar_wrapp">
              <div className="nav_back_wrapp">
                <BackButton />
              </div>
              <p className="nav_screen_name">Received aucemo</p>
            </div> */}
            {/* <NavbarSec name="Received aucemo" /> */}
            <div
              className="legacy_dashbord_nav_sec"
              style={{ margin: "10px auto" }}
            >
              <button
                onClick={() => {
                  navigate(-1);
                }}
              >
                <FaAngleLeft color="var(--color-white)" size={20} />
              </button>
              <p>Received aucemo</p>
              <div></div>
              {/* <img
                onClick={() => {
                  navigate("/CreateLegacy");
                }}
                src={images.round_plus}
                alt=""
              /> */}
            </div>
            <div style={{}} className="bid13_search">
              <div
                className="bids13_user_bid_main"
                style={{ paddingTop: "10px" }}
              >
                <input
                  type="text"
                  placeholder="Search"
                  className="bids13_user_bid_search"
                  // value={searchTerm}
                  onChange={(e) => searchFilter_branch(e.target.value)}
                />
                {/* <img src={images.search} alt="" className="search_ic" /> */}
              </div>
            </div>

            {getreceiveddata == 0 ? (
              <>
                <div
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <p
                    style={{
                      alignSelf: "center",
                      textAlign: "center",
                      fontFamily: "var(--font-600)",
                      color: "white",
                      fontSize: "14px",
                    }}
                  >
                    No Items found
                  </p>
                </div>
              </>
            ) : (
              <div className="recevied_products_wrapp">
                {getreceiveddata &&
                  getreceiveddata.map((item, index) => {
                    return item.bond_img_video[0] == undefined ? null : (
                      <div className="recevied_sigle_product_wrapp">
                        <img
                          src={item.bond_img_video[0].img_video}
                          alt=""
                          className="recevied_sigle_product_img"
                        />
                        <div className="recevied_sigle_product_blur">
                          <h5 className="recevied_sigle_product_name">
                            {item.product_name}
                          </h5>
                          <p className="recevied_sigle_product_text">
                            Applied on - {item.apply_date}
                          </p>
                          <p className="recevied_sigle_product_text">
                            Assigned by - {item.owner_name}
                          </p>
                          <p className="recevied_sigle_product_text">
                            Assigned aucemo - {item.no_of_assign_bond}
                          </p>

                          <div className="recevied_sigle_product_btns_wrapp">
                            <button
                              className="accept_reject_btn"
                              onClick={() => {
                                // setassignid(item.assign_id);
                                // setacceptmodel(true);
                                // setis_nfb(item.is_nfb);
                                // setcon(0);

                                setloading(true);
                                const param = {
                                  user_id: userId,
                                  assign_id: item.assign_id,
                                  status: 0,
                                  is_nfb: item.is_nfb,
                                };
                                console.log(param);
                                axios
                                  .post(user_accept_reject_bond, param)
                                  .then((Response) => {
                                    setloading(false);
                                    console.log(Response.data);
                                    if (Response.data.status == 1) {
                                      // getReleasebond();
                                      // window.location.reload();
                                      // closemodal();
                                      setSuccessMsg(Response.data.message);
                                      setSuccessModal(true);
                                    } else {
                                    }
                                  })
                                  .catch((err) => {
                                    setloading(false);
                                    console.log(err);
                                  });
                              }}
                            >
                              Accept
                            </button>
                            <button
                              className="accept_reject_btn"
                              onClick={() => {
                                setassignid(item.assign_id);
                                setacceptmodel(true);
                                setis_nfb(item.is_nfb);
                                setcon(1);
                              }}
                            >
                              Reject
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
          {acceptmodel === true ? (
            // <div className="modal_login">
            //   <div className="modal-content_login">
            //     <div className="modal_div1">
            //       <img className="forgot_main" src={images.logo} alt="" />
            //     </div>
            //     <div className="modal_div">
            //       <b></b>
            //       <br />
            //       <p className="modal-message">
            //         Are you sure ? This action cannot be reversed.
            //       </p>
            //       <br />
            //     </div>
            //     <hr className="hr_forgot" />
            //     <br />
            //     <div className="modal_two_buton">
            //       <button className="close_forgot" onClick={closemodal}>
            //         Cancel
            //       </button>
            //       <button className="close_forgot" onClick={acceptdata}>
            //         Okay
            //       </button>
            //     </div>
            //   </div>
            // </div>
            <ReactModal
              isOpen={setSuccessModal}
              // onAfterOpen={afterOpenModal}
              onRequestClose={closemodal}
              style={customStyles}
            >
              <div className="NewModal_main_wrapp">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img className="newmodal_logo" src={images.logo} alt="" />
                  <p className="newmodal_titel"></p>
                  {/* <p className="newmodal_titel">Alert</p> */}
                </div>

                {/* <div className="newmodal_des_wrapp"> */}
                <p className="newmodal_des">
                  {" "}
                  Are you sure ? This action cannot be reversed.
                </p>
                {/* </div> */}

                <div className="modal_two_buton" style={{ width: "100%" }}>
                  <button className="close_forgot" onClick={acceptdata}>
                    Okay
                  </button>
                  <button className="close_forgot" onClick={closemodal}>
                    My Assets
                  </button>
                </div>
              </div>
            </ReactModal>
          ) : null}

          {/* success modal start */}

          {getsuccessmodel === true ? (
            // <div className="modal_login">
            //   <div className="modal-content_login">
            //     <div className="modal_div1">
            //       <img className="forgot_main" src={images.logo} alt="" />
            //     </div>
            //     <div className="modal_div">
            //       <b></b>
            //       <br />
            //       <p className="modal-message">{getsuccessmsg}</p>
            //       <br />
            //     </div>
            //     <hr className="hr_forgot" />
            //     <br />
            //     <div className="modal_two_buton">
            //       <button
            //         className="close_forgot"
            //         onClick={() => {
            //           getReleasebond();
            //           setSuccessModal(false);
            //         }}
            //       >
            //         Okay
            //       </button>
            //       <button
            //         className="close_forgot"
            // onClick={() => {
            //   navigate("/myproduct");
            // }}
            //       >
            //         My Assets
            //       </button>
            //     </div>
            //   </div>
            // </div>
            <ReactModal
              isOpen={setSuccessModal}
              // onAfterOpen={afterOpenModal}
              onRequestClose={closemodal1}
              style={customStyles}
            >
              <div className="NewModal_main_wrapp">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img className="newmodal_logo" src={images.logo} alt="" />
                  <p className="newmodal_titel"></p>
                  {/* <p className="newmodal_titel">Alert</p> */}
                </div>

                {/* <div className="newmodal_des_wrapp"> */}
                <p className="newmodal_des">{getsuccessmsg}</p>
                {/* </div> */}

                <div className="modal_two_buton" style={{ width: "100%" }}>
                  <button
                    className="close_forgot"
                    onClick={() => {
                      getReleasebond();
                      setSuccessModal(false);
                    }}
                  >
                    Okay
                  </button>
                  <button
                    className="close_forgot"
                    onClick={() => {
                      navigate("/myproduct");
                    }}
                  >
                    My Assets
                  </button>
                </div>
              </div>
            </ReactModal>
          ) : null}
          {/* success modal end */}
        </>
      )}
    </div>
  );
};

export default ReceivedNfbs;
