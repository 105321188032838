import React, { useEffect, useState } from "react";
import "./actionneed.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import NavbarSec from "../../common/NavbarSec";
import images from "../../constants/images";
import {
  get_mileston_bond_detail,
  product_history,
} from "../../utils/Constant";
import axios from "axios";
import qs from "qs";
import moment from "moment/moment";
import { ToastContainer, toast } from "react-toastify";
import BackButton from "../../components/BackButton";
import Slider from "react-slick";
import { useRef } from "react";

const Actionneed = () => {
  const userId = localStorage.getItem("user_id");
  const location = useLocation();
  const navigate = useNavigate();
  const [is_step, setis_step] = useState(0);
  const [offer_price, setoffer_price] = useState("");
  const [getproductPrice, setProductPrice] = useState("");
  const [full_msg, setfull_msg] = useState("");
  const [getextra_msg, setextra_msg] = useState("");
  const [getstatuscode, setstatuscode] = useState("");
  const [loading, setloading] = useState(false);
  const [getuserName, setUserName] = useState("");
  const gettodayDate = moment(new Date()).format("YYYY-MM-DD");
  const videoRef = useRef(null);

  const [slideIndex, setSlideIndex] = useState(0);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (currentSlide) => setSlideIndex(currentSlide),
    customPaging: (i) => (
      <div
        style={{
          width: "10px",
          height: "10px",
          margin: "10px 5px",
          borderRadius: "50%",
          backgroundColor: slideIndex === i ? "#da0e41" : "white",
          opacity: slideIndex === i ? 1 : 0.6,
        }}
      ></div>
    ),
    responsive: [
      {
        breakpoint: 2560,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const totalbidamount =
    parseInt(location.state.total_bid_amount) /
    parseInt(location.state.total_bid).toFixed(2);

  const History = () => {
    const param = {
      product_id: location.state.product_id,
      user_id: userId,
    };
    console.log("parammpin", param);
    axios
      .post(product_history, param)
      .then((Response) => {
        console.log("acdascs", Response.data.result);
        const data = Response.data.result;
        setUserName(data.user_name);
        setis_user(data.is_user);
        if (data.status == 2) {
          // if (data.extra_message != null) {
          //   setoffer_price(data.offer_price);
          //   setis_step(data.is_step);
          //   setfull_msg(data.message);
          //   setextra_msg(data.extra_message);
          // } else {
          setoffer_price(data.offer_price);
          setis_step(data.is_step);
          // setcommitDate(data.commitment_date);
          setfull_msg(
            data.is_user && data.is_user == 1
              ? `Exciting news! You now have the opportunity to create Aucemo and tap into our vibrant community. We believe in the value of your creation and are committed to helping you succeed.\n Please note that a nominal fee of ${
                  location.state && location.state.is_globle ? "10" : "20"
                } Aucemo will be charged for the creation of Aucemo. This enables us to provide you with exceptional resources and support. Get ready to showcase your asset, build your brand, and unlock new revenue streams. Thank you for choosing aucemo.club as your partner. We can't wait to see your aucemo thrive in our community.`
              : `Exciting news! You now have the opportunity to create Aucemo and tap into our vibrant community. We believe in the value of your creation and are committed to helping you succeed.\n  Get ready to showcase your asset, build your brand, and unlock new revenue streams. Thank you for choosing aucemo.club as your partner. We can't wait to see your aucemo thrive in our community.`
          );
          setProductPrice(Response.data.result.product_price);
          setProductPrice(Response.data.result.product_price);

          // alert(Response.data.message);
          // }
        } else if (data.status == 3) {
          setfull_msg(data.message);
          setstatuscode(3);
          setextra_msg(data.extra_message);
          // setcommitDate(data.commitment_date);
        }
        setloading(false);
        // if (Response.data.status == 1) {
        //   setis_step(data.is_step);
        //   setoffer_price(data.offer_price)
        // } else {
        //   setErrorMessage(Response.data.message);
        //   setShowModal(true);
        // }
        // this.setState({
        //   // data: Response.data.result,
        //   task_arrayholder: Response.data.result,
        // });
      })
      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };

  const [user_product_history, setUser_product_history] = useState("");
  const [data, setData] = useState({});
  const [accept_bond_bid_id, setaccept_bond_bid_id] = useState("");
  const [release_bond_id, setrelease_bond_id] = useState(
    location.state.release_bond_id
  );
  const [current_highest_bid, setcurrent_highest_bid] = useState("");

  const [is_nfb, setis_nfb] = useState("");
  const [total_available_bond_bid, settotal_available_bond_bid] = useState("");
  const [minimum_bid_amount, setminimum_bid_amount] = useState("");
  const [product_id, setproduct_id] = useState("");
  const [partner_bond_benifit_data, setpartner_bond_benifit_data] =
    useState("");
  const [product_name, setproduct_name] = useState("");
  const [is_club_member, setis_club_member] = useState("");
  const [is_club_id, setis_club_id] = useState("");
  const [is_globle, setis_globle] = useState("");
  const [is_user_owner, setis_user_owner] = useState("");
  const [getis_user, setis_user] = useState("");
  const [getproducthashtag, setProductHashtag] = useState([]);
  const [getuserid, setuserid] = useState("");
  const [getclubid, setclubid] = useState("");
  const [is_club, setIsClub] = useState("");
  const [join_club_product, setjoin_club_product] = useState("");
  const [share_by_user, setshare_by_user] = useState("");
  const [getmediaLinks, setMediaLinks] = useState([]);

  const [stock, setstock] = useState([]);
  const [getuniqIndex, setUniqIndex] = useState(0);
  const [getqr_code_data, setqr_code_data] = useState("");
  const [showVideo, setShowVideo] = useState(false);
  const [proof_type, setproof_type] = useState("");

  const handleImageClick = () => {
    setShowVideo(true);
  };

  const handleVideoClick = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  };

  const handleVideoEnded = () => {
    setShowVideo(false);
    videoRef.current.currentTime = 0;
  };

  const handleCopyLink = (link) => {
    const el = document.createElement("textarea");
    el.value = link;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    alert("Link copied to clipboard!");
  };

  const GetProductDetail = async () => {
    setloading(true);

    const param = {
      user_id: userId,
      bond_id: location.state.product_id,
      // user_milestone_id: location.state.user_milestone_id,
    };

    console.log("param --==-->", param);

    await axios
      .post(get_mileston_bond_detail, qs.stringify(param))
      .then((res) => {
        setloading(false);
        if (res.data.status === 1) {
          console.log("res.data--->11", res.data);
          // setuniq_code(res.data.result.uniq_code);
          const data = res.data.result[0];
          const productHastags = (data && data.product_hashtag) || "";
          setUser_product_history(data.user_product_history);
          setData(data);
          setaccept_bond_bid_id(data.accept_bond_bid_id);
          setrelease_bond_id(data.release_bond_id);
          setcurrent_highest_bid(data.current_highest_bid);
          setis_nfb(data.is_nfb);
          settotal_available_bond_bid(data.total_available_bond_bid);
          setminimum_bid_amount(data.minimum_bid_amount);
          setproduct_id(data.product_id);
          // setpartner_bond_benifit_data(data.product_id);
          setpartner_bond_benifit_data(data.partner_bond_benifit_data);
          setproduct_name(data.product_name);
          setis_club_member(data.is_club_member);
          setis_club_id(data.is_club_id);
          setis_globle(data.globle_exclusive);
          setis_user_owner(data.is_user_owner);
          setProductHashtag(productHastags.split(" "));
          setuserid(data.user_id);
          setclubid(data.is_club_id);
          setIsClub(data.is_club);
          setjoin_club_product(data.join_club_product);
          setshare_by_user(data.share_by_user);
          setMediaLinks(data.social_media_link);
          setstock(data.user_release_bond);
          setproof_type(data.proof_type);
        } else {
          console.log("product view err ->", res.data);
          // setIsModalVisible(true);
          // setMsg(res.data.message);
        }
      })
      .catch((err) => {
        setloading(false);
      });
  };

  useEffect(() => {
    History();
    if (gettodayDate >= location.state.commitment_date) {
      console.log(
        "commitment date ajj ni date karta nani chhe athava sarkhi chhe"
      );
      console.log("commitment date", location.state.commitment_date);
      console.log("today date", gettodayDate);
    } else if (gettodayDate <= location.state.commitment_date) {
      console.log(
        "commitment date ajj ni date karta moti chhe athava sarkhi chhe"
      );
      console.log("commitment date", location.state.commitment_date);
      console.log("today date", gettodayDate);
    } else {
      console.log("Both dates are equal");
      console.log("commitment date", location.state.commitment_date);
      console.log("today date", gettodayDate);
    }
    GetProductDetail();
  }, []);

  return (
    <div className="page_main_wrapp">
      <div
        className="productview_2"
        style={{ margin: "0px auto", width: "100%" }}
      >
        <div className="productview_header">
          <div className="productview_main_header_notifi">
            <div className="productviewmain1">
              <BackButton />
            </div>
            <div className="productviewmain2"></div>
          </div>
        </div>
        {/* <NavbarSec name="" /> */}
        <div className="actionneed_main" style={{ marginTop: "0px" }}>
          {/* <b>Dear {location.state.owner_name}</b> */}
          {/* <b style={{ fontSize: "16px", fontFamily: "var(--font-600)" }}>
            Dear {getuserName}
          </b> */}
          {/* <br />
          {location.state.commitment_date == "" ? (
            <p className="actionneed_p">
              {full_msg}
              <br />
              {getextra_msg !== "" ? getextra_msg : null}
            </p>
          ) : location.state.commitment_date <= gettodayDate ? (
            <p className="actionneed_p">
              Thank you for choosing aucemo.club as your partner. We can't wait
              to see your aucemo thrive in our community.
            </p>
          ) : (
            <p className="actionneed_p">
              {full_msg}
              <br />
              {getextra_msg !== "" ? getextra_msg : null}
            </p>
          )}
          <br /> */}
          {/* {location.state.total_bid_amount &&
            location.state.total_bid_amount !== "" && (
              <p
                style={{
                  marginBottom: "10px",
                }}
                className="actionneed_p"
              >
                Based on your six bids totaling{" "}
                {location.state.total_bid_amount} the value of each individual
                asset would be{" "}
                {totalbidamount.toFixed(2)}
              </p>
            )} */}

          {/* ------------------ new design start -------------------- */}
          <div className="productview_club_user">
            <div
              onClick={() => {
                // if (getclubid == "" || getclubid == 0) {
                navigate("/UserProfile", {
                  state: {
                    user_id: getuserid,
                  },
                });
                // } else {
                //   navigate("/Clubprofile", {
                //     state: {
                //       club_id: getclubid,
                //     },
                //   });
                // }
              }}
              className="product_user_nm"
            >
              <img src={data.profile} className="product_user_nm_img" alt="" />
              <p className="product_user_nm_p">{data.user_name}</p>
            </div>

            <div
              className="pv_imgs_wrapp"
              style={{ width: "100%", margin: "10px auto" }}
            >
              <Slider {...settings}>
                {data && data.bond_img_video
                  ? data.bond_img_video.map((item, index) => {
                      console.log("item", item);
                      return (
                        <>
                          {/* <img
                          src={item.img_video}
                          alt=""
                          className="pv_img_single_img"
                        /> */}

                          <img
                            src={item.img_video}
                            alt=""
                            style={{ width: "100%" }}
                            className="pv_img_single_img"
                            onClick={() => {
                              navigate("/ActionViewImg", {
                                state: {
                                  img: data.bond_img_video
                                    ? data.bond_img_video
                                    : "",
                                },
                              });
                            }}
                          />
                        </>
                      );
                    })
                  : null}
              </Slider>
            </div>

            {/* <Cardviewsecond start /> */}
            <div className="detsil_product_nm">
              <div className="sub_product1">
                <p className="sub_h_1">{data.product_name}</p>
                <p className="sub_h_p">{data.created_at}</p>
              </div>
              <div className="sub_product2">
                <p className="product-nfb_nm">Asset Value</p>
                <p className="product-nfb_price">₹{data.product_value}</p>
              </div>
            </div>
            {/* <Cardviewsecond end/> */}
            {/* create aucemo button start */}

            {getstatuscode == 3 ? null : (
              <>
                {is_step == 0 ? (
                  <>
                    {/* <Link
                   to={"/bondissue"}
                   state={{
                     product_id: location.state.product_id,
                     remaining_bond: location.state.remaining_bond,
                     product_price: getproductPrice,
                     is_globle: location.state.is_globle,
                     is_owner: location.state.is_owner,
                     commitment_date_new: location.state.commitment_date,
                   }}
                   className="actionneed_btn"
                 >
                   Create Aucemo
                 </Link> */}

                    <Link
                      to={"/bondissue"}
                      state={{
                        product_id: location.state.product_id,
                        remaining_bond: location.state.remaining_bond,
                        product_price: getproductPrice,
                        is_globle: location.state.is_globle,
                        is_owner: location.state.is_owner,
                        is_gift: location.state.is_gift,
                        commitment_date_new: location.state.commitment_date,
                        is_user: getis_user,
                      }}
                      className="new_btn_wrapp mt-3"
                    >
                      <p>Create Aucemo</p>
                    </Link>
                  </>
                ) : is_step == 1 ? (
                  <Link
                    to={"/Collaboratenfb"}
                    state={{
                      product_id: location.state.product_id,
                      commitment_date_new: location.state.commitment_date,
                      is_user: getis_user,
                    }}
                    className="new_btn_wrapp mt-3"
                  >
                    <p> Create Aucemo</p>
                  </Link>
                ) : is_step == 5 ? (
                  <Link
                    to={"/bondissue"}
                    state={{
                      product_id: location.state.product_id,
                      remaining_bond: location.state.remaining_bond,
                      product_price: getproductPrice,
                      is_globle: location.state.is_globle,
                      is_owner: location.state.is_owner,
                      is_gift: location.state.is_gift,
                      commitment_date_new: location.state.commitment_date,
                      is_user: getis_user,
                    }}
                    className="new_btn_wrapp mt-3"
                  >
                    <p>Create Aucemo</p>
                  </Link>
                ) : is_step == 6 ? (
                  <Link
                    to={"/Collaboratenfb"}
                    state={{
                      product_id: location.state.product_id,
                      commitment_date_new: location.state.commitment_date,
                      is_user: getis_user,
                    }}
                    className="new_btn_wrapp mt-3"
                  >
                    <p>Create Aucemo</p>
                  </Link>
                ) : is_step == 7 ? (
                  <Link
                    to={"/Createnfbs"}
                    state={{
                      product_id: location.state.product_id,
                      commitment_date_new: location.state.commitment_date,
                      is_user: getis_user,
                    }}
                    className="new_btn_wrapp mt-3"
                  >
                    <p>Create Aucemo</p>
                  </Link>
                ) : (
                  <Link
                    to={"/Createnfbs"}
                    state={{
                      product_id: location.state.product_id,
                      commitment_date_new: location.state.commitment_date,
                      is_user: getis_user,
                    }}
                    className="new_btn_wrapp mt-3"
                  >
                    <p>Create Aucemo</p>
                  </Link>
                )}
              </>
            )}
            {/* create aucemo button end */}

            {/* product story start */}
            {/* {data.product_history == "" ||
            data.product_history === null ||
            data.product_history === undefined ? null : (
              <>
                <div className="product_story">
                  <h1 className="pv_titel">
                    Asset <br /> Story
                  </h1>
                  <div className="product_view_hr"></div>
                </div>
                <div className="product_story_del">
                  <p className="p_v_details">{data.product_history}</p>
                </div>
              </>
            )} */}
            {/* product story end */}

            {/* Highlights start */}
            {data.product_highlights == "" ||
            data.product_highlights === null ||
            data.product_highlights === undefined ? null : (
              <>
                <div className="product_story">
                  <div className="product_view_hr"></div>
                  <h1
                    className="pv_titel"
                    style={{ fontFamily: "var(--font-600)" }}
                  >
                    Highlights
                  </h1>
                </div>
                <p className="ser_p_produuct">{data.product_highlights}</p>
              </>
            )}
            {/* Highlights end */}

            {/* phisical asset section start */}
            <div className="product_view_phisical_sec_wrapp">
              <p className="product_view_gradinat_text_heading">
                {data.product_name?.slice(0, 24)}
                {data.product_name?.length > 24 ? "..." : null}
              </p>
              <div className="phisical_asset_box_gradient_wrapp">
                <div className="phisical_asset_box_inner_wrapp">
                  {/* image */}

                  {data.bond_img_video && data.bond_img_video.length > 0 ? (
                    <img
                      src={data.bond_img_video[0].img_video}
                      alt="product img"
                      onClick={() => {
                        navigate("/ActionViewImg", {
                          state: {
                            img: data.bond_img_video ? data.bond_img_video : "",
                          },
                        });
                      }}
                      style={{ cursor: "pointer" }}
                    />
                  ) : null}

                  {/* phisical story */}

                  {data.product_history == "" ||
                  data.product_history === null ||
                  data.product_history === undefined ? null : (
                    <div className="phisical_asset_story_wrapp">
                      <p className="phisical_asset_story_heading">
                        Story Statement
                      </p>

                      <p className="p_v_details">{data.product_history}</p>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* phisical asset section end */}

            {/* video start */}
            <div className="pv_video_shows">
              {!showVideo ? (
                <>
                  <img
                    // src={images.thumbnail}
                    src={data.bond_thumbnail}
                    className="video_bg_pv"
                    alt="Video Thumbnail"
                  />
                  <img
                    src={images.video}
                    className="video_plays"
                    onClick={handleImageClick}
                    alt=""
                  />
                </>
              ) : (
                <video
                  ref={videoRef}
                  className="video_player_pv"
                  autoPlay
                  controls
                  onClick={handleVideoClick}
                  onEnded={handleVideoEnded}
                  src={data.bond_video}
                  poster={data.bond_thumbnail}
                >
                  {/* <source
                src={data.bond_video}
                type="video/mp4" className="video_link"
              /> */}
                  {/* Add additional source tags for other video formats if necessary */}
                </video>
              )}
              {showVideo && (
                <img
                  src={images.video}
                  className="video_play1_pv"
                  onClick={handleVideoClick}
                  alt=""
                />
              )}
            </div>
            {/* video end */}
            {/* cirtificate start */}
            {data.owner_proof ==
              "https://www.ravi.host/public/assets/club_owner_proof/" ||
            data.owner_proof ==
              "https://www.ravi.host/public/assets/owner_proof/" ||
            data.owner_proof == "" ||
            data.owner_proof == null ? null : (
              <>
                <div className="product_story">
                  <div className="product_view_hr"></div>
                  <h1 className="pv_titel">Certificate</h1>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {proof_type === "document" ? (
                    <div>
                      <a
                        href={data.owner_proof}
                        target="_blank"
                        rel="noopener noreferrer"
                        download="document.pdf"
                      >
                        <img
                          src={images.pdf_icon}
                          style={{
                            width: "100%",
                            minWidth: "200px",
                            height: "200px",
                          }}
                          alt=""
                        />
                      </a>
                    </div>
                  ) : (
                    <img
                      src={data.owner_proof}
                      style={{
                        width: "100%",
                        minWidth: "200px",
                        height: "200px",
                      }}
                      alt=""
                      onClick={() => {
                        navigate("/CirtificateViewImg", {
                          state: {
                            img: data.owner_proof ? data.owner_proof : "",
                          },
                        });
                      }}
                    />
                  )}
                </div>
              </>
            )}
            {/* cirtificate end */}

            {/* physical start */}
            {(data.physical_owner_name !== "" ||
              data.physical_asset_address !== "") && (
              <div style={{ width: "100%" }}>
                <div className="product_story">
                  <h1 className="pv_titel">Physical Details</h1>
                  <div className="product_view_hr"></div>
                </div>
                {/* owner name */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    margin: "10px auto",
                  }}
                >
                  {data.physical_owner_name &&
                  data.physical_owner_name !== "" ? (
                    <div className="phisical_asset_owner_name_wrapp">
                      <div>
                        <img src={images.new_profile_icon} alt="" />
                      </div>
                      <p>{data.physical_owner_name}</p>
                    </div>
                  ) : null}

                  {/* location */}
                  {data.physical_asset_address &&
                  data.physical_asset_address !== "" ? (
                    <div className="phisical_asset_owner_name_wrapp">
                      <div>
                        <img src={images.new_location_icon} alt="" />
                      </div>
                      <p>{data.physical_asset_address}</p>
                    </div>
                  ) : null}
                </div>
              </div>
            )}
            {/* physical start */}

            {/* milestone start */}
            {data.user_milestone_id !== "" &&
              data.user_milestone_id !== null &&
              data.user_milestone_id !== undefined && (
                <>
                  <div className="product_story">
                    <div className="product_view_hr"></div>
                    <h1 className="pv_titel">Milestone</h1>
                  </div>

                  <Link
                    className="productview_milestone_wrapp"
                    to="/MilestoneDashboard"
                    state={{
                      user_id: data.user_id,
                      user_milestone_id: data.user_milestone_id,
                    }}
                  >
                    <img
                      src={
                        data.mile_image_link &&
                        data.mile_image_link[0]?.imageLink
                      }
                      alt=""
                    />
                    <div>
                      <div>
                        <p>{data.milestone_name}</p>
                      </div>
                    </div>
                  </Link>
                </>
              )}
            {/* milestone end */}

            {/* media start */}
            {data.bond_media_upload && data.bond_media_upload.length > 0 && (
              <>
                <div className="product_story">
                  <h1 className="pv_titel">Media</h1>
                  <div className="product_view_hr"></div>
                </div>
                <div style={{ width: "100%" }}>
                  {data.bond_media_upload.map((item, index) => {
                    console.log("media", item);
                    return (
                      <img
                        src={item.img_video}
                        style={{ width: "100px", height: "100px" }}
                        alt=""
                        onClick={() => {
                          navigate("/ProductViewImg2", {
                            state: {
                              img: item.img_video ? item.img_video : "",
                            },
                          });
                        }}
                      />
                    );
                  })}
                </div>
              </>
            )}
            {/* media end */}

            {/* media links start */}
            {getmediaLinks && getmediaLinks.length > 0 ? (
              <>
                <div className="product_story">
                  <div className="product_view_hr"></div>
                  <h1 className="pv_titel">Media Link</h1>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "6px",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    width: "100%",
                  }}
                >
                  {getmediaLinks && getmediaLinks.length > 0
                    ? getmediaLinks.map((item, index) => {
                        console.log("item", item);
                        return (
                          <div key={index} className="ml_wrapp">
                            <a
                              href={item.media_link}
                              target="_blank"
                              className="ml_link_text"
                            >
                              <p>{item.media_link}</p>
                            </a>
                            <button
                              className="ml_copy_btn"
                              onClick={() => handleCopyLink(item.media_link)}
                            >
                              Copy
                            </button>
                          </div>
                        );
                      })
                    : null}
                </div>
              </>
            ) : null}
            {/* media links end */}

            {/* <div className="hasttagnew_pro" > */}

            {/* Hashtags start */}
            {data.product_hashtag && data.product_hashtag !== null
              ? getproducthashtag.length > 0 && (
                  <>
                    <div className="product_story">
                      <h1 className="pv_titel">Hashtags</h1>
                      <div className="product_view_hr"></div>
                    </div>
                    <div className="hashtags_list">
                      {getproducthashtag.map((item, index) => {
                        return <p key={index}>{item}</p>;
                      })}
                    </div>
                  </>
                )
              : null}
            {/* </div> */}
            {/* Hashtags end */}
          </div>

          <div
            className="creator_details"
            onClick={() => {
              if (
                data.owner_id !== "" ||
                data.owner_id !== 0 ||
                data.owner_id !== null
              ) {
                // this.props.navigation.navigate('UserProfile', {
                //   user_id: this.state.owner_id,
                // });
                navigate("/UserProfile", {
                  state: {
                    user_id: data.owner_id,
                  },
                });
              } else {
              }
            }}
          >
            <div
              className="creator_details_details"
              style={{ width: "90%", margin: "10px auto" }}
            >
              <p style={{ color: "#000" }}>{data.description}</p>
            </div>
            <img
              style={{ objectFit: "contain" }}
              src={data.owner_profile}
              className="creator_details_img"
              alt=""
            />
            <div className="creator_details_sub">
              <h1 className="creator_details_sub_h1">{data.owner_name}</h1>
              <p className="creator_details_sub_p">Asset Creator</p>
            </div>
          </div>

          {/* ------------------ new design end -------------------- */}

          {/* <img className="actionneed_img" src={images.new_logo} alt="" /> */}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Actionneed;
