import React, { useState } from "react";
import "../FeedNewFrame1.css";
import { useSwipeable } from "react-swipeable";
import images from "../../constants/images";
import axios from "axios";
import qs from "qs";
import { release_bond_like } from "../../utils/Constant";
import { useNavigate } from "react-router-dom";

const NewNfbFrame1 = ({
  item,
  setIsModalOpen,
  setMsg,
  setModalName,
  icon,
  windowWidth,
}) => {
  const navigate = useNavigate();

  const [isFlipped, setIsFlipped] = useState(false);

  const handleSwipe = () => {
    setIsFlipped(!isFlipped);
  };
  const swipeHandlers = useSwipeable({
    onSwiped: handleSwipe,
  });

  return (
    <div className="blue-card-main-wrapp">
      <div
        className={`flip-container ${isFlipped ? "flipped" : ""}`}
        {...swipeHandlers}
      >
        {windowWidth > 786 && (
          <div
            onClick={() => setIsFlipped(!isFlipped)}
            style={{
              width: "90%",
              display: "flex",
              justifyContent: "flex-end",
              // top: "-9.5%",
              // position: "absolute",
              // left: "0px",
            }}
          >
            <img
              src={images.flip_icon}
              style={{ width: "30px", height: "30px", alignSelf: "flex-end" }}
            />
          </div>
        )}
        <div className="flipper">
          <div className="front">
            <img src={images.new_frame_1_f} className="frame-img" alt="" />
            <img src={icon} className="inner-img" alt="product img" />
            <div className="front-card-del-qr">
              <div className="front-card-del-div">
                <div className="front-card-del">
                  <p>
                    {item.product_name?.slice(0, 24)}
                    {item.product_name?.length > 24 ? "..." : null}
                  </p>
                  <p>
                    By : {item.creator_name?.slice(0, 20)}{" "}
                    {item.creator_name?.length > 20 ? "..." : null}
                  </p>
                  <p>Product Value : ₹{item.product_value_data}</p>
                </div>
              </div>
              <div className="front-card-qr">
                <div className="qr-circle">
                  <img src={item.qr_code} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="back">
            <img src={images.new_frame_1_b} className="frame-img" alt="" />
            {/* <img src={icon} className="inner-img" alt="" /> */}
            <div className="back-card-del-div">
              {item.description && (
                <div style={{ width: "100%", marginBottom: "10px" }}>
                  <p className="des-name">Product Description</p>
                  <p className="des-content">
                    {item.description?.slice(0, 78)}{" "}
                    {item.description?.length > 78 && "..."}
                  </p>
                  {item.description?.length > 78 && (
                    <button
                      onClick={() => {
                        setIsModalOpen(true);
                        setMsg(item.description);
                        setModalName("Product Description");
                      }}
                      className="fram_read_btn"
                    >
                      Read more
                    </button>
                  )}
                </div>
              )}

              {item.product_history && (
                <div style={{ width: "100%", marginBottom: "10px" }}>
                  <p className="des-name">Product story</p>
                  <p className="des-content">
                    {item.product_history?.slice(0, 78)}{" "}
                    {item.product_history?.length > 78 && "..."}
                  </p>
                  {item.product_history?.length > 78 && (
                    <button
                      onClick={() => {
                        setIsModalOpen(true);
                        setMsg(item.product_history);
                        setModalName("Product Story");
                      }}
                      className="fram_read_btn"
                    >
                      Read more
                    </button>
                  )}
                </div>
              )}

              {item.product_highlights && (
                <>
                  <p className="des-name">Product Highlight</p>
                  <p className="des-content">
                    {item.product_highlights?.slice(0, 78)}{" "}
                    {item.product_highlights?.length > 78 && "..."}
                  </p>
                  {item.product_highlights?.length > 78 && (
                    <button
                      onClick={() => {
                        setIsModalOpen(true);
                        setMsg(item.product_highlights);
                        setModalName("Product Highlight");
                      }}
                      className="fram_read_btn"
                    >
                      Read more
                    </button>
                  )}
                </>
              )}
            </div>
            <div className="owner-name-div">
              <p className="owned-by">Owned by</p>
              <p className="owner-name">{item.owner_name}</p>
            </div>
            <div className="back-card-del-qr">
              <div className="back-card-del-div2">
                <div className="back-card-del">
                  <p>
                    {item.product_name?.slice(0, 24)}
                    {item.product_name?.length > 24 ? "..." : null}
                  </p>
                  <p>
                    By : {item.creator_name?.slice(0, 20)}{" "}
                    {item.creator_name?.length > 20 ? "..." : null}
                  </p>
                  <p>aucemo Value : ₹{item.product_value}</p>
                </div>
              </div>
              <div className="back-card-qr">
                <div className="qr-circle">
                  <img src={item.qr_code} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewNfbFrame1;
