import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import "./clubAttachbenefit.css";
import qs from "qs";
import NavbarSec from "../common/NavbarSec";

const ClubAttachbenefit = () => {
  const userId = localStorage.getItem("user_id");
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedCheckbox, setSelectedCheckbox] = useState(null);
  const [own_benifits, setown_benifits] = useState([]);
  const [partner_benifits, setpartner_benifits] = useState([]);
  const [getuniq_code, setuniq_code] = useState("");
  const [isloading, setIsLoading] = useState(false);

  const handleCheckboxChange = (index) => {
    setSelectedCheckbox(index);
  };

  const goBack = () => {
    // This will navigate back to the previous page in your history.
    window.history.back();
    // window.location.reload();
  };

  // rendom string start

  const generateRandomString = () => {
    const chars =
      // "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ!@#$%^&*()_+~`|}{[]:;?><,./-=";
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let randomString = "";
    for (let i = 0; i < 4; i++) {
      const randomIndex = Math.floor(Math.random() * chars.length);
      randomString += chars.charAt(randomIndex);
    }

    console.log("randomString===========================", randomString);
    setuniq_code(randomString);
  };
  // rendom string end

  useEffect(() => {
    if (
      (location.state.uniq_code && location.state.uniq_code == undefined) ||
      location.state.uniq_code == "" ||
      location.state.uniq_code == null
    ) {
      generateRandomString();
    } else {
      console.log("====================================");
      console.log("location.state.uniq_code", location.state.uniq_code);
      console.log("====================================");

      setuniq_code(location.state.uniq_code);

      setuniq_code(location.state.uniq_code);
    }

    GetPartnerBenefits();
    GetOwnBenefits();
  }, []);

  // partner benefit start

  const GetPartnerBenefits = async () => {
    setIsLoading(true);
    const param = {
      club_product_id: location.state.club_product_id,
      user_id: location.state.club_user_id,
      uniq_code:
        location.state.uniq_code == undefined
          ? getuniq_code
          : location.state.uniq_code,
    };

    await axios
      .post(
        "https://www.ravi.host/api/club_get_all_partner_with_benifit",
        qs.stringify(param)
      )
      .then((Response) => {
        setIsLoading(false);
        console.log("====================================");
        console.log("club_get_all_partner_with_benifit res =>", Response.data);
        console.log("====================================");
        if (Response.data.status == 1) {
          setpartner_benifits(Response.data.result);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  // partner benefit end

  // Own benefit start

  const GetOwnBenefits = async () => {
    setIsLoading(true);
    const param = {
      club_product_id: location.state.club_product_id,
      user_id: location.state.club_user_id,
      uniq_code:
        location.state.uniq_code == undefined
          ? getuniq_code
          : location.state.uniq_code,
    };

    await axios
      .post(
        "https://www.ravi.host/api/club_get_user_product_bond_benifit",
        qs.stringify(param)
      )
      .then((Response) => {
        setIsLoading(false);
        console.log("====================================");
        console.log("get_user_product_bond_benifit res =>", Response.data);
        console.log("====================================");
        if (Response.data.status == 1) {
          setown_benifits(Response.data.result);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  // Own benefit end

  // Define an array of items for checkboxes
  const items = [
    { name: "Item 1" },
    { name: "Item 2" },
    { name: "Item 3" },
    { name: "Item 4" },
    { name: "Item 5" },
    // Add more items as needed
  ];

  return (
    <div className="page_main_wrapp">
      <div className="page_base_wrapp">
        <NavbarSec name="Attach Aucemo" />
        <div className="own_benefit">
          <div className="nm_of_own">
            Attach Own Benefit <p className="info_btn"> &#8505;</p>
          </div>
        </div>
        <div className="attach_nm_list">
          <div className="link_attach">
            <center>
              <Link
                to={"/Createclubbenefit"}
                state={{
                  club_product_id: location.state.club_product_id,
                }}
              >
                <span>+ </span>
                <br />
                <span className="link_attach_nm">Create New</span>
              </Link>
            </center>
          </div>
          <div className="link_attach2">
            {own_benifits && own_benifits.length > 0
              ? own_benifits.map((item, index) => {
                  console.log("item 0->", item);
                  return (
                    <button
                      onClick={() => {
                        const updatedArray = [...own_benifits];

                        if (item.is_status == 1) {
                          const param = {
                            club_product_id: location.state.club_product_id,
                            user_id: location.state.club_user_id,
                            no_of_bond: location.state.number_of_bond,
                            product_benifit_id: item.club_bo_ben_id,
                            partner_id: "",
                            partner_benifit_id: "",
                            uniq_code: getuniq_code,
                            action_user_id: userId,

                            club_id: location.state.club_id,
                            share_by_user: location.state.share_by_user,
                          };

                          axios
                            .post(
                              "https://www.ravi.host/api/club_no_of_bond_assign_benifit",
                              qs.stringify(param)
                            )
                            .then((Response) => {
                              console.log("consol", Response.data);
                              if (Response.data.status == 1) {
                                GetOwnBenefits();
                              }
                            });
                        } else {
                          const param = {
                            id: item.bond_draft_benifit_id,
                          };

                          axios
                            .post(
                              "https://www.ravi.host/api/club_draft_bond_api_part_3_delete",
                              qs.stringify(param)
                            )
                            .then((Response) => {
                              console.log(
                                "====================================",
                                Response.data
                              );
                              if (Response.data.status == 1) {
                                GetOwnBenefits();
                                // this.setState({
                                //   isLoading: false,
                                // });
                                // item.is_status =
                                //   item.is_status === 1 ? 2 : 1;

                                // this.setState({
                                //   own_benifits: updatedArray,
                                // });
                              } else {
                                alert(Response.data.message);
                              }
                            });
                        }
                      }}
                      className="details_of_benefit_2"
                    >
                      <input
                        type="checkbox"
                        className="details_of_checkbox"
                        checked={item.is_status === 2}
                      />
                      <img
                        className="clubAttac_img_resel"
                        src={item.reward_img_video[0].img_video}
                        alt=""
                      />
                      <p className="nm_sub_benefit">{item.reward_name}</p>
                      <Link
                        style={{ color: "#fff" }}
                        // to={"/benefits"}
                        to={"/ViewBenefits"}
                        state={{
                          // product_id: location.state.club_product_id,
                          // number_of_bond: item.no_of_bond,
                          // uniq_code: item.uniq_code,

                          is_nfb: item.is_nfb,
                          pro_bo_ben_id: item.club_bo_ben_id,
                          partner: 0,
                        }}
                      >
                        Know more
                      </Link>
                    </button>
                  );
                })
              : null}
          </div>
        </div>
        <br />
        <div className="own_benefit">
          <div className="nm_of_own">
            Attach Partner Benefit <p className="info_btn"> &#8505;</p>
          </div>
        </div>
        {partner_benifits && partner_benifits.length > 0 && (
          <div className="attach_partner_1">
            {partner_benifits.map((item, index) => (
              <div className="attach_partner_main" key={index}>
                <button
                  // className="attach_partner_main"
                  key={index}
                  onClick={() => {
                    const updatedArray = [...partner_benifits];
                    console.log("item", item);
                    if (item.is_status == 2) {
                      const param = {
                        id: item.bond_draft_benifit_id,
                      };

                      axios
                        .post(
                          "https://www.ravi.host/api/club_draft_bond_api_part_3_delete",
                          qs.stringify(param)
                        )
                        .then((Response) => {
                          console.log(
                            "====================================",
                            Response.data
                          );
                          if (Response.data.status == 1) {
                            GetPartnerBenefits();
                            window.location.reload();
                            // updatedArray[index].is_status =
                            //   updatedArray[index].is_status === 1
                            //     ? 2
                            //     : 1;

                            // this.setState({
                            //   partner_benifits: updatedArray,
                            // });
                          } else {
                            alert(Response.data.message);
                          }
                        });
                    } else {
                      const param = {
                        // club_product_id: location.state.club_product_id,
                        // user_id: location.state.club_product_id,
                        // action_user_id: userId,
                        // no_of_bond: location.state.number_of_bond,
                        // product_benifit_id: item.pro_bo_ben_id,
                        // //   partner_id: item.partner_id,
                        // partner_benifit_id: "",
                        // partner_id: "",
                        // //   partner_benifit_id: item.partner_benifit_id,
                        // uniq_code: getuniq_code,

                        club_product_id: location.state.club_product_id,
                        user_id: location.state.club_user_id,
                        no_of_bond: location.state.number_of_bond,
                        product_benifit_id: item.pro_bo_ben_id,

                        partner_id: item.partner_id,
                        partner_benifit_id: item.partner_benifit_id,
                        uniq_code: getuniq_code,
                        action_user_id: userId,

                        club_id: location.state.club_id,
                        share_by_user: location.state.share_by_user,
                      };

                      axios
                        .post(
                          "https://www.ravi.host/api/club_no_of_bond_assign_benifit",
                          qs.stringify(param)
                        )
                        .then((Response) => {
                          console.log(
                            "club_no_of_bond_assign_benifit",
                            Response.data
                          );

                          if (Response.data.status == 1) {
                            GetPartnerBenefits();
                            // updatedArray[index].is_status =
                            //   updatedArray[index].is_status === 1
                            //     ? 2
                            //     : 1;

                            // this.setState({
                            //   partner_benifits: updatedArray,
                            // });
                          }
                        });
                    }
                  }}
                >
                  <input
                    type="checkbox"
                    className="attach_partner_select"
                    checked={item.is_status === 2}
                    // onChange={() => {
                    //   console.log("item.is_status", item.is_status);
                    // }}
                    onChange={() => handleCheckboxChange(index)}
                  />
                  <img
                    src={item.partner_profile}
                    className="benefits_img_1"
                    alt=""
                  />
                  <p className="nm_sub_benefit">{item.partner_name}</p>
                </button>
                <Link
                  // to={"/benefits"}
                  to={"/ViewBenefits"}
                  state={{
                    // product_id: location.state.club_product_id,
                    // number_of_bond: item.no_of_bond,
                    // uniq_code: item.uniq_code,
                    partner: 1,
                    is_nfb: item.is_nfb,
                    partner_benifit_id: item.partner_benifit_id,
                  }}
                  style={{ color: "#fff" }}
                  className="nm_sub_benefit2"
                >
                  Know more
                </Link>
              </div>
            ))}
          </div>
        )}
        <div className="attache_new_set_div">
          <button onClick={goBack} className="new_btn_wrapp mt-3">
            <p>Attach</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ClubAttachbenefit;
